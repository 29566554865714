import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as mutations from '../graphql/mutations';
import { createMeet as createMeetQuery } from '../graphql/mutations'; // Import your GraphQL mutations

// Import your GraphQL mutations
import * as queries from '../graphql/queries'; // Import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

const useCreateMeet = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let conflict = false;
  const [meets, setMeets] = useState([]);

  const listMeets = async () => {
    try {
      setLoading(true);
      const response = await API.graphql(graphqlOperation(queries.listMeets));
      const fetchedMeets = response.data.listMeets.items;
      setMeets(fetchedMeets);
      setLoading(false);
      return fetchedMeets;
    } catch (error) {
      console.error('Error listing meets:', error);
      setLoading(false);
      throw error;
    }
  };

  const deleteMeetById = async (meetId) => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: mutations.deleteMeet,
        variables: { input: { id: meetId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      setLoading(false);
      setMeets(meets.filter((meet) => meet.id !== meetId));
      toast.success('Meet deleted successfully');
      setLoading(false);
    } catch (error) {
      console.error('Error deleting meet:', error);
      setLoading(false);
      toast.error('Failed to delete meet');
      throw error;
    }
  };

  const createMeet = async (meetData) => {
    setLoading(true);
    try {
      // Get all meet data
      const allMeetsResponse = await API.graphql(graphqlOperation(queries.listMeets));

      // Check for conflicts
      const allMeets = allMeetsResponse.data.listMeets.items;
      const conflictingMeet = allMeets.find((meet) => {
        const selectedDate = new Date(meetData.selectedDate);
        const meetDate = new Date(meet.selectedDate);
        const sameDate = selectedDate.getDate() === meetDate.getDate();
        const sameMonth = selectedDate.getMonth() === meetDate.getMonth();
        const sameYear = selectedDate.getFullYear() === meetDate.getFullYear();
        const sameEveryDay = meetData.everyDay === meet.everyDay;
        const sameFromTime = meetData.from === meet.from;
        const sameToTime = meetData.to === meet.to;
        const [newMeetStart, newMeetEnd] = meetData.from.split(' - ');
        const [existingMeetStart, existingMeetEnd] = meet.from.split(' - ');

        const sameStart = newMeetStart === existingMeetStart;
        const sameEnd = newMeetEnd === existingMeetEnd;
        console.log('newMeetStart', newMeetStart, newMeetEnd, existingMeetStart, existingMeetEnd);
        console.log('sameStart', sameStart, sameEnd);
        const isValid = newMeetStart < existingMeetStart || newMeetStart >= existingMeetEnd;

        console.log('isValid', newMeetStart < existingMeetStart || newMeetStart >= newMeetEnd);
        if (sameStart || sameEnd || !isValid) conflict = true;
        return sameDate && sameMonth && sameYear && sameEveryDay && sameFromTime && sameToTime && sameStart && sameEnd;
      });
      console.log('conflictMeet', conflict);
      if (conflictingMeet || conflict) {
        throw new Error('Another meet already scheduled during this time');
      }

      // Make API call to create the meet
      const response = await API.graphql(graphqlOperation(createMeetQuery, { input: meetData }));
      toast.success('Meet scheduled successfully');
      navigate('/dashboard/schedule-meet');
      return response;
    } catch (error) {
      console.error('Error creating meet:', error);
      toast.error(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listMeets();
  }, []);

  return { meets, deleteMeetById, createMeet, loading };
};

export default useCreateMeet;
