import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container,Button, Stack, Typography } from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
// mock
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { CouponSort, CouponList, CouponCartWidget, CouponFilterSidebar, AddCouponSideBar } from '../sections/@dashboard/coupon';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader';
import useHandlePagintationCoupon from '../hooks/handlePaginationCoupons';
import { getAllCoupons,clearCouponsErrors} from "../redux/actions/CouponsAction";
import MainSkeleton from '../components/SkeletonLoader';

// ----------------------------------------------------------------------

export default function CouponPage() {
  const dispatch = useDispatch();

  // const {
  //   coupons,
  //   loading,
  //   deleted,
  //   updated,
  //   added,
  //   error,
  //   // productsCount,
  //   resultPerPage,
  //   // filteredProductsCount,
  // } = useSelector((state) => state.coupons);
  const [openFilter, setOpenFilter] = useState(false);
  const [openCoupon, setOpenCoupon] = useState(false);
  const {
    couponList,
    nextNextToken,
    loading,
    deleteCoupon,
    fetchNextPage,
    fetchCoupon,
    fetchPreviousPage,
    previousTokens,
    createDiscountCoupons ,
    updateCouponDetails,
  } = useHandlePagintationCoupon();
  const [coupons, setCouponList] = useState(couponList);

  const [isLoading, setIsLoading] = useState(false);
  // const { couponList, loading, error, fetchCoupons } = useCouponList();


  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleOpenCoupon = () => {
    setOpenCoupon(true);
  };
  const handleCloseCoupon = () => {
    setOpenCoupon(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  // useEffect(() => {
  //   if (error) {
  //     dispatch(clearCouponsErrors());
  //   }
  //   dispatch(getAllCoupons()); 
  // }, [error,deleted,updated,added]);

  useEffect(() => {
    setCouponList(couponList || []);
  }, [couponList]);

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };


  if(loading){
    return <Loader/>
  }   

  return (
    <>
      <Helmet>
        <title> Dashboard: Coupon | Minimal UI </title>
      </Helmet>

      <Container>
        <ToastContainer />
        <Typography variant="h4" sx={{ mb: 5 }}>
          Coupon
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            {/* <CouponFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <CouponSort />
            <br /> */}
            <AddCouponSideBar
              openCoupon={openCoupon}
              onCloseCoupon={handleCloseCoupon}
              onOpenCoupon={handleOpenCoupon}
              setLoading={setIsLoading}
              createDiscountCoupons ={createDiscountCoupons}
              // fetchCoupons={fetchCoupons}
            />
          </Stack>
        </Stack>

        {/* <CouponList coupons={coupons}
        //  fetchCoupons={fetchCoupons}
          /> */}
        {/* <CouponCartWidget /> */}
        {loading || isLoading ? (
          <>
            <MainSkeleton />
            
          </>
        ) : (
          <CouponList
            coupons={coupons}
            setCouponList={setCouponList}
            updateCouponDetails={updateCouponDetails}
            deleteCoupon={deleteCoupon}

            // checkAuthAndFetchData={checkAuthAndFetchData}
          />
        )}
        {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
            {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}
