import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as mutations from '../graphql/mutations'; // Import your GraphQL mutations

const useUpdateWorkWithUs = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  // const { id } = useParams();

  const updateSignUpWorkWithUs = async (newData) => {
    console.log('Data', newData);
    try {
      setIsUpdating(true);
      const response = await API.graphql({
        query: mutations.updateWorkWithUs,
        variables: { input: { ...newData, id: newData.id } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const updatedDetails = response?.data?.updateWorkWithUs; // Assuming your mutation returns the updated details
      setIsUpdating(false);
      toast.success('Work With Us Updated Successfully!');

      return updatedDetails;
    } catch (error) {
      console.error('Error updating Work With Us details:', error);
      setIsUpdating(false);
      toast.error('Something Went Wrong');

      throw error; // Propagate the error for handling in the component
    }
  };

  return { updateSignUpWorkWithUs, isUpdating };
};

export default useUpdateWorkWithUs;
