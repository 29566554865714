import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Container,
  Box,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useGetLeaguesPlayerDetails from '../../hooks/getLeaguesPlayerDetails';
import Loader from '../Loader';
import useDeleteLeaguePlayer from '../../hooks/deleteLeaguePlayer';

import useVerifyLeague from '../../hooks/verifyLeague';
import { clearPlayerErrors, deletePlayer, getPlayerDetails, verifyPlayer } from '../../redux/actions/PlayerAction';
import useHandlePagintationPlayers from '../../hooks/handlePaginationPlayer';

const League = () => {
  const { loading, deletePlayers } = useHandlePagintationPlayers();
  const {
    playerDetails,
    loading: loadigngPlayers,
    error: playerError,
    deleted,
    updated,
    success,
  } = useSelector((state) => state.player);

  // const { verifyLeaguePlayerDetails } = useVerifyLeague();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const [verifed,setIsVerified]

  // const { playerDetails?, loading } = useGetLeaguesPlayerDetails();
  const [isVerified, setIsVerified] = useState(playerDetails?.isVerified);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const navigate = useNavigate();

  const handleAccept = async () => {
    dispatch(verifyPlayer(id));
    dispatch(getPlayerDetails(id));
    setIsVerified(true);
  };

  const handleReject = async () => {
    if (playerDetails?.id) {
      console.log('id', playerDetails?.id); // Check if a row is selected
      // await deleteLeaguePlayerDetails(playerDetails?.id);
      setIsDeleteDialogOpen(false);
      await deletePlayers(playerDetails?.id);

      dispatch(getPlayerDetails(id));
      navigate('/dashboard/verifications');
      setIsVerified(true);

      // Use the selected row's id
    }
  };

  useEffect(() => {
    if (playerError) {
      dispatch(clearPlayerErrors());
    }
    if (id) {
      dispatch(getPlayerDetails(id));
    }

    console.log('Player', playerDetails);
  }, [id, isVerified, playerError]);

  const isDisabled = true;
  if (loading||loadigngPlayers) {
    return <Loader />;
  }

  return (
    <Container>
      <Box>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete User Details</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this User Details?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleReject} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container style={{ marginLeft: 1, marginTop: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box className="w-[100px] h-[100px] rounded-full overflow-hidden">
              <img
                src={playerDetails?.profileLink}
                alt={playerDetails?.participantName}
                className="w-full h-full object-cover"
              />
            </Box>

            <TextField
              label="Participant Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.participantName}
              disabled
            />
            <TextField
              label="City Of Registration"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.cityOfRegistration}
              disabled
            />
            <TextField
              label="Registered Team Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.teamName}
              disabled
            />
            <TextField
              label="Height"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.HEIGHT}
              disabled
            />
            <TextField
              label="Weight"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.WEIGHT}
              disabled
            />
            <TextField
              label="Years Played in HS"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.H_S}
              disabled
            />
            <TextField
              label="Years played as pro"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.NBA}
              disabled
            />
            <TextField
              label="Years Played in College"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.COLLEGE}
              disabled
            />
            <TextField
              label="Date of birth"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.dateOfBirth}
              disabled
            />
            <TextField
              label="Adult ?"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.isAdult}
              disabled
            />
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.email}
              disabled
            />
            <TextField
              label="Jersey Size"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.jerseySize}
              disabled
            />
            <TextField
              label="Member of Other Team"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.isMemberOfOtherTeam}
              disabled
            />
            <TextField
              label="School Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.schoolName}
              disabled
            />
            <TextField
              label="Grade"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.grade}
              disabled
            />
            <TextField
              label="Club Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.clubName}
              disabled
            />
            <TextField
              label="Team Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.teamName}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Secondary Emergency Contact Person Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.secondaryPersonName}
              disabled
            />
            <TextField
              label="Secondary Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.secondaryCallPhoneNumber}
              disabled
            />
            <TextField
              label="Secondary Email Address"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.secondaryEmergencyContactEmail}
              disabled
            />
            <TextField
              label="Primary Emergency Contact Person Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.primaryPersonName}
              disabled
            />
            <TextField
              label="Primary Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.primaryCallPhoneNumber}
              disabled
            />
            <TextField
              label="Primary Email Address"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.primaryEmergencyContactEmail}
              disabled
            />
            <TextField
              label="From Where You Heard About 3x3"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.heardAboutLeague}
              disabled
            />
            <TextField
              label="Health Conditions"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.healthConditions}
              disabled
            />
            <TextField
              label="Message"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.message}
              disabled
            />
            <TextField
              label="Parent First Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.parentFirstName}
              disabled
            />
            <TextField
              label="Parent Last Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.parentLastName}
              disabled
            />
            <TextField
              label="Parent Email Address"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.parentEmailAddress}
              disabled
            />
            <TextField
              label="Parents Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.parentPhoneNumber}
              disabled
            />
            <TextField
              label="Parents Business Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.parentBusinessPhoneNumber}
              disabled
            />
            <TextField
              label="Parents Home Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.parentHomePhoneNumber}
              disabled
            />
            <TextField
              label="Parents Country"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.parentCountry}
              disabled
            />
            <TextField
              label="Signature"
              fullWidth
              margin="normal"
              variant="outlined"
              value={playerDetails?.signature}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginLeft: 2 }}>
          <Typography variant="h5">Verification Documents</Typography>
          <ListItem>
            <ListItemIcon>
              <AttachFileIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={playerDetails?.document} />
            <ListItemIcon>
              <IconButton href={playerDetails?.documentLink} target="_blank" rel="noopener noreferrer" download>
                <VisibilityIcon color="primary" />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        </Grid>
      </Box>

      {!isVerified ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2} // Adjust the gap as needed for spacing between buttons
          marginTop={2} // Adjust the marginTop for top spacing
          marginBottom={2}
        >
          <Button
            variant="contained"
            onClick={handleAccept}
            style={{
              backgroundColor: 'green',
              color: 'white',
            }}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteClick}
            style={{
              backgroundColor: 'red',
              color: 'white',
            }}
          >
            Reject
          </Button>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2} // Adjust the gap as needed for spacing between buttons
          marginTop={2} // Adjust the marginTop for top spacing
          marginBottom={2}
        >
          <Typography
            variant="contained"
            disabled
            style={{
              color: 'green',
            }}
          >
            Verified
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default League;
