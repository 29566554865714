import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Box } from  '@mui/material';
import Partner from '../components/signup/Partner';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';

const EditPartner = () => (
    <Box display="flex" mb={5}>
      <DashboardLayout />
      <Box mt={10} width="100%">
        <Partner />
      </Box>
    </Box>
  );

export default EditPartner;
