import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Button, Typography } from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import MainSkeleton from '../components/SkeletonLoader';
import {
  EventSort,
  EventList,
  EventCartWidget,
  EventFilterSidebar,
  AddNewEventSideBar,
} from '../sections/@dashboard/camps';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader';
import useAddEvents from '../hooks/addEvents';
import useEventsList from '../hooks/getEventsList';
import useGetTeams from '../hooks/getTeamsList';
import { getAllEvents, clearEventsErrors } from '../redux/actions/CampEventsAction';
import useHandlePagintationCampEvents from '../hooks/handlePaginationCampEvents';

// ----------------------------------------------------------------------

export default function EventsPage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    eventsList,
    nextNextToken,
    loading,
    deleteEvents,
    fetchNextPage,
    fetchEvents,
    fetchPreviousPage,
    previousTokens,
    addEvent,
    updateEvents,
  } = useHandlePagintationCampEvents();


  const [openFilter, setOpenFilter] = useState(false);

  const [openEvent, setOpenEvent] = useState(false);
  const [events, setEvents] = useState(eventsList);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleOpenEvent = () => {
    setOpenEvent(true);
  };
  const handleCloseEvent = () => {
    setOpenEvent(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  useEffect(() => {
    setEvents(eventsList || []);
  }, [eventsList]);

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };
console.log("EVENTS",events)
  return (
    <>
      <Helmet>
        <title> Dashboard: Season 1 | Minimal UI </title>
      </Helmet>

      <Container>
        <ToastContainer />
        <Typography variant="h4" sx={{ mb: 5 }}>
        Camps
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <AddNewEventSideBar
              openEvent={openEvent}
              onCloseEvent={handleCloseEvent}
              onOpenEvent={handleOpenEvent}
              adddEvent={addEvent}
              // fetchEventsLists={fetchEventsLists}
            />
          </Stack>
        </Stack>
        {loading || isLoading ? (
          <>
            <MainSkeleton />
          </>
        ) : (
          <EventList
            events={events}
            updateEvents={updateEvents}
            deleteEvents={deleteEvents}

            //  fetchEventsLists={fetchEventsLists}
          />
        )}
        {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
           {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}
