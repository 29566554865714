import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries'; // Assuming queries for fetching carousel images are here
import * as mutations from '../graphql/mutations'; // Assuming mutations for updating/deleting carousel images are here
import getLink from 'src/utils/getLinks';
import deleteFile from 'src/utils/deleteFile';

const useHandlePaginationCarousel = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken] = useState(undefined);
  const fetchCarouselImages = async () => {
    setLoading(true);
    try {
      const variables = {
        limit: 10, // Fetch 10 images at a time
        nextToken,
      };

      const result = await API.graphql(graphqlOperation(queries.listCarouselImages, variables));
      console.log("result", result);

      const fetchedImages = result?.data?.listCarouselImages?.items || [];

      const fetchedImagesWithLinks = await Promise.all(
        fetchedImages.map(async (img) => {
          const url = await getLink(img.image); // Assume getLink is an async function
          return {
            id: img.id,
            ...img,
            imageKey: img.image, // the fetched URL
            imageLink: url, // Use the fetched URL
          };
        })
      );

      console.log("fetchedImages", fetchedImages);

      // Append the newly fetched images to the existing list without creating nested arrays
      setImages(fetchedImagesWithLinks); // Spread fetchedImagesWithLinks

      // Update the nextToken for pagination
      setNextToken(result?.data?.listCarouselImages?.nextToken);

      console.log("Images", images);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching carousel images:', error);
    }
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const addImage = async (data) => {
    setLoading(true);
    const {text,newImageUrl}=data;
    try {
      const result = await API.graphql(
        graphqlOperation(mutations.createCarouselImages, {
          input: { image: newImageUrl,text }, // Update to match your schema
        })
      );

      const newImage = result.data.createCarouselImages;
      setImages((prevImages) => [...prevImages, newImage]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error adding image to carousel:', error);
    }
  };

  const updateImage = async (data) => {
    const {id, newImageUrl,text,newImage}=data;
    console.log("Updated Data",data)
    setLoading(true);
    try {
      const existingImage = await API.graphql(
        graphqlOperation(queries.getCarouselImages, { id })
      );

      const oldImageUrl = existingImage.data.getCarouselImages.image;

      // Delete the old image from S3
      if (oldImageUrl&&newImageUrl) {
        await deleteFile(oldImageUrl); // Assume deleteFileFromS3 is a function to delete the image from S3
      }

      const result = await API.graphql(
        graphqlOperation(mutations.updateCarouselImages, {
          input: {
            id: id,
            image: newImageUrl?newImageUrl:oldImageUrl,
            text
          }
        })
      );

      const updatedImage = result.data.updateCarouselImages;

      // Update the image in the state by replacing the old one with the updated one
      setImages((prevImages) =>
        prevImages.map((img) =>
          img.id === id ? { ...img, imageLink: updatedImage.imageLink } : img
        )
      );

    } catch (error) {
      console.error('Error updating image in carousel:', error);
    } finally {
      setLoading(false);

    }
  };

  const deleteImage = async (id) => {
    setLoading(true);
    try {
      // Get the carousel image details by ID
      const car = await API.graphql(
        graphqlOperation(queries.getCarouselImages, {
          id: id // Pass the id directly without wrapping in 'input'
        })
      );

      const imageLink = car?.data?.getCarouselImages?.imageLink;

      // If imageLink exists, delete the file from storage
      if (imageLink) {
        await deleteFile(imageLink);
      }

      // Delete the image record from the database
      const result = await API.graphql(
        graphqlOperation(mutations.deleteCarouselImages, {
          input: { id: id } // Delete the image entry by its ID
        })
      );

      const deletedImage = result.data.deleteCarouselImages;

      // Remove the deleted image from the local state
      setImages((prevImages) => prevImages.filter((img) => img.id !== deletedImage.id));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error deleting image from carousel:', error);
    }
  };


  useEffect(() => {
    fetchCarouselImages();
  }, [nextToken]);

  return {
    images,
    currentIndex,
    nextImage,
    previousImage,
    loading,
    addImage,
    updateImage,
    fetchCarouselImages,
    deleteImage
  };
};

export default useHandlePaginationCarousel;
