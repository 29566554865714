import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import NewsPage from './pages/NewsPage';

import UserPage from './pages/UserPage';
import Player from './pages/Player';
import AllUsers from './pages/AllUsers';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import AdvertisementPage from './pages/AdvertisementPage';
import EventsPage from './pages/EventsPage';
import Events5x5Page from './pages/Events5x5Page';
import TournamentEvents from './pages/TournamentEvents';
import CampEvents from './pages/CampEvents';
import DashboardAppPage from './pages/DashboardAppPage';
import EditPost from './pages/EditPostPage';
import AddDetailsPage from './pages/AddDetailsPage';
import Add5x5DetailsPage from './pages/Add5x5DetailsPage';
import TournamentTeam from './pages/TournamentTeam';
import EditPlayer from './pages/EditPlayers';
import EditPlayer5x5 from './pages/EditPlayer5x5s';
import EditCamp from './pages/EditCamps';
import EditClinic from './pages/EditClinics';
import EditTournament from './pages/EditTournaments';
import EditTrainingProgram from './pages/EditTrainingPrograms';
import EditVerification from './pages/EditVerification';
import Edit5x5Verification from './pages/Edit5x5Verification';
import EditTournamentVerification from './pages/EditTournamentVerification';
import EditUserPage from './pages/EditUserPage';
import VerificationsPage from './pages/Verification';
import Verifications5x5Page from './pages/Verification5x5';
import TournamentVerification from './pages/TournamentVerification';
import OrderPage from './pages/OrderPage';
import ScorePage from './pages/ScorePage';
import Score5x5Page from './pages/Score5x5Page';
import NewsletterEmailListPage from './pages/NewsletterEmailListPage';
import People from './pages/People';
import EditVolunteer from './pages/EditVolunteers';
import CouponPage from './pages/CouponPage';
import Session from './pages/Session';
import Category from './pages/Category';
import EditSignUpEvent from './pages/EditSignupEvents';
import EditWorkWithUs from './pages/EditWorkWithUs';
import EditPartner from './pages/EditPartners';
import AuthRedirect from './components/AuthRedirect';
import Event2 from './pages/Event2';
import LeagueSeason2 from './pages/LeagueSeason2';
import League5x5Season2 from './pages/League5x5Season2';
import TournamentEvent2 from './pages/TournamentEvent2';
import TournamentScore from './pages/TournamentScore';
import ClinicCardsPage from './pages/ClinicCardsPage';
import Activities from './pages/Activities';
import TrainingProgramCardsPage from './pages/TrainingProgramCardsPage';
import CampCardsPage from './pages/CampCardsPage';
import Course from './pages/Course';
import ClinicCourse from './pages/ClinicCourse';
import CampCourse from './pages/CampCourse';
import AddPricesPage from './pages/AddPricesPage';
import WriteNews from './pages/WriteNews';
import EditClinicCard from './pages/EditClinicCard';
import EditActivity from './pages/EditActivity';
import EditTrainingProgramCard from './pages/EditTrainingProgramCard';
import EditCampCard from './pages/EditCampCard.js';
import ScheduleMeet from './pages/ScheduleMeet';
import ScheduleClinicMeet from './pages/ScheduleClinicMeet';
import ScheduleCampMeet from './pages/ScheduleCampMeet';
import NewMeet from './pages/NewMeet';
import UserMeet from './pages/UserMeet';
import NewClinicMeet from './pages/NewClinicMeet';
import NewCampMeet from './pages/NewCampMeet';
import MockData from './pages/MockData';
import Tutor from './pages/Tutor';
import MeetScheduler from './pages/MeetScheduler';
import NewMeetSecond from './pages/NewMeetSecond';
import TournamentDetails from './pages/TournamentDetails';
import CampDetails from './pages/CampDetails';
import GenerateTournamentJerseyNo from './pages/GenerateTournamentJerseyNo';
import GenerateLeagueJerseyNo from './pages/GenerateLeagueJerseyNo';
import GenerateLeagueJerseyNo5x5 from './pages/GenerateLeagueJerseyNo5x5';
import CarouselPage from './pages/Carousel';
import ComptetiveTeams from './pages/comptetive/ComptetiveTeams';
import Schedule from './pages/schedule/Schedule';
import Comptetive5x5Teams from './pages/comptetive5x5/Comptetive5x5Teams';
import ComptetiveCoach from './pages/comptetive/ComptetiveCoach';
import Comptetive5x5Coach from './pages/comptetive5x5/Comptetive5x5Coach';
import ComptetiveInvite from './pages/comptetive/ComptetiveInvite';
import Comptetive5x5Invite from './pages/comptetive5x5/Comptetive5x5Invite';
import ComptetiveNotInvited from './pages/comptetive/ComptetiveNotInvited';
import Comptetive5x5NotInvited from './pages/comptetive5x5/Comptetive5x5NotInvited';
import ComptetivePlayersDetails from './pages/comptetive/ComptetivePlayersDetails';
import ComptetiveInvitedPlayersDetails from './pages/comptetive/ComptetiveInvitedPlayersDetails';
import Comptetive5x5InvitedPlayersDetails from './pages/comptetive5x5/Comptetive5x5InvitedPlayersDetails';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <AuthRedirect children={<DashboardLayout />} />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'players', element: <Player /> },
        { path: 'all-users', element: <AllUsers /> },
        { path: 'carousel', element: <CarouselPage /> },
        { path: 'verifications', element: <VerificationsPage /> },
        { path: '5x5verifications', element: <Verifications5x5Page /> },
        { path: 'tournament-verifications', element: <TournamentVerification /> },
        { path: 'orders', element: <OrderPage /> },
        { path: 'scores', element: <ScorePage /> },
        { path: '5x5scores', element: <Score5x5Page /> },
        { path: 'meet', element: <MeetScheduler /> },

        { path: 'products', element: <ProductsPage /> },
        { path: 'advertisement', element: <AdvertisementPage /> },
        { path: 'mock-data', element: <MockData /> },
        { path: 'events', element: <EventsPage /> },
        { path: '5x5events', element: <Events5x5Page /> },
        { path: 'tournament-events', element: <TournamentEvents /> },
        { path: 'camps', element: <CampEvents /> },
        { path: 'tournament-details', element: <TournamentDetails /> },
        { path: 'camp-details', element: <CampDetails /> },
        { path: 'tournament-jersey-details', element: <GenerateTournamentJerseyNo /> },
        { path: 'league-jersey-details', element: <GenerateLeagueJerseyNo /> },
        { path: '5x5league-jersey-details', element: <GenerateLeagueJerseyNo5x5 /> },
        { path: 'clinic-cards', element: <ClinicCardsPage /> },
        // { path: 'clinic-activities', element: <ClinicActivitiesPage /> },
        // { path: 'player-activities', element: <PlayerActivities /> },
        { path: 'activities', element: <Activities /> },
        { path: 'training-program-cards', element: <TrainingProgramCardsPage /> },
        { path: 'training-program-courses', element: <Course /> },
        { path: 'camp-cards', element: <CampCardsPage /> },
        { path: 'camp-courses', element: <CampCourse /> },
        { path: 'clinic-courses', element: <ClinicCourse /> },
        { path: 'add-prices', element: <AddPricesPage /> },
        { path: 'event2', element: <Event2 /> },
        { path: 'season2', element: <LeagueSeason2 /> },
        { path: '5x5season2', element: <League5x5Season2 /> },
        { path: 'tournament-event2', element: <TournamentEvent2 /> },
        { path: 'tournament-score', element: <TournamentScore /> },
        { path: 'people', element: <People /> },
        { path: 'newsletter-listing', element: <NewsletterEmailListPage /> },
        { path: 'schedule-meet', element: <ScheduleMeet /> },
        // { path: 'schedule-clinic-meet', element: <ScheduleMeet /> },
        { path: 'schedule-clinic-meet', element: <ScheduleClinicMeet /> },
        { path: 'schedule-camp-meet', element: <ScheduleCampMeet /> },
        { path: 'news', element: <NewsPage /> },
        { path: 'add-details', element: <AddDetailsPage /> },
        { path: '5x5add-details', element: <Add5x5DetailsPage /> },
        { path: 'tournament-teams', element: <TournamentTeam /> },
        { path: 'categories', element: <Category /> },
        { path: 'tutor', element: <Tutor /> },
        {
          path: 'news/:id',
          element: <EditPost />,
        },
        {
          path: 'appointments/clinic',
          element: <UserMeet />,
        },
        {
          path: 'appointments/training-program',
          element: <UserMeet />,
        },
        {
          path: 'comptetive',
          element: <ComptetiveTeams />,
        },
        {
          path: 'comptetive-coach',
          element: <ComptetiveCoach />,
        },
        {
          path: 'comptetive/players',
          element: <ComptetivePlayersDetails />,
        }, 
        {
          path: 'comptetive/invited-players',
          element: <ComptetiveInvitedPlayersDetails />,
        }, 
         {
          path: 'comptetive/invite',
          element: <ComptetiveInvite />,
        }, 
        {
          path: 'comptetive/not-invited',
          element: <ComptetiveNotInvited />,
        },
        {
          path: '5x5comptetive',
          element: <Comptetive5x5Teams />,
        },
        {
          path: 'schedule',
          element: <Schedule />,
        },
        {
          path: '5x5comptetive-coach',
          element: <Comptetive5x5Coach />,
        },
        {
          path: '5x5comptetive/players',
          element: <ComptetivePlayersDetails />,
        }, 
        {
          path: '5x5comptetive/invited-players',
          element: <Comptetive5x5InvitedPlayersDetails />,
        }, 
         {
          path: '5x5comptetive/invite',
          element: <Comptetive5x5Invite />,
        }, 
        {
          path: '5x5comptetive/not-invited',
          element: <Comptetive5x5NotInvited />,
        },
        {
          path: 'appointments/camp',
          element: <UserMeet />,
        },
        { path: 'coupon', element: <CouponPage /> },
        { path: 'session', element: <Session /> },
      ],
    },
    { path: '/EditTrainingProgramCard/:id', element: <AuthRedirect children={<EditTrainingProgramCard />} /> },
    { path: '/edit-camp-card/:id', element: <AuthRedirect children={<EditCampCard />} /> },
    { path: '/edit-clinic-card/:id', element: <AuthRedirect children={<EditClinicCard />} /> },
    { path: '/edit-clinic-card/', element: <AuthRedirect children={<EditClinicCard />} /> },
    { path: '/edit-activities/:id', element: <AuthRedirect children={<EditActivity />} /> },
    { path: '/edit-activities/', element: <AuthRedirect children={<EditActivity />} /> },
    { path: '/dashboard/meet/new-meet', element: <NewMeetSecond /> },

    { path: '/dashboard/meet/new-meet/:id', element: <AuthRedirect children={<NewMeetSecond />} /> },
    { path: '/dashboard/meet/newClinicMeet/', element: <AuthRedirect children={<NewClinicMeet />} /> },
    { path: '/dashboard/meet/newClinicMeet/:id', element: <AuthRedirect children={<NewClinicMeet />} /> },
    { path: '/dashboard/meet/newCampMeet/', element: <AuthRedirect children={<NewCampMeet />} /> },
    { path: '/dashboard/meet/newCampMeet/:id', element: <AuthRedirect children={<NewCampMeet />} /> },
    { path: '/EditTrainingProgramCard/', element: <AuthRedirect children={<EditTrainingProgramCard />} /> },
    { path: '/edit-camp-card/', element: <AuthRedirect children={<EditCampCard />} /> },
    { path: '/create-new/', element: <AuthRedirect children={<WriteNews />} /> },
    { path: '/create-new/:id', element: <AuthRedirect children={<WriteNews />} /> },
    { path: ':id', element: <AuthRedirect children={<EditUserPage />} /> },
    { path: '/player/:id', element: <AuthRedirect children={<EditPlayer />} /> },
    { path: '/player5x5/:id', element: <AuthRedirect children={<EditPlayer5x5 />} /> },
    { path: '/camp/:id', element: <AuthRedirect children={<EditCamp />} /> },
    { path: '/clinic/:id', element: <AuthRedirect children={<EditClinic />} /> },
    { path: '/tournament/:id', element: <AuthRedirect children={<EditTournament />} /> },
    { path: '/event/:id', element: <AuthRedirect children={<EditSignUpEvent />} /> },
    { path: '/workwithUs/:id', element: <AuthRedirect children={<EditWorkWithUs />} /> },
    { path: '/partner/:id', element: <AuthRedirect children={<EditPartner />} /> },

    { path: '/training-program/:id', element: <AuthRedirect children={<EditTrainingProgram />} /> },
    { path: '/player/verification/:id', element: <AuthRedirect children={<EditVerification />} /> },
    { path: '/player5x5/verification/:id', element: <AuthRedirect children={<Edit5x5Verification />} /> },
    { path: '/tournament/verification/:id', element: <AuthRedirect children={<EditTournamentVerification />} /> },
    {
      path: '/volunteer/:id',
      element: <AuthRedirect children={<EditVolunteer />} />,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },

    {
      path: 'login',
      element: <LoginPage />,
    },
  ]);

  return routes;
}
