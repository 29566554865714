import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { toast } from 'react-toastify';

import WordEditor from '../components/blog/Write';
import ShowPreview from '../components/blog/ShowPreview';
import useHandlePagintationClinicCards from '../hooks/handlePaginationClinicCards';
// import useHandleNews from '../hooks/handlePaginationNews'; // Commented out
import Loader from '../components/Loader';

const EditClinicCard = () => {
  const [content, setContent] = useState('');
  const [preview, setPreview] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [price, setPrice] = useState();
  const [order, setOrder] = useState();
  const [image, setImages] = useState([]);

  const [title, setTitle] = useState();
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const {
    clinicCardsList,
    nextNextToken,
    loading,
    deleteClinicCards,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
    addClinicCard,
    updateClinicCards,
    clinicCard,
  } = useHandlePagintationClinicCards();

  const handleWrite = (newContent) => {
    setContent(newContent);
  };

  /*
  const handleNewsTypeChange = (event) => {
    setNewsType(event.target.value);
  };

*/
  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    setShowCancelDialog(false);
    // Redirect to home page
    // history.push('/dashboard/news');
    navigate('/dashboard/clinic-cards');
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
  };
  const handleSave = async () => {
    try {
      if (!title) {
        toast.error('Title is required.');
        return;
      }

      // Check if price is empty
      if (!price) {
        toast.error('Price is required.');
        return;
      }
      // Check if price is empty
      if (!content) {
        toast.error('Content is required.');
        return;
      }
      // Check if price is empty
      if (!order) {
        toast.error('Order is required.');
        return;
      }
      if (id) {
        const data = {
          title,
          description: content,
          price,
          order,
          id,
        };

        // Save the news content with updated image sources
        await updateClinicCards(data);
      } else {
        const data = {
          title,
          description: content,
          price,
          order,
        };

        // Save the news content with updated image sources
        await addClinicCard(data);
      }

      navigate('/dashboard/clinic-cards');
      console.log('Save functionality executed successfully');
    } catch (error) {
      console.error('Error during handleSave:', error);
    }
  };



  useEffect(() => {
    if (id) {
      setPrice(clinicCard?.price);
      setOrder(clinicCard?.order);
      setTitle(clinicCard?.title);
      setContent(clinicCard?.description);
    }
  }, [id, clinicCard]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box className="flex flex-col gap-[25px] py-2 px-2 w-full max-w-[1280px] mx-auto h-full">
      <Box className="flex gap-2 justify-between items-center">
        <Box className="flex gap-2 items-center">
          <button
            className={`border transition-all ease-in-out duration-300  text-[16px]  font-bold ${!preview
                ? 'bg-blue-500  hover:bg-blue-600  text-white'
                : 'border text-blue-500   border-blue-400 hover:bg-blue-200'
              } text-black p-1 px-3 rounded-lg`}
            onClick={() => setPreview(false)}
          >
            Edit
          </button>
          <button
            className={`border transition-all text-[16px] ease-in-out duration-300  font-bold ${preview
                ? 'bg-blue-500 hover:bg-blue-600 rounded-lg text-white'
                : 'border text-blue-500   border-blue-400 hover:bg-blue-200'
              } text-black p-1 rounded-lg px-3`}
            onClick={() => setPreview(true)}
          >
            Preview
          </button>
        </Box>
        <Box className="flex gap-2 items-center">
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="text" color="secondary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            autoFocus
            margin="dense"
            label="Order"
            fullWidth
            value={order}
            onChange={(e) => setOrder(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            autoFocus
            margin="dense"
            label="Price"
            fullWidth
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Grid>
      </Grid>

      <Box className="flex justify-between w-full mt-2">
        {preview ? (
          <ShowPreview content={content} title={title} />
        ) : (
          <WordEditor content={content} image={image} setImages={setImages} setContent={handleWrite} />
        )}
      </Box>
      <Dialog open={showCancelDialog} onClose={handleCancelDialogClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>If you cancel, your changes will be lost. Do you really want to cancel?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="primary">
            <NavLink to="/dashboard/clinic-cards">Yes</NavLink>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditClinicCard;
