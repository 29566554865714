const ConfirmationDialog = ({ message, onConfirm, onCancel }) => {
    return (
      <div className="fixed inset-0 z-[10000] flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-5 rounded-md">
          <p>{message}</p>
          <div className="mt-3 flex justify-end">
            <button onClick={onCancel} className="mr-3 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md">
              Cancel
            </button>
            <button onClick={onConfirm} className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md">
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default ConfirmationDialog;