export const getKey = (url) => {
  console.log("URL", url);
  const urlObject = new URL(url);
  const pathname = urlObject.pathname;
  const prefix = 'public/';
  const startIndex = pathname.indexOf(prefix);

  if (startIndex !== -1) {
    return decodeURIComponent(pathname.substring(startIndex + prefix.length));
  }

  return '';
};

