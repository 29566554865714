import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';

const buttonStyles = {
  backgroundColor: '#1E90FF', // Set your desired background color
  color: 'white', // Set your desired text color
};

const ModalOne = ({  title, 
  handleClose, 
  handleImageUpload, 
  handleInputChange, 
  handleSelectChange, 
  selectedAgeGroup, 
  handleAdd, 
  buttonText, 
  inputLabel, 
  options, 
  showTournamentDropdown,
  tournamentOptions,
  selectedTournament,
  handleTournamentChange,
  tournamentSelectLabel,
  showCityDropdown,
  selectedCity,
  cityOptions,
  handleCityChange
 }) => {
    console.log('tournamentOptions',tournamentOptions)
  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={inputLabel}
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="select-label">Age</InputLabel>
              <Select
                labelId="select-label"
                label="Age"
                value={selectedAgeGroup}
                onChange={handleSelectChange}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="" >Select Age</MenuItem>
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {showCityDropdown && (
              <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <InputLabel id="select-label">City</InputLabel>
                <Select
                  labelId="select-label"
                  label="City"
                  value={selectedCity}
                  onChange={handleCityChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="" >Select City</MenuItem>
                  {cityOptions.map((option, index) => (
                    <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
        )}
          {showTournamentDropdown && (
            <Grid item xs={12}>
             
              <FormControl fullWidth margin="dense">
                <InputLabel id="select-tournament-label">{tournamentSelectLabel}</InputLabel>
                <Select
                labelId="select-tournament-label"
                label="Age"
                  value={selectedTournament}
                  onChange={handleTournamentChange}
                  fullWidth
                  variant="outlined"
                >
                  {tournamentOptions.map((option) => (
                    <MenuItem key={option?.id} value={option?.id}>{option?.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: '16px' }}>
        <Button onClick={handleClose} variant="outlined" color="secondary" style={{ marginRight: 'auto' }}>
          Cancel
        </Button>
        <Button onClick={handleAdd} style={buttonStyles} color="primary" variant="contained">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalOne;
