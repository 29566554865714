import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import CouponCard from './CouponCard';
import Loader from '../../../components/Loader';

// ----------------------------------------------------------------------

CouponList.propTypes = {
  coupons: PropTypes.array.isRequired,
};

// export default function CouponList({ couponList, fetchCoupons, ...other }) {
  export default function CouponList({ updateCouponDetails,deleteCoupon, coupons,setCouponList, ...other  }) {
 
  return (
    <Grid container spacing={3} {...other}>
      {coupons?.map((coupon) => (
        <Grid key={coupon?.id} item xs={12} sm={6} md={3}>
         
          <CouponCard deleteCoupon={deleteCoupon}  updateCouponDetails={updateCouponDetails} coupon={coupon} setCouponList={setCouponList}   />
        </Grid>
      ))}
    </Grid>
  );
}
