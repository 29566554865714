import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  TextField,
  Input,
  Box,
} from '@mui/material';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import CustomInput from '../../../components/input/Input';
import CustomImageInput from '../../../components/input/ImageUploader';

import * as mutations from '../../../graphql/mutations';

import * as queries from '../../../graphql/queries';
import useAddItems from '../../../hooks/addItems';
import useUpdateProductDetails from '../../../hooks/updateProduct';
import Loader from '../../../components/Loader';
import { createProduct, updateProduct, getAllProducts } from '../../../redux/actions/ProductAction';

AddNewProductSideBar.propTypes = {
  openProduct: PropTypes.bool,
  onOpenProduct: PropTypes.func,
  onCloseProduct: PropTypes.func,
};

export default function AddNewProductSideBar({
  openProduct,
  onOpenProduct,
  onCloseProduct,
  editData,
  updateProductDetails,
  createItems,
  hidden=false
  // checkAuthAndFetchData,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');

  const [sellingPrice, setSellingPrice] = useState('');
  const [originalPrice, setOriginalPrice] = useState(''); // Add state for original price
  const [stockQty, setStockQty] = useState(0); // Add state for stock quantity
  const [ratings, setRatings] = useState('');

  const [tags, setTags] = useState([]);
  const [image, setImage] = useState('');
  const [oldImage, setOldImage] = useState('');

  const [file, setFile] = useState(null);
  const [productId, setProductId] = useState(null);

  const isEditMode = !!editData; // Check if editData is present

  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      setTitle(editData.title || '');
      setSellingPrice(editData.selling_price || '');
      setOriginalPrice(editData.original_price || ''); // Set original price
      setStockQty(editData.stock_qty || 0); // Set stock quantity
      setRatings(editData.rating || '');
      setTags(editData.tags || '');
      setImage(editData.img_url || '');
      setOldImage(editData.img_url || '');

      setFile(editData.file || '');
      setProductId(editData.id || '');

      // Set other fields accordingly
    }
  }, [editData, isEditMode]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSellingPriceChange = (event) => {
    setSellingPrice(event.target.value);
  };

  const handleOriginalPriceChange = (event) => {
    setOriginalPrice(event.target.value);
  };

  const handleStockQtyChange = (event) => {
    setStockQty(event.target.value);
  };

  const handleRatingsChange = (event) => {
    setRatings(event.target.value);
  };

  const handleTagsChange = (event) => {
    const tagss = event.target.value;
    setTags([tagss]);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    setImage(file);
  };
  const handleFileChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setFile(selectedImage);
      setImage(selectedImage);
    } else {
      setFile(null);
    }
  };
  const handleUploadData = async () => {
    try {
      if (!title) {
        toast.error('Title is required.');
        return;
      }

      if (!sellingPrice) {
        toast.error('Selling Price is required.');
        return;
      }

      if (!originalPrice) {
        toast.error('Original Price is required.');
        return;
      }

      if (!stockQty) {
        toast.error('Stock Quantity is required.');
        return;
      }

      if (!ratings) {
        toast.error('Ratings is required.');
        return;
      }

      if (!tags) {
        toast.error('Tags is required.');
        return;
      }

      if (!image && !isEditMode) {
        toast.error('Image is required.');
        return;
      }


      const newItem = {
        title,
        selling_price: parseFloat(sellingPrice),
        original_price: parseFloat(originalPrice), // Include original price
        stock_qty: parseInt(stockQty, 10), // Include stock quantity
        // eslint-disable-next-line radix
        rating: parseInt(ratings),

        tags,
        img_url: image,
      };
      // console.log('file', file);
      onCloseProduct();

      if (isEditMode) {
        // Set loading state for update
        // dispatch(updateProduct(productId,newItem,oldImage));
        const response = await updateProductDetails(productId, newItem, oldImage);
       
        // const response = await updateProductDetails(productId, newItem, oldImage);
      } else {
        const response = await createItems(newItem);
      
      }
      setTitle('');
      setSellingPrice('');
      setOriginalPrice('');
      setStockQty('');
      setRatings('');
      setTags('');
      setImage('');
    } catch (error) {
      console.error('Error creating item:', error);

      throw new Error(error);
    }
  };
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleUploadData();
    }
  };

  return (
    <>
      <Button
        disableRipple
        hidden={hidden}
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenProduct}
        style={buttonStyles}
      >
        Add Products
      </Button>

      <Dialog open={openProduct} onClose={onCloseProduct}>
        <DialogTitle>{isEditMode ? 'Edit Product' : 'Add New Product'}</DialogTitle>

        <DialogContent>
          <Stack direction={'column'} sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontSize: '14px',
                color: 'gray',
                wordSpacing: '3px',
                fontWeight: 'medium',
              }}
            >
              For Adding New Product Fill Title, Rating, Price, Tag, and Image.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <TextField label="Title" type="text" required fullWidth value={title} onChange={handleTitleChange} />
            <TextField
              label="Selling Price"
              type="number"
              required
              fullWidth
              value={sellingPrice}
              onKeyPress={handleKeyPress}
              onChange={handleSellingPriceChange}
            />
            <TextField
              label="Original Price"
              type="number"
              required
              fullWidth
              value={originalPrice}
              onKeyPress={handleKeyPress}
              onChange={handleOriginalPriceChange}
            />
            <TextField
              label="Stock Quantity"
              type="number"
              required
              fullWidth
              value={stockQty}
              onKeyPress={handleKeyPress}
              onChange={handleStockQtyChange}
            />
            <TextField
              label="Ratings"
              type="number"
              required
              fullWidth
              value={ratings}
              onKeyPress={handleKeyPress}
              onChange={handleRatingsChange}
            />
            <TextField
              label="Tags"
              type="text"
              fullWidth
              value={tags}
              onKeyPress={handleKeyPress}
              onChange={handleTagsChange}
            />
            {/* <TextField label="Image Url" type="text" fullWidth value={image} onChange={handleImageChange} /> */}
            <Input
              type="file"
              id="file"
              sx={{ marginTop: 2 }}
              label="Upload Image"
              variant="outlined"
              fullWidth
              accept="image/*"
              onKeyPress={handleKeyPress}
              onChange={(e) => handleFileChange(e)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={onCloseProduct} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button
                  onKeyPress={handleKeyPress}
                  onClick={handleUploadData}
                  style={{ backgroundColor: 'green', color: 'white' }}
                >
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
