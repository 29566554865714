import {
  CREATE_ADDPRICE_REQUEST,
  CREATE_ADDPRICE_SUCCESS,
  CREATE_ADDPRICE_FAIL,
  UPDATE_ADDPRICE_REQUEST,
  UPDATE_ADDPRICE_SUCCESS,
  UPDATE_ADDPRICE_FAIL,
  DELETE_ADDPRICE_REQUEST,
  DELETE_ADDPRICE_SUCCESS,
  DELETE_ADDPRICE_FAIL,
  GET_ADDPRICE_REQUEST,
  GET_ADDPRICE_SUCCESS,
  GET_ADDPRICE_FAIL,
  CLEAR_ADDPRICE_ERRORS,
  ALL_ADDPRICE_REQUEST,
  ALL_ADDPRICE_SUCCESS,
  ALL_ADDPRICE_FAIL,
} from '../constants/AddPricesConstants';

const initialState = {
  addPrices: [],
  addPriceCount: 0,
  resultPerPage: 0,
  addPrice: {},
  isUpdated:false,
  loading: false,
  error: null,
  filteredAddPricesCount: 0,
};

export const addPricesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_ADDPRICE_REQUEST:
    case GET_ADDPRICE_REQUEST:
    case CREATE_ADDPRICE_REQUEST:
    case DELETE_ADDPRICE_REQUEST:
    case UPDATE_ADDPRICE_REQUEST:
      return {
        ...state,
        loading: true,
        addPrices: [],
        addPrice: {},
      };
    case ALL_ADDPRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        addPrices: action.payload.addPrices,
        addPriceCount: action.payload.addPricesCount,
        resultPerPage: action.payload.resultPerPage,
        filteredAddPricesCount: action.payload.filteredAddPricesCount,
      };
    case CREATE_ADDPRICE_SUCCESS:
    case GET_ADDPRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        addPrice: action.payload,
      };
    case DELETE_ADDPRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_ADDPRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case GET_ADDPRICE_FAIL:
    case ALL_ADDPRICE_FAIL:
    case CREATE_ADDPRICE_FAIL:
    case UPDATE_ADDPRICE_FAIL:
    case DELETE_ADDPRICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ADDPRICE_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



