import React from 'react';
import { Box } from  '@mui/material';
import WorkWithUs from '../components/signup/WorkWithUs';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';

const EditWorkWithUs = () => (
    <Box display="flex" mb={5}>
      <DashboardLayout />
      <Box mt={10} width="100%">
        <WorkWithUs />
      </Box>
    </Box>
  );

export default EditWorkWithUs;
