import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Tabs, Tab, Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTeam, getAllTeams, clearTeamErrors } from '../redux/actions/Team5x5Action';
import { createCity, getAllCity, clearCityErrors } from '../redux/actions/CityAction';
import Details from '../components/DetailsPage/Details';
import Loader from '../components/Loader';
import ModalOne from '../components/ModalOne';
import ModalTwo from '../components/ModalTwo';

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

export default function Add5x5DetailsPage() {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [cityName, setCityName] = useState('');
  const [location, setLocation] = useState('');
  const [teamName, setTeamName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [agegroup, setAgegroup] = useState('');

  const teams = useSelector((state) => state.team5x5s.team5x5s);
  const cities = useSelector((state) => state.city.cities);
  const loading = useSelector((state) => state.team5x5s.loading || state.city.loading);
  const error = useSelector((state) => state.team5x5s.error || state.city.error);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleAgeGroup = (e) => {
    setAgegroup(e.target.value);
  };

  const handleShowModals = () => {
    setShowModal(true);
  };

  const handleCloseModals = () => {
    setShowModal(false);
  };

  const addTeams = () => {
    if (!teamName.trim()) {
      toast.error('Team name is required');
      return;
    }
    if (!selectedImage) {
      toast.error('Image is required');
      return;
    }
    if (!agegroup) {
      toast.error('Age is required');
      return;
    }

    if (!location) {
      toast.error('City is required');
      return;
    }

    const obj = { name: teamName, imageURL: selectedImage, agegroup,location };
    dispatch(createTeam(obj))
      .then(() => dispatch(getAllTeams()))
      .then(() => {
        handleCloseModals();
        setTeamName('');
        setLocation('');
        setAgegroup('');
        setSelectedImage('');
      })
      .catch((error) => {
        console.error('Error adding team:', error);
      });
  };

  const addCity = () => {

    if (!cityName.trim()) {
      toast.error('City is required');
      return;
    }

    const data = { name: cityName };
    dispatch(createCity(data))
      .then(() => dispatch(getAllCity()))
      .then(() => {
        handleCloseModals();
        setCityName('');
      })
      .catch((error) => {
        console.error('Error adding city:', error);
      });

  };

  useEffect(() => {
    if (error) {
      dispatch(clearTeamErrors());
      dispatch(clearCityErrors());
    }
    dispatch(getAllTeams());
    dispatch(getAllCity());
  }, [error, dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <ToastContainer />
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="5x5 Teams" />
        <Tab label="City" />
      </Tabs>
      <Box hidden={tabValue !== 0}>
        <Details label={'5x5 Teams'} addDetails={handleShowModals} data={teams} />
      </Box>
      <Box hidden={tabValue !== 1}>
        <Details label={'City'} addDetails={handleShowModals} data={cities} />
      </Box>
      {showModal && tabValue === 0 && (
        <ModalOne
          title="Add Team"
          handleClose={handleCloseModals}
          handleImageUpload={handleImageUpload}
          handleInputChange={(e) => setTeamName(e.target.value)}
          handleSelectChange={handleAgeGroup}
          selectedAgeGroup={agegroup}
          handleAdd={addTeams}
          buttonText="Add Team"
          inputLabel="Team Name"
          options={ages}
          showTournamentDropdown={false} // Pass true to show tournament dropdown
          tournamentOptions={null} // Pass events data for the tournament dropdown
          selectedTournament={null} // Pass selected tournament value if needed
          handleTournamentChange={null} // Pass handler for tournament dropdown change event
          tournamentSelectLabel="" // Pass label for the tournament dropdown
          showCityDropdown={true}
          selectedCity={location}
          cityOptions={cities}
          handleCityChange={(e) => setLocation(e.target.value)}
     
        />
      )}
      {showModal && tabValue === 1 && (
        <ModalTwo
          title="Add City"
          handleClose={handleCloseModals}
          handleInputChange={(e) => setCityName(e.target.value)}
          handleAdd={addCity}
          buttonText="Add City"
          inputLabel="City Name"
        />
      )}
    </Container>
  );
}