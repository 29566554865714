import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  TextareaAutosize,
  Grid,
  Box,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import useGetAllCity from '../../hooks/getCityList';

import useSignUpEventById from '../../hooks/getSignUpEventsById';
import useLeagueList from '../../hooks/getEventList';

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

const Event = () => {
  const { signUpEvent, deleteSignUpEventById, updateSignUpEventById, loading } = useSignUpEventById();
  const { id } = useParams();
  const { cities } = useGetAllCity();
  const { leagueList, loading: leagueLoading } = useLeagueList();

  const navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [league, setLeague] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [agegroup, setAgegroup] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState('');

  const [description, setDescription] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const [editMode, setEditMode] = useState(false);

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleReject = async () => {
    if (signUpEvent?.id) {
      await deleteSignUpEventById();
      setIsDeleteDialogOpen(false);
      navigate('/dashboard/verifications');
    }
  };

  const handleUpdate = async () => {
    if (signUpEvent?.id) {
      const updatedEvent = {
        cityName: selectedCity || signUpEvent?.cityName,
        description: description || signUpEvent?.description,
        league: league || signUpEvent?.league,
        dateOfBirth: dateOfBirth || signUpEvent?.dateOfBirth,
        email: emailAddress || signUpEvent?.email,
        phoneNumber: phoneNumber || signUpEvent?.phoneNumber,
        agegroup: agegroup || signUpEvent?.agegroup,
      };
      await updateSignUpEventById(updatedEvent);
      setEditMode(false); // Exit edit mode after update
      // Optionally, you can handle UI changes or navigate after updating
    }
  };

  if (loading || leagueLoading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              SIGN UP FOR EVENTS
            </Typography>

            <Box mt={4}>
              <Typography variant="h6">Name of the Event</Typography>
              {!editMode && (
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={!editMode}
                  value={league || signUpEvent?.league}
                  onChange={(e) => setLeague(e.target.value)}
                />
              )}
              {editMode && (
                <Select
                  fullWidth
                  variant="outlined"
                  disabled={!editMode}
                  value={league || signUpEvent?.league}
                  onChange={(e) => setLeague(e.target.value)}
                >
                  <MenuItem value="">Select League</MenuItem>
                  {leagueList.map((league) => (
                    <MenuItem key={league?.id} value={league?.title}>
                      {league?.title}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <Box mt={4}>
              <Typography variant="h6">Email</Typography>
            
               
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={!editMode}
                  value={emailAddress || signUpEvent?.email}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
            
               
            </Box>
            <Box mt={4}>
              <Typography variant="h6">Phone Number</Typography>
             
              
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={!editMode}
                  value={phoneNumber || signUpEvent?.phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
             
             
            </Box>
            <Box mt={4}>
              <Typography variant="h6">Age Group</Typography>
              {!editMode && (
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={!editMode}
                  value={agegroup || signUpEvent?.agegroup}
                  onChange={(e) => setAgegroup(e.target.value)}
                />
              )}
              
                {editMode && (
                <Select
                  fullWidth
                  variant="outlined"
                  disabled={!editMode}
                  value={agegroup || signUpEvent?.agegroup}
                  onChange={(e) => setAgegroup(e.target.value)}
                >
                  <MenuItem value="">Select Age Group</MenuItem>
                  {ages.map((agegroup,index) => (
                    <MenuItem key={index} value={agegroup.value}>
                      {agegroup.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
             
            </Box>
            <Box mt={4}>
              <Typography variant="h6">City Name</Typography>
              {!editMode && (
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={!editMode}
                  value={selectedCity || signUpEvent?.cityName}
                />
              )}
              {editMode && (
                <Select
                  fullWidth
                  variant="outlined"
                  disabled={!editMode}
                  value={selectedCity || signUpEvent?.cityName}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <MenuItem value="">Select City</MenuItem>
                  {cities.map((city) => (
                    <MenuItem key={city.id} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>

            <Box mt={4}>
              <Typography variant="h6">Date of birth</Typography>
              <TextField
                fullWidth
                margin="normal"
                type="date"
                variant="outlined"
                disabled={!editMode}
                value={dateOfBirth || signUpEvent?.dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </Box>

            <Box mt={4}>
              <Typography variant="h6">Description</Typography>
              <TextareaAutosize
                rows={6}
                margin="normal"
                style={{ width: '100%' }}
                disabled={!editMode}
                value={description || signUpEvent?.description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>

            <Box mt={4}>
              <Button
                variant="contained"
                style={{ backgroundColor: 'blue' }}
                onClick={handleUpdate}
                disabled={!editMode}
              >
                Update
              </Button>

              <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleDeleteClick} sx={{ ml: 2 }}>
                Delete
              </Button>

              <Button 
                 style={{ backgroundColor: 'purple' }}
              variant="contained" 
              onClick={() => setEditMode((prev) => !prev)} sx={{ ml: 2 }}>
                {editMode ? 'Cancel' : 'Edit'}
              </Button>

              <Dialog
                open={isDeleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Delete Event</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this event?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteCancel} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleReject} color="error" autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Event;
