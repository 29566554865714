import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Button, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import MainSkeleton from '../components/SkeletonLoader';
import {
  ClinicCardList,
  AddNewClinicCardSideBar,
} from '../sections/@dashboard/cliniccard';
import 'react-toastify/dist/ReactToastify.css';
import useHandlePagintationClinicCards from '../hooks/handlePaginationClinicCards';

// ----------------------------------------------------------------------

export default function ClinicCardsPage() {
 
  const {
    clinicCardsList,
    nextNextToken,
    loading,
    deleteClinicCards,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
    addClinicCard,
    updateClinicCards,
  } = useHandlePagintationClinicCards();

  const [openClinicCard, setOpenClinicCard] = useState(false);
  const [clinicCards, setClinicCards] = useState(clinicCardsList);

  
  const handleOpenClinicCard = () => {
    setOpenClinicCard(true);
  };
  const handleCloseClinicCard = () => {
    setOpenClinicCard(false);
  };
 
  useEffect(() => {
    setClinicCards(clinicCardsList || []);
  }, [clinicCardsList]);

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Clinic Cards | Minimal UI </title>
      </Helmet>

      <Container>
        <ToastContainer />
        <Typography variant="h4" sx={{ mb: 5 }}>
          Clinic Cards
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <AddNewClinicCardSideBar
              openClinicCard={openClinicCard}
              onCloseClinicCard={handleCloseClinicCard}
              onOpenClinicCard={handleOpenClinicCard}
              adddClinicCard={addClinicCard}
            />
          </Stack>
        </Stack>
        {loading ? (
          <>
            <MainSkeleton />
          </>
        ) : (
          <ClinicCardList
            clinicCards={clinicCards}
            updateClinicCards={updateClinicCards}
            deleteClinicCards={deleteClinicCards}
          />
        )}
        {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
           {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}
