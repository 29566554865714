import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { toast } from 'react-toastify';
import WordEditor from '../components/blog/Write';
import ShowPreview from '../components/blog/ShowPreview';
// import useHandleNews from '../hooks/handlePaginationNews'; // Commented out
import Loader from '../components/Loader';
import useHandlePaginationTrainingProgramCards from '../hooks/handlePaginationTrainingProgramCards';

const EditTrainingProgramCard = () => {
  const [content, setContent] = useState('');
  const [preview, setPreview] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [price, setPrice] = useState();

  const [image, setImages] = useState([]);
  const [title, setTitle] = useState();
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const {
    trainingProgramCardsList,
    nextNextToken,
    loading,
    deleteTrainingProgramCards,
    fetchNextPage,
    trainingProgramCard,
    fetchPreviousPage,
    previousTokens,
    addTrainingProgramCard,
    updateTrainingProgramCards,
  } = useHandlePaginationTrainingProgramCards();

  const handleWrite = (newContent) => {
    setContent(newContent);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  /*
  const handleNewsTypeChange = (event) => {
    setNewsType(event.target.value);
  };

*/
  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    setShowCancelDialog(false);
    // Redirect to home page
    // history.push('/dashboard/news');
    navigate('/dashboard/training-program-cards');
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
  };
  const handleSave = async () => {
    try {
      if (!title) {
        toast.error('Title is required.');
        return;
      }

      // Check if price is empty
      if (!price) {
        toast.error('Price is required.');
        return;
      }
      // Check if price is empty
      if (!content) {
        toast.error('Content is required.');
        return;
      }
      if (id) {
        const data = {
          title,
          description: content,
          price,
          id
        };

        // Save the news content with updated image sources
        await updateTrainingProgramCards(data);
      } else {
        const data = {
          title,
          description: content,
          price,
        };

        // Save the news content with updated image sources
        await addTrainingProgramCard(data);
      }

      navigate('/dashboard/training-program-cards');
      console.log('Save functionality executed successfully');
    } catch (error) {
      console.error('Error during handleSave:', error);
    }
  };

  const extractText = (content) => {
    // Replace HTML tags and extract plain text
    const div = document.createElement('div');
    div.innerHTML = content;
    const text = div.innerText.trim();

    return text;
  };
  // Function to extract images and videos from HTML content
  const extractImagesAndVideos = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const images = [];
    const videos = [];
    const iframe = [];

    // Extract images
    const imageElements = doc.querySelectorAll('img');
    imageElements.forEach((element) => {
      const src = element.getAttribute('src');
      images.push(src);
    });

    // Extract videos
    const videoElements = doc.querySelectorAll('video');
    videoElements.forEach((element) => {
      const src = element.getAttribute('src');
      videos.push(src);
    });

    // Extract iframes
    const iframeElements = doc.querySelectorAll('iframe');
    iframeElements.forEach((element) => {
      const src = element.getAttribute('src');
      iframe.push(src);
    });

    return { images, videos, iframe };
  };

  useEffect(() => {
    if (id) {
      setPrice(trainingProgramCard?.price);
      setTitle(trainingProgramCard?.title);
      setContent(trainingProgramCard?.description);
    }

    console.log("Traingng PRogra", trainingProgramCard)
  }, [id, trainingProgramCard]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box className="flex flex-col gap-[25px] py-2 px-2 w-full max-w-[1280px] mx-auto h-full">
      <Box className="flex gap-2 justify-between items-center">
        <Box className="flex gap-2 items-center">
          <button
            className={`border transition-all ease-in-out duration-300  text-[16px]  font-bold ${!preview
              ? 'bg-blue-500  hover:bg-blue-600  text-white'
              : 'border text-blue-500   border-blue-400 hover:bg-blue-200'
              } text-black p-1 px-3 rounded-lg`}
            onClick={() => setPreview(false)}
          >
            Edit
          </button>
          <button
            className={`border transition-all text-[16px] ease-in-out duration-300  font-bold ${preview
              ? 'bg-blue-500 hover:bg-blue-600 rounded-lg text-white'
              : 'border text-blue-500   border-blue-400 hover:bg-blue-200'
              } text-black p-1 rounded-lg px-3`}
            onClick={() => setPreview(true)}
          >
            Preview
          </button>
        </Box>
        <Box className="flex gap-2 items-center">
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="text" color="secondary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>

      <Box className="grid grid-cols-4 gap-2">
        <FormControl fullWidth className="col-span-3">
          <TextField id="title" variant="outlined" placeholder="Title" value={title} onChange={handleTitleChange} />
        </FormControl>
        <FormControl fullWidth className="cols-span-1">
          <TextField
            id="price"
            variant="outlined"
            placeholder="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </FormControl>
      </Box>

      <Box className="flex justify-between w-full mt-2">
        {preview ? (
          <ShowPreview content={content} title={title} />
        ) : (
          <WordEditor content={content} image={image} setImages={setImages} setContent={handleWrite} />
        )}
      </Box>
      <Dialog open={showCancelDialog} onClose={handleCancelDialogClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>If you cancel, your changes will be lost. Do you really want to cancel?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="primary">
            <NavLink to="/dashboard/training-program-cards">Yes</NavLink>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditTrainingProgramCard;
