import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as mutations from '../graphql/mutations'; // Import your GraphQL mutations

const useUpdateAdvertisementDetails = () => {
  const [loading, setIsUpdating] = useState(false);
  // const { id } = useParams();

  const updateAdvertisementDetails = async (newData, advertisementId) => {
    try {
      setIsUpdating(true);

      console.log('PRodiuctId', advertisementId);
      const response = await API.graphql({
        query: mutations.updateAdvertisement,
        variables: { input: { ...newData, id: advertisementId } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const updatedDetails = response?.data?.updateAdvertisement; // Assuming your mutation returns the updated details
      setIsUpdating(false);
      toast.success('Advertisement Updated ');

      return updatedDetails;
    } catch (error) {
      console.error('Error updating Advertisement details:', error);
      setIsUpdating(false);
      toast.error('Something Went Wrong');

      throw error; // Propagate the error for handling in the component
    }
  };

  return { updateAdvertisementDetails, loading };
};

export default useUpdateAdvertisementDetails;
