/* eslint-disable consistent-return */
/* eslint-disable consistent-return, prefer-const, no-plusplus, no-await-in-loop, no-undef */

import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries'; // Make sure to import your GraphQL queries
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL queries
import { createRounds, updateRounds } from './startTournamentScoreHooks';

const useUpdateScoreByIdOrAge = () => {
  const [loading, setLoading] = useState(false);
  // const { id } = useParams();
  const getTeamsByLeague = async (eventId) => {
    try {
      const responseOfListTeams = await API.graphql({
        query: queries.listTournamentTeams,
        variables: {
          filter: {
            tournamentId: {
              eq: eventId,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const data = responseOfListTeams?.data.listTournamentTeams.items;
      return data;
    } catch (error) {
      throw new Error('Fething Error teams', error);
    }
  };
  const getWinnersByLeague = async (eventId) => {
    try {
      const responseOfListTeams = await API.graphql({
        query: queries.listWinners,
        variables: {
          filter: {
            tournamentId: {
              eq: eventId,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const data = responseOfListTeams?.data.listWinners.items;

      console.log('responseOfListWinner'.data);
      return data;
    } catch (error) {
      throw new Error('Fething Error teams', error);
    }
  };
  const getRoundsByLeague = async (eventId) => {
    try {
      const responseOfListTeams = await API.graphql({
        query: queries.listRounds,
        variables: {
          filter: {
            eventId: {
              eq: eventId,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const data = responseOfListTeams?.data.listRounds.items;
      return data;
    } catch (error) {
      throw new Error('Fething Error teams', error);
    }
  };
  const getScoresByLeague = async (eventId) => {
    try {
      const scoresResponse = await API.graphql({
        query: queries.listScores,
        variables: {
          filter: {
            eventId: {
              eq: eventId,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const data = scoresResponse?.data.listScores.items;
      return data;
    } catch (error) {
      throw new Error('Fething Error teams', error);
    }
  };

  const returnPlayersFromTeams = (team) => {
    const player = team.players.items;
    return player;
  };

  const updateScoreById = async (data, isWinner) => {
    let dataToSend;
    console.log('id', data);
    delete data.firstTeam.__typename;
    delete data.secondTeam.__typename;
    setLoading(true);
    try {
      const response = await API.graphql(graphqlOperation(queries.getScore, { id: data?.id }));
      const score = response.data.getScore;
      const responseOfTournamentLeague = await API.graphql(
        graphqlOperation(queries.getTournamentLeague, { id: score?.eventId })
      );
      const event = responseOfTournamentLeague.data.getTournamentLeague;
      console.log('UPDATE THIS SCORE', score);
      console.log('Event', event);
      const eventStatus = response?.data?.getScore?.eventStatus;
      console.log('eventStatus', eventStatus);
      if (eventStatus === 'EVENT_ENDED' || event.eventStatus === 'EVENT_ENDED') {
        toast.error('Event is Ended.');
        return;
      }

      delete data.__typename;
      delete data.eventUrl;

      if (data && data.firstTeamDetails && Array.isArray(data.firstTeamDetails)) {
        data.firstTeamDetails.forEach((player) => {
          delete player.__typename; // Remove __typename from each player in firstTeamDetails
          delete player.playerImgUrl;
          delete player.teamLogoUrl;
        });
      }
      if (data && data.secondTeamDetails && Array.isArray(data.secondTeamDetails)) {
        data.secondTeamDetails.forEach((player) => {
          delete player.__typename; // Remove __typename from each player in secondTeamDetails
          delete player.playerImgUrl;
          delete player.teamLogoUrl;
        });
      }
      const playerDetails = [...data.firstTeamDetails, ...data.secondTeamDetails];
      console.log('datasss', data);
      let updateData;
      console.log('WINNER TEAMS', data);
      if (data.winner) {
        updateData = {
          // ...event,
          playerDetails,
          firstTeamDetails: data.firstTeamDetails,
          secondTeamDetails: data.secondTeamDetails,
          firstTeam: data.firstTeam,
          secondTeam: data.secondTeam,
          date: data.date,
          time: data.time,
          courtNo: data.courtNo,
          record: data.record,
          ppg: data.ppg,
          rpe: data.rpe,
          apg: data.apg,
          ranking: data.ranking,
          winner: data.winner,
          eventStatus: data.eventStatus,
        };
      } else {
        updateData = {
          // ...event,
          playerDetails,
          firstTeamDetails: data.firstTeamDetails,
          secondTeamDetails: data.secondTeamDetails,
          firstTeam: data.firstTeam,
          secondTeam: data.secondTeam,
          date: data.date,
          time: data.time,
          courtNo: data.courtNo,
          record: data.record,
          ppg: data.ppg,
          rpe: data.rpe,
          apg: data.apg,
          ranking: data.ranking,
        };
      }
      console.log('updateData', updateData);

      const resp = await API.graphql({
        query: mutations.updateScore,
        variables: { input: { ...updateData, id: data?.id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      dataToSend = resp?.data?.updateScore;
      const teams = await getTeamsByLeague(event.id);
      const winnerTeamFe = await getWinnersByLeague(event.id);
      const winnerTeamFetched = winnerTeamFe[0];

      console.log('WinnerTeamFetched', winnerTeamFetched);
      if (data.winner) {
        const bothTeams = [score.firstTeam.teamId, score.secondTeam.teamId];
        const loserTeams = bothTeams.filter((teamId) => teamId !== data.winner);

        const totalRounds = winnerTeamFetched.totalRounds;
        const roundsDat = await getRoundsByLeague(event.id);
        const rounds = roundsDat;
        const roundD = rounds.filter((r) => r.round === data.round);
        const currentRound = roundD[0];
        const currentRoundRunnerUp = [...currentRound.runnerUpList];
        let nextRoundD;
        let updatedCurrentRoundRunnerUpList = currentRound.runnerUpList.filter(
          (team) => team !== data.winner && team !== loserTeams[0]
        );
        if (data.round === totalRounds) {
          const winnerTeams = teams.filter((team) => team.id === data.winner);
          const loserTeams = score.teams.filter((team) => team.teamId !== data.winner);
          const updateCurrentRoundFinal = {
            id: currentRound.id,
            runnerUpList: [],
            eventStatus: 'EVENT_ENDED',
            runnerUp: {
              teamId: loserTeams[0].id,
              teamName: loserTeams[0].name,
              teamLogo: loserTeams[0].imageURL,
            },
            winner: {
              teamId: winnerTeams[0].id,
              teamName: winnerTeams[0].name,
              teamLogo: winnerTeams[0].imageURL,
            },
          };
          const winnerData = {
            id: winnerTeamFetched.id,
            eventStatus: 'EVENT_ENDED',
            runnerUp: {
              teamId: loserTeams[0].id,
              teamName: loserTeams[0].name,
              teamLogo: loserTeams[0].imageURL,
            },
            winner: {
              teamId: winnerTeams[0].id,
              teamName: winnerTeams[0].name,
              teamLogo: winnerTeams[0].imageURL,
            },
          };
          const respLe = await API.graphql({
            query: mutations.updateTournamentLeague,
            variables: { input: { id: event.id, eventStatus: 'EVENT_ENDED' } },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          const winnerResponse = await API.graphql({
            query: mutations.updateWinner,
            variables: { input: { ...winnerData } },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          const roundResponse = await updateRounds(updateCurrentRoundFinal);
          toast.success('Score Updated Successfully!');
          return dataToSend;
        }

        if (data.round + 1 <= totalRounds) {
          const nextR = rounds.filter((r) => r.round === data.round + 1);
          nextRoundD = nextR[0];
          console.log('nextRoundD', nextRoundD, nextR);
          if (!nextR || nextR.length < 1) {
            let dataRound = {
              eventName: event.title,
              eventId: event.id,

              runnerUpList: [data.winner],
              round: data.round + 1,
              eventStatus: 'EVENT_STARTED',

              winnerId: winnerTeamFetched.id,
            };
            const previousR = rounds.filter((roun) => roun.round === currentRound.round - 1);
            const currentRunnerList = currentRound.runnerUpList;
            console.log('Current Runner', currentRunnerList, previousR[0]?.runnerUpList);
            console.log('Previous R', previousR);

            if (data.round > 1 && currentRunnerList.length > 0 && previousR[0]?.runnerUpList?.length < 1) {
              dataRound.runnerUpList = [];
              const dataToCreateRounds = {
                ...dataRound,
                runnerUpList: [],
              };
              console.log('Creating Next Rounds', dataRound);
              const roundCreated = await createRounds(dataToCreateRounds);
              nextRoundD = roundCreated;
              updatedCurrentRoundRunnerUpList = [];
              console.log('Created Next Rounds', nextRoundD);
              const firstTeamDetails = teams.filter((team) => team.id === data.winner);
              const secondTeamDetails = teams.filter((team) => team.id === currentRound.runnerUpList[0]);
              const bothTeams = [...firstTeamDetails, ...secondTeamDetails];
              const score = [];
              console.log('Score:create', score);
              console.log('Both:Teams', bothTeams);
              for (let i = 0; i < bothTeams.length; i++) {
                if (bothTeams[i].players.items.length < 4) {
                  toast.error(`${bothTeams[i].name} Does  n't have enough players`);
                  return;
                }

                const playerScoreOfSingleTeams = [];
                for (let j = 0; j < bothTeams[i].players.items.length; j++) {
                  const player = bothTeams[i].players.items[j];

                  const playerDetails = {
                    ...stats,
                    teamId: bothTeams[i].id,
                    teamName: bothTeams[i].name,
                    teamLogo: bothTeams[i].imageURL,
                    playerId: player.id,
                    playerName: player.participantName,
                    playerImage: player.profile,
                  };
                  console.log('playerDetails', playerDetails);
                  playerScoreOfSingleTeams.push(playerDetails);
                }

                score.push(playerScoreOfSingleTeams);
              }
              console.log('SCORE', score);
              const createdScore = [];
              for (let i = 0; i < score.length; i += 2) {
                if (i < score.length - 1) {
                  console.log('createdScorePlayer', score[i][0].teamId);

                  const firstTeam = {
                    teamId: score[i][0].teamId,
                    teamName: score[i][0].teamName,
                    teamLogo: score[i][0].teamLogo,
                    score: data.firstTeam.score,
                  };

                  const secondTeam = {
                    teamId: score[i + 1][0].teamId,
                    teamName: score[i + 1][0].teamName,
                    teamLogo: score[i + 1][0].teamLogo,
                    score: data.secondTeam.score,
                  };

                  const firstTeamsPlayerDetails = [...score[i]];
                  const secondTeamsPlayerDetails = [...score[i + 1]];
                  const bothPlayerDetails = [...firstTeamsPlayerDetails, ...secondTeamsPlayerDetails];

                  const currentTwoTeams = [firstTeam, secondTeam];

                  const singleScore = {
                    event: event.title,
                    eventId: event.id,
                    eventImage: event.img_url,
                    matchNumber: i,
                    round: data.round + 1,
                    teams: [...currentTwoTeams],
                    firstTeam: currentTwoTeams[0],
                    secondTeam: currentTwoTeams[1],
                    ...rank,
                    tournament: true,
                    city: '',
                    playerDetails: bothPlayerDetails,
                    firstTeamDetails: firstTeamsPlayerDetails,
                    secondTeamDetails: secondTeamsPlayerDetails,
                    eventStatus: 'EVENT_STARTED',
                    roundId: nextRoundD.id,
                    eventsId: event.id,
                    winnerId: winnerTeamFetched.id,
                  };

                  createdScore.push(singleScore);
                }
              }
              if (!createdScore) {
                toast.error('Error Creating Score');
                return;
              }
              console.log('Created SCORE', createdScore);
              const scoreCreated = await API.graphql({
                query: mutations.createScore,
                variables: { input: { ...createdScore[0] } },
                authMode: GRAPHQL_AUTH_MODE.API_KEY,
              });
            } else {
              try {
                const roundCreated = await createRounds(dataRound);
                nextRoundD = roundCreated;
              } catch (error) {
                console.error('Error Creating Rounds :', error.message);
                toast.error('Something Went wrong');
              }
            }
          } else {
            const runnerUp = nextR[0].runnerUpList;
            console.log('Runner UP', runnerUp);
            if (runnerUp.length < 1) {
              const upateRoundsData = {
                id: nextR[0].id,
                runnerUpList: [data.winner],
              };
              const updaterounds = await updateRounds(upateRoundsData);
            } else {
              const firstTeamDetails = teams.filter((team) => team.id === data.winner);
              const secondTeamDetails = teams.filter((team) => team.id === nextR[0].runnerUpList[0]);
              const bothTeams = [...firstTeamDetails, ...secondTeamDetails];
              const score = [];
              console.log('Score:create', score);
              console.log('Both:Teams', bothTeams);
              for (let i = 0; i < bothTeams.length; i++) {
                if (bothTeams[i].players.items.length < 4) {
                  toast.error(`${bothTeams[i].name} Does  n't have enough players`);
                  return;
                }

                const playerScoreOfSingleTeams = [];
                for (let j = 0; j < bothTeams[i].players.items.length; j++) {
                  const player = bothTeams[i].players.items[j];

                  const playerDetails = {
                    ...stats,
                    teamId: bothTeams[i].id,
                    teamName: bothTeams[i].name,
                    teamLogo: bothTeams[i].imageURL,
                    playerId: player.id,
                    playerName: player.participantName,
                    playerImage: player.profile,
                  };
                  console.log('playerDetails', playerDetails);
                  playerScoreOfSingleTeams.push(playerDetails);
                }

                score.push(playerScoreOfSingleTeams);
              }
              console.log('SCORE', score);
              const createdScore = [];
              for (let i = 0; i < score.length; i += 2) {
                if (i < score.length - 1) {
                  console.log('createdScorePlayer', score[i][0].teamId);

                  const firstTeam = {
                    teamId: score[i][0].teamId,
                    teamName: score[i][0].teamName,
                    teamLogo: score[i][0].teamLogo,
                  };

                  const secondTeam = {
                    teamId: score[i + 1][0].teamId,
                    teamName: score[i + 1][0].teamName,
                    teamLogo: score[i + 1][0].teamLogo,
                  };

                  const firstTeamsPlayerDetails = [...score[i]];
                  const secondTeamsPlayerDetails = [...score[i + 1]];
                  const bothPlayerDetails = [...firstTeamsPlayerDetails, ...secondTeamsPlayerDetails];

                  const currentTwoTeams = [firstTeam, secondTeam];

                  const singleScore = {
                    event: event.title,
                    eventId: event.id,
                    eventImage: event.img_url,
                    matchNumber: i,
                    round: data.round + 1,
                    teams: [...currentTwoTeams],
                    firstTeam: currentTwoTeams[0],
                    secondTeam: currentTwoTeams[1],
                    ...rank,
                    tournament: true,
                    city: '',
                    playerDetails: bothPlayerDetails,
                    firstTeamDetails: firstTeamsPlayerDetails,
                    secondTeamDetails: secondTeamsPlayerDetails,
                    eventStatus: 'EVENT_STARTED',
                    roundId: nextR[0].id,
                    eventsId: event.id,
                    winnerId: winnerTeamFetched.id,
                  };

                  createdScore.push(singleScore);
                }
              }
              if (!createdScore) {
                toast.error('Error Creating Score');
                return;
              }
              console.log('Created SCORE', createdScore);
              const scoreCreated = await API.graphql({
                query: mutations.createScore,
                variables: { input: { ...createdScore[0] } },
                authMode: GRAPHQL_AUTH_MODE.API_KEY,
              });
              const upateRoundsDataAgain = {
                id: nextR[0].id,
                runnerUpList: [],
              };
              const updaterounds = await updateRounds(upateRoundsDataAgain);
            }
          }
        }

        console.log('currentRound', currentRound);

        console.log('updatedCurrentRoundRunnerUpList', updatedCurrentRoundRunnerUpList);
        const roundData = roundD[0];
        const nextRoundRunnerUpList = [nextRoundD, data.winner];

        const winnerTeamFetchedData = winnerTeamFetched;
        const matches = winnerTeamFetchedData?.matches;

        console.log('WINNER', winnerTeamFetched);
        console.log('WINNER', winnerTeamFetchedData);
        console.log('WINNER', matches);

        // const matchesRoun=[]

        // if (winnerTeamFetchedData && roundData) {
        //   matches = [...matches, score.id];
        console.log('currentRound', currentRound, updatedCurrentRoundRunnerUpList);
        const roundUpdateData = {
          id: currentRound.id,
          runnerUpList: updatedCurrentRoundRunnerUpList,
        };
        const winnerRunnerUpList = winnerTeamFetched?.runnerUpList?.filter(
          (winner) => winner !== data.winner && winner !== loserTeams[0]
        );
        const winnerTeamData = {
          id: winnerTeamFetchedData.id,

          runnerUpList: winnerRunnerUpList,
        };

        //   const bothTeams = [score.firstTeam.teamId, score.secondTeam.teamId];
        //   const loserTeams = bothTeams.filter((teamId) => teamId !== data.winner);
        //   let runnerUpList = winnerTeamData.runnerUpList;
        //   let roundTeamsDataList = roundData.runnerUpList;

        //   // Filter out the loser teams from the runner-up list
        //   roundTeamsDataList = roundTeamsDataList.filter(
        //     (runnerUp) => runnerUp !== loserTeams[0] && runnerUp !== data.winner
        //   );
        //   runnerUpList = runnerUpList.filter((runnerUp) => !loserTeams.includes(runnerUp));

        //   roundData.runnerUpList = [...roundTeamsDataList];

        //   // Update the runner-up list in the winnerTeamData object

        //   winnerTeamData.runnerUpList = [...runnerUpList];

        try {
          // const winnerResponse = await API.graphql({
          //   query: mutations.updateWinner,
          //   variables: { input: { ...winnerTeamData } },
          //   authMode:GRAPHQL_AUTH_MODE.API_KEY
          // });
        } catch (error) {
          console.error('Error :', error.message);
          toast.error('Error Updating Winner');
        } //   console.log('ROUND', data.round, winnerTeamFetched, winnerTeamFetched.data.updateWinner.totalRounds);
        //   if (data.round < winnerTeamFetched?.data?.updateWinner?.totalRounds) {
        //     const updateNextRounds = rounds.data.listRounds.items.filter((r) => r.round === data.round + 1);

        //     console.log('updateRounds', rounds, updateNextRounds);
        //     delete updateNextRounds[0].__typename;
        //     delete updateNextRounds[0].updatedAt;
        //     delete updateNextRounds[0].createdAt;
        //     delete roundData.__typename;
        //     delete roundData.updatedAt;
        //     delete roundData.createdAt;

        //     if (roundData.runnerUpList.length === 1) {
        //       updateNextRounds[0].runnerUpList.push(roundData.runnerUpList[0]); // Push the first (and only) element
        //       roundData.runnerUpList.pop(); // Remove the first (and only) element
        //     }
        //     console.log('UPDATTE NExt Rounds', updateNextRounds, data.winner);
        delete roundUpdateData.__typename;
        delete roundUpdateData.leauge;
        delete roundUpdateData.runnerUp;
        delete roundUpdateData.tournament;
        delete roundUpdateData.winner;
        delete roundUpdateData.tournamentLeagueRoundsId;

        console.log('UpdateRoundsData', roundUpdateData);
        try {
          const updated = await API.graphql({
            query: mutations.updateRounds,
            variables: {
              input: {
                ...roundUpdateData,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        } catch (error) {
          console.error('Error :', error.message);
          toast.error('Error Updating Rounds');
        } //     console.log('UPDATED ', updated, data.winner);
        //     const winnersRound = await API.graphql({
        //       query: mutations.updateRounds,
        //       variables: { input: { ...roundData } },
        //       authMode:GRAPHQL_AUTH_MODE.API_KEY
        //     });
        //   }
        // }
        // if (data.round === totalRounds) {
        //   const winnerTeamFetched = await API.graphql({
        //     query: mutations.updateWinner,
        //     variables: { input: { ...winnerTeamData } },
        //     authMode:GRAPHQL_AUTH_MODE.API_KEY
        //   });
        // }
      }
      // else {
      //   const newWinnerData = {
      //     id: score.eventId,
      //     eventName: score.eventName,
      //     matches: [score],
      //   };
      //   if (data.winner) {
      //     const bothTeams = [score.firstTeam.teamId, score.secondTeam.teamId];
      //     const loserTeams = bothTeams.filter((teamId) => teamId !== data.winner);
      //     let runnerUpList = newWinnerData.runnerUpList;

      //     // Filter out the loser teams from the runner-up list
      //     runnerUpList = runnerUpList.filter((runnerUp) => !loserTeams.includes(runnerUp));

      //     // Update the runner-up list in the winnerTeamData object
      //     newWinnerData.runnerUpList = [...runnerUpList];
      //   }
      //   const winnerTeamFetched = await API.graphql({
      //     query: mutations.createWinner,
      //     variables: { input: { ...newWinnerData } },
      //     authMode:GRAPHQL_AUTH_MODE.API_KEY
      //   });
      // }
      // if (data.winner) {
      const responseOfTournamentLeagues = await API.graphql(
        graphqlOperation(queries.getTournamentLeague, { id: score?.eventId })
      );
      const rounds = await getRoundsByLeague(score.eventId);
      console.log('responseOfTour', responseOfTournamentLeagues);
      const events = responseOfTournamentLeagues.data.getTournamentLeague;
      const scores = await getScoresByLeague(score.eventId);
      console.log('responseOfTour', responseOfTournamentLeagues);
      toast.success('Score Updated Successfully!');
      return {
        ...events,
        rounds,
        scores,
      };

      // }
      toast.success('Score Updated Successfully!');
    } catch (error) {
      console.error('Error :', error);
      toast.error('Error upading Score');
    } finally {
      setLoading(false);
    }
  };

  const endEvenAndScore = async (data) => {
    const eventStatus = 'EVENT_ENDED';
    if (!data) {
      toast.error('Id can not be empty');
      return;
    }
    setLoading(true);
    try {
      const id = data;
      const eventNam = data.event;
      console.log('IFDDDDDD', id);

      const resScore = await API.graphql(graphqlOperation(queries.getScore, { id: id[0].id }));
      if (resScore.data.getScore.eventStatus === 'EVENT_ENDED') {
        toast.error('Event is Ended.');
        return;
      }
      const scr = resScore.data.getScore;
      const score = scr;
      const resLeague = await API.graphql(graphqlOperation(queries.listTournamentLeagues));
      const league = resLeague.data.listTournamentLeagues.items.filter((league) => league.title === score.event);
      console.log('league', league);
      delete score.__typename;
      delete league[0].__typename;
      if (!league) {
        toast.error('Invalid League');
        return;
      }

      data.map(async (score) => {
        score.firstTeamDetails.forEach((player) => {
          delete player.__typename; // Remove __typename from each player in secondTeamDetails
          delete player.playerImgUrl;
          delete player.teamLogoUrl;
        });
        score?.playerDetails?.forEach((player) => {
          delete player.__typename; // Remove __typename from each player in secondTeamDetails
          delete player.playerImgUrl;
          delete player.teamLogoUrl;
        });
        score.secondTeamDetails.forEach((player) => {
          delete player.__typename; // Remove __typename from each player in secondTeamDetails
          delete player.playerImgUrl;
          delete player.teamLogoUrl;
        });

        score.secondTeamDetails.forEach((player) => {
          delete player.__typename; // Remove __typename from each player in secondTeamDetails
          delete player.playerImgUrl;
          delete player.teamLogoUrl;
        });
        score.teams.forEach((player) => {
          delete player.__typename; // Remove __typename from each player in secondTeamDetails
          delete player.playerImgUrl;
          delete player.teamLogoUrl;
        });
        delete score.firstTeam.__typename;
        delete score.secondTeam.__typename;
        delete score.createdAt;
        delete score.updatedAt;
        delete score.__typename;
        const updateData = {
          // ...event,
          playerDetails: [...score.firstTeamDetails, ...score.secondTeamDetails],
          firstTeamDetails: score.firstTeamDetails,
          secondTeamDetails: score.secondTeamDetails,
          date: score.date,
          time: score.time,
          courtNo: score.courtNo,
          record: score.record,
          ppg: score.ppg,
          rpe: score.rpe,
          apg: score.apg,
          ranking: score.ranking,
        };
        const resp = await API.graphql({
          query: mutations.updateScore,
          variables: { input: { ...score, eventStatus, id: score?.id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const winnerTeamFetched = await API.graphql({
          query: queries.listWinners,
          variables: {
            filter: {
              eventId: {
                eq: league.id,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const winnerTeamFetchedData = winnerTeamFetched.data.listWinners.items[0];
        let matches = winnerTeamFetchedData?.matches;

        console.log('WINNER', winnerTeamFetched);
        console.log('WINNER', winnerTeamFetchedData);
        console.log('WINNER', matches);

        if (winnerTeamFetchedData) {
          matches = [...matches, score];
          const winnerTeamData = {
            id: winnerTeamFetchedData.id,
            eventName: score.eventName,
            matches,
            runnerUpList: winnerTeamFetchedData.runnerUpList,
          };
          if (data.winner) {
            const bothTeams = [score.firstTeam.teamId, score.secondTeam.teamId];
            const loserTeams = bothTeams.filter((teamId) => teamId !== data.winner);
            let runnerUpList = winnerTeamData.runnerUpList;

            // Filter out the loser teams from the runner-up list
            runnerUpList = runnerUpList.filter((runnerUp) => !loserTeams.includes(runnerUp));

            // Update the runner-up list in the winnerTeamData object
            winnerTeamData.runnerUpList = [...runnerUpList];
          }
          const winnerTeamFetched = await API.graphql({
            query: mutations.updateWinner,
            variables: { input: { ...winnerTeamData } },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          const roundsTeamFetched = await API.graphql({
            query: mutations.updateWinner,
            variables: { input: { ...winnerTeamData } },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        } else {
          const newWinnerData = {
            id: score.eventId,
            eventName: score.eventName,
            matches: [score],
          };
          if (data.winner) {
            const bothTeams = [score.firstTeam.teamId, score.secondTeam.teamId];
            const loserTeams = bothTeams.filter((teamId) => teamId !== data.winner);
            let runnerUpList = newWinnerData.runnerUpList;

            // Filter out the loser teams from the runner-up list
            runnerUpList = runnerUpList.filter((runnerUp) => !loserTeams.includes(runnerUp));

            // Update the runner-up list in the winnerTeamData object
            newWinnerData.runnerUpList = [...runnerUpList];
          }
          const winnerTeamFetched = await API.graphql({
            query: mutations.createWinner,
            variables: { input: { ...newWinnerData } },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        }
        return score;
      });

      league[0].teamsId.forEach((player) => {
        delete player.__typename; // Remove __typename from each player in secondTeamDetails
        delete player.playerImgUrl;
        delete player.teamLogoUrl;
      });
      league[0].teamsName.forEach((player) => {
        delete player.__typename; // Remove __typename from each player in secondTeamDetails
        delete player.playerImgUrl;
        delete player.teamLogoUrl;
      });
      delete league[0].scoreID;
      delete league[0].createdAt;
      delete league[0].updatedAt;

      const respLe = await API.graphql({
        query: mutations.updateTournamentLeague,
        variables: { input: { ...league[0], eventStatus, id: league[0]?.id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      toast.success('Event Ended successfully');
      setLoading(false);
    } catch (error) {
      toast.error(error.message || 'Something went wrong check console');
    } finally {
      setLoading(false);
    }
  };
  return { updateScoreById, loading, endEvenAndScore };
};

export default useUpdateScoreByIdOrAge;

const stats = {
  GP: '0',
  MIN: '0',
  PTS: '0',
  FGM: '0',
  FGA: '0',
  FGPERCENT: '0',
  THREEPM: '0',
  THREEPA: '0',
  THREEPPERCENT: '0',
  FTM: '0',
  FTA: '0',
  FTPERCENT: '0',
  OREB: '0',
  DREB: '0',
  REB: '0',
  AST: '0',
  TOV: '0',
  STL: '0',
  BLK: '0',
  PF: '0',
  FP: '0',
  DDTWO: '0',
  TDTHREE: '0',
  PlusMinus: '0',
};

const rank = {
  record: '0',
  ppg: '0',
  rpe: '0',
  apg: '0',
  ranking: '0',
};

/* eslint-enable consistent-return, prefer-const, no-plusplus, no-await-in-loop, no-undef */
