
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';
import Loader from '../../../components/Loader';
import useGetImage from '../../../hooks/getImageLink';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({updateProductDetails,deleteProduct, products,setProductList,checkAuthAndFetchData, ...other }) {
 

  return (
    <Grid container spacing={3} {...other}>
      {products?.map((product) => (
        <Grid key={product?.id} item xs={12}  sm={6} md={3}>
          <ShopProductCard deleteProduct={deleteProduct}  updateProductDetails={updateProductDetails} product={product} setProductList={setProductList} checkAuthAndFetchData={checkAuthAndFetchData} />
        </Grid>
      ))}
    </Grid>
  );
}