import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Select,
  Container,
  Box,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  Typography
} from '@mui/material';
import Loader from '../Loader';
import useGetPartnerDetails from '../../hooks/getPartnerDetails';
import useUpdatePartner from '../../hooks/updatePartnerById';

const reasons = [
  "AFFILIATE",
  "ADVERTISEMENT",
  "SPONSORSHIP",
  "PARTNERSHIP"
];

const Partner = () => {
  const { partnerDetails, loading } = useGetPartnerDetails();
  const { updatePartner, loading: updateLoading } = useUpdatePartner();
  console.log('partner', partnerDetails);

  const [formData, setFormData] = useState({
    name:'',
    organization: '',
    reason: '',
    number: '',
    email: '',
    message: '',
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateLeague(); // Submit the form
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const buttonStyles = {
    backgroundColor: '#1E90FF',
    color: 'white',
  };

  useEffect(() => {
    if (partnerDetails) {
      setFormData({
        name: partnerDetails.name || '',
        email: partnerDetails.email || '',
        number: partnerDetails.number || '',
        reason: partnerDetails.reason || '',
        organization: partnerDetails.organization || '',
        message: partnerDetails.message || '',
      });
    }
  }, [partnerDetails]);

  const updateLeague = async () => {
    const data = {
      id: partnerDetails?.id,
      name: formData?.name || partnerDetails?.name,
      organization: formData?.organization || partnerDetails?.organization,
      reason: formData?.reason || partnerDetails?.reason,
      email: formData?.email || partnerDetails?.email,
      message: formData?.message || partnerDetails?.message,
      number: formData?.number || partnerDetails?.number,
    };
    await updatePartner(data);
  };

  if (loading || updateLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Container>
      <form onSubmit={handleFormSubmit}>
        <Grid item xs={12} sm={6} style={{ marginLeft: 1, marginTop: 5 }}>
          <Typography variant="h4" component="div" className="font-bold">Edit Partner</Typography>
        </Grid>
        <Grid container style={{ marginLeft: 1, marginTop: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              id="name"
              variant="outlined"
              label="Participant"
              fullWidth
              value={formData.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="organization"
              id="organization"
              variant="outlined"
              label="Organization"
              fullWidth
              value={formData.organization}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              name="email"
              variant="outlined"
              label="Email Address"
              fullWidth
              value={formData.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="number"
              name="number"
              variant="outlined"
              label="Phone Number"
              fullWidth
              value={formData.number}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="reason-label">Reason</InputLabel>
              <Select
                id="reason"
                name="reason"
                variant="outlined"
                label="Reason"
                fullWidth
                value={formData.reason}
                onChange={handleInputChange}
              >
                <MenuItem value="">Select Reason</MenuItem>
                {reasons?.map((data, index) => (
                  <MenuItem key={index} value={data}>{data}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="message"
              id="message"
              value={formData.message}
              onChange={handleInputChange}
              variant="outlined"
              multiline
              fullWidth
              rows={4}
            />
          </Grid>
        </Grid>
        <Box mt={2} ml={5}>
          <Button variant="contained" onClick={updateLeague} style={buttonStyles} type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default Partner;
