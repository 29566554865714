import {
  CREATE_NEWS_REQUEST,
  CREATE_NEWS_SUCCESS,
  CREATE_NEWS_FAIL,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  LIST_NEWS_REQUEST,
  LIST_NEWS_SUCCESS,
  LIST_NEWS_FAIL,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  UPDATE_NEWS_VIEWS_REQUEST,
  UPDATE_NEWS_VIEWS_SUCCESS,
  UPDATE_NEWS_VIEWS_FAIL,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  DELETE_NEWS_RESET,
  CLEAR_NEWS_ERRORS,
} from "../constants/NewsConstants";

const initialState = {
  news: [],
  vlog: [],
  blog: [],
  newsDetails: {},
  newsCount: 0,
  newsViews: 0,

  loading: false,
  error: null,
  resultPerPage: 0,
  filteredNewsCount: 0,
};

export const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_NEWS_REQUEST:
    case UPDATE_NEWS_REQUEST:
    case CREATE_NEWS_REQUEST:
    case DELETE_NEWS_REQUEST:
    case GET_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LIST_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
        // vlog: action.payload.vlog,
        // blog: action.payload.blog,
        // newsCount: action.payload.newsCount,
        // resultPerPage: action.payload.resultPerPage,
        // filteredNewsCount: action.payload.filteredNewsCount,
        loading: false,
      };
  
    case GET_NEWS_SUCCESS:
      return {
        ...state,
        newsDetails: action.payload.newsDetails,
        newsViews: action.payload.newsViews,
        loading: false,

      };
    case GET_NEWS_FAIL:
    case CREATE_NEWS_FAIL:
    case UPDATE_NEWS_FAIL:
    case DELETE_NEWS_FAIL:
    case LIST_NEWS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CLEAR_NEWS_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
