

export const GET_ADDPRICE_REQUEST = 'GET_ADDPRICE_REQUEST';
export const GET_ADDPRICE_SUCCESS = 'GET_ADDPRICE_SUCCESS';
export const GET_ADDPRICE_FAIL = 'GET_ADDPRICE_FAIL';


export const ALL_ADDPRICE_REQUEST = 'ALL_ADDPRICE_REQUEST';
export const ALL_ADDPRICE_SUCCESS = 'ALL_ADDPRICE_SUCCESS';
export const ALL_ADDPRICE_FAIL = 'ALL_ADDPRICE_FAIL';

export const CREATE_ADDPRICE_REQUEST = 'CREATE_ADDPRICE_REQUEST';
export const CREATE_ADDPRICE_SUCCESS = 'CREATE_ADDPRICE_SUCCESS';
export const CREATE_ADDPRICE_FAIL = 'CREATE_ADDPRICE_FAIL';

export const UPDATE_ADDPRICE_REQUEST = 'UPDATE_ADDPRICE_REQUEST';
export const UPDATE_ADDPRICE_SUCCESS = 'UPDATE_ADDPRICE_SUCCESS';
export const UPDATE_ADDPRICE_FAIL = 'UPDATE_ADDPRICE_FAIL';


export const DELETE_ADDPRICE_REQUEST = 'DELETE_ADDPRICE_REQUEST';
export const DELETE_ADDPRICE_SUCCESS = 'DELETE_ADDPRICE_SUCCESS';
export const DELETE_ADDPRICE_FAIL = 'DELETE_ADDPRICE_FAIL';
export const DELETE_ADDPRICE_RESET = 'DELETE_ADDPRICE_RESET';

export const CLEAR_ADDPRICE_ERRORS = 'CLEAR_ADDPRICE_ERRORS';
