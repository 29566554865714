/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL mutations
import useGetLink from './getImageLink';
import uploadFile from '../utils/uploadFile';
import deleteFile from '../utils/deleteFile';

const useHandleCampUser = () => {
  const [campUserDetails, setCampUser] = useState();
  const [campList, setCampList] = useState();

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { getLink } = useGetLink();

  const fetchCampUsers = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.listCamps));
      const data = response?.data?.listCamps?.items;
      const modifiedCamps = data?.map((user) => {
        return {
          ...user,
          id: user?.id,

          name: user?.participantName,
          email: user?.email,
          status: 'Confirmed',
          //
          zone: 'Camp',
          // Add other attributes you want to include
        };
      });
      setCampList(modifiedCamps);
      setLoading(false);
      return modifiedCamps;
    } catch (error) {
      console.error('Error fetching training program users:', error);
      setLoading(false);
    }
  };

  const fetchCampUserById = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.getCamp, { id }));
      const data = response?.data?.getCamp;
      const profileLink = await getLink(data?.profile);
      const d = {
        ...data,
        profileLink,
      };
      setCampUser(d);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching event details:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  const deleteCampUserById = async (idToDelete) => {
    try {
      setLoading(true);

      const idDelete = id || idToDelete;

      const response = await API.graphql({
        query: mutations.deleteCamp,
        variables: { input: { id: idToDelete } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      setCampUser(null);
      setLoading(false);
      toast.success('Camp Data Deleted Successfully!');

      setCampUser([]);
      setLoading(false);
      navigate('/dashboard/people');
    } catch (error) {
      console.error('Error deleting event:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  const updateCampById = async (data) => {
    try {
      setLoading(true);
      const { oldProfile, profile } = data;
      console.log(`updateCampById`, oldProfile);
      if (oldProfile !== profile) {
        const deleted = await deleteFile(oldProfile);
        // if (deleted) {
        // }
        const p = await uploadFile(profile);
        data.profile = p;
      }
      delete data?.oldProfile;

      const response = await API.graphql({
        query: mutations.updateCamp,
        variables: { input: { id, ...data } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedData = response?.data?.updateCamp;

      const profileLink = await getLink(response?.data?.updateCamp?.profile);
      const d = {
        ...data,
        profileLink,
      };
      setCampList((prevList) => prevList.map((user) => (user.id === updatedData.id ? updatedData : user)));

      setCampUser(d);
      setLoading(false);
      toast.success('Camp Data Updated Successfully!');
    } catch (error) {
      console.error('Error updating event:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  useEffect(() => {
    if (id) {
      fetchCampUserById();
    }
    fetchCampUsers();
  }, [id]);

  return { campUserDetails, campList, fetchCampUsers, loading, deleteCampUserById, updateCampById };
};

export default useHandleCampUser;
