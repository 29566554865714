import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// or for dayjs
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ScoreComponent from './ScoreComponent';
import RecordBox from './RecordBox';
import useUpdateScoreByIdOrAge from '../../hooks/updateTournamentScore';
import Loader from '../Loader';


const Score = React.memo(({ score, setListScores, setCustomLoading, setRounds }) => {
  const [firstTeamDetails, setFirstTeamDetails] = useState([]);
  const [secondTeamDetails, setSecondTeamDetails] = useState([]);
  const [firstTeam, setFirstTeam] = useState({});
  const [secondTeam, setSecondTeam] = useState({});
  const { updateScoreById, loading } = useUpdateScoreByIdOrAge();
  const [stateSetter, setStateSetter] = useState({
    date: '',
    time: '',
    courtNo: '',
    record: '',
    ppg: '',
    rpe: '',
    apg: '',
    ranking: '',
  });

  useEffect(() => {
    if (score.firstTeamDetails) {
      setFirstTeamDetails(score.firstTeamDetails);
    }
    if (score.secondTeamDetails) {
      setSecondTeamDetails(score.secondTeamDetails);
    }
    if (score.firstTeam) {
      setFirstTeam(score.firstTeam);
    }
    if (score.secondTeam) {
      setSecondTeam(score.secondTeam);
    }
    setStateSetter({
      date: score?.date,
      time: score?.time,
      courtNo: score?.courtNo,
      record: score.record,
      ppg: score.ppg,
      rpe: score.rpe,
      apg: score.apg,
      ranking: score.ranking,
    });
  }, [score]);

  const handleFirstTeamDetailsChange = (index, field, value) => {
    const updatedFirstTeamDetails = [...firstTeamDetails];
    updatedFirstTeamDetails[index][field] = value;
    setFirstTeamDetails(updatedFirstTeamDetails);
  };

  console.log("Score Page Score", score)

  const handleSecondTeamDetailsChange = (index, field, value) => {
    const updatedSecondTeamDetails = [...secondTeamDetails];
    updatedSecondTeamDetails[index][field] = value;
    setSecondTeamDetails(updatedSecondTeamDetails);
  };

  const onChangeSetter = (field, value) => {
    setStateSetter((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    console.log("Filed", field, value, stateSetter)
  };
  const handleFirstTeamChange = (value) => {
    setFirstTeam(prevTeam => ({
      ...prevTeam,
      score: value
    }));
  };

  const handleSecondTeamChange = (value) => {
    setSecondTeam(prevTeam => ({
      ...prevTeam,
      score: value
    }));
  };
  const updateScore = async (winner, winnerTeam) => {
    // Perform score update logic here
    setCustomLoading(true);
    let data;
    console.log('ISWINNER', winner);
    if (winner) {
      data = {
        firstTeamDetails,
        secondTeamDetails,
        firstTeam: { ...firstTeam, score: firstTeam.score },
        secondTeam: { ...secondTeam, score: secondTeam.score },
        id: score.id,
        ...stateSetter,
        winner: winnerTeam,
        round: score.round,

        eventStatus: 'EVENT_ENDED',
      };
    } else {
      data = {
        firstTeamDetails,
        secondTeamDetails,
        firstTeam: { ...firstTeam, score: firstTeam.score },
        secondTeam: { ...secondTeam, score: secondTeam.score },
        id: score.id,
        ...stateSetter,
      };
    }
    console.log('data', data);
    const isWinner = !!winner;
    if (isWinner) {
      const responseEvent = await updateScoreById(data, isWinner);
      console.log("ResponseEvent", responseEvent)
      if (responseEvent?.rounds?.length > 0) {
        setRounds(responseEvent?.rounds)
        if (responseEvent?.scores) {

          setListScores(responseEvent?.scores)
        }
      }
    }else {
      const responseScore = await updateScoreById(data, isWinner);
      console.log('entered')
      console.log("responseScore", responseScore);

      if (responseScore?.rounds?.length > 0) {
        setRounds(responseScore?.rounds)
        if (responseScore?.scores) {

          setListScores(responseScore?.scores)
        }
      }
    }
    setCustomLoading(false)
  };




  return (
    <Box sx={{ bgcolor: 'white', padding: 4 }}>

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box width={'fit'} display={'flex'} marginX={'auto'} gap={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <RecordBox scoreId={score.id} onChangeSetter={onChangeSetter} stateSetter={stateSetter} />

          </LocalizationProvider>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          {/* <h1 className="text-center w-full bg-blue-100 text-black font-semibold p-2">{score?.firstTeam?.teamName}</h1> */}
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{
            backgroundColor: '#F0F8FF', // Light blue background
            color: '#333333', // Dark gray text color
            fontWeight: 'bold',
            p: 2, // Increased padding
            borderRadius: 2 // Rounded corners
          }}>
            <Typography variant="h6">
              {score?.firstTeam?.teamName}
            </Typography>
            <TextField
              variant="outlined"
              label="Score"
              size="small"
              value={firstTeam?.score || 0}
              onChange={(event) => handleFirstTeamChange(event.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ADD8E6', // Light blue border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#87CEEB', // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1E90FF', // Focused border color
                  },
                },
              }}
            />
          </Box>
          <ScoreComponent
            players={firstTeamDetails}
            onChange={(index, field, value) => handleFirstTeamDetailsChange(index, field, value)}
          />
          <br />
          {/* <h1 className="text-center w-full bg-blue-100 text-black font-semibold p-2">{score?.secondTeam?.teamName}</h1> */}
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{
            backgroundColor: '#F0F8FF', // Light blue background
            color: '#333333', // Dark gray text color
            fontWeight: 'bold',
            p: 2, // Increased padding
            borderRadius: 2 // Rounded corners
          }}>
            <Typography variant="h6">
              {score?.secondTeam?.teamName}
            </Typography>
            <TextField
              variant="outlined"
              label="Score"
              size="small"
              value={secondTeam?.score || 0}
              onChange={(event) => handleSecondTeamChange(event.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ADD8E6', // Light blue border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#87CEEB', // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1E90FF', // Focused border color
                  },
                },
              }}
            />
          </Box>
          <ScoreComponent
            players={secondTeamDetails}
            onChange={(index, field, value) => handleSecondTeamDetailsChange(index, field, value)}
          />
        </Box>
      </Box>
      <div className="w-full flex justify-center items-center py-5 ">
        <button
          disabled={score?.eventStatus === 'EVENT_ENDED' || loading}
          onClick={() => updateScore(false)}
          className={`mx-auto wit bg-orange-500 p-4 px-10 text-white ${score.eventStatus === 'EVENT_ENDED' ? 'opacity-50' : ''
            } rounded-md`}
        >
          Update
        </button>
        <button
          disabled={score?.eventStatus === 'EVENT_ENDED' || loading}
          onClick={() => updateScore(true, score.firstTeamDetails[0].teamId)}
          className={`mx-auto wit bg-green-500 p-4 px-10 text-white ${score.eventStatus === 'EVENT_ENDED' ? 'opacity-50' : ''
            } rounded-md`}
        >
          {score.firstTeamDetails[0]?.teamName} Winner
        </button>
        <button
          disabled={score?.eventStatus === 'EVENT_ENDED' || loading}
          onClick={() => updateScore(true, score.secondTeamDetails[0].teamId)}
          className={`mx-auto wit bg-green-500 p-4 px-10 text-white ${score.eventStatus === 'EVENT_ENDED' ? 'opacity-50' : ''
            } rounded-md`}
        >
          {score.secondTeamDetails[0]?.teamName} Winner
        </button>
      </div>
    </Box>
  );
});

export default Score;
