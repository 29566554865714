import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Stack,
  FormGroup,
  FormControlLabel,
  Box,
  Typography,
  Container,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import useGetCategory from '../../../hooks/getCategoryList';
import { createCoupon, updateCoupon, getAllCoupons } from "../../../redux/actions/CouponsAction";

AddCouponSideBar.propTypes = {
  openCoupon: PropTypes.bool,
  onOpenCoupon: PropTypes.func,
  onCloseCoupon: PropTypes.func,
};

export default function AddCouponSideBar({ 
  openCoupon, onOpenCoupon, onCloseCoupon, editData,
  updateCouponDetails,
  createDiscountCoupons,
  hidden=false
 }) {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [percentageDiscount, setPercentageDiscount] = useState('');
  const [flatDiscount, setFlatDiscount] = useState('');
  const [flatDiscountOnUpto, setFlatDiscountOnUpto] = useState('');
  const [percentageDiscountOnUpto, setPercentageDiscountOnUpto] = useState('');
  const [isUserLimit, setIsUserLimit] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [uniqueProduct, setUniqueProduct] = useState(false);
  const [allProductsDiscount, setAllProductsDiscount] = useState(false);
  const [productCategories, setProductCategories] = useState('');
  const [maxUsageLimit, setMaxUsageLimit] = useState(null);
  const [id, setId] = useState('');
  const [type, setType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [validFrom, setValidFrom] = useState(''); // Initialize validFrom state
  const [validTo, setValidTo] = useState(''); // Initialize validFrom state

  const isEditMode = !!editData; // Check if editData is present

  const { category, loading: categoryListLoading } = useGetCategory();

  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      const validFrom = editData?.validFrom ? new Date(editData.validFrom) : null;
      const formattedValidFrom = validFrom instanceof Date && !isNaN(validFrom) ? validFrom.toISOString().slice(0, 10) : '';
     
      // const formattedValidFrom = new Date(editData?.validFrom).toISOString().slice(0, 10);
      const validTo = editData?.validTo ? new Date(editData.validTo) : null;
      const formattedValidTo = validTo instanceof Date && !isNaN(validTo) ? validTo.toISOString().slice(0, 10) : '';
     
    
      // const formattedValidTo = new Date(editData?.validTo).toISOString().slice(0, 10);

      setCode(editData?.code || '');
      setDescription(editData?.description || '');
      setPercentageDiscount(editData?.couponValue || '');
      setFlatDiscount(editData?.couponValue || '');
      setFlatDiscountOnUpto(editData?.discountOnUpto || '');
      setPercentageDiscountOnUpto(editData?.discountOnUpto || '');
      setIsUserLimit(editData?.isUserLimit || false);
      setIsActive(editData?.isActive || false);
      setUniqueProduct(editData?.uniqueProduct || false);
      setAllProductsDiscount(editData?.allProductsDiscount || false);
      setProductCategories(editData?.productCategories || '');
      setMaxUsageLimit((editData?.maxUsageLimit || null));
      setId(editData?.id || '');
      setType(editData?.type || '');
      setValidFrom(formattedValidFrom || '');
      setValidTo(formattedValidTo || '');
    }
  }, [editData, isEditMode]);
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  // ... (other code)
  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleValidFromChange = (event) => {
    setValidFrom(event.target.value); // Update validFrom state when the date is changed
  };

  const handleValidToChange = (event) => {
    setValidTo(event.target.value); // Update validFrom state when the date is changed
  };
  const handlePercentageDiscountChange = (event) => {
    setPercentageDiscount(event.target.value);
  };

  const handleFlatDiscountChange = (event) => {
    setFlatDiscount(event.target.value); // Update validFrom state when the date is changed
  };
  const handleFlatDiscountOnUptoChange = (event) => {
    setFlatDiscountOnUpto(event.target.value); // Update validFrom state when the date is changed
  };

  const handlePercentageDiscountOnUptoChange = (event) => {
    setPercentageDiscountOnUpto(event.target.value); // Update validFrom state when the date is changed
  };

  const handleCategoriesChange = (event) => {
    setProductCategories(event.target.value); // Update validFrom state when the date is changed
  };

  const handleIsLimitChange = () => {
    setIsUserLimit(!isUserLimit); // Toggling the isLimit state
  };
  const handleAllProductsDiscountChange = () => {
    setAllProductsDiscount(!allProductsDiscount); // Toggling the isLimit state
    setUniqueProduct(allProductsDiscount);
  };
  const handleUniqueProductChange = () => {
    setUniqueProduct(!uniqueProduct); // Toggling the isLimit state
    setAllProductsDiscount(uniqueProduct); // Toggling the isLimit state

  };
  const handleIsActiveChange = () => {
    setIsActive(!isActive); // Toggling the isActive state
  };
  const handleMaxUsageLimitChange = (event) => {
    setMaxUsageLimit(event.target.value); // Update validFrom state when the date is changed
  };

  const resetForm = () => {
    setCode('');
    setPercentageDiscount('');
    setUniqueProduct('');
    setAllProductsDiscount('');
    setProductCategories('');
    setDescription('');
    setType('');
    setErrorMessage('');
    setValidFrom('');
    setValidTo('');
    setFlatDiscount('');
    setFlatDiscountOnUpto('');
    setPercentageDiscountOnUpto('');
    setIsUserLimit('');
    setIsActive('');
    setMaxUsageLimit(null);
  };

  const handleCancel = () => {
    resetForm(); // Call the resetForm function to clear all fields
    onCloseCoupon(); // Close the modal
  };


  const handleTypeChange = (event) => {
    setType(event.target.value);
  };



  const handleUploadData = async () => {
    if (!code) {
      toast.error('Code is required.');
      return;
    }

    if (!description) {
      toast.error('Description is required.');
      return;
    }

    if (!type) {
      toast.error('Type is required.');
      return;
    }

    // Additional validation based on the selected type
    if (type === 'FLAT_DISCOUNT') {
      if (!flatDiscount) {
        toast.error('Flat Discount is required.');
        return;
      }
      if (!flatDiscountOnUpto) {
        toast.error('Flat Discount On Upto is required.');
        return;
      }
    } else if (type === 'PERCENTAGE_DISCOUNT') {
      if (!percentageDiscount) {
        toast.error('Percentage Discount is required.');
        return;
      }
      if (!percentageDiscountOnUpto) {
        toast.error('Percentage Discount On Upto is required.');
        return;
      }
    }
    // Check if Is Limit is checked and User Limit is empty
    if (isUserLimit && !maxUsageLimit) {
      toast.error('User Limit is required.');
      return;
    }
    if (!validFrom) {
      toast.error('Valid From date is required.');
      return;
    }

    if (!validTo) {
      toast.error('Valid To date is required.');
      return;
    }

    // Check if Unique Products is checked and Product Categories is empty
    if (uniqueProduct && !productCategories) {
      toast.error('Product Categories are required.');
      return;
    }
    const maxUsageLimitData = isUserLimit ? maxUsageLimit || null : null;
    const formattedValidFrom = new Date(validFrom);
    const formattedValidTo = new Date(validTo);
    let data;
    if (type === 'FLAT_DISCOUNT') {
      data = {
        type,
        code,
        description,
        couponValue: flatDiscount,
        discountOnUpto: flatDiscountOnUpto,
        isUserLimit,
        isActive,
        uniqueProduct,
        allProductsDiscount,
        productCategories,
        maxUsageLimit: maxUsageLimitData,
        validFrom: formattedValidFrom,
        validTo: formattedValidTo,
      };
    }

    if (type === 'PERCENTAGE_DISCOUNT') {
      data = {
        type,
        code,
        description,
        couponValue: percentageDiscount,
        discountOnUpto: percentageDiscountOnUpto,
        isUserLimit,
        isActive,
        uniqueProduct,
        productCategories,
        allProductsDiscount,
        maxUsageLimit: maxUsageLimitData,
        validFrom: formattedValidFrom,
        validTo: formattedValidTo,
      };
    }

    // if (type === 'PERCENTAGE_DISCOUNT' || type === 'FLAT_DISCOUNT') {
    //   if (isEditMode) {
    //     //  await updateCouponDetails(id, data);
    //     await dispatch(updateCoupon(id, data));
    //   } else {
    //     //  await createCoupon(data);
    //     await dispatch(createCoupon(data));
    //   }

    // }
    onCloseCoupon();
    if (type === 'PERCENTAGE_DISCOUNT' || type === 'FLAT_DISCOUNT') {

    if (isEditMode) {
      // Set loading state for update
      const response = await updateCouponDetails(id, data);
     
    } else {
      const response = await createDiscountCoupons(data);
    
    }
  }
    resetForm(); // Reset form fields
    // await dispatch(getAllCoupons());
    // await fetchCoupons();
  };


  return (
    <>
      <Button
        disableRipple
        hidden={hidden}
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenCoupon}
        style={buttonStyles}
      >
        Add Coupon
      </Button>
      <Dialog open={openCoupon} onClose={handleCancel}>
        <DialogTitle>{isEditMode ? 'Edit Coupon' : 'Add New Coupon'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new Coupon.</DialogContentText>

          <FormControl fullWidth margin="dense">
            <TextField
              autoFocus
              margin="dense"
              label="Code"
              fullWidth
              className='uppercase'
              value={code}
              onChange={handleCodeChange}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <TextField
              autoFocus
              margin="dense"
              label="Description"
              fullWidth
              value={description}
              onChange={handleDescriptionChange}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              label="Type"
              name="type"
              value={type}
              fullWidth
              className="uppercase"
              variant="outlined"
              onChange={handleTypeChange}
            >
              <MenuItem className="uppercase" value="PERCENTAGE_DISCOUNT">
                PERCENTAGE_DISCOUNT
              </MenuItem>
              <MenuItem className="uppercase" value="FLAT_DISCOUNT">
                FLAT_DISCOUNT
              </MenuItem>

            </Select>
          </FormControl>
          {type === 'PERCENTAGE_DISCOUNT' && (
            <>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Percentage Discount"
                  fullWidth
                  value={percentageDiscount}
                  onChange={handlePercentageDiscountChange}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Percentage Discount On Upto"
                  fullWidth
                  value={percentageDiscountOnUpto}
                  onChange={handlePercentageDiscountOnUptoChange}
                />
              </FormControl>

              {errorMessage && (
                <Typography variant="body2" color="error" sx={{ marginTop: 2, marginBottom: 2 }}>
                  {errorMessage}
                </Typography>
              )}
            </>
          )}

          {type === 'FLAT_DISCOUNT' && (
            <>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Flat Discount"
                  fullWidth
                  value={flatDiscount}
                  onChange={handleFlatDiscountChange}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Flat Discount On Upto"
                  fullWidth
                  value={flatDiscountOnUpto}
                  onChange={handleFlatDiscountOnUptoChange}
                />
              </FormControl>
            </>
          )}


          <FormControl margin="dense">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={isUserLimit} onChange={handleIsLimitChange} color="primary" />}
                label="Is Limit"
              />
            </FormGroup>
          </FormControl>

          {isUserLimit && (
            <>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="User Limit"
                  fullWidth
                  value={maxUsageLimit}
                  onChange={handleMaxUsageLimitChange}
                />
              </FormControl>
            </>
          )}

          <FormControl fullWidth margin="dense">
            <TextField
              type="date"
              label="valid From"
              value={validFrom}
              name="validFrom"
              onChange={handleValidFromChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <TextField
              type="date"
              label="Valid To"
              value={validTo}
              name="validTo"
              onChange={handleValidToChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl margin="dense">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={isActive} onChange={handleIsActiveChange} color="primary" />}
                label="Is Active"
              />
            </FormGroup>
          </FormControl>

          <FormControl margin="dense">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={uniqueProduct} onChange={handleUniqueProductChange} color="primary" />}
                label="Unique Products"
              />
            </FormGroup>
          </FormControl>
          {uniqueProduct && (
            <>
              <FormControl fullWidth margin="dense">
                <InputLabel id="type">Product Categories</InputLabel>
                <Select
                  labelId="productCategories"
                  label="product Categories"
                  name="productCategories"
                  value={productCategories}
                  fullWidth
                  className="uppercase"
                  variant="outlined"
                  onChange={handleCategoriesChange}
                >

                  {category?.map((record, i) => (
                    <MenuItem key={i} className="uppercase" value={record?.title}>
                      {record?.title}
                    </MenuItem>
                  ))}


                </Select>
              </FormControl>
            </>
          )}
          <FormControl margin="dense">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={allProductsDiscount} onChange={handleAllProductsDiscountChange} color="primary" />}
                label="All Products Discount"
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={handleCancel} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
