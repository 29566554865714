/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import useAllLeaguePlayerList from './getAllLeaguesPlayer';

import useVolunteersList from './getAllVlounteersList';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries'; // Make sure to import your GraphQL queries
import { listUsers } from './getUsersFromAws';
import deleteFile from '../utils/deleteFile';

const useGetAllUsers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const { modifiedLeaguesUsersList } = useAllLeaguePlayerList();
  const { modifiedvVolunteersList } = useVolunteersList();
  const groupname = 'Users'; // Provide the group name
  const limit = 60; // Specify the limit as needed
  const [loading, setLoading] = useState(true);

  const listAllUsers = async () => {
    try {
      setLoading(true);

      // Fetch users from AWS
      const awsUsers = await listUsers(groupname, limit);
      // console.log("user - ", awsUsers);
      // Process AWS users data
      const processedAWSUsers = awsUsers?.Users?.map((user) => {
        const dob = user?.Attributes.find((attr) => attr.Name === 'birthdate')?.Value;
        const age = dob ? calculateAge(dob) : null;

        return {
          id: user?.Username,
          enabled: user?.Enabled,
          status: user?.UserStatus,
          email: user?.Attributes.find((attr) => attr.Name === 'email')?.Value,
          name: user?.Attributes.find((attr) => attr.Name === 'name')?.Value,
          participantName: user?.Attributes.find((attr) => attr.Name === 'name')?.Value,
          isVerified: user?.UserStatus === 'CONFIRMED',
          phoneNumber: user?.Attributes.find((attr) => attr.Name === 'phone_number')?.Value,
          zone: `User`,
          age: age,

          // Add other attributes you want to include
        };
      });
      function calculateAge(dobString) {
        const dob = new Date(dobString);
        const now = new Date();
        let age = now.getFullYear() - dob.getFullYear();

        // Adjust age if the birthday hasn't occurred yet this year
        if (now < new Date(now.getFullYear(), dob.getMonth(), dob.getDate())) {
          age--;
        }

        return age;
      }
      const playerDataPromises = processedAWSUsers.map(async (user) => {
        const playersQuery = await API.graphql({
          query: queries.listPlayers,
          variables: {
            filter: {
              userId: {
                eq: user.id,
              },
              isVerified: {
                eq: true,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const player5x5sQuery = await API.graphql({
          query: queries.listPlayer5x5s,
          variables: {
            filter: {
              userId: {
                eq: user.id,
              },
              isVerified: {
                eq: true,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });


        const workWithUsQuery = await API.graphql({
          query: queries.listWorkWithuses,
          variables: {
            filter: {
              userId: {
                eq: user.id,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const eventsQuery = await API.graphql({
          query: queries?.listSignUpEvents,
          variables: {
            filter: {
              userId: {
                eq: user.id,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const volunteersQuery = await API.graphql({
          query: queries.listVolunteers,
          variables: {
            filter: {
              userId: {
                eq: user.id,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const partnerQuery = await API.graphql({
          query: queries.listPartners,
          variables: {
            filter: {
              userId: {
                eq: user.id,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        return {
          user,
          players: playersQuery.data.listPlayers.items,
          player5x5s: player5x5sQuery.data.listPlayer5x5s.items,
          volunteers: volunteersQuery?.data?.listVolunteers?.items,
          workWithUs: workWithUsQuery?.data?.listWorkWithuses?.items,
          partners: partnerQuery?.data?.listPartners?.items,
          signUpEvents: eventsQuery?.data?.listSignUpEvents?.items,
        };
      });

      // Wait for all promises to resolve
      const playerData = await Promise.all(playerDataPromises);

      let modifiedPlayers = [];
      let modifiedPlayer5x5s = [];
      let modifiedVolunteers = [];
      let modifiedEvents = [];
      let modifiedWorkWithUs = [];
      let modifiedPartners = [];

      playerData.forEach(({ players,player5x5s, volunteers, workWithUs, partners, signUpEvents }) => {
        // Modify 'players' array
        modifiedPlayers = [
          ...modifiedPlayers,
          ...(players || []).map((user) => ({
            ...user,
            // id: user?.userId,
            name: user?.participantName,
            email: user?.email,
            isVerified: user?.isVerified,
            status: 'Confirmed',

            zone: 'Player',
            // Add other attributes you want to include
          })),
        ];

        modifiedPlayer5x5s = [
          ...modifiedPlayer5x5s,
          ...(player5x5s || []).map((user) => ({
            ...user,
            // id: user?.userId,
            name: user?.participantName,
            email: user?.email,
            isVerified: user?.isVerified,
            status: 'Confirmed',

            zone: 'Player5x5',
            // Add other attributes you want to include
          })),
        ];


        modifiedPartners = [
          ...modifiedPartners,
          ...(partners || []).map((user) => ({
            ...user,
            name: user?.name,
            email: user?.email,
            isVerified: true,
            status: 'Confirmed',

            zone: 'Partner',
            // Add other attributes you want to include
          })),
        ];

        modifiedEvents = [
          ...modifiedEvents,
          ...(signUpEvents || []).map((user) => ({
            ...user,
            name: user?.league,
            email: user?.email,
            isVerified: true,
            status: 'Confirmed',

            zone: 'Events',
            // Add other attributes you want to include
          })),
        ];

        modifiedWorkWithUs = [
          ...modifiedWorkWithUs,
          ...(workWithUs || []).map((user) => ({
            ...user,
            name: user?.participantName,
            email: user?.email,
            isVerified: true,
            status: 'Confirmed',

            zone: 'WorkWithUs',
            // Add other attributes you want to include
          })),
        ];

        // Modify 'volunteers' array
        modifiedVolunteers = [
          ...modifiedVolunteers,
          ...(volunteers || []).map((user) => ({
            ...user,
            name: user?.participantName,
            email: user?.email,
            isVerified: true,
            status: 'Confirmed',
            zone: 'Volunteer',
            // Add other attributes you want to include
          })),
        ];
      });

      // Get the verified volunteers and leagues users

      // Combine all users

      const combinedUsers = [
        ...processedAWSUsers,
        ...modifiedVolunteers,
        ...modifiedPlayers,
        ...modifiedPlayer5x5s,
        ...modifiedPartners,
        ...modifiedWorkWithUs,
        ...modifiedEvents,
      ];

      console.log('combinedUsers', combinedUsers, modifiedLeaguesUsersList);
      setAllUsers(combinedUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error listing users', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    listAllUsers();
  }, [modifiedLeaguesUsersList, modifiedvVolunteersList]);

  const deletePlayer5x5 = async (playerId) => {
    try {
      setLoading(true);
     
      const player5x5Response = await API.graphql({
        query: queries.listPlayer5x5s,
        variables: {
          filter: {
            id: {
              eq: playerId,
            },
          },
        },
      });
      const isDeleted = await deleteFile(player5x5Response?.data?.listPlayer5x5s?.items[0]?.profile);
      // Make an API call to delete the player with playerId
      const isDeletedFile = await deleteFile(player5x5Response?.data?.listPlayer5x5s?.items[0]?.document);
      if (!isDeleted || !isDeletedFile) {
        toast.success('Error Deleting File!');
        setLoading(false);
        return false;
      }

      const response = await API.graphql({
        query: mutations.deletePlayer5x5,
        variables: { input: { id: playerId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user.id === playerId && user.zone === 'Player5x5')));
      toast.success('Deleted!');
      setLoading(false);
      // After successful deletion, update the state by removing the player
    } catch (error) {
      console.error('Error deleting player:', error);
    } finally {
      setLoading(false);
    }
  };

  const deletePlayer = async (playerId) => {
    try {
      setLoading(true);
      // Perform deletion of the player based on the playerId
      // For example:
      // const responsed = await API.graphql(graphqlOperation(queries.getPlayer, { input: { id: playerId } }), {
      //   authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      const playerResponse = await API.graphql({
        query: queries.listPlayers,
        variables: {
          filter: {
            id: {
              eq: playerId,
            },
          },
        },
      });
      const isDeleted = await deleteFile(playerResponse?.data?.listPlayers?.items[0]?.profile);
      // Make an API call to delete the player with playerId
      const isDeletedFile = await deleteFile(playerResponse?.data?.listPlayers?.items[0]?.document);
      if (!isDeleted || !isDeletedFile) {
        toast.success('Error Deleting File!');
        setLoading(false);
        return false;
      }

      const response = await API.graphql({
        query: mutations.deletePlayer,
        variables: { input: { id: playerId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user.id === playerId && user.zone === 'Player')));
      toast.success('Deleted!');
      setLoading(false);
      // After successful deletion, update the state by removing the player
    } catch (error) {
      console.error('Error deleting player:', error);
    } finally {
      setLoading(false);
    }
  };


  const deleteVolunteer = async (volunteerId) => {
    try {
      setLoading(true);
      // Perform deletion of the volunteer based on the volunteerId
      // Similar to deletePlayer function, make an API call to delete the volunteer

      const response = await API.graphql({
        query: mutations.deleteVolunteer,
        variables: { input: { id: volunteerId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const isDeleted = await deleteFile(response?.data?.deleteVolunteer?.profile);
      const isDeletedFile = await deleteFile(response?.data?.deleteVolunteer?.document);

      toast.success('Deleted!');
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user.id === volunteerId && user.zone === 'Volunteer')));
      setLoading(false);
    } catch (error) {
      console.error('Error deleting volunteer:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteWorkWithUs = async (volunteerId) => {
    try {
      setLoading(true);
      // Perform deletion of the volunteer based on the volunteerId
      // Similar to deletePlayer function, make an API call to delete the volunteer
      const response = await API.graphql({
        query: mutations.deleteWorkWithUs,
        variables: { input: { id: volunteerId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const isDeleted = await deleteFile(response?.data?.deleteWorkWithUs?.profile);
      const isDeletedFile = await deleteFile(response?.data?.deleteWorkWithUs?.document);

      toast.success('Deleted!');
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user.id === volunteerId && user.zone === 'WorkWithUs')));
      setLoading(false);
    } catch (error) {
      console.error('Error deleting volunteer:', error);
    } finally {
      setLoading(false);
    }
  };
  const deletePartner = async (partnerId) => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: mutations.deletePartner,
        variables: { input: { id: partnerId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      toast.success('Partner Deleted Successfully!');
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user.id === partnerId && user.zone === 'Partner')));
      setLoading(false);
    } catch (error) {
      console.error('Error deleting volunteer:', error);
    } finally {
      setLoading(false);
    }
  };
  const deleteEvent = async (eventId) => {
    try {
      setLoading(true);

      // Perform deletion of the event based on the eventId
      // Similar to deletePlayer and deleteVolunteer, make an API call to delete the event
      // await API.graphql({ ... });
      const response = await API.graphql({
        query: mutations.deleteSignUpEvent,
        variables: { input: { id: eventId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const isDeleted = await deleteFile(response?.data?.deleteVolunteer?.profile);
      const isDeletedFile = await deleteFile(response?.data?.deleteVolunteer?.document);

      toast.success('Deleted!');
      // After successful deletion, update the state by removing the event
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user.id === eventId && user.zone === 'Events')));
      setLoading(false);
    } catch (error) {
      console.error('Error deleting event:', error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const suspendUser = async (username) => {
    console.log('Username: ', username);
    try {
      setLoading(true);

      const checkoutPayload = {
        body: {
          Username: username,
        },
      };

      setLoading(true);

      const response = await API.post('userApiForSfbayarea', '/user/disable', checkoutPayload);
      const updatedAllUsers = allUsers.map((user) => {
        if (user.id === username && user.zone === 'User') {
          return {
            ...user,
            enabled: false, // Set the enabled attribute to false for the suspended user
          };
        }
        return user;
      });

      setAllUsers(updatedAllUsers);

      setLoading(false);

      toast.success('User Suspended Successfully.');
    } catch (error) {
      console.error('Error suspending user:', error);
      toast.error('Error suspending user.');
    } finally {
      setLoading(false);
    }
  };

  const enableUser = async (username) => {
    try {
      setLoading(true);
      const checkoutPayload = {
        body: {
          Username: username,
        },
      };

      setLoading(true);

      const response = await API.post('userApiForSfbayarea', '/user/enable', checkoutPayload);
      const updatedAllUsers = allUsers.map((user) => {
        if (user.id === username && user.zone === 'User') {
          return {
            ...user,
            enabled: true, // Set the enabled attribute to false for the suspended user
          };
        }
        return user;
      });
      setAllUsers(updatedAllUsers);
      setLoading(false);

      toast.success('User Enabled.');
    } catch (error) {
      console.error('Error Enabling user:', error);
      toast.error('Error Enabling user');
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (username) => {
    try {
      setLoading(true);

      const [
        volunteerResponse,
        workWithUsResponse,
        partnerResponse,
        signUpEventsResponse,
        trainingProgramResponse,
        tournamentResponse,
        campResponse,
        playerResponse,
        player5x5Response,
        clinicResponse,
        playerCart,
      ] = await Promise.all([
        API.graphql({ query: queries.listVolunteers, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listWorkWithuses, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listPartners, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listSignUpEvents, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listTrainingPrograms, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listTournaments, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listCamps, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listPlayers, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listPlayer5x5s, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listClinics, variables: { filter: { userId: { eq: username } } } }),
        API.graphql({ query: queries.listCartItems, variables: { filter: { userid: { eq: username } } } }),
      ]);

      const deletePromises = [];

      // Delete cart items and associated files
      playerCart?.data?.listCartItems?.items.forEach((cartItem) => {
        deletePromises.push(
          API.graphql({
            query: mutations.deleteCartItem,
            variables: { input: { id: cartItem?.id } },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          })
        );
        // Delete files associated with cart items (if needed)
      });

      // Function to handle deletion of an item and its associated files
      const deleteItemAndFiles = async (mutation, item) => {
        if (item?.length > 0) {
          item.forEach(async (currentItem) => {
            const deleteP = API.graphql({
              query: mutation,
              variables: { input: { id: currentItem?.id } },
              authMode: GRAPHQL_AUTH_MODE.API_KEY,
            });

            if (deleteP?.data?.mutation) {
              await deleteFile(deleteP.data.mutation.profile);
              await deleteFile(deleteP.data.mutation.document);
              // Delete other associated files as needed
            }
            deletePromises.push(deleteP);
            // Delete files associated with currentItem (if needed)
          });
        }
      };

      await Promise.all([
        deleteItemAndFiles(mutations.deletePlayer, playerResponse?.data?.listPlayers?.items),
        deleteItemAndFiles(mutations.deletePlayer, player5x5Response?.data?.listPlayer5x5s?.items),
        deleteItemAndFiles(mutations.deleteClinic, clinicResponse?.data?.listPlayers?.items),
        deleteItemAndFiles(mutations.deleteVolunteer, volunteerResponse?.data?.listVolunteers?.items),
        deleteItemAndFiles(mutations.deleteSignUpEvent, signUpEventsResponse?.data?.listSignUpEvents?.items),
        deleteItemAndFiles(mutations.deleteWorkWithUs, workWithUsResponse?.data?.listWorkWithuses?.items),
        deleteItemAndFiles(mutations.deletePartner, partnerResponse?.data?.listPartners?.items),
        deleteItemAndFiles(mutations.deleteTrainingProgram, trainingProgramResponse?.data?.listTrainingPrograms?.items),
        deleteItemAndFiles(mutations.deleteTournament, tournamentResponse?.data?.listTournaments?.items),
        deleteItemAndFiles(mutations.deleteCamp, campResponse?.data?.listCamps?.items),
      ]);

      // Delete the user
      const deleteUserPayload = { body: { Username: username } };
      await API.del('userApiForSfbayarea', '/user/delete', deleteUserPayload);
      // Finally, delete the user

      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user?.userId === username && user.zone === 'Events')));
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user?.userId === username && user.zone === 'Player')));
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user?.userId === username && user.zone === 'Player5x5')));
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user?.userId === username && user.zone === 'Volunteer')));
      setAllUsers((prevUsers) =>
        prevUsers.filter((user) => !(user?.userId === username && user.zone === 'WorkWithUs'))
      );
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user?.userId === username && user.zone === 'Partner')));
      setAllUsers((prevUsers) => prevUsers.filter((user) => !(user?.id === username && user.zone === 'User')));

      toast.success('User Deleted Successfully.');
      // await API.del('user', '/user/delete', deleteUserPayload);
    } catch (error) {
      console.error('Error Deleting user:', error);
      toast.error('Error Deleting user.');
    } finally {
      setLoading(false);
    }
  };

  return {
    allUsers,
    deleteWorkWithUs,
    deletePartner,
    deleteUser,
    suspendUser,
    enableUser,
    loading,
    deleteEvent,
    deletePlayer,
    deleteVolunteer,
  };
};

export default useGetAllUsers;
