import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { toast } from "react-toastify"; // Assuming you have a toast library for displaying messages
import { useParams } from "react-router-dom";

const useFindUserById = () => {
  const [loading, setLoading] = useState(false);
const {id}=useParams();
const [userDetails,setUserDetails]=useState();
console.log('params',id)
useEffect(()=>{

  const findUserById = async () => {
    try {

      const checkoutPayload = {
        body: {
          Username:id
        },
      };

      setLoading(true);
const Username=id;
      const response = await API.post('userApiForSfbayarea', `/user/${Username}`);

      setUserDetails(response?.user)
      
    } catch (error) {
      console.error("Error Find user Id:", error);
      toast.error("Error Find user");
    } finally {
      setLoading(false);
    }
  };
  findUserById()
},[id])

  return { userDetails,loading };
};

export default useFindUserById;
