import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Import the toast component
import SkeletonTable from '../components/SkeletonTable';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { VerifyUserListHead, VerifyUserListToolbar } from '../sections/@dashboard/verifications';
import useHandlePagintationPlayers5x5 from '../hooks/handlePaginationPlayer5x5';
import { getAllPlayer } from '../redux/actions/PlayerAction';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'participantName', label: 'Name', alignRight: false },
  { id: 'cityOfRegistration', label: 'Zone', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  console.log('applySortFilter', array);
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.participantName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function VerificationsPage() {
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const { loading, notVerifiedPlayers, deletePlayers } = useHandlePagintationPlayers5x5();
  // const { deletePlayerUserById, notVerifiedPlayers, loading } = useHandlePlayerUser();
  const [players, setPlayers] = useState(notVerifiedPlayers || []);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('participantName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRowId, setSelectedRowId] = useState(null); // Add a state to store the selected row's id

  const navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };
 

  const handleOpenMenu = (event, id) => {
    // Pass the id when opening the menu
    setSelectedRowId(id);
    console.log('id', id); // Check if a row is selected
    // Store the selected row's id
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = notVerifiedPlayers?.map((n) => n?.participantName);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleEditNavigate = (id) => {
    if (selectedRowId) {
      console.log('id', id); // Check if a row is selected
      navigate(`/player5x5/verification/${selectedRowId}`); // Use the selected row's id
    }
    handleCloseMenu(); // Close the menu
  };

  const handleDeleteLeageus = async (id) => {
    if (selectedRowId) {
      await deletePlayers(selectedRowId);
      setPlayers((prevList) => prevList.filter((item) => item.id !== selectedRowId));

      setIsDeleteDialogOpen(false);

      // Use the selected row's id
    }
    handleCloseMenu(); // Close the menu
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - notVerifiedPlayers.length) : 0;

  const filteredUsers = applySortFilter(notVerifiedPlayers, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers?.length && !!filterName;
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  return (
    <>
      <Helmet>
        <title>Verifications User</title>
      </Helmet>

      {loading ? (
        <SkeletonTable />
      ) : (
        <>
          <Container sx={{ paddingLeft: '25px', paddingRight: '25px' }}>
            <Dialog
              open={isDeleteDialogOpen}
              onClose={handleDeleteCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Delete User Details</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this User Details?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteLeageus} color="primary" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                User
              </Typography>
             
            </Stack>

            <Card>
              <VerifyUserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <VerifyUserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={notVerifiedPlayers?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, participantMobile, participantName, status, cityOfRegistration, zone, isVerified } =
                          row;
                        const selectedUser = selected.indexOf(participantName) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            sx={{ paddingLeft: '25px' }}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedUser}
                          >
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={participantName} src={row.avatarUrl} />
                                <Typography variant="subtitle2" noWrap>
                                  {participantName}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">{zone}</TableCell>
                            <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>

                            <TableCell align="left">
                              <Typography variant="subtitle2" noWrap>
                                {participantName}
                              </Typography>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={notVerifiedPlayers?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem onClick={handleEditNavigate}>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Edit
            </MenuItem>

            <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
        </>
      )}
    </>
  );
}
