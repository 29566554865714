import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';

const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
};

const CreateComptetiveTeamsModalOne = ({
    title,
    handleClose,
    handleInputChange,
    selectedAgeGroup,
    handleAdd,
    buttonText,
    inputLabel,
    options,
    showCityDropdown,
    selectedCity,
    cityOptions,
    showCoachDropdown,
    selectedCoach,
    coachOptions,
    handleCoachChange
}) => {

    const handleSubmit = (event) => {
        event.preventDefault();  // Prevents default form submission
        handleAdd();
    };

    return (
        <Dialog open onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                name="name"
                                label={inputLabel}
                                fullWidth
                                required
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="dense">
                                <InputLabel id="select-label">Age</InputLabel>
                                <Select
                                    labelId="select-label"
                                    label="Age"
                                    name="agegroup"
                                    value={selectedAgeGroup}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    required
                                >
                                    <MenuItem value="">Select Age</MenuItem>
                                    {options.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {showCityDropdown && (
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id="select-label">City</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        label="City"
                                        name="city"
                                        required
                                        value={selectedCity}
                                        onChange={handleInputChange}
                                        fullWidth
                                        variant="outlined"
                                    >
                                        <MenuItem value="">Select City</MenuItem>
                                        {cityOptions.map((option, index) => (
                                            <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                              {showCoachDropdown && (
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id="coachName">Coach</InputLabel>
                                    <Select
                                        labelId="coachId"
                                        label="Coach"
                                        name="coachId"
                                        required
                                        value={selectedCoach}
                                        onChange={handleInputChange}
                                        fullWidth
                                        variant="outlined"
                                    >
                                        <MenuItem value="">Select Coach</MenuItem>
                                        {coachOptions.map((option, index) => (
                                            <MenuItem key={index} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <input
                                type="file"
                                name="imageURL"
                                required
                                accept="image/*"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginBottom: '16px' }}>
                    <Button onClick={handleClose} variant="outlined" color="secondary" style={{ marginRight: 'auto' }}>
                        Cancel
                    </Button>
                    <Button type="submit" style={buttonStyles} color="primary" variant="contained">
                        {buttonText}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateComptetiveTeamsModalOne;
