import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Button, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import MainSkeleton from '../components/SkeletonLoader';
import {
  TrainingProgramCardList,
  AddNewTrainingProgramCardSideBar,
} from '../sections/@dashboard/trainingprogramcard';
import 'react-toastify/dist/ReactToastify.css';
import handlePaginationTrainingProgramCards from '../hooks/handlePaginationTrainingProgramCards';

// ----------------------------------------------------------------------

export default function TrainingProgramCardsPage() {

  const {
    trainingProgramCardsList,
    nextNextToken,
    loading,
    deleteTrainingProgramCards,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
    addTrainingProgramCard,
    updateTrainingProgramCards,
  } = handlePaginationTrainingProgramCards();

  console.log("TrainingProgramCardList", trainingProgramCardsList)

  const [openTrainingProgramCard, setOpenTrainingProgramCard] = useState(false);
  const [trainingProgramCards, setTrainingProgramCards] = useState(trainingProgramCardsList);


  const handleOpenTrainingProgramCard = () => {
    setOpenTrainingProgramCard(true);
  };
  const handleCloseTrainingProgramCard = () => {
    setOpenTrainingProgramCard(false);
  };

  useEffect(() => {
    setTrainingProgramCards(trainingProgramCardsList || []);
  }, [trainingProgramCardsList]);

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Training Program Cards | Minimal UI </title>
      </Helmet>

      <Container>
        <ToastContainer />
        <Typography variant="h4" sx={{ mb: 5 }}>
          Training Program Cards
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <AddNewTrainingProgramCardSideBar
              openTrainingProgramCard={openTrainingProgramCard}
              onCloseTrainingProgramCard={handleCloseTrainingProgramCard}
              onOpenTrainingProgramCard={handleOpenTrainingProgramCard}
              adddTrainingProgramCard={addTrainingProgramCard}
            />
          </Stack>
        </Stack>
        {loading ? (
          <>
            <MainSkeleton />
          </>
        ) : (
          <TrainingProgramCardList
            trainingProgramCards={trainingProgramCards}
            updateTrainingProgramCards={updateTrainingProgramCards}
            deleteTrainingProgramCards={deleteTrainingProgramCards}
          />
        )}
        {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
            {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}
