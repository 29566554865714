/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import z from 'zod';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL mutations
import getLink from 'src/utils/getLinks';
import deleteFile from 'src/utils/deleteFile';

const ScheduleSchema = z.object({
    firstTeam: z.string(),
    firstTeamId : z.string(),
    secondTeam: z.string(),
    secondTeamId : z.string(),
    firstScore: z.coerce.number(), // Automatically converts strings to numbers
    secondScore: z.coerce.number(), // Automatically converts strings to numbers
    location: z.string(),
    date: z.string(),
    time: z.string()
});


const useHandleSchedule = () => {
    const [schedule, setSchedule] = useState([]);

    const [loading, setLoading] = useState(false);

    const createSchedule = async (teamData) => {
        setLoading(true);
    
        try {
            const parsedData = ScheduleSchema.parse(teamData);  // Validate team data with Zod schema
    
            // Fetch coach details by filtering with firstTeamId
            const firstTeamResponse = await API.graphql(
                graphqlOperation(queries.getComptetiveTeam5X5, { id: parsedData.firstTeamId }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );

            const secondTeamResponse = await API.graphql(
                graphqlOperation(queries.getComptetiveTeam5X5, { id: parsedData.secondTeamId }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );


            const firstTeam = firstTeamResponse?.data?.getComptetiveTeam5X5?.name;
            const secondTeam = secondTeamResponse?.data?.getComptetiveTeam5X5?.name;

            if (!firstTeam) {
                toast.error("Team not found.");
                return null;
            }

            if (!secondTeam) {
                toast.error("Team not found.");
                return null;
            }
            parsedData.time =`${parsedData.time}:00.000`;
            parsedData.date = new Date(parsedData.date).toISOString(); 
            // Include firstTeam in the parsed data
            const dataToCreate = {
                ...parsedData,
                firstTeam, // Add firstTeam to the data being sent
                secondTeam, // Add secondTeam to the data being sent
            };
            console.log('parsed data',parsedData)
            // Proceed to create the team
            const response = await API.graphql(
                graphqlOperation(mutations.createSchedule, { input: dataToCreate }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            if (response?.data?.createSchedule) {
                toast.success("Scheduled successfully!");
                return response.data.createSchedule;  // Return the constructed team data
            } else {
                toast.error("Failed to create team due to missing data.");
                return null;
            }
        } catch (error) {
            toast.error("Failed to schedule.");
            throw error;  // Rethrow error for the calling function to handle
        } finally {
            setLoading(false);
        }
    };
    

    const updateSchedule = async (teamId, teamData) => {
        setLoading(true);

        const parsedData = ScheduleSchema.parse(teamData);
            // Fetch coach details by filtering with firstTeamId
            const firstTeamResponse = await API.graphql(
                graphqlOperation(queries.getComptetiveTeam5X5, { id: parsedData.firstTeamId }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );

            const secondTeamResponse = await API.graphql(
                graphqlOperation(queries.getComptetiveTeam5X5, { id: parsedData.secondTeamId }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );


            const firstTeam = firstTeamResponse?.data?.getComptetiveTeam5X5?.name;
            const secondTeam = secondTeamResponse?.data?.getComptetiveTeam5X5?.name;

            if (!firstTeam) {
                toast.error("Team not found.");
                return null;
            }

            if (!secondTeam) {
                toast.error("Team not found.");
                return null;
            }

            parsedData.date = new Date(parsedData.date).toISOString(); 
            parsedData.firstTeam = firstTeam; 
            parsedData.secondTeam = secondTeam; 
           console.log('teamId',teamId);
        try {
            const response = await API.graphql(
                graphqlOperation(mutations.updateSchedule, { input: { id: teamId, ...parsedData } }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            console.log('udpated adtaa ',response?.data?.updateSchedule)
            toast.success("Schedule updated successfully!");
            return response?.data?.updateSchedule;
        } catch (error) {
            toast.error("Failed to update Schedule.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    const deleteSchedule = async (teamId) => {
        
        setLoading(true);

        const data = await getScheduleById(teamId);

        console.log("data",data)

        try {
            const response = await API.graphql(
                graphqlOperation(mutations.deleteSchedule, { input: { id: teamId } }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            toast.success("Schedule deleted successfully!");
            return response;
        } catch (error) {
            toast.error("Failed to delete Schedule.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    const getScheduleById = async (ScheduleId) => {
        setLoading(true);

        try {
            const response = await API.graphql(
                graphqlOperation(queries.getSchedule, { id: ScheduleId }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            return response.data.getSchedule;
        } catch (error) {
            toast.error("Failed to fetch Schedule.");
            throw error;
        } finally {
            setLoading(false)
        }
    };


    const getAllSchedules = async () => {
        setLoading(true);

        try {
            const response = await API.graphql(
                graphqlOperation(queries.listSchedules),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            
            setSchedule(response?.data?.listSchedules?.items);
            return response?.data?.listSchedules?.items || [];
        } catch (error) {
            toast.error("Failed to fetch teams.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    return {
        createSchedule,
        updateSchedule,
        deleteSchedule,
        getScheduleById,
        getAllSchedules,
        loading,
        schedule
        
    };
};

export { ScheduleSchema, useHandleSchedule };
