import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopTrainingProgramCard from './TrainingProgramCard';

// ----------------------------------------------------------------------

TrainingProgramCardList.propTypes = {
  trainingProgramCards: PropTypes.array.isRequired,
};

export default function TrainingProgramCardList({ trainingProgramCards, loading, updateTrainingProgramCards, deleteTrainingProgramCards, ...other }) {
  return (
     <Grid container spacing={3} {...other}>
       {trainingProgramCards?.map((trainingProgramCard) => (
         <Grid key={trainingProgramCard.id} item xs={12} sm={6} md={3}>
           <ShopTrainingProgramCard
             updateTrainingProgramCards={updateTrainingProgramCards}
             deleteTrainingProgramCards={deleteTrainingProgramCards}
             trainingProgramCard={trainingProgramCard}
           />
         </Grid>
       ))}
     </Grid>
  );
 }
 
