import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import {
  createAppointment as createAppointmentQuery,
  createAppointmentSchedule as createAppointmentScheduleQuery,
} from '../graphql/mutations';
import { listUsers } from './getUsersFromAws';
import { deleteTypeName } from '../utils/deleteTypeName';

export function useCreateAppointment() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { createAppointmentSchedule } = useCreateAppointmentSchedule();
  const [meets, setMeets] = useState([]);
  const [userMeets, setUserMeets] = useState([]);
  const { pathname } = useLocation();

  const listMeets = async () => {
    try {
      setLoading(true);
      // const filter = pathname.includes('clinic')
      //   ? { type: { eq: 'CLINIC' } }
      //   : { type: { eq: 'TRAINING_PROGRAM' } };
      let filter;

      if (pathname.includes('clinic')) {
        filter = { type: { eq: 'CLINIC' } };
      } else if (pathname.includes('camp')) {
        filter = { type: { eq: 'CAMP' } };
      } else {
        filter = { type: { eq: 'TRAINING_PROGRAM' } };
      }
      
      const response = await API.graphql(
        graphqlOperation(queries.listAppointments, { filter })
      );
      const fetchedMeets = response?.data?.listAppointments?.items;
      // const filteredMeets = pathname.includes('clinic')
      //   ? fetchedMeets.filter(appointment => appointment.type.toLowerCase().includes('clinic'))
      //   : fetchedMeets;
      let filteredMeets;

      switch (true) {
        case pathname.includes('clinic'):
          filteredMeets = fetchedMeets.filter(appointment => appointment.type.toLowerCase().includes('clinic'));
          break;
        case pathname.includes('camp'):
          filteredMeets = fetchedMeets.filter(appointment => appointment.type.toLowerCase().includes('camp'));
          break;
        default:
            filteredMeets = fetchedMeets.filter(appointment => appointment.type.toLowerCase().includes('training_program'));
          break;
      }

      setMeets(filteredMeets);
      setLoading(false);
      return fetchedMeets;
    } catch (error) {
      console.error('Error listing meets:', error);
      setLoading(false);
      throw error;
    }
  };

  const listUserMeets = async (type) => {
    try {
      setLoading(true);
      // const filter = type === 'clinic'
      //   ? { type: { eq: 'CLINIC' } }
      //   : { type: { eq: 'TRAINING_PROGRAM' } };
      let filter;

      if (pathname.includes('clinic')) {
        filter = { type: { eq: 'CLINIC' } };
      } else if (pathname.includes('camp')) {
        filter = { type: { eq: 'CAMP' } };
      } else {
        filter = { type: { eq: 'TRAINING_PROGRAM' } };
      }

      const response = await API.graphql(graphqlOperation(queries.listUserMeets, { filter }));
      const fetchedMeets = response?.data?.listUserMeets?.items;

      const promises = fetchedMeets.map(async (meet) => {
        const { appointmentId, appointmentScheduleId, userId } = meet;

        try {
          const appointmentResponse = await API.graphql(
            graphqlOperation(queries.getAppointment, { id: appointmentId })
          );
          const appointmentData = appointmentResponse?.data?.getAppointment;

          const appointmentScheduleResponse = await API.graphql(
            graphqlOperation(queries.getAppointmentSchedule, { id: appointmentScheduleId })
          );
          const appointmentScheduleData = appointmentScheduleResponse?.data?.getAppointmentSchedule;

          let tutorData = null;
          if (appointmentScheduleData?.tutorId) {
            const appointmentTutorResponse = await API.graphql(
              graphqlOperation(queries.getTutor, { id: appointmentScheduleData.tutorId })
            );
            tutorData = appointmentTutorResponse?.data?.getTutor;
          }

          const groupname = 'Users';
          const limit = 60;
          const awsUsers = await listUsers(groupname, limit);
          const processedAWSUsers = awsUsers?.Users?.map((user) => ({
            id: user?.Username,
            name: user?.Attributes.find((attr) => attr.Name === 'name')?.Value,
            phoneNumber: user.Attributes.find((attr) => attr.Name === 'phone_number')?.Value,
           

          }));
          const userData = processedAWSUsers.filter((user) => user.id === userId);

          return {
            ...meet,
            appointmentData,
            appointmentScheduleData,
            tutorData,
            userData,
          };
        } catch (error) {
          console.error('Error fetching additional data:', error);
          return null;
        }
      });

      const resolvedMeets = await Promise.all(promises.filter((p) => p !== null));
      setUserMeets(resolvedMeets);
      setLoading(false);
      return resolvedMeets;
    } catch (error) {
      console.error('Error listing meets:', error);
      setLoading(false);
      throw error;
    }
  };

  const deleteUserMeetById = async (id) => {
    try {
      setLoading(true);

      API.graphql({
        query: mutations.deleteUserMeet,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }).then(async (response) => {
        console.log(response);

        toast.success('Meet Deleted Successfully !');
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');

      console.error('Error Deleting  Category:', error);
    }
  };

  const deleteMeetById = async (meetId) => {
    try {
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(queries.listAppointmentSchedules, {
          filter: {
            appointmentId: { eq: meetId },
          },
        })
      );
      const data = response?.data?.listAppointmentSchedules?.items;
      data.map(async (schedule) => {
        await API.graphql({
          query: mutations.deleteAppointmentSchedule,
          variables: { input: { id: schedule.id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
      });
      await API.graphql({
        query: mutations.deleteAppointment,
        variables: { input: { id: meetId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      await API.graphql(graphqlOperation(mutations.deleteAppointment, { input: { id: meetId } }));
      setMeets(meets.filter((meet) => meet.id !== meetId));
      toast.success('Meet deleted successfully');
      setLoading(false);
    } catch (error) {
      console.error('Error deleting meet:', error);
      setLoading(false);
      toast.error('Failed to delete meet');
      throw error;
    }
  };

  async function createAppointment(appointmentData) {
    setLoading(true);

    if (
      !appointmentData.name ||
      !appointmentData.description ||
      !appointmentData.duration ||
      !appointmentData.maxStudents ||
      !appointmentData.price ||
      !appointmentData.type ||
      !appointmentData.courseId ||
      !appointmentData.days ||
      !appointmentData.tutorId ||
      !appointmentData.dates
    ) {
      toast.error('Appointment data is incomplete');
      setLoading(false);
      return null;
    }

    try {
      const { name, description, tutorDetails, duration, maxStudents, price, type, courseId } = appointmentData;
      console.log('Data', type);

      const response = await API.graphql({
        query: createAppointmentQuery,
        variables: { input: { name, description, duration, maxStudents, price, type, courseId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const createdAppointment = response.data.createAppointment;

      tutorDetails?.map(async (tut) => {
        const excludeSlots = tut.excludeSlots?.map((sl) => {
          const date = new Date(sl.date).toISOString(); // Convert date to AWS date format (YYYY-MM-DD)
          return {
            date,
            slots: sl.slots,
          };
        });

        const appointmentScheduleData = {
          tutorId: tut.tutorId,
          appointmentId: createdAppointment.id,
          slots: tut.slots,
          maxStudents: tut.maxStudents,
          daily: tut.daily,
          days: tut.days,
          dates: tut.dates,
          excludeSlots,
        };

        await createAppointmentSchedule(appointmentScheduleData);
      });

      const clinic = pathname.includes('newClinicMeet');
      const camp = pathname.includes('newCampMeet');
      
      if (clinic) {
        navigate('/dashboard/schedule-clinic-meet');
      } else if (camp) {
        navigate('/dashboard/schedule-camp-meet');
      } else {
        navigate('/dashboard/schedule-meet');
      }
      
      setLoading(false);
      return createdAppointment;
    } catch (error) {
      console.error('Error creating appointment:', error);
      toast.error('Failed to create appointment');
      setLoading(false);
      throw error;
    }
  }

  return { createAppointment, meets, userMeets, listUserMeets, deleteUserMeetById, listMeets, deleteMeetById, loading };
}

export function useCreateTutor() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function createTutor(tutorData) {
    setLoading(true);

    if (!tutorData.name || !tutorData.profile || !tutorData.bio) {
      toast.error('Tutor data is incomplete');
      setLoading(false);
      return null;
    }

    try {
      const response = await API.graphql({
        query: mutations.createTutor,
        variables: { input: { id: tutorData } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      toast.success('Tutor created successfully');
      navigate('/tutors');
      setLoading(false);
      return response.data.createTutor;
    } catch (error) {
      console.error('Error creating tutor:', error);
      toast.error('Failed to create tutor');
      setLoading(false);
      throw error;
    }
  }

  return { createTutor, loading };
}

export function useCreateAppointmentSchedule() {
  const [loading, setLoading] = useState(false);

  async function createAppointmentSchedule(scheduleData) {
    console.log('SCHEDUle data', scheduleData);
    setLoading(true);
    if (
      !scheduleData.tutorId ||
      !scheduleData.appointmentId ||
      !scheduleData.slots ||
      !scheduleData.maxStudents ||
      !scheduleData.days ||
      !scheduleData.dates
    ) {
      toast.error('Appointment schedule data is incomplete');
      setLoading(false);
      return null;
    }

    try {
      const appointmentScheduleData = {
        tutorId: scheduleData.tutorId,
        appointmentId: scheduleData.appointmentId,
        slots: scheduleData.slots,
        maxStudents: scheduleData.maxStudents,
        daily: scheduleData.daily,
        days: scheduleData.days,
        dates: scheduleData.dates,
        excludeSlots: scheduleData.excludeSlots,
      };
      const response = await API.graphql({
        query: createAppointmentScheduleQuery,
        variables: { input: appointmentScheduleData },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      toast.success('Appointment created successfully');
      setLoading(false);
      return response.data.createAppointmentSchedule;
    } catch (error) {
      console.error('Error creating appointment schedule:', error);
      toast.error('Failed to create appointment schedule');
      setLoading(false);
      throw error;
    }
  }

  return { createAppointmentSchedule, loading };
}

export function useGetAppointmentDetails() {
  const [loading, setLoading] = useState(false);
  const [appointmentData, setAppointmentData] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const getAppointmentDetails = async (id) => {
    setLoading(true);
    try {
      const response = await API.graphql(graphqlOperation(queries.getAppointment, { id }));
      const appointmentData = response.data.getAppointment;
      console.log('Data appointmentData', appointmentData);
      const data = deleteTypeName(appointmentData);
      console.log('Data', data);
      setAppointmentData(data);

      setLoading(false);
      return data;
    } catch (error) {
      console.error('Error fetching appointment details:', error);
      toast.error('Failed to fetch appointment details');
      setLoading(false);
      return null;
    }
  };

  const updateAppointment = async (id, updatedData) => {
    setLoading(true);

    try {
      const tutorDetails = updatedData.tutorDetails;
      console.log('updatedData', updatedData);
      const filter = { appointmentId: { eq: id } };

      const response = await API.graphql(graphqlOperation(queries.listAppointmentSchedules, { filter }));

      const allSchedules = response.data.listAppointmentSchedules.items || [];

      const currentTutorIds = tutorDetails?.map((tut) => tut?.id);
      console.log('currentTutorIds', currentTutorIds);

      // Find removed tutors
      const removedTutors = allSchedules.filter((schedule) => !currentTutorIds.includes(schedule.id));

      removedTutors?.map(async (removeThis) => {
        const response = await API.graphql({
          query: mutations.deleteAppointmentSchedule,
          variables: { input: { id: removeThis.id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
      });
      console.log('REmve', removedTutors);
      const appointmentData = response.data.getAppointment;
      console.log('Ttttt', tutorDetails);
      await Promise.all(
        tutorDetails?.map(async (tut) => {
          const excludeSlots = tut.excludeSlots?.map((sl) => {
            const date = new Date(sl.date).toISOString(); // Convert date to AWS date format (YYYY-MM-DD)
            return {
              date,
              slots: sl.slots,
            };
          });

          const appointmentScheduleData = {
            tutorId: tut.tutorId,
            appointmentId: id,
            slots: tut.slots,
            maxStudents: tut.maxStudents,
            daily: tut.daily,
            days: tut.days,
            dates: tut.dates,
            excludeSlots,
          };
          console.log('Ttttt Details', tut.id);
          if (tut.id) {
            const response = await API.graphql({
              query: mutations.updateAppointmentSchedule,
              variables: { input: { id: tut.id, ...appointmentScheduleData } },
              authMode: GRAPHQL_AUTH_MODE.API_KEY,
            });
          } else {
            const response = await API.graphql({
              query: createAppointmentScheduleQuery,
              variables: { input: appointmentScheduleData },
              authMode: GRAPHQL_AUTH_MODE.API_KEY,
            });
          }
        })
      );

      toast.success('Appointment updated successfully');
      const clinic = pathname.includes('newClinicMeet');
      const camp = pathname.includes('newCampMeet');
      console.log("pathname", pathname, clinic)
      if (clinic) {
        navigate('/dashboard/schedule-clinic-meet');
      }else if (camp) {
        navigate('/dashboard/schedule-camp-meet');
      } else {
        navigate('/dashboard/schedule-meet');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating appointment :', error);
      toast.error('Failed to update appointment');
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log('Appointment', id, appointmentData);
    if (id) {
      getAppointmentDetails(id);
    }
  }, [id]);
  return { getAppointmentDetails, updateAppointment, appointmentData, loading };
}