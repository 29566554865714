import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Box } from  '@mui/material';
import Partner from '../components/user/Partner';
import League from '../components/user/League';
import Volunteer from '../components/user/Volunteer';
import useGetLeaguesPlayerDetails from '../hooks/getLeaguesPlayerDetails';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import User from '../components/signup/User';

function EditUserPage() {
  return (
    <>
        <Box display="flex" mb={5}>
      <DashboardLayout />
      <Box mt={10} width="100%">
        {/* Replace with the component you want to render */}
        {/* <Partner /> */}
        <User/>
        {/* <League /> */}
        {/* <Volunteer /> */}
      </Box>
    </Box>
    </>
  )
}

export default EditUserPage
