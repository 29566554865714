import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { Auth } from 'aws-amplify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from '../../../components/iconify';
import { useSetUser } from '../../../store';
import Loader from '../../../components/Loader';
import useLogin from '../../../hooks/login';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { handleClick, loading } = useLogin();
  const setUser = useSetUser();
  const [error, setError] = useState(null);

  const [showPassword, setShowPassword] = useState(false);

  const handleClicks = async () => {
    const email = document.getElementById('email').value;
    const pass = document.getElementById('password').value;
    await handleClick();
  };
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ToastContainer />

      <Stack spacing={3}>
        <TextField id="email" name="email" label="Email address" />

        <TextField
          name="password"
          id="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
      <br />
      <LoadingButton
        style={buttonStyles}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClicks}
      >
        Login
      </LoadingButton>
    </>
  );
}
