import {
    CREATE_CAMPCARD_REQUEST,
    CREATE_CAMPCARD_SUCCESS,
    CREATE_CAMPCARD_FAIL,
    UPDATE_CAMPCARD_REQUEST,
    UPDATE_CAMPCARD_SUCCESS,
    UPDATE_CAMPCARD_FAIL,
    DELETE_CAMPCARD_REQUEST,
    DELETE_CAMPCARD_SUCCESS,
    DELETE_CAMPCARD_FAIL,
    GET_CAMPCARD_REQUEST,
    GET_CAMPCARD_SUCCESS,
    GET_CAMPCARD_FAIL,
    CLEAR_CAMPCARD_ERRORS,
    ALL_CAMPCARD_REQUEST,
    ALL_CAMPCARD_SUCCESS,
    ALL_CAMPCARD_FAIL,
  } from '../constants/CampCardsConstants';
  
  const initialState = {
    campCards: [],
    campCardCount: 0,
    resultPerPage: 0,
    campCard: {},
    isUpdated:false,
    loading: false,
    error: null,
    filteredCampCardsCount: 0,
  };
  
  export const campCardsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ALL_CAMPCARD_REQUEST:
      case GET_CAMPCARD_REQUEST:
      case CREATE_CAMPCARD_REQUEST:
      case DELETE_CAMPCARD_REQUEST:
      case UPDATE_CAMPCARD_REQUEST:
        return {
          ...state,
          loading: true,
          campCards: [],
          campCard: {},
        };
      case ALL_CAMPCARD_SUCCESS:
        return {
          ...state,
          loading: false,
          campCards: action.payload.campCards,
          campCardCount: action.payload.campCardsCount,
          resultPerPage: action.payload.resultPerPage,
          filteredCampCardsCount: action.payload.filteredCampCardsCount,
        };
      case CREATE_CAMPCARD_SUCCESS:
      case GET_CAMPCARD_SUCCESS:
        return {
          ...state,
          loading: false,
          campCard: action.payload,
        };
      case DELETE_CAMPCARD_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_CAMPCARD_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: true,
        };
      case GET_CAMPCARD_FAIL:
      case ALL_CAMPCARD_FAIL:
      case CREATE_CAMPCARD_FAIL:
      case UPDATE_CAMPCARD_FAIL:
      case DELETE_CAMPCARD_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CAMPCARD_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  