
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CarouselCard from './CarauselCard';

// ----------------------------------------------------------------------


export default function CarouselList({updateCarousel,onEditCarousel,deleteCarousel, carousels,setCarouselList,checkAuthAndFetchData, ...other }) {
 

  return (
    <Grid container spacing={3} {...other}>
      {carousels?.map((carousel) => (
        <Grid key={carousel?.id} item xs={12}  sm={6} md={3}>
          <CarouselCard deleteCarousel={deleteCarousel}  onEditCarousel={onEditCarousel} updateCarousel={updateCarousel} carousel={carousel} setCarouselList={setCarouselList} checkAuthAndFetchData={checkAuthAndFetchData} />
        </Grid>
      ))}
    </Grid>
  );
}