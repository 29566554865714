/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import getLink from '../utils/getLinks';
import deleteFile from '../utils/deleteFile';
import * as queries from '../graphql/queries';
import uploadFile from '../utils/uploadFile';
import * as mutations from '../graphql/mutations';

const useHandlePagintationOrders = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [orderList, setOrdersList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };

  const fetchOrders = async () => {
    setLoading(true);

    let variables;
    try {
      // let filterExpression = {};
      // console.log('orderName: ' + orderName);
      // if (orderName && orderName.trim() !== '') {
      //   setInitialNextNextToken(undefined);
      //   setNextToken(undefined);
      //   setNextNextToken(undefined);
      //   setPreviousTokens([]);
      //   filterExpression = {
      //     title: {
      //       contains: orderName.trim(),
      //     },
      //   };

      //   variables = {
      //     limit: 100,
      //     filter: filterExpression,
      //   };

      //   if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
      //     variables = {
      //       ...variables,
      //       nextToken,
      //     };
      //   }
      // } else {
      variables = {
        limit: 10,
      };

      if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
        variables = {
          ...variables,
          nextToken,
        };
      }

      // }

      const response = await API.graphql({
        query: queries.listOrders,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const orderItems = response?.data?.listOrders?.items;
      const itemDetailsArray = [];

      // Fetching item details for each order
      for (const order of orderItems) {
        const res = await API.graphql(graphqlOperation(queries.getAddress, { id: order?.address }));

        const url = await fetchImageFromCacheWithExpiration(order?.img_url);
        const updatedOrder = {
          ...order,
          address: res?.data?.getAddress,
          img_url: url,
        };
        itemDetailsArray.push(updatedOrder);
      }
      if (!initialNextNextToken) {
        setInitialNextNextToken(response?.data?.listOrders?.nextToken);
      }

      setOrdersList([...itemDetailsArray]);
      setNextNextToken(response?.data?.listOrders?.nextToken);

      setLoading(false);
      // }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Orders:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteOrders = async (id) => {
    try {
      setLoading(true);
      const res = await API.graphql(graphqlOperation(queries.getOrder, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const isDeleted = await deleteFile(res?.data?.getOrder?.img_url);

      if (isDeleted) {
        const response = await API.graphql({
          query: mutations.deleteOrder,
          variables: { input: { id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        console.log(response);
        toast.success('Orders Deleted Successfully!');
        setLoading(false);

        // Remove deleted order from the orderList
        setOrdersList((prevOrdersList) => prevOrdersList.filter((item) => item.id !== id));

        // Remove deleted images from local storage
        localStorage.removeItem(res?.data?.deleteLeague?.img_url);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Orders:', error);
    }
  };

  const updateOrders = async (newData) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.updateOrder,
        variables: { input: { ...newData, id: newData.id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const updatedData = response?.data?.updateOrder;

      // Update the order in the list
      setOrdersList((prevOrders) => prevOrders.map((order) => (order.id === updatedData.id ? updatedData : order)));

      toast.success('Orders Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating order details:', error);
      toast.error('Error updating order details');
      throw error; // Propagate the error for handling in the component
    }
  };

  // const addEvent = async (items) => {
  //   setLoading(true);

  //   try {
  //     setLoading(true);

  //     // Upload the image to S3 using Storage.put
  //     const updatedData = { ...items };

  //     const key = await uploadFile(updatedData?.img_url);
  //     const response = await create(key, updatedData);
  //     const render_img_url = await fetchImageFromCacheWithExpiration(key);

  //     const orderData = {
  //       ...response?.data?.createLeague,
  //       render_img_url,
  //     };
  //     // Check if the team was successfully created
  //     setLoading(false);
  //     setOrdersList((prevOrdersList) => [orderData, ...prevOrdersList]); // Add the new order data to the order list

  //     // Fetch order URL from AWS and update orderList
  //     // Implement fetchOrdersUrlFromAWS to retrieve order data

  //     setLoading(false);
  //     toast.success(`Created Successfully!`);
  //     return response;
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error('Something Went Wrong: Check Console');
  //     console.error('Error creating order:', error);
  //     throw error;
  //   }
  // };
  // const create = async (key, updatedData) => {
  //   setLoading(true);

  //   const response = await API.graphql({
  //     query: mutations.createLeague,
  //     variables: {
  //       input: {
  //         ...updatedData,
  //         img_url: key,
  //       },
  //     },
  //     authMode:GRAPHQL_AUTH_MODE.API_KEY
  //   });
  //   return response;
  // };
  useEffect(() => {
    fetchOrders('');
  }, [nextToken]);

  return {
    orderList,
    nextNextToken,
    loading,
    deleteOrders,
    fetchNextPage,
    fetchOrders,
    fetchPreviousPage,
    previousTokens,
    // addEvent,
    updateOrders,
  };
};

export default useHandlePagintationOrders;
