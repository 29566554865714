import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer } from "./reducers/UserReducer";
import { productsReducer } from "./reducers/ProductReducer";
import { eventsReducer } from "./reducers/EventReducer";
import { event5x5sReducer } from "./reducers/Event5x5Reducer";
import { tournamentEventsReducer } from "./reducers/TournamentEventReducer";
import { campEventsReducer } from "./reducers/CampEventReducer";
import { clinicCardsReducer } from "./reducers/ClinicCardReducer";
import { campCardsReducer } from "./reducers/CampCardReducer";
import { activitiesReducer } from "./reducers/ActivityReducer";
import { trainingProgramCardsReducer } from "./reducers/TrainingProgramCardReducer";
import { coursesReducer } from "./reducers/CourseReducer";
import { clinicCoursesReducer } from "./reducers/ClinicCourseReducer";
import { campCoursesReducer } from "./reducers/CampCourseReducer";
import { addPricesReducer } from "./reducers/AddPriceReducer";
import { adsReducer } from "./reducers/AdReducer";
import { couponsReducer } from "./reducers/CouponReducer";
import { sessionsReducer } from "./reducers/SessionReducer";
import { teamsReducer } from "./reducers/TeamReducer";
import { team5x5sReducer } from "./reducers/Team5x5Reducer";
import { tournamentTeamsReducer } from "./reducers/TournamentTeamReducer";
import { cityReducer } from "./reducers/CityReducer";
import { newsReducer } from "./reducers/NewsReducer";
import { categoryReducer } from "./reducers/CategoryReducer";
import { playerReducer } from "./reducers/PlayerReducer";
import { Player5x5Reducer } from "./reducers/Player5x5Reducer";
import { tournamentReducer } from "./reducers/TournamentReducer";

const reducer = combineReducers({
  products: productsReducer,
  user: userReducer,
  events: eventsReducer,
  event5x5s: event5x5sReducer,
  tournamentEvents: tournamentEventsReducer,
  campEvents: campEventsReducer,
  tournament: tournamentReducer,
  ads: adsReducer,
  coupons: couponsReducer,
  sessions: sessionsReducer,
  teams: teamsReducer,
  team5x5s: team5x5sReducer,
  tournamentTeams: tournamentTeamsReducer,
  city: cityReducer,
  category: categoryReducer,
  news: newsReducer,
  player: playerReducer,
  player5x5: Player5x5Reducer,
  clinicCards: clinicCardsReducer,
  campCards: campCardsReducer,
  activities: activitiesReducer,
  trainingProgramCards: trainingProgramCardsReducer,
  courses: coursesReducer,
  clinicCourses: clinicCoursesReducer,
  campCourses: campCoursesReducer,
  addPrices: addPricesReducer,
});

const middleware = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
