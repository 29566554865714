import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams } from 'react-router-dom';
import * as queries from '../graphql/queries'; // Make sure to import your GraphQL queries

const useGetPartnerDetails = () => {
  const [partnerDetails, setPartnerDetails] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const { id } = useParams();
  console.log('id', id);
  const fetchPartnerDetails = async () => {
    try {
      setLoading(true); // Set loading to true when fetching data

      const response = await API.graphql({
        query: queries.getPartner,
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setPartnerDetails(response?.data?.getPartner);
      console.log('Partner details', response?.data?.getPartner);
      setLoading(false); // Set loading to false when data is fetched or in case of an error
    } catch (error) {
      console.error('Error fetching Partner details:', error);
    } finally {
      setLoading(false); // Set loading to false when data is fetched or in case of an error
    }
  };

  useEffect(() => {
    fetchPartnerDetails(); // Call the fetchPartnerDetails function when the component mounts
  }, []); // Empty dependency array, meaning this effect runs once on mount

  return { partnerDetails, loading }; // Include loading state in the return value
};

export default useGetPartnerDetails;
