import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import Loader from '../components/Loader';
// / Make sure to import Loader

const useGetLink = () => {
  const [loading, setloading] = useState(false);
  const [playerDetails, setPlayerDetails] = useState([]);
  const [link, setLink] = useState(false);
  const [key, setKey] = useState('');

  const getLink = async (keys) => {
    setKey(keys);
    const link=await fetchData(keys);
    return link;
    
  };
  // eslint-disable-next-line consistent-return
  const fetchData = async(key) => {
    try {
      if(key===''||null){
        return '';
      }
      const signedUrl = await getSignedUrl(key);

      console.log(`Fetching signed ` ,signedUrl )
      return signedUrl;
    } catch (error) {
      console.error('Error fetching image URL', error);
    }
  };
  // useEffect(() => {
  //   setloading(true);
  //   // eslint-disable-next-line consistent-return
  //   const fetchData = async (key) => {
  //     try {
  //       const signedUrl = await getSignedUrl(key);

  //       return signedUrl;
  //     } catch (error) {
  //       console.error('Error fetching image URL', error);
  //     }
  //   };

  //   if (key) {
  //     fetchData();
  //   }
  // }, [key]);

  const getSignedUrl = (link) => {
    return Storage.get(link, {
      level: 'public',
      download: false,
    });
  };

  return { getLink, loading, link }; // Return the data, not `uploadImage`
};

export default useGetLink;
