import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { toast } from 'react-toastify';

import WordEditor from '../components/blog/Write';
import ShowPreview from '../components/blog/ShowPreview';
import useHandlePagintationActivities from '../hooks/handlePaginationActivities';
import Loader from '../components/Loader';

const EditActivity = () => {
  const [content, setContent] = useState('');
  const [preview, setPreview] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [activityType, setActivityType] = useState('PLAYER'); // Default news type
  const [image, setImages] = useState([]);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const {
    loading,
    addActivity,
    updateActivities,
    activity,
    getActivityById
  } = useHandlePagintationActivities();

  const handleWrite = (newContent) => {
    setContent(newContent);
  };



  const handleActivityTypeChange = (event) => {
    setActivityType(event.target.value);
  };


  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    setShowCancelDialog(false);
    navigate('/dashboard/activities');
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
  };
  const handleSave = async () => {
    try {
      if (!activityType) {
        toast.error('Activity Type is required.');
        return;
      }
      if (!title) {
        toast.error('Title is required.');
        return;
      }


      // Check if price is empty
      if (!content) {
        toast.error('Content is required.');
        return;
      }
      if (id) {
        const data = {
          title,
          description: content,
          type: activityType,
          id,
        };

        // Save the news content with updated image sources
        await updateActivities(data);
      } else {
        const data = {
          title,
          description: content,
          type: activityType,
        };

        // Save the news content with updated image sources
        await addActivity(data);
      }

      navigate('/dashboard/activities');
      console.log('Save functionality executed successfully');
    } catch (error) {
      console.error('Error during handleSave:', error);
    }
  };

  const extractText = (content) => {
    // Replace HTML tags and extract plain text
    const div = document.createElement('div');
    div.innerHTML = content;
    const text = div.innerText.trim();

    return text;
  };
  // Function to extract images and videos from HTML content
  const extractImagesAndVideos = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const images = [];
    const videos = [];
    const iframe = [];

    // Extract images
    const imageElements = doc.querySelectorAll('img');
    imageElements.forEach((element) => {
      const src = element.getAttribute('src');
      images.push(src);
    });

    // Extract videos
    const videoElements = doc.querySelectorAll('video');
    videoElements.forEach((element) => {
      const src = element.getAttribute('src');
      videos.push(src);
    });

    // Extract iframes
    const iframeElements = doc.querySelectorAll('iframe');
    iframeElements.forEach((element) => {
      const src = element.getAttribute('src');
      iframe.push(src);
    });

    return { images, videos, iframe };
  };

  useEffect(() => {
    if (id) {
      getActivityById(id)
        .then((activityData) => {
          setTitle(activityData.title || ''); // Set title
          setActivityType(activityData.type || 'PLAYER'); // Set type with a default value
          setContent(activityData.description || ''); // Set description
        })
        .catch((error) => {
          console.error('Error fetching activity:', error);
        });
    }
  }, [id]); // Make sure to include 'id' as a dependency

  if (loading) {
    return <Loader />;
  }

  return (
    <Box className="flex flex-col gap-[25px] py-2 px-2 w-full max-w-[1280px] mx-auto h-full">
      <Box className="flex gap-2 justify-between items-center">
        <Box className="flex gap-2 items-center">
          <button
            className={`border transition-all ease-in-out duration-300  text-[16px]  font-bold ${!preview
              ? 'bg-blue-500  hover:bg-blue-600  text-white'
              : 'border text-blue-500   border-blue-400 hover:bg-blue-200'
              } text-black p-1 px-3 rounded-lg`}
            onClick={() => setPreview(false)}
          >
            Edit
          </button>
          <button
            className={`border transition-all text-[16px] ease-in-out duration-300  font-bold ${preview
              ? 'bg-blue-500 hover:bg-blue-600 rounded-lg text-white'
              : 'border text-blue-500   border-blue-400 hover:bg-blue-200'
              } text-black p-1 rounded-lg px-3`}
            onClick={() => setPreview(true)}
          >
            Preview
          </button>
        </Box>
        <Box className="flex gap-2 items-center">
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="text" color="secondary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="text"
            variant="outlined"
            label="Title"
            autoFocus
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="activityType">Type</InputLabel>
            <Select
              labelId="activityType"
              id="activityType"
              value={activityType}
              onChange={handleActivityTypeChange}
              label="Type"
              variant="outlined"
            >
              <MenuItem value="PLAYER">PLAYER</MenuItem>
              <MenuItem value="PLAYER5X5">PLAYER5X5</MenuItem>
              <MenuItem value="CAMP">CAMP</MenuItem>
              <MenuItem value="TOURNAMENT">TOURNAMENT</MenuItem>
              <MenuItem value="TRAINING_PROGRAM">TRAINING PROGRAM</MenuItem>
              <MenuItem value="CLINIC">CLINIC</MenuItem>
              <MenuItem value="COMPTETIVE">COMPTETIVE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box className="flex justify-between w-full mt-2">
        {preview ? (
          <ShowPreview content={content} title={title} />
        ) : (
     
        <WordEditor image={image} setImages={setImages} content={content} setContent={handleWrite} />
        )}
      </Box>
      <Dialog open={showCancelDialog} onClose={handleCancelDialogClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>If you cancel, your changes will be lost. Do you really want to cancel?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="primary">
            <NavLink to="/dashboard/activities">Yes</NavLink>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditActivity;
