import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Box,
  Button,
  Chip,
  Avatar,
  CircularProgress
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon
} from '@mui/icons-material';
import { debounce } from 'lodash';
import { useHandleComptetivePlayers } from 'src/hooks/handleComptetivePlayers';
import getLink from 'src/utils/getLinks';


const ComptetivePlayersDetails = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Pagination state
  const [pageSize] = useState(10);
  const [currentToken, setCurrentToken] = useState(null);
  const [tokenStack, setTokenStack] = useState([]); // Stack to store previous tokens
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  
  // Menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const { getAllPlayers, updatePlayers } = useHandleComptetivePlayers();

  const fetchPlayers = async (token = null, isNextPage = true) => {
    setLoading(true);
    try {
      const response = await getAllPlayers({
        limit: pageSize,
        nextToken: token,
        filter: searchTerm
      });
      
      const { items, nextToken } = response;
      setPlayers(items);
      setHasNextPage(!!nextToken);
      
      // Update pagination state
      if (isNextPage && token) {
        setTokenStack(prev => [...prev, token]);
      }
      setCurrentToken(nextToken);

    } catch (error) {
      console.error('Error fetching players:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce((value) => {
    setSearchTerm(value);
    setCurrentPage(1);
    setTokenStack([]);
    setCurrentToken(null);
    fetchPlayers(null);
  }, 500);

  useEffect(() => {
    fetchPlayers();
  }, []);

  const handleNextPage = () => {
    setCurrentPage(prev => prev + 1);
    fetchPlayers(currentToken, true);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
      const previousToken = tokenStack[tokenStack.length - 2];
      setTokenStack(prev => prev.slice(0, -1));
      fetchPlayers(previousToken, false);
    }
  };

  const handleMenuOpen = (event, player) => {
    setAnchorEl(event.currentTarget);
    setSelectedPlayer(player);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPlayer(null);
  };

  const handleUnverifyPlayer = async () => {
    try {
      await updatePlayers(selectedPlayer.id, { isVerified: false });
      fetchPlayers(currentToken);
      handleMenuClose();
    } catch (error) {
      console.error('Error unverifying player:', error);
    }
  };

  const handleViewDocument = () => {
    if (selectedPlayer?.documentUrl) {
      window.open(selectedPlayer.documentUrl, '_blank');
    }
    handleMenuClose();
  };

  const getTransactionStatusColor = (status) => {
    switch (status) {
      case 'PAID':
        return 'success';
      case 'PENDING':
        return 'warning';
      case 'FAILED':
        return 'error';
      default:
        return 'default';
    }
  };

  if (loading && players.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5" component="h1">
          Competitive Players
        </Typography>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => debouncedSearch(e.target.value)}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="players table">
          <TableHead>
            <TableRow>
              <TableCell>Profile</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Transaction Status</TableCell>
              <TableCell>Verified</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map((player) => (
              <TableRow key={player.id}>
                <TableCell>
                  <Avatar
                    src={player.profileUrl}
                    alt={player.participantName || 'Profile'}
                  />
                </TableCell>
                <TableCell>{player.participantName || 'N/A'}</TableCell>
                <TableCell>{player.email}</TableCell>
                <TableCell>{player.userId}</TableCell>
                <TableCell>
                  <Chip
                    label={player.transactionStatus || 'N/A'}
                    color={getTransactionStatusColor(player.transactionStatus)}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    label={player.isVerified ? 'Verified' : 'Not Verified'}
                    color={player.isVerified ? 'success' : 'default'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={(event) => handleMenuOpen(event, player)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Custom Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
        <Button
          startIcon={<NavigateBeforeIcon />}
          onClick={handlePreviousPage}
          disabled={currentPage === 1 || loading}
          variant="outlined"
        >
          Previous
        </Button>
        <Typography sx={{ alignSelf: 'center', mx: 2 }}>
          Page {currentPage}
        </Typography>
        <Button
          endIcon={<NavigateNextIcon />}
          onClick={handleNextPage}
          disabled={!hasNextPage || loading}
          variant="outlined"
        >
          Next
        </Button>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleViewDocument}>
          <VisibilityIcon fontSize="small" sx={{ mr: 1 }} />
          View Document
        </MenuItem>
        {/* <MenuItem onClick={handleUnverifyPlayer}>
          Unverify Player
        </MenuItem>
        <MenuItem disabled>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit Details
        </MenuItem> */}
      </Menu>
    </Box>
  );
};

export default ComptetivePlayersDetails;