import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Box,
  Button,
  Chip,
  Avatar,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@mui/icons-material';
import { debounce } from 'lodash';
import { useHandleComptetive5x5Players } from 'src/hooks/handleComptetive5x5Players';
import getLink from 'src/utils/getLinks';
import useCityList from 'src/hooks/getCityList';
import { useHandleComptetive5x5Teams } from 'src/hooks/handleComptetive5x5Teams';

const Comptetive5x5InvitedPlayersDetails = () => {

  const ages = [
    { value: 'u7', label: 'U7' },
    { value: 'u8', label: 'U8' },
    { value: 'u9', label: 'U9' },
    { value: 'u10', label: 'U10' },
    { value: 'u11', label: 'U11' },
    { value: 'u12', label: 'U12' },
    { value: 'u13', label: 'U13' },
    { value: 'u14', label: 'U14' },
    { value: 'u15', label: 'U15' },
    { value: 'u17', label: 'U17' },
    { value: 'u18', label: 'U18' },
    { value: 'u19-u39', label: 'U19-U39' },
    { value: 'over u40', label: 'Over U40' },
  ];

  const [players, setPlayers] = useState([]);
  const [filterPlayers, setFilterPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  // Pagination state
  const [pageSize] = useState(10);
  const [currentToken, setCurrentToken] = useState(null);
  const [tokenStack, setTokenStack] = useState([]); // Stack to store previous tokens
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // Menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  // New filter states
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [teams, setTeams] = useState([]);
  const { getAllInvitedPlayers, updatePlayers } = useHandleComptetive5x5Players();

  const { loading: cityLoading, error, cities } = useCityList();

  const {loading: teamsLoading, getAllTeams} = useHandleComptetive5x5Teams();

  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  // Load teams when component mounts
  React.useEffect(() => {
    const loadTeams = async () => {
      const teamData = await getAllTeams();
      setTeams(teamData);
    };
    loadTeams();
  }, []);

  const fetchPlayers = async (token = null, isNextPage = true) => {
    setLoading(true);
    try {
      const response = await getAllInvitedPlayers({
        limit: pageSize,
        nextToken: token,
        filter: searchTerm,
      });
console.log('fet ched pla',response)
      const { items, nextToken } = response;
      setPlayers(items);
      setFilterPlayers(items);
      setHasNextPage(!!nextToken);

      // Update pagination state
      if (isNextPage && token) {
        setTokenStack((prev) => [...prev, token]);
      }
      setCurrentToken(nextToken);
    } catch (error) {
      console.error('Error fetching players:', error);
    } finally {
      setLoading(false);
    }
  };
  // Filter players based on selected criteria
  const handleFilterSubmit = () => {
    console.log('clicked');
    console.log('Selected Values:', {
      city: selectedCity,
      ageGroup: selectedAgeGroup,
      team: selectedTeam
  });
  console.log('All Players:', players);
    const filteredPlayers = players.filter((player) => {
      const matchesCity = selectedCity ? player.cityOfRegistration === selectedCity : true;
      const matchesAgeGroup = selectedAgeGroup ? player.agegroup === selectedAgeGroup : true;
      const matchesTeam = selectedTeam ? player.teamID === selectedTeam : true;

      return matchesCity && matchesAgeGroup && matchesTeam;
    });

    setFilterPlayers(filteredPlayers); // Update players with filtered results
    setCurrentPage(1); // Reset to first page
    // Optionally reset tokens if needed
    setTokenStack([]);
    setCurrentToken(null);
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
    fetchPlayers(currentToken, true);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      const previousToken = tokenStack[tokenStack.length - 2];
      setTokenStack((prev) => prev.slice(0, -1));
      fetchPlayers(previousToken, false);
    }
  };

  const handleMenuOpen = (event, player) => {
    setAnchorEl(event.currentTarget);
    setSelectedPlayer(player);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPlayer(null);
  };


  const handleViewDocument = () => {
    if (selectedPlayer?.documentUrl) {
      window.open(selectedPlayer.documentUrl, '_blank');
    }
    handleMenuClose();
  };

  const getTransactionStatusColor = (status) => {
    switch (status) {
      case 'PAID':
        return 'success';
      case 'PENDING':
        return 'warning';
      case 'FAILED':
        return 'error';
      default:
        return 'default';
    }
  };

  if (loading && filterPlayers.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5" component="h1">
          Comptetive Invited Players
        </Typography>
      </Box>
      {/* Filters */}
      <Box sx={{ display: 'flex', gap: '16px', mb: '16px' }}>
        <FormControl fullWidth>
          <InputLabel>Select City</InputLabel>
          <Select value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
            {/* Replace with your city data */}
            {/* Assuming cities is an array of city objects */}
            {cities.map((city) => (
              <MenuItem key={city.id} value={city.name}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Select Age Group</InputLabel>
          <Select value={selectedAgeGroup} onChange={(e) => setSelectedAgeGroup(e.target.value)}>
            {ages.map((age) => (
              <MenuItem key={age.value} value={age.value}>
                {age.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Select Team</InputLabel>
          <Select value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
            {/* Replace with your team data */}
            {teams.map((team) => (
              <MenuItem key={team.id} value={team.id}>
                {team.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="contained" color="primary"  style={buttonStyles} onClick={handleFilterSubmit}>
          Submit
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="players table">
          <TableHead>
            <TableRow>
              <TableCell>Profile</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Transaction Status</TableCell>
              <TableCell>Verified</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterPlayers.map((player) => (
              <TableRow key={player.id}>
                <TableCell>
                  <Avatar src={player.profileUrl} alt={player.participantName || 'Profile'} />
                </TableCell>
                <TableCell>{player.participantName || 'N/A'}</TableCell>
                <TableCell>{player.email}</TableCell>
                <TableCell>{player.userId}</TableCell>
                <TableCell>
                  <Chip
                    label={player.transactionStatus || 'N/A'}
                    color={getTransactionStatusColor(player.transactionStatus)}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    label={player.isVerified ? 'Verified' : 'Not Verified'}
                    color={player.isVerified ? 'success' : 'default'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <IconButton size="small" onClick={(event) => handleMenuOpen(event, player)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Custom Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
        <Button
          startIcon={<NavigateBeforeIcon />}
          onClick={handlePreviousPage}
          disabled={currentPage === 1 || loading}
          variant="outlined"
        >
          Previous
        </Button>
        <Typography sx={{ alignSelf: 'center', mx: 2 }}>Page {currentPage}</Typography>
        <Button
          endIcon={<NavigateNextIcon />}
          onClick={handleNextPage}
          disabled={!hasNextPage || loading}
          variant="outlined"
        >
          Next
        </Button>
      </Box>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleViewDocument}>
          <VisibilityIcon fontSize="small" sx={{ mr: 1 }} />
          View Document
        </MenuItem>
        {/* <MenuItem onClick={handleUnverifyPlayer}>
          Unverify Player
        </MenuItem>
        <MenuItem disabled>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit Details
        </MenuItem> */}
      </Menu>
    </Box>
  );
};

export default Comptetive5x5InvitedPlayersDetails;
