import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { ToastContainer } from 'react-toastify'; // Assuming you have a toast library for displaying messages

import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen'; // Import the LockOpen icon

// components
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { userState } from '../store';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import useHandleCampUser from '../hooks/hanldeCampUser';
import useHandleClinicUser from '../hooks/handleClinic';
import useHandleTrainingProgramUser from '../hooks/hanldeTrainingProgram';
import useHandleTournamentUser from '../hooks/hanldeTournamentUser';

import { UserListHead, UserListToolbar, AddNewUserSideBar } from '../sections/@dashboard/allusers';
// mock
// import USERLIST from '../_mock/user';
import useGetAllUsers from '../hooks/getUsers';
import Loader from '../components/Loader';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'zone', label: 'Type', alignRight: false },
  { id: 'age', label: 'Age', alignRight: false },
  { id: 'phoneNumber', label: 'Phone No', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function AllUsers() {
  const dispatch = useDispatch();
  const { deleteCampUserById, loading: loadingCamp } = useHandleCampUser();
  const { deleteTournamentUserById, loading: loadingTournament } = useHandleTournamentUser();
  const { deleteTrainingProgramUserById, loading: loadingTrainingProgram } = useHandleTrainingProgramUser();
  const { deleteClinicUserById, loading: loadingClinic } = useHandleClinicUser();

  const [open, setOpen] = useState(null);
  const {
    allUsers,
    deleteEvent,
    deletePlayer,
    deletePlayer5x5,
    deletePartner,
    suspendUser,
    enableUser,
    deleteVolunteer,
    loading,
    // listAllUsers,
    deleteWorkWithUs,
    deleteUser,
  } = useGetAllUsers();
  console.log('allUsers', allUsers);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };
  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedRowId, setSelectedRowId] = useState(null); // Add a state to store the selected row's id
  const [selectedZone, setSelectedZone] = useState(''); // Add a state to store the selected row's id

  const navigate = useNavigate();

  const [enabled, setEnabled] = useState(false);

  const handleReactivate = async () => {
    // Implement reactivate logic here
    console.log('Reactivate', selectedRowId);
    const response = await enableUser(selectedRowId);
    // listAllUsers();
  };

  const handleSuspend = async () => {
    const response = await suspendUser(selectedRowId);
    console.log('Suspend', selectedRowId);
    // listAllUsers();

    // Implement suspend logic here
  };

  const handleDeleteUser = async () => {
    if (selectedZone === 'Player') {
      await deletePlayer(selectedRowId);
    }else if (selectedZone === 'Player5x5') {
      await deletePlayer5x5(selectedRowId);
    }  else if (selectedZone === 'Volunteer') {
      const response = await deleteVolunteer(selectedRowId);
    } else if (selectedZone === 'User') {
      const response = await deleteUser(selectedRowId);
    } else if (selectedZone === 'Events') {
      const response = await deleteEvent(selectedRowId);
    } else if (selectedZone === 'WorkWithUs') {
      const response = await deleteWorkWithUs(selectedRowId);
    } else if (selectedZone === 'Camp') {
      const response = await deleteCampUserById(selectedRowId);
    } else if (selectedZone === 'Clinic') {
      const response = await deleteClinicUserById(selectedRowId);
    } else if (selectedZone === 'Tournament') {
      const response = await deleteTournamentUserById(selectedRowId);
    } else if (selectedZone === 'TrainingProgram') {
      const response = await deleteTrainingProgramUserById(selectedRowId);
    } else if (selectedZone === 'Partner') {
      const response = await deletePartner(selectedRowId);
    }
    console.log('Suspend', selectedRowId);
    // listAllUsers();
    setIsDeleteDialogOpen(false);

    // Implement suspend logic here
  };

  const handleOpenMenu = (event, id, enabled, zone) => {
    // Pass the id when opening the menu
    setSelectedRowId(id);
    setSelectedZone(zone);
    setEnabled(enabled);
    // Store the selected row's id
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allUsers?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleEditNavigate = (id) => {
    if (selectedRowId && selectedZone && selectedZone === 'User') {
      navigate(`/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Player') {
      navigate(`/player/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Player5x5') {
      navigate(`/player5x5/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Volunteer') {
      navigate(`/volunteer/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Events') {
      navigate(`/event/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'WorkWithUs') {
      navigate(`/workwithUs/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Camp') {
      navigate(`/camp/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Clinic') {
      navigate(`/clinic/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Tournament') {
      navigate(`/tournament/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'TrainingProgram') {
      navigate(`/training-program/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Partner') {
      navigate(`/partner/${selectedRowId}`); // Use the selected row's id
    }

    handleCloseMenu(); // Close the menu
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allUsers.length) : 0;

  const filteredUsers = applySortFilter(allUsers, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) {
      // Handle the case where dateOfBirth is not provided
      return null; // Or any other desired action like returning an error message
    }
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    return monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate()) ? age - 1 : age;
  };

  if (loading || loadingCamp || loadingClinic || loadingTournament || loadingTrainingProgram) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>
      <ToastContainer />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>

          {/* <AddNewUserSideBar openUser={openUser} onCloseUser={handleCloseUser} onOpenUser={handleOpenUser} /> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected?.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, paddingLeft: '25px', paddingRight: '25px' }}>
              <Dialog
                open={isDeleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Delete User Details</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this User Details?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteCancel} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleDeleteUser} color="primary" autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>

              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={allUsers?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                    const { id, name, zone, dateOfBirth, phoneNumber, email, enabled } = row;
                    const selectedUser = selected.indexOf(name) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        sx={{ padding: '25px' }}
                        role="checkbox"
                        selected={selectedUser}
                      >
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src="src" />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{zone}</TableCell>
                        <TableCell align="left">{calculateAge(dateOfBirth)}</TableCell>
                        <TableCell align="left">{phoneNumber}</TableCell>
                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => handleOpenMenu(event, id, enabled, zone)}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={allUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {selectedZone === 'User' ? (
          <MenuItem onClick={() => handleEditNavigate()}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            View
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleEditNavigate()}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
        )}

        {enabled ? (
          // Show 'Suspend' when user is enabled
          <MenuItem onClick={handleSuspend}>
            <Iconify icon={'mdi:block'} sx={{ mr: 2 }} />
            Suspend
          </MenuItem>
        ) : (
          // Show 'Reactivate' when user is not enabled
          selectedZone === 'User' && (
            <MenuItem onClick={handleReactivate}>
              <Iconify icon={'eva:checkmark-outline'} sx={{ mr: 2 }} />
              Reactivate
            </MenuItem>
          )
        )}
        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteClick}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
