import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';

const buttonStyles = {
  backgroundColor: '#1E90FF', // Set your desired background color
  color: 'white', // Set your desired text color
};

const CreateSchedulesModalOne = ({
  title,
  handleClose,
  handleInputChange,
  handleAdd,
  buttonText,
  firstScoreLabel,
  secondScoreLabel,
  selectedFirstScore,
  selectedSecondScore,
  selectedCity,
  cityOptions,
  selectedFirstTeam,
  selectedSecondTeam,
  teamOptions,
  selectedDate,
  selectedTime,
  error
}) => {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents default form submission
    handleAdd();
  };

  return (
    <Dialog open onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <InputLabel id="firstTeamName">First Team</InputLabel>
                <Select
                  labelId="firstTeamId"
                  label="firstTeam"
                  name="firstTeamId"
                  required
                  value={selectedFirstTeam}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="">Select First Team</MenuItem>
                  {teamOptions.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </Grid>
           

            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <InputLabel id="secondTeamName">Second Team</InputLabel>
                <Select
                  labelId="secondTeamId"
                  label="secondTeam"
                  name="secondTeamId"
                  required
                  value={selectedSecondTeam}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="">Select Second Team</MenuItem>
                  {teamOptions.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="firstScore"
                name="firstScore"
                value={selectedFirstScore}
                label={firstScoreLabel}
                fullWidth
                required
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="secondScore"
                name="secondScore"
                value={selectedSecondScore}
                label={secondScoreLabel}
                fullWidth
                required
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <InputLabel id="select-label">Location</InputLabel>
                <Select
                  labelId="select-label"
                  label="location"
                  name="location"
                  required
                  value={selectedCity}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="">Select Location</MenuItem>
                  {cityOptions.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>


            {/* Date Input */}
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="date"
                name="date"
                label="Select Date"
                type="date"
                fullWidth
                required
                value={selectedDate}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* Time Input */}
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="time"
                name="time"
                label="Select Time"
                type="time"
                fullWidth
                required
                value={selectedTime}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: '16px' }}>
          <Button onClick={handleClose} variant="outlined" color="secondary" style={{ marginRight: 'auto' }}>
            Cancel
          </Button>
          <Button type="submit" style={buttonStyles} color="primary" variant="contained">
            {buttonText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateSchedulesModalOne;
