import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useHandleSchedule } from 'src/hooks/useHandleSchedule';
import { useHandleComptetive5x5Teams } from 'src/hooks/handleComptetive5x5Teams';
import Loader from 'src/components/Loader';
import CreateSchedulesModalOne from './CreateComptetiveModal';
import useCityList from 'src/hooks/getCityList';
import Details from './Details';

export default function Schedules() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    date: '',
    time: '',
    location: '',
    firstTeam: '',
    secondTeam: '',
    firstTeamId: '',
    secondTeamId: '',
    firstScore: '',
    secondScore: '',
  });

  const [error, setError] = useState('');

  const [schedules, setSchedules] = useState([]);
  const [comptetive5x5Team, setComptetive5x5Team] = useState([]);
  const { cities, loading: cityLoading, error: cityError } = useCityList();

  const {
    createSchedule,
    loading: comptetiveLoading,
    deleteSchedule,
    updateSchedule,
    getAllSchedules,
  } = useHandleSchedule();
  const { loading: comptetive5X5TeamLoading, getAllTeams } = useHandleComptetive5x5Teams();

  const [agegroup, setAgegroup] = useState('');

  const [showModal, setShowModal] = useState(false);

  const handleShowModals = () => {
    setShowModal(true);
  };

  const handleCloseModals = () => {
    setShowModal(false);
    // Reset fields when closing the modal
    setFields({
    date: '',
    time: '',
    location: '',
    firstTeam: '',
    secondTeam: '',
    firstTeamId: '',
    secondTeamId: '',
    firstScore: '',
    secondScore: '',
    });
    setError('');
  };

  const handleEditClick = (id) => {
    const scheduleToEdit = schedules.find((schedule) => schedule.id === id);
    
    // Format the date from AWS DateTime to YYYY-MM-DD format
    let formattedDate = '';
   
    if (scheduleToEdit.date) {
      const dateObj = new Date(scheduleToEdit.date);
      formattedDate = dateObj.toISOString().split('T')[0];
    }
 

    setFields({
      ...scheduleToEdit,
      date: formattedDate
    });
    console.log('formattedDate',formattedDate)
    setShowModal(true);
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
setError('');
    if (name === 'location') {
      setFields((previous) => ({
        ...previous,
        location: value,
      }));
    }
    setFields((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const handleAddOrEdit = async () => {
    setLoading(true);
 // Check if the firstTeam and secondTeam are the same
 if (fields.firstTeamId === fields.secondTeamId) {
    setError('The first team and second team cannot be the same.');
    setLoading(false);
    return; // Prevent further execution if the teams are the same
  } else {
    setError(''); // Clear the error if the teams are different
  }
    try {
      if (fields.id) {
        // Edit existing schedule
        const updatedSchedule = await updateSchedule(fields.id, fields);
        // Update the schedules list with the updated schedule
        setSchedules((prev) =>
            prev.map((schedule) =>
            schedule.id === updatedSchedule.id ? updatedSchedule : schedule
        ));
      } else {
        // Create new schedule
        const newSchedule = await createSchedule(fields);
        setSchedules((prev) => [...prev, newSchedule]);
      }

      // Reset fields after operation
      setFields({
        date: '',
        time: '',
        location: '',
        firstTeam: '',
        secondTeam: '',
        firstTeamId: '',
        secondTeamId: '',
        firstScore: '',
        secondScore: '',
      });
    } catch (error) {
      console.error('Error handling schedule', error);
      toast.error('Failed to handle schedule');
    } finally {
      setLoading(false);
      handleCloseModals();
    }
  };

  const getAllSchedule = async () => {
    setLoading(true);
    try {
      const data = await getAllSchedules();
      const teamData = await getAllTeams();
      console.log('data', data);
      console.log('teamData', teamData);
      setSchedules(data);
      setComptetive5x5Team(teamData);
    } catch (error) {
      console.log('Error', error);
      toast.error('error');
    } finally {
      setLoading(false);
    }
  };

  const deleteAndRemove = async (id) => {
    try {
      setSchedules((previousData) => previousData.filter((team) => team.id !== id));
      await deleteSchedule(id);
    } catch (error) {
      console.log('Error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllSchedule();
  }, []);

  if (loading || cityLoading || comptetiveLoading || comptetive5X5TeamLoading) {
    return <Loader />;
  }

  return (
    <>
      <ToastContainer />
      {/* Render ModalOne component conditionally */}
      {showModal && (
        <CreateSchedulesModalOne
          title={fields.id ? 'Edit Schedule' : 'Add Schedule'}
          handleClose={handleCloseModals}
          handleInputChange={handleInputValue}
          handleAdd={handleAddOrEdit} // Use the updated function
          buttonText={fields.id ? 'Update Schedule' : 'Add Schedule'}
          firstScoreLabel="First Score"
          secondScoreLabel="Second Score"
          selectedCity={fields['location']}
          selectedFirstScore={fields['firstScore']}
          selectedSecondScore={fields['secondScore']}
          cityOptions={cities}
          selectedFirstTeam={fields['firstTeamId']}
          selectedSecondTeam={fields['secondTeamId']}
          selectedDate={fields['date']}
          selectedTime={fields['time']}
          teamOptions={comptetive5x5Team}
          error={error}
        />
      )}

      <Container maxWidth="lg">
        <Box mt={5}>
          <Details
            deleteThis={deleteAndRemove}
            handleEditClick={handleEditClick}
            label={'Schedule'}
            addDetails={handleShowModals}
            data={schedules || []}
          />
        </Box>
      </Container>
    </>
  );
}
