import {
  REGISTER_PLAYER_REQUEST,
  REGISTER_PLAYER_SUCCESS,
  REGISTER_PLAYER_FAIL,
  UPDATE_PLAYER_REQUEST,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_PLAYER_FAIL,
  GET_PLAYER_REQUEST,
  GET_PLAYER_SUCCESS,
  GET_PLAYER_FAIL,
  LOAD_PLAYERS_REQUEST,
  LOAD_PLAYERS_SUCCESS,
  LOAD_PLAYERS_FAIL,
  DELETE_PLAYER_REQUEST,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_FAIL,
  DELETE_PLAYER_RESET,
  CLEAR_PLAYER_ERRORS,
} from '../constants/PlayerConstants';

const initialState = {
  players: [],
  verifiedPlayers: [],
  notVerifiedPlayers: [],
  playerDetails: {},
  created: false,
  deleted: false,
  updated: false,
  score: {},
  team: {},
  loading: false,
  error: null,
};

export const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PLAYERS_REQUEST:
    case GET_PLAYER_REQUEST:
    case REGISTER_PLAYER_REQUEST:
    case DELETE_PLAYER_REQUEST:
    case UPDATE_PLAYER_REQUEST:

      return {
        ...state,
        loading: true,
      };
    case DELETE_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
      };
    case UPDATE_PLAYER_SUCCESS:
      return{
  ...state,
  loading: false,
  updated: true,
}
    case GET_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        playerDetails: action.payload,
        // team: action.payload.team,
        // score: action.payload.score,
      };

    case REGISTER_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        playerDetails: action.payload,
        created: true,
      };
    case LOAD_PLAYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        players: action.payload.players,
        verifiedPlayers: action.payload.verifiedPlayers,
        notVerifiedPlayers: action.payload.nonVerifiedPlayers,
      };
    case GET_PLAYER_FAIL:
    case LOAD_PLAYERS_FAIL:
    case REGISTER_PLAYER_FAIL:
    case UPDATE_PLAYER_FAIL:
    case DELETE_PLAYER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_PLAYER_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// export const newProductReducer = (state = { product: {} }, action) => {
//   switch (action.type) {
//     case CREATE_PRODUCT_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case CREATE_PRODUCT_SUCCESS:
//       return {
//         loading: false,
//         success: action.payload.success,
//         product: action.payload.product,
//       };
//     case CREATE_PRODUCT_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     // case ADD_PRODUCT_RESET:
//     //   return {
//     //     ...state,
//     //     success: false,
//     //   };
//     case CLEAR_PRODUCT_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };
//     default:
//       return state;
//   }
// };

// export const productDetailsReducer = (state = { product: {} }, action) => {
//   switch (action.type) {
//     case GET_PRODUCT_REQUEST:
//       return {
//         loading: true,
//         ...state,
//       };
//     case GET_PRODUCT_SUCCESS:
//       return {
//         loading: false,
//         product: action.payload,
//       };
//     case GET_PRODUCT_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };

//     case CLEAR_PRODUCT_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };
//     default:
//       return state;
//   }
// };

// export const productReducer = (state = {}, action) => {
//   switch (action.type) {
//     case DELETE_PRODUCT_REQUEST:
//     case UPDATE_PRODUCT_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case DELETE_PRODUCT_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         isDeleted: action.payload,
//       };

//     case UPDATE_PRODUCT_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         isUpdated: action.payload,
//       };
//     case DELETE_PRODUCT_FAIL:
//     case UPDATE_PRODUCT_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     case DELETE_PRODUCT_RESET:
//       return {
//         ...state,
//         isDeleted: false,
//       };
//     // case UPDATE_PRODUCT_RESET:
//     //   return {
//     //     ...state,
//     //     isUpdated: false,
//     //   };
//     case CLEAR_PRODUCT_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };
//     default:
//       return state;
//   }
// };
