import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
import {
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAIL,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAIL,
  CLEAR_EVENT_ERRORS,
  ALL_EVENT_REQUEST,
  ALL_EVENT_SUCCESS,
  ALL_EVENT_FAIL,
} from '../constants/EventsConstants';
import getLinks from '../../utils/getLinks';
import deleteFile from '../../utils/deleteFile';
import uploadFile from '../../utils/uploadFile';

export const getAllEvents = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_EVENT_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listLeagues));

    const data = response.data.listLeagues.items || [];
    const updatedEvents = await Promise.all(
      data?.map(async (event) => {
        try {
          const signedUrl = await getLinks(event?.img_url);
          return { ...event, render_img_link: signedUrl };
        } catch (error) {
          console.error('Error fetching image URL', error);
          return event;
        }
      })
    );

    const startedEvents = updatedEvents.filter((event) => event.eventStatus === 'EVENT_STARTED');
    const notStartedevents = updatedEvents.filter((event) => event.eventStatus === 'EVENT_NOT_STARTED');
    const endedEvents = updatedEvents.filter((event) => event.eventStatus === 'EVENT_ENDED');
    console.log('notStartedevents', notStartedevents);
    console.log('updatedEvents', updatedEvents);
    const updatedData = {
      events: updatedEvents,

      eventsCount: updatedEvents?.length,
      resultPerPage: 10,
      endedEvents,
      startedEvents,
      notStartedevents,
      filteredEventsCount: null,
    };

    dispatch({
      type: ALL_EVENT_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_EVENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getEventDetails = (eventId) => async (dispatch) => {
  try {
    dispatch({ type: GET_EVENT_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getLeague, { id: eventId }));

    const data = response?.data.getLeague;
    const signedUrl = await getLinks(data?.img_url);
    const newData = { ...data, img_url: signedUrl };
    dispatch({
      type: GET_EVENT_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_EVENT_FAIL,
      payload: errorMessage,
    });
  }
};

export const createEvent = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_EVENT_REQUEST });
    const imageUrl = await uploadFile(formData.img_url);
    const response = await API.graphql({
      query: mutations.createLeague,

      variables: {
        input: {
          ...formData,
          img_url: imageUrl,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createLeague;
    toast.success('Event Added Successfully!');

    dispatch({
      type: CREATE_EVENT_SUCCESS,
      payload: data,
    });
    toast.success('Created!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('EVENT error', error);

    dispatch({
      type: CREATE_EVENT_FAIL,
      payload: error,
    });
  }
};
export const deleteEvent = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_EVENT_REQUEST });

    const res = await API.graphql(graphqlOperation(queries.getLeague, { id }), {
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const isDeleted = await deleteFile(res?.data?.getLeague?.img_url);

    if (isDeleted) {
      API.graphql({
        query: mutations.deleteLeague,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }).then(async (response) => {
        toast.success('Event Deleted Successfully!');

        console.log(response);
        dispatch({
          type: DELETE_EVENT_SUCCESS,
          payload: true,
        });
      });
    }
    toast.success('Deleted!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_EVENT_FAIL,
      payload: error || 'Failed to fetch EVENT data',
    });
  }
};
export const updateEvent = (eventId, formData, oldImage) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_EVENT_REQUEST });
    const league = await API.graphql(graphqlOperation(queries.getLeague, { id: eventId }));

    const { newImage } = formData;
    let imgUrl = league?.data?.getLeague?.img_url;

    if (newImage) {
      const isDeleted = await deleteFile(imgUrl);
      imgUrl = await uploadFile(newImage);
    }

    const data = {
      title: formData?.title,
      selling_price: formData?.selling_price,
      teamsId: formData?.teamsId,
      teamsName: formData?.teamsName,
      location: formData?.location,
      date: formData?.date,
      img_url: imgUrl,
    };

    const response = await API.graphql({
      query: mutations.updateLeague,
      variables: { input: { ...data, id: eventId } },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const updatedDetails = response?.data?.updateLeague;
    toast.success('Event Updated Successfully!');
    dispatch({
      type: UPDATE_EVENT_SUCCESS,
      payload: updatedDetails,
    });
    toast.success('Updated!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.error('Error updating EVENT:', error);

    dispatch({
      type: UPDATE_EVENT_FAIL,
      payload: error || 'Failed to update EVENT',
    });
  }
};
export const clearEventsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_EVENT_ERRORS });
};
