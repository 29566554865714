import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, Button } from '@mui/material';
import MainSkeleton from '../components/SkeletonLoader';
import useHandlePaginationCarousel from '../hooks/useHandlePaginationCarousel';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import CarouselCard from 'src/sections/@dashboard/carausel/CarauselCard';
import AddNewCarouselSideBar from 'src/sections/@dashboard/carausel/AddNewCarauselSideBar';
import CarouselList from 'src/sections/@dashboard/carausel/CarauselList';

// ----------------------------------------------------------------------

export default function CarouselPage() {
  const [openCarousel, setOpenCarousel] = useState(false);

  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    images,
    nextToken,
    loading,
    fetchNextPage,
    fetchPreviousPage,
    addImage,
    deleteImage,
    updateImage,
    fetchCarouselImages,
  } = useHandlePaginationCarousel();
  const [carousels, setCarouselsList] = useState(images);

  // Open/close handlers for adding/editing carousels
  const handleOpenCarousel = () => {
    setOpenCarousel(true);
    setEditData(null);
  };

  const handleFetchImages = async () => {
    setIsLoading(true);

    try {
      const data = await fetchCarouselImages();
      setCarouselsList(images || []);
      console.log("images", images)
    } catch (error) {
      throw new error(error)
    }
    finally {
      setIsLoading(false);

    }
  }

  useEffect(() => {
    handleFetchImages();
  }, []);

  const handleCloseCarousel = () => {
    if (!loading) {

      setOpenCarousel(false);
      setEditData(null);
    }
  };

  // Edit carousel image data
  const handleEditCarousel = (imageData) => {
    setEditData(imageData);
    setOpenCarousel(true);
  };

  const handleCreateCarouselImages = async (newImage) => {
    try {
      await addImage(newImage)
      fetchCarouselImages();
    } catch (error) {
      console.error('Error creating carousel images:', error);
    }
  };

  const handleUpdateCarouselImages = async (id, updatedImages) => {
    try {
      await updateImage(id, updatedImages);
      fetchCarouselImages();
    } catch (error) {
      console.error('Error updating carousel images:', error);
    }
  };

  const handleDeleteImage = async (imageUrl) => {
    try {
      await deleteImage(imageUrl);
      fetchCarouselImages();
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Carousel | Minimal UI </title>
      </Helmet>

      <Container>
        {/* <Stack direction="row" s="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}> */}
        <div className='flex w-full pb-10 items-center justify-between '>

          <Typography variant="h4" >
            Carousel
          </Typography>

          <Stack direction="row" spacing={1}>
            <AddNewCarouselSideBar
              openCarousel={openCarousel}
              onOpenCarousel={handleOpenCarousel}
              onCloseCarousel={handleCloseCarousel}
              editData={editData}
              createCarouselImages={handleCreateCarouselImages}
              updateCarouselImages={handleUpdateCarouselImages}
            />
          </Stack>
        </div>
        {/* </Stack>I */}
        {loading ? (
          <MainSkeleton />
        ) : (
          <CarouselList
            carousels={images}
            setCarouselsList={setCarouselsList}
            updateCarousel={handleUpdateCarouselImages}
            deleteCarousel={handleDeleteImage}
            onEditCarousel={handleUpdateCarouselImages}
          />
        )}

        {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >            {fetchPreviousPage && (
            <Button onClick={fetchPreviousPage}>
              Previous Page
            </Button>
          )}
            {nextToken !== null && (
              <Button onClick={fetchNextPage} disabled={!nextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}
