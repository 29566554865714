import {
  CREATE_CITY_REQUEST,
  CREATE_CITY_SUCCESS,
  CREATE_CITY_FAIL,
  GET_CITY_REQUEST,
  GET_CITY_SUCCESS,
  GET_CITY_FAIL,
  LIST_CITIES_REQUEST,
  LIST_CITIES_SUCCESS,
  LIST_CITIES_FAIL,
  UPDATE_CITY_REQUEST,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  DELETE_CITY_REQUEST,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_FAIL,
  DELETE_CITY_RESET,
  CLEAR_CITY_ERRORS,
  
  
} from "../constants/CityConstants";

  const initialState = {
    cities: [],
    cityCount: 0,
    resultPerPage: 0,
    city: {},
    loading: false,
    error: null,
    filteredCityCount: 0,
  };
  
  export const cityReducer = (state = initialState, action) => {
    switch (action.type) {
      case LIST_CITIES_REQUEST:
      case GET_CITY_REQUEST:
        case DELETE_CITY_REQUEST:
          case CREATE_CITY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case LIST_CITIES_SUCCESS:
        return {
          ...state,
          loading: false,
          cities: action.payload.cities,
          cityCount: action.payload.cityCount,
          resultPerPage: action.payload.resultPerPage,
          filteredCityCount: action.payload.filteredCityCount,
        };
  
        case GET_CITY_SUCCESS:
          return {
                 ...state,
            loading: false,
            cities:action.payload

          };
          case CREATE_CITY_SUCCESS:
            return {
              ...state,
              added: true,
              loading: false,
            };
      case GET_CITY_FAIL:
      case LIST_CITIES_FAIL:
        case CREATE_CITY_FAIL:
          case DELETE_CITY_FAIL:
        return {
               ...state,
          loading: false,
          error: action.payload,
        };
        case DELETE_CITY_SUCCESS:
          return {
            ...state,
            deleted: true,
            loading: false,
          };
      case CLEAR_CITY_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
 