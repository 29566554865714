/* eslint-disable no-plusplus, no-await-in-loop, no-empty */
import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import { faker } from '@faker-js/faker';
import * as mutations from '../graphql/mutations';
import uploadFile from 'src/utils/uploadFile';

const useHandleMokeData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const imageUrls = [
    'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/262524/pexels-photo-262524.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/3183165/pexels-photo-3183165.jpeg?auto=compress&cs=tinysrgb&w=600',
  ];

  const createTournamentTeamDataFunction = async (data) => {
    const { tournamentType, tournamentLeague, title, ageGroup, city, numberOfTeams } = data;
    const randomIndex = Math.floor(Math.random() * imageUrls.length);

    const imageURL = await convertImageUrlToFile(imageUrls[randomIndex]);
    const uploadedKey = await uploadFile(imageURL);

    try {
      const teamData = {
        name: faker.company.companyName(),
        imageURL: uploadedKey,
        event: tournamentLeague.title,
        eventId: tournamentLeague.id,
        agegroup: ageGroup,
        tournamentId: tournamentLeague.id,

        location: city,
        city,
      };

      const response = await API.graphql({
        query: mutations.createTournamentTeam,
        variables: { input: teamData },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      console.log('response', response);
      return response?.data?.createTournamentTeam;
    } catch (error) {
      console.error('Error creating Tournament team data:', error);
      setError(error);
      throw new Error(error);
    }
  };

  const playerTeamDataFunction = async (teamData) => {
    try {
      const response = await API.graphql({
        query: mutations.createTeam,
        variables: { input: teamData },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      return response?.data?.createTeam;
    } catch (error) {
      console.error('Error creating  team data:', error);
      setError(error);
      throw new Error(error);
    }
  };
  const teamLeagueDataFunction = async (data) => {
    const { tournamentType, league, title, ageGroup, city, numberOfTeams } = data;
    const randomIndex = Math.floor(Math.random() * imageUrls.length);

    const imageURL = await convertImageUrlToFile(imageUrls[randomIndex]);
    const uploadedKey = await uploadFile(imageURL);

    try {
      const teamData = {
        name: faker.company.companyName(),
        imageURL: uploadedKey,
        agegroup: ageGroup,

        location: city,
        city,
      };
      const response = await API.graphql({
        query: mutations.createTeam,
        variables: { input: teamData },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      return response?.data?.createTeam;
    } catch (error) {
      console.error('Error creating Tournament team data:', error);
      setError(error);
      throw new Error(error);
    }
  };
  const playerTeamLeagueDataFunction = async (tournamentLeagueData) => {
    try {
      const response = await API.graphql({
        query: mutations.createLeague,
        variables: { input: tournamentLeagueData },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      return response?.data?.createLeague;
    } catch (error) {
      console.error('Error creating player league data:', error);
      setError(error);
      throw new Error(error);
    }
  };

  const createPlayerFun = async (data) => {
    try {
      const { tournamentType, league, team, teamId, title, ageGroup, city, numberOfTeams } = data;

      //  console.log('teamResponse', team);
      const randomIndex = Math.floor(Math.random() * imageUrls.length);
      const randomInde = Math.floor(Math.random() * imageUrls.length);
      const imageURL = await convertImageUrlToFile(imageUrls[randomIndex]);
      const imageUR = await convertImageUrlToFile(imageUrls[randomInde]);
      const uploadedKey = await uploadFile(imageURL);
      const uploadedKeyDocument = await uploadFile(imageUR);

      const playerData = {
        agegroup: ageGroup || 'u7',
        userId: faker.datatype.uuid(),
        isVerified: true,
        ext: faker.phone.phoneNumber(),
        cityOfRegistration: city,
        participantName: faker.name.findName(),
        league: title,
        teamID: teamId,
        profile: uploadedKey,
        email: faker.internet.email(),
        teamName: team,
        message: faker.lorem.sentence(),
        dateOfBirth: faker.date.past().toISOString(),
        isAdult: faker.datatype.boolean(),
        grade: 'A',
        jerseySize: 'M',
        schoolName: faker.company.companyName(),
        transactionStatus: 'SUCCESS',
        isFirstYearPlaying: faker.datatype.boolean(),
        memberExpire: faker.date.future().toISOString(),
        isMemberOfOtherTeam: faker.datatype.boolean(),
        clubName: faker.company.companyName(),
        secondaryPersonName: faker.name.findName(),
        primaryPersonName: faker.name.findName(),
        primaryCallPhoneNumber: faker.phone.phoneNumber(),
        secondaryCallPhoneNumber: faker.phone.phoneNumber(),
        primaryEmergencyContactEmail: faker.internet.email(),
        secondaryEmergencyContactEmail: faker.internet.email(),
        heardAboutLeague: faker.random.word(),
        healthConditions: faker.datatype.boolean(),
        parentFirstName: faker.name.firstName(),
        parentLastName: faker.name.lastName(),
        parentEmailAddress: faker.internet.email(),
        parentPhoneNumber: faker.phone.phoneNumber(),
        parentBusinessPhoneNumber: faker.phone.phoneNumber(),
        parentHomePhoneNumber: faker.phone.phoneNumber(),
        parentCountry: faker.address.country(),
        signature: faker.random.word(),
        document: uploadedKeyDocument,
        HEIGHT: faker.datatype.number().toString(),
        WEIGHT: faker.datatype.number().toString(),
        H_S: faker.datatype.number().toString(),
        NBA: faker.datatype.number().toString(),
        COLLEGE: faker.datatype.number().toString(),
        phoneNumber: faker.phone.phoneNumber(),
      };
      const response = await API.graphql({
        query: mutations.createPlayer,
        variables: { input: playerData },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      return response?.data?.createPlayer;
    } catch (error) {
      console.error('Error creating player  data:', error);
      setError(error);
      throw new Error(error);
    }
  };

  const createTournamentPlayerFun = async (data) => {
    const { tournamentType, tournamentId, team, teamId, title, ageGroup, city, numberOfTeams } = data;
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    const randomInde = Math.floor(Math.random() * imageUrls.length);
    const imageURL = await convertImageUrlToFile(imageUrls[randomIndex]);
    const imageUR = await convertImageUrlToFile(imageUrls[randomInde]);
    const uploadedKey = await uploadFile(imageURL);
    const uploadedKeyDocument = await uploadFile(imageUR);

    //  console.log('teamResponse', team);
    const tournamentPlayerData = {
      userId: faker.datatype.uuid(), // Ensure this is provided
      isVerified: true,
      // ext: faker.phone.phoneNumber(),
      participantName: faker.name.findName(), // Ensure this is provided
      tournamentId, // Ensure this is provided
      // scoreID: faker.datatype.uuid(), // Ensure this is provided
      teamID: teamId, // Ensure this is provided
      team: teamId, // Ensure this is provided
      profile: uploadedKey,
      teamName: team.name, // Ensure this is provided
      message: faker.lorem.sentence(),
      dateOfBirth: faker.date.past().toISOString(),
      isAdult: faker.datatype.boolean(),
      grade: 'A', // Provide a default value
      email: faker.internet.email(), // Ensure this is provided
      isFirstYearPlaying: faker.datatype.boolean(),
      transactionStatus: 'SUCCESS',
      memberExpire: faker.date.future().toISOString(),
      jerseySize: 'M', // Provide a default value
      schoolName: faker.company.companyName(),
      paymentStatus: 'SUCCESS',
      isMemberOfOtherTeam: faker.datatype.boolean(),
      clubName: faker.company.companyName(), // Ensure this is provided
      secondaryPersonName: faker.name.findName(),
      primaryPersonName: faker.name.findName(),
      primaryCallPhoneNumber: faker.phone.phoneNumber(),
      secondaryCallPhoneNumber: faker.phone.phoneNumber(),
      primaryEmergencyContactEmail: faker.internet.email(),
      secondaryEmergencyContactEmail: faker.internet.email(),
      heardAboutLeague: faker.random.word(),
      healthConditions: faker.datatype.boolean(),
      parentFirstName: faker.name.firstName(),
      parentLastName: faker.name.lastName(),
      parentEmailAddress: faker.internet.email(),
      parentPhoneNumber: faker.phone.phoneNumber(),
      parentBusinessPhoneNumber: faker.phone.phoneNumber(),
      parentHomePhoneNumber: faker.phone.phoneNumber(),
      parentCountry: faker.address.country(),
      signature: faker.random.word(),
      HEIGHT: faker.datatype.number().toString(),
      WEIGHT: faker.datatype.number().toString(),
      H_S: faker.datatype.number().toString(),
      NBA: faker.datatype.number().toString(),
      COLLEGE: faker.datatype.number().toString(),
      phoneNumber: faker.phone.phoneNumber(),
      agegroup: ageGroup || 'u7', // Provide a default value
    };
    try {
      const response = await API.graphql({
        query: mutations.createTournament,
        variables: { input: tournamentPlayerData },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      return response?.data?.createTournament;
    } catch (error) {
      console.error('Error creating tournament player  data:', error);
      setError(error);
      throw new Error(error);
    }
  };

  const createTournamentLeagueFun = async (tournamentLeagueData) => {
    const { title, ageGroup, city, numberOfTeams } = tournamentLeagueData;
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    const randomInde = Math.floor(Math.random() * imageUrls.length);
    const imageURL = await convertImageUrlToFile(imageUrls[randomIndex]);

    const uploadedKey = await uploadFile(imageURL);

    const tournamentData = {
      title,
      date: new Date().toISOString(),
      agegroup: ageGroup,
      img_url: uploadedKey,

      eventStatus: 'EVENT_NOT_STARTED',
      selling_price: faker.commerce.price(),
      description: faker.lorem.paragraph(),
      teamsId: [{ firstTeamId: '', secondTeamId: '' }],
      teamsName: [{ firstTeam: '', secondTeam: '' }],
      allTeamsId: [],
      allTeamsName: [],
    };

    const { data } = await API.graphql({
      query: mutations.createTournamentLeague,
      variables: { input: tournamentData },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const tournamentLeague = data.createTournamentLeague;
    for (let i = 0; i < numberOfTeams; i++) {
      tournamentLeagueData.tournamentLeague = tournamentLeague;
      const team = await createTournamentTeamDataFunction(tournamentLeagueData);
      tournamentLeagueData.tournamentId = tournamentLeague.id;
      tournamentLeagueData.teamId = team.id;
      tournamentLeagueData.team = team;
      for (let j = 0; j < 4; j++) {
        const player = await createTournamentPlayerFun(tournamentLeagueData);
      }
    }
  };

  const createLeagueFun = async (leagueData) => {
    const { title, ageGroup, city, numberOfTeams } = leagueData;
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    const randomInde = Math.floor(Math.random() * imageUrls.length);
    const imageURL = await convertImageUrlToFile(imageUrls[randomIndex]);

    const uploadedKey = await uploadFile(imageURL);

    const dataToCreateLeague = {
      title,
      date: new Date().toISOString(),
      // agegroup: ageGroup,
      img_url: uploadedKey,
      location: city,
      eventStatus: 'EVENT_NOT_STARTED',
      selling_price: faker.commerce.price(),
      description: faker.lorem.paragraph(),
      teamsId: [{ firstTeamId: '', secondTeamId: '' }],
      teamsName: [{ firstTeam: '', secondTeam: '' }],
    };

    const { data } = await API.graphql({
      query: mutations.createLeague,
      variables: { input: dataToCreateLeague },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const leagueCreatedData = data.createLeague;
    for (let i = 0; i < numberOfTeams; i++) {
      leagueData.league = leagueCreatedData;
      const team = await teamLeagueDataFunction(leagueData);
      leagueData.teamId = team.id;
      leagueData.team = team.name;
      for (let j = 0; j < 4; j++) {
        const player = await createPlayerFun(leagueData);
      }
    }
  };

  // const createTournamentData = async (tournamentType, title, ageGroup, city, numberOfTeams) => {
  //   try {
  //     setLoading(true);
  //     setError(null);

  //     let tournamentLeague;
  //     let league;

  //     const tournamentDataPromises = [];
  //     const playerDataPromises = [];

  //     const grades = ['5th', '7th', '10th', '12th'];
  //     const jerseySizeOptions = ['ys', 'ym', 'yl', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'];
  //     console.log({
  //       tournamentType,
  //       title,
  //       ageGroup,
  //       city,
  //       numberOfTeams,
  //     });

  //     if (tournamentType === 'tournament') {
  //       // Create tournament event
  //       const tournamentLeagueData = {
  //         title,
  //         date: new Date().toISOString(),
  //         agegroup: ageGroup,
  //         img_url: '',
  //         eventStatus: 'EVENT_NOT_STARTED',
  //         selling_price: faker.commerce.price(),
  //         description: faker.lorem.paragraph(),
  //         teamsId: [{ firstTeamId: '', secondTeamId: '' }],
  //         teamsName: [{ firstTeam: '', secondTeam: '' }],
  //         allTeamsId: [],
  //         allTeamsName: [],
  //       };
  //       tournamentLeague = await teamLeagueDataFunction(tournamentLeagueData);
  //     } else if (tournamentType === 'league') {
  //       // Create tournament event
  //       const playerLeagueData = {
  //         title,
  //         date: new Date().toISOString(),
  //         // agegroup: ageGroup, // add ageoupt in league Schema
  //         img_url: '',
  //         eventStatus: 'EVENT_NOT_STARTED',
  //         location: city,
  //         selling_price: faker.commerce.price(),
  //         description: faker.lorem.paragraph(),
  //         teamsId: [{ firstTeamId: '', secondTeamId: '' }],
  //         teamsName: [{ firstTeam: '', secondTeam: '' }],
  //       };
  //       league = await playerTeamLeagueDataFunction(playerLeagueData);
  //     }
  //     console.log("Tournament League", tournamentLeague)
  //     for (let i = 0; i < numberOfTeams; i++) {
  //       if (tournamentType === 'tournament') {
  //         const teamData = {
  //           name: faker.company.companyName(),
  //           imageURL: faker.image.imageUrl(),
  //           players: [],
  //           event: tournamentLeague.title,
  //           eventId: tournamentLeague.id,
  //           scoreID: [],
  //           agegroup: ageGroup,
  //           location: city,
  //           city,
  //         };
  //         const team = await createTournamentTeamDataFunction(teamData);

  //         for (let j = 0; j < 4; j++) {
  //           const grade = grades[Math.floor(Math.random() * grades.length)];
  //           const jerseySize = jerseySizeOptions[Math.floor(Math.random() * jerseySizeOptions.length)];
  //           const userId = faker.datatype.uuid();
  //           console.log('teamResponse', team);
  //           const tournamentPlayerData = {
  //             userId: faker.datatype.uuid(), // Ensure this is provided
  //             verified: true,
  //             ext: faker.phone.phoneNumber(),
  //             participantName: faker.name.findName(), // Ensure this is provided
  //             tournament: title, // Ensure this is provided
  //             scoreID: faker.datatype.uuid(), // Ensure this is provided
  //             teamID: team.id, // Ensure this is provided
  //             profile: faker.internet.url(),
  //             teamName: team.name, // Ensure this is provided
  //             message: faker.lorem.sentence(),
  //             dateOfBirth: faker.date.past().toISOString(),
  //             isAdult: faker.datatype.boolean(),
  //             grade: 'A', // Provide a default value
  //             email: faker.internet.email(), // Ensure this is provided
  //             isFirstYearPlaying: faker.datatype.boolean(),
  //             transactionStatus: 'SUCCESS',
  //             memberExpire: faker.date.future().toISOString(),
  //             jerseySize: 'M', // Provide a default value
  //             schoolName: faker.company.companyName(),
  //             paymentStatus: 'SUCCESS',
  //             isMemberOfOtherTeam: faker.datatype.boolean(),
  //             clubName: faker.company.companyName(), // Ensure this is provided
  //             secondaryPersonName: faker.name.findName(),
  //             primaryPersonName: faker.name.findName(),
  //             primaryCallPhoneNumber: faker.phone.phoneNumber(),
  //             secondaryCallPhoneNumber: faker.phone.phoneNumber(),
  //             primaryEmergencyContactEmail: faker.internet.email(),
  //             secondaryEmergencyContactEmail: faker.internet.email(),
  //             heardAboutLeague: faker.random.word(),
  //             healthConditions: faker.datatype.boolean(),
  //             parentFirstName: faker.name.firstName(),
  //             parentLastName: faker.name.lastName(),
  //             parentEmailAddress: faker.internet.email(),
  //             parentPhoneNumber: faker.phone.phoneNumber(),
  //             parentBusinessPhoneNumber: faker.phone.phoneNumber(),
  //             parentHomePhoneNumber: faker.phone.phoneNumber(),
  //             parentCountry: faker.address.country(),
  //             signature: faker.random.word(),
  //             HEIGHT: faker.datatype.number().toString(),
  //             WEIGHT: faker.datatype.number().toString(),
  //             H_S: faker.datatype.number().toString(),
  //             NBA: faker.datatype.number().toString(),
  //             COLLEGE: faker.datatype.number().toString(),
  //             phoneNumber: faker.phone.phoneNumber(),
  //             agegroup: ageGroup || '5-7', // Provide a default value
  //           };

  //           const player = await createTournamentPlayerFun(tournamentPlayerData);
  //         }
  //       } else if (tournamentType === 'league') {
  //         const playerTeamData = {
  //           name: faker.company.companyName(),
  //           imageURL: faker.image.imageUrl(),
  //           players: [],
  //           scoreID: [],
  //           agegroup: ageGroup,
  //           location: city,
  //           city,
  //         };
  //         // Create tournament te
  //         const teamResponse = await playerTeamDataFunction(playerTeamData);

  //         // Generate tournament data for each team asynchronously

  //         for (let j = 0; j < 4; j += 1) {
  //           const grade = grades[Math.floor(Math.random() * grades.length)];
  //           const jerseySize = jerseySizeOptions[Math.floor(Math.random() * jerseySizeOptions.length)];
  //           const userId = faker.datatype.uuid(); // Generate a unique user ID
  //           const playerData = {
  //             agegroup: ageGroup || 'u7', // Provide a default value
  //             userId, // Ensure this is provided
  //             isVerified: true,
  //             ext: faker.phone.phoneNumber(),
  //             cityOfRegistration: city,
  //             participantName: faker.name.findName(), // Ensure this is provided
  //             league: title, // Ensure this is provided
  //             // scoreID: faker.datatype.uuid(), // Ensure this is provided
  //             teamID: teamResponse.id, // Ensure this is provided
  //             profile: faker.internet.url(),
  //             teamName: teamResponse.name,
  //             message: faker.lorem.sentence(),
  //             dateOfBirth: faker.date.past().toISOString(),
  //             isAdult: faker.datatype.boolean(),
  //             grade, // Provide a default value
  //             email: faker.internet.email(), // Ensure this is provided
  //             jerseySize, // Provide a default value
  //             schoolName: faker.company.companyName(),
  //             transactionStatus: 'SUCCESS',
  //             isFirstYearPlaying: faker.datatype.boolean(),
  //             memberExpire: faker.date.future().toISOString(),
  //             isMemberOfOtherTeam: faker.datatype.boolean(),
  //             clubName: faker.company.companyName(), // Ensure this is provided
  //             secondaryPersonName: faker.name.findName(),
  //             primaryPersonName: faker.name.findName(),
  //             primaryCallPhoneNumber: faker.phone.phoneNumber(),
  //             secondaryCallPhoneNumber: faker.phone.phoneNumber(),
  //             primaryEmergencyContactEmail: faker.internet.email(),
  //             secondaryEmergencyContactEmail: faker.internet.email(),
  //             heardAboutLeague: faker.random.word(),
  //             healthConditions: faker.datatype.boolean(),
  //             parentFirstName: faker.name.firstName(),
  //             parentLastName: faker.name.lastName(),
  //             parentEmailAddress: faker.internet.email(),
  //             parentPhoneNumber: faker.phone.phoneNumber(),
  //             parentBusinessPhoneNumber: faker.phone.phoneNumber(),
  //             parentHomePhoneNumber: faker.phone.phoneNumber(),
  //             parentCountry: faker.address.country(),
  //             signature: faker.random.word(),
  //             document: faker.random.word(),
  //             HEIGHT: faker.datatype.number().toString(),
  //             WEIGHT: faker.datatype.number().toString(),
  //             H_S: faker.datatype.number().toString(),
  //             NBA: faker.datatype.number().toString(),
  //             COLLEGE: faker.datatype.number().toString(),
  //             phoneNumber: faker.phone.phoneNumber(),
  //           };

  //           await createPlayerFun(playerData);
  //         }
  //       }
  //     }
  //     // Toast success message
  //     toast.success('Data added Successfully!');
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error creating Tournament data:', error);
  //     setError(error);
  //     setLoading(false);
  //   }
  // };

  const createTournamentData = async (tournamentType, title, ageGroup, city, numberOfTeams) => {
    try {
      setLoading(true);
      const data = {
        tournamentType,
        title,
        ageGroup,
        city,
        numberOfTeams,
      };

      console.log('tournamentLeagueData', data);
      if (tournamentType === 'tournament') {
        await createTournamentLeagueFun(data);
      } else if (tournamentType === 'league') {
        await createLeagueFun(data);
      }
      toast.success('Created');
      setLoading(false);
    } catch (error) {
      console.error('Error creating Fake data:', error);
      toast.error('Error creating Fake data', error.message);
      setLoading(false);
      throw new Error('Error creating Fake data', error.message);
    }
  };

  return {
    loading,
    error,
    createTournamentData,
  };
};

export default useHandleMokeData;

/* eslint-disable no-plusplus, no-await-in-loop, no-empty */
async function convertImageUrlToFile(url) {
  try {
    console.log('URL', url);
    const response = await fetch(url);

    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: blob.type });
    return file;
  } catch (error) {
    console.error('Error converting image URL to file:', error);
    return null;
  }
}
