import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries';

export const listLeaguesQuery = `
  query ListLeague5x5s($filter: ModelLeague5x5FilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
    listLeague5x5s(filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
      items {
        id
        title
        location
        eventStatus
       
      }
      nextToken
    }
  }
`;

export const fetchNotStartedLeagues = async () => {
  try {
    let leagueData = [];
    let nextToken = null;

    do {
      const response = await API.graphql(
        graphqlOperation(listLeaguesQuery, {
          filter: {
            eventStatus: {
              eq: 'EVENT_NOT_STARTED',
            },
          },
          limit: 100, // Adjust as needed
          nextToken: nextToken,
        })
      );

      const { items, nextToken: newNextToken } = response.data.listLeague5x5s;
      leagueData = [...leagueData, ...items]; // Concatenate items
      nextToken = newNextToken; // Update nextToken for next iteration
    } while (nextToken);

    return leagueData;
  } catch (error) {
    console.error('Error fetching not started leagues:', error);
    toast.error('Failed to fetch not started leagues');
    throw error; // Rethrow or handle the error as needed
  }
};

export const fetchStartedLeagues = async () => {
  try {
    let leagueData = [];
    let nextToken = null;

    do {
      const response = await API.graphql(
        graphqlOperation(listLeaguesQuery, {
          filter: {
            eventStatus: {
              eq: 'EVENT_STARTED',
            },
          },
          limit: 100, // Adjust as needed
          nextToken: nextToken,
        })
      );
      const { items, nextToken: newNextToken } = response.data.listLeague5x5s;
      leagueData = [...leagueData, ...items]; // Concatenate items
      nextToken = newNextToken; // Update nextToken for next iteration
    } while (nextToken);

    return leagueData;
  } catch (error) {
    console.error('Error fetching started leagues:', error);
    toast.error('Failed to fetch started leagues');
    throw error; // Rethrow or handle the error as needed
  }
};

export const fetchLeagueById = async (leagueId) => {
  try {
    const response = await API.graphql({
      query: `
        query ListLeague5x5sByEventStatus($eventStatus: String!, $nextToken: String) {
            listLeague5x5sByEventStatus(eventStatus: $eventStatus, nextToken: $nextToken) {
              items {
                id
                title
                location
                eventStatus
                teams {
      items {
        id
        name
        agegroup
        imageURL
        player {
          items {
            id
            participantName
            profile
            teamID
          }
         }
    }

                
                }
                
              }
              nextToken
            }
          }
        `,
      variables: { id: leagueId },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    return response.data.getLeague;
  } catch (error) {
    console.error('Error fetching league:', error);
    toast.error('Failed to fetch league');
    throw error; // Rethrow or handle the error as needed
  }
};

export const fetchTournamentById = async (tournamentId) => {
  try {
    const response = await API.graphql({
      query: `
          query GetTournament($id: ID!) {
            getTournamentLeague(id: $id) {
              id
              title
              date
              agegroup
              img_url
              selling_price
              eventStatus
              description
              winner {
                id
                # Assuming Winner is a related model with an ID field
                name # Replace with relevant fields from Winner model
              }
              teams {
                id
                name
              }
              allTeams {
                id
                name
              }
              rounds {
                id
             
                roundName # Replace with relevant fields from Rounds model
              }
              scores {
                id
                # Assuming Score is a related model with an ID field
                scoreValue # Replace with relevant fields from Score model
              }
            }
          }
        `,
      variables: { id: tournamentId },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    return response.data.getTournamentLeague;
  } catch (error) {
    console.error('Error fetching tournament:', error);
    toast.error('Failed to fetch tournament');
    throw error; // Rethrow or handle the error as needed
  }
};

const getLeagueQuery = `
  query GetLeague($id: ID!) {
    getLeague(id: $id) {
      id
      title
      location
      eventStatus
      score {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
  teamName
  teamLogo
          }
          firstTeam {
            teamId
            teamName
            teamLogo
          }
          secondTeam {
            teamId
            teamName
            teamLogo
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
          
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
            
          }
          firstTeamDetails {
            
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
            
          }
          secondTeamDetails {
           
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
            
          }
        }
      }
      rounds {
        items {
          id
          round
          eventStatus

        }
      }
    }
  }
   `;

export const fetchLeagueByIdForScore = async (leagueId, event = false) => {
  try {
    let response;
    if (event) {
      const responseL = await API.graphql({
        query: queries.getLeague,
        variables: { id: leagueId },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      response = responseL.data.getLeague;
    } else {
      const responseT = await API.graphql({
        query: queries.getTournamentLeague,
        variables: { id: leagueId },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      response = responseT.data.getTournamentLeague;
    }

    const responseOfRounds = await API.graphql({
      query: queries.listRounds,
      variables: {
        filter: {
          eventId: {
            eq: response.id,
          },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    console.log('Response OF Rounds', responseOfRounds);
    const responseOfSCore = await API.graphql({
      query: queries.listScores,
      variables: {
        filter: {
          eventId: {
            eq: response.id,
          },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const scores = responseOfSCore.data.listScores.items;
    console.log('Response OF Scores', responseOfSCore);

    const rounds = responseOfRounds.data.listRounds.items;

    const data = {
      ...response,
      rounds,
      scores,
    };
    return data;
  } catch (error) {
    console.error('Error fetching league:', error);
    toast.error('Failed to fetch league');
    throw error; // Rethrow or handle the error as needed
  }
};

const getLeagueQueryFoUPdate = `
  query GetLeague($id: ID!) {
    getLeague(id: $id) {
      id
      title
      location
      eventStatus
      score {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
  teamName
  teamLogo
          }
          firstTeam {
            teamId
            teamName
            teamLogo
          }
          secondTeam {
            teamId
            teamName
            teamLogo
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
          
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
            
          }
          firstTeamDetails {
            
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
            
          }
          secondTeamDetails {
           
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
            
          }
        }
      }
      rounds {
        items {
          id
          round
          eventStatus
runnerUpList
        }
      }
    }
  }
   `;

export const fetchLeagueByIdForUpdateScore = async (leagueId) => {
  try {
    const response = await API.graphql({
      query: getLeagueQueryFoUPdate,
      variables: { id: leagueId },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    console.log('Response', response);

    return response.data.getLeague5x5;
  } catch (error) {
    console.error('Error fetching league:', error);
    toast.error('Failed to fetch league');
    throw error; // Rethrow or handle the error as needed
  }
};

// export const listScoresByEvents = async (leagueId) => {
//   try {

//     return response.data.getLeague;
//   } catch (error) {
//     console.error('Error fetching league:', error);
//     toast.error('Failed to fetch league');
//     throw error; // Rethrow or handle the error as needed
//   }
// };
