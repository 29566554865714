import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopAdvertisementCard from './AdvertisementCard';
import useUploadImage from '../../../hooks/getImageLink';
import Loader from '../../../components/Loader';

// ----------------------------------------------------------------------

AdvertisementList.propTypes = {
  ads: PropTypes.array.isRequired,
};

export default function AdvertisementList({updateAdDetails,deleteAd, ads,setAdvertisementList,checkAuthAndFetchData, ...other}) {
 console.log('Advertisement',ads);
  return (
    <Grid container spacing={3} {...other}>
      {ads?.map((advertisement) => (
        <Grid key={advertisement.id} item xs={12} sm={6} md={3}>
          {/* <ShopAdvertisementCard 
          advertisement={advertisement} 
          /> */}
          <ShopAdvertisementCard deleteAd={deleteAd}  updateAdDetails={updateAdDetails} advertisement={advertisement} setAdvertisementList={setAdvertisementList} checkAuthAndFetchData={checkAuthAndFetchData} />

        </Grid>
      ))}
    </Grid>
  );
}
