import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams } from 'react-router-dom';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL mutations
import useGetLink from './getImageLink';

const useSignUpEventById = () => {
  const [signUpEvent, setSignUpEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { getLink } = useGetLink();

  const fetchSignUpEventById = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.getSignUpEvent, { id }));
      const data = response?.data?.getSignUpEvent;

      setSignUpEvent(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching event details:', error);
      setLoading(false);
    }
  };

  const deleteSignUpEventById = async () => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.deleteSignUpEvent,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      setSignUpEvent([]);
      setLoading(false);
    } catch (error) {
      console.error('Error deleting event:', error);
      setLoading(false);
    }
  };

  const updateSignUpEventById = async (data) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.updateSignUpEvent,
        variables: { input: { id, ...data } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedData = response?.data?.updateSignUpEvent;

      setSignUpEvent(updatedData);
      setLoading(false);
    } catch (error) {
      console.error('Error updating event:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSignUpEventById();
  }, []);

  return { signUpEvent, loading, deleteSignUpEventById, updateSignUpEventById };
};

export default useSignUpEventById;
