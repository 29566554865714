import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
import {
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAIL,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAIL,
  LOAD_TEAMS_REQUEST,
  LOAD_TEAMS_SUCCESS,
  LOAD_TEAMS_FAIL,
  LIST_TEAMS_REQUEST,
  LIST_TEAMS_SUCCESS,
  LIST_TEAMS_FAIL,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  DELETE_TEAM_RESET,
  CLEAR_TEAM_ERRORS,
} from '../constants/TournamentTeamsConstants';
import getLinks from '../../utils/getLinks';
import uploadFile from '../../utils/uploadFile';
import deleteFile from '../../utils/deleteFile';

export const getAllTournamentTeams = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_TEAMS_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listTournamentTeams));

    const data = response.data.listTournamentTeams.items || [];
    const updatedTeams = await Promise.all(
      data?.map(async (product) => {
        try {
          const signedUrl = await getLinks(product?.imageURL);
          return { ...product, img_url: signedUrl };
        } catch (error) {
          toast.error('Something Went Wrong!');
          console.error('Error fetching Team image URL', error);
          return product;
        }
      })
    );
    updatedTeams.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      } else if (a.createdAt < b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });

    const updatedData = {
      tournamentTeams: updatedTeams,
      teamsCount: updatedTeams?.length,
      resultPerPage: 10,
      filteredTeamsCount: null,
    };

    dispatch({
      type: LIST_TEAMS_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    dispatch({
      type: LIST_TEAMS_FAIL,
      payload: error,
    });
  }
};

export const createTeam = (formData) => async (dispatch) => {
  // console.log('teams data',data);
  try {
    dispatch({ type: CREATE_TEAM_REQUEST });
    const imageUrl = await uploadFile(formData.imageURL);
    const leagueResponse = await API.graphql(
      graphqlOperation(queries.getTournamentLeague, { id: formData.tournamentId })
    );

    const leagueData = leagueResponse?.data.getTournamentLeague;
    const response = await API.graphql({
      query: mutations.createTournamentTeam,

      variables: {
        input: {
          name: formData.name,
          imageURL: imageUrl,
          agegroup: formData.agegroup,
          tournamentId: formData.tournamentId,
          eventId: formData.tournamentId,
          event: leagueData?.title,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createTournamentTeam;

    toast.success('Team Added Successfully!');

    dispatch({
      type: CREATE_TEAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log('teams error', error);
    toast.error('Something Went Wrong!');

    dispatch({
      type: CREATE_TEAM_FAIL,
      payload: error,
    });
  }
};
export const deleteTournamentTeam = (id) => async (dispatch) => {
  console.log('ID', id);
  try {
    dispatch({ type: DELETE_TEAM_REQUEST });
    // const response = await API.graphql({
    //   query: mutations.deleteTeam,
    //   variables: {
    //     input: {
    //       id,
    //     },
    //   },
    //   authMode: GRAPHQL_AUTH_MODE.API_KEY
    // });
    const res = await API.graphql(graphqlOperation(queries.getTournamentTeam, { id }), {
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const isDeleted = await deleteFile(res?.data?.getTournamentTeam?.imageURL);

    if (isDeleted) {
      API.graphql({
        query: mutations.deleteTournamentTeam,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }).then(async (response) => {
        console.log(response);
        toast.success('Team Deleted Successfully!');

        dispatch({
          type: DELETE_TEAM_SUCCESS,
          payload: true,
        });
      });
    }
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_TEAM_FAIL,
      payload: error || 'Failed to fetch cart data',
    });
  }
};

export const getTeamDetails = (teamsId) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEAM_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getTeam, { id: teamsId }));

    const data = response?.data.getItem;
    dispatch({
      type: GET_TEAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_TEAM_FAIL,
      payload: errorMessage,
    });
  }
};

export const clearTeamErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_TEAM_ERRORS });
};
