import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography, Button } from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
// mock
import { useDispatch, useSelector } from 'react-redux';

import { toast,ToastContainer } from 'react-toastify';
import * as mutations from "../graphql/mutations";

import * as queries from "../graphql/queries";
import { AdvertisementList, AdvertisementCartWidget, AddNewAdvertisementSideBar } from '../sections/@dashboard/advertisement';
import useFetchAdvertisement from '../hooks/getAdvertisementList';
import Loader from '../components/Loader';
import MainSkeleton from '../components/SkeletonLoader';
import { getAllAds,clearAdsErrors} from "../redux/actions/AdsAction";
// ----------------------------------------------------------------------

export default function AdvertisementPage() {
  const dispatch = useDispatch();

  const {
    ads,
    loading,
    deleted,
    updated,
    added,
    error,
    // productsCount,
    resultPerPage,
    // filteredProductsCount,
  } = useSelector((state) => state.ads);
  const [openAdvertisement, setOpenAdvertisement] = useState(false);

  const { adList,
    nextNextToken,
    deleteAd,
    fetchNextPage,
    fetchAd,
    fetchPreviousPage,
    previousTokens,
    createAdvertisements,
    updateAdDetails,loading:adLoading } = useFetchAdvertisement();
    const [advertisementList, setAdvertisementList] = useState(adList);
    const [isLoading, setIsLoading] = useState(false);
console.log('advertisement advertisement',adList)
  
  const handleOpenAdvertisement = () => {
    setOpenAdvertisement(true);
  };
  const handleCloseAdvertisement = () => {
    setOpenAdvertisement(false);
  };

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };


  // useEffect(() => {
  //   if (error) {
  //     dispatch(clearAdsErrors());
  //   }
  //   setAdvertisementList(adList);
  //   // dispatch(getAllAds()); 
  // }, [error,deleted,updated,added,adList]);

  useEffect(() => {
    setAdvertisementList(adList || []);
  }, [adList]);

  if(loading || adLoading){
    return <Loader/>
  }  

  return (
    <>
      <Helmet>
        <title> Dashboard: Advertisement | Minimal UI </title>
      </Helmet>
      <ToastContainer/>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Advertisement
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          
            <AddNewAdvertisementSideBar
            openAdvertisement={openAdvertisement}
            onCloseAdvertisement={handleCloseAdvertisement}
            onOpenAdvertisement={handleOpenAdvertisement}
            setLoading={setIsLoading}
            createAdvertisements={createAdvertisements}
            display
            />
          </Stack>
        </Stack>
{/* 
        <AdvertisementList ads={advertisementList} 
        checkAuthAndFetchData={advertisementList}
         /> */}
            {loading || adLoading ? (
          <>
            <MainSkeleton />
            
          </>
        ) : (
          <AdvertisementList
          ads={advertisementList} 
            setAdvertisementList={setAdvertisementList}
            updateAdDetails={updateAdDetails}
            deleteAd={deleteAd}

            // checkAuthAndFetchData={checkAuthAndFetchData}
          />
        )}
         {!adLoading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
            {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}
