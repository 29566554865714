import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Modal,
  FormControl,
  Typography,
  Container,
  Button,
  ListItemText,
  ListItem,
  List,
  Box,
  Grid,
  InputLabel,
  FormHelperText,
  Link,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../components/Loader';
import useGetLeagueName from '../hooks/getLeagueName';


const TournamentDetails = () => {

  const {
    leaguesNameList: tournamentEvents,
    loading,
    getPlayerOfTournamentLeague, getTeamsOfTournamentLeague, teamList, playersList
  } = useGetLeagueName()
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [eventError, setEventError] = useState(null);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    setEventError(null);
  };

  const handleTeamClick = async (team) => {
    setSelectedTeam(team?.name);
    await getPlayerOfTournamentLeague(team?.id)
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    if (!selectedEvent) {
      setEventError('required');
    } else {
      await getTeamsOfTournamentLeague(selectedEvent);
      setSubmitted(true);
    }
  };
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Tournament Details
      </Typography>
      <Grid container spacing={2} mb={5} alignItems="center">
        <Grid item xs={8}>
          <FormControl fullWidth margin="dense" error={eventError === 'required'}>
            <InputLabel id="event">Select Event</InputLabel>
            <Select
              labelId="event"
              label="Select Event"
              name="event"
              fullWidth
              value={selectedEvent}
              onChange={handleEventChange}
              sx={{ height: 56 }}
            >
              <MenuItem value="">Select Event</MenuItem>
              {tournamentEvents?.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                  {event.title}
                </MenuItem>
              ))}
            </Select>
            {eventError === 'required' && <FormHelperText>This field is required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={4} display="flex" alignItems="center">
          <Button
            style={buttonStyles}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            sx={{ height: 56 }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      {submitted && teamList.length == 0 && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1" color="error">
            No Tournament Details Available
          </Typography>
        </Box>
      )}

      {submitted && teamList && teamList.length > 0 && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Age Group</TableCell>
                  <TableCell>Team Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((tournament) => (
                    <TableRow
                      key={tournament.id}
                    >
                      <TableCell>{tournament.agegroup}</TableCell>
                      <TableCell onClick={() => handleTeamClick(tournament)}>
                        <Link
                          component="button"
                          variant="body2"
                          color="primary"
                          underline="hover"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTeamClick(tournament);
                          }}
                        >
                          {tournament.name}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={teamList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                p: 2,
                borderRadius: 2,
                width: 400, // Set a fixed width for the modal content
                maxWidth: '90%', // Ensure the content is responsive
              }}
            >
              <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={handleCloseModal}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography variant="h6" align="center">
                {selectedTeam?.teamName} Players
              </Typography>
              <List>
                {playersList?.map((player, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={player.participantName} align="center" />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Modal>
        </>
      )}

    </Container>
  );
};

export default TournamentDetails;
