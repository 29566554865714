import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTeam, getAllTournamentTeams, clearTeamErrors } from '../redux/actions/TournamentTeamAction';
import Details from '../components/DetailsPage/Details';
import Loader from '../components/Loader';
import ModalOne from '../components/ModalOne'; // Import ModalOne component
import {getAllEvents} from "../redux/actions/TournamentEventsAction";

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

export default function TournamentTeam() {
  const dispatch = useDispatch();
  const { tournamentTeams, loading, deleted, error } = useSelector((state) => state.tournamentTeams);
  const { tournamentEvents } = useSelector((state) => state.tournamentEvents);

  const [agegroup, setAgegroup] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [tournament, setTournament] = useState('');
  const [filteredTournamentEvents, setFilteredTournamentEvents] = useState([]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleShowModals = () => {
    setShowModal(true);
  };

  const handleCloseModals = () => {
    setShowModal(false);
  };

  const handleAgeGroup = (event) => {
    const selectedAgeGroup = event.target.value;
    setAgegroup(selectedAgeGroup);
  
    // Check if tournamentEvents exists before filtering
    if (tournamentEvents) {
      // Filter tournament events based on the selected age group
      const filteredEvents = tournamentEvents.filter(event => event.agegroup === selectedAgeGroup);
    console.log('filtereddd',filteredEvents)
      setFilteredTournamentEvents(filteredEvents);
    }
  };
  
  

  const handleTournament = (event) => {
    setTournament(event.target.value);
  };

  const addTeam = () => {
    if (!teamName.trim()) {
      toast.error('Team name is required');
      return;
    }
    if (!selectedImage) {
      toast.error('Image is required');
      return;
    }
    if (!tournament) {
      toast.error('Tournament is required');
      return;
    }
    if (!agegroup) {
      toast.error('Age is required');
      return;
    }

    const obj = { name: teamName, imageURL: selectedImage, agegroup, tournamentId: tournament };

    dispatch(createTeam(obj))
      .then(() => dispatch(getAllTournamentTeams()))
      .then(() => {
        handleCloseModals();
        setTeamName('');
        setAgegroup('');
        setSelectedImage('');
        setTournament('');
      })
      .catch((error) => {
        console.error('Error adding team:', error);
      });
  };

  useEffect(() => {
    if (error) {
      dispatch(clearTeamErrors());
    }
    dispatch(getAllTournamentTeams());
    dispatch(getAllEvents());
  }, [error, deleted]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ToastContainer />
      {/* Render ModalOne component conditionally */}
      {showModal && (
        <ModalOne
          title="Add Tournament Team"
          handleClose={handleCloseModals}
          handleImageUpload={handleImageUpload}
          handleInputChange={(e) => setTeamName(e.target.value)}
          handleSelectChange={handleAgeGroup}
          selectedAgeGroup={agegroup}
          handleAdd={addTeam}
          buttonText="Add Team"
          inputLabel="Team Name"
          options={ages}
          showTournamentDropdown // Pass true to show tournament dropdown
          tournamentOptions={filteredTournamentEvents} // Pass filtered events data for the tournament dropdown
          selectedTournament={tournament} // Pass selected tournament value if needed
          handleTournamentChange={handleTournament} // Pass handler for tournament dropdown change event
          tournamentSelectLabel="Select Tournament" // Pass label for the tournament dropdown
          showCityDropdown={false}
          selectedCity={null}
          cityOptions={null}
          handleCityChange={null}
          />
      )}

      <Container maxWidth="lg">
        <Box mt={5}>
          <Details label={'TournamentTeams'} addDetails={handleShowModals} data={tournamentTeams || []} />
        </Box>
      </Container>
    </>
  );
}
