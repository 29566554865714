import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
import {
  CREATE_TRAININGPROGRAMCARD_REQUEST,
  CREATE_TRAININGPROGRAMCARD_SUCCESS,
  CREATE_TRAININGPROGRAMCARD_FAIL,
  UPDATE_TRAININGPROGRAMCARD_REQUEST,
  UPDATE_TRAININGPROGRAMCARD_SUCCESS,
  UPDATE_TRAININGPROGRAMCARD_FAIL,
  DELETE_TRAININGPROGRAMCARD_REQUEST,
  DELETE_TRAININGPROGRAMCARD_SUCCESS,
  DELETE_TRAININGPROGRAMCARD_FAIL,
  GET_TRAININGPROGRAMCARD_REQUEST,
  GET_TRAININGPROGRAMCARD_SUCCESS,
  GET_TRAININGPROGRAMCARD_FAIL,
  CLEAR_TRAININGPROGRAMCARD_ERRORS,
  ALL_TRAININGPROGRAMCARD_REQUEST,
  ALL_TRAININGPROGRAMCARD_SUCCESS,
  ALL_TRAININGPROGRAMCARD_FAIL,
} from '../constants/TrainingProgramCardsConstants';

export const getAllTrainingProgramCards = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_TRAININGPROGRAMCARD_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listTrainingProgramCards));

    const updatedTrainingProgramCards = response.data.listTrainingProgramCards.items || [];

    const updatedData = {
      trainingProgramCards: updatedTrainingProgramCards,
      trainingProgramCardsCount: updatedTrainingProgramCards?.length,
      resultPerPage: 10,
      filteredTrainingProgramCardsCount: null,
    };

    dispatch({
      type: ALL_TRAININGPROGRAMCARD_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_TRAININGPROGRAMCARD_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getTrainingProgramCardDetails = (trainingProgramCardId) => async (dispatch) => {
  try {
    dispatch({ type: GET_TRAININGPROGRAMCARD_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getTrainingProgramCard, { id: trainingProgramCardId }));

    const newData = response?.data.getTrainingProgramCard;
    dispatch({
      type: GET_TRAININGPROGRAMCARD_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_TRAININGPROGRAMCARD_FAIL,
      payload: errorMessage,
    });
  }
};

export const createTrainingProgramCard = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TRAININGPROGRAMCARD_REQUEST });

    const response = await API.graphql({
      query: mutations.createTrainingProgramCard,

      variables: {
        input: {
          ...formData,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createTrainingProgramCard;
    toast.success('Training Program Card Added Successfully!');

    dispatch({
      type: CREATE_TRAININGPROGRAMCARD_SUCCESS,
      payload: data,
    });
    toast.success('Created!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('TRAININGPROGRAMCARD error', error);

    dispatch({
      type: CREATE_TRAININGPROGRAMCARD_FAIL,
      payload: error,
    });
  }
};
export const deleteTrainingProgramCard = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TRAININGPROGRAMCARD_REQUEST });

    API.graphql({
      query: mutations.deleteTrainingProgramCard,
      variables: { input: { id } },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then(async (response) => {
      toast.success('Training Program Card Deleted Successfully!');

      console.log(response);
      dispatch({
        type: DELETE_TRAININGPROGRAMCARD_SUCCESS,
        payload: true,
      });
    });

    toast.success('Deleted!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_TRAININGPROGRAMCARD_FAIL,
      payload: error || 'Failed to fetch TrainingProgram CARD data',
    });
  }
};
export const updateTrainingProgramCard = (trainingProgramCardId, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TRAININGPROGRAMCARD_REQUEST });

    const data = {
      title: formData?.title,
      price: formData?.price,
      description: formData?.description,
    };

    const response = await API.graphql({
      query: mutations.updateTrainingProgramCard,
      variables: { input: { ...data, id: trainingProgramCardId } },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const updatedDetails = response?.data?.updateTrainingProgramCard;
    toast.success('Training Program Card Updated Successfully!');
    dispatch({
      type: UPDATE_TRAININGPROGRAMCARD_SUCCESS,
      payload: updatedDetails,
    });
    toast.success('Updated!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.error('Error updating TRAININGPROGRAMCARD:', error);

    dispatch({
      type: UPDATE_TRAININGPROGRAMCARD_FAIL,
      payload: error || 'Failed to update TRAININGPROGRAMCARD',
    });
  }
};
export const clearTrainingProgramCardsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_TRAININGPROGRAMCARD_ERRORS });
};
