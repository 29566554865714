import React from 'react';
import { Box, TextField, Grid, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';

const RecordBox = ({ stateSetter, scoreId, onChangeSetter }) => {
  const [scoreData, setScoreData] = React.useState({
    record: '',
    ranking: '',
    ppg: '',
    rpe: '',
    apg: '',
    date: '',
    time: '',
    courtNo: null,
  });

  const { record, ranking, ppg, rpe, apg, date, time } = scoreData;

  const handleChange = (event, field) => {
    const value = event.target.value;
    setScoreData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    onChangeSetter(field, value);
  };

  const getScoreById = async (id) => {
    try {
      const response = await API.graphql({
        query: `
          query GetScoreById($id: ID!) {
            getScore(id: $id) {
              id
              date
              time
              record
              ranking
              apg
ppg
rpe
courtNo
            }
          }
        `,
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const scoreData = response.data.getScore;
      setScoreData({
        record: scoreData.record,
        ranking: scoreData.ranking,
        ppg: scoreData?.ppg,
        rpe: scoreData?.rpe,
        apg: scoreData?.apg,
        date: scoreData?.date,
        time: scoreData?.time,
        courtNo: scoreData?.courtNo,
      });
    } catch (error) {
      console.error('Error getting score by ID:', error);
      throw error;
    }
  };

  // Call the getScoreById function when the component mounts to fetch the score data
  React.useEffect(() => {
    if (scoreId) {
      getScoreById(scoreId);
    }
  }, [scoreId]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <DatePicker
            label="Select Date"
            value={dayjs(scoreData.date)}
            onChange={(date) => handleChange({ target: { value: date } }, 'date')}
            renderInput={(params) => <TextField {...params} style={{ boxShadow: 'none' }} />}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            label="Select Time"
            value={dayjs(scoreData.time)}
            onChange={(time) => handleChange({ target: { value: time } }, 'time')}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" style={{ width: '120px', boxShadow: 'none' }} />
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            label="Court No"
            value={scoreData.courtNo}
            style={{ width: '120px' }}
            onChange={(event) => handleChange(event, 'courtNo')}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container mt={2} spacing={2}>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            label="Record"
            value={scoreData?.record}
            style={{ width: '120px' }}
            onChange={(event) => handleChange(event, 'record')}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            label="Ranking"
            style={{ width: '120px' }}
            value={scoreData?.ranking}
            onChange={(event) => handleChange(event, 'ranking')}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            label="PPG"
            style={{ width: '120px' }}
            value={scoreData?.ppg}
            onChange={(event) => handleChange(event, 'ppg')}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            label="RPE"
            style={{ width: '120px' }}
            value={scoreData?.rpe}
            onChange={(event) => handleChange(event, 'rpe')}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            label="APG"
            style={{ width: '120px' }}
            value={scoreData?.apg}
            onChange={(event) => handleChange(event, 'apg')}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecordBox;
