import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
  Stack,
  Box,
  Typography,
   
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Iconify from '../../../components/iconify';
import Loader from '../../../components/Loader';
import { getAllClinicCourses } from '../../../redux/actions/ClinicCoursesAction';

AddNewClinicCourseSideBar.propTypes = {
  openCourse: PropTypes.bool,
  onOpenCourse: PropTypes.func,
  onCloseCourse: PropTypes.func,
};

export default function AddNewClinicCourseSideBar({
  updateCourses,
  hidden,
  adddCourse,
  openCourse,
  onOpenCourse,
  onCloseCourse,
  editData,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const isEditMode = !!editData;

  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      setTitle(editData.title || '');
      setDescription(editData?.description || '');
      setPrice(editData.price || '');
      setQuantity(editData.quantity || '');
    }
  }, [editData, isEditMode]);
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleUploadData = async () => {
    if (!title) {
      toast.error('Title is required.');
      return;
    }
  
    // Check if price is empty
    if (!price) {
      toast.error('Price is required.');
      return;
    }
     // Check if price is empty
     if (!description) {
      toast.error('Description is required.');
      return;
    }
     // Check if price is empty
     if (!quantity) {
      toast.error('Quantity is required.');
      return;
    }
    const courseData = {
      title,
      price,
      description,
      quantity,
      id: editData?.id,
    };
    // Handle data upload logic here
    if (isEditMode) {
      const id = courseData?.id;
      onCloseCourse();
      const updated = await updateCourses(id, courseData);

      if (updated) {
        dispatch(getAllClinicCourses());
      }
    } else {
      const data = {
        ...courseData,
      };

      onCloseCourse();
      const created = await adddCourse(data);
      // Close the modal

      if (created) {
        dispatch(getAllClinicCourses());
      }
    }
 
    // Reset form fields
    setTitle('');
    setPrice('');
    setDescription('');
    setQuantity('');
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenCourse}
        style={buttonStyles}
        hidden={hidden}
      >
        Add Clinic Course
      </Button>
      <Dialog open={openCourse} onClose={onCloseCourse}>
        <DialogTitle>{isEditMode ? 'Edit Clinic Course' : 'Add New Clinic Course'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new Clinic Course.</DialogContentText>
          <TextField autoFocus margin="dense" label="Title" fullWidth value={title} onChange={handleTitleChange} />
          <TextField autoFocus margin="dense" label="Price" fullWidth value={price} onChange={handlePriceChange} />
        
          <FormControl fullWidth margin="dense">
            <TextField
              type="text"
              label="Description"
              value={description}
              name="description"
              margin="normal"
              fullWidth
              onChange={handleDescriptionChange}
          
            />
          </FormControl>
          <TextField autoFocus margin="dense" label="Quantity" fullWidth value={quantity} onChange={handleQuantityChange} />
         
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={onCloseCourse} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
