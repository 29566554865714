import {
    CREATE_CAMPCOURSE_REQUEST,
    CREATE_CAMPCOURSE_SUCCESS,
    CREATE_CAMPCOURSE_FAIL,
    UPDATE_CAMPCOURSE_REQUEST,
    UPDATE_CAMPCOURSE_SUCCESS,
    UPDATE_CAMPCOURSE_FAIL,
    DELETE_CAMPCOURSE_REQUEST,
    DELETE_CAMPCOURSE_SUCCESS,
    DELETE_CAMPCOURSE_FAIL,
    GET_CAMPCOURSE_REQUEST,
    GET_CAMPCOURSE_SUCCESS,
    GET_CAMPCOURSE_FAIL,
    CLEAR_CAMPCOURSE_ERRORS,
    ALL_CAMPCOURSE_REQUEST,
    ALL_CAMPCOURSE_SUCCESS,
    ALL_CAMPCOURSE_FAIL,
  } from '../constants/CampCourseConstants';
  
  const initialState = {
    campCourses: [],
    campCourseCount: 0,
    resultPerPage: 0,
    course: {},
    isUpdated:false,
    loading: false,
    error: null,
    filteredCampCoursesCount: 0,
  };
  
  export const campCoursesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ALL_CAMPCOURSE_REQUEST:
      case GET_CAMPCOURSE_REQUEST:
      case CREATE_CAMPCOURSE_REQUEST:
      case DELETE_CAMPCOURSE_REQUEST:
      case UPDATE_CAMPCOURSE_REQUEST:
        return {
          ...state,
          loading: true,
          campCourses: [],
          course: {},
        };
      case ALL_CAMPCOURSE_SUCCESS:
        return {
          ...state,
          loading: false,
          campCourses: action.payload.campCourses,
          campCourseCount: action.payload.campCoursesCount,
          resultPerPage: action.payload.resultPerPage,
          filteredCampCoursesCount: action.payload.filteredCampCoursesCount,
        };
      case CREATE_CAMPCOURSE_SUCCESS:
      case GET_CAMPCOURSE_SUCCESS:
        return {
          ...state,
          loading: false,
          course: action.payload,
        };
      case DELETE_CAMPCOURSE_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_CAMPCOURSE_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: true,
        };
      case GET_CAMPCOURSE_FAIL:
      case ALL_CAMPCOURSE_FAIL:
      case CREATE_CAMPCOURSE_FAIL:
      case UPDATE_CAMPCOURSE_FAIL:
      case DELETE_CAMPCOURSE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CAMPCOURSE_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  