/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL mutations

const useHandleEvent = () => {
  const [eventDetails, setEvent] = useState();
  const [eventList, setEventList] = useState();

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listLeague5x5s, // Replace with the appropriate GraphQL query for listing teams
        variables: {
          filter: {
            eventStatus: { eq: 'EVENT_NOT_STARTED' }, // Check if the team name already exists
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const eventData = response?.data?.listLeague5x5s?.items;
      console.log('event s data ', eventData);
      setEventList(eventData);
      setLoading(false);
      return eventData;
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };

  const fetchTeamsByLocation = async (locationFilter) => {
    const listTeamsQuery = `
    query ListTeams($nextToken: String, $limit: Int, $filter: ModelTeamFilterInput) {
      listTeams(nextToken: $nextToken, limit: $limit, filter: $filter) {
        items {
          id
          name
          location
        }
        nextToken
      }
    }
  `;

    try {
      setLoading(true);
      let filteredTeams = [];
      let nextToken = null;
      const limit = 1000; // Adjust based on your API's max limit

      const filter = {
        location: {
          eq: locationFilter,
        },
      };

      do {
        const response = await API.graphql(graphqlOperation(listTeamsQuery, { nextToken, limit, filter }));

        const { items, nextToken: newNextToken } = response.data.listTeam5x5s;
        filteredTeams = filteredTeams.concat(items);
        nextToken = newNextToken;

        console.log(`Fetched ${items.length} teams. Total: ${filteredTeams.length}`);
      } while (nextToken);

      console.log(`Scan complet`);
      setLoading(false);

      return filteredTeams;
    } catch (error) {
      console.error('Error scanning teams:', error);
      setLoading(false);
      throw error;
    }
  };

  const fetchEventById = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.getLeague5x5, { id }));
      const data = response?.data?.getLeague5x5;
      setEvent(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching event details:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  const deleteEventById = async (idToDelete) => {
    try {
      setLoading(true);

      const idDelete = id || idToDelete;
      await API.graphql(graphqlOperation(mutations.deleteLeague5x5, { input: { id: idDelete } }));

      setEvent(null);
      setLoading(false);
      toast.success('Camp Data Deleted Successfully!');

      setEvent([]);
      setLoading(false);
      navigate('/dashboard/season2');
    } catch (error) {
      console.error('Error deleting event:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  const updateEventById = async (data) => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(mutations.updateLeague5x5, { input: { id, ...data } }));
      const updatedData = response?.data?.updateLeague5x5;

      setEventList((prevList) => prevList.map((user) => (user.id === updatedData.id ? updatedData : user)));

      setEvent(updatedData);
      setLoading(false);
      toast.success('Camp Data Updated Successfully!');
    } catch (error) {
      console.error('Error updating event:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  useEffect(() => {
    if (id) {
      fetchEventById();
    }
    fetchEvents();
  }, [id]);

  return { eventDetails, fetchTeamsByLocation, eventList, fetchEvents, loading, deleteEventById, updateEventById };
};

export default useHandleEvent;
