import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
  Stack,
  Box,
  Typography,
   
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import useGetTeams from '../../../hooks/getTeamsList';
import useCityList from '../../../hooks/getCityList';
import useAddEvents from '../../../hooks/addEvents';
import Loader from '../../../components/Loader';
import useUpdateEvents from '../../../hooks/updateEvent';
import { getAllEvents } from '../../../redux/actions/EventsAction';

AddNewEventSideBar.propTypes = {
  openEvent: PropTypes.bool,
  onOpenEvent: PropTypes.func,
  onCloseEvent: PropTypes.func,
};

export default function AddNewEventSideBar({
  updateEvents,
  hidden,
  adddEvent,
  openEvent,
  onOpenEvent,
  onCloseEvent,
  editData,
  events,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [team1, setTeam1] = useState('');
  const [description, setDescription] = useState('');
  const [team2, setTeam2] = useState('');
  const [location, setLocation] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [image, setImage] = useState('');
  const [newImage, setNewImage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const isEditMode = !!editData;

  // const teamsId=[]; // Check if editData is present
  const { teams } = useGetTeams();
  const { cities } = useCityList();
  // const { updateEvents, loading: updateLoading } = useUpdateEvents();
  // const { adddEvent, loading } = useAddEvents();

  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      const formattedDate = new Date(editData?.date).toISOString().slice(0, 10);
      setTitle(editData.title || '');
      setDescription(editData?.description || '');
      setPrice(editData.selling_price || '');
      // setTeam1(editData.teamsName[0] || '');
      // setTeam2(editData.teamsName[1] || '');
      setLocation(editData.location || '');
      setSelectedDate(formattedDate || '');
      setImage(editData.img_url || '');
      setNewImage(editData.img_url || '');
    }
  }, [editData, isEditMode]);
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleTeam1Change = (event) => {
    setTeam1(event.target.value);
    // Use functional update to correctly update the array

    setErrorMessage('');
  };

  const handleTeam2Change = (event) => {
    setTeam2(event.target.value);

    setErrorMessage('');
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      // setImage(selectedImage);
      setNewImage(selectedImage);
    } else {
      setImage(null);
    }
  };

  const handleUploadData = async () => {
    if (!title) {
      toast.error('Title is required.');
      return;
    }
  
    // Check if price is empty
    if (!price) {
      toast.error('Price is required.');
      return;
    }
    if (!location) {
      toast.error('Location is required.');
      return;
    }
    if (!selectedDate) {
      toast.error('Date is required.');
      return;
    }
     // Check if price is empty
     if (!description) {
      toast.error('Description is required.');
      return;
    }
    if (!newImage && !isEditMode) {
      toast.error('Image is required.');
      return;
    }
    // Check if team1 and team2 are the same
    const teamsId = [];
    teams?.map((team, index) => {
      if (team1 === team?.name) {
        teamsId.push(team?.id);
      }
      if (team2 === team?.name) {
        teamsId.push(team?.id);
      }
      return null;
    });

    if (team1 === team2 && team1 !=='' && team2 !=='') {
      // Display an error message or handle it as needed
      setErrorMessage('Team 1 and Team 2 cannot be the same');
      return; // Prevent further execution
    }
    const date = new Date(selectedDate);
    const eventData = {
      title,
      selling_price: price,
      // teamsId: [{firstTeamId:teamsId[0], secondTeamId:team2[1]}],
      // teamsName: [{firstTeam:team1, secondTeam:team2}],
      location,
      description,
      date,
      img_url: image,
      newImage,
      id: editData?.id,
    };
    // Handle data upload logic here
    // console.log('Data to upload:', eventData);
    if (isEditMode) {
      const id = eventData?.id;
      // console.log('eventsDtaa',eventData)
      onCloseEvent();
      const updated = await updateEvents(id, eventData);

      if (updated) {
        dispatch(getAllEvents());
      }
    } else {
      // dispatch(createEvent(eventData));
      delete eventData?.newImage;
      const data = {
        ...eventData,
        img_url: newImage,
        eventStatus: 'EVENT_NOT_STARTED',
      };

      onCloseEvent();
      const created = await adddEvent(data);
      // Close the modal

      if (created) {
        dispatch(getAllEvents());
      }
    }
    // await fetchEventsLists();

    // Reset form fields
    setTitle('');
    setPrice('');
    setTeam1('');
    setDescription('');
    setTeam2('');
    setLocation('');
    setSelectedDate('');
    setImage(null);
    setErrorMessage('');
    // Close the modal
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenEvent}
        style={buttonStyles}
        hidden={hidden}
      >
        Add Event
      </Button>
      <Dialog open={openEvent} onClose={onCloseEvent}>
        <DialogTitle>{isEditMode ? 'Edit Event' : 'Add New Event'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new event.</DialogContentText>
          <TextField autoFocus margin="dense" label="Title" fullWidth value={title} onChange={handleTitleChange} />
          <TextField autoFocus margin="dense" label="Price" fullWidth value={price} onChange={handlePriceChange} />
          {/* <FormControl fullWidth margin="dense">
            <InputLabel id="team1">Team 1</InputLabel>
            <Select
              labelId="team1"
              label="Team 1"
              name="team1"
              value={team1}
              fullWidth
              variant="outlined"
              onChange={handleTeam1Change}
            >
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.name}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="team2">Team 2</InputLabel>
            <Select
              labelId="team2"
              label="Team 2"
              name="team2"
              fullWidth
              variant="outlined"
              value={team2}
              onChange={handleTeam2Change}
            >
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.name}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          {errorMessage && (
            <Typography variant="body2" color="error" sx={{ marginTop: 2, marginBottom: 2 }}>
              {errorMessage}
            </Typography>
          )}
          <FormControl fullWidth margin="dense">
            <InputLabel id="location">Location</InputLabel>
            <Select
              labelId="location"
              label="Location"
              name="location"
              fullWidth
              required

              variant="outlined"
              value={location}
              onChange={handleLocationChange}
            >
              {cities.map((cities) => (
                <MenuItem key={cities.id} value={cities.name}>
                  {cities.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <TextField
              type="date"
              label="Date"
              value={selectedDate}
              name="date"
              onChange={handleDateChange}
              fullWidth
              margin="normal"
              required

              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <TextField
              type="text"
              label="Description"
              value={description}
              name="description"
              margin="normal"
              fullWidth
              onChange={handleDescriptionChange}
          
            />
          </FormControl>
          <Input
            type="file"
            id="image"
            sx={{ marginTop: 2 }}
            label="Upload Image"
            variant="outlined"
            required

            fullWidth
            inputProps={{ accept: 'image/*' }}
            onChange={handleImageChange}
          />
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={onCloseEvent} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
