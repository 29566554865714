import { useEffect, useState } from 'react';
/* eslint-disable arrow-body-style */
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// utils
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../components/iconify';
import AddNewsSideBar from './AddNewsSideBar';

// ----------------------------------------------------------------------
const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});
const StyledVideo = styled('iframe')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});

// ----------------------------------------------------------------------

NewsCard.propTypes = {
  news: PropTypes.object,
};
function formatDate(date) {
  // Ensure that the input date is a Date object
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  // Get day, month, and year components from the date
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  // Format the date as "dd-mm-yyyy"
  return `${day}-${month}-${year}`;
}
export default function NewsCard({ news, setNewsList, updateNewsDetails, deleteNews, fetchLeagues }) {
  const [deleted, setIsDeleted] = useState(false);
  console.log('newssss', news);
  const navigate=useNavigate()
  const {
    title,
    content_img_url: image,
    youtube_url: videoUrl,
    type,
    content_text: contentText,
    createdAt,
    id,
  } = news;
  // console.log('updatedn_News', news);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleEditClick = async () => {
    navigate(`/create-new/${news?.id}`);
    // setEditData(news);
    // setIsEditDialogOpen(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      setNewsList((prevNews) => prevNews?.filter((item) => item?.id !== id));
      setIsDeleteDialogOpen(false); // Close delete dialog
      // toast.success("Deleted")

      const response = await deleteNews(id);
    } catch (error) {
      setIsDeleted(false); // Hide loader
    }
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  // Assuming createdAt is a Date object or a string date in a valid format
  const formattedDate = formatDate(createdAt);

  return (
    <>
      {isEditDialogOpen && ( // Conditionally render AddNewsSideBar
        <AddNewsSideBar
          news={news}
          openEvent={isEditDialogOpen} // Pass the state that controls the Dialog visibility
          onCloseEvent={() => setIsEditDialogOpen(false)} // Pass the function to close the Dialog
          editData={editData}
          setLoading={setIsDeleted}
          setNewsList={setNewsList}
          hidden
          updateNewsDetails={updateNewsDetails}
        />
      )}
      <Card className="overflow-hidden">
        <Box sx={{ pt: '100%', position: 'relative' }}>
          {type === 'VLOG' ? (
            <StyledVideo title={title} src={videoUrl} frameBorder="0" allowFullScreen />
          ) : (
            <StyledProductImg alt={title} src={image} />
          )}
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Link color="inherit" underline="hover">
            <Typography variant="subtitle2" noWrap>
              {title}
            </Typography>
          </Link>

          {/* Display team1 vs team2 */}
          {/* Display brief and createdAt */}
          <Typography variant="subtitle2" color="inherit">
            <EventIcon /> {formattedDate}
          </Typography>
          {/* Display brief and createdAt */}
          {/* <Typography
          variant="subtitle2"
          sx={{ maxHeight: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
          color="inherit"
        >
          {brief.split(' ').slice(0, 40).join(' ')}
          {brief.split(' ').length > 40 ? '...' : ''}
        </Typography> */}
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <EditButton onEditClick={handleEditClick} />

            <DeleteButton onDeleteClick={handleDeleteClick} />
          </Stack>
          <Dialog
            open={isDeleteDialogOpen}
            onClose={handleDeleteCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete News</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this News?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Card>
    </>
  );
}

const DeleteButton = ({ onDeleteClick }) => {
  return (
    <Button color="primary" onClick={onDeleteClick}>
      <Iconify color="red" icon="fluent:delete-12-regular" />
    </Button>
  );
};
const EditButton = ({ onEditClick }) => {
  return (
    // <Link>
    <Button onClick={onEditClick}>
      <EditIcon color="primary" style={{ fontSize: '18px' }} />
    </Button>
    // </Link>
  );
};
