import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Input,
} from '@mui/material';
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import uploadFile from 'src/utils/uploadFile'; // Utility to handle file upload

// Prop Types
AddNewCarouselSideBar.propTypes = {
  openCarousel: PropTypes.bool,
  onOpenCarousel: PropTypes.func,
  onCloseCarousel: PropTypes.func,
  editData: PropTypes.object,
  updateCarouselImages: PropTypes.func,
  createCarouselImages: PropTypes.func,
  hidden: PropTypes.bool,
};

export default function AddNewCarouselSideBar({
  openCarousel,
  onOpenCarousel,
  onCloseCarousel,
  editData,
  updateCarouselImages,
  createCarouselImages,
  hidden = false,
}) {
  const [image, setImage] = useState(''); // Single image URL
  const [text, setText] = useState(editData?.text||null); // Single image URL

  const [loading, setLoading] = useState(false); // Single image URL

  const [carouselId, setCarouselId] = useState(null); // Store carousel ID for edits
  const [imageFile, setImageFile] = useState(null); // Store selected file

  const isEditMode = !!editData;

  // Prefill the form if in edit mode
  useEffect(() => {
    if (isEditMode && editData) {
      setImage(editData.imageLink || ''); // Set the existing image URL for editing
      setCarouselId(editData.id || ''); //
      setText(editData.text || ''); // Store the carousel ID
    }
  }, [editData, isEditMode]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  const handleUploadImage = async () => {
    if (!imageFile) {
      toast.error('Please select an image to upload.');
      return null;
    }

    try {
      const imageUrl = await uploadFile(imageFile);
      setImage(imageUrl); // Set the uploaded image URL
      setImageFile(null); // Reset the file input
      return imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image.');
      return null;
    }
  };

  const handleUploadData = async () => {
    setLoading(true)
    try {
      let imageUrl = imageFile;

      // If a new image file is uploaded, upload it first
      
      if (!text) {
        toast.error('Text is required.');
        return;
      }

      if (imageFile) {
        imageUrl = await handleUploadImage();
      }

      if (!imageUrl&&!isEditMode) {
        toast.error('An image is required.');
        return;
      }
     


      onCloseCarousel(); // Close the dialog after handling upload
      if (isEditMode) {
        // Update existing carousel image
        const sendthis = {
          id: carouselId,
          newImageUrl: imageUrl,
          text,

        }
        await updateCarouselImages(sendthis);
        toast.success('Carousel image updated successfully.');
      } else {
        // Create a new carousel image
        const sendthis = {
          newImageUrl: imageUrl,
          text,

        }
        await createCarouselImages(sendthis);
        toast.success('Carousel image created successfully.');
      }

      // Clear the form after successful upload
      setImage('');
      setText(null);

      setImageFile(null);
    } catch (error) {
      console.error('Error uploading carousel images:', error);
      toast.error('Failed to upload carousel images.');
    } finally {
      setLoading(false)

    }
  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleUploadData();
    }
  };


  return (
    <>
      <Button
        disableRipple
        hidden={hidden}
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenCarousel}
        style={{ backgroundColor: '#1E90FF', color: 'white' }}
      >
        {isEditMode ? 'Edit Carousel Image' : 'Add Carousel Image'}
      </Button>

      <Dialog open={openCarousel} onClose={onCloseCarousel}>
        <DialogTitle>{isEditMode ? 'Edit Carousel Image' : 'Add New Carousel Image'}</DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <Typography
              sx={{
                fontSize: '14px',
                color: 'gray',
                wordSpacing: '3px',
                fontWeight: 'medium',
              }}
            >
              Upload an image for the carousel. You can only add one image at a time.
            </Typography>

            {/* {image && (
              <Box display="flex" alignItems="center">
                <TextField
                  value={image}
                  fullWidth
                  disabled
                  sx={{ marginRight: 1 }}
                />
                <Button onClick={() => setImage('')} color="error">
                  Remove
                </Button>
              </Box>
            )} */}

            <Box display="flex" flexDirection={"column"} gap={5} alignItems="center">
              {/* <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ marginRight: '10px' }}
              /> */}
              <Input
                type="file"
                id="file"
                sx={{ marginTop: 2 }}
                label="Upload Image"
                variant="outlined"
                fullWidth
                accept="image/*"
                required
                onKeyPress={handleKeyPress}
                onChange={(e) => handleFileChange(e)}
              />

              {/* <input
                type="text"
                // accept="image/*"
                onChange={(e) => setText(e.target.value

                )} style={{ marginRight: '10px' }}
              /> */}
              <TextField
                label="Text"
                type="text"
                required
                fullWidth
                value={text}
                onKeyPress={handleKeyPress}
                onChange={(e) => setText(e.target.value)}
              />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Button disabled={loading} onClick={onCloseCarousel} style={{ backgroundColor: 'red', color: 'white' }}>
              Cancel
            </Button>
            <Button disabled={loading} onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
              {loading ? "uploading" : isEditMode ? 'Update' : 'Save'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
