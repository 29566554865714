import { selector } from "recoil";
import { userAtom } from "./atoms";

export const userState = selector({
    key: "userState",
    get: ({get}) => {
        const user = get(userAtom);
        return { user }
    }
});

