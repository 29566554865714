import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
import {
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAIL,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
  GET_COURSE_FAIL,
  CLEAR_COURSE_ERRORS,
  ALL_COURSE_REQUEST,
  ALL_COURSE_SUCCESS,
  ALL_COURSE_FAIL,
} from '../constants/CourseConstants';

export const getAllCourses = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_COURSE_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listTrainingProgramCourses));

    const updatedCourses = response.data.listTrainingProgramCourses.items || [];

    const updatedData = {
      courses: updatedCourses,
      coursesCount: updatedCourses?.length,
      resultPerPage: 10,
      filteredCoursesCount: null,
    };

    dispatch({
      type: ALL_COURSE_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_COURSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getCourseDetails = (courseId) => async (dispatch) => {
  try {
    dispatch({ type: GET_COURSE_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getTrainingProgramCourse, { id: courseId }));

    const newData = response?.data.getTrainingProgramCourse;
    dispatch({
      type: GET_COURSE_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_COURSE_FAIL,
      payload: errorMessage,
    });
  }
};

export const createCourse = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_COURSE_REQUEST });

    const response = await API.graphql({
      query: mutations.createTrainingProgramCourse,

      variables: {
        input: {
          ...formData,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createTrainingProgramCourse;
    toast.success('Course  Added Successfully!');

    dispatch({
      type: CREATE_COURSE_SUCCESS,
      payload: data,
    });
    toast.success('Created!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('COURSE error', error);

    dispatch({
      type: CREATE_COURSE_FAIL,
      payload: error,
    });
  }
};
export const deleteCourse = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_COURSE_REQUEST });

    API.graphql({
      query: mutations.deleteTrainingProgramCourse,
      variables: { input: { id } },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then(async (response) => {
      toast.success('Course  Deleted Successfully!');

      console.log(response);
      dispatch({
        type: DELETE_COURSE_SUCCESS,
        payload: true,
      });
    });

    toast.success('Deleted!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_COURSE_FAIL,
      payload: error || 'Failed to fetch Course  data',
    });
  }
};
export const updateCourse = (courseId, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_COURSE_REQUEST });

    const data = {
      title: formData?.title,
      price: formData?.price,
      description: formData?.description,
    };

    const response = await API.graphql({
      query: mutations.updateTrainingProgramCourse,
      variables: { input: { ...data, id: courseId } },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const updatedDetails = response?.data?.updateTrainingProgramCourse;
    toast.success('Course Updated Successfully!');
    dispatch({
      type: UPDATE_COURSE_SUCCESS,
      payload: updatedDetails,
    });
    toast.success('Updated!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.error('Error updating COURSE:', error);

    dispatch({
      type: UPDATE_COURSE_FAIL,
      payload: error || 'Failed to update COURSE',
    });
  }
};
export const clearCoursesErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_COURSE_ERRORS });
};
