import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL mutations
import useGetLink from './getImageLink';
import uploadFile from '../utils/uploadFile';
import deleteFile from '../utils/deleteFile';

const useHandleTournamentUser = () => {
  const [tournamentUserDetails, setTournamentUser] = useState();
  const [tournamentList, setTournamentList] = useState([]);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { getLink } = useGetLink();

  const fetchTournamentUsers = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.listTournaments));
      const data = response?.data?.listTournaments?.items;

      const modifiedTournaments = data?.map((user) => {
        return {
          ...user,
          id: user?.id,
          name: user?.participantName,
          email: user?.email,
          status: 'Confirmed',
          //
          zone: 'Tournament',
          // Add other attributes you want to include
        };
      });
      setTournamentList(modifiedTournaments);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching tournament:', error);
      setLoading(false);
    }
  };

  const fetchTournamentUserById = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.getTournament, { id }));
      const data = response?.data?.getTournament;
      const profileLink = await getLink(data?.profile);
      const d = {
        ...data,
        profileLink,
      };
      setTournamentUser(d);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching event details:', error);
      setLoading(false);
    }
  };

  const deleteTournamentUserById = async (idToDelete) => {
    try {
      setLoading(true);

      const idDelete = id || idToDelete;

      const response = await API.graphql({
        query: mutations.deleteTournament,
        variables: { input: { id: idToDelete } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      setTournamentUser([]);
      setLoading(false);
      toast.success('Tournament Data Deleted Successfully!');
      fetchTournamentUserById();
      navigate('/dashboard/people');
    } catch (error) {
      console.error('Error deleting event:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  const updateTournamentById = async (data) => {
    try {
      setLoading(true);
      const { oldProfile, profile } = data;
      console.log(`updateCampById`, oldProfile, profile);
      if (oldProfile !== profile) {
        const deleted = await deleteFile(oldProfile);
        // if (deleted) {
        // }
        const p = await uploadFile(profile);
        data.profile = p;
      }
      delete data?.oldProfile;

      const response = await API.graphql({
        query: mutations.updateTournament,
        variables: { input: { id, ...data } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const updatedData = response?.data?.updateTournament;

      const profileLink = await getLink(updatedData?.profile);
      const d = {
        ...data,
        profileLink,
      };
      setTournamentList((prevList) => prevList.map((user) => (user.id === updatedData.id ? updatedData : user)));

      setTournamentUser(d);
      toast.success('Tournament Data Updated Successfully!');
      setLoading(false);
    } catch (error) {
      console.error('Error updating event:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  useEffect(() => {
    if (id) {
      fetchTournamentUserById();
    }
    fetchTournamentUsers();
  }, [id]);

  return {
    tournamentUserDetails,
    tournamentList,
    fetchTournamentUsers,
    loading,
    deleteTournamentUserById,
    updateTournamentById,
  };
};

export default useHandleTournamentUser;
