import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// utils
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from "react-redux";
import Iconify from '../../../components/iconify';
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import Loader from '../../../components/Loader';
import AddCouponSideBar from './AddCouponSideBar';
import useDeleteCoupon from '../../../hooks/deleteCoupon';
import { deleteCoupon,getAllCoupons} from "../../../redux/actions/CouponsAction";

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

CouponCard.propTypes = {
  coupons: PropTypes.object,
};
function formatDate(date) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  // Get day, month, and year components from the date
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  // Format the date as "dd-mm-yyyy"
  return `${day}-${month}-${year}`;
}

export default function CouponCard({ setCouponList, deleteCoupon, updateCouponDetails, coupon }) {
  const dispatch = useDispatch();

  const { code,
    type,
    couponValue,
    discountOnUpto,
    description,
    isUserLimit,
    isActive,
    maxUsageLimit,
    validFrom,
    validTo, 
    id,
    allProductsDiscount,
    productCategories,
    uniqueProduct
   } = coupon;
 

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  // const { deleteCoupon } = useDeleteCoupon();
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleEditClick = () => {
    setEditData(coupon);
    setIsEditDialogOpen(true);
  };
  const handleDeleteConfirm = async () => {
    // await deleteCoupon(id);
    // await fetchCoupons();
    // await dispatch(deleteCoupon(id)); 
    // dispatch(getAllCoupons()); 
    setCouponList((prevCoupon) => prevCoupon?.filter((item) => item?.id !== id));
    setIsDeleteDialogOpen(false); // Close delete dialog
    // toast.success("Deleted")
    // Show loader
      const res = await deleteCoupon(id);
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  // Assuming createdAt is a Date object or a string date in a valid format
  const formattedDate = formatDate(validFrom);
  const validToFormat = formatDate(validTo);

  return (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {code}
          </Typography>
        </Link>
        <Typography variant="subtitle2" noWrap>
          {type}
        </Typography>
        {/* <Typography variant="subtitle2" noWrap>
         Description:  {description}
        </Typography> */}
        {/* {type === 'PERCENTAGE_DISCOUNT' && (
          <>
            <Typography variant="subtitle2" noWrap>
              Percentage Discount:  {couponValue}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Minimum Discount Percentage: {discountOnUpto}
            </Typography>
          </>
        )}

        {type === 'FLAT_DISCOUNT' && (
          <>
            <Typography variant="subtitle2" noWrap>
              Flat Discount:  {couponValue}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Flat Minimum Discount:  {discountOnUpto}
            </Typography>
          </>
        )} */}
          {/* <Typography variant="subtitle2" noWrap>
          All Products Discount : {allProductsDiscount ? "YES" : "NO"}
        </Typography>
        <Typography variant="subtitle2" noWrap>
        Unique Product : {uniqueProduct ? "YES" : "NO"}
        </Typography>
        {uniqueProduct && (
          <>
            <Typography variant="subtitle2" noWrap>
            Product Categories  {productCategories}
            </Typography>
          </>
        )}
        <Typography variant="subtitle2" noWrap>
           Limit : {isUserLimit ? "YES" : "NO"}
        </Typography>
        {isUserLimit && (
          <>
            <Typography variant="subtitle2" noWrap>
             Max Usage Limit  {maxUsageLimit}
            </Typography>
          </>
        )} */}

        {/* <Typography variant="subtitle2" color="inherit">
          <EventIcon /> Valid From {formattedDate}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          <EventIcon />  Valid Till {validToFormat}
        </Typography> */}
        <Typography variant="subtitle2" noWrap>
          Status : {isActive ? "Active" : "Inactive"}
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">

          <EditButton onEditClick={handleEditClick} />
          {isEditDialogOpen && ( // Conditionally render AddCouponSideBar
            <AddCouponSideBar
              openCoupon={isEditDialogOpen} // Pass the state that controls the Dialog visibility
              onCloseCoupon={() => setIsEditDialogOpen(false)} // Pass the function to close the Dialog
              editData={editData} // Pass your edit data
            updateCouponDetails={updateCouponDetails}

            />
          )}
          <DeleteButton onDeleteClick={handleDeleteClick} />
        </Stack>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Coupon</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Coupon?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Card>
  );
}

const DeleteButton = ({ onDeleteClick }) => {
  return (
    <Button color="primary" onClick={onDeleteClick}>
      <Iconify color="red" icon="fluent:delete-12-regular" />
    </Button>
  );
};
const EditButton = ({ onEditClick }) => {
  return (
    <Button onClick={onEditClick}>
      <EditIcon color="primary" style={{ fontSize: '18px' }} />
    </Button>
  );
};