import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
  Box,
   
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import Loader from '../../../components/Loader';
import { getAllAddPrices } from '../../../redux/actions/AddPricesAction';

AddNewAddPriceSideBar.propTypes = {
  openAddPrice: PropTypes.bool,
  onOpenAddPrice: PropTypes.func,
  onCloseAddPrice: PropTypes.func,
};

const types = [
  // { label: 'PLAYER', value: 'PLAYER' },
  // { label: 'CAMP', value: 'CAMP' },
  { label: 'TOURNAMENT', value: 'TOURNAMENT' }
  ];

export default function AddNewAddPriceSideBar({
  updateAddPrices,
  hidden,
  adddAddPrice,
  openAddPrice,
  onOpenAddPrice,
  onCloseAddPrice,
  editData,
  addPrices,
}) {
  const dispatch = useDispatch();
  const [type, setType] = useState('');
  const [price, setPrice] = useState('');

  const isEditMode = !!editData;

  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      setType(editData.type || '');
      setPrice(editData.price || '');
    }
  }, [editData, isEditMode]);
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  


  const handleUploadData = async () => {
   // Check if type is empty
   if (!type) {
    toast.error('Type is required.');
    return;
  }

  // Check if price is empty
  if (!price) {
    toast.error('Price is required.');
    return;
  }
    const addPriceData = {
      type,
      price,
      id: editData?.id,
    };
    if (isEditMode) {
      const id = addPriceData?.id;
      onCloseAddPrice();
      const updated = await updateAddPrices(id, addPriceData);

      if (updated) {
        dispatch(getAllAddPrices());
      }
    } else {
      const data = {
        ...addPriceData,
      };

      onCloseAddPrice();
      const created = await adddAddPrice(data);
      if (created) {
        dispatch(getAllAddPrices());
      }
    }
 
    // Reset form fields
    setType('');
    setPrice('');
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenAddPrice}
        style={buttonStyles}
        hidden={hidden}
      >
        Add Price
      </Button>
      <Dialog open={openAddPrice} onClose={onCloseAddPrice}>
        <DialogTitle>{isEditMode ? 'Edit  Price' : 'Add New Price'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new Add Price.</DialogContentText>
          <FormControl fullWidth margin="dense">
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              label="Type"
              name="type"
              fullWidth
              required

              variant="outlined"
              value={type}
              onChange={handleTypeChange}
            >
              {types.map((type,index) => (
                <MenuItem key={index} value={type.label}>
                {type.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
           <TextField autoFocus margin="dense" label="Price" fullWidth value={price} onChange={handlePriceChange} />
      
         
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={onCloseAddPrice} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
