import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries'; // Import your GraphQL queries for listing teams

function useGetTeams() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function fetchTeams() {
    try {
      // Make the GraphQL API call to fetch the list of teams
      // const response = await API.graphql({
      //   query: queries.listTeam5x5s,
      //   authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      const response = await API.graphql(graphqlOperation(queries.listTeam5x5s));
      const teamData = response?.data?.listTeam5x5s?.items;

      setTeams(teamData);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchTeams();
  }, []);

  return { teams, loading, error, fetchTeams };
}

export default useGetTeams;
