import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Select,
  Container,
  Box,
  MenuItem,
  Grid,
  InputLabel,
  Checkbox,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import useGetLeaguesPlayerDetails from '../../hooks/getLeaguesPlayerDetails';
import useHandlePlayerUser from '../../hooks/handlePlayer';
import Loader from '../Loader';
import useUpdatePlayer from '../../hooks/updatePlayerById';
import useGetAllCity from '../../hooks/getCityList';
import useGetAllTeams from '../../hooks/getTeamsLists';
import uploadFile from '../../utils/uploadFile';
import deleteFile from '../../utils/deleteFile';

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

const League = () => {
  const { cities } = useGetAllCity();
  const {
    playerUserDetails: LeaguesPlayerDetails,
    deletePlayerUserById,
    updatePlayerById,
    loading,
  } = useHandlePlayerUser();

  const { teamslist } = useGetAllTeams();

  const [participant, setParticipant] = useState('');
  const { updatePlayer, loading: updateLoading } = useUpdatePlayer();
  const [emailAddress, setEmailAddress] = useState('');
  const [agegroup, setAgegroup] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [newImage, setNewImage] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [NBA, setNBA] = useState('');
  const [HS, setHS] = useState('');
  const [isVerified, setIsVerified] = useState(null);
  console.log('League Player Details', LeaguesPlayerDetails);

  const [parentEmailAddress, setParentEmailAddress] = useState('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState('');
  const [homePhoneNumber, setHomePhoneNumber] = useState('');
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState('');
  const [ext, setExt] = useState('');
  const [updatedTeams, setUpdatedTeams] = useState(teamslist || []);
const [country, setCountry] = useState('');
  const [registeredTeam, setRegisteredTeam] = useState('');
  const [cityOfRegistration, setCityOfRegistration] = useState('');
  const [eventSignUpFor, setEventSignUpFor] = useState('');
  const [profile, setProfile] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [isParentGuardian, setIsParentGuardian] = useState(false);
  const [grade, setGrade] = useState('');
  const [isFirstYearPlaying, setIsFirstYearPlaying] = useState('');
  const [jerseySize, setJerseySize] = useState('');
  const [schoolName, setSchoolName] = useState('');

  const [isMemberOfTeamOrClub, setIsMemberOfTeamOrClub] = useState('');
  const [clubName, setClubName] = useState('');
  const [secondaryEmergencyContact, setSecondaryEmergencyContact] = useState('');
  const [primaryEmergencyContact, setPrimaryEmergencyContact] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [primaryEmergencyContactEmail, setPrimaryEmergencyContactEmail] = useState('');
  const [primaryCallPhoneNumber, setPrimaryCallPhoneNumber] = useState('');
  const [secondaryEmergencyContactEmail, setSecondaryEmergencyContactEmail] = useState('');
  const [secondaryCallPhoneNumber, setSecondaryCallPhoneNumber] = useState('');
  const [howDidYouHear, setHowDidYouHear] = useState('');
  const [healthConditions, setHealthConditions] = useState('');
  const [signatureType, setSignatureType] = useState('');
  const [electronicSignature, setElectronicSignature] = useState('');
  const [agreeRelease, setAgreeRelease] = useState(false);
  const [agreeActive, setAgreeActive] = useState(false);
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [yearsHS, setYearsHS] = useState('');
  const [yearsNBA, setYearsNBA] = useState('');
  const [yearsCollege, setYearsCollege] = useState('');

  const handlePrimaryEmergencyContactChange = (e) => {
    setPrimaryEmergencyContact(e.target.value);
  };

  const handleAgeGroup = (e) => {
    const selectedAgeGroup = e.target.value;
    setAgegroup(selectedAgeGroup);
    const matchingTeams = teamslist.filter(
      (team) => team.location === cityOfRegistration && team.agegroup === selectedAgeGroup
    );
    console.log("ageGroup", teamslist, selectedAgeGroup, matchingTeams);
    // If there are matching teams, add them to updatedTeams
    if (matchingTeams.length > 0) {
      setUpdatedTeams([...matchingTeams]);
    } else {
      setUpdatedTeams([]);
    }
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
   
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const handlePrimaryEmergencyContactEmailChange = (e) => {
    setPrimaryEmergencyContactEmail(e.target.value);
  };
  const handleSecondaryEmergencyContactEmailChange = (e) => {
    setSecondaryEmergencyContactEmail(e.target.value);
  };
  const handlePrimaryCallPhoneNumberChange = (e) => {
    setPrimaryCallPhoneNumber(e.target.value);
  };
  const handleSecondaryCallPhoneNumberChange = (e) => {
    setSecondaryCallPhoneNumber(e.target.value);
  };
  const handleHeightChange = (e) => {
    setHeight(e.target.value);
    // Validation logic...
  };

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
    // Validation logic...
  };

  const handleYearsHSChange = (e) => {
    setYearsHS(e.target.value);
    // Validation logic...
  };

  const handleYearsNBAChange = (e) => {
    setYearsNBA(e.target.value);
    // Validation logic...
  };

  const handleYearsCollegeChange = (e) => {
    setYearsCollege(e.target.value);
    // Validation logic...
  };
  const handleAgreeReleaseChange = (event) => {
    setAgreeRelease(event.target.checked);
  };

  const handleAgreeActiveChange = (event) => {
    setAgreeActive(event.target.checked);
  };

  const handleParticipantChange = (e) => {
    setParticipant(e.target.value);
  };

  const handleEmailAddressChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const handleParentEmailAddressChange = (e) => {
    setParentEmailAddress(e.target.value);
  };

  const handleCellPhoneNumberChange = (e) => {
    setCellPhoneNumber(e.target.value);
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };
  const handleBusinessPhoneNumberChange = (e) => {
    setBusinessPhoneNumber(e.target.value);
  };

  const handleHomePhoneNumberChange = (e) => {
    setHomePhoneNumber(e.target.value);
  };

  const handleExtChange = (e) => {
    setExt(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleRegisteredTeamChange = (e) => {
    setRegisteredTeam(e.target.value);
  };


  const handleCityOfRegistrationChange = (e) => {
    setCityOfRegistration(e.target.value);
    const city = e.target.value;
    const matchingTeams = teamslist.filter(
      (team) => team.location === city && team.agegroup === agegroup
    );

    console.log("matchingTeams", city, matchingTeams);
    // If there are matching teams, add them to updatedTeams
    if (matchingTeams.length > 0) {
      setUpdatedTeams([...matchingTeams]);
    } else {
      setUpdatedTeams([]);
    }

  };
  const handleEventForChange = (e) => {
    setEventSignUpFor(e.target.value);
  };

  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);

    // Implement file upload logic for the resume
  };

  const handleDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleIsParentGuardianChange = (e) => {
    console.log('IsChecked', e.target.value);
    setIsParentGuardian(e.target.checked);
  };

  const handleGradeChange = (e) => {
    setGrade(e.target.value);
  };

  const handleIsFirstYearPlayingChange = (e) => {
    setIsFirstYearPlaying(e.target.value === 'true');
  };

  const handleJerseySizeChange = (e) => {
    setJerseySize(e.target.value);
  };

  const handleSchoolNameChange = (e) => {
    setSchoolName(e.target.value);
  };

  const handleIsMemberOfTeamOrClubChange = (e) => {
    setIsMemberOfTeamOrClub(e.target.value === 'true');
  };
  const handleIsVerifiedChange = (e) => {
    setIsVerified(e.target.value);
  };
  const handleClubNameChange = (e) => {
    setClubName(e.target.value);
  };

  const handleSecondaryEmergencyContactChange = (e) => {
    setSecondaryEmergencyContact(e.target.value);
  };

  const handleHowDidYouHearChange = (e) => {
    setHowDidYouHear(e.target.value);
  };

  const handleHealthConditionsChange = (e) => {
    const selectedValue = e.target.value === 'true'; // Convert string to boolean
    setHealthConditions(selectedValue);
    setShowMessage(selectedValue);
  };

  const handleSignatureTypeChange = (e) => {
    setSignatureType(e.target.value);
  };

  const handleElectronicSignatureChange = (e) => {
    setElectronicSignature(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement form submission logic here
  };
  console.log('Verify League', LeaguesPlayerDetails);
  const buttonStyles = {
    backgroundColor: '#1E90FF',
    color: 'white',
  };
  useEffect(() => {
    const matchingTeams = teamslist.filter(
      (team) => team.name === LeaguesPlayerDetails?.teamName
    );
    console.log("ageGroup", teamslist, matchingTeams);
    // If there are matching teams, add them to updatedTeams
    if (matchingTeams.length > 0) {
      setUpdatedTeams([...matchingTeams]);
    } else {
      setUpdatedTeams([]);
    }
    setParticipant(LeaguesPlayerDetails?.participantName || '');
    setIsVerified(LeaguesPlayerDetails?.isVerified);
    setEmailAddress(LeaguesPlayerDetails?.email || '');
    setParentEmailAddress(LeaguesPlayerDetails?.parentEmailAddress || '');
    setDateOfBirth(LeaguesPlayerDetails?.dateOfBirth || '');
    setRegisteredTeam(LeaguesPlayerDetails?.teamName || '');
    setCityOfRegistration(LeaguesPlayerDetails?.cityOfRegistration || '');
    // setEventSignUpFor(LeaguesPlayerDetails?.league || '');
    setFirstName(LeaguesPlayerDetails?.parentFirstName || '');
    setLastName(LeaguesPlayerDetails?.parentLastName || '');
    setHomePhoneNumber(LeaguesPlayerDetails?.parentHomePhoneNumber || '');
    setCellPhoneNumber(LeaguesPlayerDetails?.parentPhoneNumber || '');
    setSecondaryEmergencyContact(LeaguesPlayerDetails?.secondaryPersonName || '');
    setPrimaryEmergencyContact(LeaguesPlayerDetails?.primaryPersonName || '');
    setMessage(LeaguesPlayerDetails?.message || '');
    setShowMessage(!!LeaguesPlayerDetails?.healthConditions);
    setPrimaryEmergencyContactEmail(LeaguesPlayerDetails?.primaryEmergencyContactEmail || '');
    setPrimaryCallPhoneNumber(LeaguesPlayerDetails?.primaryCallPhoneNumber || '');
    setSecondaryEmergencyContactEmail(LeaguesPlayerDetails?.secondaryEmergencyContactEmail || '');
    setSecondaryCallPhoneNumber(LeaguesPlayerDetails?.secondaryCallPhoneNumber || '');
    setIsFirstYearPlaying(LeaguesPlayerDetails?.isFirstYearPlaying || false);
    setHealthConditions(LeaguesPlayerDetails?.healthConditions || false);
    setBusinessPhoneNumber(LeaguesPlayerDetails?.parentBusinessPhoneNumber || '');
    setSchoolName(LeaguesPlayerDetails?.schoolName || '');
    setHeight(LeaguesPlayerDetails?.HEIGHT || '');
    setWeight(LeaguesPlayerDetails?.WEIGHT || '');
    setYearsHS(LeaguesPlayerDetails?.H_S || '');
    setYearsNBA(LeaguesPlayerDetails?.NBA || '');
    setYearsCollege(LeaguesPlayerDetails?.COLLEGE || '');
    setIsParentGuardian(LeaguesPlayerDetails?.isAdult || false);
    setGrade(LeaguesPlayerDetails?.grade || '');
    setJerseySize(LeaguesPlayerDetails?.jerseySize || '');
    setIsMemberOfTeamOrClub(LeaguesPlayerDetails?.isMemberOfOtherTeam || false);
    setClubName(LeaguesPlayerDetails?.clubName || '');
    setExt(LeaguesPlayerDetails?.ext || '');
    setHowDidYouHear(LeaguesPlayerDetails?.heardAboutLeague || '');
    // setNewImage(LeaguesPlayerDetails?.profile||"")
    setCountry(LeaguesPlayerDetails?.parentCountry || '');
    setElectronicSignature(LeaguesPlayerDetails?.signature || '');
    setPhoneNumber(LeaguesPlayerDetails?.phoneNumber || '');
    setAgegroup(LeaguesPlayerDetails?.agegroup || '');
  }, [LeaguesPlayerDetails]);

  const updateLeague = async () => {
    let image = profile;
    if (newImage) {
      const isDeleted = await deleteFile(LeaguesPlayerDetails?.profile);
      if (isDeleted) {
        image = await uploadFile(newImage);
      }
    }
    const teamName = registeredTeam || LeaguesPlayerDetails?.teamName;
    const isTeam = teamslist?.filter((team) => team.name === teamName);
    const teamID = isTeam[0]?.id;
    const data = {
      id: LeaguesPlayerDetails?.id,
      participantName: participant || LeaguesPlayerDetails?.participantName,
      email: emailAddress || LeaguesPlayerDetails?.email,
      HEIGHT: height || LeaguesPlayerDetails?.HEIGHT,
      H_S: HS || LeaguesPlayerDetails?.H_S,
      NBA: NBA || LeaguesPlayerDetails?.NBA,
      WEIGHT: weight || LeaguesPlayerDetails?.WEIGHT,
      cityOfRegistration: cityOfRegistration || LeaguesPlayerDetails?.cityOfRegistration,
      dateOfBirth: dateOfBirth || LeaguesPlayerDetails?.dateOfBirth,
      document: document || LeaguesPlayerDetails?.document,
      grade: grade || LeaguesPlayerDetails?.grade,
      healthConditions,
      heardAboutLeague: howDidYouHear || LeaguesPlayerDetails?.heardAboutLeague,
      isAdult: isParentGuardian,
      isMemberOfOtherTeam: isMemberOfTeamOrClub,
      isVerified,
      isFirstYearPlaying,
      teamID,
      jerseySize: jerseySize || LeaguesPlayerDetails?.jerseySize,
      // league: eventSignUpFor || LeaguesPlayerDetails?.league,
      parentBusinessPhoneNumber: businessPhoneNumber || LeaguesPlayerDetails?.parentBusinessPhoneNumber,
      parentCountry: country || LeaguesPlayerDetails?.parentCountry,
      parentEmailAddress: parentEmailAddress || LeaguesPlayerDetails?.parentEmailAddress,
      parentFirstName: firstName || LeaguesPlayerDetails?.parentFirstName,
      parentHomePhoneNumber: cellPhoneNumber || LeaguesPlayerDetails?.parentHomePhoneNumber,
      parentLastName: lastName || LeaguesPlayerDetails?.parentLastName,
      parentPhoneNumber: homePhoneNumber || LeaguesPlayerDetails?.parentPhoneNumber,
      profile: image || LeaguesPlayerDetails?.profile,
      teamName: registeredTeam || LeaguesPlayerDetails?.teamName,
      schoolName: schoolName || LeaguesPlayerDetails?.schoolName,
      message: message || LeaguesPlayerDetails?.message,
      primaryPersonName: primaryEmergencyContact || LeaguesPlayerDetails?.primaryPersonName,
      secondaryPersonName: secondaryEmergencyContact || LeaguesPlayerDetails?.secondaryPersonName,
      primaryCallPhoneNumber: primaryCallPhoneNumber || LeaguesPlayerDetails?.primaryCallPhoneNumber,
      secondaryCallPhoneNumber: secondaryCallPhoneNumber || LeaguesPlayerDetails?.secondaryCallPhoneNumber,
      primaryEmergencyContactEmail: primaryEmergencyContactEmail || LeaguesPlayerDetails?.primaryEmergencyContactEmail,
      secondaryEmergencyContactEmail:
        secondaryEmergencyContactEmail || LeaguesPlayerDetails?.secondaryEmergencyContactEmail,
      signature: electronicSignature || LeaguesPlayerDetails?.signature,
      phoneNumber: phoneNumber || LeaguesPlayerDetails?.phoneNumber,
      agegroup: agegroup || LeaguesPlayerDetails?.agegroup,
    };
    await updatePlayerById(data);
  };

  if (loading || updateLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Container>
      <form onSubmit={handleFormSubmit}>
        <Grid item xs={12} sm={6} style={{ marginLeft: 1, marginTop: 5 }}>
          <div className="text-3xl font-bold">Edit LEAGUE PLAYER</div>
        </Grid>

        <Grid container style={{ marginLeft: 1, marginTop: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="participant"
              variant="outlined"
              label="Participant"
              fullWidth
              value={participant}
              onChange={handleParticipantChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              variant="outlined"
              label="Email Address"
              fullWidth
              value={emailAddress}
              onChange={handleEmailAddressChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Phone Number"
              fullWidth
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="city_of_registration">City Of Registration</InputLabel>

              <Select
                name="city_of_registration"
                variant="outlined"
                label="City Of Registration"
                fullWidth
                value={cityOfRegistration}
                onChange={handleCityOfRegistrationChange}
              >
                {cities?.map((value, index) => (
                  <MenuItem value={value.name} key={index}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="agegroup">Age Group</InputLabel>
              <Select
                name="agegroup"
                variant="outlined"
                label="Age Group"
                fullWidth
                value={agegroup}
                onChange={handleAgeGroup}
              >
                <MenuItem value="">Select Age Group</MenuItem>
                {ages?.map((data, index) => (
                  <MenuItem value={data.value}>{data.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="registered_team">Registered Team Name</InputLabel>
              <Select
                name="registered_team"
                variant="outlined"
                label="Registered Team Name"
                fullWidth
                value={registeredTeam}
                onChange={handleRegisteredTeamChange}
              >
                <MenuItem value="">Select Registered Team</MenuItem>
                {updatedTeams?.map((value, index) => (
                  <MenuItem value={value.name}>{value.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={LeaguesPlayerDetails?.profileLink}
              className="w-[100px] h-[100px] rounded-full overflow-hidden"
              alt={LeaguesPlayerDetails?.participantName}
            />
            <InputLabel id="profile">Upload Profile Picture</InputLabel>

            <input type="file" accept="image/*" name="profile" onChange={handleProfileChange} />
          </Grid>
         
          {/* <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="event_signing_up_for">Event Signing Up For</InputLabel>

              <Select
                name="event_signing_up_for"
                variant="outlined"
                label="Event Signing Up For"
                fullWidth
                value={eventSignUpFor}
                onChange={handleEventForChange}
              >
                <MenuItem value={eventSignUpFor}>{eventSignUpFor}</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Height"
              name="height"
              type="text"
              variant="outlined"
              fullWidth
              value={height}
              onChange={handleHeightChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Weight"
              name="weight"
              type="text"
              variant="outlined"
              fullWidth
              value={weight}
              onChange={handleWeightChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Years Played in HS"
              name="years_high_school"
              type="text"
              pattern="\d*"
              variant="outlined"
              fullWidth
              value={yearsHS}
              onChange={handleYearsHSChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Years played as pro"
              name="years_nba"
              type="text"
              pattern="\d*"
              variant="outlined"
              fullWidth
              value={yearsNBA}
              onChange={handleYearsNBAChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Years Played in College"
              name="years_college"
              type="text"
              pattern="\d*"
              variant="outlined"
              fullWidth
              value={yearsCollege}
              onChange={handleYearsCollegeChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="date"
              label="Date of Birth"
              variant="outlined"
              value={dateOfBirth}
              onChange={handleDateOfBirthChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox checked={isParentGuardian} onChange={handleIsParentGuardianChange} />}
              label={
                <Typography variant="body1">
                  You are this person's parent or legal guardian. You must be the parent or legal guardian to register
                  someone under 18 years old. as of 2021-2022 school year
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="grade">Grade</InputLabel>

              <Select fullWidth label="Grade" variant="outlined" value={grade} onChange={handleGradeChange}>
                <MenuItem value="5th">5th</MenuItem>
                <MenuItem value="7th">7th</MenuItem>
                <MenuItem value="10th">12th</MenuItem>
                <MenuItem value={'12th'}>12th</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="radio-group">
              Is 2021 your child's first year playing in the Hoop it Up League?
            </InputLabel>
            <RadioGroup
              id="radio-group"
              aria-label="Is 2021 your child's first year playing in the Hoop it Up League?"
              value={isFirstYearPlaying ? 'true' : 'false'}
              onChange={handleIsFirstYearPlayingChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="jersey-size">Jersey Size</InputLabel>
              <Select
                fullWidth
                labelId="jersey-size"
                label="Jersey Size"
                variant="outlined"
                value={jerseySize}
                onChange={handleJerseySizeChange}
              >
                <MenuItem value={jerseySize}>{jerseySize}</MenuItem>
                <MenuItem value="YS">YS</MenuItem>
                <MenuItem value="YM">YM</MenuItem>
                <MenuItem value="YL">YL</MenuItem>
                <MenuItem value="S">M</MenuItem>
                <MenuItem value="L">L</MenuItem>
                <MenuItem value="XL">XL</MenuItem>
                <MenuItem value="XXL">XXL</MenuItem>
                <MenuItem value="XXL">XXL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="School Name"
              variant="outlined"
              value={schoolName}
              onChange={handleSchoolNameChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="radio-group">Is the participant currently a member of a team or club?</InputLabel>
            <RadioGroup
              aria-label="Is the participant currently a member of a team or club?"
              value={isMemberOfTeamOrClub ? 'true' : 'false'}
              onChange={handleIsMemberOfTeamOrClubChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Club Name"
              variant="outlined"
              value={clubName}
              onChange={handleClubNameChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="how-did-you-hear-label">Primary Emergency Contact</InputLabel>

              <Select
                fullWidth
                label="Primary Emergency Contact"
                variant="outlined"
                value={primaryEmergencyContact}
                onChange={handlePrimaryEmergencyContactChange}
              >
                {[
                  { label: 'Father', value: 'Father' },
                  { label: 'Mother', value: 'Mother' },
                  { label: 'Son', value: 'Son' },
                  { label: 'Daughter', value: 'Daughter' },
                  { label: 'Brother', value: 'Brother' },
                  { label: 'Spouce', value: 'Spouce' },
                ].map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number for Emergency Contact"
              variant="outlined"
              value={primaryCallPhoneNumber}
              onChange={handlePrimaryCallPhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="primaryEmail"
              variant="outlined"
              label="Primary Email Address"
              fullWidth
              value={primaryEmergencyContactEmail}
              onChange={handlePrimaryEmergencyContactEmailChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="how-did-you-hear-label">Secondary Emergency Contact</InputLabel>

              <Select
                fullWidth
                label="Secondary Emergency Contact"
                variant="outlined"
                value={secondaryEmergencyContact}
                onChange={handleSecondaryEmergencyContactChange}
              >
                {[
                  { label: 'Father', value: 'Father' },
                  { label: 'Mother', value: 'Mother' },
                  { label: 'Son', value: 'Son' },
                  { label: 'Daughter', value: 'Daughter' },
                  { label: 'Brother', value: 'Brother' },
                  { label: 'Spouce', value: 'Spouce' },
                ].map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number for Emergency Contact"
              variant="outlined"
              value={secondaryCallPhoneNumber}
              onChange={handleSecondaryCallPhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="secondaryEmail"
              variant="outlined"
              label="Secondary Email Address"
              fullWidth
              value={secondaryEmergencyContactEmail}
              onChange={handleSecondaryEmergencyContactEmailChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="how-did-you-hear-label">How did you hear about us?</InputLabel>

              <Select
                fullWidth
                label="How did you hear about us?"
                variant="outlined"
                value={howDidYouHear}
                onChange={handleHowDidYouHearChange}
              >
                {howDidYouHearOptions.map((option, index) => (
                  <MenuItem value={option.value} key={index}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="radio-group">Are there any health conditions?</InputLabel>
            <RadioGroup
              aria-label="Are there any health conditions?"
              value={healthConditions ? 'true' : 'false'}
              onChange={handleHealthConditionsChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          {showMessage && (
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Explain"
                value={message}
                onChange={handleMessageChange}
                variant="outlined"
                multiline
                fullWidth
                rows={4}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="First Name"
              fullWidth
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Last Name"
              fullWidth
              value={lastName}
              onChange={handleLastNameChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="parentEmail"
              variant="outlined"
              label="Parent Email Address"
              fullWidth
              value={parentEmailAddress}
              onChange={handleParentEmailAddressChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Cell Phone Number"
              fullWidth
              value={cellPhoneNumber}
              onChange={handleCellPhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Home Phone Number"
              fullWidth
              value={homePhoneNumber}
              onChange={handleHomePhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Business Phone Number"
              fullWidth
              value={businessPhoneNumber}
              onChange={handleBusinessPhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="text" variant="outlined" label="Ext" fullWidth value={ext} onChange={handleExtChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="country">Country</InputLabel>
              <Select fullWidth label="Country" variant="outlined" value={country} onChange={handleCountryChange}>
                {countryList?.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="radio-group">Verified?</InputLabel>
            <RadioGroup aria-label="Verified?" value={isVerified ? 'true' : 'false'} onChange={handleIsVerifiedChange}>
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <InputLabel>Digital Signature</InputLabel>
            <RadioGroup
              aria-label="Signature Type"
              name="signatureType"
              value={signatureType}
              onChange={handleSignatureTypeChange}
            >
              <FormControlLabel value="write" control={<Radio />} label="I will sign the registration form by hand." />
              <FormControlLabel
                value="type"
                control={<Radio />}
                label="I will type my name as my electronic signature."
              />
            </RadioGroup>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Signature"
              variant="outlined"
              value={electronicSignature}
              onChange={handleElectronicSignatureChange}
            />
          </Grid>
          {signatureType === 'type' && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Electronic Signature"
                variant="outlined"
                value={electronicSignature}
                onChange={handleElectronicSignatureChange}
              />
            </Grid>
          )}
          {/* <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" className="font-bold text-xl mt-4 mb-2">
                Waivers and Agreements
              </Typography>
              <Typography>
                Please read the following waivers and agreements carefully. They include release of liability and waiver
                of legal rights, and deprive you of the ability to sue certain parties. By agreeing electronically, you
                acknowledge that you have both read and understood all text presented to you as part of the registration
                process.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox required checked={agreeRelease} onChange={handleAgreeReleaseChange} color="primary" />
                  }
                  label={
                    <Typography>
                      I agree to the <strong>RELEASE OF LIABILITY AND CANCELLATION POLICY</strong>
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox required checked={agreeActive} onChange={handleAgreeActiveChange} color="primary" />
                  }
                  label={
                    <Typography>
                      I agree to the <strong>Active Agreement and Waiver</strong>
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
          </Grid> */}
        </Grid>
        <Box mt={2} ml={5}>
          <Button variant="contained" onClick={updateLeague} style={buttonStyles} type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default League;

const howDidYouHearOptions = [
  { value: 'friend', label: 'From a Friend' },
  { value: 'socialMedia', label: 'Social Media' },
  { value: 'website', label: 'Website' },
  { value: 'event', label: 'Event or Workshop' },
  { value: 'other', label: 'Other' },
];
const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];
