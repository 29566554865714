import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter, update } from 'lodash';
import { sentenceCase } from 'change-case';
import { ToastContainer } from 'react-toastify';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';

// React Redux, Recoil, React Router
import { useNavigate } from 'react-router-dom';
import useHandleTournamentUser from '../hooks/hanldeTournamentUser';

// Custom hooks
import Loader from '../components/Loader';
import useHandleCampUser from '../hooks/hanldeCampUser';
import useHandleClinicUser from '../hooks/handleClinic';
import useHandleTrainingProgramUser from '../hooks/hanldeTrainingProgram';
import useHandlePagintationPlayers from '../hooks/handlePaginationPlayer';

// Redux actions

// Components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar, AddNewUserSideBar } from '../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'zone', label: 'Zone', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'enabled', label: 'Enabled', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  console.log('array', array);

  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

const People = () => {
  const { deleteCampUserById, campList, fetchCampUsers, loading } = useHandleCampUser();
  const { deletePlayers, playerList, fetchPlayer, loading:playerLoading } = useHandlePagintationPlayers();
  const {
    deleteTournamentUserById,
    fetchTournamentUsers,
    tournamentList,
    loading: loadingTournament,
  } = useHandleTournamentUser();
  const {
    fetchTrainingProgramUsers,
    deleteTrainingProgramUserById,
    trainingProgramList,
    loading: loadingTrainingProgram,
  } = useHandleTrainingProgramUser();
  const { fetchClinicUsers, clinicList, deleteClinicUserById, loading: loadingClinic } = useHandleClinicUser();

  const [updating, setUpdating] = useState(false);
  const [formVisible, setFormVisible] = useState(false); // To control form visibility
  const [selectedPeople, setSelectedPeople] = useState('');
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedRowId, setSelectedRowId] = useState(null); // Add a state to store the selected row's id
  const [selectedZone, setSelectedZone] = useState(''); // Add a state to store the selected row's id

  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const handleDeleteUser = async () => {
    setUpdating(true);
    if (selectedZone === 'Clinic') {
      try {
        const deleted = await deleteClinicUserById(selectedRowId);
        setAllUsers(prevList => prevList.filter(item => item.id !== selectedRowId));

      } catch (error) {
        console.log('error', error);
        setUpdating(false);
      }
    } else if (selectedZone === 'Camp') {
      // const response = await deleteUser(selectedRowId);
      try {
        const deleted = await deleteCampUserById(selectedRowId);
        setAllUsers(prevList => prevList.filter(item => item.id !== selectedRowId));

      } catch (error) {
        console.log('error', error);
        setUpdating(false);
      }
    } else if (selectedZone === 'Tournament') {
      try {
        const deleted = await deleteTournamentUserById(selectedRowId);
        setAllUsers(prevList => prevList.filter(item => item.id !== selectedRowId));

      } catch (error) {
        console.log('error', error);
        setUpdating(false);
      }
    } else if (selectedZone === 'Training Program') {
      try {
        const deleted = await deleteTrainingProgramUserById(selectedRowId);
        setAllUsers(prevList => prevList.filter(item => item.id !== selectedRowId));

      } catch (error) {
        console.log('error', error);
        setUpdating(false);
      }
    } else if (selectedZone === 'Player') {
      try {
        const deleted = await deletePlayers(selectedRowId);
        setAllUsers(prevList => prevList.filter(item => item.id !== selectedRowId));

      } catch (error) {
        console.log('error', error);
        setUpdating(false);
      }
    }
    // listAllUsers();

    // Implement suspend logic here
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleOpenUser = () => {
    setOpenUser(true);
  };
  const handleCloseUser = () => {
    setOpenUser(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
 

  const handleOpenMenu = (event, id, enabled, zone) => {
    // Pass the id when opening the menu
    setSelectedRowId(id);
    setSelectedZone(zone);
    setEnabled(enabled);
    // Store the selected row's id
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allUsers?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleEditNavigate = () => {
    if (selectedRowId && selectedZone && selectedZone === 'Player') {
      navigate(`/player/${selectedRowId}`); // Use the selected row's id
    }else if (selectedRowId && selectedZone && selectedZone === 'Camp') {
      navigate(`/camp/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Clinic') {
      navigate(`/clinic/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Tournament') {
      navigate(`/tournament/${selectedRowId}`); // Use the selected row's id
    } else if (selectedRowId && selectedZone && selectedZone === 'Training Program') {
      navigate(`/training-program/${selectedRowId}`); // Use the selected row's id
    }
    handleCloseMenu(); // Close the menu
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allUsers.length) : 0;

  const filteredUsers = applySortFilter(allUsers, getComparator(order, orderBy), filterName);

  const isNotFound = filteredUsers && !filteredUsers.length && !!filterName;


  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };


  console.log('Camp', campList);

  const handleSelect = async (event) => {
    // Fetch player details based on the selected player
    // You can implement this logic using an API call.
    // For now, let's assume the details are fetched successfully.
    setSelectedPeople(event.target.value);
    if (selectedPeople === 'Player') {
      console.log('fetch player entered')
      const data = await fetchPlayer();
      console.log('Player', data);
      setAllUsers(playerList);
    }else if (selectedPeople === 'Camp') {
      const data = await fetchCampUsers();

      console.log('Camp', data);
      setAllUsers(campList);
    } else if (selectedPeople === 'Cliic') {
      const data = await fetchClinicUsers();
      setAllUsers(clinicList);
    } else if (selectedPeople === 'Tournament') {
      const data = await fetchTournamentUsers();
      setAllUsers(tournamentList);
    } else if (selectedPeople === 'Training Program') {
      const data = await fetchTrainingProgramUsers();

      setAllUsers(trainingProgramList);
    }
  };

  const handleSubmit = async () => {
    // listAllUsers()
    console.log('selectedPeople',selectedPeople)
    try {
      if (selectedPeople === 'Player') {
        console.log('PlayerUsers', playerList);
        setAllUsers(playerList);
      }else if (selectedPeople === 'Camp') {
        console.log('campUsers', campList);
        setAllUsers(campList);
      }
      if (selectedPeople === 'Clinic') {
        setAllUsers(clinicList);
      }
      if (selectedPeople === 'Tournament') {
        setAllUsers(tournamentList);
      }
      if (selectedPeople === 'TrainingProgram') {
        setAllUsers(trainingProgramList);
      }

      setFormVisible(true); // Show the form after selecting a player
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  if (loading || loadingClinic || loadingTrainingProgram || loadingTournament || playerLoading) {
    return <Loader />;
  }
  return (
    <Container >
      <Typography variant="h4" gutterBottom>
        User Info
      </Typography>
      <Grid   container spacing={2} mb={5}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <InputLabel id="type">Select Type</InputLabel>
            <Select
              labelId="type"
              label="Select Type"
              name="type"
              value={selectedPeople}
              fullWidth
              variant="outlined"
              onChange={handleSelect}
            >
              <MenuItem value="Player">Player</MenuItem>
              <MenuItem value="Camp">Camp</MenuItem>
              <MenuItem value="Clinic">Clinic</MenuItem>
              <MenuItem value="Tournament">Tournament</MenuItem>
              <MenuItem value="TrainingProgram">Training Program</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          <Button style={buttonStyles} variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
      {formVisible && (
        <Grid container spacing={2}  >
          <ToastContainer />
          <Container sx={{paddingLeft:"25px",paddingRight:"25px"}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                User
              </Typography>
            </Stack>

            <Card>
              <UserListToolbar
                numSelected={selected?.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={allUsers?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                        const { id, name, zone, status, enabled } = row;
                        const selectedUser = selected.indexOf(name) !== -1;
                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      

                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={name} src="src" />
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">{zone}</TableCell>

                            <TableCell align="left">
                              <Label color={(status === 'banned' && 'error') || 'success'}>
                                {sentenceCase(status)}
                              </Label>
                            </TableCell>
                            <TableCell align="left">
                              <Label color={(enabled === false && 'error') || 'success'}>
                                {enabled ? 'YES' : 'NO'}
                              </Label>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(event, id, enabled, zone)}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allUsers ? allUsers.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 180,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem onClick={() => handleEditNavigate()}>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Edit
            </MenuItem>
            <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteUser}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
        </Grid>
      )}
    </Container>
  );
};

export default People;
