import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  Avatar
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from 'src/components/iconify';


const Details = ({ label, data, addDetails, deleteThis }) => {
  const dispatch = useDispatch();
  const [id, setId] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState(data); // Use a separate state for the table data
  const [rowsPerPage, setRowsPerPage] = useState(5);

  console.log("data",data)

  const handleDeleteClick = (id) => {
    setIsDeleteDialogOpen(true);
    setId(id);
    setAnchorEl(null);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleteDialogOpen(false);

    await deleteThis(id)

  }

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5" fontWeight="bold">
          {label}
        </Typography>
        <Button
          disableRipple
          color="inherit"
          variant="contained"
          startIcon={<Iconify icon="gala:add" />}
          onClick={addDetails}
          style={buttonStyles}
        >
          Add {label}
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>

            {label === 'Category' || label === 'City' ? (
              <TableCell>Name</TableCell>
            ) : label === 'Comptetive 5X5 Coach' ? (
              <>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
              </>
            ) : (
              <>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                {label === 'TournamentTeams' ? (
                  <>
                    <TableCell>Age</TableCell>
                    <TableCell>Event</TableCell>
                  </>
                ) : (
                  label === 'Teams' && <TableCell>City</TableCell>
                )}
              </>
            )}

              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value) => (
              <TableRow key={value.id}>
              {label === 'Category' || label === 'City' ? (
                  <TableCell>{value?.title || value?.name}</TableCell>
                ) : label === 'Comptetive 5X5 Coach' ? (
                  <>
                    <TableCell>{value?.name}</TableCell>
                    <TableCell>{value?.email}</TableCell>
                    <TableCell>{value?.phoneNumber}</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>
                      <Avatar src={value?.img_url} alt={value?.name} variant="rounded" size="medium" />
                    </TableCell>
                    <TableCell>{value?.name}</TableCell>
                    {label === 'TournamentTeams' && (
                      <>
                        <TableCell>{value?.agegroup}</TableCell>
                        <TableCell>{value?.event}</TableCell>
                      </>
                    )}
                    {label === 'Teams' && (
                      <TableCell>{value?.location}</TableCell>
                    )}
                  </>
                )}

                <TableCell align="right">
                  <IconButton onClick={(event) => handleMenuOpen(event, value.id)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && id === value.id}
                    onClose={handleMenuClose}
                  >

                    <MenuItem onClick={() => handleDeleteClick(value.id)}>
                      <DeleteIcon /> Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Delete {label}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this {label}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Details;
