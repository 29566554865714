import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
// Import your GraphQL queries
import 'react-toastify/dist/ReactToastify.css';

import {
  REGISTER_PLAYER_REQUEST,
  REGISTER_PLAYER_SUCCESS,
  REGISTER_PLAYER_FAIL,
  UPDATE_PLAYER_REQUEST,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_PLAYER_FAIL,
  GET_PLAYER_REQUEST,
  GET_PLAYER_SUCCESS,
  GET_PLAYER_FAIL,
  LOAD_PLAYERS_REQUEST,
  LOAD_PLAYERS_SUCCESS,
  LOAD_PLAYERS_FAIL,
  DELETE_PLAYER_REQUEST,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_FAIL,
  DELETE_PLAYER_RESET,
  CLEAR_PLAYER_ERRORS,
} from '../constants/Player5x5Constants';
import getLinks from '../../utils/getLinks';
import uploadFile from '../../utils/uploadFile';
import deleteFromStorage from '../../utils/deleteDataFromS3';

export const getAllPlayer = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_PLAYERS_REQUEST });

    const response = await API.graphql({
      query: queries.listPlayer5x5s,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const players = response?.data.listPlayer5x5s.items;
    const verifiedPlayers = players?.filter((player) => player.isVerified === true);
    const nonVerifiedPlayers = players?.filter((player) => player.isVerified !== true);

    console.log('players: ', players);
    console.log('nonVerifiedPlayers: ', nonVerifiedPlayers);
    console.log('verifiedPlayers: ', verifiedPlayers);

    dispatch({
      type: LOAD_PLAYERS_SUCCESS,
      payload: { players, verifiedPlayers, nonVerifiedPlayers },
    });
  } catch (error) {
    dispatch({
      type: LOAD_PLAYERS_FAIL,
      payload: error.message || 'Failed to fetch player data',
    });
  }
};

export const getPlayerDetails = (playerId) => async (dispatch) => {
  try {
    dispatch({ type: GET_PLAYER_REQUEST });

    const playerResponse = await API.graphql({
      query: queries.getPlayer5x5,
      variables: {
        id: playerId,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const playerData = playerResponse?.data?.getPlayer5x5;

    const signedUrl = await getLinks(playerData?.profile);
    const document = await getLinks(playerData?.document);

    const updatedData = {
      ...playerData,
      img_url: signedUrl,
      profileLink: signedUrl,
      documentLink: document,
    };

    const data = {
      playerDetails: updatedData,
    };

    dispatch({
      type: GET_PLAYER_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch player details';

    dispatch({
      type: GET_PLAYER_FAIL,
      payload: errorMessage,
    });
  }
};

export const deletePlayerByUserId = (userId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PLAYER_REQUEST });

    const player = await API.graphql({
      query: queries.listPlayer5x5s,
      variables: {
        filter: {
          userId: {
            eq: userId,
          },
        }, // Fix: Use playerId here
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const playerId = player?.data?.listPlayer5x5s?.items[0].id;
    console.log('Player', playerId);
    const playerData = player?.data?.listPlayer5x5s?.items[0];

    const isDeleted = await deleteFromStorage(playerData.profile);
    const isDeletedDoc = await deleteFromStorage(playerData?.document);

    // if (isDeleted && isDeletedDoc) {
    const response = await API.graphql({
      query: mutations.deletePlayer5x5,
      variables: {
        input: { id: playerId }, // Fix: Use playerId here
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    console.log(response);
    toast.success('Player Deleted');
    dispatch({
      type: DELETE_PLAYER_SUCCESS,
      payload: { deleted: true },
    });
    // }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch player details';

    toast.error(errorMessage);

    dispatch({
      type: DELETE_PLAYER_FAIL,
      payload: errorMessage,
    });
  }
};

export const deletePlayer = (playerId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PLAYER_REQUEST });

    const res = await API.graphql(graphqlOperation(queries.getPlayer5x5, { id: playerId }), {
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const isDeleted = await deleteFromStorage(res?.data?.getPlayer5x5?.profile);
    const isDeletedDoc = await deleteFromStorage(res?.data?.deletePlayer5x5?.document);

    // if (isDeleted && isDeletedDoc) {
    const response = await API.graphql({
      query: mutations.deletePlayer5x5,
      variables: {
        input: { id: playerId }, // Fix: Use playerId here
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    console.log(response);
    toast.success('Player Deleted');
    dispatch({
      type: DELETE_PLAYER_SUCCESS,
      payload: { deleted: true },
    });
    // }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch player details';

    dispatch({
      type: DELETE_PLAYER_FAIL,
      payload: errorMessage,
    });
  }
};

export const verifyPlayer = (playerId) => async (dispatch) => {
  console.log('verifyPlayer', playerId);
  try {
    dispatch({ type: UPDATE_PLAYER_REQUEST });
    const playerDetails = await API.graphql({
      query: queries.getPlayer5x5,
      variables: {
        id: playerId,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    // const playerD=playerDetails.data?.getPlayer5x5;
    const playerD = { ...playerDetails.data?.getPlayer5x5 };
    delete playerD.__typename;
    delete playerD.updatedAt;
    delete playerD.createdAt;

    const response = await API.graphql({
      query: mutations.updatePlayer5x5,
      variables: {
        input: {
          ...playerD,
          id: playerId,
          isVerified: true,
          // Add other fields here...
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    console.log(response);
    toast.success('Player Verified');
    dispatch({
      type: UPDATE_PLAYER_SUCCESS,
      payload: { updated: true },
    });
    // }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch player details';

    dispatch({
      type: UPDATE_PLAYER_FAIL,
      payload: errorMessage,
    });
  }
};

// add a new product
export const registerAsPlayer = (data, userId) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_PLAYER_REQUEST });
    const profileImage = await uploadFile(data.profile);
    const document = await uploadFile(data.document);

    const response = await API.graphql({
      query: mutations.createPlayer5x5,

      variables: {
        input: {
          userId,
          ...data,
          profile: profileImage,
          document,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    // const config = { headers: { "Content-Type": "multipart/form-data" } };

    dispatch({
      type: REGISTER_PLAYER_SUCCESS,
      payload: response?.data?.createPlayer5x5,
    });
    toast.success('registration successful');
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: REGISTER_PLAYER_FAIL,
      payload: errorMessage,
    });
  }
};

export const clearPlayerErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PLAYER_ERRORS });
};
