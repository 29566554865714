import { useEffect, React, useState } from 'react';
// @mui
import { Helmet } from 'react-helmet-async';

import { Container, Stack, Button, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useHandleTutor from '../hooks/handleTutor';
import { AddNewTutor, TutorList } from '../sections/@dashboard/tutor';
import Loader from '../components/Loader';

const Tutor = () => {
  const {
    tutorData,
    tutorList,
    createTutor,
    updateTutor,
    deleteTutor,
    getAllTutors,
    isLoading,
    nextNextToken,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
    error } = useHandleTutor();

  const [tutor, setTutor] = useState(tutorList);
  const [openTutor, setOpenTutor] = useState(false);
  console.log('tutorData: ', tutorList);

  const handleOpenModal = () => {
    setOpenTutor(true);
  };
  const handleCloseModal = () => {
    setOpenTutor(false);
  };
  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Tutor</title>
      </Helmet>

      <Container>
        <ToastContainer />
        <Typography variant="h4" sx={{ mb: 5 }}>
          Tutors
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>

          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <AddNewTutor
              isOpen={openTutor}
              onClose={handleCloseModal}
              onOpen={handleOpenModal}
              addNewData={createTutor}

            />
          </Stack>
        </Stack>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          {
            tutorList && tutorList.length > 0 &&

            <TutorList
              deleteData={deleteTutor}
              dataList={tutorList}
              updateData={updateTutor}
              setData={setTutor}
              fetchData={getAllTutors}
            />
          }
        
        </Stack>
        {!isLoading && (
            <Stack
              direction="row"
              maxWidth={500}
              marginTop={6}
              marginRight="auto"
              marginLeft="auto"
              justifyContent="center"
              gap={5}
              alignItems="center"
              mb={2}
            >
              {fetchPreviousPage && previousTokens?.length > 0 && (
                <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                  Previous Page
                </Button>
              )}
              {nextNextToken !== null && (
                <Button onClick={handleNextPage} disabled={!nextNextToken}>
                  Next Page
                </Button>
              )}
            </Stack>
          )}
      </Container>
    </>
  );
};

export default Tutor;
