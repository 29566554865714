import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams } from 'react-router-dom';
import * as queries from '../graphql/queries'; // Make sure to import your GraphQL queries

const useGetVolunteerDetails = () => {
  const [volunteerDetails, setVolunteerDetails] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const { id } = useParams();
  console.log('id', id);
  const fetchVolunteerDetails = async () => {
    try {
      setLoading(true); // Set loading to true when fetching data

      const response = await API.graphql({
        query: queries.getVolunteer,
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setVolunteerDetails(response?.data?.getVolunteer);
      console.log('Volunteer details', response?.data?.getVolunteer);
      setLoading(false); // Set loading to false when data is fetched or in case of an error
    } catch (error) {
      console.error('Error fetching volunteer details:', error);
    } finally {
      setLoading(false); // Set loading to false when data is fetched or in case of an error
    }
  };

  useEffect(() => {
    fetchVolunteerDetails(); // Call the fetchVolunteerDetails function when the component mounts
  }, []); // Empty dependency array, meaning this effect runs once on mount

  return { volunteerDetails, loading }; // Include loading state in the return value
};

export default useGetVolunteerDetails;
