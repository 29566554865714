import React from "react";
import { Auth } from "aws-amplify";
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// components
import { ToastContainer } from 'react-toastify';  // Move this import up
import { RecoilRoot } from 'recoil';
import { StyledChart } from './components/chart';

// routes
import Router from './routes';

// theme
import './style.css';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';

// ----------------------------------------------------------------------
export const AuthContext = React.createContext(); // added this
const initialState = {
  isAuthenticated: localStorage.getItem("auth") || false,
  email: localStorage.getItem("email") || null,
  token: localStorage.getItem("token") || null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("email", action.email);
      localStorage.setItem("auth", true);
      return {
        ...state,
        isAuthenticated: true,
        email: action.email,
      };
    case "LOGOUT":
      Auth.signOut().then(() => {
        console.log("Successfully signed out");
      }).catch((err) => {
        console.log("Signout Error - ", err);
      });
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        email: null,
      };
    default:
      return state;
  }
};


 
export default function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <HelmetProvider>
       <AuthContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
          <ToastContainer />
      <BrowserRouter>
      <RecoilRoot>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </RecoilRoot>
      </BrowserRouter>
      </AuthContext.Provider>
    </HelmetProvider>
  );
}
