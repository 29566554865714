/* eslint-disable consistent-return */
import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries'; // Import your GraphQL queries for listing teams
import * as mutations from '../graphql/mutations'; // Import your GraphQL queries for listing teams

function useStartScore() {
  const [teams, setTeams] = useState([]);
  const [event, setEvent] = useState();

  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function startEvents(event) {
    try {
      setLoading(true);
      // Fetch the events list
      // const response = await API.graphql({
      //   query: queries.listLeagues,
      //   variables: {
      //     filter: {
      //       eventStatus: {
      //         eq: 'EVENT_NOT_STATRED',
      //       },
      //       title: {
      //         eq: event,
      //       },
      //     },
      //   },
      //   authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      const response = await API.graphql(graphqlOperation(queries.getLeague, { id: event }));

      // const eventsLists = response?.data?.listLeagues?.items;
      const eventsLists = response?.data?.getLeague;

      // const eventFound = eventsLists?.length > 0 ? eventsLists[0] : [];
      const eventFound = eventsLists;

      setEvent(eventFound);
      if (!eventFound.length < 1) {
        toast.error('Event Already Started or Deleted!');
        return false;
      }
      if (eventFound.eventStatus === 'EVENT_STARTED') {
        toast.error('Event Already Started !');
        return false;
      }

      if (event.teamsName.length < 1) {
        toast.error('No Teams in Events!');
        return false;
      }

      const teams = event.teamsName;

      teams?.map(async (team) => {
        const responseTeams = await API.graphql({
          query: queries.listTeams,
          variables: {
            filter: {
              name: {
                eq: team,
              },
              city: {
                eq: event.location,
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
      });
    } catch (error) {
      toast.error('Something Went Wrong!', error);

      setError(error);
      setLoading(false);
      console.error('Error:', error);
    }
  }

  return { startEvents, teams, players, loading, error };
}
const stats = {
  GP: '0',
  MIN: '0',
  PTS: '0',
  FGM: '0',
  FGA: '0',
  FGPERCENT: '0',
  THREEPM: '0',
  THREEPA: '0',
  THREEPPERCENT: '0',
  FTM: '0',
  FTA: '0',
  FTPERCENT: '0',
  OREB: '0',
  DREB: '0',
  REB: '0',
  AST: '0',
  TOV: '0',
  STL: '0',
  BLK: '0',
  PF: '0',
  FP: '0',
  DDTWO: '0',
  TDTHREE: '0',
  PlusMinus: '0',
};

const rank = {
  record: '0',
  ppg: '0',
  rpe: '0',
  apg: '0',
  ranking: '0',
};
export default useStartScore;
