import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from "react-redux";
import Iconify from '../../../components/iconify';
import AddNewTrainingProgramCardSideBar from './AddNewTrainingProgramCardSideBar';
import { getAllTrainingProgramCards } from "../../../redux/actions/TrainingProgramCardsAction";

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopTrainingProgramCard.propTypes = {
  trainingProgramCard: PropTypes.object,
};

export default function ShopTrainingProgramCard({ trainingProgramCard, deleteTrainingProgramCards, updateTrainingProgramCards }) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { title, description, id, price } = trainingProgramCard;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState(null); // Track the edit data
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleEditClick = () => {
    // console.log('Idds', id);
    navigate(`/EditTrainingProgramCard/${id}`)
  };
  const handleDeleteConfirm = async () => {
    setIsDeleteDialogOpen(false);
    await deleteTrainingProgramCards(id);
    dispatch(getAllTrainingProgramCards());
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };


  return (
    <Card >

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        </Link>

        <Typography variant="subtitle2" noWrap>
          ${price}
        </Typography>

        {/* <Typography variant="subtitle2" >
        {description.split(' ').slice(0, 20).join(' ')}
          {description.split(' ').length > 20 ? '...' : ''}
        </Typography> */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <EditButton onEditClick={handleEditClick} />
          {isEditDialogOpen && ( // Conditionally render AddNewEventSideBar
            <AddNewTrainingProgramCardSideBar
              hidden
              openTrainingProgramCard={isEditDialogOpen} // Pass the state that controls the Dialog visibility
              onCloseTrainingProgramCard={() => setIsEditDialogOpen(false)} // Pass the function to close the Dialog
              editData={editData} // Pass your edit data
              updateTrainingProgramCards={updateTrainingProgramCards}
            />
          )}
          <DeleteButton onDeleteClick={handleDeleteClick} />
        </Stack>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Training Program Card</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Training Program Card?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Card>
  );
}

const DeleteButton = ({ onDeleteClick }) => {
  return (
    <Button color="primary" onClick={onDeleteClick}>
      <Iconify color="red" icon="fluent:delete-12-regular" />
    </Button>
  );
};
const EditButton = ({ onEditClick }) => {
  return (
    <Button onClick={onEditClick}>
      <EditIcon color="primary" style={{ fontSize: '18px' }} />
    </Button>
  );
};
