import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopActivity from './Activity';

// ----------------------------------------------------------------------

ActivityList.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default function ActivityList({ activities, loading, updateActivities, deleteActivities, ...other }) {
  return (
     <Grid container spacing={3} {...other}>
       {activities?.map((activity) => (
         <Grid key={activity.id} item xs={12} sm={6} md={3}>
           <ShopActivity
             updateActivities={updateActivities}
             deleteActivities={deleteActivities}
             activity={activity}
           />
         </Grid>
       ))}
     </Grid>
  );
 }
 
