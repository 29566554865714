import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
  Stack,
  Box,
  Typography,

} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import Loader from '../../../components/Loader';
import { getAllCampCards } from '../../../redux/actions/CampCardsAction';


AddNewCampCardSideBar.propTypes = {
  openCampCard: PropTypes.bool,
  onOpenCampCard: PropTypes.func,
  onCloseCampCard: PropTypes.func,
};

export default function AddNewCampCardSideBar({
  updateCampCards,
  hidden,
  adddCampCard,
  openCampCard,
  onOpenCampCard,
  onCloseCampCard,
  editData,
  campCards,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const isEditMode = !!editData;
  const navigate = useNavigate();

  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      setTitle(editData.title || '');
      setDescription(editData?.description || '');
      setPrice(editData.price || '');
    }
  }, [editData, isEditMode]);
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const navigateToEditCardPage = () => {
    navigate('/edit-camp-card')
  }


  const handleUploadData = async () => {
    if (!title) {
      toast.error('Title is required.');
      return;
    }
  
    // Check if price is empty
    if (!price) {
      toast.error('Price is required.');
      return;
    }
     // Check if price is empty
     if (!description) {
      toast.error('Description is required.');
      return;
    }
    const campCardData = {
      title,
      price,
      description,
      id: editData?.id,
    };
    // Handle data upload logic here
    // console.log('Data to upload:', campCardData);
    if (isEditMode) {
      const id = campCardData?.id;
      // console.log('eventsDtaa',campCardData)
      onCloseCampCard();
      const updated = await updateCampCards(id, campCardData);

      if (updated) {
        dispatch(getAllCampCards());
      }
    } else {
      const data = {
        ...campCardData,
      };

      onCloseCampCard();
      const created = await adddCampCard(data);
      // Close the modal

      if (created) {
        dispatch(getAllCampCards());
      }
    }

    // Reset form fields
    setTitle('');
    setPrice('');
    setDescription('');
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={navigateToEditCardPage}
        style={buttonStyles}
        hidden={hidden}
      >
        Add Camp Card
      </Button>
      <Dialog open={openCampCard} onClose={onCloseCampCard}>
        <DialogTitle>{isEditMode ? 'Edit Camp Card' : 'Add New Camp Card'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new Camp Card.</DialogContentText>
          <TextField autoFocus margin="dense" label="Title" fullWidth value={title} onChange={handleTitleChange} />
          <TextField autoFocus margin="dense" label="Price" fullWidth value={price} onChange={handlePriceChange} />

          <FormControl fullWidth margin="dense">
            <TextField
              type="text"
              label="Description"
              value={description}
              name="description"
              margin="normal"
              fullWidth
              onChange={handleDescriptionChange}

            />
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={onCloseCampCard} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
