import { useState, useEffect } from 'react';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries'; // Import your GraphQL queries

const useGetAllTeams = () => {
  const [teamslist, setTeamsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.graphql({
      query: queries.listTeams,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then((response) => {
      setTeamsList(response?.data?.listTeams?.items);
    });

    setLoading(false);
  }, []);

  return { teamslist };
};

export default useGetAllTeams;
