import {
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAIL,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAIL,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAIL,
  CLEAR_ACTIVITY_ERRORS,
  ALL_ACTIVITY_REQUEST,
  ALL_ACTIVITY_SUCCESS,
  ALL_ACTIVITY_FAIL,
} from '../constants/ActivitiesConstants';

const initialState = {
  activities: [],
  activityCount: 0,
  resultPerPage: 0,
  activity: {},
  isUpdated:false,
  loading: false,
  error: null,
  filteredActivitiesCount: 0,
};

export const activitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_ACTIVITY_REQUEST:
    case GET_ACTIVITY_REQUEST:
    case CREATE_ACTIVITY_REQUEST:
    case DELETE_ACTIVITY_REQUEST:
    case UPDATE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        activities: [],
        activity: {},
      };
    case ALL_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        activities: action.payload.activities,
        activityCount: action.payload.activitiesCount,
        resultPerPage: action.payload.resultPerPage,
        filteredActivitiesCount: action.payload.filteredActivitiesCount,
      };
    case CREATE_ACTIVITY_SUCCESS:
    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        activity: action.payload,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

  
    case GET_ACTIVITY_FAIL:
    case ALL_ACTIVITY_FAIL:
    case CREATE_ACTIVITY_FAIL:
    case UPDATE_ACTIVITY_FAIL:
    case DELETE_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ACTIVITY_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



