

export const GET_TRAININGPROGRAMCARD_REQUEST = 'GET_TRAININGPROGRAMCARD_REQUEST';
export const GET_TRAININGPROGRAMCARD_SUCCESS = 'GET_TRAININGPROGRAMCARD_SUCCESS';
export const GET_TRAININGPROGRAMCARD_FAIL = 'GET_TRAININGPROGRAMCARD_FAIL';


export const ALL_TRAININGPROGRAMCARD_REQUEST = 'ALL_TRAININGPROGRAMCARD_REQUEST';
export const ALL_TRAININGPROGRAMCARD_SUCCESS = 'ALL_TRAININGPROGRAMCARD_SUCCESS';
export const ALL_TRAININGPROGRAMCARD_FAIL = 'ALL_TRAININGPROGRAMCARD_FAIL';

export const CREATE_TRAININGPROGRAMCARD_REQUEST = 'CREATE_TRAININGPROGRAMCARD_REQUEST';
export const CREATE_TRAININGPROGRAMCARD_SUCCESS = 'CREATE_TRAININGPROGRAMCARD_SUCCESS';
export const CREATE_TRAININGPROGRAMCARD_FAIL = 'CREATE_TRAININGPROGRAMCARD_FAIL';

export const UPDATE_TRAININGPROGRAMCARD_REQUEST = 'UPDATE_TRAININGPROGRAMCARD_REQUEST';
export const UPDATE_TRAININGPROGRAMCARD_SUCCESS = 'UPDATE_TRAININGPROGRAMCARD_SUCCESS';
export const UPDATE_TRAININGPROGRAMCARD_FAIL = 'UPDATE_TRAININGPROGRAMCARD_FAIL';


export const DELETE_TRAININGPROGRAMCARD_REQUEST = 'DELETE_TRAININGPROGRAMCARD_REQUEST';
export const DELETE_TRAININGPROGRAMCARD_SUCCESS = 'DELETE_TRAININGPROGRAMCARD_SUCCESS';
export const DELETE_TRAININGPROGRAMCARD_FAIL = 'DELETE_TRAININGPROGRAMCARD_FAIL';
export const DELETE_TRAININGPROGRAMCARD_RESET = 'DELETE_TRAININGPROGRAMCARD_RESET';

export const CLEAR_TRAININGPROGRAMCARD_ERRORS = 'CLEAR_TRAININGPROGRAMCARD_ERRORS';
