import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';

const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
};

const CreateComptetiveCoachModalOne = ({
    title,
    handleClose,
    handleInputChange,
    handleAdd,
    buttonText,
    inputLabel,
    emailLabel,
    phoneNumberLabel,
    addressLabel
}) => {

    const handleSubmit = (event) => {
        event.preventDefault();  // Prevents default form submission
        handleAdd();
    };

    return (
        <Dialog open onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                name="name"
                                label={inputLabel}
                                fullWidth
                                required
                                onChange={handleInputChange}
                            />
                        </Grid>
                      
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="email"
                                type='email'
                                name="email"
                                label={emailLabel}
                                fullWidth
                                required
                                onChange={handleInputChange}
                            />
                        </Grid>
                      
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="phoneNumber"
                                name="phoneNumber"
                                label={phoneNumberLabel}
                                fullWidth
                                required
                                onChange={handleInputChange}
                            />
                        </Grid>
                  
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="address"
                                name="name"
                                label={addressLabel}
                                fullWidth
                                required
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginBottom: '16px' }}>
                    <Button onClick={handleClose} variant="outlined" color="secondary" style={{ marginRight: 'auto' }}>
                        Cancel
                    </Button>
                    <Button type="submit" style={buttonStyles} color="primary" variant="contained">
                        {buttonText}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateComptetiveCoachModalOne;
