import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams } from 'react-router-dom';
import * as queries from '../graphql/queries'; // Make sure to import your GraphQL queries

const useNewsDetails = () => {
  const [getNewsDetails, setGetNewsDetails] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const { id } = useParams();

  const fetchNewsDetails = async () => {
    try {
      setLoading(true); // Set loading to true when fetching data

      const response = await API.graphql({
        query: queries.getNews,
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const newsDetails = response?.data?.getNews;
      setGetNewsDetails(newsDetails);

      setLoading(false); // Set loading to false when data is fetched or in case of an error
    } catch (error) {
      console.error('Error fetching news details:', error);
    } finally {
      setLoading(false); // Set loading to false when data is fetched or in case of an error
    }
  };

  useEffect(() => {
    fetchNewsDetails(); // Call the fetchNewsDetails function when the component mounts
  }, []); // Empty dependency array, meaning this effect runs once on mount

  return { fetchNewsDetails, getNewsDetails, loading }; // Include loading state in the return value
};

export default useNewsDetails;
