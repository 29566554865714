import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter, update } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

// @mui
import {
  Button,
  Checkbox,
  MenuItem,
  TableBody,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  Container,
  Typography,
  Grid,
  Paper,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useHandlePagintationEvents from '../hooks/handlePaginationEvents';
import Loader from '../components/Loader';
import useHandleEvent from '../hooks/handleLeagueSeason2';
import useCityList from '../hooks/getCityList';
import Iconify from '../components/iconify';
import { getAllEvents } from '../redux/actions/EventsAction';
import useGetTeams from '../hooks/getTeamsList';
import useAddTeamsToEvents from '../hooks/addTeamsToEvents';

const LeagueSeason2 = () => {
  const { fetchTeamsByLocation, eventList, loading } = useHandleEvent();
  const dispatch = useDispatch();
const {addToEvents}=useAddTeamsToEvents();
  const [updating, setUpdating] = useState(false);
  // const [loadingUpate, setUpdatingLoading] = useState(false);

  const [formVisible, setFormVisible] = useState(false); // To control form visibility
  const [selectedEvent, setSelectedEvent] = useState('');
  const { notStartedevents, loading: eventsLoading, error: eventErrors } = useSelector((state) => state.events);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [currentTeams, setCurrentTeams] = useState({});
  const [location, setLocation] = useState('');
  const { cities } = useCityList();
  const { teams } = useGetTeams();
  const { loading: updateEventLoading, updateEvents } = useHandlePagintationEvents();


  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  const removeButtonStyles = {
    backgroundColor: '#FF0000', // Set to your desired hex code for red
    color: 'white', // Set your desired text color
  };

  const handleSelect = async (event) => {
    console.log("Event Value",event.target.value)

    setSelectedEvent(event.target.value);
    const eventFound=notStartedevents?.find((ev)=>ev.id===event.target.value);
    console.log("Event",eventFound)
    const city=eventFound.location;
    console.log("City",city)
setLocation(city);    
  };

  const handleCheckboxChange = (teamId) => {
    setSelectedTeams((prevSelectedTeams) =>
      prevSelectedTeams.includes(teamId)
        ? prevSelectedTeams.filter((id) => id !== teamId)
        : [...prevSelectedTeams, teamId]
    );
  };

  useEffect(() => {
    dispatch(getAllEvents());
  }, []);

  const handleSubmit = async () => {
    try {
      const data = await fetchTeamsByLocation(location);
      setCurrentTeams(data);
      setFormVisible(true);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

const handleUpdate = async () => {
  setUpdating(true)
  console.log('clicked',selectedEvent);
  if(!selectedEvent){
    toast.error("Select Event First");
    return;
  }
  if(!selectedTeams){
    toast.error("Select Teams");
    return
  };

  await addToEvents(selectedTeams,selectedEvent)
  setUpdating(false)

}

  if (loading || updateEventLoading || eventsLoading||updating) {
    return <Loader />;
  }
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Event 2
      </Typography>
      <Grid container spacing={2} mb={5}>
        
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <InputLabel id="event">Select Event</InputLabel>
            <Select
              labelId="event"
              label="Select Event"
              name="event"
              value={selectedEvent}
              fullWidth
              variant="outlined"
              onChange={handleSelect}
            >
              <MenuItem value="">Select Event</MenuItem>
              {notStartedevents?.map((event) => (
                <MenuItem key={event?.id} value={event?.id}>
                  {event?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          <Button style={buttonStyles} variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>

      {formVisible && (
        <Grid marginTop={'25px'}>
          <ToastContainer />
         {/* Display Current Teams with Checkboxes */}
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {currentTeams.map((team) => (
                  <TableRow key={team.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedTeams.includes(team.id)}
                        onChange={() => handleCheckboxChange(team.id)}
                      />
                    </TableCell>
                    <TableCell>{team.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Update button */}
          <Grid container justifyContent="center" alignItems="center" mt={5}>
            <Button style={buttonStyles} variant="contained" color="primary" onClick={handleUpdate}>
              Update
            </Button>
          </Grid>
        </Grid>
      )}


    </Container>
  );
};

export default LeagueSeason2;
