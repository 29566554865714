import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Button, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import MainSkeleton from '../components/SkeletonLoader';
import {
  ClinicCourseList,
  AddNewClinicCourseSideBar,
} from '../sections/@dashboard/cliniccourse';
import 'react-toastify/dist/ReactToastify.css';
import handlePaginationClinicCourse from '../hooks/handlePaginationClinicCourse';

// ----------------------------------------------------------------------

export default function ClinicCourse() {
 
  const {
    clinicCourseList,
    nextNextToken,
    loading,
    deleteCourses,
    fetchNextPage,
    fetchCourse,
    fetchPreviousPage,
    previousTokens,
    addCourse,
    updateCourses,
  } = handlePaginationClinicCourse();

  const [openCourse, setOpenCourse] = useState(false);
  const [courses, setCourses] = useState(clinicCourseList);

  
  const handleOpenCourse = () => {
    setOpenCourse(true);
  };
  const handleCloseCourse = () => {
    setOpenCourse(false);
  };
 
  useEffect(() => {
    setCourses(clinicCourseList || []);
  }, [clinicCourseList]);

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Clinic Courses | Minimal UI </title>
      </Helmet>

      <Container>
        <ToastContainer />
        <Typography variant="h4" sx={{ mb: 5 }}>
        Clinic Courses
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <AddNewClinicCourseSideBar
              openCourse={openCourse}
              onCloseCourse={handleCloseCourse}
              onOpenCourse={handleOpenCourse}
              adddCourse={addCourse}
            />
          </Stack>
        </Stack>
        {loading ? (
          <>
            <MainSkeleton />
          </>
        ) : (
          <ClinicCourseList
            courses={courses}
            updateCourses={updateCourses}
            deleteCourses={deleteCourses}
          />
        )}
        {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
           {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}
