import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  IconButton,
  Avatar,
  Tooltip,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import ExcludeDaysModal from './ExcludeModal'; // Import the ExcludeDaysModal component

const ToggleButton = ({ label, checked, onChange }) => (
  <div className="flex items-center gap-2">
    <input type="checkbox" checked={checked} onChange={onChange} className="px-2 py-2" />
    <p>{label}</p>
  </div>
);

const weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Tutor = ({
  tutor,
  currentTutor,
  setTutor,
  setTutorDetails,
  index,
  tutorDetails,
  handleRemoveSlot,
  handleRemoveTutor,
  currentTutorDetails,
}) => {
  const { daily, days, tutorProfile, excludeSlots, tutorName, dates, slots, maxStudents, tutorId } = tutor;
  const data = tutorDetails[index];
  const [openExcludeModal, setOpenExcludeModal] = useState(false);

  const handleDays = (event) => {
    if (!data.days?.includes(event)) {
      data.days = [...data.days, event];
      setTutorDetails([...tutorDetails]);
    } else {
      data.days = data.days.filter((day) => day !== event);
      setTutorDetails([...tutorDetails]);
    }
  };

  const handleEveryDay = () => {
    data.daily = !data.daily;
    setTutorDetails([...tutorDetails]);
  };

  const handleClick = () => {
    setTutor(tutorId);
  };

  const handleExcludeDays = () => {
    setTutor(tutorId);
    toast('Now choose the date and slots you want to exclude');
    setOpenExcludeModal(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  return (
    <div
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      role="button"
      tabIndex={0}
      className={`px-4 py-5 flex cursor-pointer justify-between rounded-md border ${currentTutor === tutorId ? 'border-blue-500' : 'border-gray-200'} transition-all ease-in-out duration-300`}
    >
      <div className="field py-3 w-full flex justify-between gap-2 items-start">
        <div className="img w-fit flex flex-col items-center text-center">
          <Avatar src={tutorProfile} className="h-20 w-20" alt={tutorName} />
          <p className="py-2 text-center">{tutorName}</p>
        </div>
        <div className="flex flex-col gap-2 w-1/4">
          <p className="font-semibold">Slots</p>
          <div className="flex flex-col gap-2">
            {slots?.map((slot, slotIndex) => (
              <div key={slotIndex} className="flex items-center gap-2 bg-gray-100 p-2 rounded">
                <span>{`${new Date(slot.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${new Date(slot.endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</span>
                <IconButton onClick={() => handleRemoveSlot(index, slotIndex)} size="small" className="ml-2 text-red-600">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-2 w-1/4">
          <div className="flex items-center justify-between">
            <p className="font-semibold">Exclude Days</p>
            <Button onClick={handleExcludeDays} variant="contained" className="bg-orange-800" size="small" color="primary">
              Add
            </Button>
          </div>
          <div className="flex flex-col gap-2">
            {excludeSlots?.map((excludeSlot, excludeIndex) => (
              <div key={excludeIndex} className="flex flex-col gap-2 bg-gray-100 p-2 rounded">
                <div className="flex justify-between items-center">
                  <Tooltip title={excludeSlot.date}>
                    <span>{excludeSlot.date}</span>
                  </Tooltip>
                  <IconButton
                    onClick={() => {
                      const updatedExcludeSlots = excludeSlots?.filter((_, i) => i !== excludeIndex);
                      data.excludeSlots = updatedExcludeSlots;
                      setTutorDetails([...tutorDetails]);
                    }}
                    size="small"
                    className="ml-2 text-red-600"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
                {Array.isArray(excludeSlot.slots) && excludeSlot.slots.map((slot, slotIndex) => (
                  <div key={slotIndex} className="flex items-center gap-2 bg-gray-200 p-1 rounded">
                    <span>{`${new Date(slot.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${new Date(slot.endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</span>
                    <IconButton
                      onClick={() => {
                        const updatedSlots = excludeSlot.slots.filter((_, i) => i !== slotIndex);
                        excludeSlot.slots = updatedSlots;
                        setTutorDetails([...tutorDetails]);
                      }}
                      size="small"
                      className="ml-2 text-red-600"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-2 w-1/4">
          <p className="font-semibold text-blue-500">Available Days</p>
          <div className="flex gap-2 flex-wrap">
            {weeks?.map((day) => (
              <Button
                key={day}
                variant="outlined"
                size="small"
                className={`${daily ? 'bg-gray-500 text-white' : days?.includes(day) ? 'bg-blue-600 text-white' : 'bg-white'}`}
                onClick={() => handleDays(day)}
              >
                {day}
              </Button>
            ))}
          </div>
        </div>
        <ToggleButton label="Daily" checked={daily} onChange={handleEveryDay} />
      </div>
      <IconButton
        onClick={() => handleRemoveTutor(index)}
        className="ml-2 text-red-600 self-start"
        size="small"
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <Dialog open={openExcludeModal} onClose={() => setOpenExcludeModal(false)}>
        <DialogTitle>Exclude Days and Slots</DialogTitle>
        <DialogContent>
          <ExcludeDaysModal currentTutorDetails={currentTutorDetails} handleClose={() => setOpenExcludeModal(false)} tutorDetails={tutorDetails} setTutorDetails={setTutorDetails} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Tutor;
