import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

function useCityList() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [cities, setCities] = useState([]);

  async function getCity() {
    try {
      setLoading(true);

      // });
      const response = await API.graphql(graphqlOperation(queries.listCities));
      // Check if the city was successfully created
      const data = response?.data?.listCities?.items;
      setCities(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);

      throw error;
    }
  }
  useEffect(() => {
    getCity();
  }, []);

  return { cities, loading, error, getCity };
}

export default useCityList;
