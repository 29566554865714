import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import useGetLink from './getImageLink';
import deleteFile from '../utils/deleteFile';
import uploadFile from '../utils/uploadFile';

const useHandleClinicUser = () => {
  const [clinicUserDetails, setClinicUser] = useState();
  const [clinicList, setClinicList] = useState([]);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { getLink } = useGetLink();

  const fetchClinicUsers = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.listClinics));
      const data = response?.data?.listClinics?.items;
      const modifiedClinics = data?.map((user) => {
        return {
          ...user,
          id: user?.id,
          name: user?.participantName,
          email: user?.email,
          status: 'Confirmed',
          //
          zone: 'Clinic',
          // Add other attributes you want to include
        };
      });
      setClinicList(modifiedClinics);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching training program users:', error);
      setLoading(false);
    }
  };

  const fetchClinicUserById = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.getClinic, { id }));
      const data = response?.data?.getClinic;
      const profileLink = await getLink(data?.profile);
      const d = {
        ...data,
        profileLink,
      };
      setClinicUser(d);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching training program user details:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  const deleteClinicUserById = async (idToDelete) => {
    try {
      setLoading(true);
      const idDelete = id || idToDelete;

      const response = await API.graphql({
        query: mutations.deleteClinic,
        variables: { input: { id: idToDelete } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setClinicList((prevList) => prevList.filter((item) => item.id !== idDelete));
      setLoading(false);
      toast.success('Clinic Data Deleted Successfully.');
      navigate('/dashboard/people');
    } catch (error) {
      console.error('Error deleting Clinic user:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  const updateClinicById = async (data) => {
    try {
      setLoading(true);
      const { oldProfile, profile } = data;
      console.log(`updateCampById`, oldProfile, profile);
      if (oldProfile !== profile) {
        const deleted = await deleteFile(oldProfile);
        // if (deleted) {
        // }
        const p = await uploadFile(profile);
        data.profile = p;
      }
      delete data?.oldProfile;

      const response = await API.graphql(graphqlOperation(mutations.updateClinic, { input: { id, ...data } }));
      const updatedData = response?.data?.updateClinic;

      const profileLink = await getLink(updatedData?.profile);
      const d = {
        ...data,
        profileLink,
      };
      setClinicList((prevList) => prevList.map((user) => (user.id === updatedData.id ? updatedData : user)));

      setClinicUser(d);
      toast.success('Clinic Data Updated Successfully!');
      setLoading(false);
    } catch (error) {
      console.error('Error updating Clinic :', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  useEffect(() => {
    if (id) {
      fetchClinicUserById();
    }
    fetchClinicUsers();
  }, [id]);

  return {
    clinicUserDetails,
    clinicList,
    fetchClinicUsers,
    loading,
    deleteClinicUserById,
    updateClinicById,
  };
};

export default useHandleClinicUser;
