import React, { useEffect, useState } from 'react';
import {
    Select,
    MenuItem,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    FormControl,
    Typography,
    Container,
    Button,
    Box,
    Grid,
    InputLabel,
    FormHelperText,
    Tabs,
    Tab,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { getAllEvents } from '../redux/actions/TournamentEventsAction';
import useFilteredTournamentData from '../hooks/useFilteredTournamentData';

const GenerateTournamentJerseyNo = () => {
    const dispatch = useDispatch();
    const {
        tournamentEvents,
        loading
    } = useSelector((state) => state.tournamentEvents);
    const { fetchFilteredData, tournamentData, loading: filterLoading, generateJerseyNumbers  } = useFilteredTournamentData();
    const [selectedEvent, setSelectedEvent] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [submitted, setSubmitted] = useState(false);
    const [eventError, setEventError] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [jerseySize, setJerseySize] = useState({
        YS: '',
        YM: '',
        YL: '',
        S: '',
        M: '',
        L: '',
        XL: '',
        XXL: '',
        XXXL: '',
    });

    useEffect(() => {
        dispatch(getAllEvents());
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEventChange = (event) => {
        setSelectedEvent(event.target.value);
        setEventError(null);
    };


    const handleSubmit = async () => {
        if (!selectedEvent) {
            setEventError('required');
        } else {

            await fetchFilteredData(selectedEvent);
            setSubmitted(true);
        }
    };
    const handleGenerateJerseySubmit = async () => {
        if (!selectedEvent) {
            setEventError('required');
        } else {
            await generateJerseyNumbers(selectedEvent, jerseySize);
            setSubmitted(true);
        }
    };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleJerseySizeChange = (event) => {
        setJerseySize({
            ...jerseySize,
            [event.target.name]: event.target.value,
        });
    };

    const buttonStyles = {
        backgroundColor: '#1E90FF',
        color: 'white',
    };

    if (loading || filterLoading) {
        return <Loader />;
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Tournament Jersey Details
            </Typography>
            <Grid container spacing={2} mb={5} alignItems="center">
                <Grid item xs={8}>
                    <FormControl fullWidth margin="dense" error={eventError === 'required'}>
                        <InputLabel id="event">Select Event</InputLabel>
                        <Select
                            labelId="event"
                            label="Select Event"
                            name="event"
                            fullWidth
                            value={selectedEvent}
                            onChange={handleEventChange}
                            sx={{ height: 56 }}
                        >
                            <MenuItem value="">Select Event</MenuItem>
                            {tournamentEvents?.map((event) => (
                                <MenuItem key={event.id} value={event.id}>
                                    {event.title}
                                </MenuItem>

                            ))}
                        </Select>
                        {eventError === 'required' && <FormHelperText>This field is required</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={4} display="flex" alignItems="center">
                    <Button
                        style={buttonStyles}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        fullWidth
                        sx={{ height: 56 }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
            {submitted && (
                <>
                   <Tabs
    value={tabValue}
    onChange={handleTabChange}
    aria-label="Tournament Tabs"
    variant="fullWidth" // Set the tabs to take full width
>
    <Tab
        label="Generate"
        sx={{ justifyContent: 'center' }} // Center the text
    />
    <Tab
        label="View"
        sx={{ justifyContent: 'center' }} // Center the text
    />
</Tabs>


                    {tabValue === 0 && (
                        <>
                            <Box mt={2}>
                                <Typography variant="h6" gutterBottom>
                                    Enter Jersey Sizes
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="YS"
                                                name="YS"
                                                value={jerseySize.YS}
                                                onChange={handleJerseySizeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="YM"
                                                name="YM"
                                                value={jerseySize.YM}
                                                onChange={handleJerseySizeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="YL"
                                                name="YL"
                                                value={jerseySize.YL}
                                                onChange={handleJerseySizeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="S"
                                                name="S"
                                                value={jerseySize.S}
                                                onChange={handleJerseySizeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="M"
                                                name="M"
                                                value={jerseySize.M}
                                                onChange={handleJerseySizeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="L"
                                                name="L"
                                                value={jerseySize.L}
                                                onChange={handleJerseySizeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="XL"
                                                name="XL"
                                                value={jerseySize.XL}
                                                onChange={handleJerseySizeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="XXL"
                                                name="XXL"
                                                value={jerseySize.XXL}
                                                onChange={handleJerseySizeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="XXXL"
                                                name="XXXL"
                                                value={jerseySize.XXXL}
                                                onChange={handleJerseySizeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                            </Box>
                            <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    marginTop={2}
    marginBottom={2}
>
    <Button
        style={buttonStyles}
        variant="contained"
        color="primary"
        onClick={handleGenerateJerseySubmit}
        sx={{ width: 'fit-content' }} // Set the button width to fit its content
    >
        Submit
    </Button>
</Box>

                        </>
                    )}

                    {tabValue === 1 && (
                        <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Players</TableCell>
                                            <TableCell>Jersey No</TableCell>
                                            <TableCell>Team</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tournamentData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((tournament) => (
                                                <TableRow key={tournament.id}>
                                                    <TableCell>{tournament?.participantName}</TableCell>
                                                    <TableCell>{tournament?.jerseyNo}</TableCell>
                                                    <TableCell>{tournament?.teamName}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={tournamentData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}


                </>
            )}
        </Container>
    );
};

export default GenerateTournamentJerseyNo;