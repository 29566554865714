import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import * as customQueries from '../graphql/customQuries';
import * as queries from '../graphql/queries';

const useGetLeagueName = () => {
  const [leaguesNameList, setLeaguesListName] = useState();
  const [notStartedLeaguesNameList, setNotStartedLeaguesListName] = useState();
  const [loading, setLoading] = useState(false);
  const [playersList, setPlayersList] = useState();
  const [teamList, setTeamsList] = useState();

  const getTournamentLeague = async () => {
    setLoading(true);
    try {
      console.log('Response Started');
      const response = await API.graphql(graphqlOperation(queries.listTournamentLeagues));

      console.log('Response', response);
      const list = response.data.listTournamentLeagues.items;
      const notStartedList = list?.filter((leagu) => leagu.eventStatus === 'EVENT_NOT_STARTED');

      setNotStartedLeaguesListName(notStartedList);
      setLeaguesListName(list);
    } catch (error) {
      console.error(`Error While Listing League Name ${error}`);
      throw new Error(`Error While Listing League Name ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const getTeamsOfTournamentLeague = async (eventId) => {
    setLoading(true);
    try {
      const responseOfListTeams = await API.graphql({
        query: queries.listTournamentTeams,
        variables: {
          filter: {
            tournamentId: {
              eq: eventId,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const list = responseOfListTeams.data.listTournamentTeams.items;
      console.log('TeamsList', list);
      setTeamsList(list);
    } catch (error) {
      console.error(`Error While Listing League Name ${error.message}`);
      throw new Error(`Error While Listing League Name ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const getTournamentLeagueByID = async (eventId) => {
    setLoading(true);
    try {
      const response = await API.graphql(graphqlOperation(queries.getTournamentLeague, { id: eventId }));

      const list = response.data.getTournamentLeague;
      console.log('TeamsList', list);

      return list;
    } catch (error) {
      console.error(`Error While Listing League Name ${error.message}`);
      throw new Error(`Error While Listing League Name ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  const getPlayerOfTournamentLeague = async (teamId) => {
    setLoading(true);

    try {
      const response = await API.graphql(graphqlOperation(queries.getTournamentTeam, { id: teamId }));

      const player = response.data.getTournamentTeam;
      setPlayersList(player.players.items);

      setLoading(false);

      return player.players.items;
    } catch (error) {
      console.error('Error fetching not started leagues:', error);
      setLoading(false);
      // toast.error('Failed to fetch not started leagues');
      throw error; // Rethrow or handle the error as needed
    }
  };

  useEffect(() => {
    getTournamentLeague();
  }, []);
  return {
    leaguesNameList,
    notStartedLeaguesNameList,
    getTournamentLeague,
    getTournamentLeagueByID,
    getPlayerOfTournamentLeague,
    getTeamsOfTournamentLeague,
    teamList,
    playersList,
    loading,
  };
};

export default useGetLeagueName;
