import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Select,
  Container,
  Box,
  MenuItem,
  Grid,
  InputLabel,
  Checkbox,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import Loader from '../Loader';

import useGetAllCity from "../../hooks/getCityList";
import getLeaguesList from "../../hooks/getLeagues";
import useGetVolunteerDetails from '../../hooks/getVolunteerDetails';
import useUpdateVolunteer from '../../hooks/updateVolunteer';

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

const Volunteer = () => {
  const { cities } = useGetAllCity();
  console.log('dsjyhjfds',cities);
  const { leagues } = getLeaguesList();
  const { volunteerDetails, loading } = useGetVolunteerDetails();
  const { updateVolunteer, loading: updateLoading } = useUpdateVolunteer();
  console.log('VOluntere', volunteerDetails);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [agegroup, setAgegroup] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [ext, setExt] = useState('');
  const [homePhone, setHomePhone] = useState('');
  const [country, setCountry] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [isParentGuardian, setIsParentGuardian] = useState(false);
  const [gender, setGender] = useState('');
  const [shirtSize, setShirtSize] = useState('');
  const [isMemberOfTeamOrClub, setIsMemberOfTeamOrClub] = useState(false);
  const [clubName, setClubName] = useState('');
  const [secondaryEmergencyContact, setSecondaryEmergencyContact] = useState('');
  const [howDidYouHear, setHowDidYouHear] = useState('');
  const [healthConditions, setHealthConditions] = useState(false);
  const [agreeRelease, setAgreeRelease] = useState(false);
  const [agreeActive, setAgreeActive] = useState(false);
  const [signatureType, setSignatureType] = useState(''); // Default value
  const [electronicSignature, setElectronicSignature] = useState('');
  const [participant, setParticipant] = useState('');
  const [event, setEvent] = useState('');
  const [preferredCity, setPreferredCity] = useState('');
  const [volunteerSpecialty, setVolunteerSpecialty] = useState('');
  const [msg, setMsg] = useState('');
  const [familyMemberName, setFamilyMemberName] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [primaryEmergencyContact,setPrimaryEmergencyContact] =useState("");
  const [primaryEmergencyContactEmail, setPrimaryEmergencyContactEmail] =
    useState("");
  const [parentEmailAddress, setParentEmailAddress] = useState("");

  const [primaryCallPhoneNumber, setPrimaryCallPhoneNumber] = useState("");
  const [secondaryEmergencyContactEmail, setSecondaryEmergencyContactEmail] =
    useState("");
  const [secondaryCallPhoneNumber, setSecondaryCallPhoneNumber] = useState("");
  
  const handlePrimaryEmergencyContactChange = (e) => {
    setPrimaryEmergencyContact(e.target.value);

  };
  const handleAgeGroup = (e) => {
    setAgegroup(e.target.value);
   
  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
   
  };
  const handlePrimaryEmergencyContactEmailChange = (e) => {
    setPrimaryEmergencyContactEmail(e.target.value);

  };
  const handleSecondaryEmergencyContactEmailChange = (e) => {
    setSecondaryEmergencyContactEmail(e.target.value);

  };
  const handlePrimaryCallPhoneNumberChange = (e) => {
    setPrimaryCallPhoneNumber(e.target.value);

  };
  const handleSecondaryCallPhoneNumberChange = (e) => {
    setSecondaryCallPhoneNumber(e.target.value);

  };
  const handleEventChange = (e) => {
    setEvent(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

 
  const handleParentEmailAddressChange = (e) => {
    setParentEmailAddress(e.target.value);
  };

  const handleCellPhoneNumberChange = (e) => {
    setCellPhone(e.target.value);
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };
  const handleBusinessPhoneNumberChange = (e) => {
    setBusinessPhone(e.target.value);
  };

  const handleHomePhoneNumberChange = (e) => {
    setHomePhone(e.target.value);
  };

  const handleExtChange = (e) => {
    setExt(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleParticipantChange = (e) => {
    setParticipant(e.target.value);
  };




  const handlePreferredCityChange = (e) => {
    setPreferredCity(e.target.value);
  };

 
  const handleVolunteerSpecialtyChange = (e) => {
    setVolunteerSpecialty(e.target.value);
  };

  const handleMsgChange = (e) => {
    setMsg(e.target.value);
  };

  const handleFamilyMemberNameChange = (e) => {
    setFamilyMemberName(e.target.value);
  };

  const handleDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleIsParentGuardianChange = (e) => {
    setIsParentGuardian(e.target.checked);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleShirtSizeChange = (e) => {
    setShirtSize(e.target.value);
  };

  const handleIsMemberOfTeamOrClubChange = (e) => {
    setIsMemberOfTeamOrClub(e.target.value === 'true');
  };

  const handleClubNameChange = (e) => {
    setClubName(e.target.value);
  };

  const handleSecondaryEmergencyContactChange = (e) => {
    setSecondaryEmergencyContact(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  

  const handleHowDidYouHearChange = (e) => {
    setHowDidYouHear(e.target.value);
  };

  const handleHealthConditionsChange = (e) => {
    const selectedValue = e.target.value === 'true'; // Convert string to boolean
    setHealthConditions(selectedValue);
    setShowMessage(selectedValue);
  };
  
  

  const handleSignatureTypeChange = (e) => {
    setSignatureType(e.target.value);
  };

  const handleElectronicSignatureChange = (e) => {
    setElectronicSignature(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement form submission logic here
  };
  const buttonStyles = {
    backgroundColor: '#1E90FF',
    color: 'white',
  };

  useEffect(() => {
    setParticipant(volunteerDetails?.participantName || '');
    setEvent(volunteerDetails?.event || '');
    setPreferredCity(volunteerDetails?.preferredCity || '');
    setVolunteerSpecialty(volunteerDetails?.volunteerSpecialty || '');
    setFamilyMemberName(volunteerDetails?.familyMemberName || '');
    setMsg(volunteerDetails?.msg || '');
    setGender(volunteerDetails?.gender || '');
    setShirtSize(volunteerDetails?.shirtSize || '');
    setDateOfBirth(volunteerDetails?.dateOfBirth || '');
    setIsParentGuardian(volunteerDetails?.isAdult || false);
    setClubName(volunteerDetails?.clubName || '');
    setIsMemberOfTeamOrClub(volunteerDetails?.isMemberOfOtherTeam || false);
    setEmail(volunteerDetails?.email || '');
    setParentEmailAddress(volunteerDetails?.parentEmailAddress || '');
    setMessage(volunteerDetails?.message || '');
    setFirstName(volunteerDetails?.parentFirstName || '');
    setLastName(volunteerDetails?.parentLastName || '');
    setHomePhone(volunteerDetails?.parentHomePhoneNumber || '');
    setCellPhone(volunteerDetails?.parentPhoneNumber || '');
    setSecondaryEmergencyContact(volunteerDetails?.secondaryPersonName || '');
    setPrimaryEmergencyContact(volunteerDetails?.primaryPersonName || '');
    setBusinessPhone(volunteerDetails?.parentBusinessPhoneNumber || '');
    setCountry(volunteerDetails?.parentCountry || '');
    setElectronicSignature(volunteerDetails?.signature || '');
    setShowMessage(!!volunteerDetails?.healthConditions);
    setPrimaryEmergencyContactEmail(volunteerDetails?.primaryEmergencyContactEmail || '');
    setPrimaryCallPhoneNumber(volunteerDetails?.primaryCallPhoneNumber || '');
    setSecondaryEmergencyContactEmail(volunteerDetails?.secondaryEmergencyContactEmail || '');
    setSecondaryCallPhoneNumber(volunteerDetails?.secondaryCallPhoneNumber || '');
    setHealthConditions(volunteerDetails?.healthConditions);
    setIsMemberOfTeamOrClub(volunteerDetails?.isMemberOfOtherTeam);
    setExt(volunteerDetails?.ext || '');
    setHowDidYouHear(volunteerDetails?.heardAboutLeague || '');
    setPhoneNumber(volunteerDetails?.phoneNumber || '');
    setAgegroup(volunteerDetails?.agegroup || '');
  }, [volunteerDetails]);

  const updateLeague = async () => {
    const data = {
      id: volunteerDetails?.id,
      participantName: participant || volunteerDetails?.participantName,
      volunteerSpecialty : volunteerSpecialty || volunteerDetails?.volunteerSpecialty,
      email: email || volunteerDetails?.email,
      ext: ext || volunteerDetails?.ext,
      msg: msg || volunteerDetails?.msg,
      dateOfBirth: dateOfBirth || volunteerDetails?.dateOfBirth,
      healthConditions,
      heardAboutLeague: howDidYouHear || volunteerDetails?.heardAboutLeague,
      isAdult: isParentGuardian,
      isMemberOfOtherTeam: isMemberOfTeamOrClub,
      shirtSize: shirtSize || volunteerDetails?.shirtSize,
      preferredCity: preferredCity || volunteerDetails?.preferredCity,
      familyMemberName: familyMemberName || volunteerDetails?.familyMemberName,
      message: message || volunteerDetails?.message,
      parentBusinessPhoneNumber: businessPhone || volunteerDetails?.parentBusinessPhoneNumber,
      parentCountry: country || volunteerDetails?.parentCountry,
      parentEmailAddress: parentEmailAddress || volunteerDetails?.parentEmailAddress,
      parentFirstName: firstName || volunteerDetails?.parentFirstName,
      parentHomePhoneNumber: homePhone || volunteerDetails?.parentHomePhoneNumber,
      parentLastName: lastName || volunteerDetails?.parentLastName,
      parentPhoneNumber: cellPhone || volunteerDetails?.parentPhoneNumber,
      secondaryPersonName: secondaryEmergencyContact || volunteerDetails?.secondaryPersonName,
      signature: electronicSignature || volunteerDetails?.signature,
      gender: gender || volunteerDetails?.gender,
      event: event || volunteerDetails?.event,
      primaryPersonName: primaryEmergencyContact || volunteerDetails?.primaryPersonName,
      primaryCallPhoneNumber: primaryCallPhoneNumber || volunteerDetails?.primaryCallPhoneNumber,
      secondaryCallPhoneNumber: secondaryCallPhoneNumber || volunteerDetails?.secondaryCallPhoneNumber,
      primaryEmergencyContactEmail: primaryEmergencyContactEmail || volunteerDetails?.primaryEmergencyContactEmail,
      secondaryEmergencyContactEmail: secondaryEmergencyContactEmail || volunteerDetails?.secondaryEmergencyContactEmail,
      phoneNumber: phoneNumber || volunteerDetails?.phoneNumber,
      agegroup: agegroup || volunteerDetails?.agegroup,
    };

    await updateVolunteer(data);
  };

  if (loading || updateLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Container>
      <form onSubmit={handleFormSubmit}>
        <Grid item xs={12} sm={6} style={{ marginLeft: 1, marginTop: 5 }}>
          <div className="text-3xl font-bold">Edit VOLUNTEER</div>
        </Grid>
        <Grid container style={{ marginLeft: 1, marginTop: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="participant"
              variant="outlined"
              label="Participant"
              fullWidth
              value={participant}
              onChange={handleParticipantChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              variant="outlined"
              label="Email Address"
              fullWidth
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Phone Number"
              fullWidth
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="agegroup">Age Group</InputLabel>
              <Select
                name="agegroup"
                variant="outlined"
                label="Age Group"
                fullWidth
                value={agegroup}
                onChange={handleAgeGroup}
              >
                <MenuItem value="">Select Age Group</MenuItem>
                {ages?.map((data, index) => (
                  <MenuItem value={data.value}>{data.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="event_signing_up_for">Event Signing Up For</InputLabel>

              <Select
                name="event_signing_up_for"
                variant="outlined"
                label="Event Signing Up For"
                fullWidth
                value={event}
                onChange={handleEventChange}
              >
                <MenuItem value="">
                  Select Event
                </MenuItem>
                {leagues?.map((value, i) => (
                  <MenuItem value={value.title} key={i}>
                    {value.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="preferred_city">Preferred City</InputLabel>

              <Select
                name="preferred_city"
                variant="outlined"
                label="Preferred City"
                fullWidth
                value={preferredCity}
                onChange={handlePreferredCityChange}
              >
                <MenuItem value="">
                  Select City
                </MenuItem>
                {cities?.map((value, idx) => (
                  <MenuItem value={value.name} key={idx}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="speciality_as_volunteer">Speaciality As Volunteer</InputLabel>
              <Select
                name="speciality_as_volunteer"
                variant="outlined"
                label="Speaciality As Volunteer"
                fullWidth
                value={volunteerSpecialty}
                onChange={handleVolunteerSpecialtyChange}
              >
                <MenuItem value="">
                  select volunteer
                </MenuItem>
                <MenuItem value="volunteer">
                  volunteer
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="message"
              label="Type Message"
              multiline
              rows={6}
              variant="outlined"
              fullWidth
              value={msg}
              onChange={handleMsgChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="familyMemberName"
              label="Family Member's Name"
              variant="outlined"
              fullWidth
              value={familyMemberName}
              onChange={handleFamilyMemberNameChange}
            />
          </Grid>
        
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <TextField
                type="date"
                label="Date of Birth"
                value={dateOfBirth}
                name="date"
                onChange={handleDateOfBirthChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox checked={isParentGuardian} onChange={handleIsParentGuardianChange} />}
              label={
                <Typography variant="body1">
                  You are this person's parent or legal guardian. You must be the parent or legal guardian to register
                  someone under 18 years old. as of 2021-2022 school year
                </Typography>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="how-did-you-hear-label">Gender</InputLabel>

              <Select fullWidth label="Gender" variant="outlined" value={gender} onChange={handleGenderChange}>
                <MenuItem value="" >
                select Gender
                </MenuItem>
                <MenuItem value="Male" >
                  Male
                </MenuItem>
                <MenuItem value="Female" >
                  Female
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="how-did-you-hear-label">Shirt Size</InputLabel>

              <Select
                fullWidth
                label="Shirt Size"
                variant="outlined"
                value={shirtSize}
                onChange={handleShirtSizeChange}
              >
                <MenuItem value="" >
                  select Shirt Size
                </MenuItem>
                {shirtSizeOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="radio-group">Is the participant currently a member of a team or club?</InputLabel>
            <RadioGroup
              aria-label="Is the participant currently a member of a team or club?"
              value={isMemberOfTeamOrClub ? 'true' : 'false'}
              onChange={handleIsMemberOfTeamOrClubChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Club Name"
              variant="outlined"
              value={clubName}
              onChange={handleClubNameChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="how-did-you-hear-label">Primary Emergency Contact</InputLabel>

              <Select
                fullWidth
                label="Primary Emergency Contact"
                variant="outlined"
                value={primaryEmergencyContact}
                onChange={handlePrimaryEmergencyContactChange}
              >
                {[
                  { label: 'Father', value: 'Father' },
                  { label: 'Mother', value: 'Mother' },
                  { label: 'Son', value: 'Son' },
                  { label: 'Daughter', value: 'Daughter' },
                  { label: 'Brother', value: 'Brother' },
                  { label: 'Spouce', value: 'Spouce' },
                ].map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number for Emergency Contact"
              variant="outlined"
              value={primaryCallPhoneNumber}
              onChange={handlePrimaryCallPhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="primaryEmail"
              variant="outlined"
              label="Primary Email Address"
              fullWidth
              value={primaryEmergencyContactEmail}
              onChange={handlePrimaryEmergencyContactEmailChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="how-did-you-hear-label">Secondary Emergency Contact</InputLabel>

              <Select
                fullWidth
                label="Secondary Emergency Contact"
                variant="outlined"
                value={secondaryEmergencyContact}
                onChange={handleSecondaryEmergencyContactChange}
              >
                {[
                  { label: 'Father', value: 'Father' },
                  { label: 'Mother', value: 'Mother' },
                  { label: 'Son', value: 'Son' },
                  { label: 'Daughter', value: 'Daughter' },
                  { label: 'Brother', value: 'Brother' },
                  { label: 'Spouce', value: 'Spouce' },
                ].map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number for Emergency Contact"
              variant="outlined"
              value={secondaryCallPhoneNumber}
              onChange={handleSecondaryCallPhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="secondaryEmail"
              variant="outlined"
              label="Secondary Email Address"
              fullWidth
              value={secondaryEmergencyContactEmail}
              onChange={handleSecondaryEmergencyContactEmailChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="how-did-you-hear-label">How did you hear about us?</InputLabel>

              <Select
                fullWidth
                label="How did you hear about us?"
                variant="outlined"
                value={howDidYouHear}
                onChange={handleHowDidYouHearChange}
              >
                {howDidYouHearOptions.map((option, index) => (
                  <MenuItem value={option.value} key={index}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="radio-group">Are there any health conditions?</InputLabel>
            <RadioGroup
              aria-label="Are there any health conditions?"
              value={healthConditions ? 'true' : 'false'}
              onChange={handleHealthConditionsChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          {showMessage && (
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Explain"
                value={message}
                onChange={handleMessageChange}
                variant="outlined"
                multiline
                fullWidth
                rows={4}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="First Name"
              fullWidth
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Last Name"
              fullWidth
              value={lastName}
              onChange={handleLastNameChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="parentEmail"
              variant="outlined"
              label="Parent Email Address"
              fullWidth
              value={parentEmailAddress}
              onChange={handleParentEmailAddressChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Cell Phone Number"
              fullWidth
              value={cellPhone}
              onChange={handleCellPhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Home Phone Number"
              fullWidth
              value={homePhone}
              onChange={handleHomePhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Business Phone Number"
              fullWidth
              value={businessPhone}
              onChange={handleBusinessPhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="text" variant="outlined" label="Ext" fullWidth value={ext} onChange={handleExtChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="country">Country</InputLabel>
              <Select fullWidth label="Country" variant="outlined" value={country} onChange={handleCountryChange}>
              <MenuItem value="" >
                  select Country
                </MenuItem>
                {countryList?.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <InputLabel>Digital Signature</InputLabel>
            <RadioGroup
              aria-label="Signature Type"
              name="signatureType"
              value={signatureType}
              onChange={handleSignatureTypeChange}
            >
              <FormControlLabel value="write" control={<Radio />} label="I will sign the registration form by hand." />
              <FormControlLabel
                value="type"
                control={<Radio />}
                label="I will type my name as my electronic signature."
              />
            </RadioGroup>
          </Grid> */}
          {signatureType === 'type' && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Electronic Signature"
                variant="outlined"
                value={electronicSignature}
                onChange={handleElectronicSignatureChange}
              />
            </Grid>
          )}
          {/* <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" className="font-bold text-xl mt-4 mb-2">
                Waivers and Agreements
              </Typography>
              <Typography>
                Please read the following waivers and agreements carefully. They include release of liability and waiver
                of legal rights, and deprive you of the ability to sue certain parties. By agreeing electronically, you
                acknowledge that you have both read and understood all text presented to you as part of the registration
                process.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox required checked={agreeRelease} onChange={handleAgreeReleaseChange} color="primary" />
                  }
                  label={
                    <Typography>
                      I agree to the <strong>RELEASE OF LIABILITY AND CANCELLATION POLICY</strong>
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox required checked={agreeActive} onChange={handleAgreeActiveChange} color="primary" />
                  }
                  label={
                    <Typography>
                      I agree to the <strong>Active Agreement and Waiver</strong>
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
          </Grid> */}
        </Grid>
        <Box mt={2} ml={5}>
          <Button variant="contained" onClick={updateLeague} style={buttonStyles} type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default Volunteer;
const howDidYouHearOptions = [
  { value: 'friend', label: 'From a Friend' },
  { value: 'socialMedia', label: 'Social Media' },
  { value: 'website', label: 'Website' },
  { value: 'event', label: 'Event or Workshop' },
  { value: 'other', label: 'Other' },
];
const shirtSizeOptions = [
  { value: "small", label: "Small" },
  { value: "medium", label: "Medium" },
  { value: "large", label: "Large" },
  { value: "xlarge", label: "X-Large" },
  { value: "xxlarge", label: "XX-Large" },
];
const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];