import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHandleComptetive5x5Coach } from 'src/hooks/handleComptetive5x5Coach';
import Loader from 'src/components/Loader';
import CreateComptetiveCoachModalOne from './CreateComptetive5x5CoachModal';
import Details from './Details';


export default function ComptetiveCoach() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        address: ""
    });

    const [error, setError] = useState('');

    const [comptetiveCoach, setComptetiveCoach] = useState([]);

   
    const { createCoach, loading: comptetiveLoading, updateCoach, deleteCoach, getAllCoachs, getCoachById } = useHandleComptetive5x5Coach();

    const [showModal, setShowModal] = useState(false);


    const handleShowModals = () => {
        setShowModal(true);
    };

    const handleCloseModals = () => {
        setShowModal(false);
    };


    const handleInputValue = (e) => {
        const { name, value } = e.target;
             setFields((previous) => ({
                ...previous,
                [name]: value,
            }));
       
    };
    const createNewCoach = async () => {
        setLoading(true);

        try {
            // ComptetiveCoachchema.parse(fields);  // Validate schema
            console.log("Data", fields);

            const updateAndSend = {
                ...fields
            };

            const addThis = await createCoach(updateAndSend);

            setComptetiveCoach((prev) => [...prev, addThis]);

            setFields({
                name: "",
                email: "",
                phoneNumber: "",
                address: ""
            });

        } catch (error) {
            console.log("Error", error);
            setError(error);
        } finally {
            setLoading(false);
            handleCloseModals();
        }
    };




    const getAllComptetiveCoach = async () => {
        setLoading(true);
        try {
            const data = await getAllCoachs();
            console.log("data", data)
            setComptetiveCoach(data);
        } catch (error) {
            console.log("Error", error)
            toast.error('error')
        } finally {
            setLoading(false);
        }

    };

    const deleteAndRemove = async (id) => {
        try {
            setComptetiveCoach((previousData) => previousData.filter(team => team.id !== id));
            await deleteCoach(id);
        } catch (error) {
            console.log("Error", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {


        getAllComptetiveCoach();

    }, []);



    if (loading  || comptetiveLoading) {
        return <Loader />;
    }


    return (
        <>
            <ToastContainer />
            {/* Render ModalOne component conditionally */}
            {showModal && (
                <CreateComptetiveCoachModalOne
                    title="Add Coach"
                    handleClose={handleCloseModals}
                    handleInputChange={handleInputValue}
                    handleAdd={createNewCoach}
                    buttonText="Add Coach"
                    inputLabel="Name"
                    emailLabel="Email"
                    phoneNumberLabel="Phone Number"
                    addressLabel="Address"
                    handleCityChange={handleInputValue}
                />
            )}

            <Container maxWidth="lg">
                <Box mt={5}>
                    <Details deleteThis={deleteAndRemove} label={'Comptetive 5X5 Coach'} addDetails={handleShowModals} data={comptetiveCoach || []} />
                </Box>
            </Container>
        </>
    );
}
