import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Score from './Score';
import StartTournament from './StartTournament';

const TabPanel = ({ children, value, index }) => {
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ScoreShow = React.memo(({ scores,setListScores, setCustomLoading, setRounds, eventId }) => {
  const [tabValue, setTabValue] = useState(0); // Define tabValue state
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Define handleTabChange function
  };

  console.log(`Score:HERE`, scores);
  console.log(`EventId:`, eventId);

  return (
    <Box>{
      /*
       
       
    
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Score or Start Tournament"
        variant="fullWidth" // Ensure the tabs take up the whole width
      >
        <Tab
          label="Score"
          sx={{
            backgroundColor: tabValue === 0 ? 'primary.main' : 'transparent', // Blue background for the current tab
            color: tabValue === 0 ? 'white' : 'black', // White text for the current tab
          }}
        />
        <Tab
          label="Start Tournament"
          sx={{
            backgroundColor: tabValue === 1 ? 'primary.main' : 'transparent', // Blue background for the current tab
            color: tabValue === 1 ? 'white' : 'black', // White text for the current tab
          }}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
         */
    }
      {scores && scores.length > 0 && scores.filter((score) => score.eventStatus !== 'EVENT_ENDED').length > 0 ? (
        scores.filter((score) => score.eventStatus !== 'EVENT_ENDED').map((score, index) => (
          <Box className="" key={score?.id}>
            <Score  setListScores={setListScores} setCustomLoading={setCustomLoading} setRounds={setRounds} score={score} key={index} />
            <br />
          </Box>
        ))
      ) : (
        <p className="text-red-500 text-1xl font-medium">Event Not Started or Ended</p>
      )}
      {
        /*  </TabPanel>
       
     
    <TabPanel value={tabValue} index={1}>
    <StartTournament tournamentId={eventId && eventId} />
    </TabPanel>
    */

      }
    </Box >
  );
});

export default ScoreShow;
