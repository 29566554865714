import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Box } from  '@mui/material';
import Clinic from '../components/signup/Clinic';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';

const EditClinic = () => {
  return (
    <Box display="flex" mb={5}>
      <DashboardLayout />
      <Box mt={10} width="100%">
        <Clinic/>
      </Box>
    </Box>
  );
};

export default EditClinic;
