import React, { useState } from 'react';
import {
  TextField,
  Button,
  CardContent,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Grid,
  TablePagination,
  Card,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify'; // Move this import up
import { useHandleComptetive5x5Teams } from 'src/hooks/handleComptetive5x5Teams';
import { useHandleComptetive5x5Players } from 'src/hooks/handleComptetive5x5Players';
import useCityList from 'src/hooks/getCityList';

const ages = [
  { value: 'u7', label: 'U7' },
  { value: 'u8', label: 'U8' },
  { value: 'u9', label: 'U9' },
  { value: 'u10', label: 'U10' },
  { value: 'u11', label: 'U11' },
  { value: 'u12', label: 'U12' },
  { value: 'u13', label: 'U13' },
  { value: 'u14', label: 'U14' },
  { value: 'u15', label: 'U15' },
  { value: 'u17', label: 'U17' },
  { value: 'u18', label: 'U18' },
  { value: 'u19-u39', label: 'U19-U39' },
  { value: 'over u40', label: 'Over U40' },
];

const Comptetive5x5Invite = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedAge, setSelectedAge] = useState('');
  const [teams, setTeams] = useState([]);
  const [tryoutUsers, setTryoutUsers] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('all'); // New state for status
  const [filteredData, setFilteredData] = useState([]); // New state for filtered data
  const statuses = ['all', 'invited', 'not invited']; // Status options
  // Sorting states
  const [sortOrder, setSortOrder] = useState('newest'); // 'newest' or 'oldest'
  const { loading: cityLoading, error, cities } = useCityList();
  const { loading, findUserByMail, invitePlayer, getAllTryouts } = useHandleComptetive5x5Players();
  const { loading: teamsLoading, getAllTeams } = useHandleComptetive5x5Teams();

  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setIsAllSelected(checked);
    const updatedRows = { ...selectedRows };

    // Select or deselect all users in the entire dataset
    tryoutUsers.forEach((user) => {
      if (checked) {
        updatedRows[user.userId] = {
          selected: true,
          team: selectedRows[user.userId]?.team || '',
          city: selectedRows[user.userId]?.city || '',
          agegroup: selectedRows[user.userId]?.agegroup || '',
        };
      } else {
        delete updatedRows[user.userId];
      }
    });

    setSelectedRows(updatedRows);
  };

  React.useEffect(() => {
    // Update the Select All checkbox based on the overall selection state
    const allSelected = allUsers.every((user) => selectedRows[user.userId]?.selected);
    setIsAllSelected(allUsers.length > 0 && allSelected);
  }, [selectedRows, allUsers]);

  React.useEffect(() => {
    const loadData = async () => {
      const teamData = await getAllTeams();
      const userData = await findUserByMail();
      const tryoutData = await getAllTryouts();
      console.log('tryoutData', tryoutData?.items);
      setTeams(teamData);
      setAllUsers(userData);
      setUsers(userData);
      setTryoutUsers(tryoutData?.items);
    };
    loadData();
  }, []);

  React.useEffect(() => {
    const loadData = async () => {
      const tryoutData = await getAllTryouts();
      setFilteredData(tryoutData?.items || []); // Initialize filtered data with all items
    };
    loadData();
  }, []);

  const handleSubmit = () => {
    // Filter tryoutUsers based on selectedCity and selectedStatus
    const filtered = tryoutUsers.filter((user) => {
      const matchesCity = selectedCity ? user.cityOfRegistration === selectedCity : true; // If city is selected, filter; otherwise, include all
      const matchesStatus = selectedStatus === 'all' || 
      (selectedStatus === 'invited' && user.transactionStatus?.toLowerCase() === 'success') || // Check 'success' in lowercase
      (selectedStatus === 'not invited' && user?.transactionStatus === null); // Filter by invited/not invited based on transactionStatus

      return matchesCity && matchesStatus;
    });

    setFilteredData(filtered);
  };
 // Fetching teams and users
 const loadData = async () => {
  const teamData = await getAllTeams();
  const userData = await findUserByMail();
  const tryoutData = await getAllTryouts();
  setTeams(teamData);
  setAllUsers(userData);
  setUsers(userData);
  setTryoutUsers(tryoutData?.items || []);
  // Initialize filtered data with all items
  setFilteredData(tryoutData?.items || []);
  sortFilteredData(tryoutData?.items || [], sortOrder); // Sort on initial load
};

// Sort filtered data based on sortOrder
const sortFilteredData = (data, order) => {
 const sortedData = [...data].sort((a, b) => {
     if (order === 'newest') {
         return new Date(b.dateOfBirth) - new Date(a.dateOfBirth); // Sort newest first
     } else {
         return new Date(a.dateOfBirth) - new Date(b.dateOfBirth); // Sort oldest first
     }
 });
 setFilteredData(sortedData);
};

// Handle sort order change
const handleSortChange = (event) => {
 const newOrder = event.target.value;
 setSortOrder(newOrder);
 sortFilteredData(filteredData, newOrder); // Sort data when order changes
};
  const handleEmailSearch = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (value.length > 2) {
      const filteredUsers = allUsers.filter((user) => user.email.toLowerCase().includes(value.toLowerCase()));
      setUsers(filteredUsers);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSelectedUserId(user.userId);
    setEmail(user.email);
    setUsers([]);
  };

  const handleInvite = async () => {
    if (selectedUserId && selectedUser && selectedTeam && selectedAge && selectedCity) {
      const selectedTeamName = teams.find((team) => team.id === selectedTeam)?.name;
      await invitePlayer({
        email: selectedUser.email,
        teamID: selectedTeam,
        teamName: selectedTeamName,
        agegroup: selectedAge,
        userId: selectedUserId,
        cityOfRegistration: selectedCity,
      });
      setSelectedUser(null);
      setSelectedTeam('');
      setSelectedAge('');
      setEmail('');
    }
  };

  const handleRowSelection = (id) => {
    setSelectedRows((prev) => {
        const newRows = { ...prev };
        if (newRows[id]?.selected) {
            delete newRows[id]; // Deselect if already selected
        } else {
            // Find the user's tryout data to get the default age group
            const userTryout = tryoutUsers.find((user) => user.userId === id);
            const defaultAgeGroup = userTryout?.agegroup || ''; // Set default age group
            
            newRows[id] = {
                selected: true,
                team: prev[id]?.team || '',
                city: prev[id]?.city || '',
                agegroup: prev[id]?.agegroup || defaultAgeGroup, // Use default age group here
            };
        }
        return newRows;
    });
};

  const handleFieldChange = (id, field, value) => {
    setSelectedRows((prev) => {
        const newValue = { ...prev[id] } || { selected: true };

        if (field === 'city') {
            // When city changes, update the city and reset team selection
            newValue.city = value;
            newValue.team = ''; // Reset team when city changes
            
            // Filter teams based on the selected city
            const cityTeams = teams.filter(team => team.city === value);
            newValue.availableTeams = cityTeams; // Store available teams for this row
        } else if (field === 'team') {
            newValue.team = value;
        } else if (field === 'agegroup') {
            newValue.agegroup = value;
        }

        return { ...prev, [id]: newValue };
    });
};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Updated handleSubmitTryouts to pass data to invitePlayer
  const handleSubmitTryouts = async () => {
    const incompleteRows = Object.entries(selectedRows).filter(
      ([, value]) => !value.team || !value.city || !value.agegroup
    );
    console.log(incompleteRows.length);
    if (incompleteRows.length > 0) {
      toast.error('Please provide the Team, City, and Agegroup details for all selected rows.');
      return;
    }

    // Transform selectedRows into array of invitation data
    const invitationData = Object.entries(selectedRows).map(([userId, details]) => {
      const user = tryoutUsers.find((u) => u.userId === userId);
      const teamName = teams.find((t) => t.id === details.team)?.name;

      return {
        userId: userId,
        email: user.email,
        teamID: details.team,
        teamName: teamName,
        cityOfRegistration: details.city,
        agegroup: details.agegroup,
      };
    });

    try {
      await invitePlayer(invitationData);
      setSelectedRows({});
    } catch (error) {
      console.error('Tryout invitation error:', error);
    }
  };

  return (
    <Card className="w-full mx-auto mt-8">
      <ToastContainer />
      <CardContent>
        <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)} variant="fullWidth">
          <Tab label="Via Tryouts" />
          <Tab label="Via Email" />
        </Tabs>

        {tabIndex === 0 && (
          <div className="mt-4">
            <Typography variant="h5" className="mb-4">
              Invite Players via Tryouts
            </Typography>

            <Grid container spacing={2} alignItems="center" justifyContent="space-between" className="mb-4">
              {/* City Selector */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Select City</InputLabel>
                  <Select value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
                    {cities.map((city, index) => (
                      <MenuItem key={index} value={city.name}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Status Selector */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Select Status</InputLabel>
                  <Select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                    {statuses.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} sm={4} container justifyContent="center">
                <Button
                  onClick={handleSubmit}
                  style={{ backgroundColor: '#1E90FF', color: 'white' }}
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

            {/* Conditional Rendering: Table or No Record Found */}
            {filteredData.length > 0 ? (
              <>
                {/* Sort Order Selector */}
                <Grid item xs={12} sm={4} mb={5} container justifyContent="center">
                  <FormControl fullWidth>
                      <InputLabel>Sort By</InputLabel>
                      <Select value={sortOrder} onChange={handleSortChange}>
                          <MenuItem value="newest">Newest First</MenuItem>
                          <MenuItem value="oldest">Oldest First</MenuItem>
                      </Select>
                  </FormControl>
                </Grid>
                <TableContainer className="mb-4" style={{ overflowX: 'auto', maxWidth: '100%' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            indeterminate={
                              Object.keys(selectedRows).length > 0 &&
                              Object.keys(selectedRows).length < filteredData.length
                            }
                          />
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Date of Birth</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Team</TableCell>
                        <TableCell>Age Group</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                        <TableRow key={user.userId}>
                          <TableCell>
                            <Checkbox
                              checked={!!selectedRows[user.userId]?.selected}
                              onChange={() => handleRowSelection(user.userId)}
                            />
                          </TableCell>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{new Date(user.dateOfBirth).toLocaleDateString()}</TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <InputLabel>City</InputLabel>
                              <Select
                                value={selectedRows[user.userId]?.city || ''}
                                onChange={(e) => handleFieldChange(user.userId, 'city', e.target.value)}
                                disabled={!selectedRows[user.userId]?.selected}
                              >
                                {cities.map((city, index) => (
                                  <MenuItem key={index} value={city.name}>
                                    {city.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                           <TableCell>
                           <FormControl fullWidth>
                              <InputLabel>Team</InputLabel>
                              <Select 
                                  value={selectedRows[user.userId]?.team || ''} 
                                  onChange={(e) => handleFieldChange(user.userId, 'team', e.target.value)} 
                                  disabled={!selectedRows[user.userId]?.selected || !selectedRows[user.userId]?.city}
                              >
                                  {(selectedRows[user.userId]?.availableTeams || []).map((team) => (
                                      <MenuItem key={team.id} value={team.id}>
                                          {team.name}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <InputLabel>Age Group</InputLabel>
                              <Select
                                value={selectedRows[user.userId]?.agegroup || ''}
                                onChange={(e) => handleFieldChange(user.userId, 'agegroup', e.target.value)}
                                disabled={!selectedRows[user.userId]?.selected}
                              >
                                {ages.map((age) => (
                                  <MenuItem key={age.value} value={age.value}>
                                    {age.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />

                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Button
                      onClick={handleSubmitTryouts}
                      className="w-64"
                      disabled={Object.keys(selectedRows).length === 0}
                      style={buttonStyles}
                      color="primary"
                      variant="contained"
                    >
                      {loading || cityLoading ? <CircularProgress size={24} /> : 'Invite Players'}
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography variant="h6" align="center" color="textSecondary">
                No record found
              </Typography>
            )}
          </div>
        )}

        {tabIndex === 1 && (
          <div className="mt-4 space-y-4">
            <Typography variant="h5">Invite Player via Email</Typography>
            <TextField
              fullWidth
              label="Search by Email"
              value={email}
              onChange={handleEmailSearch}
              variant="outlined"
            />
            {users.length > 0 && (
              <List className="border rounded-md">
                {users.map((user) => (
                  <ListItem button onClick={() => handleUserSelect(user)} key={user.userId}>
                    <ListItemText
                      primary={user.email}
                      secondary={`DOB: ${new Date(user.birthdate).toLocaleDateString()}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {selectedUser && (
              <Alert severity="info" className="mt-4">
                Selected Player: {selectedUser.email}
                <br />
                Date of Birth: {new Date(selectedUser.birthdate).toLocaleDateString()}
              </Alert>
            )}
            {selectedUser && (
              <FormControl fullWidth>
                <InputLabel>Select Team</InputLabel>
                <Select value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
                  {teams.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {selectedUser && (
              <FormControl fullWidth>
                <InputLabel>Select City</InputLabel>
                <Select value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {selectedUser && (
              <FormControl fullWidth>
                <InputLabel>Select Age Group</InputLabel>
                <Select value={selectedAge} onChange={(e) => setSelectedAge(e.target.value)}>
                  {ages.map((age) => (
                    <MenuItem key={age.value} value={age.value}>
                      {age.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Button
                  onClick={handleInvite}
                  className="w-64"
                  disabled={!selectedUser || !selectedTeam || !selectedCity || !selectedAge}
                  style={buttonStyles}
                  color="primary"
                  variant="contained"
                >
                  {loading || cityLoading ? <CircularProgress size={24} /> : 'Invite Player'}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Comptetive5x5Invite;
