import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import Iconify from '../../../components/iconify';
import AddNewCarouselSideBar from './AddNewCarauselSideBar';

const StyledCarouselImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  padding: '10px',
  objectFit: 'contain',
  position: 'absolute',
});



export default function CarouselCard({ carousel, onEditCarousel, onAddImage, deleteCarousel }) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  console.log("Carausel", carousel)


  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteCarousel(carousel.id);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error deleting carousel:', error);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Card>
      <Box sx={{ pt: '56.25%', position: 'relative' }}>
        <StyledCarouselImg alt="carousel image" src={carousel.imageLink} />

      </Box>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {carousel.text}
          </Typography>
        </Link>
 
      </Stack>
      <Stack spacing={2} sx={{ p: 3 }}>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <EditButton onEditClick={handleEditClick} />
          <DeleteButton onDeleteClick={handleDeleteClick} />
        </Stack>



        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Carousel</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this entire carousel?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {isEditDialogOpen && (
          <AddNewCarouselSideBar
            openCarousel={isEditDialogOpen}
            onCloseCarousel={() => setIsEditDialogOpen(false)}
            editData={carousel}
            updateCarouselImages={onEditCarousel}
          />
        )}
      </Stack>
    </Card >
  );
}

const DeleteButton = ({ onDeleteClick }) => {
  return (
    <Button color="primary" onClick={onDeleteClick}>
      <Iconify color="red" icon="fluent:delete-12-regular" />
    </Button>
  );
};

const EditButton = ({ onEditClick }) => {
  return (
    <Button onClick={onEditClick}>
      <EditIcon color="primary" style={{ fontSize: '18px' }} />
    </Button>
  );
};