import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

function useLeagueList() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [leagueList, setLeague] = useState([]);

  async function getLeague() {
    try {
      setLoading(true);

      // Check if a city with the same name already exists

      // If the city name is unique, Load the city
      // const response = await API.graphql({
      //     query: queries.listCities,
      //     authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      const response = await API.graphql(graphqlOperation(queries.listLeagues));
      // Check if the city was successfully created
      const data = response?.data?.listLeagues?.items;
      setLeague(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);

      throw error;
    }
  }
  useEffect(() => {
    getLeague();
  }, []);

  return { leagueList, loading, error, getLeague };
}

export default useLeagueList;
