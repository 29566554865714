import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
import {
  CREATE_CAMPCARD_REQUEST,
  CREATE_CAMPCARD_SUCCESS,
  CREATE_CAMPCARD_FAIL,
  UPDATE_CAMPCARD_REQUEST,
  UPDATE_CAMPCARD_SUCCESS,
  UPDATE_CAMPCARD_FAIL,
  DELETE_CAMPCARD_REQUEST,
  DELETE_CAMPCARD_SUCCESS,
  DELETE_CAMPCARD_FAIL,
  GET_CAMPCARD_REQUEST,
  GET_CAMPCARD_SUCCESS,
  GET_CAMPCARD_FAIL,
  CLEAR_CAMPCARD_ERRORS,
  ALL_CAMPCARD_REQUEST,
  ALL_CAMPCARD_SUCCESS,
  ALL_CAMPCARD_FAIL,
} from '../constants/CampCardsConstants';

export const getAllCampCards = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_CAMPCARD_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listCampCards));

    const updatedCampCards = response.data.listCampCards.items || [];

    const updatedData = {
      campCards: updatedCampCards,
      campCardsCount: updatedCampCards?.length,
      resultPerPage: 10,
      filteredCampCardsCount: null,
    };

    dispatch({
      type: ALL_CAMPCARD_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_CAMPCARD_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getCampCardDetails = (campCardId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CAMPCARD_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getCampCard, { id: campCardId }));

    const newData = response?.data.getCampCard;
    dispatch({
      type: GET_CAMPCARD_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_CAMPCARD_FAIL,
      payload: errorMessage,
    });
  }
};

export const createCampCard = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CAMPCARD_REQUEST });

    const response = await API.graphql({
      query: mutations.createCampCard,

      variables: {
        input: {
          ...formData,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createCampCard;
    toast.success('Training Program Card Added Successfully!');

    dispatch({
      type: CREATE_CAMPCARD_SUCCESS,
      payload: data,
    });
    toast.success('Created!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('CAMPCARD error', error);

    dispatch({
      type: CREATE_CAMPCARD_FAIL,
      payload: error,
    });
  }
};
export const deleteCampCard = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CAMPCARD_REQUEST });

    API.graphql({
      query: mutations.deleteCampCard,
      variables: { input: { id } },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then(async (response) => {
      toast.success('Training Program Card Deleted Successfully!');

      console.log(response);
      dispatch({
        type: DELETE_CAMPCARD_SUCCESS,
        payload: true,
      });
    });

    toast.success('Deleted!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_CAMPCARD_FAIL,
      payload: error || 'Failed to fetch Camp CARD data',
    });
  }
};
export const updateCampCard = (campCardId, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CAMPCARD_REQUEST });

    const data = {
      title: formData?.title,
      price: formData?.price,
      description: formData?.description,
    };

    const response = await API.graphql({
      query: mutations.updateCampCard,
      variables: { input: { ...data, id: campCardId } },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const updatedDetails = response?.data?.updateCampCard;
    toast.success('Training Program Card Updated Successfully!');
    dispatch({
      type: UPDATE_CAMPCARD_SUCCESS,
      payload: updatedDetails,
    });
    toast.success('Updated!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.error('Error updating CAMPCARD:', error);

    dispatch({
      type: UPDATE_CAMPCARD_FAIL,
      payload: error || 'Failed to update CAMPCARD',
    });
  }
};
export const clearCampCardsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CAMPCARD_ERRORS });
};
