import { useState, useEffect } from 'react';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries'; // Import your GraphQL queries

const useGetAllCategory = () => {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const list = async () => {
    setLoading(true);
    // API.graphql({
    //     query: queries.listCategories,
    //     authMode: GRAPHQL_AUTH_MODE.API_KEY
    // }).then((response) => {
    //     setCategory(response?.data?.listCategories?.items);
    // });

    const response = await API.graphql(graphqlOperation(queries.listCategories));
    setCategory(response?.data?.listCategories?.items);
    setLoading(false);
  };
  useEffect(() => {
    list();
  }, []);

  return { category, list, loading };
};

export default useGetAllCategory;
