export const GET_CAMPCOURSE_REQUEST = 'GET_CAMPCOURSE_REQUEST';
export const GET_CAMPCOURSE_SUCCESS = 'GET_CAMPCOURSE_SUCCESS';
export const GET_CAMPCOURSE_FAIL = 'GET_CAMPCOURSE_FAIL';


export const ALL_CAMPCOURSE_REQUEST = 'ALL_CAMPCOURSE_REQUEST';
export const ALL_CAMPCOURSE_SUCCESS = 'ALL_CAMPCOURSE_SUCCESS';
export const ALL_CAMPCOURSE_FAIL = 'ALL_CAMPCOURSE_FAIL';

export const CREATE_CAMPCOURSE_REQUEST = 'CREATE_CAMPCOURSE_REQUEST';
export const CREATE_CAMPCOURSE_SUCCESS = 'CREATE_CAMPCOURSE_SUCCESS';
export const CREATE_CAMPCOURSE_FAIL = 'CREATE_CAMPCOURSE_FAIL';

export const UPDATE_CAMPCOURSE_REQUEST = 'UPDATE_CAMPCOURSE_REQUEST';
export const UPDATE_CAMPCOURSE_SUCCESS = 'UPDATE_CAMPCOURSE_SUCCESS';
export const UPDATE_CAMPCOURSE_FAIL = 'UPDATE_CAMPCOURSE_FAIL';


export const DELETE_CAMPCOURSE_REQUEST = 'DELETE_CAMPCOURSE_REQUEST';
export const DELETE_CAMPCOURSE_SUCCESS = 'DELETE_CAMPCOURSE_SUCCESS';
export const DELETE_CAMPCOURSE_FAIL = 'DELETE_CAMPCOURSE_FAIL';
export const DELETE_CAMPCOURSE_RESET = 'DELETE_CAMPCOURSE_RESET';

export const CLEAR_CAMPCOURSE_ERRORS = 'CLEAR_CAMPCOURSE_ERRORS';