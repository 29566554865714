import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Box } from  '@mui/material';
import Tournament from '../components/signup/Tournament';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';

const EditTournament = () => {
  return (
    <Box display="flex" mb={5}>
      <DashboardLayout />
      <Box mt={10} width="100%">
        <Tournament/>
      </Box>
    </Box>
  );
};

export default EditTournament;
