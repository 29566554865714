import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Button, Stack, Typography } from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainSkeleton from '../components/SkeletonLoader';

import { NewsSort, NewsList, NewsCartWidget, NewsFilterSidebar, AddNewsSideBar } from '../sections/@dashboard/news';
import Loader from '../components/Loader';
import useGetTeams from '../hooks/getTeamsList';
import useNewsList from '../hooks/getAllNews';
import { clearNewsErrors, getAllNews } from '../redux/actions/NewsAction';
import useHandleNews from '../hooks/handlePaginationNews';

// ----------------------------------------------------------------------

export default function NewsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    newsList,
    nextNextToken,
    fetchNews,
    createNews,
    updateNewsDetails,
    loading,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
    deleteNewsContent,
  } = useHandleNews();

  const [news, setNews] = useState(newsList);
  console.log('newsList: ', newsList);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleOpenEvent = () => {
    setOpenEvent(true);
  };
  const handleCloseEvent = () => {
    setOpenEvent(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    setNews(newsList || []);
  }, [newsList]);

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: News | Minimal UI </title>
      </Helmet>

      <Container>
        <ToastContainer />
        <Typography variant="h4" sx={{ mb: 5 }}>
          News
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            {/* <NewsFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <NewsSort />
            <br/> */}
            <Link to={'/create-new'}>
              <AddNewsSideBar
                openEvent={openEvent}
                onCloseEvent={handleCloseEvent}
                onOpenEvent={handleOpenEvent}
                fetchLeagues={getAllNews}
                setLoading={setIsLoading}
                setNewsList={setNews}
                news={news}
                createNews={createNews}
              />
            </Link>
          </Stack>
        </Stack>
        {loading || isLoading ? (
          <>
            <MainSkeleton />
          </>
        ) : (
          <NewsList
            deleteNews={deleteNewsContent}
            news={news}
            updateNewsDetails={updateNewsDetails}
            setNewsList={setNews}
            fetchLeagues={getAllNews}
          />
        )}
        {/* <NewsCartWidget /> */}
        {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
            le
          >
            {fetchPreviousPage && previousTokens?.length > 0  && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}
