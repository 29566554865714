import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { toast } from 'react-toastify';
import * as queries from "../../graphql/queries"; // Import your GraphQL queries
import * as mutations from "../../graphql/mutations"; // Import your GraphQL queries
import {
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAIL,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAIL,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAIL,
  CLEAR_ACTIVITY_ERRORS,
  ALL_ACTIVITY_REQUEST,
  ALL_ACTIVITY_SUCCESS,
  ALL_ACTIVITY_FAIL,
} from '../constants/ActivitiesConstants';

export const getAllActivities = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_ACTIVITY_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listActivities));

    const updatedActivities = response.data.listActivities.items || [];

    const updatedData = {
      tournamentActivities: updatedActivities,
      tournamentActivitiesCount: updatedActivities?.length,
      resultPerPage: 10,
      filteredActivitiesCount: null,
    };

    dispatch({
      type: ALL_ACTIVITY_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_ACTIVITY_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const clearActivitiesErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ACTIVITY_ERRORS });
};
