import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Avatar, Box, TableCell, TableRow, TextField, Typography } from '@mui/material';

const PlayerScore = ({ player, disabled, onChange }) => {
  const [stats, setStats] = useState({
    GP: '0',
    MIN: '0',
    PTS: '0',
    FGM: '0',
    FGA: '0',
    FGPERCENT: '0',
    THREEPM: '0',
    TDTHREE: '0',
    THREEPA: '0',
    THREEPPERCENT: '0',
    FTM: '0',
    FTA: '0',
    FTPERCENT: '0',
    OREB: '0',
    DREB: '0',
    REB: '0',
    AST: '0',
    TOV: '0',
    STL: '0',
    BLK: '0',
    PF: '0',
    FP: '0',
    DDTWO: '0',
    PlusMinus: '0',
  });

  useEffect(() => {
    const newStats = {
      GP: player?.GP || '0',
      MIN: player?.MIN || '0',
      TDTHREE: player?.TDTHREE || '0',
      PTS: player?.PTS || '0',
      FGM: player?.FGM || '0',
      FGA: player?.FGA || '0',
      FGPERCENT: player?.FGPERCENT || '0',
      THREEPM: player?.THREEPM || '0',
      THREEPA: player?.THREEPA || '0',
      THREEPPERCENT: player?.THREEPPERCENT || '0',
      FTM: player?.FTM || '0',
      FTA: player?.FTA || '0',
      FTPERCENT: player?.FTPERCENT || '0',
      OREB: player?.OREB || '0',
      DREB: player?.DREB || '0',
      REB: player?.REB || '0',
      AST: player?.AST || '0',
      TOV: player?.TOV || '0',
      STL: player?.STL || '0',
      BLK: player?.BLK || '0',
      PF: player?.PF || '0',
      FP: player?.FP || '0',
      DDTWO: player?.DDTWO || '0',
      PlusMinus: player?.PlusMinus || '0',
    };
    setStats(newStats);
  }, [player]);

  const handleStateChange = (field, value) => {
    const newStats = { ...stats, [field]: value };
    setStats(newStats);
    onChange(field, value); // Call the onChange function with the field name and the new value
  }; return (
    <div className="flex" style={{ background: 'white', padding: '1px' }}>
      <div
        style={{
          whiteSpace: 'nowrap',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontWeight: 'bold',
          color: 'gray',
          backgroundColor: 'white',
          paddingLeft: '1px',
          paddingRight: '1px',
          paddingTop: '1px',
          paddingBottom: '1px',
          width: '120px',
        }}
      >
        <Box display="flex" justifyContent="center" width={50} height={50} borderRadius="50%" overflow="hidden">
          <Avatar alt={player?.playerName} src={player?.playerImage} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
        <Typography>{player?.playerName.slice(0, 10)}</Typography>
      </div>
      {Object.entries(stats).map(([field, value]) => (
        <div key={field} style={{ padding: '1px', paddingLeft: '1px', paddingRight: '1px' }}>
          <input
            className={`rounded-md  h-full border border p-5 ${field === 'THREEPPERCENT' ? 'w-[150px]' : 'w-[120px] '}`}
            type="text"
            value={value}
            disabled={disabled}
            onChange={(e) => handleStateChange(field, e.target.value)}
          />
        </div>

      ))}
    </div>
  );
};

export default PlayerScore;
