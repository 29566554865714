import { API, graphqlOperation } from 'aws-amplify';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
// Import your GraphQL queries

import {
  CREATE_NEWS_REQUEST,
  CREATE_NEWS_SUCCESS,
  CREATE_NEWS_FAIL,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  LIST_NEWS_REQUEST,
  LIST_NEWS_SUCCESS,
  LIST_NEWS_FAIL,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  DELETE_NEWS_RESET,
  CLEAR_NEWS_ERRORS,
  UPDATE_NEWS_VIEWS_REQUEST,
  UPDATE_NEWS_VIEWS_SUCCESS,
  UPDATE_NEWS_VIEWS_FAIL,
} from '../constants/NewsConstants';
import getLinks from '../../utils/getLinks';

export const getAllNews = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_NEWS_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.listNews));
    const newsItems = response?.data?.listNews?.items || [];

    const updatedData = await Promise.all(
      newsItems.map(async (news) => {
        try {
          const signedUrl = await getLinks(news?.content_img_url);
          const signedUrl2 = await getLinks(news?.img_url);
          const videLink = await getLinks(news?.video_url);

          return {
            ...news,
            render_img_url: signedUrl,
            render_content_img_url: signedUrl2,
            render_video_url: videLink,
          };
        } catch (error) {
          console.error('Error fetching image URL', error);
          return news;
        }
      })
    );

    const news = updatedData.filter((item) => item.type === 'NEWS');
    const blog = updatedData.filter((item) => item.type === 'BLOG');
    const vlog = updatedData.filter((item) => item.type === 'VLOG');

    const updated = {
      news,
      blog,
      vlog,
      newsCount: news.length,
      resultPerPage: 10,
      filteredEventsCount: null,
    };

    console.log(updatedData);
    dispatch({
      type: LIST_NEWS_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    dispatch({
      type: LIST_NEWS_FAIL,
      payload: error.message || 'Failed to fetch news data',
    });
  }
};

export const getNewsDetails = (newsId) => async (dispatch) => {
  try {
    dispatch({ type: GET_NEWS_REQUEST });

    const newsResponse = await API.graphql({
      query: queries.getNews,
      variables: {
        id: newsId,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const newsData = newsResponse?.data?.getNews;

    const signedUrl = await getLinks(newsData?.content_img_url);
    const signedUrl2 = await getLinks(newsData?.img_url);
    const videLink = await getLinks(newsData?.video_url);

    const updatedData = {
      ...newsData,
      img_url: signedUrl,
      content_img_url: signedUrl2,
      video_url: videLink,
    };

    const updateViewCountInput = {
      id: newsId,
      view_count: updatedData?.view_count + 1,
    };

    await API.graphql({
      query: mutations.updateNews,
      variables: {
        input: updateViewCountInput,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const data = {
      newsDetails: updatedData,
      newsViews: updatedData?.view_count,
    };

    dispatch({
      type: GET_NEWS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch news details';

    dispatch({
      type: GET_NEWS_FAIL,
      payload: errorMessage,
    });
  }
};

// add a new product
// export const addProduct = (productData,images) => async (dispatch) => {
//     try {
//       dispatch({ type: ADD_PRODUCT_REQUEST });

//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       // const config = { headers: { "Content-Type": "multipart/form-data" } };

//       const { data } = await axios.post("/api/v1/admin/new/product", productData, config);

//       dispatch({
//         type: ADD_PRODUCT_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       const errorMessage =
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message;

//       dispatch({
//         type: ADD_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const updateProduct = (productId, productData) => async (dispatch) => {
//     try {
//       dispatch({ type: UPDATE_PRODUCT_REQUEST });

//       const config = {
//         headers: { "Content-Type": "application/json" },
//       };

//       const { data } = await axios.put(
//         `/api/v1/admin/product/${productId}`,
//         productData,
//         config
//       );

//       dispatch({
//         type: UPDATE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: UPDATE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const deleteProduct = (productId) => async (dispatch) => {
//     try {
//       dispatch({ type: DELETE_PRODUCT_REQUEST });

//       const { data } = await axios.delete(`/api/v1/admin/product/${productId}`);

//       dispatch({
//         type: DELETE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: DELETE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

export const clearNewsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_NEWS_ERRORS });
};
