

export const GET_SESSION_REQUEST = 'GET_SESSION_REQUEST';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAIL = 'GET_SESSION_FAIL';


export const ALL_SESSION_REQUEST = 'ALL_SESSION_REQUEST';
export const ALL_SESSION_SUCCESS = 'ALL_SESSION_SUCCESS';
export const ALL_SESSION_FAIL = 'ALL_SESSION_FAIL';

export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAIL = 'CREATE_SESSION_FAIL';

export const UPDATE_SESSION_REQUEST = 'UPDATE_SESSION_REQUEST';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export const UPDATE_SESSION_FAIL = 'UPDATE_SESSION_FAIL';


export const DELETE_SESSION_REQUEST = 'DELETE_SESSION_REQUEST';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAIL = 'DELETE_SESSION_FAIL';
export const DELETE_SESSION_RESET = 'DELETE_SESSION_RESET';

export const CLEAR_SESSION_ERRORS = 'CLEAR_SESSION_ERRORS';
