// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// const navConfig = [
//   {
//     title: 'dashboard',
//     path: '/dashboard/app',
//     icon: icon('ic_analytics'),
//   },
//   {
//     title: 'all users',
//     path: '/dashboard/all-users',
//     icon: icon('ic_user'),
//   },
//   {
//     title: 'user',
//     path: '/dashboard/user',
//     icon: icon('ic_user'),
//   },
//   {
//     title: 'player verifications',
//     path: '/dashboard/verifications',
//     icon: icon('ic_verification'),
//   },
//   {
//     title: 'tournament verifications',
//     path: '/dashboard/tournament-verifications',
//     icon: icon('ic_verification'),
//   },
//   {
//     title: 'newsletter-listing',
//     path: '/dashboard/newsletter-listing',
//     icon: icon('ic_newsletter'),
//   },
//   {
//     title: 'event1',
//     path: '/dashboard/events',
//     icon: icon('ic_event'),
//   },
//   {
//     title: 'event2',
//     path: '/dashboard/event2',
//     icon: icon('ic_event'),
//   },
//   {
//     title: 'session',
//     path: '/dashboard/session',
//     icon: icon('ic_event'),
//   },
//   {
//     title: 'product',
//     path: '/dashboard/products',
//     icon: icon('ic_cart'),
//   },
//   {
//     title: 'categories',
//     path: '/dashboard/categories',
//     icon: icon('ic_category'),
//   },
//   {
//     title: 'clinic card',
//     path: '/dashboard/clinic-cards',
//     icon: icon('ic_cliniccard'),
//   },
//   {
//     title: 'training program card',
//     path: '/dashboard/training-program-cards',
//     icon: icon('ic_cliniccard'),
//   },
//   {
//     title: 'training program course',
//     path: '/dashboard/training-program-courses',
//     icon: icon('ic_cliniccard'),
//   },
//   {
//     title: 'add price',
//     path: '/dashboard/add-prices',
//     icon: icon('ic_price'),
//   },
//   {
//     title: 'tornament teams',
//     path: '/dashboard/tournament-teams',
//     icon: icon('ic_details'),

//   },
//   {
//     title: 'tornament events',
//     path: '/dashboard/tournament-events',
//     icon: icon('ic_details'),

//   },
//   {
//     title: 'tournament event2',
//     path: '/dashboard/tournament-event2',
//     icon: icon('ic_event'),
//   },
//   {
//     title: 'tournament score',
//     path: '/dashboard/tournament-score',
//     icon: icon('ic_event'),
//   },
//   {
//     title: 'advertisement',
//     path: '/dashboard/advertisement',
//     icon: icon('ic_ad'),
//   },
//   {
//     title: 'orders',
//     path: '/dashboard/orders',
//     icon: icon('ic_order'),
//   },
//   {
//     title: 'people',
//     path: '/dashboard/people',
//     icon: icon('ic_details'),
//   },
//    {
//     title: 'scores',
//     path: '/dashboard/scores',
//     icon: icon('ic_score'),
//   },
//   // {
//   //   title: 'Add News,Articles,Blogs',
//   //   path: '/dashboard/products',
//   //   icon: icon('ic_cart'),
//   // },
//     {
//     title: 'news',
//     path: '/dashboard/news',
//     icon: icon('ic_news'),
//   },
//   {
//     title: 'add-details',
//     path: '/dashboard/add-details',
//     icon: icon('ic_details'),

//   },
//   {
//     title: 'coupon',
//     path: '/dashboard/coupon',
//     icon: icon('ic_coupon'),

//   },

// ];
const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'users',
    icon: icon('ic_user'),
    subMenu: [
      { title: 'all users', path: '/dashboard/all-users', icon: icon('ic_user') },
      { title: 'user', path: '/dashboard/user', icon: icon('ic_user') },
    ],
  },
  {
    title: 'verifications',
    icon: icon('ic_verification'),
    subMenu: [
      { title: 'Verification 3x3 Recreational', path: '/dashboard/verifications', icon: icon('ic_verification') },
      { title: 'Verification 5x5 Recreational', path: '/dashboard/5x5verifications', icon: icon('ic_verification') },
      { title: 'tournament verifications', path: '/dashboard/tournament-verifications', icon: icon('ic_verification') },
    ],
  },
      { title: 'players', path: '/dashboard/players', icon: icon('ic_user') },

  {
    title: 'people',
    icon: icon('ic_people'),
    subMenu: [
      {
        title: 'Email Lists',
        path: '/dashboard/newsletter-listing',
        icon: icon('ic_newsletter'),
      },
      {
        title: 'people',
        path: '/dashboard/people',
        icon: icon('ic_people'),
      },
    ],
  },
  
  {
    title: 'leagues 3x3',
    icon: icon('ic_player'),
    subMenu: [
      { title: 'jersey details', path: '/dashboard/league-jersey-details', icon: icon('ic_details') },
      { title: 'season 1', path: '/dashboard/events', icon: icon('ic_event') },
      { title: 'season 2', path: '/dashboard/season2', icon: icon('ic_event') },
      {
        title: 'scores',
        path: '/dashboard/scores',
        icon: icon('ic_score'),
      },
      {
        title: 'add team city',
        path: '/dashboard/add-details',
        icon: icon('ic_details'),
      },

    ],
  },

  {
    title: 'leagues 5x5',
    icon: icon('ic_player'),
    subMenu: [
      { title: 'jersey details', path: '/dashboard/5x5league-jersey-details', icon: icon('ic_details') },
      { title: 'season 1', path: '/dashboard/5x5events', icon: icon('ic_event') },
      { title: 'season 2', path: '/dashboard/5x5season2', icon: icon('ic_event') },
      {
        title: 'scores',
        path: '/dashboard/5x5scores',
        icon: icon('ic_score'),
      },
      {
        title: 'add team',
        path: '/dashboard/5x5add-details',
        icon: icon('ic_details'),
      },

    ],
  },
  {
    title: 'tournament',
    icon: icon('ic_tournament'),
    subMenu: [
      { title: 'tournament details', path: '/dashboard/tournament-details', icon: icon('ic_details') },
      { title: 'jersey details', path: '/dashboard/tournament-jersey-details', icon: icon('ic_details') },
      { title: 'tournament teams', path: '/dashboard/tournament-teams', icon: icon('ic_team') },
      { title: 'season 1', path: '/dashboard/tournament-events', icon: icon('ic_event') },
      // { title: 'tournament event2', path: '/dashboard/tournament-event2', icon: icon('ic_event') },
      { title: 'tournament score', path: '/dashboard/tournament-score', icon: icon('ic_score') },
    { title: 'add price', path: '/dashboard/add-prices', icon: icon('ic_price') },
   
    ],
  },
  {
    title: 'comptetive 3x3',
    icon: icon('ic_tournament'),
    subMenu: [
      { title: 'comptetive coach', path: '/dashboard/comptetive-coach', icon: icon('ic_details') },
      { title: 'comptetive teams', path: '/dashboard/comptetive', icon: icon('ic_team') },
      { title: 'invite player', path: '/dashboard/comptetive/invite', icon: icon('ic_details') },
      { title: 'player details', path: '/dashboard/comptetive/players', icon: icon('ic_details') },
      { title: 'invited comptetive users', path: '/dashboard/comptetive/invited-players', icon: icon('ic_details') },
      { title: 'Not Invited', path: '/dashboard/comptetive/not-invited', icon: icon('ic_details') },
    //   { title: 'season 1', path: '/dashboard/tournament-events', icon: icon('ic_event') },
    //   // { title: 'tournament event2', path: '/dashboard/tournament-event2', icon: icon('ic_event') },
    //   { title: 'tournament score', path: '/dashboard/tournament-score', icon: icon('ic_score') },
    // { title: 'add price', path: '/dashboard/add-prices', icon: icon('ic_price') },
   
    ],
  },
  {
    title: 'comptetive 5x5',
    icon: icon('ic_tournament'),
    subMenu: [
      { title: 'comptetive coach', path: '/dashboard/5x5comptetive-coach', icon: icon('ic_details') },
      { title: 'comptetive teams', path: '/dashboard/5x5comptetive', icon: icon('ic_team') },
      { title: 'schedule', path: '/dashboard/schedule', icon: icon('ic_team') },
      { title: 'invite player', path: '/dashboard/5x5comptetive/invite', icon: icon('ic_details') },
      { title: 'player details', path: '/dashboard/5x5comptetive/players', icon: icon('ic_details') },
      { title: 'invited comptetive users', path: '/dashboard/5x5comptetive/invited-players', icon: icon('ic_details') },
      { title: 'Not Invited', path: '/dashboard/5x5comptetive/not-invited', icon: icon('ic_details') },
   
    ],
  },
  {
    title: 'mock data',
    path: '/dashboard/mock-data',
    icon: icon('ic_details'),
  },
  {
    title: 'training program',
    icon: icon('ic_training_program'),
    subMenu: [
      { title: 'training program card', path: '/dashboard/training-program-cards', icon: icon('ic_cliniccard') },
      { title: 'training program course', path: '/dashboard/training-program-courses', icon: icon('ic_cliniccard') },
      {
        title: 'Meet',
        path: '/dashboard/schedule-meet',
        icon: icon('ic_calendar'),
      },
      {
        title: 'User Meet',
        path: '/dashboard/appointments/training-program',
        icon: icon('ic_calendar'),
      },
    ],
  },
 
  
  {
    title: 'clinics',
    icon: icon('ic_clinic'),
    subMenu: [
      { title: 'clinic card', path: '/dashboard/clinic-cards', icon: icon('ic_cliniccard') },
       { title: 'clinic course', path: '/dashboard/clinic-courses', icon: icon('ic_cliniccard') },
      {
        title: 'Meet',
        path: '/dashboard/schedule-clinic-meet',
        icon: icon('ic_calendar'),
      },
      {
        title: 'User Meet',
        path: '/dashboard/appointments/clinic',
        icon: icon('ic_calendar'),
      },
    ],
  },
  {
    title: 'Tutor',
    path: '/dashboard/tutor',
    icon: icon('ic_details'),
  },
  {
    title: 'camp',
    icon: icon('ic_training_program'),
    subMenu: [
      { title: 'camp details', path: '/dashboard/camp-details', icon: icon('ic_details') },
      { title: 'camps', path: '/dashboard/camps', icon: icon('ic_event') },
      { title: 'camp card', path: '/dashboard/camp-cards', icon: icon('ic_cliniccard') },
      { title: 'camp course', path: '/dashboard/camp-courses', icon: icon('ic_cliniccard') },
      {
        title: 'Meet',
          path: '/dashboard/schedule-camp-meet',
        icon: icon('ic_calendar'),
      },
      {
        title: 'User Meet',
        path: '/dashboard/appointments/camp',
        icon: icon('ic_calendar'),
      },
    ],
  },
  { title: 'activity', path: '/dashboard/activities', icon: icon('ic_player') },


 
  { title: 'session', path: '/dashboard/session', icon: icon('ic_event') },
 
 {
    title: 'advertisement',
    path: '/dashboard/advertisement',
    icon: icon('ic_ad'),
  },
 
  {
    title: 'news',
    path: '/dashboard/news',
    icon: icon('ic_news'),
  },
  {
    title: 'products',
    icon: icon('ic_cart'),
    subMenu: [
      { title: 'products', path: '/dashboard/products', icon: icon('ic_cart') },
      { title: 'categories', path: '/dashboard/categories', icon: icon('ic_category') },
      {
        title: 'orders',
        path: '/dashboard/orders',
        icon: icon('ic_order'),
      },
    ],
  },
  {
    title: 'coupon',
    path: '/dashboard/coupon',
    icon: icon('ic_coupon'),
  },
  {
    title: 'carousel',
    path: '/dashboard/carousel',
    icon: icon('ic_image'),
  },

];

export default navConfig;
