/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL mutations

const useHandleEvent = () => {
  const [eventDetails, setEvent] = useState();
  const [eventList, setEventList] = useState();

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchEvents = async (titleFilter, agegroupFilter) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: queries.listTournamentLeagues, // Replace with the appropriate GraphQL query for listing teams
        variables: {
          filter: {
            title: { eq: titleFilter }, // Check if the team name already exists
            agegroup: { eq: agegroupFilter }, // Check if the team name already exists
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const eventData = response?.data?.listTournamentLeagues?.items;
      console.log('event s data ', eventData);
      setEventList(eventData);
      setLoading(false);
      return eventData;
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };

  const fetchEventsByAge = async (ageFilter) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: queries.listTournamentLeagues, // Replace with the appropriate GraphQL query for listing teams
        variables: {
          filter: {
            agegroup: { eq: ageFilter }, // Check if the team name already exists
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const eventData = response?.data?.listTournamentLeagues?.items;
      const updatedEventData = eventData?.map((event) => {
        const updatedTeamsName = event?.teamsName?.map(({ __typename, ...rest }) => rest);
        const updatedTeamsId = event?.teamsId?.map(({ __typename, ...rest }) => rest);

        return {
          ...event,
          teamsName: updatedTeamsName,
          teamsId: updatedTeamsId,
        };
      });

      setEventList(updatedEventData);
      setLoading(false);
      return eventData;
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };

  const fetchEventById = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.getTournamentLeague, { id }));
      const data = response?.data?.getTournamentLeague;
      setEvent(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching event details:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  const deleteEventById = async (idToDelete) => {
    try {
      setLoading(true);

      const idDelete = id || idToDelete;

      const response = await API.graphql({
        query: mutations.deleteTournamentLeague,
        variables: { input: { id: idToDelete } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      setEvent(null);
      setLoading(false);
      toast.success('Camp Data Deleted Successfully!');

      setEvent([]);
      setLoading(false);
      navigate('/dashboard/event2');
    } catch (error) {
      console.error('Error deleting event:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  const updateEventById = async (data) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.updateTournamentLeague,
        variables: { input: { id, ...data } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedData = response?.data?.updateTournamentLeague;

      setEventList((prevList) => prevList.map((user) => (user.id === updatedData.id ? updatedData : user)));

      setEvent(updatedData);
      setLoading(false);
      toast.success('Camp Data Updated Successfully!');
    } catch (error) {
      console.error('Error updating event:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  useEffect(() => {
    if (id) {
      fetchEventById();
    }
    fetchEvents();
  }, [id]);

  return { eventDetails, fetchEventsByAge, eventList, fetchEvents, loading, deleteEventById, updateEventById };
};

export default useHandleEvent;
