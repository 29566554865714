import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import SessionCard from './SessionCard';
import Loader from '../../../components/Loader';

// ----------------------------------------------------------------------

SessionList.propTypes = {
  sessions: PropTypes.array.isRequired,
};


  export default function SessionList({ sessions,loading, updateSession, deleteSession, ...other }) {
 
  return (
    <Grid container spacing={3} {...other}>
      {sessions?.map((sessions) => (
        <Grid key={sessions?.id} item xs={12} sm={6} md={3}>
         
          <SessionCard 
          sessions={sessions}
          updateSession={updateSession}
          deleteSession={deleteSession}  />
        </Grid>
      ))}
    </Grid>
  );
}
