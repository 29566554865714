import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter, update } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { useNavigate } from 'react-router-dom';
// Custom hooks
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import useHandlePagintationEvents from '../hooks/handlePaginationEvents';
import Loader from '../components/Loader';
import useHandleEvent from '../hooks/handleEvent2';
import useCityList from '../hooks/getCityList';
import Iconify from '../components/iconify';
import useGetTeams from '../hooks/getTeamsList';

const Event2 = () => {
  const { eventList, fetchEventsByLocation, fetchEvents, loading } = useHandleEvent();

  const [updating, setUpdating] = useState(false);
  const [formVisible, setFormVisible] = useState(false); // To control form visibility
  const [selectedEvent, setSelectedEvent] = useState('');
  const [locationEvents, setLocationEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState({});
  const [teamData, setTeamData] = useState([{}]);
  const [teamIds, setTeamids] = useState([{}]);

  const [location, setLocation] = useState('');
  const [newTeam, setNewTeam] = useState({ firstTeam: '', secondTeam: '' });

  const [open, setOpen] = useState(null);
  const { cities } = useCityList();

  const [selected, setSelected] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  // const [teams, setTeams] = useState([{ team1: '', team2: '' }]);

  const [orderBy, setOrderBy] = useState('name');

  const [selectedRowId, setSelectedRowId] = useState(null); // Add a state to store the selected row's id
  const { teams } = useGetTeams();
  const { loading: updateEventLoading, updateEvents } = useHandlePagintationEvents();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const handleNewTeam = (e, first) => {
    let data;
    if (first) {
      data = {
        firstTeam: e.target.value,
        secondTeam: newTeam.secondTeam,
      };
      setNewTeam(data);
    } else {
      data = {
        firstTeam: newTeam.firstTeam,
        secondTeam: e.target.value,
      };
      setNewTeam(data);
    }
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleOpenUser = () => {
    setOpenUser(true);
  };
  const handleCloseUser = () => {
    setOpenUser(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenMenu = (event, id, enabled) => {
    // Pass the id when opening the menu
    setSelectedRowId(id);
    setEnabled(enabled);
    // Store the selected row's id
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEditNavigate = () => {
    navigate(`/edit-event/${selectedRowId}`); // Use the selected row's id
    handleCloseMenu(); // Close the menu
  };

  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  const removeButtonStyles = {
    backgroundColor: '#FF0000', // Set to your desired hex code for red
    color: 'white', // Set your desired text color
  };

  const handleSelect = async (event) => {
    setSelectedEvent(event.target.value);
  };
  const handleLocationChange = async (e) => {
    setLocation(e.target.value);
    const data = await fetchEventsByLocation(e.target.value);
    setLocationEvents(data);
  };
  const handleSubmit = async () => {
    try {
      // const data = await fetchEvents(selectedEvent, location);
      // setAllEvents(data);
      const eventData = locationEvents?.filter((event) => event.title === selectedEvent);

      setCurrentEvents(eventData[0]);
      const data = eventData[0].teamsName;
      console.log('data', teamData[0]);
      if (data != null) {
        console.log('data1', teamData[0]);

        setTeamData(eventData[0]?.teamsName);
      }
      setFormVisible(true);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleTeamChange = (index, fieldName, e) => {
    // Assuming eventData is defined and has the same structure as teamData
    const eventData = [...teamData];

    let secondTeam = eventData[index]?.secondTeam; // Make sure secondTeam is defined
    let firstTeam = eventData[index]?.firstTeam;
    if (fieldName) {
      firstTeam = e.target.value;
    } else secondTeam = e.target.value;
    const update = {
      firstTeam,
      secondTeam,
    };

    eventData[index] = update;
    console.log('EventData', eventData);
    setTeamData(eventData);
    // setCurrentEvents(); // Unclear what this function is supposed to do
  };

  // const handleAddMore = () => {
  //   // setTeams([...teams, { team1: '', team2: '' }]);
  //   console.log('adding new team....');
  //   if (!newTeam || newTeam.firstTeam === '' || newTeam.secondTeam === '') {
  //     toast.error('New Teams Can not be empty');
  //     return;
  //   }
  //   // if (teamData) {
  //   if (Object.keys(teamData[0]).length === 0) {
  //     teamData[0]=newTeam;
  //   } else {
  //     teamData.push(newTeam);
  //   }

  //   setTeamData(teamData);
  //   setNewTeam({ firstTeam: '', secondTeam: '' });
  //   console.log('added....', teamData);
  // };
  const handleAddMore = () => {
    setTeamData([...teamData, { firstTeam: '', secondTeam: '' }]);
  };
  const handleRemove = (index) => {
    const updatedTeamData = teamData.filter((team, i) => i !== index);

    // Set the state with the updated array
    setTeamData(updatedTeamData);
  };
  const handleUpdate = async () => {

    // Create a match or start tournament logic here
    console.log('Starting Tournament:', teamData);
    if (!teamData || teamData.length === 0) {
      toast.error("Teams can't be empty");
      return;
    }

    // Check if any team selections are empty
    const hasEmptySelection = teamData.some(({ firstTeam, secondTeam }) => firstTeam === '' || secondTeam === '');
    if (hasEmptySelection) {
      toast.error('Please select both teams for each match.');
      return;
    }

    const selectedTeams = [];
    for (let i = 0; i < teamData.length; i += 1) {
      const { firstTeam, secondTeam } = teamData[i];

      // Check if either of the teams has already participated in another match
      if (selectedTeams.includes(firstTeam) || selectedTeams.includes(secondTeam)) {
        toast.error('Each team can only participate in one match at a time.');
        return;
      }

      // Add both teams to the list of selected teams
      selectedTeams.push(firstTeam, secondTeam);
    }


    for (let i = 0; i < teamData.length; i += 1) {
      const { firstTeam, secondTeam } = teamData[i];

      // Check if both teams are the same
      if (firstTeam === secondTeam) {
        toast.error('The selected teams cannot be the same.');
        return;
      }
    }


    const data = currentEvents;

    data.teamsName = teamData;
    const updated = await updateEvents(data.id, data);

    // setCurrentEvents()
  };

  if (loading || updateEventLoading) {
    return <Loader />;
  }
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Event 2
      </Typography>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <InputLabel id="location">Location</InputLabel>
            <Select
              labelId="location"
              label="Location"
              name="location"
              fullWidth
              required
              variant="outlined"
              value={location}
              onChange={handleLocationChange}
            >
              {cities?.map((cities) => (
                <MenuItem key={cities?.id} value={cities?.name}>
                  {cities?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <InputLabel id="event">Select Event</InputLabel>
            <Select
              labelId="event"
              label="Select Event"
              name="event"
              value={selectedEvent}
              fullWidth
              variant="outlined"
              onChange={handleSelect}
            >
              <MenuItem value="">Select Event</MenuItem>
              {locationEvents?.map((event) => (
                <MenuItem key={event?.id} value={event?.title}>
                  {event?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          <Button style={buttonStyles} variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>

      {formVisible && (
        <Grid marginTop={'25px'}>
          <ToastContainer />
          <Container>
            {/* Add more button */}
            <Grid container justifyContent="flex-end" alignItems="center" spacing={2} mb={5}>
              <Grid item>
                <Button style={buttonStyles} variant="contained" color="primary" onClick={handleAddMore}>
                  <AddIcon /> Add More
                </Button>
              </Grid>
            </Grid>
            <Card key={0} style={{ marginBottom: '10px', display: 'flex' }} sx={{ gap: '25px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="event">Team One</InputLabel>
                    <Select
                      labelId="Teams"
                      label="Select Teams"
                      name="teams"
                      value={teamData[0]?.firstTeam || ''}
                      fullWidth
                      variant="outlined"
                      onChange={(e) => handleTeamChange(0, true, e)}
                    >
                      <MenuItem value="Teams">Select Teams</MenuItem>
                      {teams?.map((team) => (
                        <MenuItem key={team?.id} value={team?.name}>
                          {team?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="event">Team Two</InputLabel>
                    <Select
                      labelId="Teams"
                      label="Select Teams"
                      name="teams"
                      value={teamData[0]?.secondTeam || ''}
                      fullWidth
                      variant="outlined"
                      onChange={(e) => handleTeamChange(0, false, e)}
                    >
                      <MenuItem value="Teams">Select Teams</MenuItem>
                      {teams?.map((team) => (
                        <MenuItem key={team?.id} value={team?.name}>
                          {team?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
            {/* Additional team selection divs */}
            {teamData.slice(1).map((team, index) => (
              <Card key={index + 1} style={{ marginBottom: '10px', display: 'flex' }} sx={{ gap: '25px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel id={`event-${index}`}>Team One</InputLabel>
                      <Select
                        labelId={`Teams-${index}`}
                        label="Select Teams"
                        name={`teams-${index}`}
                        value={teamData[index + 1]?.firstTeam || ''}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => handleTeamChange(index + 1, true, e)} // Adjust index to match teamData
                      >
                        <MenuItem value={`Teams-${index}`}>Select Teams</MenuItem>
                        {teams?.map((team) => (
                          <MenuItem key={team?.id} value={team?.name}>
                            {team?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel id={`event-${index}`}>Team Two</InputLabel>
                      <Select
                        labelId={`Teams-${index}`}
                        label="Select Teams"
                        name={`teams-${index}`}
                        value={teamData[index + 1]?.secondTeam || ''}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => handleTeamChange(index + 1, false, e)} // Adjust index to match teamData
                      >
                        <MenuItem value={`Teams-${index}`}>Select Teams</MenuItem>
                        {teams?.map((team) => (
                          <MenuItem key={team?.id} value={team?.name}>
                            {team?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: 'red', // Set your desired background color
                    color: 'white', // Set your desired text color
                  }}
                  color="error"
                  onClick={() => handleRemove(index + 1)} // Adjust index to match teamData
                >
                  <CloseIcon />
                </Button>
              </Card>
            ))}

          </Container>

          {/* Update button */}
          <Grid container justifyContent="center" alignItems="center">
            <Button style={buttonStyles} variant="contained" color="primary" onClick={handleUpdate}>
              Update
            </Button>
          </Grid>
        </Grid>
      )}


    </Container>
  );
};

export default Event2;
