import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Button, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import MainSkeleton from '../components/SkeletonLoader';
import {
  CampCardList,
  AddNewCampCardSideBar,
} from '../sections/@dashboard/campcard';
import 'react-toastify/dist/ReactToastify.css';
import handlePaginationCampCards from '../hooks/handlePaginationCampCards';

// ----------------------------------------------------------------------

export default function CampCardsPage() {

  const {
    campCardsList,
    nextNextToken,
    loading,
    deleteCampCards,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
    addCampCard,
    updateCampCards,
  } = handlePaginationCampCards();

  console.log("CampCardList", campCardsList)

  const [openCampCard, setOpenCampCard] = useState(false);
  const [campCards, setCampCards] = useState(campCardsList);


  const handleOpenCampCard = () => {
    setOpenCampCard(true);
  };
  const handleCloseCampCard = () => {
    setOpenCampCard(false);
  };

  useEffect(() => {
    setCampCards(campCardsList || []);
  }, [campCardsList]);

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Camp Cards | Minimal UI </title>
      </Helmet>

      <Container>
        <ToastContainer />
        <Typography variant="h4" sx={{ mb: 5 }}>
          Camp Cards
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <AddNewCampCardSideBar
              openCampCard={openCampCard}
              onCloseCampCard={handleCloseCampCard}
              onOpenCampCard={handleOpenCampCard}
              adddCampCard={addCampCard}
            />
          </Stack>
        </Stack>
        {loading ? (
          <>
            <MainSkeleton />
          </>
        ) : (
          <CampCardList
            campCards={campCards}
            updateCampCards={updateCampCards}
            deleteCampCards={deleteCampCards}
          />
        )}
        {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
            {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}