import {
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAIL,
  UPDATE_SESSION_REQUEST,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAIL,
  DELETE_SESSION_REQUEST,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAIL,
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAIL,
  CLEAR_SESSION_ERRORS,
  ALL_SESSION_REQUEST,
  ALL_SESSION_SUCCESS,
  ALL_SESSION_FAIL,
} from '../constants/SessionsConstants';

const initialState = {
  sessions: [],
  sessionCount: 0,
  resultPerPage: 0,
  session: {},
  loading: false,
  error: null,
  filteredSessionsCount: 0,
};

export const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_SESSION_REQUEST:
    case GET_SESSION_REQUEST:
    case CREATE_SESSION_REQUEST:
    case DELETE_SESSION_REQUEST:
    case UPDATE_SESSION_REQUEST:
      return {
        ...state,
        loading: true,
        sessions: [],
        session: {},
      };
    case ALL_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        sessions: action.payload.sessions,
        sessionCount: action.payload.sessionsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredSessionsCount: action.payload.filteredSessionsCount,
      };
    case CREATE_SESSION_SUCCESS:
    case GET_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        session: action.payload,
      };
    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case GET_SESSION_FAIL:
    case ALL_SESSION_FAIL:
    case CREATE_SESSION_FAIL:
    case UPDATE_SESSION_FAIL:
    case DELETE_SESSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_SESSION_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};



