import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
  Stack,
  Box,
  Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../components/iconify';
import Loader from '../../../components/Loader';
import { getAllActivities } from '../../../redux/actions/ActivitiesAction';

AddNewActivitySideBar.propTypes = {
  openActivity: PropTypes.bool,
  onOpenActivity: PropTypes.func,
  onCloseActivity: PropTypes.func,
};

export default function AddNewActivitySideBar({
  updateActivities,
  hidden,
  adddActivity,
  openActivity,
  onOpenActivity,
  onCloseActivity,
  editData,
  activities,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [order, setOrder] = useState('');
  const [description, setDescription] = useState('');
  const isEditMode = !!editData;

  const navigateToEdit = () => {
    navigate('/edit-activities');
  };

  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      setTitle(editData.title || '');
      setOrder(editData.order || '');
      setDescription(editData?.description || '');
      setPrice(editData.price || '');
    }
  }, [editData, isEditMode]);
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  const handleOrderChange = (event) => {
    setOrder(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleUploadData = async () => {
    const activityData = {
      title,
      price,
      order,
      description,
      id: editData?.id,
    };
    // Handle data upload logic here
    // console.log('Data to upload:', activityData);
    if (isEditMode) {
      const id = activityData?.id;
      // console.log('eventsDtaa',activityData)
      onCloseActivity();
      const updated = await updateActivities(id, activityData);

      if (updated) {
        dispatch(getAllActivities());
      }
    } else {
      const data = {
        ...activityData,
      };

      onCloseActivity();
      const created = await adddActivity(data);
      // Close the modal

      if (created) {
        dispatch(getAllActivities());
      }
    }

    // Reset form fields
    setTitle('');
    setOrder('');
    setPrice('');
    setDescription('');
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={navigateToEdit}
        style={buttonStyles}
        hidden={hidden}
      >
        Add Activity
      </Button>
      <Dialog open={openActivity} onClose={onCloseActivity}>
        <DialogTitle>{isEditMode ? 'Edit Activity' : 'Add New Activity'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new Activity.</DialogContentText>
          <TextField autoFocus margin="dense" label="Title" fullWidth value={title} onChange={handleTitleChange} />
          <TextField autoFocus margin="dense" label="Price" fullWidth value={price} onChange={handlePriceChange} />
          <TextField autoFocus margin="dense" label="Order" fullWidth value={order} onChange={handleOrderChange} />

          <FormControl fullWidth margin="dense">
            <TextField
              type="text"
              label="Description"
              value={description}
              name="description"
              margin="normal"
              fullWidth
              onChange={handleDescriptionChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={onCloseActivity} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
