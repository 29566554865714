/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import {
  Card,
  Box,
  Typography,
  Grid,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'; // Replace with your actual imports

const LoadingSkeleton = () => {
  return (
    <Card>
      {/* <Box sx={{ pt: '100%', position: 'relative' }}> */}
      <div className="relative">
        <div className="animate-pulse bg-gray-200 rounded-md aspect-w-1 aspect-h-1" />
      </div>

      <Stack spacing={2} sx={{ p: 3 }}>
        <div className="animate-pulse bg-gray-200 h-4 w-3/4" />
        <div className="animate-pulse bg-gray-200 h-4 w-1/2" />

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <div className="animate-pulse bg-gray-200 h-4 w-1/4" />
          <div className="animate-pulse bg-gray-200 h-4 w-1/4" />

       
        </Stack>
      </Stack>
      {/* </Box> */}
    </Card>
  );
};

const SkeletonTable = () => {
  return (
    <Grid container flexDirection={"column"} spacing={3}>
        <Grid item  gap={3} >
        <LoadingSkeleton />
        </Grid>
        <Grid item  gap={3} >
        <LoadingSkeleton />
        </Grid>
        <Grid item  gap={3} >
        <LoadingSkeleton />
        </Grid>
        <Grid item  gap={3} >
        <LoadingSkeleton />
        </Grid>
        
    
    </Grid>
  );
};

// Your existing Card component

export default SkeletonTable;
