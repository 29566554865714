import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const useFilteredTournamentData = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [tournamentData, setTournamentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFilteredData = async (event) => {
    try {
      setLoading(true);
      let teams = [];
      let nextToken = null;

      do {
        const responseTeams = await API.graphql({
          query: queries.listTournamentTeams,
          variables: {
            filter: {
              eventId: {
                eq: event,
              },
            },
            nextToken: nextToken,
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          },
        });

        const fetchedTeams = responseTeams.data.listTournamentTeams.items;
        teams = teams.concat(fetchedTeams);
        nextToken = responseTeams.data.listTournamentTeams.nextToken;
      } while (nextToken);
      const players = [];

      teams.map((team) => {
        team.players.items.map((playe) => {
          players.push(playe);
        });
      });
      const tournaments = players || [];

      // Group tournament data by age group and tournament name
      const groupedData = tournaments.reduce((acc, tournament) => {
        const { agegroup, teamName } = tournament;
        const key = `${agegroup}-${teamName}`;

        if (!acc[key]) {
          acc[key] = { agegroup, teamName, participants: [] };
        }

        acc[key].participants.push(tournament.participantName);

        return acc;
      }, {});

      // Convert the grouped data to an array
      const newFilteredData = Object.values(groupedData);

      setFilteredData(newFilteredData);
      setTournamentData(tournaments);
      setLoading(false);
      return newFilteredData;
    } catch (error) {
      setLoading(false);
      console.error('Error fetching filtered data:', error);
      throw error;
    }
  };

  const generateJerseyNumbers = async (event, jerseySize) => {
    try {
      setLoading(true);
      await fetchFilteredData(event);

      const tournamentDataForEvent = tournamentData.filter((tournament) => tournament.tournamentId === event);

      console.log('tournamentDataForEvent', tournamentDataForEvent);

      // Use Promise.all to wait for all tasks to complete
      await Promise.all(
        Object.entries(jerseySize).map(async ([size, range]) => {
          if (range) {
            const [min, max] = range.split('-').map((val) => parseInt(val.trim(), 10));
            const rangeNumbers = Array.from({ length: max - min + 1 }, (_, index) => (min + index).toString());
            const filteredDataForSize = tournamentDataForEvent.filter(
              (tournament) => tournament.jerseySize.toUpperCase() === size
            );

            // Get all existing jersey numbers for the current size
            const existingJerseyNumbers = tournamentDataForEvent
              .filter((tournament) => tournament.jerseySize.toUpperCase() === size && tournament.jerseyNo)
              .map((tournament) => tournament.jerseyNo.toUpperCase().toString());

            console.log('existingJerseyNumbers', existingJerseyNumbers);

            // Filter out rangeNumbers that are already assigned
            const availableJerseyNumbers = rangeNumbers.filter(
              (jerseyNumber) => !existingJerseyNumbers.includes(jerseyNumber)
            );

            // Get records without jersey numbers for the current size
            const recordsWithoutJerseyNumber = tournamentDataForEvent.filter(
              (tournament) => tournament.jerseySize.toUpperCase() === size
            );

            if (filteredDataForSize.length === 0) {
              toast.error(`No records available for size ${size}!`);
            } else if (availableJerseyNumbers.length === 0) {
              toast.error(`All numbers for size ${size} are already taken.`);
            } else {
              // Ensure all updates are completed before proceeding
              await Promise.all(
                recordsWithoutJerseyNumber.slice(0, availableJerseyNumbers.length).map((record, indx) => {
                  console.log('availableJerseyNumbers[indx]', availableJerseyNumbers[indx], indx);
                  return updateJerseyNumber(record.id, availableJerseyNumbers[indx]);
                  // return [];
                })
              );
              toast.success(`Jersey numbers for size ${size} updated successfully!`);
            }
          }
        })
      );

      // toast.success('Generated Jersey Numbers Successfully!');
      await fetchFilteredData(event);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error generating jersey numbers:', error);
      throw error;
    }
  };

  const updateJerseyNumber = async (id, jerseyNumber) => {
    try {
      const response = await API.graphql({
        query: mutations.updateTournament,
        variables: { input: { id, jerseyNo: jerseyNumber } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      console.log('success updating jersey number:');
    } catch (error) {
      console.error('Error updating jersey number:', error);
      throw error;
    }
  };

  return { fetchFilteredData, generateJerseyNumbers, filteredData, tournamentData, loading };
};

export default useFilteredTournamentData;
