/* eslint-disable consistent-return */
/* eslint-disable consistent-return, prefer-const, no-plusplus, no-await-in-loop, no-undef */

import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries'; // Import your GraphQL queries for listing teams
import * as mutations from '../graphql/mutations'; // Import your GraphQL queries for listing teams
import { createRounds, createWinner } from './startTournamentScoreHooks';
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function useAddTeam5x5sToEvents() {
  const [teams, setTeams] = useState([]);
  const [event, setEvent] = useState();
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function addToEvents(selectedTeams, eventId) {
    try {
      setLoading(true);

      if (selectedTeams.length < 2) {
        toast.error('Insufficient teams to create matches');
        throw new Error('Insufficient teams to create matches.');
      }
      const response = await API.graphql(graphqlOperation(queries.getLeague5x5, { id: eventId }));
      const event = response?.data?.getLeague5x5;
      if (event.eventStatus === 'EVENT_STARTED') {
        toast.error('Already Started');
        return;
      }

      const teams = await Promise.all(
        selectedTeams?.map(async (teamId) => {
          return await getTeamsById(teamId);
        })
      );
      console.log('teams', teams);

      if (teams.length < 2) {
        toast.error('Insufficient teams to create matches');
        throw new Error('Insufficient teams to create matches.');
      }

      teams?.map(async (team) => {
        try {
          const updateTeam = await API.graphql({
            query: mutations.updateTeam5x5,
            variables: {
              input: {
                id: team.id,
                leagueId: eventId,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        } catch (error) {
          console.log('Error Updating Team', error);
          throw new Error(error);
        }
      });

      toast.success('Updated');
    } catch (error) {
      console.log('Error Adding To Events');
      throw new Error(error);
    }
  }

  const getTeamsById = async (teamId) => {
    try {
      const response = await API.graphql(graphqlOperation(queries.getTeam5x5, { id: teamId }));

      return response.data.getTeam5x5;
    } catch (error) {
      console.log('Error Fetching Team by Id', error);
      throw new Error(error);
    }
  };

  function calculateRounds(n) {
    if (n <= 0) {
      return 0;
    }

    if (n === 1) {
      return 1;
    }

    let rounds = 0;
    let teams = n;

    while (teams > 1) {
      rounds++;
      teams = Math.ceil(teams / 2);
    }

    return rounds;
  }

  return {
    addToEvents,
    teams,
    event,
    players,
    loading,
    error,
  };
}

const stats = {
  GP: '0',
  MIN: '0',
  PTS: '0',
  FGM: '0',
  FGA: '0',
  FGPERCENT: '0',
  THREEPM: '0',
  THREEPA: '0',
  THREEPPERCENT: '0',
  FTM: '0',
  FTA: '0',
  FTPERCENT: '0',
  OREB: '0',
  DREB: '0',
  REB: '0',
  AST: '0',
  TOV: '0',
  STL: '0',
  BLK: '0',
  PF: '0',
  FP: '0',
  DDTWO: '0',
  TDTHREE: '0',
  PlusMinus: '0',
};

const rank = {
  record: '0',
  ppg: '0',
  rpe: '0',
  apg: '0',
  ranking: '0',
};

export default useAddTeam5x5sToEvents;

/* eslint-enable consistent-return, prefer-const, no-plusplus, no-await-in-loop, no-undef */
