import {
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  LIST_PRODUCTS_REQUEST,
  LIST_PRODUCTS_SUCCESS,
  LIST_PRODUCTS_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_RESET,
  BUY_PRODUCT_REQUEST,
  BUY_PRODUCT_SUCCESS,
  BUY_PRODUCT_FAIL,
  CLEAR_PRODUCT_ERRORS,
} from "../constants/ProductsConstants";

const initialState = {
  products: [],
  productCount: 0,
  resultPerPage: 0,
  product: {},
  loading: false,
  error: null,
  isUpdated: false,
  isDeleted: false,
  success:false,
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PRODUCTS_REQUEST:
    case GET_PRODUCT_REQUEST:
    case CREATE_PRODUCT_REQUEST:
      case DELETE_PRODUCT_REQUEST:
        case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PRODUCT_SUCCESS:
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        product: action.payload.product,
      };
    case LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.products,
        productsCount: action.payload.productsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredProductsCount: action.payload.filteredProductsCount,
      };
      case DELETE_PRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_PRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: action.payload,
        };
    case LIST_PRODUCTS_FAIL:
    case GET_PRODUCT_FAIL:
    case CREATE_PRODUCT_FAIL:
    case UPDATE_PRODUCT_FAIL:
    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_PRODUCT_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

