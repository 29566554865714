import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { getKey } from '../utils/getKey';
import { allImage, getNonMatchingValues, ReplaceUrl, updateImageSrcInHtml } from '../utils/replaceUrl';
import uploadFile from '../utils/uploadFile';
import deleteFile from '../utils/deleteFile';
import getLink from '../utils/getLinks';

const useHandlePaginationCampCards = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [campCardsList, setCampCardsList] = useState([]);
  const [campCard, setCampCard] = useState();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchCampCards = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        // }

        const result = await API.graphql({
          query: queries.listCampCards,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });

        const fetchedCampCards = result?.data?.listCampCards?.items || [];

        const updateTrainingCards = await Promise.all(
          fetchedCampCards?.map(async (item) => {
            const description = await updateImageSrcInHtml(item?.description, getLink, getKey);
            item.description = description;

            return item;
          })
        );

        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listCampCards?.nextToken);
        }

        setCampCardsList(updateTrainingCards);
        setNextNextToken(result?.data?.listCampCards?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching CampCards:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteCampCards = async (id) => {
    try {
      setLoading(true);
      const oldResponse = await API.graphql(graphqlOperation(queries.getCampCard, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const oldData = oldResponse.data.getCampCard;

      const allOldImageKey = await allImage(oldData.description, getKey);

      allOldImageKey?.map(async (key, index) => {
        await deleteFile(key);
      });

      const response = await API.graphql(graphqlOperation(mutations.deleteCampCard, { input: { id } }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const trainingCard = response?.data?.getCampById;
      console.log(response);
      toast.success('Camp Card Deleted Successfully!');
      setLoading(false);

      // Remove deleted CampCards from the campCardsList
      setCampCardsList((prevCampCardsList) => prevCampCardsList.filter((item) => item.id !== id));
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting CampCards:', error);
    }
  };

  const updateCampCards = async (formData) => {
    try {
      setLoading(true);
      const data = {
        title: formData?.title,
        price: formData?.price,
        description: formData?.description,
      };
      const oldResponse = await API.graphql(graphqlOperation(queries.getCampCard, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const oldData = oldResponse.data.getCampCard;

      console.log('oldData', oldData);

      const allOldImageKey = await allImage(oldData.description, getKey);
      const newImageKey = await allImage(data.description, getKey);

      const { notInNew: deletedImageKey } = getNonMatchingValues(allOldImageKey, newImageKey);

      deletedImageKey?.map(async (key, index) => {
        await deleteFile(key);
      });

      const response = await API.graphql({
        query: mutations.updateCampCard,
        variables: { input: { ...data, id: formData.id } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const updatedDetails = {
        ...response?.data?.updateCampCard,
      };
      setLoading(false);

      setCampCardsList((prevCampCardsList) => {
        const updatedCampCardList = prevCampCardsList?.map((item) => {
          if (item.id === formData?.id) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedCampCardList;
      });

      toast.success('Camp Card Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating TrainingCards details:', error);
      toast.error('Error updating TrainingCards details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const addCampCard = async (items) => {
    setLoading(true);

    try {
      setLoading(true);
      // Upload the image to S3 using Storage.put
      const updatedData = { ...items };
      const response = await create(updatedData);
      const campCardsData = {
        ...response?.data?.createCampCard,
      };
      setLoading(false);
      setCampCardsList((prevCampCardsList) => [campCardsData, ...prevCampCardsList]); // Add the new ClinicCards data to the events list

      setLoading(false);
      toast.success(`Camp Card Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating events:', error);
      throw error;
    }
  };
  const create = async (updatedData) => {
    setLoading(true);

    const response = await API.graphql({
      query: mutations.createCampCard,
      variables: {
        input: {
          ...updatedData,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    return response;
  };

  const getCampById = async () => {
    setLoading(true);

    try {
      const response = await API.graphql(graphqlOperation(queries.getCampCard, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const trainingCard = response?.data?.getCampCard;

      const description = await updateImageSrcInHtml(trainingCard?.description, getLink, getKey);
      trainingCard.description = description;

      setCampCard(trainingCard);

      return trainingCard;
    } catch (error) {
      toast.error('Some thing went wrong check console');
      console.log('Error CampCards:', error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCampById(id);
    }
    fetchCampCards('');
  }, [nextToken, id]);

  return {
    campCardsList,
    nextNextToken,
    loading,
    deleteCampCards,
    fetchNextPage,
    fetchCampCards,
    fetchPreviousPage,
    previousTokens,
    addCampCard,
    updateCampCards,
    campCard,
  };
};

export default useHandlePaginationCampCards;
