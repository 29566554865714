
import { Grid } from '@mui/material';
import TutorCard from './TutorCard';

// ----------------------------------------------------------------------

export default function TutorList({ dataList, loading, setData, updateData, deleteData, fetchData, ...other }) {
  console.log("DATA LIST", dataList)
  return (
    <Grid container spacing={3} {...other}>
      {dataList?.map((data) => (
        <Grid key={data.id} item xs={12} sm={6} md={3}>
          <TutorCard updateData={updateData} deleteData={deleteData} data={data} fetchData={fetchData} />
        </Grid>
      ))}
    </Grid>
  );
}
