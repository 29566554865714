import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
  Stack,
  Box,
  Typography,

} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import useGetTeams from '../../../hooks/getTeamsList';
import useCityList from '../../../hooks/getCityList';
import Loader from '../../../components/Loader';
import { getAllEvents } from '../../../redux/actions/CampEventsAction';

AddNewEventSideBar.propTypes = {
  openEvent: PropTypes.bool,
  onOpenEvent: PropTypes.func,
  onCloseEvent: PropTypes.func,
};

export default function AddNewEventSideBar({
  updateEvents,
  hidden,
  adddEvent,
  openEvent,
  onOpenEvent,
  onCloseEvent,
  editData,
  events,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [age, setAge] = useState('');
  const [city, setCity] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isEditMode = !!editData;

  const ages = [
    { value: "u7", label: "U7" },
    { value: "u8", label: "U8" },
    { value: "u9", label: "U9" },
    { value: "u10", label: "U10" },
    { value: "u11", label: "U11" },
    { value: "u12", label: "U12" },
    { value: "u13", label: "U13" },
    { value: "u14", label: "U14" },
    { value: "u15", label: "U15" },
    { value: "u17", label: "U17" },
    { value: "u18", label: "U18" },
    { value: "u19-u39", label: "U19-U39" },
    { value: "over u40", label: "Over U40" }
  ];
  const { cities } = useCityList();


  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      setTitle(editData?.name || '');
      setCity(editData?.cityOfRegistration || '');
      setAge(editData?.agegroup || '');
    }
  }, [editData, isEditMode]);
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleUploadData = async () => {
    if (!title) {
      toast.error('Title is required.');
      return;
    }

    // Check if price is empty
    if (!city) {
      toast.error('City is required.');
      return;
    }
    // if (!age) {
    //   toast.error('Age is required.');
    //   return;
    // }


    const eventData = {
      name: title,

      cityOfRegistration: city,

      id: editData?.id,
    };
    // Handle data upload logic here
    // console.log('Data to upload:', eventData);
    if (isEditMode) {
      const id = eventData?.id;
      // console.log('eventsDtaa',eventData)
      onCloseEvent();
      const updated = await updateEvents(id, eventData);

      if (updated) {
        dispatch(getAllEvents());
      }
    } else {
      // dispatch(createEvent(eventData));
      delete eventData?.newImage;
      const data = {
        ...eventData
      };

      onCloseEvent();
      const created = await adddEvent(data);
      // Close the modal

      if (created) {
        dispatch(getAllEvents());
      }
    }

    // Reset form fields
    setTitle('');
    setCity('');
    setAge('');
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenEvent}
        style={buttonStyles}
        hidden={hidden}
      >
        Add Event
      </Button>
      <Dialog open={openEvent} onClose={onCloseEvent}>
        <DialogTitle>{isEditMode ? 'Edit Event' : 'Add New Event'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new event.</DialogContentText>
          <TextField autoFocus margin="dense" label="Title" fullWidth value={title} onChange={handleTitleChange} />

          {/* <FormControl fullWidth margin="dense">
            <InputLabel id="age">Age</InputLabel>
            <Select
              labelId="age"
              label="age"
              name="age"
              fullWidth
              required
              variant="outlined"
              value={age}
              onChange={handleAgeChange}
            >
              {ages.map((age,index) => (
                <MenuItem key={index} value={age.value}>
                  {age.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <FormControl fullWidth margin="dense">
            <InputLabel id="city">City</InputLabel>
            <Select
              labelId="city"
              label="city"
              name="city"
              fullWidth
              required
              variant="outlined"
              value={city}
              onChange={handleCityChange}
            >
              {cities.map((city, index) => (
                <MenuItem key={index} value={city.name}>
                  {city.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={onCloseEvent} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
