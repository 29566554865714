import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
import {
  CREATE_CLINICCARD_REQUEST,
  CREATE_CLINICCARD_SUCCESS,
  CREATE_CLINICCARD_FAIL,
  UPDATE_CLINICCARD_REQUEST,
  UPDATE_CLINICCARD_SUCCESS,
  UPDATE_CLINICCARD_FAIL,
  DELETE_CLINICCARD_REQUEST,
  DELETE_CLINICCARD_SUCCESS,
  DELETE_CLINICCARD_FAIL,
  GET_CLINICCARD_REQUEST,
  GET_CLINICCARD_SUCCESS,
  GET_CLINICCARD_FAIL,
  CLEAR_CLINICCARD_ERRORS,
  ALL_CLINICCARD_REQUEST,
  ALL_CLINICCARD_SUCCESS,
  ALL_CLINICCARD_FAIL,
} from '../constants/ClinicCardsConstants';

export const getAllClinicCards = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_CLINICCARD_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listClinicCards));

    const updatedClinicCards = response.data.listClinicCards.items || [];

    const updatedData = {
      clinicCards: updatedClinicCards,
      clinicCardsCount: updatedClinicCards?.length,
      resultPerPage: 10,
      filteredClinicCardsCount: null,
    };

    dispatch({
      type: ALL_CLINICCARD_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_CLINICCARD_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getClinicCardDetails = (clinicCardId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CLINICCARD_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getClinicCard, { id: clinicCardId }));

    const newData = response?.data.getClinicCard;
    dispatch({
      type: GET_CLINICCARD_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_CLINICCARD_FAIL,
      payload: errorMessage,
    });
  }
};

export const createClinicCard = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CLINICCARD_REQUEST });

    const response = await API.graphql({
      query: mutations.createClinicCard,

      variables: {
        input: {
          ...formData,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createClinicCard;
    toast.success('Clinic Card Added Successfully!');

    dispatch({
      type: CREATE_CLINICCARD_SUCCESS,
      payload: data,
    });
    toast.success('Created!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('CLINICCARD error', error);

    dispatch({
      type: CREATE_CLINICCARD_FAIL,
      payload: error,
    });
  }
};
export const deleteClinicCard = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CLINICCARD_REQUEST });

    API.graphql({
      query: mutations.deleteClinicCard,
      variables: { input: { id } },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then(async (response) => {
      toast.success('Clinic Card Deleted Successfully!');

      console.log(response);
      dispatch({
        type: DELETE_CLINICCARD_SUCCESS,
        payload: true,
      });
    });

    toast.success('Deleted!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_CLINICCARD_FAIL,
      payload: error || 'Failed to fetch CLINIC CARD data',
    });
  }
};
export const updateClinicCard = (clinicCardId, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CLINICCARD_REQUEST });

    const data = {
      title: formData?.title,
      price: formData?.price,
      description: formData?.description,
    };

    const response = await API.graphql({
      query: mutations.updateClinicCard,
      variables: { input: { ...data, id: clinicCardId } },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const updatedDetails = response?.data?.updateClinicCard;
    toast.success('Clinic Card Updated Successfully!');
    dispatch({
      type: UPDATE_CLINICCARD_SUCCESS,
      payload: updatedDetails,
    });
    toast.success('Updated!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.error('Error updating CLINICCARD:', error);

    dispatch({
      type: UPDATE_CLINICCARD_FAIL,
      payload: error || 'Failed to update CLINICCARD',
    });
  }
};
export const clearClinicCardsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CLINICCARD_ERRORS });
};
