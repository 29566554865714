import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Box,
  Switch,
  FormControl,
} from '@mui/material';
import useFindUserById from '../../hooks/findUserById';
import Loader from '../Loader';
import useUpdateUser from '../../hooks/userUpdate';
import useGetAllTeams from '../../hooks/getTeamsLists';
import useGetAllCity from '../../hooks/getCityList';

const User = () => {
  const { teamslist } = useGetAllTeams();
  const { citylist } = useGetAllCity();
  const { updateUser, loading: updateLoading } = useUpdateUser();
  const { userDetails, loading } = useFindUserById();
  const [formData, setFormData] = useState({
    participant: '',
    email: '',
    phone: '',
    name: '',
    id: '',
    status: '',
    enable: false,
    team: '', // New field
    age: '', // New field
    leagues: '', // New field
  });

  useEffect(() => {
    if (userDetails && userDetails?.UserAttributes) {
      const userData = {
        id: userDetails.Username || '',
        enable: userDetails.Enabled || false,
        status: userDetails?.UserStatus || '',
        email: userDetails.UserAttributes?.find((attr) => attr.Name === 'email')?.Value || '',
        name: userDetails.UserAttributes?.find((attr) => attr.Name === 'name')?.Value || '',
        participant: userDetails.UserAttributes?.find((attr) => attr.Name === 'name')?.Value || '',
        phone: userDetails.UserAttributes.find((attr) => attr.Name === 'phone_number')?.Value || '',
        team: userDetails.UserAttributes?.find((attr) => attr.Name === 'custom:team')?.Value || '', // New field
        age: userDetails.UserAttributes?.find((attr) => attr.Name === 'custom:age')?.Value || '', // New field
        leagues: userDetails.UserAttributes?.find((attr) => attr.Name === 'custom:leagues')?.Value || '', // New field
      };
      setFormData(userData);
    }
  }, [userDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await updateUser(formData);
    console.log('Form Data:', formData);
  };

  if (loading || updateLoading) {
    return <Loader />;
  }

  const buttonStyles = {
    backgroundColor: '#1E90FF',
    color: 'white',
  };

  const ages = [
    { value: "u7", label: "U7" },
    { value: "u8", label: "U8" },
    { value: "u9", label: "U9" },
    { value: "u10", label: "U10" },
    { value: "u11", label: "U11" },
    { value: "u12", label: "U12" },
    { value: "u13", label: "U13" },
    { value: "u14", label: "U14" },
    { value: "u15", label: "U15" },
    { value: "u17", label: "U17" },
    { value: "u18", label: "U18" },
    { value: "u19-u39", label: "U19-U39" },
    { value: "over u40", label: "Over U40" }
  ];
  
  const statusOptions = ['PENDING', 'CONFIRMED', 'REJECTED'];
  return (
    <Container mt={2} mb={2} ml={5}>
      <Box ml={5} mb={2} fontSize="24px" fontWeight="bold" mt={2}>
        Edit USER
      </Box>
      <form onSubmit={handleFormSubmit}>
        <Grid container ml={2}>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ marginBottom: '16px' }}
              name="participant"
              variant="outlined"
              label="Participant"
              fullWidth
              disabled
              value={formData.participant}
              onChange={handleChange}
            />

            <TextField
              sx={{ marginBottom: '16px' }}
              name="participant"
              variant="outlined"
              label="Participant"
              fullWidth
              disabled
              value={formData.participant}
              onChange={handleChange}
            />
            <TextField
              sx={{ marginBottom: '16px' }}
              name="email"
              variant="outlined"
              label="Email Address"
              fullWidth
              disabled
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              sx={{ marginBottom: '16px' }}
              name="phone"
              label="Phone"
              variant="outlined"
              disabled
              fullWidth
              value={formData.phone}
              onChange={handleChange}
            />
            <FormControl fullWidth margin="dense">
              {/* <InputLabel id="status">Status</InputLabel> */}
              {/* <Select */}
              {/* // labelId="status" */}
              {/* // label="Status" */}
              {/* // name="status" */}
              {/* // value={formData.status} */}
              {/* // fullWidth */}
              {/* // variant="outlined" */}
              {/* // onChange={handleChange}> */}
              {/* {statusOptions.map((statusOption) => ( */}
              {/* // <MenuItem key={statusOption} value={statusOption}> */}
              {/* // {statusOption} */}
              {/* // </MenuItem> */}
              {/* // ))} */}
              {/* // </Select> */}

              <TextField
                sx={{ marginBottom: '16px' }}
                name="status"
                variant="outlined"
                value={formData.status}
                disabled
                fullWidth
                onChange={handleChange}
              />
            </FormControl>
            {/* <InputLabel>Enabled</InputLabel> */}
            <Switch
              name="enable"
              sx={{ marginBottom: '16px' }}
              checked={formData.enable}
              disabled
              onChange={handleSwitchChange}
              color="primary"
            />

            <FormControl fullWidth margin="dense">
              {/* <InputLabel id="team">Team</InputLabel> */}
              <TextField
                sx={{ marginBottom: '16px' }}
                name="team"
                value={formData.team}
                fullWidth
                disabled
                className="uppercase"
                variant="outlined"
                //  onChange={handleChange}
              />
              {/* <Select
                sx={{ marginBottom: '16px' }}
                labelId="team"
                label="Team"
                name="team"
                value={formData.team}
                fullWidth
                className="uppercase"
                variant="outlined"
                onChange={handleChange}
              >
                {teamslist &&
                  teamslist.map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select> */}
            </FormControl>
            <FormControl fullWidth margin="dense">
              {/* <InputLabel id="age">Age</InputLabel> */}
              <TextField
                sx={{ marginBottom: '16px' }}
                labelId="age"
                label="Age"
                name="age"
                value={formData.age}
                fullWidth
                className="uppercase"
                variant="outlined"
                disabled
                onChange={handleChange}
              />
              {/* <Select
                sx={{ marginBottom: '16px' }}
                labelId="age"
                label="Age"
                name="age"
                value={formData.age}
                fullWidth
                className="uppercase"
                variant="outlined"
                onChange={handleChange}
              >
                {ages.map((ageOption) => (
                  <MenuItem key={ageOption} value={ageOption}>
                    {ageOption}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>

            <FormControl fullWidth margin="dense">
              {/* <InputLabel id="league">Leagues</InputLabel> */}
              <TextField
                sx={{ marginBottom: '16px' }}
                labelId="league"
                label="Leagues"
                name="league"
                value={formData.leagues}
                fullWidth
                className="uppercase"
                variant="outlined"
                disabled
                onChange={handleChange}
              />
              {/* <Select
                sx={{ marginBottom: '16px' }}
                labelId="league"
                label="Leagues"
                name="league"
                value={formData.leagues}
                fullWidth
                className="uppercase"
                variant="outlined"
                onChange={handleChange}
              >
                {citylist &&
                  citylist.map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select> */}
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={2} ml={5}>
          {/* <Button variant="contained" style={buttonStyles} type="submit"> */}
          {/* Submit */}
          {/* </Button> */}
        </Box>
      </form>
    </Container>
  );
};

export default User;
