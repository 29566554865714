import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const useHandlePagintationSession = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [sessionList, setSessionList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchSession = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        const result = await API.graphql({
          query: queries.listSeasonCharges,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });

        const fetchedSession = result?.data?.listSeasonCharges?.items || [];

        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listSeasonCharges?.nextToken);
        }

        setSessionList(fetchedSession);
        setNextNextToken(result?.data?.listSeasonCharges?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Session:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteSession = async (id) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.deleteSeasonCharges,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      console.log(response);
      toast.success('Session Deleted Successfully!');
      setLoading(false);

      // Remove deleted Session from the SessionList
      setSessionList((prevSessionList) => prevSessionList.filter((item) => item.id !== id));
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Session:', error);
    }
  };

  const updateSession = async (sessionId, formData) => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: mutations.updateSeasonCharges,
        variables: { input: { ...formData, id: sessionId } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedDetails = {
        ...response?.data?.updateSeasonCharges,
      };
      setLoading(false);

      setSessionList((prevSessionList) => {
        const updatedSessionList = prevSessionList?.map((item) => {
          if (item.id === sessionId) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedSessionList;
      });

      toast.success('Session Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating Session details:', error);
      toast.error('Error updating Session details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const addSession = async (items) => {
    setLoading(true);

    try {
      setLoading(true);

      // Upload the image to S3 using Storage.put
      const updatedData = { ...items };
      const response = await create(updatedData);
      const sessionData = {
        ...response?.data?.createSeasonCharges,
      };
      setLoading(false);
      setSessionList((prevSessionList) => [sessionData, ...prevSessionList]); // Add the new ClinicCards data to the events list

      setLoading(false);
      toast.success(`Session Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating events:', error);
      throw error;
    }
  };
  const create = async (updatedData) => {
    setLoading(true);

    const response = await API.graphql({
      query: mutations.createSeasonCharges,
      variables: {
        input: {
          ...updatedData,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    return response;
  };
  useEffect(() => {
    fetchSession('');
  }, [nextToken]);

  return {
    sessionList,
    nextNextToken,
    loading,
    deleteSession,
    fetchNextPage,
    fetchSession,
    fetchPreviousPage,
    previousTokens,
    addSession,
    updateSession,
  };
};

export default useHandlePagintationSession;
