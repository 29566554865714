import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import getLink from '../utils/getLinks';

const useHandlePlayer5x5User = () => {
  const [player5x5UserDetails, setPlayer5x5User] = useState();
  const [player5x5List, setPlayer5x5List] = useState([]);
  const [verifiedPlayer5x5s, setVerifiedPlayer5x5List] = useState([]);
  const [notVerifiedPlayer5x5s, setNotVerifiedPlayer5x5List] = useState([]);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchPlayer5x5Users = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.listPlayer5x5s));
      const data = response?.data?.listPlayer5x5s?.items;

      const modifiedT = data?.map((user) => {
        return {
          ...user,
          id: user?.id,
          name: user?.participantName,
          email: user?.email,
          status: user?.isVerified,
          //
          zone: 'Player5x5',
          // Add other attributes you want to include
        };
      });
      const verifiedPlayers5x5 = modifiedT?.filter((player) => player.isVerified === true);
      const nonVerifiedPlayers5x5 = modifiedT?.filter((player) => player.isVerified !== true);

      setVerifiedPlayer5x5List(verifiedPlayers5x5);
      setNotVerifiedPlayer5x5List(nonVerifiedPlayers5x5);

      setPlayer5x5List(modifiedT);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Players:', error);
      setLoading(false);
    }
  };

  const fetchPlayer5x5UserById = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.getPlayer5x5, { id }));
      const data = response?.data?.getPlayer5x5;
      const profileLink = await getLink(data?.profile);
      const d = {
        ...data,
        profileLink,
      };
      setPlayer5x5User(d);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching Player details:', error);
      setLoading(false);
    }
  };

  const updatePlayer5x5ById = async (data) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.updatePlayer5x5,
        variables: { input: { id, ...data } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedData = response?.data?.updatePlayer5x5;

      const profileLink = await getLink(updatedData?.profile);
      const document = await getLink(updatedData?.document);
      const d = {
        ...data,
        documentLink: document,
        profileLink,
      };
      setPlayer5x5List((prevList) => prevList.map((user) => (user.id === updatedData.id ? updatedData : user)));

      setPlayer5x5User(d);
      toast.success('Player Data Updated Successfully!');
      setLoading(false);
    } catch (error) {
      console.error('Error updating Player:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  useEffect(() => {
    if (id) {
      fetchPlayer5x5UserById();
    }
    fetchPlayer5x5Users();
  }, [id]);

  return {
    player5x5UserDetails,
    player5x5List,
    fetchPlayer5x5Users,
    loading,
    verifiedPlayer5x5s,
    notVerifiedPlayer5x5s,

    updatePlayer5x5ById,
  };
};

export default useHandlePlayer5x5User;
