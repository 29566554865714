/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import useEventsById from './getEventsById';
import useGetTeamById from './getTeamById';
import useAllLeaguePlayerList from './getAllLeaguesPlayer';

const useAddScores = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [playerDetails, setPlayerDetails] = useState([]);
  const { fetchLeaguesUserByFilter, filterPlayer } = useAllLeaguePlayerList();
  const { fetchMultipleTeam, loading: loadingTeam, teams } = useGetTeamById();
  const { fetchEventsById, loading: fetchingEventsLoader, event } = useEventsById();
  const [teamsDetails, setTeamsDetails] = useState([{}]);
  const addScore = async (scores) => {
    try {
      // console.log('Creating score using API call', score);
      setLoading(true);
      API.graphql({
        query: mutations.createScore,
        input: { scores },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }).then((response) => {
        const score = response?.data?.createScore;
        // setScores(score);
        setLoading(false);
        console.log('Score created successfully:', score);
      });

      console.log('Fetching additional league data');
      // const response = await API.graphql(graphqlOperation(queries.listLeagues));
      // setScores(addScores);
      // console.log('Fetched additional league data:', addScores);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error('Error Adding Score:', error);
    }
  };

  return { addScore, error, loading, playerDetails };
};

export default useAddScores;
