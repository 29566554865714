import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  FormHelperText,
  FormControl,
  InputLabel,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';

import useUpdateScoreByIdOrAge from '../../hooks/updateTournamentScore';
import useAddScores from '../../hooks/addScores';
import useListScores from '../../hooks/listScores';
import { getAllEvents } from '../../redux/actions/Events5x5Action';
import { getAllCity } from '../../redux/actions/CityAction';
import NewScore from './NewScore';
import { fetchLeagueByIdForScore, fetchStartedLeagues } from 'src/hooks/useCustomQueriesLeague5x5';

const LeagueGame = () => {
  const dispatch = useDispatch();
  // const [listScores, setListScores] = useState(null);
  const [eventsList,setEventList]=useState([]);
  const [eventsLoading,setEventsLoading]=useState(false);
  const [rounds, setRounds] = useState(null);
  const { fetchScores, loading } = useListScores();
  const [listScores, setListScores] = useState(null);

 
  const [customLoading, setCustomLoading] = useState(false);

  //  const {
  //    getTournamentLeagueByID,
  //   leaguesNameList: eventsList,
  //   loading: eventsLoading,
  // } = useGetLeagueName()

  console.log('leaguesNameList', eventsList);
  const { cities, loading: cityLoading, error: cityErrors } = useSelector((state) => state.city);

  const { updateScoreById, endEvenAndScore, loading: updateLoading } = useUpdateScoreByIdOrAge();
  // const { eventsList, loading: eventLoading } = useEventsList();
  const ages = [
    { value: "u7", label: "U7" },
    { value: "u8", label: "U8" },
    { value: "u9", label: "U9" },
    { value: "u10", label: "U10" },
    { value: "u11", label: "U11" },
    { value: "u12", label: "U12" },
    { value: "u13", label: "U13" },
    { value: "u14", label: "U14" },
    { value: "u15", label: "U15" },
    { value: "u17", label: "U17" },
    { value: "u18", label: "U18" },
    { value: "u19-u39", label: "U19-U39" },
    { value: "over u40", label: "Over U40" }
  ];

  // const { cities, loading: cityLoading } = useCityList();
  const { addScore } = useAddScores();
  //  const { fetchScores, listScores, loading } = useListTournamentScores();
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [eventId, setEventsId] = useState();
  const [ageError, setAgeError] = useState(null);
  const [eventError, setEventError] = useState(null);
  const [teamStats, setTeamStats] = useState([]);
  const [playerDetails, setPlayerDetails] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [showNoEventsMessage, setShowNoEventsMessage] = useState(false);
  const [record, setRecord] = useState(0);
  const [ranking, setRanking] = useState(0);
  const [ppg, setPpg] = useState(0);
  const [rpe, setRpe] = useState(0);
  const [apg, setApg] = useState(0);
  const [cityError, setCityError] = useState(null);

  let stats;

  console.log('ListScores');

  const fetchStartedLagues=async()=>{
    setEventsLoading(true)
    try {
      const data=await fetchStartedLeagues()
      setEventList(data);
    } catch (error) {
      console.log("Error fetching not startedLeagues",error)
    }finally{
    setEventsLoading(false)

    }
  }

  const handleCityChange = async (event) => {
    const city = event.target.value;
    setSelectedCity(city);

    setCityError(null);
    console.log('Events', eventsList);
    // Filter events based on the selected city
    const filtered = eventsList?.filter((event) => event.location === city && event.eventStatus === 'EVENT_STARTED');



    setFilteredEvents(filtered);

    console.log("SELECTED_CITY",selectedCity)
    // Reset the selected event when the city changes
    setSelectedEvent('');
  };
  const handleEventChange = (e) => {
    console.log("E",e)
    const event = eventsList.filter((event) => event.id === e.target.value);
    console.log('EVENT ID',eventsList, event);
    setSelectedEvent(event[0].title);
    setEventsId(event[0].id);
    // Reset event error when a new event is selected
    setEventError(null);
    console.log("SELECTED_EVENT",selectedEvent)
  };

  const handleFetchEvents = async () => {
    // Validate city and event selection
    if (!selectedCity) {
      setCityError('required');
    } else if (!selectedEvent) {
      setEventError('required');
    } else {
      setCustomLoading(true)
      //   const response = await fetchScores(selectedEvent, selectedAge);
      // Simulate fetching events based on the selected city.    //  setFilteredEvents(filtered) ;
      // Replace this with your actual API call to fetch events.
      const event=await fetchLeagueByIdForScore(eventId,true)
      // const event = eventsList.find((event) => event.location === selectedCity);
      console.log('Event', event, selectedEvent);

        setListScores(event?.scores);
      setRounds(event?.rounds);
console.log("scores",event?.score,listScores)
      setFilteredEvents([event]);
      setCustomLoading(false)
    }
  };

  const fetchUpdatedData = async () => {
    if (updateTrigger) {
      //   await fetchScores(selectedEvent, selectedAge);
      // Reset updateTrigger to false
      setUpdateTrigger(false);
    }
  };

  const handleEndMatchClick = async () => {
    console.log('update', playerDetails);
    const updateData = { ...listScores[0], playerDetails, eventStatus: 'EVENT_ENDED' };
    await endEvenAndScore(listScores, eventId);
    setShowTable(false);
    setTeamStats([]);
    setSelectedEvent(null);
    setSelectedCity(null);

    fetchUpdatedData();
    dispatch(getAllEvents());
    handleFetchEvents();

    console.log('updateeeeeeee', updateData);
  };
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const endMatchButtonStyle = {
    backgroundColor: 'red', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  useEffect(() => {
    console.log('scores', listScores);
    const details = [];
    console.log('Event tttttt', eventId);

    if (listScores) {
      setTeamStats(listScores);
      listScores.map((value) => {
        value?.playerDetails?.map((playerDetail) => details.push(playerDetail));
        return null; // Added return null to avoid a missing return warning
      });
      setPlayerDetails(details);

      setShowTable(true);
      setShowNoEventsMessage(false);
    } else if (selectedCity && selectedEvent) {
      setShowNoEventsMessage(true);
    }
    console.log('stats', teamStats);

    // Replace this with your actual API call to fetch events.
    if (updateTrigger) {
      fetchUpdatedData();
    }
  }, [listScores, showTable, updateTrigger]);

  useEffect(() => {
    // console.log('playerDetailsNews', newPlayerData);
    dispatch(getAllCity());
    console.log('scores', listScores);
    const details = [];
    fetchStartedLagues()

    if (listScores) {
      setTeamStats(listScores);
      listScores.map((value) => {
        value?.playerDetails?.map((playerDetail) => details.push(playerDetail));
        return null; // Added return null to avoid a missing return warning
      });
      setPlayerDetails(details);

      setShowTable(true);
      setShowNoEventsMessage(false);
    } else if (selectedCity && selectedEvent) {
      setShowNoEventsMessage(true);
    }
  }, []);

  if (updateLoading || eventsLoading || customLoading) {
    return <Loader />;
  }

  return (
    <>
      <Paper>
        <Box p={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="dense" error={cityError === 'required'}>
                <InputLabel id="city">Select City</InputLabel>
                <Select
                  labelId="city"
                  label="Select City"
                  name="city"
                  fullWidth
                  variant="outlined"
                  onChange={handleCityChange}
                >
                  <MenuItem value="">Select City</MenuItem>
                  {cities?.map((city, cityIndex) => (
                    <MenuItem key={cityIndex} value={city?.name}>
                      {city?.name}
                    </MenuItem>
                  ))}
                </Select>
                {cityError === 'required' && <FormHelperText>This field is required</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="dense" error={eventError === 'required'}>
                <InputLabel id="event">Select Event</InputLabel>
                <Select
                  labelId="event"
                  label="Select Event"
                  name="event"
                  fullWidth
                  variant="outlined"
                  onChange={handleEventChange}
                >
                  <MenuItem value="">Select Event</MenuItem>
                  {filteredEvents?.map((event, eventIndex2) => (
                    <MenuItem key={eventIndex2} value={event.id}>
                      {event?.title}
                    </MenuItem>
                  ))}
                </Select>
                {eventError === 'required' && <FormHelperText>This field is required</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" style={buttonStyles} onClick={handleFetchEvents} fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div className="">
        <div className="">
          {showTable && !listScores && listScores.length < 1 && (
            <p className="text-1xl font-medium text-red-600">No Matches</p>
          )}
          {showTable && listScores && listScores.length > 0 && (
            <>
              <div className="flex items-center w-full  px-6 justify-between gap-2 py-4 text-2xl font-medium ">
                <div className="flex items-center flex-col justify-center gap-2 py-4 text-2xl font-medium ">
                  <p>{listScores[0].event}</p>
                </div>
                <div className="flex items-center flex-col justify-center gap-2 py-4 text-2xl font-medium ">
                  <p>{listScores.length} Teams</p>
                </div>
                {/* {showTable && listScores && listScores.length > 0 && listScores[0].eventStatus !== 'EVENT_ENDED' ? (
                  <Button style={endMatchButtonStyle} onClick={handleEndMatchClick}>
                    End Event
                  </Button>
                ) : (
                  <Button style={endMatchButtonStyle} disabled className="opacity-50">
                    Event Ended
                  </Button>
                )} */}
              </div>
            </>
          )}

{showTable && <NewScore listScores={listScores} setListScores={setListScores} setCustomLoading={setCustomLoading} setRounds={setRounds} rounds={rounds} eventId={eventId} />}

        </div>
      </div>
    </>
  );
};

export default LeagueGame;

const Teams = {
  stats: [
    'GP',
    'MIN',
    'PTS',
    'FGM',
    'FGA',
    'FGPERCENT',
    'THREEPM',
    'TDTHREE',
    'THREEPA',
    'THREEPPERCENT',
    'FTM',
    'FTA',
    'FTPERCENT',
    'OREB',
    'DREB',
    'REB',
    'AST',
    'TOV',
    'STL',
    'BLK',
    'PF',
    'FP',
    'DDTWO',
    '+/-',
  ],
};
