

export const GET_CLINICCOURSE_REQUEST = 'GET_CLINICCOURSE_REQUEST';
export const GET_CLINICCOURSE_SUCCESS = 'GET_CLINICCOURSE_SUCCESS';
export const GET_CLINICCOURSE_FAIL = 'GET_CLINICCOURSE_FAIL';


export const ALL_CLINICCOURSE_REQUEST = 'ALL_CLINICCOURSE_REQUEST';
export const ALL_CLINICCOURSE_SUCCESS = 'ALL_CLINICCOURSE_SUCCESS';
export const ALL_CLINICCOURSE_FAIL = 'ALL_CLINICCOURSE_FAIL';

export const CREATE_CLINICCOURSE_REQUEST = 'CREATE_CLINICCOURSE_REQUEST';
export const CREATE_CLINICCOURSE_SUCCESS = 'CREATE_CLINICCOURSE_SUCCESS';
export const CREATE_CLINICCOURSE_FAIL = 'CREATE_CLINICCOURSE_FAIL';

export const UPDATE_CLINICCOURSE_REQUEST = 'UPDATE_CLINICCOURSE_REQUEST';
export const UPDATE_CLINICCOURSE_SUCCESS = 'UPDATE_CLINICCOURSE_SUCCESS';
export const UPDATE_CLINICCOURSE_FAIL = 'UPDATE_CLINICCOURSE_FAIL';


export const DELETE_CLINICCOURSE_REQUEST = 'DELETE_CLINICCOURSE_REQUEST';
export const DELETE_CLINICCOURSE_SUCCESS = 'DELETE_CLINICCOURSE_SUCCESS';
export const DELETE_CLINICCOURSE_FAIL = 'DELETE_CLINICCOURSE_FAIL';
export const DELETE_CLINICCOURSE_RESET = 'DELETE_CLINICCOURSE_RESET';

export const CLEAR_CLINICCOURSE_ERRORS = 'CLEAR_CLINICCOURSE_ERRORS';
