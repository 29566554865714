import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
  Select,
} from '@mui/material';
// components
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainSkeleton from '../components/SkeletonLoader';
// Import the toast component
import { userState } from '../store';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { OrderListHead, OrderListToolbar } from '../sections/@dashboard/order';

import Loader from '../components/Loader';
import useGetOrderList from '../hooks/getOrderList';
import useUpdateOrder from '../hooks/updateOrder';
import deleteOrder from '../hooks/deleteOrder';
import SkeletonTable from '../components/SkeletonTable';
import useHandlePagintationOrders from '../hooks/handlePaginationOrder';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'imageUrl', label: 'Image', alignRight: false },
  { id: 'title', label: 'Product Name', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'stockqty', label: 'Quantity', alignRight: false },

  { id: 'orderStatus', label: 'Order Status', alignRight: false },
  { id: 'transactionStatus', label: 'Transaction Status', alignRight: false },
  { id: 'address', label: 'Address', alignRight: true },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function OrderPage() {
  // const { orderList, updateOrderById, deleteOrderById, loading, fetchOrdersList } = useGetOrderList();
  const {
     orderList,
    nextNextToken,
    loading,
    deleteOrders,
    fetchNextPage,
    fetchOrders,
    fetchPreviousPage,
    previousTokens,
    updateOrders
   } = useHandlePagintationOrders();
  console.log('OrdersLsit', orderList);
  // const { updateOrder, loading: updateLoading } = useUpdateOrder();

  const [open, setOpen] = useState(null);
  const [deleted, setDeleted] = useState(null);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('title');
  const [openAddressModal, setOpenAddressModal] = useState(false);
  // State to hold the selected address data
  const [selectedAddress, setSelectedAddress] = useState({});

  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRowId, setSelectedRowId] = useState(null); // Add a state to store the selected row's id

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOrderStatusChange = async (event, id) => {
    const data = {
      orderStatus: event.target.value,
      id,
    };
    await updateOrders(data);
    console.log(id, event.target.value);
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleOpenMenu = (event, id) => {
    // Pass the id when opening the menu
    setSelectedRowId(id);
    console.log('id', id); // Check if a row is selected
    // Store the selected row's id
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Function to handle opening the address modal and setting the selected address
  const handleOpenAddressModal = (address) => {
    console.log('address', address);
    setSelectedAddress(address?.addresses[0]);
    setOpenAddressModal(true);
  };

  // Function to close the address modal
  const handleCloseAddressModal = () => {
    setOpenAddressModal(false);
    setSelectedAddress(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleDeleteLeageus = async (id) => {
    if (selectedRowId) {
      console.log('id', selectedRowId); // Check if a row is selected

      const deleted = await deleteOrders(selectedRowId);
      setDeleted(deleted);

      setIsDeleteDialogOpen(false);

      // Use the selected row's id
    }
    handleCloseMenu(); // Close the menu
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList.length) : 0;

  const deleteMultipleOrder = async () => {
    console.log(selectedRowId);
  };

  const filteredUsers = applySortFilter(orderList, getComparator(order, orderBy), filterName);

  console.log('filteredUsers', filteredUsers);
  const isNotFound = !filteredUsers.length && !!filterName;
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };


  return (
    <>
      <Helmet>
        <title>Order</title>
      </Helmet>
      {loading ? (
        <>
          <SkeletonTable />
        </>
      ) : (
        <>
          <Container>
            <Dialog
              open={isDeleteDialogOpen}
              onClose={handleDeleteCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Delete Order Details</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this Order Details?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteLeageus} color="primary" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Orders
              </Typography>
            </Stack>

            <Card>
              {/* <OrderListToolbar deleteOrder={deleteMultipleOrder} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <TableContainer>
                    <Table>
                      <OrderListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={orderList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}

                        // onSelectAllClick={handleSelectAllClick}
                      />

                      <TableBody>
                        {orderList?.map((row) => {
                          const {
                            id,
                            title,
                            img_url: Image,
                            stock_qty: stock,
                            finalPrice,
                            quantity,
                            orderStatus,
                            transactionStatus,
                            address,
                          } = row;
                          const selectedUser = selected.indexOf(title) !== -1;

                          return (
                            <TableRow hover key={id} tabIndex={-1}>
                              <TableCell padding="checkbox">
                                {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, title)} /> */}
                              </TableCell>

                              <TableCell sx={{ paddingLeft: '25px' }} component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar alt={title} src={Image} />
                                </Stack>
                              </TableCell>

                              <TableCell align="left">
                                <Typography variant="subtitle2" noWrap>
                                  {title?.split(' ').slice(0, 5).join(' ')}
                                  {title.split(' ').length > 5 ? '...' : ''}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">{finalPrice}</TableCell>
                              <TableCell align="left">{stock}</TableCell>
                              <TableCell align="left">
                                <Select value={orderStatus} onChange={(event) => handleOrderStatusChange(event, id)}>
                                  <MenuItem value="PENDING">PENDING</MenuItem>
                                  <MenuItem value="PLACED">PLACED</MenuItem>
                                  <MenuItem value="SHIPPED">SHIPPED</MenuItem>
                                  <MenuItem value="DELIVERED">DELIVERED</MenuItem>
                                  <MenuItem value="CANCELLED">CANCELLED</MenuItem>

                                  {/* Add more options as needed */}
                                </Select>
                              </TableCell>

                              <TableCell align="left">{transactionStatus}</TableCell>
                              <TableCell align="right">
                                {/* <IconButton size="small" sx={{fontSize:"15px",borderRadius:0,background:"transparent"}}  color="inherit" onClick={() => handleOpenAddressModal(address)}>
                            Address
                          </IconButton> */}
                                <IconButton
                                  size="small"
                                  color="inherit"
                                  onClick={() => handleOpenAddressModal(address)}
                                >
                                  <Iconify icon={'eva:eye-outline'} />
                                </IconButton>
                              </TableCell>
                              {/* </TableRow> */}
                              <TableCell align="right">
                                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </TableContainer>
              </Scrollbar>

              {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
            {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}

            </Card>
          </Container>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
          <Dialog open={openAddressModal} onClose={handleCloseAddressModal}>
            <DialogTitle>Address Details</DialogTitle>
            <DialogContent>
              {selectedAddress && (
                <Typography>
                  {/* Display address fields here */}
                  City: {selectedAddress?.city}
                  <br />
                  Country: {selectedAddress?.country}
                  <br />
                  Pincode: {selectedAddress?.pincode}
                  <br />
                  Address: {selectedAddress?.address}
                  <br />
                  Phone Number: {selectedAddress?.phonenumber}
                  <br />
                  Secondary Phone Number: {selectedAddress?.secondaryPhonenumber}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddressModal}>Close</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
