/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
// import LZString from 'l';
import { toast } from 'react-toastify';
import getLink from '../utils/getLinks';
import deleteFile from '../utils/deleteFile';
import * as queries from '../graphql/queries';
import uploadFile from '../utils/uploadFile';
import compressBase64 from '../utils/compressFile';
import * as mutations from '../graphql/mutations';
import { getKey } from '../utils/getKey';
import { allImage, getNonMatchingValues, ReplaceUrl, updateImageSrcInHtml } from '../utils/replaceUrl';

const useHandleNews = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [news, setNews] = useState([]);
  const [newsList, setNewsList] = useState([]);

  const [blog, setBlog] = useState([]);
  const [vlog, setVlog] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);
  const { id } = useParams();
  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };

  const fetchNews = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        console.log('News', 1);
        // }

        const result = await API.graphql({
          query: queries.listNews,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });
        console.log('News', result);

        const fetchedNews = result?.data?.listNews?.items || [];

        const updatedNews = await Promise.all(
          fetchedNews.map(async (news) => {
            const img_url = news?.img_url;

            if (img_url) {
              const link = await getLink(img_url);
              news.img_url = link;
              news.content_img_url = link;
            }

            return news;
          })
        );

        const news = updatedNews?.filter((item) => item.type === 'NEWS');
        const blog = updatedNews?.filter((item) => item.type === 'BLOG');
        const vlog = updatedNews?.filter((item) => item.type === 'VLOG');
        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listNews?.nextToken);
        }
        console.log('Updated News:', updatedNews);

        setBlog(blog);
        setNews(news);
        setVlog(vlog);

        setNewsList([...updatedNews]);
        setNextNextToken(result?.data?.listNews?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching News:', error.errors);
    }
  };

  const extractImagesAndVideos = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const images = [];
    const videos = [];
    const iframe = [];

    // Extract images
    const imageElements = doc.querySelectorAll('img');
    imageElements.forEach((element) => {
      const src = element.getAttribute('src');
      images.push(src);
    });

    // Extract videos
    const videoElements = doc.querySelectorAll('video');
    videoElements.forEach((element) => {
      const src = element.getAttribute('src');
      videos.push(src);
    });

    // Extract iframes
    const iframeElements = doc.querySelectorAll('iframe');
    iframeElements.forEach((element) => {
      const src = element.getAttribute('src');
      iframe.push(src);
    });

    return { images, videos, iframe };
  };

  const fetchNewsById = async (id) => {
    setLoading(true);

    try {
      const result = await API.graphql(graphqlOperation(queries.getNews, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const fetchedNews = result?.data?.getNews;

      if (!fetchedNews) {
        throw new Error('News Not Found');
      }

      // Check and upload images if their size is greater than 500KB
      const brief = await updateImageSrcInHtml(fetchedNews.brief, getLink, getKey);
      fetchedNews.brief = brief;
      console.log('IMAGE', fetchedNews.brief);
      setLoading(false);
      return fetchedNews;
    } catch (error) {
      setLoading(false);
      console.error('Error fetching News:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteNews = async (id) => {
    try {
      setLoading(true);
      const res = await API.graphql(graphqlOperation(queries.getNews, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const type = res?.data?.getNews?.type;

      let isDeletedContentImage = true;
      let isDeletedImageUrl = true;
      let isDeletedVideo = true;

      if (type === 'NEWS' || type === 'BLOG') {
        isDeletedContentImage = await deleteFile(res?.data?.getNews?.content_img_url);
        isDeletedImageUrl = await deleteFile(res?.data?.getNews?.img_url);
      } else if (type === 'VLOG') {
        isDeletedVideo = await deleteFile(res?.data?.getNews?.video_url);
      }

      if ((type === 'NEWS' || type === 'BLOG') && isDeletedContentImage && isDeletedImageUrl) {
        await API.graphql({
          query: mutations.deleteNews,
          variables: { input: { id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        setLoading(false);
        toast.success(`${type === 'NEWS' ? 'NEWS' : 'BLOG'} Deleted Successfully!`);
      } else if (type === 'VLOG' && (res?.data?.getNews?.youtube_url === '' || isDeletedVideo)) {
        await API.graphql({
          query: mutations.deleteNews,
          variables: { input: { id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        setLoading(false);
        toast.success('VLOG Deleted Successfully!');
      } else {
        setLoading(false);
        toast.error('Something went wrong');
      }

      // Remove deleted news from the newsList
      setNewsList((prevNewsList) => prevNewsList.filter((item) => item.id !== id));

      // Remove deleted images from local storage
      localStorage.removeItem(res?.data?.getNews?.content_img_url);
      localStorage.removeItem(res?.data?.getNews?.img_url);
      localStorage.removeItem(res?.data?.getNews?.video_url);
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting News:', error);
    }
  };

  const updateNewsDetails = async (id, newData) => {
    try {
      const {
        type,
        content_img_url: contentImgKey,
        img_url: imgKey,
        title,
        brief,
        newImage,
        newContentImageUrl,
        newVideoUrl,
        view_count: view,
        content_text: contentText,
        video_url: videoKey,
        youtube_url,
      } = newData;

      setLoading(true);
      const res = await API.graphql(graphqlOperation(queries.getNews, { id }));

      let imageKey = res?.data?.getNews?.img_url || '';
      let contentImageKeys = res?.data?.getNews?.content_img_url || '';
      let videoKeys = res?.data?.getNews?.video_url || '';

      let render_img_url;
      let render_content_img_url;
      let render_video_url;
      if (type === 'NEWS' || type === 'BLOG') {
        if (newContentImageUrl) {
          localStorage.removeItem(res?.data?.getNews?.content_img_url);
          await deleteFile(res?.data?.getNews?.content_img_url); // Remove old content image URL from storage
          contentImageKeys = await uploadFile(newContentImageUrl); // Upload new content image URL
          render_content_img_url = await fetchImageFromCacheWithExpiration(contentImageKeys);
        }
        if (newImage) {
          await deleteFile(res?.data?.getNews?.img_url);
          localStorage.removeItem(res?.data?.getNews?.img_url);

          // Remove old image URL from storage
          imageKey = await uploadFile(newImage); // Upload new image URL
          render_img_url = await fetchImageFromCacheWithExpiration(imageKey);
        }
      } else if (type === 'VLOG') {
        if (newVideoUrl) {
          await deleteFile(res?.data?.getNews?.video_url); // Remove old video URL from storage
          localStorage.removeItem(res?.data?.getNews?.video_url);
          videoKeys = await uploadFile(newVideoUrl); // Upload new video URL
          console.log('keysVideo', videoKeys);
          render_video_url = await fetchImageFromCacheWithExpiration(videoKeys);
        }
      }

      const data = {
        img_url: imageKey,
        content_img_url: contentImageKeys,
        video_url: videoKeys,
        title,
        type,
        brief,
        view_count: res?.data?.getNews?.view_count,
        id,
        content_text: contentText,
        youtube_url,
      };

      await API.graphql({
        query: mutations.updateNews,
        variables: {
          input: { ...data },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const updtedData = {
        ...data,
        render_img_url,
        render_content_img_url,
        render_video_url,
      };
      // Update newsList with the updated news details
      setNewsList((prevNewsList) => {
        const updatedNewsList = prevNewsList.map((item) => {
          if (item.id === id) {
            return { ...item, ...updtedData };
          }
          return item;
        });
        return updatedNewsList;
      });

      // Remove old URLs from cache

      toast.success('Updated Successfully!');

      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating news details:', error);
      toast.error('Error updating news details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const createNews = async (data) => {
    setLoading(true);

    try {
      const updatedData = { ...data };

      const key = '';
      const key2 = '';
      const video = '';

      const render_img_url = data?.img_url;
      const render_content_img_url = null;
      const render_video_url = null;

      const response = await create(key, key2, video, updatedData);
      const newsData = {
        ...response,
        render_img_url,
        render_content_img_url,
        render_video_url,
      };

      setNewsList((prevNewsList) => [newsData, ...prevNewsList]); // Add the new news data to the news list

      // Fetch news URL from AWS and update newsList
      // Implement fetchNewsUrlFromAWS to retrieve news data

      setLoading(false);
      toast.success(`${data?.type} Posted Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating news:', error);
      throw error;
    }
  };

  const createNewsContent = async (data) => {
    setLoading(true);

    try {
      const updatedData = {
        title: data.title,
        brief: data.content,
        content_text: data.text,
        type: data.type,
      };
      const srcOfImage = ReplaceUrl(data.content);

      const keyOfFirstImage = getKey(srcOfImage);
      console.log('data: ', keyOfFirstImage);

      if (data.iframe && data.iframe.length > 0) {
        updatedData.youtube_url = data.iframe[0];
        updatedData.video_url = data.iframe[0];
      }

      if (keyOfFirstImage) {
        updatedData.img_url = keyOfFirstImage;
        updatedData.content_img_url = keyOfFirstImage;
      }

      const response = await API.graphql({
        query: mutations.createNews,
        variables: {
          input: {
            ...updatedData,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setLoading(false);
      toast.success(`${data?.type} Posted Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating news:', error);
      throw error;
    }
  };

  const updateNewsContent = async (data, id) => {
    setLoading(true);

    try {
      const updatedData = {
        title: data.title,
        brief: data.content,
        content_text: data.text,
        type: data.type,
        id,
      };
      // if (data.image && data.image.length > 0) {
      //   updatedData.content_img_url = data.image[0];
      //   updatedData.img_url = data.image;

      // }

      console.log('data: ', data);

      if (data.iframe && data.iframe.length > 0) {
        updatedData.youtube_url = data.iframe[0];
        updatedData.video_url = data.iframe[0];
      }
      const srcOfImage = ReplaceUrl(data.content);
      console.log('data: ', srcOfImage);

      const keyOfFirstImage = getKey(srcOfImage);

      if (keyOfFirstImage) {
        updatedData.img_url = keyOfFirstImage;
        updatedData.content_img_url = keyOfFirstImage;
      }

      const oldResponse = await API.graphql(graphqlOperation(queries.getNews, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const oldData = oldResponse.data.getNews;

      const allOldImageKey = await allImage(oldData.brief, getKey);
      const newImageKey = await allImage(updatedData.brief, getKey);

      console.log('New Image Key', newImageKey);

      const { notInNew: deletedImageKey } = getNonMatchingValues(allOldImageKey, newImageKey);
      console.log('DELete Deleted Image Key: ', deletedImageKey);
      deletedImageKey.map(async (key, index) => {
        await deleteFile(key);
      });

      const response = await API.graphql({
        query: mutations.updateNews,
        variables: {
          input: {
            ...updatedData,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      setLoading(false);
      toast.success(`${data?.type} Posted Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating news:', error?.errors?.message);
      throw error;
    }
  };

  const deleteNewsContent = async (id) => {
    try {
      setLoading(true);
      const result = await API.graphql(graphqlOperation(queries.getNews, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const fetchedNews = result?.data?.getNews;

      const allImageKey = await allImage(fetchedNews.brief, getKey);

      console.log('ALL Image Key', allImageKey);

      allImageKey.map(async (key) => {
        await deleteFile(key);
      });

      await API.graphql({
        query: mutations.deleteNews,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      setLoading(false);
      toast.success('VLOG Deleted Successfully!');

      // Remove deleted news from the newsList
      setNewsList((prevNewsList) => prevNewsList.filter((item) => item.id !== id));
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting News:', error);
    }
  };

  const create = async (key, key2, videoKey, updatedData) => {
    setLoading(true);

    const response = await API.graphql({
      query: mutations.createNews,
      variables: {
        input: {
          ...updatedData,
          img_url: key,
          content_img_url: key2,
          video_url: videoKey,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const newsData = response?.data?.createNews;

    setLoading(false);
    return newsData;
  };
  useEffect(() => {
    fetchNews('');
  }, [nextToken]);

  return {
    news,
    blog,
    vlog,
    newsList,
    nextNextToken,
    loading,
    deleteNews,
    fetchNextPage,
    fetchNews,
    fetchPreviousPage,
    previousTokens,
    createNews,
    updateNewsDetails,
    createNewsContent,
    updateNewsContent,
    deleteNewsContent,
    fetchNewsById,
  };
};

export default useHandleNews;
