import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHandleComptetiveTeams } from 'src/hooks/handleComptetiveTeams';
import {useHandleComptetiveCoach } from 'src/hooks/handleComptetiveCoach';
import Loader from 'src/components/Loader';
import CreateComptetiveTeamsModalOne from './CreateComptetiveModal';
import useCityList from 'src/hooks/getCityList';
import uploadFile from 'src/utils/uploadFile';
import Details from './Details';

const ages = [
    { value: "u7", label: "U7" },
    { value: "u8", label: "U8" },
    { value: "u9", label: "U9" },
    { value: "u10", label: "U10" },
    { value: "u11", label: "U11" },
    { value: "u12", label: "U12" },
    { value: "u13", label: "U13" },
    { value: "u14", label: "U14" },
    { value: "u15", label: "U15" },
    { value: "u17", label: "U17" },
    { value: "u18", label: "U18" },
    { value: "u19-u39", label: "U19-U39" },
    { value: "over u40", label: "Over U40" }
  ];

export default function ComptetiveTeams() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState({
        name: "",
        agegroup: "",
        imageURL: null,
        location: "",
        city: "",
        coachId: ""
    });

    const [error, setError] = useState('');

    const [comptetiveTeams, setComptetiveTeams] = useState([]);
    const [comptetiveCoach, setComptetiveCoach] = useState([]);
    const { cities, loading: cityLoading, error: cityError } = useCityList();

    const { tournamentEvents } = useSelector((state) => state.tournamentEvents);
    const { createTeam, loading: comptetiveLoading, updateTeam, deleteTeam, getAllTeams, getTeamById } = useHandleComptetiveTeams();
    const { loading: comptetiveCoachLoading,getAllCoachs } = useHandleComptetiveCoach();

    const [agegroup, setAgegroup] = useState('');

    const [showModal, setShowModal] = useState(false);


    const handleShowModals = () => {
        setShowModal(true);
    };

    const handleCloseModals = () => {
        setShowModal(false);
    };


    const handleInputValue = (e) => {
        const { name, value, type, files } = e.target;

        if (type === "file" && files.length > 0) {
            setFields((previous) => ({
                ...previous,
                [name]: files[0],
            }));
        } else {
            if (name === 'city') {
                setFields((previous) => ({
                    ...previous,
                    location: value,
                }));
            }
            setFields((previous) => ({
                ...previous,
                [name]: value,
            }));
        }
    };
    const createNewTeam = async () => {
        setLoading(true);

        try {
            // ComptetiveTeamSchema.parse(fields);  // Validate schema
            console.log("Data", fields.imageURL);

            let imageURL = "";
            if (!fields.imageURL) {
                toast.error("Image needed");
                setLoading(false);
                handleCloseModals();
                return;
            } else {


                imageURL = await uploadFile(fields.imageURL);
            }

            if (!imageURL) {  // Ensure imageURL is set after upload
                toast.error("Image needed");
                setLoading(false);
                handleCloseModals();
                return;
            }

            const updateAndSend = {
                ...fields,
                imageURL,
            };

            const addThis = await createTeam(updateAndSend);

            setComptetiveTeams((prev) => [...prev, addThis]);

            setFields({
                name: "",
                coachId: "",
                ageGroup: "",
                imageURL: null,
                location: "",
                city: ""
            });

        } catch (error) {
            console.log("Error", error);
            setError(error);
        } finally {
            setLoading(false);
            handleCloseModals();
        }
    };




    const getAllComptetiveTeams = async () => {
        setLoading(true);
        try {
            const data = await getAllTeams();
            const coachData = await getAllCoachs();
            console.log("data", data);
           console.log("coachData", coachData);
            setComptetiveTeams(data);
            setComptetiveCoach(coachData);
        } catch (error) {
            console.log("Error", error)
            toast.error('error')
        } finally {
            setLoading(false);
        }

    };

    const deleteAndRemove = async (id) => {
        try {
            setComptetiveTeams((previousData) => previousData.filter(team => team.id !== id));
            await deleteTeam(id);
        } catch (error) {
            console.log("Error", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {


        getAllComptetiveTeams();

    }, []);



    if (loading || cityLoading || comptetiveLoading || comptetiveCoachLoading) {
        return <Loader />;
    }


    return (
        <>
            <ToastContainer />
            {/* Render ModalOne component conditionally */}
            {showModal && (
                <CreateComptetiveTeamsModalOne
                    title="Add Team"
                    handleClose={handleCloseModals}
                    handleImageUpload={handleInputValue}
                    handleInputChange={handleInputValue}
                    handleSelectChange={handleInputValue}
                    selectedAgeGroup={fields["agegroup"]}
                    handleAdd={createNewTeam}
                    buttonText="Add Team"
                    inputLabel="Team Name"
                    options={ages}
                    showCityDropdown={true}
                    selectedCity={fields["city"]}
                    cityOptions={cities}
                    handleCityChange={handleInputValue}
                    showCoachDropdown={true}
                    selectedCoach={fields["coach"]}
                    coachOptions={comptetiveCoach}
                    handleCoachChange={handleInputValue}
                />
            )}

            <Container maxWidth="lg">
                <Box mt={5}>
                    <Details deleteThis={deleteAndRemove} label={'Comptetive Teams'} addDetails={handleShowModals} data={comptetiveTeams || []} />
                </Box>
            </Container>
        </>
    );
}
