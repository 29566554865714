import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopAddPriceCard from './AddPriceCard';

// ----------------------------------------------------------------------

AddPriceList.propTypes = {
  addPrices: PropTypes.array.isRequired,
};

export default function AddPriceList({ addPrices, loading, updateAddPrices, deleteAddPrices, ...other }) {
  return (
     <Grid container spacing={3} {...other}>
       {addPrices?.map((addPrice) => (
         <Grid key={addPrice.id} item xs={12} sm={6} md={3}>
           <ShopAddPriceCard
             updateAddPrices={updateAddPrices}
             deleteAddPrices={deleteAddPrices}
             addPrice={addPrice}
           />
         </Grid>
       ))}
     </Grid>
  );
 }
 
