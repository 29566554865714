/* eslint-disable consistent-return */
/* eslint-disable consistent-return, prefer-const, no-plusplus, no-await-in-loop, no-undef */

// Your code here...

import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries'; // Import your GraphQL queries for listing teams
import * as mutations from '../graphql/mutations'; // Import your GraphQL queries for listing teams

function useStartTournamentScoreNew() {
  const [teams, setTeams] = useState([]);
  const [event, setEvent] = useState();

  const [players, setPlayers] = useState([]);
  let flattenedPlayers = [];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function startTournaments(tournament) {
    try {
      setLoading(true);
      // Fetch the events list
      // const response = await API.graphql({
      //   query: queries.listLeagues,
      //   variables: {
      //     filter: {
      //       eventStatus: {
      //         eq: 'EVENT_NOT_STATRED',
      //       },
      //       title: {
      //         eq: event,
      //       },
      //     },
      //   },
      //   authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      const { id, teamsId, teamsName, round } = tournament;
      const response = await API.graphql(graphqlOperation(queries.getTournamentLeague, { id }));

      // const eventsLists = response?.data?.listLeagues?.items;

      const league = response?.data?.getTournamentLeague;
      delete league.scoreID;
      delete league.createdAt;
      delete league.updatedAt;
      delete league.__typename;
      if (league.eventStatus === 'EVENT_ENDED') {
        toast.error('Already Ended');
        return;
      }
      console.log('League', league);

      league.teamsName.forEach((player) => {
        delete player.__typename; // Remove __typename from each player in secondTeamDetails
        console.log('Teams', player);

        // delete player.secondTeamId.__typename;
        // delete player.teamLogoUrl;
      });
      league.teamsId.forEach((player) => {
        console.log('Teams', player);
        delete player.__typename; // Remove __typename from each player in secondTeamDetails
        // delete player.secondTeamId.__typename;
        // delete player.teamLogoUrl;
      });
      const allTeamsName = league?.teamsName;
      const allTeamsId = league?.teamsId;

      const updated = await API.graphql({
        query: mutations.updateTournamentLeague,
        variables: {
          input: {
            id: league.id,
            ...league,
            teamsId,
            teamsName,
            allTeamsName,
            allTeamsId,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const teamsIds = updated.data.updateTournamentLeague.teamsId;
      console.log('TournamentLeague', league);
      console.log('TournamentLeague Teams Id', teamsIds);
      if (!teamsIds || teamsIds.length < 1 || !league.teamsName || league.teamsName.length < 1) {
        toast.error('Insufficient teams to create matches');
        throw new Error('Insufficient teams to create matches.');
      }
      const listScores = await API.graphql({
        query: queries.listScores,
        variables: {
          filter: {
            eventStatus: {
              eq: 'EVENT_ENDED',
            },
            eventId: {
              eq: league.id,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const filteredScores = listScores.data.listScores.items;
      console.log('filteredScores', filteredScores, listScores);
      let maxMatchNumber = 0;
      filteredScores?.forEach((score) => {
        if (score.matchNumber > maxMatchNumber) {
          maxMatchNumber = score.matchNumber;
        }
      });

      const newMatchNumber = maxMatchNumber !== 0 ? maxMatchNumber + 1 : 1;
      const matches = [];
      console.log('teamIds', teamsId);

      for (let i = 0; i < teamsIds.length; i++) {
        const teamId = teamsIds[i];
        if (teamId.firstTeamId && teamId.secondTeamId) {
          console.log('teamId', teamId, i);
          console.log('first.TeamId', teamId.firstTeamId);
          console.log('firstTeamId', teamId.secondTeamId);

          const firstTeamPlayerResponse = await API.graphql({
            query: queries.listTournaments,
            variables: {
              filter: {
                agegroup: {
                  eq: league.agegroup,
                },
                tournament: {
                  eq: league.title,
                },
                teamID: {
                  eq: teamId.firstTeamId,
                },
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          const secondTeamPlayerResponse = await API.graphql({
            query: queries.listTournaments,
            variables: {
              filter: {
                agegroup: {
                  eq: league.agegroup,
                },
                tournament: {
                  eq: league.title,
                },
                teamID: {
                  eq: teamId.secondTeamId,
                },
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          // const team1 = await API.graphql(graphqlOperation(queries.listPlayers, ));
          const team1 = await API.graphql(graphqlOperation(queries.getTournamentTeam, { id: teamId.firstTeamId }));
          const team2 = await API.graphql(graphqlOperation(queries.getTournamentTeam, { id: teamId.secondTeamId }));
          // console.log('team1', team2);
          // console.log('team2', team2);

          const team1Players = firstTeamPlayerResponse?.data?.listTournaments?.items;
          const team2Players = secondTeamPlayerResponse?.data?.listTournaments?.items;
          console.log('firstTeamPlayerResponse', firstTeamPlayerResponse);
          console.log('secondTeamPlayerResponse', secondTeamPlayerResponse);
          console.log('team1Players', team1Players);
          console.log('team2Players', team2Players);

          console.log('TournamentLeagueTeam1', team1Players, team2Players);
          if (!team1Players || team1Players.length < 4 || !team2Players || team2Players?.length < 4) {
            toast.error(`Team does not have enough players`);
            setLoading(false);
            return;
            // throw new Error(`Team with ID does not have enough players.`);
          }

          const teamPlayers = [];

          // for (let j = 0; j < players.length; j++) {
          //   const playerId = players[j];
          //   const player = await API.graphql(graphqlOperation(queries.getPlayer, { id: playerId }));
          //   teamPlayers.push(player?.data?.getPlayer);
          // }
          // const team1PlayersId=team1Players.filter((player)=)
          console.log('team1Players', team1Players, team1);
          console.log('team2Players', team2Players, team1);

          const teams1PlayersDetails = await findPlayers(team1Players, team1);
          const teams2PlayersDetails = await findPlayers(team2Players, team2);
          // const teams1PlayersDetails = team1Players;
          // const teams2PlayersDetails = team2Players;

          console.log('teams1PlayersDetails', teams1PlayersDetails);
          console.log('teams2PlayersDetails', teams2PlayersDetails);
          if (teams1PlayersDetails.length !== 4 && teams2PlayersDetails.length !== 4) {
            toast.error(`Team ${teams1PlayersDetails} does not have exactly 4 players`);
            throw new Error(`Team  ${teams1PlayersDetails} does not have exactly 4 players.`);
          }
          console.log('teams1PlayersDetails', teams1PlayersDetails);

          const teams1Details = {
            teamId: team1.data.getTournamentTeam.id,
            teamName: team1.data.getTournamentTeam.name,
            teamLogo: team1.data.getTournamentTeam.imageURL,
          };

          console.log('teams1Details', teams1Details);

          const teams2Details = {
            teamId: team2.data.getTournamentTeam.id,
            teamName: team2.data.getTournamentTeam.name,
            teamLogo: team2.data.getTournamentTeam.imageURL,
          };

          console.log('teams2Details', teams2Details);

          const match = {
            teams: [teams1Details, teams2Details],
            playerDetails: {
              teams1PlayersDetails,
              teams2PlayersDetails,
            },
          };
          console.log('match', match);

          matches.push(match);
        }
      }
      const scores = [];
      console.log('scoresssssssss', scores);

      console.log('matches', matches);

      for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        console.log(`match${[i]}`, match.teams);

        console.log(`leagueMatch${[i]}`, league);

        const score = {
          event: league.title,
          eventId: league.id,
          eventImage: league.img_url,
          firstTeam: match.teams[0],
          secondTeam: match.teams[1],
          playerDetails: [...match.playerDetails.teams1PlayersDetails, ...match.playerDetails.teams2PlayersDetails],
          agegroup: league.agegroup,
          firstTeamDetails: match.playerDetails.teams1PlayersDetails,
          secondTeamDetails: match.playerDetails.teams2PlayersDetails,
          teams: match.teams,
          eventStatus: 'EVENT_STARTED',
          round,
          tournament: true,
          ...rank,
        };
        console.log('score', score);
        // const variables={
        //   input: {
        //     event: league.title,
        //     eventId: league.id,
        //     eventImage: league.img_url,
        //     firstTeam: match.teams[0],
        //     secondTeam: match.teams[1],
        //     playerDetails: [...match.playerDetails.teams1PlayersDetails,...match.playerDetails.teams2PlayersDetails],
        //     city: league.location,
        //     firstTeamDetails: match.playerDetails.teams1PlayersDetails,
        //     secondTeamDetails: match.playerDetails.teams2PlayersDetails,
        //     teams:match.teams,
        //     ...rank,
        //   },
        // }
        // const response = await API.graphql(graphqlOperation(mutations.createScore,variables));

        // await API.graphql({
        //   query: mutations.createScore, // Use 'mutation' instead of 'queries'
        //   variables: {
        //     input: {
        //       event: league.title,
        //       eventId: league.id,
        //       eventImage: league.img_url,
        //       // firstTeam: match.teams[0],
        //       // secondTeam: match.teams[1],
        //       playerDetails: [match.playerDetails.teams1PlayersDetails, match.playerDetails.teams2PlayersDetails],
        //       city: league.location,
        //       firstTeamDetails: match.playerDetails.teams1PlayersDetails,
        //       secondTeamDetails: match.playerDetails.teams2PlayersDetails,
        //       teams:match.teams,
        //       ...rank,
        //     },
        //   },
        //   authMode:GRAPHQL_AUTH_MODE.API_KEY
        // });

        scores.push(score);
        console.log('Scores', scores);
      }

      let ScoresId = [];

      scores.map(async (score) => {
        console.log('score', score);
        const scoresRes = await API.graphql({
          query: mutations.createScore, // Use 'mutation' instead of 'queries'
          variables: {
            input: {
              matchNumber: newMatchNumber,
              ...score, // Wrapping 'score' inside the 'input' field
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        ScoresId.push(scoresRes.data.createScore.id);
      });

      const winnerTeamFetched = await API.graphql({
        query: queries.listWinners,
        variables: {
          filter: {
            eventId: {
              eq: league.id,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const winnerRes = winnerTeamFetched.data.listWinners.items[0];

      const roundsTeamsFetched = await API.graphql({
        query: queries.listRounds,
        variables: {
          filter: {
            eventId: {
              eq: league.id,
            },
            round: {
              eq: tournament.round,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const rounds = roundsTeamsFetched.data.listRounds.items[0];

      const latestScoresId = [...ScoresId, ...winnerRes.matches];
      const latestRoundsId = [...ScoresId, ...rounds.matches];

      let teams = [];
      const runnerUpList = tournament.teamsId?.map((tour) => {
        console.log('tour', tour);
        teams.push(tour.firstTeamId);
        teams.push(tour.secondTeamId);
        console.log('teamsss', teams);

        return tour;
      });
      console.log('teamsss', teams);
      let latestRoundsTeams = [];
      if (tournament.round === winnerRes.totalRounds) {
        latestRoundsTeams = [...rounds.runnerUpList];
      } else {
        latestRoundsTeams = rounds.runnerUpList.filter((round) => {
          return !teams.includes(round);
        });
      }
      console.log('Tournament Id Teams: ', latestRoundsTeams);

      await API.graphql({
        query: mutations.updateWinner, // Use 'mutation' instead of 'queries'
        variables: {
          input: {
            matches: latestScoresId,
            id: winnerRes.id,
            eventId: league.id,
            eventName: winnerRes.eventName,
            eventStatus: winnerRes.eventStatus,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      await API.graphql({
        query: mutations.updateRounds, // Use 'mutation' instead of 'queries'
        variables: {
          input: {
            matches: latestRoundsId,
            id: rounds.id,
            eventId: league.id,
            eventName: rounds.eventName,
            runnerUpList: latestRoundsTeams,

            eventStatus: rounds.eventStatus,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      league.teamsId.forEach((player) => {
        delete player.__typename; // Remove __typename from each player in secondTeamDetails
        delete player.playerImgUrl;
        delete player.teamLogoUrl;
      });
      league.teamsName.forEach((player) => {
        delete player.__typename; // Remove __typename from each player in secondTeamDetails
        delete player.playerImgUrl;
        delete player.teamLogoUrl;
      });
      league.allTeamsName.forEach((player) => {
        delete player.__typename; // Remove __typename from each player in secondTeamDetails
        console.log('Teams', player);

        // delete player.secondTeamId.__typename;
        // delete player.teamLogoUrl;
      });
      league.allTeamsId.forEach((player) => {
        console.log('Teams', player);
        delete player.__typename; // Remove __typename from each player in secondTeamDetails
        // delete player.secondTeamId.__typename;
        // delete player.teamLogoUrl;
      });
      console.log('League', league);
      const res = await API.graphql({
        query: mutations.updateTournamentLeague,
        variables: {
          input: {
            id: league.id,
            ...league,
            eventStatus: 'EVENT_STARTED',
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      console.log('success', league);

      toast.success('Event Started');

      // Now you can use `scores` array containing details of all matches.

      setLoading(false);
    } catch (error) {
      if (!error === 'Error: Error: Body must be a string. Received: undefined.') {
        toast.error(`Something Went Wrong! `, error);
        setError(error);
        console.error('Error', error);
        throw new Error(error);
      }
      setLoading(false);
    }
  }

  return { startTournaments, teams, players, loading, error };
}
const stats = {
  GP: '0',
  MIN: '0',
  PTS: '0',
  FGM: '0',
  FGA: '0',
  FGPERCENT: '0',
  THREEPM: '0',
  THREEPA: '0',
  THREEPPERCENT: '0',
  FTM: '0',
  FTA: '0',
  FTPERCENT: '0',
  OREB: '0',
  DREB: '0',
  REB: '0',
  AST: '0',
  TOV: '0',
  STL: '0',
  BLK: '0',
  PF: '0',
  FP: '0',
  DDTWO: '0',
  TDTHREE: '0',
  PlusMinus: '0',
};

const rank = {
  record: '0',
  ppg: '0',
  rpe: '0',
  apg: '0',
  ranking: '0',
};

const findPlayers = async (players, team) => {
  const teamPlayers = [];
  console.log('PlayersDetails', players);
  console.log('Team', team);

  for (let j = 0; j < players.length; j++) {
    const playerId = players[j];
    const response = await API.graphql(graphqlOperation(queries.getTournament, { id: playerId.id }));
    if (!response.data.getTournament) {
      toast.error('Tournament Player Not Found Id:', playerId);
      return false;
    }
    const player = response.data.getTournament;
    console.log('Player', player);

    const playerDetails = {
      teamId: player.teamID,
      teamName: player.teamName,
      teamLogo: team.data.getTournamentTeam.imageURL,
      playerId: player.id,
      playerName: player.participantName,
      playerImage: player.profile,

      ...stats,
    };
    teamPlayers.push(playerDetails);
  }

  return teamPlayers;
};

export default useStartTournamentScoreNew;

/* eslint-enable consistent-return, prefer-const, no-plusplus, no-await-in-loop, no-undef */
