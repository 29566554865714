import {
  CREATE_AD_REQUEST,
  CREATE_AD_SUCCESS,
  CREATE_AD_FAIL,
  UPDATE_AD_REQUEST,
  UPDATE_AD_SUCCESS,
  UPDATE_AD_FAIL,
  DELETE_AD_REQUEST,
  DELETE_AD_SUCCESS,
  DELETE_AD_FAIL,
  GET_AD_REQUEST,
  GET_AD_SUCCESS,
  GET_AD_FAIL,
  CLEAR_AD_ERRORS,
  ALL_AD_REQUEST,
  ALL_AD_SUCCESS,
  ALL_AD_FAIL,
} from '../constants/AdsConstants';

const initialState = {
  ads: [],
  adCount: 0,
  resultPerPage: 0,
  ad: {},
  isUpdated:false,
  loading: false,
  error: null,
  filteredAdsCount: 0,
};

export const adsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_AD_REQUEST:
    case GET_AD_REQUEST:
    case CREATE_AD_REQUEST:
    case DELETE_AD_REQUEST:
    case UPDATE_AD_REQUEST:
      return {
        ...state,
        loading: true,
        ads: [],
        ad: {},
      };
    case ALL_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        ads: action.payload.ads,
      
        adCount: action.payload.adsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredAdsCount: action.payload.filteredAdsCount,
      };
    case CREATE_AD_SUCCESS:
    case GET_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        ad: action.payload,
      };
    case DELETE_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case GET_AD_FAIL:
    case ALL_AD_FAIL:
    case CREATE_AD_FAIL:
    case UPDATE_AD_FAIL:
    case DELETE_AD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_AD_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



