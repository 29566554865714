/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
  Stack,
  Box,
  Typography,
  Container,
  Paper,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from '../../../components/iconify';
import useGetTeams from '../../../hooks/getTeamsList';

import useCityList from '../../../hooks/getCityList';
import useAddNews from '../../../hooks/addNews';
import useUpdateNewsDetails from '../../../hooks/updateNews';
import { getAllNews } from '../../../redux/actions/NewsAction';
import Loader from '../../../components/Loader';

AddNewsSideBar.propTypes = {
  openEvent: PropTypes.bool,
  onOpenEvent: PropTypes.func,
  onCloseEvent: PropTypes.func,
};

export default function AddNewsSideBar({
  setLoading,
  hidden = false,
  createNews,
  updateNewsDetails,
  news,
  setNewsList,
  openEvent,
  onOpenEvent,
  onCloseEvent,
  editData,
  fetchLeagues,
}) {
  const [title, setTitle] = useState('');
  const [brief, setBrief] = useState('');
  const [id, setId] = useState('');
  const dispatch = useDispatch();

  const [contentImgUrl, setContentImgUrl] = useState('');
  const [newContentImageUrl, setNewContentImgUrl] = useState('');
  const [newImage, setNewImgUrl] = useState('');
  const [newVideoUrl, setNewVideoUrl] = useState('');

  // const [viewCount, setViewCount] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [contentText, setContentText] = useState('');
  const [type, setType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState(''); // Initialize selectedDate state
  const [videoUrl, setVideoUrl] = useState('');
  const [videoFile, setVideoFile] = useState(null); // Initialize videoUrl state
  const [videoUrlText, setVideoUrlText] = useState(''); // Initialize videoUrl state

  const isEditMode = !!editData; // Check if editData is present
  const { teams } = useGetTeams();
  const { cities } = useCityList();

  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      setTitle(editData.title || '');
      setBrief(editData.brief || '');
      setContentImgUrl(editData.content_img_url || '');
      setImageUrl(editData.imageUrl || '');
      setContentText(editData.content_text || '');
      setId(editData.id || '');
      setType(editData.type || '');
      setSelectedDate(editData.selectedDate || '');
      // setViewCount(editData.viewCount || '');
      setVideoUrl(editData?.video_url || '');
      setVideoUrlText(editData.youtube_url || '');
      setVideoFile(editData?.video_url || null);

      // Set other fields accordingly
    }
  }, [editData, isEditMode]);
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  // ... (other code)

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value); // Update selectedDate state when the date is changed
  };

  const handleVideoUrl = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      setVideoFile(file);
      setNewVideoUrl(file);

      reader.onload = (e) => {
        const fileContents = e.target.result;
        // setVideoFile(fileContents);
      };

      reader.readAsDataURL(file);
    } else {
      setVideoFile(null);
    }
  };

  // const handleViewCount = (event) => {
  //   setViewCount(event.target.value); // Update selectedDate state when the date is changed
  // };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const resetForm = () => {
    setTitle('');
    setBrief('');
    setContentImgUrl('');
    setImageUrl('');
    setContentText('');
    setType('');
    setErrorMessage('');
    setSelectedDate('');
    // setViewCount('');
    setVideoUrl('');
    setVideoFile('');
    setVideoUrlText('');
  };

  const handleCancel = () => {
    resetForm(); // Call the resetForm function to clear all fields
    onCloseEvent(); // Close the modal
  };
  const handleBriefChange = (event) => {
    setBrief(event.target.value);
    setErrorMessage('');
  };

  const handleImage = (event) => {
    const image = event.target.files[0];
    console.log('iamge', image);
    // Handle file upload here if needed
    if (image) {
      setImageUrl(image);
      setNewImgUrl(image);
    } else {
      setImageUrl('');
    }
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    console.log('type', event.target.value);
  };

  const handleContentText = (event) => {
    setContentText(event.target.value);
  };
  const handleVideoUrlText = (event) => {
    setVideoUrlText(event.target.value);
  };

  const handleContentImgUrlChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setContentImgUrl(selectedImage);
      setNewContentImgUrl(selectedImage);
    } else {
      setContentImgUrl('');
    }
  };

  const handleUploadData = async () => {
    setLoading(true);
    console.log('news', news);

    const currentDate = new Date();
    const date = currentDate.toISOString();
    onCloseEvent();

    let data;

    if (type === 'NEWS') {
      data = {
        id,
        title,
        brief,

        content_img_url: contentImgUrl,
        img_url: imageUrl,
        type,
        date,
        content_text: contentText,
        // view_count:viewCount,
        video_url: '',
        newImage,
        newContentImageUrl,
        newVideoUrl,
      };

      if (isEditMode) {
        const response = await updateNewsDetails(id, data);
      } else {
        delete data.newImage;
        delete data.newContentImageUrl;
        delete data.newVideoUrl;
        delete data.id;

        const response = await createNews(data);
        console.log('create response:', response);
      }
    }
    if (type === 'BLOG') {
      data = {
        id,
        title,

        brief,
        content_img_url: contentImgUrl,
        img_url: imageUrl,
        type,
        date,
        content_text: contentText,
        // view_count:viewCount,

        video_url: '',
        newImage,
        newContentImageUrl,
        newVideoUrl,
      };
    }
    if (type === 'VLOG') {
      const formData = new FormData();
      formData.append('inputFile', videoFile);
      data = {
        id,
        title,
        brief,

        content_text: '',
        youtube_url: videoUrlText,
        content_img_url: '',
        img_url: '',
        type,
        // view_count:viewCount,

        date,
        video_url: videoFile,
        newImage,
        newContentImageUrl,
        newVideoUrl,
      };
    }
    // Check if brief and content_img_url:contentImgUrl are the same
    const newsData = {
      title,
      brief,
      content_img_url: contentImgUrl,
      img_url: imageUrl,
      content_text: contentText,
      type,
      // view_count: viewCount,
      date,
      id,
      video_url: videoFile,
      // videoUrlText
    };

    if (type === 'VLOG' || type === 'BLOG') {
      if (isEditMode) {
        const response = await updateNewsDetails(id, data);
      } else {
        delete data.newImage;
        delete data.id;

        delete data.newContentImageUrl;
        delete data.newVideoUrl;

        const response = await createNews(data);
      }
    }

    dispatch(getAllNews());
    setLoading(false);

    // Handle data upload logic here
    // Reset form fields
    setTitle('');
    setBrief('');
    setContentImgUrl('');
    setImageUrl('');
    setContentText('');
    setType(null);
    setErrorMessage('');
    setSelectedDate('');
    // setViewCount('');
    setVideoUrl('');
    // Close the modal
    onCloseEvent();
  };

  // if(loading){
  //   return <Loader />;
  // }

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenEvent}
        hidden={hidden}
        style={buttonStyles}
      >
        Add News
      </Button>
      <Dialog open={openEvent} onClose={handleCancel}>
        <DialogTitle>{isEditMode ? 'Edit News' : 'Add New News'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new news.</DialogContentText>
          <FormControl fullWidth margin="dense">
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              label="Type"
              name="type"
              value={type}
              fullWidth
              className="uppercase"
              variant="outlined"
              onChange={handleTypeChange}
            >
              <MenuItem className="uppercase" value="NEWS" defaultValue="NEWS" defaultChecked>
                NEWS
              </MenuItem>
              <MenuItem className="uppercase" value="VLOG">
                VLOG
              </MenuItem>
              <MenuItem className="uppercase" value="BLOG">
                BLOG
              </MenuItem>
            </Select>
          </FormControl>

          {type === 'BLOG' && (
            <>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Title"
                  fullWidth
                  value={title}
                  onChange={handleTitleChange}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Brief"
                  fullWidth
                  value={brief}
                  onChange={handleBriefChange}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Content Text"
                  fullWidth
                  value={contentText}
                  onChange={handleContentText}
                />
              </FormControl>
              {errorMessage && (
                <Typography variant="body2" color="error" sx={{ marginTop: 2, marginBottom: 2 }}>
                  {errorMessage}
                </Typography>
              )}

              <FormControl fullWidth margin="dense">
                <label htmlFor="contentImageUrl">
                  <input
                    id="contentImageUrl"
                    name="contentImageUrl"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    // value={contentImgUrl}
                    onChange={handleContentImgUrlChange}
                  />
                  <Button className="btn-choose" variant="outlined" component="span" fullWidth>
                    Choose Content Image
                  </Button>
                  {contentImgUrl && (
                    <Typography variant="body2">Selected Content Image: {contentImgUrl.name}</Typography>
                  )}
                </label>
              </FormControl>
            </>
          )}
          {type === 'NEWS' && (
            <>
              <TextField autoFocus margin="dense" label="Title" fullWidth value={title} onChange={handleTitleChange} />
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Brief"
                  fullWidth
                  value={brief}
                  onChange={handleBriefChange}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Content Text"
                  fullWidth
                  value={contentText}
                  onChange={handleContentText}
                />
              </FormControl>
              {errorMessage && (
                <Typography variant="body2" color="error" sx={{ marginTop: 2, marginBottom: 2 }}>
                  {errorMessage}
                </Typography>
              )}

              <FormControl fullWidth margin="dense">
                <label htmlFor="contentImageUrl">
                  <input
                    id="contentImageUrl"
                    name="contentImageUrl"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    // value={contentImgUrl}
                    onChange={handleContentImgUrlChange}
                  />
                  <Button className="btn-choose" variant="outlined" component="span" fullWidth>
                    Choose Content Image
                  </Button>
                  {contentImgUrl && (
                    <Typography variant="body2">Selected Content Image: {contentImgUrl.name}</Typography>
                  )}
                </label>
              </FormControl>
            </>
          )}
          {(type === 'NEWS' || type === 'BLOG') && (
            <FormControl fullWidth margin="dense">
              <label htmlFor="imageUrl">
                <input
                  id="imageUrl"
                  name="imageUrl"
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/*"
                  onChange={handleImage}
                />
                <Button className="btn-choose" variant="outlined" component="span" fullWidth>
                  Choose Image
                </Button>
                {imageUrl && <Typography variant="body2">Selected Image: {imageUrl?.name}</Typography>}
              </label>
            </FormControl>
          )}

          {type === 'VLOG' && (
            <>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Title"
                  fullWidth
                  value={title}
                  onChange={handleTitleChange}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Brief"
                  fullWidth
                  value={brief}
                  onChange={handleBriefChange}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Youtube Url"
                  name="youtubeUrl"
                  fullWidth
                  value={videoUrlText}
                  onChange={handleVideoUrlText}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <label htmlFor="videoUrl">
                  <input
                    id="videoUrl"
                    name="videoUrl"
                    style={{ display: 'none' }}
                    type="file"
                    accept="video/*" // Accept video files only
                    onChange={handleVideoUrl}
                  />
                  <Button className="btn-choose" variant="outlined" component="span" fullWidth>
                    Choose Video
                  </Button>
                  {videoUrl && <Typography variant="body2">Selected Video URL: {videoUrl}</Typography>}
                  {videoFile && <Typography variant="body2">Selected Video: {videoFile?.name}</Typography>}
                </label>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={handleCancel} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
