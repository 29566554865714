import React, { useState, useEffect, useCallback } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { TextField, TextareaAutosize, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../components/Loader';
import useHandlePaginationCampCourse from '../hooks/handlePaginationCampCourse';
import useHandleTutor from '../hooks/handleTutor';
import { useCreateAppointment, useGetAppointmentDetails } from '../hooks/createMeetSecond';
import Tutor from '../components/Meet/RenderTutor';

export const ToggleButton = ({ label, checked, onChange }) => (
  <div className="flex items-center gap-2">
    <input type="checkbox" checked={checked} onChange={onChange} className="px-2 py-2" />
    <p>{label}</p>
  </div>
);

const weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const NewCampMeet = () => {
  const { id } = useParams();
  const [course, setCourse] = useState('');
  const [duration, setDuration] = useState('');
  const [title, setTitle] = useState('');
  const [tutor, setTutor] = useState('');
  const [maxInvitees, setMaxInvitees] = useState('');
  const [tutorDetails, setTutorDetails] = useState([]);
  const { pathname } = useLocation();
  // const type = pathname.includes('newClinicMeet') ? 'CLINIC' : 'TRAINING_PROGRAM';
  const type = pathname.includes('newClinicMeet')
  ? 'CLINIC'
  : pathname.includes('newCampMeet')
  ? 'CAMP'
  : 'TRAINING_PROGRAM';

  const [days, setDays] = useState([]);
  const [dates, setDates] = useState([]);
  const [customDuration, setCustomDuration] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [errors, setErrors] = useState({
    duration: false,
    maxInvitees: false,
  });
  const [everyDay, setEveryDay] = useState(false);
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const { loading, createAppointment } = useCreateAppointment();
  const { getAppointmentDetails, updateAppointment, appointmentData } = useGetAppointmentDetails(id);

  const { tutorList, isLoading, error } = useHandleTutor();
  const {
    campCourseList: courseList,
    nextNextToken,
    loading: courseLoading,
    fetchNextPage,
    fetchCourse,
    fetchPreviousPage,
    previousTokens,
  } = useHandlePaginationCampCourse();

  useEffect(() => {
    if (course) {
      const selectedCourse = courseList.find((c) => c.id === course);
      if (selectedCourse) {
        setTitle(selectedCourse.title);
        setMaxInvitees(selectedCourse.quantity);
        setPrice(selectedCourse.price);
        setDescription(selectedCourse.description);
      }
    }
  }, [course, courseList]);

  const handleDurationChange = (e) => {
    const selectedOption = e.target.value;
    setDuration(selectedOption);
    if (selectedOption === 'custom') {
      setCustomDuration('');
    } else {
      setCustomDuration('');
    }
  };

  const handleCustomDurationChange = (e) => {
    setCustomDuration(e.target.value);
  };

  const handleAddSlot = () => {
    if ((duration || customDuration) && selectedTimeSlot && tutor) {
      const [start, end] = selectedTimeSlot.split(' - ');
      const newSlot = {
        startTime: new Date(selectedDate.setHours(...start.split(':'))).toISOString(),
        endTime: new Date(selectedDate.setHours(...end.split(':'))).toISOString(),
      };
      const tutorIndex = tutorDetails.findIndex((tut) => tut.tutorId === tutor);
      if (tutorIndex >= 0) {
        const existingSlots = tutorDetails[tutorIndex].slots || [];
        if (!existingSlots.some((slot) => slot.startTime === newSlot.startTime && slot.endTime === newSlot.endTime)) {
          const updatedTutorDetails = [...tutorDetails];
          updatedTutorDetails[tutorIndex].slots = [...existingSlots, newSlot];
          setTutorDetails(updatedTutorDetails);
          setSelectedTimeSlot('');
        } else {
          toast.error('This slot is already selected');
        }
      } else {
        toast.error('Select a tutor first');
      }
    }
  };

  const handleRemoveSlot = (tutorIndex, slotIndex) => {
    const updatedTutorDetails = [...tutorDetails];
    updatedTutorDetails[tutorIndex].slots.splice(slotIndex, 1);
    setTutorDetails(updatedTutorDetails);
  };

  const handleRemoveTutor = (tutorIndex) => {
    if (tutorDetails.length > 1) {
      const updatedTutorDetails = tutorDetails.filter((_, index) => index !== tutorIndex);
      setTutorDetails(updatedTutorDetails);
    } else {
      toast.error('At least one tutor is required.');
    }
  };

  const createProgram = async () => {
    const isValidTutorDetails = tutorDetails.every(
      (tut) => tut.daily || (tut.dates && tut.dates.length > 0) || (tut.days && tut.days.length > 0)
    );

    if (!isValidTutorDetails) {
      toast.error('Each tutor must have at least one of daily, dates, or days specified.');
      return;
    }

    if (!course || (!duration && !customDuration) || !maxInvitees || !price || !description || tutorDetails.length === 0) {
      setErrors({
        duration: !duration && !customDuration,
        maxInvitees: !maxInvitees,
      });
      return;
    }

    const newMeet = {
      name: title,
      price,
      description,
      maxStudents: maxInvitees,
      duration: duration || customDuration,
      daily: everyDay,
      courseId: course,
      type,
      dates,
      days,
      tutorDetails,
      tutorId: tutor,
    };

    if (id) {
      await updateAppointment(id, newMeet);
    } else {
      await createAppointment(newMeet);
    }
  };

  const generateTimeSlots = useCallback(() => {
    const startTime = 5; // Start at 5 AM
    const endTime = 23; // End at 11 PM
    const durationInMinutes = duration === 'custom' ? parseInt(customDuration, 10) : parseInt(duration, 10);

    const timeSlots = [];
    for (let hour = startTime; hour < endTime; hour++) {
      for (let minute = 0; minute < 60; minute += durationInMinutes) {
        const startTimeFormatted = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
        const endHour = hour + Math.floor((minute + durationInMinutes) / 60);
        const endMinute = (minute + durationInMinutes) % 60;
        if (endHour >= endTime) break; // Ensure we don't go past the end time
        const endTimeFormatted = `${String(endHour).padStart(2, '0')}:${String(endMinute).padStart(2, '0')}`;
        timeSlots.push(`${startTimeFormatted} - ${endTimeFormatted}`);
      }
    }

    return timeSlots;
  }, [duration, customDuration]);

  const onChangePrice = (value) => {
    setPrice(value);
  };

  const onChangeInvites = (value) => {
    setMaxInvitees(value);
  };

  const onChangeDescription = (value) => {
    setDescription(value);
  };

  const handleDate = (newDate) => {
    if (!tutor) {
      toast.error('Select Tutor First');
      return;
    }

    const tutorSelected = tutorList.find((tut) => tut.id === tutor);
    if (!tutorSelected) {
      toast.error('Selected tutor not found');
      return;
    }
    const currentDate = newDate.$d;
    const currentDateDateString = currentDate.toDateString();

    const updatedTutorDetails = tutorDetails.map((tut) => {
      if (tut.tutorId === tutorSelected.id) {
        const updatedDates = tut.dates?.filter((date) => date.toDateString() !== currentDateDateString) || [];
        if (!updatedDates.some((date) => date.toDateString() === currentDateDateString)) {
          updatedDates.push(currentDate);
        }
        return { ...tut, dates: updatedDates };
      }
      return tut;
    });

    setTutorDetails(updatedTutorDetails);
  };

  const addTutor = () => {
    if (!tutor) {
      toast.error('Choose tutor First');
    } else {
      const tutorSelected = tutorList.find((tut) => tut.id === tutor);
      if (tutorDetails.some((tut) => tut.tutorId === tutorSelected.id)) {
        return;
      }

      const data = {
        tutorId: tutorSelected.id,
        tutorName: tutorSelected.name,
        tutorProfile: tutorSelected.profile,
        startTime: '',
        endTime: '',
        maxStudents: maxInvitees,
        daily: false,
        days: [],
        dates: [],
        slots: [],
        excludeDays: [],
        excludeSlots: [],
        courseId: course,
      };
      setTutorDetails([...tutorDetails, data]);
    }
  };

  const handleSlots = (slot) => {
    if (!tutor) {
      toast.error('Choose Tutor First');
      return;
    }

    const tutorSelected = tutorList.find((tut) => tut.id === tutor);
    if (!tutorSelected) {
      toast.error('Selected tutor not found');
      return;
    }

    const sliced = slot.split('-');
    const newStartTime = sliced[0];
    const newEndTime = sliced[1];

    setSelectedTimeSlot(slot);
  };

  useEffect(() => {
    if (appointmentData) {
      setTitle(appointmentData.name);
      setCourse(appointmentData.courseId);
      setDuration(appointmentData.duration);
      setMaxInvitees(appointmentData.maxStudents);
      setPrice(appointmentData.price);
      setDescription(appointmentData.description);
      setEveryDay(appointmentData.daily);
      setDays(appointmentData.days);
      setDates(appointmentData.dates);

      // Set tutor details from the schedule
      const tutorDetails = appointmentData.schedule.items.map((schedule) => ({
        tutorId: schedule.tutorId,
        tutorName: tutorList?.find((tutor) => tutor?.id === schedule?.tutorId)?.name,
        tutorProfile: tutorList?.find((tutor) => tutor?.id === schedule?.tutorId)?.profile,
        maxStudents: schedule.maxStudents,
        daily: schedule.daily,
        days: schedule.days,
        dates: schedule.dates,
        slots: schedule.slots,
        excludeDays: schedule.excludeDays || [],
        excludeSlots: schedule.excludeSlots || [],
        id: schedule.id,
      }));
      setTutorDetails(tutorDetails);
    }
  }, [appointmentData, tutorList]);

  const renderSelectedDates = (dates) => {
    if (dates.length === 0) {
      return <p>No dates selected</p>;
    }
    return (
      <div className="flex flex-wrap gap-2">
        {dates.map((date, index) => (
          <div key={index} className="px-2 py-1 bg-gray-200 rounded">
            {new Date(date).toDateString()}
          </div>
        ))}
      </div>
    );
  };

  if (loading || courseLoading || isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex relative flex-col gap-4 items-center justify-center min-h-screen">
      <Toaster />
      <Link
        to={'/dashboard/schedule-camp-meet'}
        className="flex absolute top-4 text-blue-700 font-bold cursor-pointer hover:opacity-60 transition-all ease-in-out duration-700 left-4 items-center gap-4"
      >
        <ArrowBack /> Back
      </Link>
      <div className="flex py-10 flex-col w-full justify-start h-full rounded-md shadow-xl bg-white p-5 items-center gap-4">
        <div className="flex py-5 flex-col md:flex-row w-full justify-start h-full rounded-md bg-white p-5 items-center gap-4">
          <div className="flex-co flex-wrap justify-between gap-5 w-full flex">
            <div className="justify-between gap-5 w-full flex">
              <FormControl className="field w-full flex flex-col gap-2">
                <InputLabel id="course">Course</InputLabel>
                <Select
                  className={`${!course ? 'border-red-500' : ''}`}
                  labelId="demo-simple-select-label"
                  id="course"
                  value={course}
                  label="Course"
                  onChange={(e) => setCourse(e.target.value)}
                >
                  {courseList.map((course) => (
                    <MenuItem key={course.id} value={course.id}>
                      {course.title}
                    </MenuItem>
                  ))}
                </Select>
                {!course && <p className="text-red-500">Course is required</p>}
              </FormControl>
            </div>
            <div className="justify-between gap-5 w-full flex">
              <FormControl className="field w-full flex flex-col gap-2">
                <InputLabel id="duration">Duration</InputLabel>
                <Select
                  id="duration"
                  className={`${errors.duration ? 'border-red-500' : ''}`}
                  value={duration}
                  onChange={handleDurationChange}
                >
                  <MenuItem value="">Select duration</MenuItem>
                  <MenuItem value="30">30 minutes</MenuItem>
                  <MenuItem value="60">1 hour</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
                {errors.duration && <p className="text-red-500">Duration is required</p>}
                {duration === 'custom' && (
                  <TextField
                    className=""
                    placeholder="Enter custom duration in minutes"
                    value={customDuration}
                    onChange={handleCustomDurationChange}
                  />
                )}
              </FormControl>

              <FormControl className="field flex flex-col gap-2">
                <TextField
                  id="Invites"
                  className={`${errors.maxInvitees ? 'border-red-500' : ''}`}
                  value={maxInvitees}
                  variant="outlined"
                  label="Max Tickets"
                  disabled
                />
                {errors.maxInvitees && <p className="text-red-500">Maximum invitees is required</p>}
              </FormControl>
            </div>

            <div className="justify-between gap-5 w-full flex flex-col ">
              <FormControl className="field flex w-full flex-col gap-2">
                <TextField
                  id="price"
                  className={`${!price ? 'border-red-500' : ''}`}
                  value={price}
                  label="Price"
                  variant="outlined"
                  disabled
                />
                {!price && <p className="text-red-500">Price is required</p>}
              </FormControl>

              <FormControl className="field w-full flex flex-col gap-2">
                <TextareaAutosize
                  id="description"
                  className={`w-full p-2 ${!description ? 'border-red-500' : ''}`}
                  value={description}
                  variant="outlined"
                  placeholder="Description"
                  disabled
                />
                {!description && <p className="text-red-500">Description is required</p>}
              </FormControl>
            </div>
          </div>
          <FormControl className="calendar w-full mx-auto flex flex-col md:flex-row gap-5">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar className="min-w-fit" onChange={(date) => handleDate(date)} />
            </LocalizationProvider>
            {(duration || customDuration) && (
              <div className="time-table w-full">
                <ul className="max-h-[300px] overflow-scroll flex flex-col gap-3">
                  {generateTimeSlots().map((timeSlot, index) => (
                    <button
                      value={selectedTimeSlot}
                      className={`px-4 py-2 border ${selectedTimeSlot === timeSlot ? 'bg-blue-500 text-white' : ''} border-blue-600 hover:bg-blue-500 hover:text-white cursor-pointer transition-all ease-in-out duration-500`}
                      key={index}
                      onClick={(e) => handleSlots(timeSlot)}
                    >
                      {timeSlot}
                    </button>
                  ))}
                </ul>
                <button onClick={handleAddSlot} className="mt-2 px-4 py-2 bg-blue-600 text-white rounded">
                  Add Slot
                </button>
              </div>
            )}
          </FormControl>
        </div>
        <div className="flex-col w-full items-center gap-4">
          <h1 className="text-1xl text-center py-3">Add Tutors</h1>
          <div className="AddTutor flex items-center gap-3">
            <FormControl className="field w-full flex flex-col items-center gap-2">
              <InputLabel id="Tutor">Tutor</InputLabel>
              <Select
                id="Tutor"
                label="Tutor"
                className={`w-full ${!course ? 'border-red-500' : ''}`}
                onChange={(e) => setTutor(e.target.value)}
              >
                {tutorList?.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.name}
                  </MenuItem>
                ))}
              </Select>
              {!tutor && <p className="text-red-500">Tutor is required</p>}
            </FormControl>
            <Button onClick={addTutor} className="py-0  bg-blue-800" variant="contained">
              Add Tutor
            </Button>
          </div>
          <div className="flex flex-col gap-5 w-full">
            {tutorDetails?.map((tut, index) => (
              <div key={index} className="flex flex-col gap-2 p-2 border rounded shadow">
                <Tutor
                  tutor={tut}
                  index={index}
                  tutorDetails={tutorDetails}
                  currentTutor={tutor}
                  currentTutorDetails={tut}
                  setTutor={setTutor}
                  tutorProfile={tut.tutorProfile}
                  setTutorDetails={setTutorDetails}
                  handleRemoveSlot={handleRemoveSlot}
                  handleRemoveTutor={handleRemoveTutor}
                />
                <div>
                  <h3>Selected Dates:</h3>
                  {renderSelectedDates(tut.dates)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col py-10 gap-4 w-full items-center">
        <div className="flex gap-4 text-sm">
          <Link to={'/dashboard/schedule-camp-meet'}>
            <button className="button bg-gray-100 rounded-sm hover:bg-gray-200 transition-all ease-in-out duration-700 px-4 py-2 bg-transparent text-gray-800">
              Cancel
            </button>
          </Link>
          <button
            disabled={!course || (!duration && !customDuration) || !maxInvitees || !price || !description || tutorDetails?.length === 0}
            onClick={createProgram}
            className={`button rounded-sm hover:bg-blue-800 transition-all ease-in-out duration-700 px-4 py-2 bg-blue-600 text-white ${!course || (!duration && !customDuration) || !maxInvitees || !description || !price || tutorDetails.length === 0
              ? 'pointer-events-none opacity-50'
              : ''
              }`}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewCampMeet;