
export const GET_CAMPCARD_REQUEST = 'GET_CAMPCARD_REQUEST';
export const GET_CAMPCARD_SUCCESS = 'GET_CAMPCARD_SUCCESS';
export const GET_CAMPCARD_FAIL = 'GET_CAMPCARD_FAIL';


export const ALL_CAMPCARD_REQUEST = 'ALL_CAMPCARD_REQUEST';
export const ALL_CAMPCARD_SUCCESS = 'ALL_CAMPCARD_SUCCESS';
export const ALL_CAMPCARD_FAIL = 'ALL_CAMPCARD_FAIL';

export const CREATE_CAMPCARD_REQUEST = 'CREATE_CAMPCARD_REQUEST';
export const CREATE_CAMPCARD_SUCCESS = 'CREATE_CAMPCARD_SUCCESS';
export const CREATE_CAMPCARD_FAIL = 'CREATE_CAMPCARD_FAIL';

export const UPDATE_CAMPCARD_REQUEST = 'UPDATE_CAMPCARD_REQUEST';
export const UPDATE_CAMPCARD_SUCCESS = 'UPDATE_CAMPCARD_SUCCESS';
export const UPDATE_CAMPCARD_FAIL = 'UPDATE_CAMPCARD_FAIL';


export const DELETE_CAMPCARD_REQUEST = 'DELETE_CAMPCARD_REQUEST';
export const DELETE_CAMPCARD_SUCCESS = 'DELETE_CAMPCARD_SUCCESS';
export const DELETE_CAMPCARD_FAIL = 'DELETE_CAMPCARD_FAIL';
export const DELETE_CAMPCARD_RESET = 'DELETE_CAMPCARD_RESET';

export const CLEAR_CAMPCARD_ERRORS = 'CLEAR_CAMPCARD_ERRORS';