import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useCreateAppointment } from '../../../hooks/createMeetSecond';
import Loader from '../../../components/Loader';
import MeetCard from './MeetCard';

const MeetList = () => {
  const { loading, listMeets, meets, deleteMeetById } = useCreateAppointment();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await listMeets();
      setIsDataLoaded(true);
      console.log("Meets", meets)
    };
    fetchData();

  }, []);

  if (loading || !isDataLoaded) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      {meets?.map((event) => (
        <Grid item key={event.id} xs={12} sm={6} md={4} lg={3}>
          <MeetCard deleteMeetById={deleteMeetById} event={event} />
        </Grid>
      ))}
    </Grid>
  );
};


export default MeetList;