import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { toast } from 'react-toastify';
import * as queries from "../../graphql/queries"; // Import your GraphQL queries
import * as mutations from "../../graphql/mutations"; // Import your GraphQL queries
import {
  CREATE_CLINICCOURSE_REQUEST,
  CREATE_CLINICCOURSE_SUCCESS,
  CREATE_CLINICCOURSE_FAIL,
  UPDATE_CLINICCOURSE_REQUEST,
  UPDATE_CLINICCOURSE_SUCCESS,
  UPDATE_CLINICCOURSE_FAIL,
  DELETE_CLINICCOURSE_REQUEST,
  DELETE_CLINICCOURSE_SUCCESS,
  DELETE_CLINICCOURSE_FAIL,
  GET_CLINICCOURSE_REQUEST,
  GET_CLINICCOURSE_SUCCESS,
  GET_CLINICCOURSE_FAIL,
  CLEAR_CLINICCOURSE_ERRORS,
  ALL_CLINICCOURSE_REQUEST,
  ALL_CLINICCOURSE_SUCCESS,
  ALL_CLINICCOURSE_FAIL,
} from '../constants/ClinicCourseConstants';

export const getAllClinicCourses = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_CLINICCOURSE_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listClinicCourses));

    const updatedCourses = response.data.listClinicCourses.items || [];

    const updatedData = {
      clinicCourses: updatedCourses,
      clinicCoursesCount: updatedCourses?.length,
      resultPerPage: 10,
      filteredCoursesCount: null,
    };

    dispatch({
      type: ALL_CLINICCOURSE_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_CLINICCOURSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearClinicCoursesErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CLINICCOURSE_ERRORS });
};
