import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries';

const useAllLeaguePlayerList = () => {
  const [allLeaguesUsersList, setGetLeaguesUserList] = useState([]);
  const [verifiedLeaguesUsersList, setVerifiedGetLeaguesUserList] = useState([]);
  const [notVerifiedLeaguesUsersList, setNonVerifiedGetLeaguesUserList] = useState([]);
  const [playerDetails, setPlayerDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [modifiedLeaguesUsersList, setModifiedLeaguesUserList] = useState([]);

  useEffect(() => {
    const fetchLeaguesUser = async () => {
      try {
        setLoading(true);
        API.graphql({
          query: queries.listPlayers,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        }).then((response) => {
          const newList = response?.data?.listPlayers?.items?.map((item) => ({
            ...item,
            zone: 'Player',
          }));
          const notVerifiedUsers = newList.filter((item) => item.isVerified === null);
          const verifiedUsers = newList.filter((item) => item.isVerified === true);
          setLoading(false);
          const modifiedPlayer = verifiedUsers?.map((user) => ({
            ...user,
            name: user?.participantName,
            email: user?.email,
            isVerified: user?.isVerified,
            status: 'Confirmed',

            // Add other attributes you want to include
          }));
          setModifiedLeaguesUserList(modifiedPlayer);
          setGetLeaguesUserList(newList);
          setNonVerifiedGetLeaguesUserList(notVerifiedUsers);
          setVerifiedGetLeaguesUserList(verifiedUsers);
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        console.error('Error fetching leagues:', error);
        throw error;
      }
    };

    fetchLeaguesUser();
  }, []);

  const fetchLeaguesUserByName = async (id) => {
    try {
      setLoading(true);
      API.graphql({
        query: queries.listPlayers,
        // filter:{id:{eq:id},isVerified:{eq:true}},
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }).then((response) => {
        const newList = response?.data?.listPlayers?.items?.map((item) => ({
          ...item,
          zone: 'Leagues Player',
        }));
        // const notVerifie
        // Users = newList.filter((item) => item.isVerified === null);
        const user = newList.filter((item) => item.isVerified === true);

        setPlayerDetails(newList);
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching leagues:', error);
      throw error;
    }
  };
  return {
    allLeaguesUsersList,
    verifiedLeaguesUsersList,
    notVerifiedLeaguesUsersList,
    loading,
    playerDetails,
    modifiedLeaguesUsersList,
    fetchLeaguesUserByName,
  };
};

export default useAllLeaguePlayerList;
