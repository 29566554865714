import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Input,
  Box,
  Select,
  MenuItem,
  Typography,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';

import useAddAdvertisement from '../../../hooks/addAdvertisement';
import useUpdateAdvertisementDetails from '../../../hooks/updateAdvertisement';
import deleteFile from '../../../utils/deleteFile';
import uploadFile from '../../../utils/uploadFile';
import useFetchAdvertisement from '../../../hooks/getAdvertisementList';
import { createAd, updateAd, getAllAds } from '../../../redux/actions/AdsAction';
import Loader from '../../../components/Loader';

AddNewAdvertisementSideBar.propTypes = {
  openAdvertisement: PropTypes.bool,
  onOpenAdvertisement: PropTypes.func,
  onCloseAdvertisement: PropTypes.func,
};

const pagesList = [
  'BLOG',
  'NEWS',
  'VLOG',
  'EVENTS',
  'HOME',
  'ABOUT',
  'CONTACT',
  
  'STORE',
  'LEAGUECITY',
  'LEAGUEINFO',
  'RULES',
  'PLAYER',
  'CLINICS',
  'TOURNAMENT',
  'TRAININGPROGRAM',
  'PARTNER',
  'ICON',
  'CAMP',
  'LEAGUETEAM',
  'LEAGUEPLAYER',
  'REGISTER',
  'LOGIN',
  'SCORE',
];

export default function AddNewAdvertisementSideBar({
  openAdvertisement,
  onOpenAdvertisement,
  onCloseAdvertisement,
  editData,
  updateAdDetails,
  createAdvertisements,
  display = false,
}) {
  const dispatch = useDispatch();
  const [imageUploading, setLoading] = useState(false);
  const [type, setType] = useState(editData?.type || '');
  const { createAdvertisement } = useAddAdvertisement();
  const [file, setFile] = useState(null);
  const [advertisementId, setAdvertisementId] = useState(null);
  const [page, setPage] = useState(editData?.page || '');
  const [lgImage, setLgImage] = useState('');
  const [mdImage, setMdImage] = useState('');
  const [smImage, setSmImage] = useState('');
  const [xsImage, setXsImage] = useState('');

  const { updateAdvertisementDetails, loading } = useUpdateAdvertisementDetails();
  const isEditMode = !!editData; // Check if editData is present

  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      setType(editData.type || '');

      // setFile(editData.img_url || '');
      setAdvertisementId(editData.id || '');

      // Set other fields accordingly
    }
  }, [editData, isEditMode]);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handlePage = (event) => {
    setPage(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleUploadData();
    }
  };

  const handleFileChange = (event, screen) => {
    const selectedImage = event.target.files[0];

    if (selectedImage) {
      setFile(selectedImage);
      if (screen === 'LG') {
        setLgImage(selectedImage);
      } else if (screen === 'MD') {
        setMdImage(selectedImage);
      } else if (screen === 'SM') {
        setSmImage(selectedImage);
      } else if (screen === 'XS') {
        setXsImage(selectedImage);
      }
    } else {
      setFile(null);
    }
  };
  const handleUploadData = async () => {
    try {
      if (!type) {
        toast.error('Advertisement type is required.');
        return;
      }
      if (!page) {
        toast.error('Page type is required.');
        return;
      }
      if (!isEditMode) {
        if (!lgImage) {
          toast.error('Please upload a large screen image.');
          return;
        }
        if (!mdImage) {
          toast.error('Please upload a medium screen image.');
          return;
        }
        if (!smImage) {
          toast.error('Please upload a small screen image.');
          return;
        }
        if (!xsImage) {
          toast.error('Please upload an extra small screen image.');
          return;
        }
      }

      setLoading(true);
      let newItem = {
        type,
        lg_img_url: lgImage,
        md_img_url: mdImage,
        sm_img_url: smImage,
        xs_img_url: xsImage,
        page,
        // Assuming tags are comma-separated
      };
      if (isEditMode) {
        newItem = {
          type,
          lg_img_url: editData?.lg_img_url,
          md_img_url: editData?.md_img_url,
          sm_img_url: editData?.sm_img_url,
          xs_img_url: editData?.xs_img_url,
          page,
          // Assuming tags are comma-separated
        };
        console.log('EditMode', editData);
        let lgImageUrl = editData?.lg_img_url;
        let mdImageUrl = editData?.md_img_url;
        let smImageUrl = editData?.sm_img_url;
        let xsImageUrl = editData?.xs_img_url;

        if (lgImage) {
          const lgDeleted = await deleteFile(lgImageUrl);

          if (lgDeleted) {
            lgImageUrl = await uploadFile(lgImage, page);
          }
          newItem.lg_img_url = lgImageUrl;
        }
        if (mdImage) {
          const mdDeleted = await deleteFile(mdImageUrl);

          if (mdDeleted) {
            mdImageUrl = await uploadFile(mdImage, page);
          }
          newItem.md_img_url = mdImageUrl;
        }
        if (smImage) {
          const smDeleted = await deleteFile(smImageUrl);

          if (smDeleted) {
            smImageUrl = await uploadFile(smImage, page);
          }
          newItem.sm_img_url = smImageUrl;
        }
        if (xsImage) {
          const xsDeleted = await deleteFile(xsImageUrl);

          if (xsDeleted) {
            xsImageUrl = await uploadFile(xsImage, page);
          }
          newItem.xs_img_url = xsImageUrl;
        }
        // await updateAdvertisementDetails(newItem, advertisementId);

        // dispatch(getAllAds());

        // await checkAuthAndFetchData();
          onCloseAdvertisement();
        const response = await updateAdDetails(advertisementId, newItem);

      } else {
        // await createAdvertisement(newItem);
        // await checkAuthAndFetchData();
          onCloseAdvertisement();
        const response = await createAdvertisements(newItem);
        // console.log('Advertisement created:', response.data.createAdvertisement);
      }
      //    await checkAuthAndFetchData();
      //   const id = newItem?.id;
      //   const updated=await updateAd(id,newItem)
      //   onCloseAdvertisement();

      //   if(updated){

      //     dispatch(getAllAds());
      //   }
      // } else {
      //   // dispatch(createEvent(newItem));
      //   delete newItem?.lgImage;
      //   console.log('newitem',newItem)
      //   const created = await createAd(newItem);
      //   // Close the modal
      // dispatch(getAllAds());

      //   if (created) {

      //   }
      // }

      // Reset the form fields after successful upload
      setType('');
      setFile('');
      setPage('');
      setLgImage('');
      setMdImage('');
      setXsImage('');
      setSmImage('');

    
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error('Error creating Advertisement:', error);
    }
  };
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  if (loading || imageUploading) {
    return <Loader />;
  }
  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenAdvertisement}
        style={buttonStyles}
        className={`${display ? '' : 'hidden'}`}
      >
        Add Advertisement
      </Button>

      <Dialog open={openAdvertisement} onClose={onCloseAdvertisement}>
        <DialogTitle>{isEditMode ? 'Edit Advertisement' : 'Add New Advertisement'}</DialogTitle>

        <DialogContent>
          <Stack direction={'column'} sx={{ mt: 2 }}>
            <Typography
              mb={2}
              sx={{
                fontSize: '14px',
                color: 'gray',
                wordSpacing: '3px',
                fontWeight: 'medium',
              }}
            >
              For Adding New Advertisement Image Dimension should be 1552 x 240
            </Typography>
            <Typography
              mb={2}
              sx={{
                fontSize: '14px',
                color: 'gray',
                wordSpacing: '3px',
                fontWeight: 'medium',
              }}
            >
              For Adding New Sponsor Image Dimension should be 435 x 663
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="type">Type</InputLabel>
              <Select
                labelId="type"
                label="Type"
                name="type"
                value={type}
                fullWidth
                className="uppercase"
                variant="outlined"
                onChange={handleTypeChange}
              >
                <MenuItem className="uppercase" value="SPONSOR" defaultValue="SPONSOR" defaultChecked>
                  SPONSOR
                </MenuItem>
                <MenuItem className="uppercase" value="ADVERTISEMENT">
                  ADVERTISEMENT
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel id="type">Page Type</InputLabel>
              <Select
                labelId="type"
                label="Type"
                name="type"
                value={page}
                fullWidth
                className="uppercase"
                variant="outlined"
                onChange={handlePage}
                onKeyPress={handleKeyPress}
              >
                <MenuItem className="uppercase" defaultValue="Choose Page" defaultChecked>
                  Choose Page
                </MenuItem>
                {pagesList?.map((value, index) => (
                  <MenuItem className="uppercase" key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <span id="Large Screen Image">Large Screen Image</span>

              <Input
                type="file"
                id="file"
                sx={{ marginTop: 1 }}
                label="Upload Image"
                variant="outlined"
                fullWidth
                inputProps={{ accept: 'image/*' }}
                onChange={(e) => handleFileChange(e, 'LG')}
              />
            </FormControl>

            <FormControl fullWidth margin="dense">
              <span id="Medium Screen Image">Medium Screen Image</span>

              <Input
                type="file"
                id="file"
                sx={{ marginTop: 1 }}
                label="Upload Image"
                variant="outlined"
                fullWidth
                inputProps={{ accept: 'image/*' }}
                onChange={(e) => handleFileChange(e, 'MD')}
              />
            </FormControl>

            <FormControl fullWidth margin="dense">
              <span id="Small Screen Image">Small Screen Image</span>

              <Input
                type="file"
                id="file"
                sx={{ marginTop: 1 }}
                label="Upload Image"
                variant="outlined"
                fullWidth
                inputProps={{ accept: 'image/*' }}
                onChange={(e) => handleFileChange(e, 'SM')}
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <span id="Extra Small Screen Image">Extra Small Screen Image</span>

              <Input
                type="file"
                id="file"
                sx={{ marginTop: 1 }}
                label="Upload Image"
                variant="outlined"
                fullWidth
                inputProps={{ accept: 'image/*' }}
                onChange={(e) => handleFileChange(e, 'XS')}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={onCloseAdvertisement} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
