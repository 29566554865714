import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries

import {
  CREATE_CITY_REQUEST,
  CREATE_CITY_SUCCESS,
  CREATE_CITY_FAIL,
  GET_CITY_REQUEST,
  GET_CITY_SUCCESS,
  GET_CITY_FAIL,
  LIST_CITIES_REQUEST,
  LIST_CITIES_SUCCESS,
  LIST_CITIES_FAIL,
  UPDATE_CITY_REQUEST,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  DELETE_CITY_REQUEST,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_FAIL,
  DELETE_CITY_RESET,
  CLEAR_CITY_ERRORS,
} from '../constants/CityConstants';

export const getAllCity = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_CITIES_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listCities));

    const data = response.data.listCities.items || [];
    const updatedCities = await Promise.all(
      data?.map(async (product) => {
        try {
          return { ...product };
        } catch (error) {
          console.error('Error fetching Team image URL', error);
          return product;
        }
      })
    );

    updatedCities.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      } else if (a.createdAt < b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
    const updatedData = {
      cities: updatedCities,
      citiesCount: updatedCities?.length,
      resultPerPage: 10,
      filteredCityCount: null,
    };

    dispatch({
      type: LIST_CITIES_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: LIST_CITIES_SUCCESS,
      payload: error,
    });
  }
};

export const getCityDetails = (cityId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CITY_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getCity, { id: cityId }));

    const data = response?.data.getItem;
    dispatch({
      type: GET_CITY_REQUEST,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_CITY_FAIL,
      payload: errorMessage,
    });
  }
};
export const createCity = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CITY_REQUEST });

    // Normalize the city name
    const normalizedCityName = formData.name.toLowerCase().replace(/\s+/g, '');

    // Fetch existing cities
    const existingCitiesResponse = await API.graphql(graphqlOperation(queries.listCities));

    const existingCities = existingCitiesResponse.data.listCities.items;

    // Check if the normalized city name already exists
    const nameExists = existingCities.some(
      (city) => city.name.toLowerCase().replace(/\s+/g, '') === normalizedCityName
    );

    if (nameExists) {
      toast.error('City name is already taken!');
      return;
    }

    const response = await API.graphql({
      query: mutations.createCity,

      variables: {
        input: {
          name: formData.name,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createCity;

    toast.success('City Added Successfully!');

    dispatch({
      type: CREATE_CITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('city error', error);

    dispatch({
      type: CREATE_CITY_FAIL,
      payload: error,
    });
  }
};
export const deleteCity = (id) => async (dispatch) => {
  console.log('ID', id);
  try {
    dispatch({ type: DELETE_CITY_REQUEST });

    API.graphql({
      query: mutations.deleteCity,
      variables: { input: { id } },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then(async (response) => {
      console.log(response);
      toast.success('City Deleted Successfully!');

      dispatch({
        type: DELETE_CITY_SUCCESS,
        payload: true,
      });
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_CITY_FAIL,
      payload: error || 'Failed to fetch city data',
    });
  }
};

export const clearCityErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CITY_ERRORS });
};
