import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Select,
  Container,
  Box,
  MenuItem,
  Grid,
  InputLabel,
  Checkbox,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import useHandleTrainingProgramUser from '../../hooks/hanldeTrainingProgram';
import Loader from '../Loader';
import useGetAllCity from '../../hooks/getCityList';
import useGetAllTeams from '../../hooks/getTeamsLists';
import useHandlePaginationCourse from '../../hooks/handlePaginationCourse';
import useHandlePaginationTrainingProgramCards from '../../hooks/handlePaginationTrainingProgramCards';

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

const TrainingProgram = () => {
  const {
    fetchTrainingProgramUsers,
    deleteTrainingProgramUserById,
    trainingProgramUserDetails,
    updateTrainingProgramById,
    loading,
  } = useHandleTrainingProgramUser();
  const {
    trainingProgramCardsList,
    loading:cardLoading,
  } = useHandlePaginationTrainingProgramCards();
  const {
    courseList,
    loading:courseLoading,
  } = useHandlePaginationCourse();
  const { cities } = useGetAllCity();
  const { teamslist } = useGetAllTeams();
  const [participant, setParticipant] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const [agegroup, setAgegroup] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [ext, setExt] = useState('');
  const [registeredTeam, setRegisteredTeam] = useState('');
  const [cityOfRegistration, setCityOfRegistration] = useState('');
  const [profile, setProfile] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [isParentGuardian, setIsParentGuardian] = useState(false);

  const [clubName, setClubName] = useState('');

  const [signatureType, setSignatureType] = useState('');
  const [electronicSignature, setElectronicSignature] = useState('');
  const [agreeRelease, setAgreeRelease] = useState(false);
  const [agreeActive, setAgreeActive] = useState(false);

  const handleAgeGroup = (e) => {
    setAgegroup(e.target.value);
   
  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
   
  };
 
  const handleAgreeReleaseChange = (event) => {
    setAgreeRelease(event.target.checked);
  };

  const handleAgreeActiveChange = (event) => {
    setAgreeActive(event.target.checked);
  };

  const handleParticipantChange = (e) => {
    setParticipant(e.target.value);
  };

  const handleEmailAddressChange = (e) => {
    setEmailAddress(e.target.value);
  };

 

  const handleExtChange = (e) => {
    setExt(e.target.value);
  };



  const handleRegisteredTeamChange = (e) => {
    setRegisteredTeam(e.target.value);
  };

  const handleCityOfRegistrationChange = (e) => {
    setCityOfRegistration(e.target.value);
  };



  const handleProfileChange = (e) => {
    const selectedFile = e.target.files[0]; // Get the selected file from the event

    // Implement file upload logic for the resume
    if (selectedFile) setProfile(selectedFile);
    // Implement file upload logic for the resume
  };


  const handleDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleIsParentGuardianChange = (e) => {
    setIsParentGuardian(e.target.checked);
  };


  const handleClubNameChange = (e) => {
    setClubName(e.target.value);
  };




  const handleSignatureTypeChange = (e) => {
    setSignatureType(e.target.value);
  };

  const handleElectronicSignatureChange = (e) => {
    setElectronicSignature(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement form submission logic here
  };
  console.log('Verify trainingProgram', trainingProgramUserDetails);
  const buttonStyles = {
    backgroundColor: '#1E90FF',
    color: 'white',
  };
  useEffect(() => {
    setParticipant(trainingProgramUserDetails?.participantName || '');
    setEmailAddress(trainingProgramUserDetails?.email || '');
    setDateOfBirth(trainingProgramUserDetails?.dateOfBirth || '');
    setRegisteredTeam(trainingProgramUserDetails?.teamName || '');
    setCityOfRegistration(trainingProgramUserDetails?.cityOfRegistration || '');
    setIsParentGuardian(trainingProgramUserDetails?.isAdult || false);
    setClubName(trainingProgramUserDetails?.clubName || '');
    setExt(trainingProgramUserDetails?.ext || '');
    setElectronicSignature(trainingProgramUserDetails?.signature || '');
    setPhoneNumber(trainingProgramUserDetails?.phoneNumber || '');
    setAgegroup(trainingProgramUserDetails?.agegroup || '');
  }, [trainingProgramUserDetails]);

  const updateTrainingProgramByIds = async () => {
    const data = {
      id: trainingProgramUserDetails?.id,
      userId: trainingProgramUserDetails?.userId,
      ext: ext || trainingProgramUserDetails?.ext,
      participantName: participant || trainingProgramUserDetails?.participantName,
      cityOfRegistration: cityOfRegistration || trainingProgramUserDetails?.cityOfRegistration,
      teamID: trainingProgramUserDetails?.teamID,
      profile: profile || trainingProgramUserDetails?.profile,
      teamName: registeredTeam || trainingProgramUserDetails?.teamName,
      dateOfBirth: dateOfBirth || trainingProgramUserDetails?.dateOfBirth,
      isAdult: isParentGuardian,
      email: emailAddress || trainingProgramUserDetails?.email,
      clubName: clubName || trainingProgramUserDetails?.clubName,
      signature: electronicSignature || trainingProgramUserDetails?.signature,
      phoneNumber: phoneNumber || trainingProgramUserDetails?.phoneNumber,
      agegroup: agegroup || trainingProgramUserDetails?.agegroup,
    };
    await updateTrainingProgramById(data);
  };

  if (loading || courseLoading || cardLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Container>
      <form onSubmit={handleFormSubmit}>
        <Grid item xs={12} sm={6} style={{ marginLeft: 1, marginTop: 5 }}>
          <div className="text-3xl font-bold">Edit Training Program</div>
        </Grid>

        <Grid container style={{ marginLeft: 1, marginTop: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="participant"
              variant="outlined"
              label="Participant"
              fullWidth
              value={participant}
              onChange={handleParticipantChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              variant="outlined"
              label="Email Address"
              fullWidth
              value={emailAddress}
              onChange={handleEmailAddressChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="text"
              variant="outlined"
              label="Phone Number"
              fullWidth
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="agegroup">Age Group</InputLabel>
              <Select
                name="agegroup"
                variant="outlined"
                label="Age Group"
                fullWidth
                value={agegroup}
                onChange={handleAgeGroup}
              >
                <MenuItem value="">Select Age Group</MenuItem>
                {ages?.map((data, index) => (
                  <MenuItem value={data.value}>{data.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        
          <Grid item xs={12} sm={6}>
            <Box className="w-[100px] h-[100px] rounded-full overflow-hidden">
              <img src={trainingProgramUserDetails?.profileLink} className='object-cover h-full w-full'  alt={trainingProgramUserDetails?.participantName} />
            </Box>
            <InputLabel id="profile">Upload Profile Picture</InputLabel>

            <input type="file" accept="image/*" name="profile"  onChange={handleProfileChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="city_of_registration">City Of Registration</InputLabel>

              <Select
                name="city_of_registration"
                variant="outlined"
                label="City Of Registration"
                fullWidth
                value={cityOfRegistration}
                onChange={handleCityOfRegistrationChange}
              >
                {cities?.map((value, index) => (
                  <MenuItem value={value.name} key={index}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="date"
              label="Date of Birth"
              variant="outlined"
              value={dateOfBirth}
              onChange={handleDateOfBirthChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox checked={isParentGuardian} onChange={handleIsParentGuardianChange} />}
              label={
                <Typography variant="body1">
                  You are this person's parent or legal guardian. You must be the parent or legal guardian to register
                  someone under 18 years old. as of 2021-2022 school year
                </Typography>
              }
            />
          </Grid>
         
          <Grid item xs={12} sm={6}>
            <TextField name="text" variant="outlined" label="Ext" fullWidth value={ext} onChange={handleExtChange} />
          </Grid>
        
          {/* <Grid item xs={12} sm={6}>
            <InputLabel>Digital Signature</InputLabel>
            <RadioGroup
              aria-label="Signature Type"
              name="signatureType"
              value={signatureType}
              onChange={handleSignatureTypeChange}
            >
              <FormControlLabel value="write" control={<Radio />} label="I will sign the registration form by hand." />
              <FormControlLabel
                value="type"
                control={<Radio />}
                label="I will type my name as my electronic signature."
              />
            </RadioGroup>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Signature"
              variant="outlined"
              value={electronicSignature}
              onChange={handleElectronicSignatureChange}
            />
          </Grid>
          {signatureType === 'type' && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Electronic Signature"
                variant="outlined"
                value={electronicSignature}
                onChange={handleElectronicSignatureChange}
              />
            </Grid>
          )}
          {/* <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" className="font-bold text-xl mt-4 mb-2">
                Waivers and Agreements
              </Typography>
              <Typography>
                Please read the following waivers and agreements carefully. They include release of liability and waiver
                of legal rights, and deprive you of the ability to sue certain parties. By agreeing electronically, you
                acknowledge that you have both read and understood all text presented to you as part of the registration
                process.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox required checked={agreeRelease} onChange={handleAgreeReleaseChange} color="primary" />
                  }
                  label={
                    <Typography>
                      I agree to the <strong>RELEASE OF LIABILITY AND CANCELLATION POLICY</strong>
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox required checked={agreeActive} onChange={handleAgreeActiveChange} color="primary" />
                  }
                  label={
                    <Typography>
                      I agree to the <strong>Active Agreement and Waiver</strong>
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
          </Grid> */}
        </Grid>
        <Box mt={2} ml={5}>
          <Button variant="contained" onClick={updateTrainingProgramByIds} style={buttonStyles} type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default TrainingProgram;

