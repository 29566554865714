import React from 'react';
import { Box } from  '@mui/material';
import League5x5 from '../components/user/League5x5';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';

const EditVerification = () => (
    <Box display="flex" mb={5}>
      <DashboardLayout />
      <Box mt={10} width="100%">
       
        <League5x5 />
      
      </Box>
    </Box>
  );

export default EditVerification;
