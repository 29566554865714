export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';

export const LIST_CATEGORIES_REQUEST = 'LIST_CATEGORIES_REQUEST';
export const LIST_CATEGORIES_SUCCESS = 'LIST_CATEGORIES_SUCCESS';
export const LIST_CATEGORIES_FAIL = 'LIST_CATEGORIES_FAIL';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';
export const DELETE_CATEGORY_RESET = 'DELETE_CATEGORY_RESET';

export const CLEAR_CATEGORY_ERRORS = 'CLEAR_CATEGORY_ERRORS';
