/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import z from 'zod';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL mutations
import getLink from 'src/utils/getLinks';
import deleteFile from 'src/utils/deleteFile';

const ComptetiveTeamSchema = z.object({
    name: z.string(),
    coachId : z.string(),
    agegroup: z.string(),
    imageURL: z.string(),
    location: z.string(),
    city: z.string()
});

const ComptetiveUserSchema = z.object({
    id: z.string().optional(),
    userId: z.string(),
    isVerified: z.boolean().optional(),
    league: z.string().optional(),
    agegroup: z.string().optional(),
    ext: z.string().optional(),
    participantName: z.string(),
    cityOfRegistration: z.string(),
    teamID: z.string().optional(),
    isAccepted: z.boolean().optional(),
    profile: z.string(),
    teamName: z.string(),
    message: z.string().optional(),
    token: z.string().optional(),
    tokenExpire: z.string().optional(),
    dateOfBirth: z.string().optional(),
    isAdult: z.boolean(),
    grade: z.string().optional(),
    email: z.string(),
    jerseySize: z.string(),
    jerseyNo: z.number().optional(),
    sessionId: z.string().optional(),
    seasonIds: z.array(z.string()).optional(),
    schoolName: z.string().optional(),
    transactionStatus: z.enum(["PAID", "PENDING", "FAILED"]).optional(),
    memberExpire: z.string().optional(),
    isMemberOfOtherTeam: z.boolean(),
    clubName: z.string().optional(),
    secondaryPersonName: z.string().optional(),
    primaryPersonName: z.string().optional(),
    isFirstYearPlaying: z.boolean().optional(),
    primaryCallPhoneNumber: z.string().optional(),
    secondaryCallPhoneNumber: z.string().optional(),
    primaryEmergencyContactEmail: z.string().optional(),
    secondaryEmergencyContactEmail: z.string().optional(),
    heardAboutLeague: z.string().optional(),
    healthConditions: z.boolean().optional(),
    parentFirstName: z.string().optional(),
    parentLastName: z.string().optional(),
    parentEmailAddress: z.string().optional(),
    parentPhoneNumber: z.string().optional(),
    parentBusinessPhoneNumber: z.string().optional(),
    parentHomePhoneNumber: z.string().optional(),
    parentCountry: z.string().optional(),
    signature: z.string(),
    document: z.string(),
    HEIGHT: z.string(),
    WEIGHT: z.string(),
    H_S: z.string(),
    NBA: z.string(),
    COLLEGE: z.string(),
    phoneNumber: z.string().optional(),
});

const useHandleComptetive5x5Teams = () => {
    const [comptetiveTeams, setComptetiveTeams] = useState([]);

    const [loading, setLoading] = useState(false);

    const createTeam = async (teamData) => {
        setLoading(true);
    
        try {
            const parsedData = ComptetiveTeamSchema.parse(teamData);  // Validate team data with Zod schema
    
            // Fetch coach details by filtering with coachId
            const coachResponse = await API.graphql(
                graphqlOperation(queries.getComptetiveCoach5X5, { id: parsedData.coachId }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );

            const coachName = coachResponse?.data?.getComptetiveCoach5X5?.name;

            if (!coachName) {
                toast.error("Coach not found.");
                return null;
            }

            // Include coachName in the parsed data
            const dataToCreate = {
                ...parsedData,
                coachName, // Add coachName to the data being sent
            };

            // Proceed to create the team
            const response = await API.graphql(
                graphqlOperation(mutations.createComptetiveTeam5X5, { input: dataToCreate }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            if (response.data.createComptetiveTeam5X5) {
                toast.success("Team created successfully!");
    
                // Prepare data with the image URL link
                const sendthis = {
                    img_url: await getLink(response.data.createComptetiveTeam5X5.imageURL),
                    ...response.data.createComptetiveTeam5X5,
                };
    
                return sendthis;  // Return the constructed team data
            } else {
                toast.error("Failed to create team due to missing data.");
                return null;
            }
        } catch (error) {
            toast.error("Failed to create team.");
            throw error;  // Rethrow error for the calling function to handle
        } finally {
            setLoading(false);
        }
    };
    

    const updateTeam = async (teamId, teamData) => {
        setLoading(true);

        const parsedData = ComptetiveTeamSchema.parse(teamData);
        try {
            const response = await API.graphql(
                graphqlOperation(mutations.updateComptetiveTeam5X5, { input: { id: teamId, ...parsedData } }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            toast.success("Team updated successfully!");
            return response;
        } catch (error) {
            toast.error("Failed to update team.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    const deleteTeam = async (teamId) => {
        setLoading(true);

        const data=await getTeamById(teamId);

        console.log("data",data)

        await deleteFile(data.imageURL);

        try {
            const response = await API.graphql(
                graphqlOperation(mutations.deleteComptetiveTeam5X5, { input: { id: teamId } }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            toast.success("Team deleted successfully!");
            return response;
        } catch (error) {
            toast.error("Failed to delete team.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    const getTeamById = async (teamId) => {
        setLoading(true);

        try {
            const response = await API.graphql(
                graphqlOperation(queries.getComptetiveTeam5X5, { id: teamId }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            return response.data.getComptetiveTeam5X5;
        } catch (error) {
            toast.error("Failed to fetch team.");
            throw error;
        } finally {
            setLoading(false)
        }
    };


    const getAllTeams = async () => {
        setLoading(true);

        try {
            const response = await API.graphql(
                graphqlOperation(queries.listComptetiveTeam5X5s),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );

            const withImageUrl = await Promise.all(

                response.data.listComptetiveTeam5X5s.items?.map(async (data) => {
                    const url = await getLink(data.imageURL);
                    return {
                        ...data,
                        img_url: url
                    }

                })

            );
            console.log("withImageUrl", withImageUrl)
            setComptetiveTeams(withImageUrl);
            return withImageUrl|| [];
        } catch (error) {
            toast.error("Failed to fetch teams.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    return {
        createTeam,
        updateTeam,
        deleteTeam,
        getTeamById,
        getAllTeams,
        loading,
        comptetiveTeams
        
    };
};

export { ComptetiveTeamSchema, ComptetiveUserSchema, useHandleComptetive5x5Teams };
