import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Stack,
  Box,
  Input,
  TextareaAutosize
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import useHandleTutor from '../../../hooks/handleTutor';

export default function AddNewTutor({
  isOpen,
  editData,
  hidden = false,
  onClose,
  onOpen,
  addNewData,
  updateData,
  fetchData,
}) {
  const [name, setName] = useState('');
  const [profile, setProfile] = useState();
  const [bio, setBio] = useState('');
  const isEditMode = !!editData;
  const [newImage, setNewImage] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEditMode && editData) {
      setName(editData?.name || '');
      setProfile(editData?.oldProfile || '');
      setBio(editData?.bio || '');
    }
  }, [editData, isEditMode]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleProfileChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      // setImage(selectedImage);
      if (editData) {
        setNewImage(selectedImage);
      } else {
        setProfile(selectedImage);
      }
    } else {
      setProfile(null);
    }
  };

  const handleBioChange = (event) => {
    setBio(event.target.value);
  };

  const handleUploadData = async () => {
    const tutorData = {
      name,
      profile,
      bio,
    };
    if (!name) {
      // Display an error message or handle the missing fields as required
      toast.error('Name is required.');
      return;
    }
    if (!bio) {
      // Display an error message or handle the missing fields as required
      toast.error('Bio is required.');
      return;
    }
  // For adding a new tutor, profile (image) is required
  if (!isEditMode) {
    if (!profile) {
      toast.error('Profile is required.');
      return;
    }
    tutorData.profile = profile;
  }
 
   console.log(tutorData)
    if (isEditMode) {
      const id = editData.id;

      if (newImage) tutorData.newImage = newImage;
      tutorData.id = id;

      const updated = await updateData(tutorData,id);
      if (updated) {
        await fetchData();
        onClose();
      }
    } else {
      const updated = await addNewData(tutorData);
    }

    // Reset form fields
    setName('');
    setProfile('');
    setBio('');
    onClose();
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        onClick={onOpen}
        hidden={hidden}
        style={{ backgroundColor: '#1E90FF', color: 'white' }}
      >
        Add Tutor
      </Button>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{isEditMode ? 'Edit Tutor' : 'Add New Tutor'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new Tutor.</DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={name}
            onChange={handleNameChange}
          />
          <TextField
            required
            autoFocus
            type={'text'}
            margin="dense"
            label="bio"

            fullWidth
            value={bio}
            onChange={handleBioChange}
          />
          <Input
            type="file"
            id="image"
            sx={{ marginTop: 2 }}
            label="Upload Image"
            variant="outlined"
            required
            fullWidth
            inputProps={{ accept: 'image/*' }}
            onChange={handleProfileChange}
          />
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={onClose} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
