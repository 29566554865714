import {
  CREATE_CLINICCARD_REQUEST,
  CREATE_CLINICCARD_SUCCESS,
  CREATE_CLINICCARD_FAIL,
  UPDATE_CLINICCARD_REQUEST,
  UPDATE_CLINICCARD_SUCCESS,
  UPDATE_CLINICCARD_FAIL,
  DELETE_CLINICCARD_REQUEST,
  DELETE_CLINICCARD_SUCCESS,
  DELETE_CLINICCARD_FAIL,
  GET_CLINICCARD_REQUEST,
  GET_CLINICCARD_SUCCESS,
  GET_CLINICCARD_FAIL,
  CLEAR_CLINICCARD_ERRORS,
  ALL_CLINICCARD_REQUEST,
  ALL_CLINICCARD_SUCCESS,
  ALL_CLINICCARD_FAIL,
} from '../constants/ClinicCardsConstants';

const initialState = {
  clinicCards: [],
  clinicCardCount: 0,
  resultPerPage: 0,
  clinicCard: {},
  isUpdated:false,
  loading: false,
  error: null,
  filteredClinicCardsCount: 0,
};

export const clinicCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CLINICCARD_REQUEST:
    case GET_CLINICCARD_REQUEST:
    case CREATE_CLINICCARD_REQUEST:
    case DELETE_CLINICCARD_REQUEST:
    case UPDATE_CLINICCARD_REQUEST:
      return {
        ...state,
        loading: true,
        clinicCards: [],
        clinicCard: {},
      };
    case ALL_CLINICCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        clinicCards: action.payload.clinicCards,
        clinicCardCount: action.payload.clinicCardsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredClinicCardsCount: action.payload.filteredClinicCardsCount,
      };
    case CREATE_CLINICCARD_SUCCESS:
    case GET_CLINICCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        clinicCard: action.payload,
      };
    case DELETE_CLINICCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_CLINICCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case GET_CLINICCARD_FAIL:
    case ALL_CLINICCARD_FAIL:
    case CREATE_CLINICCARD_FAIL:
    case UPDATE_CLINICCARD_FAIL:
    case DELETE_CLINICCARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_CLINICCARD_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



