import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import NewsCard from './NewsCard';
import useGetImage from '../../../hooks/getImageLink';
import Loader from '../../../components/Loader';

// ----------------------------------------------------------------------

NewsList.propTypes = {
  news: PropTypes.array.isRequired,
};

export default function NewsList({ news,updateNewsDetails,deleteNews, fetchLeagues,setNewsList, ...other }) {


  return (
    <Grid container spacing={3} {...other}>
      {news?.map((news) => (
        <Grid key={news?.id} item xs={12} sm={6} md={3}>
          <NewsCard deleteNews={deleteNews} updateNewsDetails={updateNewsDetails} news={news} setNewsList={setNewsList}  fetchLeagues={fetchLeagues} />
        </Grid>
      ))}
    </Grid>
  );
}
