import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopCampCard from './CampCard';

// ----------------------------------------------------------------------

CampCardList.propTypes = {
  campCards: PropTypes.array.isRequired,
};

export default function CampCardList({ campCards, loading, updateCampCards, deleteCampCards, ...other }) {
  return (
     <Grid container spacing={3} {...other}>
       {campCards?.map((campCard) => (
         <Grid key={campCard.id} item xs={12} sm={6} md={3}>
           <ShopCampCard
             updateCampCards={updateCampCards}
             deleteCampCards={deleteCampCards}
             campCard={campCard}
           />
         </Grid>
       ))}
     </Grid>
  );
 }