import { useState } from 'react';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify'; // Assuming you have a toast library for displaying messages
import { useParams } from 'react-router-dom';

const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const updateUser = async (userAttributes) => {
    try {
      if (!id) {
        toast.error('Id Doest not found');
        return;
      }
      const checkoutPayload = {
        body: {
          Username: id,
          UserAttributes: userAttributes,
        },
      };

      setLoading(true);

      const response = await API.put('user', '/user/update', checkoutPayload);

      console.log('User response - ', response);
      toast.success('User Enabled.');
    } catch (error) {
      console.error('Error Update user:', error);
      toast.error('Error Update user');
    } finally {
      setLoading(false);
    }
  };

  return { updateUser, loading };
};

export default useUpdateUser;
