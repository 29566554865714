import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import getLink from '../utils/getLinks';

const useHandlePlayerUser = () => {
  const [playerUserDetails, setPlayerUser] = useState();
  const [playerList, setPlayerList] = useState([]);
  const [verifiedPlayers, setVerifiedPlayerList] = useState([]);
  const [notVerifiedPlayers, setNotVerifiedPlayerList] = useState([]);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchPlayerUsers = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.listPlayers));
      const data = response?.data?.listPlayers?.items;

      const modifiedT = data?.map((user) => {
        return {
          ...user,
          id: user?.id,
          name: user?.participantName,
          email: user?.email,
          status: user?.isVerified,
          //
          zone: 'Player',
          // Add other attributes you want to include
        };
      });
      const verifiedPlayers = modifiedT?.filter((player) => player.isVerified === true);
      const nonVerifiedPlayers = modifiedT?.filter((player) => player.isVerified !== true);

      setVerifiedPlayerList(verifiedPlayers);
      setNotVerifiedPlayerList(nonVerifiedPlayers);

      setPlayerList(modifiedT);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Players:', error);
      setLoading(false);
    }
  };

  const fetchPlayerUserById = async () => {
    try {
      setLoading(true);

      const response = await API.graphql(graphqlOperation(queries.getPlayer, { id }));
      const data = response?.data?.getPlayer;
      const profileLink = await getLink(data?.profile);
      const d = {
        ...data,
        profileLink,
      };
      setPlayerUser(d);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching Player details:', error);
      setLoading(false);
    }
  };

  const updatePlayerById = async (data) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.updatePlayer,
        variables: { input: { id, ...data } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedData = response?.data?.updatePlayer;

      const profileLink = await getLink(updatedData?.profile);
      const document = await getLink(updatedData?.document);
      const d = {
        ...data,
        documentLink: document,
        profileLink,
      };
      setPlayerList((prevList) => prevList.map((user) => (user.id === updatedData.id ? updatedData : user)));

      setPlayerUser(d);
      toast.success('Player Data Updated Successfully!');
      setLoading(false);
    } catch (error) {
      console.error('Error updating Player:', error);
      setLoading(false);
      toast.error('Something Went Wrong!');
    }
  };

  useEffect(() => {
    if (id) {
      fetchPlayerUserById();
    }
    fetchPlayerUsers();
  }, [id]);

  return {
    playerUserDetails,
    playerList,
    fetchPlayerUsers,
    loading,
    verifiedPlayers,
    notVerifiedPlayers,

    updatePlayerById,
  };
};

export default useHandlePlayerUser;
