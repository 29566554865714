import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { toast } from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import 'react-calendar/dist/Calendar.css';

const ExcludeDaysModal = ({ tutorDetails, currentTutorDetails, setTutorDetails, handleClose }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState({});
  const [currentDate, setCurrentDate] = useState('');
  const { slots, excludeSlots } = currentTutorDetails || {};

  useEffect(() => {
    if (excludeSlots) {
      const initialDates = excludeSlots.map((es) => es.date);
      const initialSlots = excludeSlots.reduce((acc, es) => {
        acc[es.date] = es.slots;
        return acc;
      }, {});
      setSelectedDates(initialDates);
      setSelectedSlots(initialSlots);
    }
  }, [excludeSlots]);

  const handleDateSelection = (newDate) => {
    const currentDate = newDate.$d;
    const dateStr = currentDate.toDateString();

    if (selectedDates.includes(dateStr)) {
      setSelectedDates(selectedDates.filter((date) => date !== dateStr));
      setSelectedSlots((prevSlots) => {
        const updatedSlots = { ...prevSlots };
        delete updatedSlots[dateStr];
        return updatedSlots;
      });
    } else {
      setSelectedDates([dateStr, ...selectedDates]);
      if (!selectedSlots[dateStr]) {
        setSelectedSlots((prevSlots) => ({ ...prevSlots, [dateStr]: [] }));
      }
    }
    setCurrentDate(dateStr);
  };

  const handleSlotSelection = (date, slot) => {
    setSelectedSlots((prevSlots) => {
      const updatedSlots = prevSlots[date]?.includes(slot)
        ? prevSlots[date].filter((s) => s !== slot)
        : [...(prevSlots[date] || []), slot];
      return { ...prevSlots, [date]: updatedSlots };
    });
  };

  const handleRemoveSelectedSlot = (date, slot) => {
    setSelectedSlots((prevSlots) => ({
      ...prevSlots,
      [date]: prevSlots[date].filter((s) => s !== slot),
    }));
  };

  const handleRemoveDate = (date) => {
    setSelectedDates((prevDates) => prevDates.filter((d) => d !== date));
    setSelectedSlots((prevSlots) => {
      const updatedSlots = { ...prevSlots };
      delete updatedSlots[date];
      return updatedSlots;
    });
  };

  const handleSaveExcludeDays = () => {
    const updatedTutorDetails = tutorDetails.map((tut) => {
      if (tut.tutorId === currentTutorDetails.tutorId) {
        selectedDates.forEach((date) => {
          if (tut.excludeSlots && tut.excludeSlots.find((es) => es.date === date)) {
            tut.excludeSlots = tut.excludeSlots.map((es) =>
              es.date === date ? { ...es, slots: [...new Set([...es.slots, ...selectedSlots[date]])] } : es
            );
          } else {
            tut.excludeSlots = [...(tut.excludeSlots || []), { date, slots: selectedSlots[date] }];
          }
        });
      }
      return tut;
    });

    setTutorDetails(updatedTutorDetails);
    toast.success('Exclude days and slots updated successfully');
    handleClose();
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Exclude Days and Slots</DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-col gap-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar className="min-w-fit" onChange={handleDateSelection} />
          </LocalizationProvider>
          {currentDate && (
            <ul className="max-h-[200px] overflow-y-auto flex flex-col gap-2">
              {slots && slots.map((timeSlot, index) => (
                <button
                  className={`px-4 py-2 border rounded ${selectedSlots[currentDate]?.includes(timeSlot) ? 'bg-blue-500 text-white' : 'bg-white'} border-blue-600 hover:bg-blue-500 hover:text-white cursor-pointer transition-all ease-in-out duration-300`}
                  key={index}
                  onClick={() => handleSlotSelection(currentDate, timeSlot)}
                >
                  <span>{`${new Date(timeSlot.startTime).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })} - ${new Date(timeSlot.endTime).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}`}</span>
                </button>
              ))}
            </ul>
          )}
          {selectedDates.length > 0 && (
            <div className="time-table w-full mt-4">
              {selectedDates.map((date, dateIndex) => (
                <div
                  key={dateIndex}
                  onClick={() => setCurrentDate(date)}
                  className="mt-4 hover:bg-gray-100 p-2 cursor-pointer rounded border transition-all ease-in-out duration-300"
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setCurrentDate(date);
                    }
                  }}
                >
                  <div className="flex justify-between items-center">
                    <h4 className="font-semibold">{date}</h4>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveDate(date);
                      }}
                      size="small"
                      className="text-red-600"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>

                  <div className="selected-slots mt-2">
                    <ul className="flex flex-wrap gap-2">
                      {selectedSlots[date]?.map((slot, index) => (
                        <li key={index} className="flex items-center gap-2 bg-gray-200 p-1 rounded">
                          <span className="px-2 py-1 border rounded">
                            {`${new Date(slot.startTime).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                            })} - ${new Date(slot.endTime).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}`}
                          </span>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveSelectedSlot(date, slot);
                            }}
                            size="small"
                            className="ml-2 text-red-600"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSaveExcludeDays} variant="contained" className="bg-orange-800" color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExcludeDaysModal;
