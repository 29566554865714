

export const GET_AD_REQUEST = 'GET_AD_REQUEST';
export const GET_AD_SUCCESS = 'GET_AD_SUCCESS';
export const GET_AD_FAIL = 'GET_AD_FAIL';


export const ALL_AD_REQUEST = 'ALL_AD_REQUEST';
export const ALL_AD_SUCCESS = 'ALL_AD_SUCCESS';
export const ALL_AD_FAIL = 'ALL_AD_FAIL';

export const CREATE_AD_REQUEST = 'CREATE_AD_REQUEST';
export const CREATE_AD_SUCCESS = 'CREATE_AD_SUCCESS';
export const CREATE_AD_FAIL = 'CREATE_AD_FAIL';

export const UPDATE_AD_REQUEST = 'UPDATE_AD_REQUEST';
export const UPDATE_AD_SUCCESS = 'UPDATE_AD_SUCCESS';
export const UPDATE_AD_FAIL = 'UPDATE_AD_FAIL';


export const DELETE_AD_REQUEST = 'DELETE_AD_REQUEST';
export const DELETE_AD_SUCCESS = 'DELETE_AD_SUCCESS';
export const DELETE_AD_FAIL = 'DELETE_AD_FAIL';
export const DELETE_AD_RESET = 'DELETE_AD_RESET';

export const CLEAR_AD_ERRORS = 'CLEAR_AD_ERRORS';
