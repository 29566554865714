/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import getLink from '../utils/getLinks';
import deleteFile from '../utils/deleteFile';
import * as queries from '../graphql/queries';
import uploadFile from '../utils/uploadFile';
import * as mutations from '../graphql/mutations';

const useHandlePagintationEvents5x5 = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [eventsList, setEventsList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };

  const fetchEvents = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        // }

        const result = await API.graphql({
          query: queries.listLeague5x5s,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });
        const fetchedEvents = result?.data?.listLeague5x5s?.items || [];

        // if (fetchEvents.length > 0) {
        const updatedEvents = await Promise.all(
          fetchedEvents.map(async (events) => {
            try {
              const cachedImgUrl = await fetchImageFromCacheWithExpiration(events?.img_url);

              let render_img_url = cachedImgUrl;

              if (!cachedImgUrl) {
                render_img_url = await loadImageAndCache(events?.img_url);
              }

              const dataWithImageUrl = {
                ...events,
                render_img_url,
              };

              return { ...dataWithImageUrl };
            } catch (error) {
              console.error('Error fetching image URL', error);
              return events;
            }
          })
        );
        // }

        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listLeague5x5s?.nextToken);
        }

        setEventsList([...updatedEvents]);
        setNextNextToken(result?.data?.listLeague5x5s?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Events:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteEvents = async (id) => {
    try {
      setLoading(true);
      const res = await API.graphql(graphqlOperation(queries.getLeague5x5, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const isDeleted = await deleteFile(res?.data?.getLeague5x5?.img_url);

      if (isDeleted) {
        const response = await API.graphql({
          query: mutations.deleteLeague5x5,
          variables: { input: { id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        console.log(response);
        toast.success('Events Deleted Successfully!');
        setLoading(false);

        // Remove deleted events from the eventsList
        setEventsList((prevEventsList) => prevEventsList.filter((item) => item.id !== id));

        // Remove deleted images from local storage
        localStorage.removeItem(res?.data?.deleteLeague5x5?.img_url);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Events:', error);
    }
  };

  const updateEvents = async (eventId, formData) => {
    try {
      setLoading(true);
      const league = await API.graphql(graphqlOperation(queries.getLeague5x5, { id: eventId }));

      const { newImage, img_url } = formData;
      console.log('formData', formData);
      let imgUrl = league?.data?.getLeague5x5?.img_url;

      let render_img_url;
      console.log('img_url ', img_url);
      console.log('newImage ', newImage);
      render_img_url = await fetchImageFromCacheWithExpiration(imgUrl);

      if (newImage && newImage !== img_url) {
        console.log('newImage2 ', newImage);
        const isDeleted = await deleteFile(imgUrl);

        localStorage.removeItem(imgUrl);

        imgUrl = await uploadFile(newImage);
        render_img_url = await fetchImageFromCacheWithExpiration(imgUrl);
      }
      const data = {
        title: formData?.title,
        selling_price: formData?.selling_price,

        description: formData?.description,
        location: formData?.location,
        date: formData?.date,
        img_url: imgUrl,
        eventStatus: 'EVENT_NOT_STARTED',
      };
      // const teamsId = [];
      if (formData?.teamsName) {
        const teamsId = await Promise.all(
          formData.teamsName.map(async (team) => {
            const teams1 = await API.graphql({
              query: queries.listTeam5x5s,
              variables: {
                filter: {
                  name: {
                    eq: team.firstTeam,
                  },
                },
              },
              authMode: GRAPHQL_AUTH_MODE.API_KEY,
            });

            const teams2 = await API.graphql({
              query: queries.listTeam5x5s,
              variables: {
                filter: {
                  name: {
                    eq: team.secondTeam,
                  },
                },
              },
              authMode: GRAPHQL_AUTH_MODE.API_KEY,
            });

            console.log('teams1', teams1);
            console.log('teams2', teams2);

            return {
              firstTeamId: teams1.data.listTeam5x5s.items[0].id,
              secondTeamId: teams2.data.listTeam5x5s.items[0].id,
            };
          })
        );

        console.log('teamsId', teamsId);

        // Create a new array without the __typename field in each object
        // const updatedTeamsName = formData.teamsName.map(({ __typename, ...rest }) => rest);

        console.log('updatedTeamsName', formData.teamsName);
        // Use the updatedTeamsName array in your data object

        const teamsName = formData?.teamsName?.map(({ __typename, ...rest }) => rest);
        // const updatedTeamsId = event?.teamsId?.map(({ __typename, ...rest }) => rest);
        const data = {
          title: formData?.title,
          selling_price: formData?.selling_price,
          teamsId,
          description: formData?.description,
          location: formData?.location,
          date: formData?.date,
          img_url: imgUrl,
          eventStatus: 'EVENT_NOT_STARTED',
          teamsName, // Use the updatedTeamsName array
        };
      }

      const response = await API.graphql({
        query: mutations.updateLeague5x5,
        variables: { input: { ...data, id: eventId } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setLoading(false);
      const updatedDetails = {
        ...response?.data?.updateLeague5x5,
        render_img_url,
      };

      setEventsList((prevEventsList) => {
        const updatedNewsList = prevEventsList?.map((item) => {
          if (item.id === eventId) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedNewsList;
      });

      toast.success('Events Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating events details:', error);
      toast.error('Error updating events details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const addEvent = async (items) => {
    setLoading(true);

    try {
      setLoading(true);

      // Upload the image to S3 using Storage.put
      const updatedData = { ...items };

      const key = await uploadFile(updatedData?.img_url);
      const response = await create(key, updatedData);
      const render_img_url = await fetchImageFromCacheWithExpiration(key);

      const eventsData = {
        ...response?.data?.createLeague5x5,
        render_img_url,
        eventStatus: 'EVENT_NOT_STARTED',
      };
      // Check if the team was successfully created
      setLoading(false);
      setEventsList((prevEventsList) => [eventsData, ...prevEventsList]); // Add the new events data to the events list

      // Fetch events URL from AWS and update eventsList
      // Implement fetchEventsUrlFromAWS to retrieve events data

      setLoading(false);
      toast.success(`Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating events:', error);
      throw error;
    }
  };
  const create = async (key, updatedData) => {
    setLoading(true);

    const response = await API.graphql({
      query: mutations.createLeague5x5,
      variables: {
        input: {
          ...updatedData,
          img_url: key,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    return response;
  };
  useEffect(() => {
    fetchEvents('');
  }, [nextToken]);

  return {
    eventsList,
    nextNextToken,
    loading,
    deleteEvents,
    fetchNextPage,
    fetchEvents,
    fetchPreviousPage,
    previousTokens,
    addEvent,
    updateEvents,
  };
};

export default useHandlePagintationEvents5x5;
