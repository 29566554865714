import {
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAIL,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAIL,
  CLEAR_EVENT_ERRORS,
  ALL_EVENT_REQUEST,
  ALL_EVENT_SUCCESS,
  ALL_EVENT_FAIL,
} from '../constants/Events5x5Constants';

const initialState = {
  event5x5s: [],
  notStartedevents: [],
  startedEvents: [],
  endedEvents: [],

  eventCount: 0,
  resultPerPage: 0,
  event: {},
  isUpdated:false,
  loading: false,
  error: null,
  filteredEventsCount: 0,
};

export const event5x5sReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_EVENT_REQUEST:
    case GET_EVENT_REQUEST:
    case CREATE_EVENT_REQUEST:
    case DELETE_EVENT_REQUEST:
    case UPDATE_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        event5x5s: [],
        event: {},
      };
    case ALL_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        event5x5s: action.payload.events,
        notStartedevents: action.payload.notStartedevents,
        startedEvents: action.payload.startedEvents,
        endedEvents: action.payload.endedEvents,
        eventCount: action.payload.eventsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredEventsCount: action.payload.filteredEventsCount,
      };
    case CREATE_EVENT_SUCCESS:
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        event: action.payload,
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case GET_EVENT_FAIL:
    case ALL_EVENT_FAIL:
    case CREATE_EVENT_FAIL:
    case UPDATE_EVENT_FAIL:
    case DELETE_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_EVENT_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



