import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import WordEditor from '../components/blog/Write';
import ShowPreview from '../components/blog/ShowPreview';
import useHandleNews from '../hooks/handlePaginationNews';
import Loader from '../components/Loader';
import uploadFile from '../utils/uploadFile';

const WriteNews = () => {
  const [content, setContent] = useState('');

  const [image, setImages] = useState([]);
  const [preview, setPreview] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState();
  const [newsType, setNewsType] = useState('NEWS'); // Default news type
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const {
    fetchNewsById,

    loading,
    updateNewsContent,
    createNewsContent,
  } = useHandleNews();
  const handleWrite = (newContent) => {
    setContent(newContent);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleNewsTypeChange = (event) => {
    setNewsType(event.target.value);
  };

  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    setShowCancelDialog(false);
    // Redirect to home page
    // history.push('/dashboard/news');
    navigate('/dashboard/news');
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
  };
  const handleSave = async () => {
    try {
      const { images, videos, iframe, contentUpdated } = await extractImagesAndVideos(content);
      const text = extractText(content);
      console.log("IMAGE", image)
      console.log("contentUpdated", contentUpdated)
      if (id) {
        const data = {
          title,
          content,
          type: newsType,
          text,
        };

        // if (videos) {
        //  data.videos = videos;
        // }
        if (iframe) {
          data.iframe = iframe;
        }

        // Save the news content with updated image sources
        await updateNewsContent(data, id);
      } else {
        const data = {
          title,
          content,
          type: newsType,
          text,
        };

        // if (videos) {
        //   data.videos = videos;
        // }
        if (iframe) {
          data.iframe = iframe;
        }

        // Save the news content with updated image sources
        await createNewsContent(data);
      }

      navigate('/dashboard/news');
      console.log('Save functionality executed successfully');
    } catch (error) {
      console.error('Error during handleSave:', error);
    }
  };

  const extractText = (content) => {
    // Replace HTML tags and extract plain text
    const div = document.createElement('div');
    div.innerHTML = content;
    const text = div.innerText.trim();

    return text;
  };
  // Function to extract images and videos from HTML content
  const extractImagesAndVideos = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    console.log("DOC", doc)
    const images = [];
    const videos = [];
    const iframe = [];
    const contentUpdated = htmlContent;
    console.log("contentUpdated", contentUpdated);
    console.log("htmlContent", htmlContent)
    // Extract images
    // Extract videos
    const videoElements = doc.querySelectorAll('video');
    videoElements.forEach((element) => {
      const src = element.getAttribute('src');
      videos.push(src);
    });

    // Extract iframes
    const iframeElements = doc.querySelectorAll('iframe');
    iframeElements.forEach((element) => {
      const src = element.getAttribute('src');
      iframe.push(src);
    });

    return { images, videos, iframe, contentUpdated };
  };

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const newsData = await fetchNewsById(id);
        setContent(newsData?.brief);
        console.log('newsData', newsData);
        setTitle(newsData?.title);
        setNewsType(newsData?.type);
      };
      fetchData();
    }
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box className="flex flex-col gap-[25px] py-2 px-2 w-full max-w-[1280px] mx-auto h-full">
      <Box className="flex gap-2 justify-between items-center">
        <Box className="flex gap-2 items-center">
          <button
            className={`border transition-all ease-in-out duration-300  text-[16px]  font-bold ${!preview
              ? 'bg-blue-500  hover:bg-blue-600  text-white'
              : 'border text-blue-500   border-blue-400 hover:bg-blue-200'
              } text-black p-1 px-3 rounded-lg`}
            onClick={() => setPreview(false)}
          >
            Edit
          </button>
          <button
            className={`border transition-all text-[16px] ease-in-out duration-300  font-bold ${preview
              ? 'bg-blue-500 hover:bg-blue-600 rounded-lg text-white'
              : 'border text-blue-500   border-blue-400 hover:bg-blue-200'
              } text-black p-1 rounded-lg px-3`}
            onClick={() => setPreview(true)}
          >
            Preview
          </button>
        </Box>
        <Box className="flex gap-2 items-center">
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="text" color="secondary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
      <Box className="flex gap-2">
        <FormControl fullWidth>
          {/* <InputLabel htmlFor="title">Title</InputLabel> */}
          <TextField id="title" variant="outlined" placeholder='Title' value={title} onChange={handleTitleChange} />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="newsType">Type</InputLabel>
          <Select
            labelId="newsType"
            id="newsType"
            value={newsType}
            onChange={handleNewsTypeChange}
            label="Type"
            variant="outlined"
          >
            <MenuItem value="NEWS">NEWS</MenuItem>
            <MenuItem value="BLOG">BLOG</MenuItem>
            <MenuItem value="VLOG">VIDEO BLOG</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className="flex justify-between w-full mt-2">
        {preview ? (
          <ShowPreview content={content} title={title} newsType={newsType} />
        ) : (
          <WordEditor image={image} setImages={setImages} content={content} setContent={handleWrite} />
        )}
      </Box>
      <Dialog open={showCancelDialog} onClose={handleCancelDialogClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>If you cancel, your changes will be lost. Do you really want to cancel?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="primary">
            <a href="/dashboard/news">Yes</a>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WriteNews;



const decodeBase64ToBlob = (base64, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i + 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

