import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Container, Stack, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import {  SessionList, AddSessionSideBar } from '../sections/@dashboard/session';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader';
import { getAllSessions,clearSessionsErrors} from "../redux/actions/SessionAction";
import useHandlePagintationSession from '../hooks/useHandlePagintationSession';


export default function Session() {

  const {
    sessionList,
    nextNextToken,
    loading,
    deleteSession,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
    addSession,
    updateSession,
  } = useHandlePagintationSession();

  const [openSession, setOpenSession] = useState(false);
  const [sessions, setSessions] = useState(sessionList);

  const handleOpenSession = () => {
    setOpenSession(true);
  };
  const handleCloseSession = () => {
    setOpenSession(false);
  };

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };

  useEffect(() => {
    setSessions(sessionList || []);
  }, [sessionList]);

  if(loading){
    return <Loader/>
  }   

  return (
    <>
      <Helmet>
        <title> Dashboard: Session | Minimal UI </title>
      </Helmet>

      <Container>
        <ToastContainer />
        <Typography variant="h4" sx={{ mb: 5 }}>
          Session
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <AddSessionSideBar
              openSession={openSession}
              onCloseSession={handleCloseSession}
              onOpenSession={handleOpenSession}
              addSession={addSession}

            />
          </Stack>
        </Stack>
        <SessionList  
         sessions={sessions}
            updateSession={updateSession}
            deleteSession={deleteSession} />
               {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
           {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
}
