/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';

import * as mutations from '../graphql/mutations'; // Import your GraphQL mutations
import useDeleteDataFromS3 from './deleteDataFromS3';
import * as queries from '../graphql/queries';
import getLink from '../utils/getLinks';
import uploadFile from '../utils/uploadFile';
import deleteFile from '../utils/deleteFile';

const useUpdateNewsDetails = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [contentImageUrl, setContentImageUrl] = useState('');
  const { VideoDelete } = useDeleteDataFromS3();

  const updateNewsDetails = async (id, newData) => {
    try {
      const {
        type,
        content_img_url: contentImgKey,
        img_url: imgKey,
        title,
        brief,
        newImage,
        newContentImageUrl,
        newVideoUrl,
        view_count: view,
        id,
        content_text: contentText,
        video_url: videoKey,
      } = newData;
      console.log('New Daata', newData);
      setIsUpdating(true);
      const res = await API.graphql(graphqlOperation(queries.getNews, { id }));

      // const res = await API.graphql({
      //   query: queries.getNews,
      //   variables: {
      //     input: {id },
      //   },
      //   authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      console.log('Newsssdata', newData);

      // const productDeleted = response.data.deleteSignupLeague; // Updated response

      let imageKey = res?.data?.getNews?.img_url || '';
      let contentImageKeys = res?.data?.getNews?.content_img_url || '';
      let videoKeys = res?.data?.getNews?.video_url || '';

      if (type === 'NEWS' || type === 'BLOG') {
        if (newContentImageUrl) {
          const isDeleted = await deleteFile(res?.getNews?.content_img_url);

          contentImageKeys = await uploadFile(newContentImageUrl);
        }
        if (newImage) {
          const isDeleted = await deleteFile(res?.getNews?.img_url);

          imageKey = await uploadFile(newImage);
        }
      } else if (type === 'VLOG') {
        if (newVideoUrl) {
          const isDeleted = await deleteFile(res?.getNews?.video_url);
          videoKeys = await uploadFile(newImage);
        }
      }

      const data = {
        img_url: imageKey,
        content_img_url: contentImageKeys,
        video_url: videoKeys,
        title,
        type,
        brief,
        view_count: res?.data?.getNews?.view_count,
        id,
        content_text: contentText,
      };

      const response = await API.graphql({
        query: mutations.updateNews,
        variables: {
          input: { ...data },
        },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      if (type === 'NEWS') {
        toast.success('NEWS Updated Successfully!');
      }
      if (type === 'BLOG') {
        toast.success('BLOG Updated Successfully!');
      }
      if (type === 'VLOG') {
        toast.success('VLOG Updated Successfully!');
      }

      // const updatedDetails = response?.data?.updateNews; // Assuming your mutation returns the updated details
      setIsUpdating(false);
      return true;
    } catch (error) {
      console.error('Error updating news details:', error);
      setIsUpdating(false);
      toast.error(error);
      throw error; // Propagate the error for handling in the component
    }
  };

  return { updateNewsDetails, isUpdating };
};

export default useUpdateNewsDetails;
