import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

import {
  REGISTER_TOURNAMENT_REQUEST,
  REGISTER_TOURNAMENT_SUCCESS,
  REGISTER_TOURNAMENT_FAIL,
  UPDATE_TOURNAMENT_REQUEST,
  UPDATE_TOURNAMENT_SUCCESS,
  UPDATE_TOURNAMENT_FAIL,
  GET_TOURNAMENT_REQUEST,
  GET_TOURNAMENT_SUCCESS,
  GET_TOURNAMENT_FAIL,
  LOAD_TOURNAMENTS_REQUEST,
  LOAD_TOURNAMENTS_SUCCESS,
  LOAD_TOURNAMENTS_FAIL,
  DELETE_TOURNAMENT_REQUEST,
  DELETE_TOURNAMENT_SUCCESS,
  DELETE_TOURNAMENT_FAIL,
  DELETE_TOURNAMENT_RESET,
  CLEAR_TOURNAMENT_ERRORS,
} from '../constants/TournamentConstants';
import getLinks from '../../utils/getLinks';
import uploadFile from '../../utils/uploadFile';

export const getAllTournament = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_TOURNAMENTS_REQUEST });

    const response = await API.graphql({
      query: queries.listTournaments,
      variables: {
        filter: {
          isVerified: {
            eq: true,
          },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    dispatch({
      type: LOAD_TOURNAMENTS_SUCCESS,
      payload: response?.data?.listTournaments.items,
    });
  } catch (error) {
    dispatch({
      type: LOAD_TOURNAMENTS_FAIL,
      payload: error.message || 'Failed to fetch Tournament data',
    });
  }
};

export const getTournamentDetails = (tournamentId) => async (dispatch) => {
  try {
    dispatch({ type: GET_TOURNAMENT_REQUEST });

    const tournamentResponse = await API.graphql({
      query: queries.getTournament,
      variables: {
        id: tournamentId,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const tournamentData = tournamentResponse?.data?.getTournament;
    console.log('tournamentData', tournamentData);

    const signedUrl = await getLinks(tournamentData?.profile);

    const updatedData = {
      ...tournamentData,
      img_url: signedUrl,
      profile: signedUrl,
    };

    dispatch({
      type: GET_TOURNAMENT_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch TOURNAMENT details';

    dispatch({
      type: GET_TOURNAMENT_FAIL,
      payload: errorMessage,
    });
  }
};
export const verifyTournament = (playerId) => async (dispatch) => {
  console.log('verifyTournament', playerId);
  try {
    dispatch({ type: UPDATE_TOURNAMENT_REQUEST });
    const playerDetails = await API.graphql({
      query: queries.getTournament,
      variables: {
        id: playerId,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    // const playerD=playerDetails.data?.getPlayer;
    const playerD = { ...playerDetails.data?.getTournament };
    delete playerD.__typename;
    delete playerD.updatedAt;
    delete playerD.createdAt;

    const response = await API.graphql({
      query: mutations.updateTournament,
      variables: {
        input: {
          // ...playerD,
          id: playerId,
          isVerified: true,
          // Add other fields here...
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    toast.success('TOURNAMENT Verified');
    dispatch({
      type: UPDATE_TOURNAMENT_SUCCESS,
      payload: { updated: true },
    });
    // }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch TOURNAMENT details';

    dispatch({
      type: UPDATE_TOURNAMENT_FAIL,
      payload: errorMessage,
    });
  }
};

export const registerAsTournament = (data, userId) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_TOURNAMENT_REQUEST });
    const profileImage = await uploadFile(data.profile);

    const response = await API.graphql({
      query: mutations.createTournament,

      variables: {
        input: {
          userId,
          ...data,
          profile: profileImage,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    dispatch({
      type: REGISTER_TOURNAMENT_SUCCESS,
      payload: response?.data?.createTournament,
    });
    toast.success('Registered Successfully!');
    // window.location ='/';
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: REGISTER_TOURNAMENT_FAIL,
      payload: errorMessage,
    });
  }
};

export const clearTournamentErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_TOURNAMENT_ERRORS });
};
