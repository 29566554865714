import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
// Import the toast styles
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import useDeleteDataFromS3 from './deleteDataFromS3';
import deleteFile from '../utils/deleteFile';

const useDeleteAdvertisement = () => {
  const [loading, setLoading] = useState(false);

  // Add loading state

  const deleteAdvertisement = async (id) => {
    try {
      setLoading(true); // Set loading to true when fetching data
      const res = await API.graphql(graphqlOperation(queries.getAdvertisement, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const editData = res?.data.getAdvertisement;

      const lgImageUrl = editData?.lg_img_url;
      const mdImageUrl = editData?.md_img_url;
      const smImageUrl = editData?.sm_img_url;
      const xsImageUrl = editData?.xs_img_url;

      const lgDeleted = await deleteFile(lgImageUrl);
      const mdDeleted = await deleteFile(mdImageUrl);
      const smDeleted = await deleteFile(smImageUrl);
      const xsDeleted = await deleteFile(xsImageUrl);

      if (lgDeleted && mdDeleted && smDeleted && xsDeleted) {
        const response = await API.graphql({
          query: mutations.deleteAdvertisement,
          variables: {
            input: { id },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        // const productDeleted = response.data.deleteSignupLeague; // Updated response

        toast.success('Advertisement Deleted');
      } else {
        toast.error('Image Not Deleted');
      }

      setLoading(false); //

      // Show success toast
    } catch (error) {
      console.error('Error Deleting Advertisement :', error);
      toast.error('Something Went Wrong: Check Console');

      setLoading(false); // Set loading to false in case of an error
      // Show error toast
    }
  };

  // Call the deleteAdvertisement function when the component mounts

  return { deleteAdvertisement, loading }; // Include loading state in the return value
};

export default useDeleteAdvertisement;
