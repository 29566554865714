import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { toast } from 'react-toastify';

import 'react-quill/dist/quill.snow.css';
import deleteFile from '../../utils/deleteFile';
import uploadFile from '../../utils/uploadFile';
import './quill-custom-styles.css'; // Import the custom styles
import getLink from '../../utils/getLinks';
import { getKey } from '../../utils/getKey';


const WordEditor = ({ content, setContent, images, setImages }) => {
  const quillRef = useRef();
  const [previousContent, setPreviousContent] = useState(content);
  const [imageUrls, setImageUrls] = useState(images || []);
  const [isLoading, setIsLoading] = useState(false); // State for managing loading status

  useEffect(() => {
    quillRef.current
      .getEditor()
      .getModule('toolbar')
      .addHandler('image', async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
          if (!input.files || !input?.files?.length || !input?.files?.[0]) return;
          const editor = quillRef?.current?.getEditor();
          const file = input.files[0];

          setIsLoading(true); // Show loader
          try {
            const key = await uploadFile(file);
            console.log("KEY", key)
            const imageUrl = await getLink(key);
            setImageUrls((prevUrls) => [...prevUrls, imageUrl]);
            setImages((prevUrls) => [...prevUrls, key]);
            console.log("KEY HERE", key)
            const range = editor.getSelection(true);
            editor.insertEmbed(range.index, 'image', imageUrl);
          } catch (error) {
            console.log("Error", error)
            toast.error('Failed to upload the image');
          } finally {
            setIsLoading(false); // Hide loader
          }
        };
      });

    if (previousContent !== content) {
      const previousDoc = new DOMParser().parseFromString(previousContent, 'text/html');
      const currentDoc = new DOMParser().parseFromString(content, 'text/html');
      const previousImages = Array.from(previousDoc.querySelectorAll('img')).map((img) => img.src);
      const currentImages = Array.from(currentDoc.querySelectorAll('img')).map((img) => img.src);

      const removedImages = previousImages.filter((src) => !currentImages.includes(src));
      removedImages.forEach(async (src) => {
        if (imageUrls.includes(src)) {
          const key = getKey(src);
          console.log("DEETE")
          const deleted = await deleteFile(key);
          setImageUrls(imageUrls.filter((url) => url !== src));
          setImages(imageUrls.filter((url) => url !== key));
        }
      });

      setPreviousContent(content);
    }
  }, [content, quillRef, previousContent, imageUrls, setImages]);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'], // Added 'video' to the toolbar
        ['clean'],
      ],
    },
  };

  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link', 'image', 'video', // Added 'video' to the formats
  ];

  // Custom handler for inserting videos
  const insertVideo = () => {
    const url = prompt('Enter the URL of the video (YouTube, Vimeo, etc.):');
    if (url) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);
      quill.insertEmbed(range.index, 'video', url, 'user');
    }
  };

  return (
    <>

      <ReactQuill
        theme='snow'
        value={content}
        onChange={setContent}
        onBlur={() => setContent(content)}
        modules={modules}
        formats={formats}
        placeholder='Type here... Max three images can be added, each less than 50kb. Images will be automatically compressed if the size is greater than 50kb.'
        className='w-full max-w-[1280px] min-h-[250px] h-full h-auto bg-white'
        ref={quillRef}
        style={{ opacity: isLoading ? 0.5 : 1 }} // Adjust opacity when loading
      />
    </>
  );
};

export default WordEditor;
