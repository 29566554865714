

export const GET_COURSE_REQUEST = 'GET_COURSE_REQUEST';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_COURSE_FAIL = 'GET_COURSE_FAIL';


export const ALL_COURSE_REQUEST = 'ALL_COURSE_REQUEST';
export const ALL_COURSE_SUCCESS = 'ALL_COURSE_SUCCESS';
export const ALL_COURSE_FAIL = 'ALL_COURSE_FAIL';

export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_FAIL = 'CREATE_COURSE_FAIL';

export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL';


export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAIL = 'DELETE_COURSE_FAIL';
export const DELETE_COURSE_RESET = 'DELETE_COURSE_RESET';

export const CLEAR_COURSE_ERRORS = 'CLEAR_COURSE_ERRORS';
