import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
import {
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAIL,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAIL,
  CLEAR_EVENT_ERRORS,
  ALL_EVENT_REQUEST,
  ALL_EVENT_SUCCESS,
  ALL_EVENT_FAIL,
} from '../constants/CampEventsConstants';

export const getAllEvents = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_EVENT_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listCampEvents));

    if (
      !response ||
      !response.data ||
      !response.data.listCampEvents ||
      !Array.isArray(response.data.listCampEvents.items)
    ) {
      throw new Error('Invalid response data');
    }

    const data = response.data.listCampEvents.items || [];
    const updatedEvents = await Promise.all(
      data?.map(async (event) => {
        try {
          return { ...event };
        } catch (error) {
          console.error('Error fetching image URL', error);
          return event;
        }
      })
    );

      const updatedData = {
      campEvents: updatedEvents,
      eventsCount: updatedEvents?.length,
      resultPerPage: 10,
      filteredEventsCount: null,
    };

    dispatch({
      type: ALL_EVENT_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    console.error('Error fetching events:', error);
    toast.error('Something Went Wrong!');
    dispatch({
      type: ALL_EVENT_FAIL,
      payload: error.message || 'An error occurred while fetching events',
    });
  }
};

export const getEventDetails = (eventId) => async (dispatch) => {
  try {
    dispatch({ type: GET_EVENT_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getCampEvent, { id: eventId }));

    const data = response?.data.getCampEvent;

    const newData = { ...data};
    dispatch({
      type: GET_EVENT_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_EVENT_FAIL,
      payload: errorMessage,
    });
  }
};

export const createEvent = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_EVENT_REQUEST });
    const response = await API.graphql({
      query: mutations.createCampEvent,

      variables: {
        input: {
          ...formData,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createCampEvent;
    toast.success('Event Added Successfully!');

    dispatch({
      type: CREATE_EVENT_SUCCESS,
      payload: data,
    });
    toast.success('Created!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('EVENT error', error);

    dispatch({
      type: CREATE_EVENT_FAIL,
      payload: error,
    });
  }
};
export const deleteEvent = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_EVENT_REQUEST });
   
      API.graphql({
        query: mutations.deleteCampEvent,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }).then(async (response) => {
        toast.success('Event Deleted Successfully!');

        console.log(response);
        dispatch({
          type: DELETE_EVENT_SUCCESS,
          payload: true,
        });
      });
   
    toast.success('Deleted!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_EVENT_FAIL,
      payload: error || 'Failed to fetch EVENT data',
    });
  }
};
export const updateEvent = (eventId, formData, oldImage) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_EVENT_REQUEST });
  
    const data = {
      name: formData?.name,
      cityOfRegistration: formData?.cityOfRegistration,
   
    };

    const response = await API.graphql({
      query: mutations.updateCampEvent,
      variables: { input: { ...data, id: eventId } },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const updatedDetails = response?.data?.updateCampEvent;
    toast.success('Event Updated Successfully!');
    dispatch({
      type: UPDATE_EVENT_SUCCESS,
      payload: updatedDetails,
    });
    toast.success('Updated!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.error('Error updating EVENT:', error);

    dispatch({
      type: UPDATE_EVENT_FAIL,
      payload: error || 'Failed to update EVENT',
    });
  }
};
export const clearEventsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_EVENT_ERRORS });
};
