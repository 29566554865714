import {
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  LIST_CATEGORIES_REQUEST,
  LIST_CATEGORIES_SUCCESS,
  LIST_CATEGORIES_FAIL,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY_RESET,
  CLEAR_CATEGORY_ERRORS,
  
  
} from "../constants/CategoryConstants";

  const initialState = {
    categories: [],
    categoryCount: 0,
    resultPerPage: 0,
    category: {},
    loading: false,
    error: null,
    filteredCategoryCount: 0,
  };
  
  export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case LIST_CATEGORIES_REQUEST:
      case GET_CATEGORY_REQUEST:
        case DELETE_CATEGORY_REQUEST:
          case CREATE_CATEGORY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case LIST_CATEGORIES_SUCCESS:
        return {
          ...state,
          loading: false,
          categories: action.payload.categories,
          categoryCount: action.payload.categoryCount,
          resultPerPage: action.payload.resultPerPage,
          filteredCategoryCount: action.payload.filteredCategoryCount,
        };
  
        case GET_CATEGORY_SUCCESS:
          return {
                 ...state,
            loading: false,
            categories:action.payload

          };
          case CREATE_CATEGORY_SUCCESS:
            return {
              ...state,
              added: true,
              loading: false,
            };
      case GET_CATEGORY_FAIL:
      case LIST_CATEGORIES_FAIL:
        case CREATE_CATEGORY_FAIL:
          case DELETE_CATEGORY_FAIL:
        return {
               ...state,
          loading: false,
          error: action.payload,
        };
        case DELETE_CATEGORY_SUCCESS:
          return {
            ...state,
            deleted: true,
            loading: false,
          };
      case CLEAR_CATEGORY_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
 