import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const AuthRedirect = ({ children }) => {
  const navigate=useNavigate()
  useEffect(() => {
    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        // If the user is not authenticated, redirect to the login page
        navigate('/login'); // Replace '/login' with your login route
      }
    };

    checkAuth();
  }, [navigate]);
  // If authenticated, render the children (component)
  return <>{children}</>;
};

export default AuthRedirect;
