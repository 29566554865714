import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import z from 'zod';
import { useState } from 'react';
import { verifyPlayer } from 'src/redux/actions/PlayerAction';
import getLink from 'src/utils/getLinks';

const ComptetiveUserSchema = z.object({
  id: z.string().optional(),
  userId: z.string(),
  isVerified: z.boolean().optional(),
  league: z.string().optional(),
  agegroup: z.string().optional(),
  ext: z.string().optional(),
  participantName: z.string().optional(),
  cityOfRegistration: z.string(),
  teamID: z.string(),
  isAccepted: z.boolean().optional(),
  profile: z.string().optional(),
  teamName: z.string(),
  message: z.string().optional(),
  token: z.string().optional(),
  tokenExpire: z.string().optional(),
  dateOfBirth: z.string().optional(),
  isAdult: z.boolean().optional(),
  grade: z.string().optional(),
  email: z.string(),
  jerseySize: z.string().optional(),
  jerseyNo: z.number().optional(),
  sessionId: z.string().optional(),
  seasonIds: z.array(z.string()).optional(),
  schoolName: z.string().optional(),
  transactionStatus: z.enum(['PAID', 'PENDING', 'FAILED']).optional(),
  memberExpire: z.string().optional(),
  isMemberOfOtherTeam: z.boolean().optional(),
  clubName: z.string().optional(),
  secondaryPersonName: z.string().optional(),
  primaryPersonName: z.string().optional(),
  isFirstYearPlaying: z.boolean().optional(),
  primaryCallPhoneNumber: z.string().optional(),
  secondaryCallPhoneNumber: z.string().optional(),
  primaryEmergencyContactEmail: z.string().optional(),
  secondaryEmergencyContactEmail: z.string().optional(),
  heardAboutLeague: z.string().optional(),
  healthConditions: z.boolean().optional(),
  parentFirstName: z.string().optional(),
  parentLastName: z.string().optional(),
  parentEmailAddress: z.string().optional(),
  parentPhoneNumber: z.string().optional(),
  parentBusinessPhoneNumber: z.string().optional(),
  parentHomePhoneNumber: z.string().optional(),
  parentCountry: z.string().optional(),
  signature: z.string().optional(),
  document: z.string().optional(),
  HEIGHT: z.string().optional(),
  WEIGHT: z.string().optional(),
  H_S: z.string().optional(),
  NBA: z.string().optional(),
  COLLEGE: z.string().optional(),
  phoneNumber: z.string().optional(),
});

const UpdateUser = ComptetiveUserSchema.partial().extend({
  id: z.string(),
});
const useHandleComptetivePlayers = () => {
  const [loading, setLoading] = useState(false);

  const createPlayer = async (teamData) => {
    setLoading(true);

    const parsedData = ComptetiveUserSchema.parse(teamData);
    try {
      const response = await API.graphql(graphqlOperation(mutations.createComptetiveUser, { input: parsedData }), {
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      toast.success('Team created successfully!');
      return response;
    } catch (error) {
      toast.error('Failed to create team.');
      throw error;
    } finally {
      setLoading(false);
    }
  };
  // const invitePlayer = async (teamData) => {
  //   setLoading(true);

  //   try {
  //     console.log("teamData", teamData)

  //     // Validate the data using zod schema
  //     const parsedData = ComptetiveUserSchema.parse(teamData);
  //     console.log("teamData", teamData)

  //     const apiName = 'invitePlayeAPi';
  //     const path = '/invite';
  //     const myInit = {
  //       body: {
  //         userId: parsedData.userId,
  //         email: parsedData.email,
  //         teamID: parsedData.teamID,
  //         teamName: parsedData.teamName,

  //         cityOfRegistration: parsedData.cityOfRegistration,
  //         agegroup: parsedData.agegroup
  //       },
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     };

  //     // Use API.post (lowercase 'p') instead of API.Post
  //     const response = await API.post(apiName, path, myInit);

  //     if (response.success) {
  //       toast.success("Player invited successfully!");
  //     } else {
  //       throw new Error(response.message || 'Failed to invite player');
  //     }

  //     return response;

  //   } catch (error) {
  //     console.error('Error inviting player:', error);
  //     toast.error(error.message || "Failed to invite player.");
  //     throw error;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const invitePlayer = async (teamData) => {
    setLoading(true);

    try {
      // Check if teamData is an array or a single object
      const dataToProcess = Array.isArray(teamData) ? teamData : [teamData];
      console.log('teamData dataToProcess', teamData, dataToProcess);
      // Process multiple invitations
      const invitationResults = await Promise.all(
        dataToProcess.map(async (singleData) => {
          try {
            // Validate the data using zod schema
            const parsedData = ComptetiveUserSchema.parse(singleData);

            const apiName = 'invitePlayeAPi';
            const path = '/invite';
            const myInit = {
              body: {
                userId: parsedData.userId,
                email: parsedData.email,
                teamID: parsedData.teamID,
                teamName: parsedData.teamName,
                cityOfRegistration: parsedData.cityOfRegistration,
                agegroup: parsedData.agegroup,
              },
              headers: {
                'Content-Type': 'application/json',
              },
            };

            // Use API.post (lowercase 'p') instead of API.Post
            const response = await API.post(apiName, path, myInit);

            if (response.success) {
              return { success: true, email: parsedData.email };
            } else {
              return {
                success: false,
                email: parsedData.email,
                message: response.message || 'Failed to invite player',
              };
            }
          } catch (error) {
            return {
              success: false,
              email: singleData.email,
              message: error.message || 'Failed to invite player',
            };
          }
        })
      );

      // Handle results
      const successfulInvites = invitationResults.filter((result) => result.success);
      const failedInvites = invitationResults.filter((result) => !result.success);

      if (successfulInvites.length > 0) {
        toast.success(`Successfully invited ${successfulInvites.length} player(s)`);
      }

      if (failedInvites.length > 0) {
        const errorMessages = failedInvites.map((invite) => `${invite.email}: ${invite.message}`).join('\n');

        toast.error(`Failed to invite ${failedInvites.length} player(s):\n${errorMessages}`);
      }

      return invitationResults;
    } catch (error) {
      console.error('Error in invitation process:', error);
      toast.error('An unexpected error occurred during invitations.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updatePlayers = async (teamId, teamData) => {
    setLoading(true);

    const parsedData = ComptetiveUserSchema.parse(teamData);
    try {
      const response = await API.graphql(
        graphqlOperation(mutations.updateComptetiveUser, { input: { id: teamId, ...parsedData } }),
        { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
      );
      toast.success('Team updated successfully!');
      return response;
    } catch (error) {
      toast.error('Failed to update team.');
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const deletePlayers = async (teamId) => {
    setLoading(true);

    try {
      const response = await API.graphql(graphqlOperation(mutations.deleteComptetiveUser, { input: { id: teamId } }), {
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      toast.success('Team deleted successfully!');
      return response;
    } catch (error) {
      toast.error('Failed to delete team.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getPlayerById = async (teamId) => {
    setLoading(true);

    try {
      const response = await API.graphql(graphqlOperation(queries.getComptetiveUser, { id: teamId }), {
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      return response;
    } catch (error) {
      toast.error('Failed to fetch team.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getUserMailAndDate = (users) => {
    return users.map((user) => {
      let email;
      let birthdate;
      let userId;

      // Filter out the attributes with 'email_verified' being 'true'
      const verifiedMails = user.Attributes?.filter((attribute) => {
        return attribute.Name === 'email_verified' && attribute.Value === 'true';
      });

      if (verifiedMails.length > 0) {
        user.Attributes.forEach((attribute) => {
          if (attribute.Name === 'email') {
            email = attribute.Value;
          }
          if (attribute.Name === 'birthdate') {
            birthdate = attribute.Value;
          }
          userId = user.Username;
        });
      }

      return { email, birthdate, userId };
    });
  };

  const findUserByMail = async () => {
    setLoading(true);

    try {
      const apiName = 'adminApiForSfbayarea';
      const path = '/admin/listUsers';
      const myInit = {
        queryStringParameters: {
          users: 'Users',
          limit: 60,
        },
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      const response = await API.get(apiName, path, myInit);

      const data = getUserMailAndDate(response.Users);
      console.log('data', data);
      return data;
    } catch (error) {
      toast.error('Failed to fetch team.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getAllPlayers = async ({ limit = 10, nextToken = null, filter = '' }) => {
    setLoading(true);
    try {
      const variables = {
        limit,
        nextToken,
        filter: filter
          ? {
              or: [
                { participantName: { contains: filter } },
                { email: { contains: filter } },
                { userId: { contains: filter } },
              ],
            }
          : null,
      };

      const response = await API.graphql(graphqlOperation(queries.listComptetiveUsers, variables), {
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const itesmWithImage = await Promise.all(
        response.data.listComptetiveUsers.items?.map(async (player) => {
          const profileUrl = await getLink(player.profile);
          const documentUrl = await getLink(player.document);

          return {
            ...player,
            profileUrl,
            documentUrl,
          };
        })
      );

      return {
        items: itesmWithImage,
        nextToken: response.data.listComptetiveUsers.nextToken,
      };
    } catch (error) {
      toast.error('Failed to fetch teams.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getAllTryouts = async ({ limit = 10, nextToken = null } = {}) => {
    setLoading(true);
    try {
      const variables = {
        limit,
        nextToken,
        // Remove the filter logic to fetch all data
      };

      const response = await API.graphql(graphqlOperation(queries.listTryouts, variables), {
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      // Check if response and listTryouts are defined before accessing items and nextToken
      if (response && response.data && response.data.listTryouts) {
        const tryouts = response.data.listTryouts.items || [];
        // Fetch comptetiveUsers data
        const comptetiveUsersResponse = await API.graphql(graphqlOperation(queries.listComptetiveUsers), {
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        const comptetiveUsers = comptetiveUsersResponse?.data?.listComptetiveUsers?.items || [];

        // Map through tryouts and append transactionStatus if userId matches
        const tryoutsWithTransactionStatus = tryouts.map((tryout) => {
          const comptetiveUser = comptetiveUsers.find((user) => user.userId === tryout.userId);
          return {
            ...tryout,
            transactionStatus: comptetiveUser?.transactionStatus || null, // Append status or default to null
          };
        });
        console.log('response.data.listTryouts.items', tryoutsWithTransactionStatus);
        return {
          items: tryoutsWithTransactionStatus || [],
          nextToken: response.data.listTryouts.nextToken || null,
        };
      } else {
        // If data is not available, return default empty values
        return {
          items: [],
          nextToken: null,
        };
      }
    } catch (error) {
      toast.error('Failed to fetch tryouts.');
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const getNotInvitedTryouts = async ({ limit = 10, nextToken = null } = {}) => {
    setLoading(true);
    try {
      const variables = {
        limit,
        nextToken,
      };

      // Fetch tryouts data
      const response = await API.graphql(graphqlOperation(queries.listTryouts, variables), {
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      if (response && response.data && response.data.listTryouts) {
        const tryouts = response.data.listTryouts.items || [];

        // Fetch competitiveUsers data
        const comptetiveUsersResponse = await API.graphql(graphqlOperation(queries.listComptetiveUsers), {
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        const comptetiveUsers = comptetiveUsersResponse?.data?.listComptetiveUsers?.items || [];

        // Filter competitive users where transactionStatus is not 'success'
        const notInvitedUsers = comptetiveUsers.filter((user) => user.transactionStatus?.toLowerCase() !== 'success');

        // Map through tryouts and keep only those whose userId matches a not-invited user
        const notInvitedTryouts = tryouts.filter((tryout) =>
          notInvitedUsers.some((user) => user.userId === tryout.userId)
        );

        console.log('Not Invited Tryouts:', notInvitedTryouts);

        return {
          items: notInvitedTryouts || [],
          nextToken: response.data.listTryouts.nextToken || null,
        };
      } else {
        // If data is not available, return default empty values
        return {
          items: [],
          nextToken: null,
        };
      }
    } catch (error) {
      toast.error('Failed to fetch not invited tryouts.');
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const getAllInvitedPlayers = async ({ limit = 10, nextToken = null, filter = '' } = {}) => {
    setLoading(true);
    try {
      const tryoutsVariables = {
        limit,
        nextToken,
      };
  
      // Fetch tryouts data
      const tryoutsResponse = await API.graphql(graphqlOperation(queries.listTryouts, tryoutsVariables), {
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
  
      if (tryoutsResponse && tryoutsResponse.data && tryoutsResponse.data.listTryouts) {
        const tryouts = tryoutsResponse.data.listTryouts.items || [];
  
        // Fetch competitive users data
        const comptetiveUsersResponse = await API.graphql(graphqlOperation(queries.listComptetiveUsers), {
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
  
        const comptetiveUsers = comptetiveUsersResponse?.data?.listComptetiveUsers?.items || [];
  
        // Filter competitive users where transactionStatus is 'success'
        const invitedUsers = comptetiveUsers.filter((user) => user.transactionStatus?.toLowerCase() === 'success');
  
        // Map through tryouts and keep only those whose userId matches an invited user
        const invitedTryouts = invitedUsers.filter((tryout) =>
          tryouts.some((user) => user.userId === tryout.userId)
        );
  
        // Enhance data with image and document URLs (similar to getAllPlayers)
        const itemsWithImage = await Promise.all(
          invitedTryouts.map(async (tryout) => {
            const profileUrl = await getLink(tryout.profile);
            const documentUrl = await getLink(tryout.document);
  
            return {
              ...tryout,
              profileUrl,
              documentUrl,
            };
          })
        );
  
        return {
          items: itemsWithImage || [],
          nextToken: tryoutsResponse.data.listTryouts.nextToken || null,
        };
      } else {
        // If no tryouts data is available, return default empty values
        return {
          items: [],
          nextToken: null,
        };
      }
    } catch (error) {
      toast.error('Failed to fetch invited players.');
      throw error;
    } finally {
      setLoading(false);
    }
  };
  
  const listComptetiveUsers = /* GraphQL */ `
    query ListComptetiveUsers($filter: ModelComptetiveUserFilterInput, $limit: Int, $nextToken: String) {
      listComptetiveUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          userId
          participantName
          email
          profile
          transactionStatus
          isVerified
          document
          createdAt
          updatedAt
        }
        nextToken
        total
      }
    }
  `;

  return {
    createPlayer,
    updatePlayers,
    deletePlayers,
    getPlayerById,
    getAllPlayers,
    findUserByMail,
    invitePlayer,
    getAllTryouts,
    getNotInvitedTryouts,
    getAllInvitedPlayers,
    loading,
  };
};

export { ComptetiveUserSchema, useHandleComptetivePlayers };
