/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import z from 'zod';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'; // Make sure to import your GraphQL mutations

const ComptetiveCoachSchema = z.object({
    name: z.string(),
    phoneNumber: z.string(),
    email: z.string(),
    address: z.string()
});

const useHandleComptetiveCoach = () => {
    const [comptetiveCoach, setComptetiveCoach] = useState([]);

    const [loading, setLoading] = useState(false);

    const createCoach = async (coachData) => {
        setLoading(true);
    
        try {
            const parsedData = ComptetiveCoachSchema.parse(coachData);  // Validate coach data with Zod schema
    
            const response = await API.graphql(
                graphqlOperation(mutations.createComptetiveCoach, { input: parsedData }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
    
            if (response.data.createComptetiveCoach) {
                toast.success("Coach created successfully!");
    
                return response.data.createComptetiveCoach;  // Return the constructed coach data
            } else {
                toast.error("Failed to create coach due to missing data.");
                return null;
            }
        } catch (error) {
            toast.error("Failed to create coach.");
            throw error;  // Rethrow error for the calling function to handle
        } finally {
            setLoading(false);
        }
    };
    

    const updateCoach = async (coachId, coachData) => {
        setLoading(true);

        const parsedData = ComptetiveCoachSchema.parse(coachData);
        try {
            const response = await API.graphql(
                graphqlOperation(mutations.updateComptetiveCoach, { input: { id: coachId, ...parsedData } }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            toast.success("Coach updated successfully!");
            return response;
        } catch (error) {
            toast.error("Failed to update coach.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    const deleteCoach = async (coachId) => {
        setLoading(true);

        const data=await getCoachById(coachId);

        console.log("data",data)

        try {
            const response = await API.graphql(
                graphqlOperation(mutations.deleteComptetiveCoach, { input: { id: coachId } }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            toast.success("Coach deleted successfully!");
            return response;
        } catch (error) {
            toast.error("Failed to delete coach.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    const getCoachById = async (coachId) => {
        setLoading(true);

        try {
            const response = await API.graphql(
                graphqlOperation(queries.getComptetiveCoach, { id: coachId }),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );
            return response.data.getComptetiveCoach;
        } catch (error) {
            toast.error("Failed to fetch coach.");
            throw error;
        } finally {
            setLoading(false)
        }
    };


    const getAllCoachs = async () => {
        setLoading(true);

        try {
            const response = await API.graphql(
                graphqlOperation(queries.listComptetiveCoaches),
                { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
            );

            setComptetiveCoach(response.data.listComptetiveCoaches.items);
            return response.data.listComptetiveCoaches.items|| [];
        } catch (error) {
            toast.error("Failed to fetch coachs.");
            throw error;
        } finally {
            setLoading(false)
        }
    };

    return {
        createCoach,
        updateCoach,
        deleteCoach,
        getCoachById,
        getAllCoachs,
        loading,
        comptetiveCoach
        
    };
};

export { ComptetiveCoachSchema, useHandleComptetiveCoach };
