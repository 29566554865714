import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import {
  Table,
  TableBody,
  Typography,
  FormControl,
  Grid,
  Container,
  TableCell,
  TableContainer,
  Stack,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';

import { format } from 'date-fns';
import useGetSubscribedUsers from '../hooks/getSubscribedUsersList.';
import Loader from '../components/Loader';

const NewsletterEmailListPage = () => {
  const { subscribedUsers,notExportedUsers, loading } = useGetSubscribedUsers();
  const [emailList, setEmailList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [exportOption, setExportOption] = useState('complete');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dateError, setDateError] = useState('');
  function formatDate(dateString) {
    const date = new Date(dateString);
    // Use the format function to customize the date format
    return format(date, 'dd-MM-yyyy');
  }
  
  useEffect(() => {
    setEmailList(subscribedUsers);
  }, [subscribedUsers]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExportOptionChange = (event) => {
    setExportOption(event.target.value);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
    setDateError('');
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
    setDateError('');
  };

  const exportEmails = () => {
    if (exportOption === 'complete') {
      const csvContent = `Email,createdAt\n${emailList.map((email) => `${email?.email},${formatDate(email?.createdAt)}`).join('\n')}`;
      downloadCSV('newsletter_emails.csv', csvContent);
    } else if (exportOption === 'date') {
      if (!fromDate || !toDate) {
        setDateError('Please select both "From Date" and "To Date".');
      } else if (new Date(fromDate) > new Date(toDate)) {
        setDateError('"From Date" cannot be greater than "To Date".');
      } else {
        const filteredEmails = emailList.filter((email) => {
          const createdAt = new Date(email.createdAt);
          return createdAt >= new Date(fromDate) && createdAt <= new Date(toDate);
        });

        const csvContent = `Email,createdAt\n${filteredEmails.map((email) => `${email.email},${formatDate(email?.createdAt)}`).join('\n')}`;
        downloadCSV('newsletter_emails.csv', csvContent);
      }
    }
  };

  const downloadCSV = (fileName, content) => {
    const blob = new Blob([content], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const buttonStyles = {
    backgroundColor: '#1E90FF',
    color: 'white',
  };
  if (loading) {
    return <Loader />;
  }

  //

  return (
    <Container>
      <ToastContainer />
      <Typography variant="h4" gutterBottom>
        Newsletter Email List
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Select value={exportOption} onChange={handleExportOptionChange} fullWidth>
              <MenuItem value="complete">Complete</MenuItem>
              <MenuItem value="date">Export with Date</MenuItem>
            </Select>
          </Grid>
          {exportOption === 'date' && (
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    type="date"
                    label="From Date"
                    value={fromDate}
                    name="date"
                    onChange={handleFromDateChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <TextField
                    type="date"
                    label="To Date"
                    value={toDate}
                    name="date"
                    onChange={handleToDateChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                {dateError && (
                  <Typography variant="caption" color="error">
                    {dateError}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <Button
              disableRipple
              color="inherit"
              variant="contained"
              style={buttonStyles}
              onClick={exportEmails}
              fullWidth
            >
              Export
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emailList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
              <TableRow key={index}>
                <TableCell>{data.email}</TableCell>
                <TableCell>{formatDate(data?.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={emailList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Container>
  );
};

export default NewsletterEmailListPage;
