import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSetUser } from '../store';

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setUser = useSetUser();

  const handleClick = async () => {
    const email = document.getElementById('email').value;
    const pass = document.getElementById('password').value;

    setLoading(true);

    try {
      const user = await Auth.signIn(email, pass);

      // Check if the logged-in user belongs to the 'Admin' group
      const userGroups = user?.signInUserSession?.idToken?.payload['cognito:groups'];
      if (userGroups && userGroups.includes('Admin')) {
        const _user = user.attributes;
        setUser(_user);
        console.log('Login successful - ', user);
        toast.success('Logged in successfully!');

        // Save user details securely for 24 hours using localStorage
        const userDetails = JSON.stringify({
          email: user.attributes.email,
          groups: userGroups,
          // Add other necessary user details
        });
        localStorage.setItem('userDetails', userDetails);

        // Set an expiration time for 24 hours (in milliseconds)
        const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
        const expirationTimestamp = new Date().getTime() + expirationTime;
        localStorage.setItem('expirationTimestamp', expirationTimestamp);

        setLoading(false);
        navigate('/dashboard', { replace: true });
      } else {
        setLoading(false);
        toast.error('Access denied. You are not an admin.');
      }
    } catch (error) {
      setLoading(false);

      if (error.code === 'UserNotFoundException' || error.code === 'NotAuthorizedException') {
        toast.error('Invalid Email or Password.');
      } else {
        toast.error('Error signing in.');
      }

      console.log('Error signing in - ', error.message);
    }
  };

  return { handleClick, loading };
};

export default useLogin;
