import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries'; // Import your GraphQL queries for listing teams

function useGetTeamById() {
  const [team, setTeam] = useState([]);
  const [teams, setTeams] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function fetchTeam(id) {
    try {
      setLoading(true);

      // Make the GraphQL API call to fetch the list of teams
      const response = await API.graphql({
        query: queries.getTeam,
        input: { id }, // Replace with the appropriate GraphQL query for listing teams
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const teamData = response?.data?.getTeam;

      setTeam(teamData);

      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function fetchMultipleTeam(ids) {
    try {
      setLoading(true);
      const teamsData = await Promise.all(ids.map(async (id) => fetchTeam(id)));
      setTeams(teamsData);
      console.log('Teams data:', teamsData);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  return { fetchTeam, fetchMultipleTeam, teams, team, loading, error };
}

export default useGetTeamById;
