import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import PlayerScore from './PlayerScore';
import Loader from '../Loader';

const Teams = {
  stats: [
    'GP',
    'MIN',
    'PTS',
    'FGM',
    'FGA',
    'FGPERCENT',
    'THREEPM',
    'TDTHREE',
    'THREEPA',
    'THREEPPERCENT',
    'FTM',
    'FTA',
    'FTPERCENT',
    'OREB',
    'DREB',
    'REB',
    'AST',
    'TOV',
    'STL',
    'BLK',
    'PF',
    'FP',
    'DDTWO',
    '+/-',
  ],
};

const ScoreComponent = React.memo(({ players, disabled, onChange }) => {
  const [teamStats, setTeamStats] = useState([]);

  useEffect(() => {
    setTeamStats(players);
    console.log("ScoreComponent")
  }, [players]);

  const handleScoreChange = (index, field, value) => {
    const updatedTeamStats = [...teamStats];
    updatedTeamStats[index][field] = value;
    setTeamStats(updatedTeamStats);
    onChange(index, field, value);
  };

  return (
    <TableContainer component={Paper}>
      {teamStats ? (
        <div className='grid overflow-x-scroll'>

          <div className='w-full  py-3 text-sm bg-gray-100 flex items-center text-start'>
            <div className='w-[122px] pl-4 p-2  text-start' >Player</div>
            {Teams.stats.map((stat, index) => (
              <div className={` pl-4 p-2 text-start ${stat === 'THREEPPERCENT' ? 'w-[150px]' : 'w-[122px]'}`} key={index}>{stat}</div>
            ))}
          </div>


          <Box>
            {teamStats &&
              teamStats.map((player, index) => (
                <PlayerScore
                  key={index}
                  player={player}
                  disabled={disabled}
                  onChange={(field, value) => handleScoreChange(index, field, value)}
                />
              ))}
          </Box>
        </div>
      ) : (
        <Loader />
      )}
    </TableContainer>
  );
});

export default ScoreComponent;
