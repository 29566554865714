import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
import {
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAIL,
  UPDATE_SESSION_REQUEST,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAIL,
  DELETE_SESSION_REQUEST,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAIL,
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAIL,
  CLEAR_SESSION_ERRORS,
  ALL_SESSION_REQUEST,
  ALL_SESSION_SUCCESS,
  ALL_SESSION_FAIL,
} from '../constants/SessionsConstants';

export const getAllSessions = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_SESSION_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listSeasonCharges));

    const data = response.data.listSeasonCharges.items || [];
    const updatedSessions = await Promise.all(
      data?.map(async (session) => {
        try {
          return { ...session };
        } catch (error) {
          toast.error('Something Went Wrong!');

          console.error('Error fetching Data', error);
          return session;
        }
      })
    );

    const updatedData = {
      sessions: updatedSessions,
      sessionsCount: updatedSessions?.length,
      resultPerPage: 10,
      filteredSessionsCount: null,
    };

    dispatch({
      type: ALL_SESSION_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_SESSION_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getSessionDetails = (sessionId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SESSION_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getSeasonCharges, { id: sessionId }));

    const data = response?.data.getSeasonCharges;
    const newData = { ...data };
    dispatch({
      type: GET_SESSION_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_SESSION_FAIL,
      payload: errorMessage,
    });
  }
};

export const createSession = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_SESSION_REQUEST });
    const response = await API.graphql({
      query: mutations.createSeasonCharges,
      variables: {
        input: {
          ...formData,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createSeasonCharges;
    toast.success('Session Added Successfully!');
    dispatch({
      type: CREATE_SESSION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');
    console.log('SESSION error', error);
    dispatch({
      type: CREATE_SESSION_FAIL,
      payload: error,
    });
  }
};
export const deleteSession = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SESSION_REQUEST });

    const res = await API.graphql(graphqlOperation(queries.getSeasonCharges, { id }), {
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    API.graphql({
      query: mutations.deleteSeasonCharges,
      variables: { input: { id } },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then(async (response) => {
      console.log(response);
      dispatch({
        type: DELETE_SESSION_SUCCESS,
        payload: true,
      });
    });
    toast.success('Session Deleted Successfully!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_SESSION_FAIL,
      payload: error || 'Failed to fetch SESSION data',
    });
  }
};
export const updateSession = (sessionId, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SESSION_REQUEST });

    const response = await API.graphql({
      query: mutations.updateSeasonCharges,
      variables: { input: { ...formData, id: sessionId } },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const updatedDetails = response?.data?.updateSeasonCharges;
    toast.success('Session Updated Successfully!');

    dispatch({
      type: UPDATE_SESSION_SUCCESS,
      payload: updatedDetails,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.error('Error updating SESSION:', error);

    dispatch({
      type: UPDATE_SESSION_FAIL,
      payload: error || 'Failed to update SESSION',
    });
  }
};
export const clearSessionsErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_SESSION_ERRORS });
};
