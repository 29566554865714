import {
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAIL,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
  GET_COURSE_FAIL,
  CLEAR_COURSE_ERRORS,
  ALL_COURSE_REQUEST,
  ALL_COURSE_SUCCESS,
  ALL_COURSE_FAIL,
} from '../constants/CourseConstants';

const initialState = {
  courses: [],
  courseCount: 0,
  resultPerPage: 0,
  course: {},
  isUpdated:false,
  loading: false,
  error: null,
  filteredCoursesCount: 0,
};

export const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_COURSE_REQUEST:
    case GET_COURSE_REQUEST:
    case CREATE_COURSE_REQUEST:
    case DELETE_COURSE_REQUEST:
    case UPDATE_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
        courses: [],
        course: {},
      };
    case ALL_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload.courses,
        courseCount: action.payload.coursesCount,
        resultPerPage: action.payload.resultPerPage,
        filteredCoursesCount: action.payload.filteredCoursesCount,
      };
    case CREATE_COURSE_SUCCESS:
    case GET_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        course: action.payload,
      };
    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case GET_COURSE_FAIL:
    case ALL_COURSE_FAIL:
    case CREATE_COURSE_FAIL:
    case UPDATE_COURSE_FAIL:
    case DELETE_COURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_COURSE_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



