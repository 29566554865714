/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { getKey } from '../utils/getKey';
import { allImage, getNonMatchingValues, ReplaceUrl, updateImageSrcInHtml } from '../utils/replaceUrl';
import uploadFile from '../utils/uploadFile';
import deleteFile from '../utils/deleteFile';
import getLink from '../utils/getLinks';

const useHandlePagintationClinicCards = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [clinicCardsList, setClinicCardsList] = useState([]);
  const [clinicCard, setClinicCard] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchClinicCards = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        // }

        const result = await API.graphql({
          query: queries.listClinicCards,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });

        const fetchedClinicCards = result?.data?.listClinicCards?.items || [];

        const updateClinicCards = await Promise.all(
          fetchedClinicCards?.map(async (item) => {
            const description = await updateImageSrcInHtml(item?.description, getLink, getKey);
            item.description = description;

            return item;
          })
        );

        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listClinicCards?.nextToken);
        }

        setClinicCardsList(updateClinicCards);
        setNextNextToken(result?.data?.listClinicCards?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching ClinicCards:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteClinicCards = async (id) => {
    try {
      setLoading(true);
      const oldResponse = await API.graphql(graphqlOperation(queries.getClinicCard, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const oldData = oldResponse.data.getClinicCard;

      const allOldImageKey = await allImage(oldData.description, getKey);

      allOldImageKey?.map(async (key, index) => {
        await deleteFile(key);
      });

      const response = await API.graphql({
        query: mutations.deleteClinicCard,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      console.log(response);
      toast.success('Clinic Card Deleted Successfully!');
      setLoading(false);

      // Remove deleted ClinicCards from the ClinicCardsList
      setClinicCardsList((prevClinicCardsList) => prevClinicCardsList.filter((item) => item.id !== id));
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting ClinicCards:', error);
    }
  };
  const getClinicCardById = async (id) => {
    setLoading(true);
    try {
      const response = await API.graphql(graphqlOperation(queries.getClinicCard, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const clinic = response?.data?.getClinicCard;

      const description = await updateImageSrcInHtml(clinic?.description, getLink, getKey);
      clinic.description = description;

      setClinicCard(clinic);

      setLoading(false);
      return clinic;
    } catch (error) {
      setLoading(false);
      console.error('Error fetching ClinicCard by ID:', error);
      throw error;
    }
  };
  const updateClinicCards = async (formData) => {
    try {
      setLoading(true);
      const data = {
        title: formData?.title,
        price: formData?.price,
        order: formData?.order,
        description: formData?.description,
      };

      const oldResponse = await API.graphql(graphqlOperation(queries.getClinicCard, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const oldData = oldResponse.data.getClinicCard;

      console.log('oldData', oldData);

      const allOldImageKey = await allImage(oldData.description, getKey);
      const newImageKey = await allImage(data.description, getKey);

      const { notInNew: deletedImageKey } = getNonMatchingValues(allOldImageKey, newImageKey);

      deletedImageKey?.map(async (key, index) => {
        await deleteFile(key);
      });

      const response = await API.graphql({
        query: mutations.updateClinicCard,
        variables: { input: { ...data, id: formData.id } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedDetails = {
        ...response?.data?.updateClinicCard,
      };
      setLoading(false);

      setClinicCardsList((prevClinicCardsList) => {
        const updatedClinicCardList = prevClinicCardsList?.map((item) => {
          if (item.id === formData.id) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedClinicCardList;
      });

      toast.success('Clinic Card Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating ClinicCards details:', error);
      toast.error('Error updating ClinicCards details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const addClinicCard = async (items) => {
    setLoading(true);

    try {
      setLoading(true);

      // Upload the image to S3 using Storage.put
      const updatedData = { ...items };
      const response = await create(updatedData);
      const clinicCardsData = {
        ...response?.data?.createClinicCard,
      };
      setLoading(false);
      setClinicCardsList((prevClinicCardsList) => [clinicCardsData, ...prevClinicCardsList]); // Add the new ClinicCards data to the events list

      setLoading(false);
      toast.success(`Clinic Card Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating events:', error);
      throw error;
    }
  };
  const create = async (updatedData) => {
    setLoading(true);

    const response = await API.graphql({
      query: mutations.createClinicCard,
      variables: {
        input: {
          ...updatedData,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    return response;
  };
  useEffect(() => {
    fetchClinicCards('');
    if (id) {
      getClinicCardById(id);
    }
  }, [nextToken, id]);

  return {
    clinicCardsList,
    nextNextToken,
    loading,
    deleteClinicCards,
    fetchNextPage,
    fetchClinicCards,
    fetchPreviousPage,
    previousTokens,
    addClinicCard,
    updateClinicCards,
    clinicCard,
    getClinicCardById,
  };
};

export default useHandlePagintationClinicCards;
