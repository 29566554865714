/* eslint-disable consistent-return */
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

const uploadFile = async (file) => {
  try {
    if (!file) {
      return;
    }

    const uniqueId = uuidv4();
    const uniqueFileName = `${uniqueId}_${file.name}`;

    console.log(`Uploading`, uniqueFileName);

    // Create a new Blob from the file content
    const blob = new Blob([file]);

    // Read blob content as ArrayBuffer
    const arrayBuffer = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.readAsArrayBuffer(blob);
    });

    const options = {
      level: 'public',
      contentType: file.type,
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
    };

    const { key } = await Storage.put(uniqueFileName, arrayBuffer, options);

    console.log('File uploaded successfully. Key:', key);

    return key;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export default uploadFile;
