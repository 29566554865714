import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { toast } from 'react-toastify';
import * as queries from "../../graphql/queries"; // Import your GraphQL queries
import * as mutations from "../../graphql/mutations"; // Import your GraphQL queries
import {
  CREATE_FCOURSE_REQUEST,
  CREATE_CAMPCOURSE_SUCCESS,
  CREATE_CAMPCOURSE_FAIL,
  UPDATE_CAMPCOURSE_REQUEST,
  UPDATE_CAMPCOURSE_SUCCESS,
  UPDATE_CAMPCOURSE_FAIL,
  DELETE_CAMPCOURSE_REQUEST,
  DELETE_CAMPCOURSE_SUCCESS,
  DELETE_CAMPCOURSE_FAIL,
  GET_CAMPCOURSE_REQUEST,
  GET_CAMPCOURSE_SUCCESS,
  GET_CAMPCOURSE_FAIL,
  CLEAR_CAMPCOURSE_ERRORS,
  ALL_CAMPCOURSE_REQUEST,
  ALL_CAMPCOURSE_SUCCESS,
  ALL_CAMPCOURSE_FAIL,
} from '../constants/CampCourseConstants';

export const getAllCampCourses = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_CAMPCOURSE_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listCampCourses));

    const updatedCourses = response.data.listCampCourses.items || [];

    const updatedData = {
      campCourses: updatedCourses,
      campCoursesCount: updatedCourses?.length,
      resultPerPage: 10,
      filteredCoursesCount: null,
    };

    dispatch({
      type: ALL_CAMPCOURSE_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_CAMPCOURSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearCampCoursesErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CAMPCOURSE_ERRORS });
};