import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';

const useEventsList = () => {
  const [eventsList, setEventsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const fetchEventsLists = async () => {
    try {
      setLoading(true);
      setError(null);
      setSuccess(false);

      const response = await API.graphql({
        query: queries.listTournamentLeagues,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const newsList = response?.data?.listTournamentLeagues?.items;
      setEventsList(newsList);
      setSuccess(true);
    } catch (error) {
      console.error('Error fetching leagues:', error);
      setError(error);
      toast.error('something went wrong');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventsLists();
  }, []);

  return { eventsList, loading, error, success, fetchEventsLists };
};

export default useEventsList;
