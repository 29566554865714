import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import MeetList from '../sections/@dashboard/schedulemeet/MeetList';

const ScheduleMeet = () => {
  const { pathname } = useLocation();
  const newMeetLink = pathname.includes('clinic')
    ? '/dashboard/meet/newClinicMeet'
    : '/dashboard/meet/new-meet';

  return (
    <div className="flex px-5 flex-col gap-4 relative">
      <Link
        to={newMeetLink}
        className="absolute top-0 right-5 border border-blue-600 px-3 w-fit py-1 text-sm hover:bg-blue-100 rounded-md"
      >
        New Event Type
      </Link>
      <div className="w-full py-20">
        <MeetList />
      </div>
    </div>
  );
};

export default ScheduleMeet;
