import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import './editStyle.css';

import {
  Button,
  Container,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Divider,
  Grid,
} from '@mui/material';

import useNewsDetails from '../hooks/getNewsDetails';
import useUpdateNewsDetails from '../hooks/updateNews';

const EditPost = () => {
  const { id } = useParams();

  const [editable, setEditable] = useState(false);
  const navigate = useNavigate()
  // Use state variables to store the post data
  const [title, setTitle] = useState('');
  const [contentText, setContentText] = useState('');
  const [brief, setBrief] = useState('');
  const [contentImageUrl, setContentImageUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const { getNewsDetails, loading } = useNewsDetails();
  const { updateNewsDetails, isUpdating } = useUpdateNewsDetails();
  // Populate the state variables with the fetched data when available
  useEffect(() => {
    if (getNewsDetails) {
      setTitle(getNewsDetails.title);
      setContentText(getNewsDetails.content_text);
      setBrief(getNewsDetails.brief);
      setContentImageUrl(getNewsDetails.content_img_url);
      setImageUrl(getNewsDetails.img_url);
    }
  }, [getNewsDetails]);

  const handleUpdate = async () => {
    try {
      // Call your updateNewsDetails function with the updated data
      const updatedDetails = await updateNewsDetails({
        title,
        content_text: contentText,
        brief,


        content_img_url: contentImageUrl,
        img_url: imageUrl,
      });
      updateNewsDetails(updatedDetails);
      // Handle success or error as needed
      // You may also want to reset isEditable to false here
      setEditable(false);
    } catch (error) {
      console.error('Error updating news NewsDetails:', error);
    }
  };

  return (
    <div className="edit flex flex-col w-full gap-2 p-15px">
      <button onClick={() => setEditable(!editable)}>{editable ? 'Cancel' : 'Edit'}</button>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <span>Title</span>
          <input placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} disabled={!editable} />
        </div>
        <div className="flex flex-col gap-1">
          <span>Text Content</span>
          <textarea
            placeholder="Body"
            value={contentText}
            className="min-h-150px w-full"
            onChange={(e) => setContentText(e.target.value)}
            disabled={!editable}
          />
        </div>
      </div>
      <div className="body">
        <span>Brief</span>

        <textarea
          placeholder="Body"
          className="min-h-150px w-full"
          value={brief}
          onChange={(e) => setBrief(e.target.value)}
          disabled={!editable}
        />
      </div>
      <div className="flex flex-col">
        <span>Image Url</span>
        <img className="bodyImageUrl" alt="bodyimage" src={contentImageUrl} />
      </div>
      <div className="flex flex-col">
        <span>Content Image Url</span>

        <img className="bodyImageUrl" alt="imageUrl" src={imageUrl} />
      </div>
      <div className="edit-button flex flex-col gap-2">
        <button onClick={handleUpdate} disabled={!editable}>
          Update
        </button>
      </div>
    </div>
  );
};

export default EditPost;
