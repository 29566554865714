/* eslint-disable react/no-danger */
import React from 'react';
import './quill-custom-styles.css'; // Import the custom styles

const ShowPreview = ({ title, content }) => (
    <div className="w-full ql-editor bg-white min-h-full p-2">
      <h1 className="text-2xl font-bold mb-2">{title}</h1>

      <div  dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );

export default ShowPreview;
