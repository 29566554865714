import React, { useState } from 'react';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ShowConfirmationDialog';
import { fDate } from '../../../utils/formatTime';
import useCreateMeet from '../../../hooks/createMeet';

const MeetCard = ({ event, deleteMeetById }) => {
  const [selected, setSelected] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const deleteCard = () => {
    setShowConfirmDialog(true);
  };

  const confirmDelete = async () => {
    await deleteMeetById(event.id);
    setShowConfirmDialog(false);
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  return (
    <>
      <div
        className={`flex  ${selected ? 'border' : 'border-t-0'
          } md:w-[250px] border-blue-700 transition-all ease-in-out duration-500 cursor-pointer w-full max-w-[250px]  rounded-md p-5 flex-col gap-5`}
      >
        <div className="flex justify-between items-center">
          <h1 className="text-1xl">{event.name}</h1>
          <div className="flex gap-">
            <IconButton
              onClick={deleteCard}
              className="text-red hover:opacity-60 transition-all ease-in-out duration-500"
              aria-label="delete"
            >
              <Delete />
            </IconButton>
            <Link to={`/dashboard/meet/newClinicMeet/${event.id}`} >


              <IconButton
                className="text-blue hover:opacity-60 transition-all ease-in-out duration-500"
                aria-label="edit"
              >
                <Edit />
              </IconButton>
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-1 justify-start items-start">
          <p className="flex text-gray-500 font-light items-center gap-2">
            <span>{fDate(event.selectedDate)}</span>
            <span>{event.maxStudents} students</span>
          </p>
        </div>
      </div>
      {showConfirmDialog && (
        <ConfirmationDialog
          message="Are you sure you want to delete this card?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </>
  );
};

export default MeetCard;
