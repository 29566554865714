import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import useCityList from '../../../hooks/getCityList';
import {  getAllSessions } from "../../../redux/actions/SessionAction";

AddSessionSideBar.propTypes = {
  openSession: PropTypes.bool,
  onOpenSession: PropTypes.func,
  onCloseSession: PropTypes.func,
};

export default function AddSessionSideBar({
  updateSession,
  hidden,
  addSession, openSession, onOpenSession, onCloseSession, editData }) {
  const dispatch = useDispatch();
  const { cities } = useCityList();

  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [agegroup, setAgeGroup] = useState('');
  const [location, setLocation] = useState('');

  const [startingDate, setStartingDate] = useState('');
  const [endingDate, setEndingDate] = useState('');
  const [lastRegistrationDate, setLastRegistrationDate] = useState('');
  const [price, setPrice] = useState('');
  const [id, setId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isEditMode = !!editData; // Check if editData is present
 
  const ages = [
    { value: "u7", label: "U7" },
    { value: "u8", label: "U8" },
    { value: "u9", label: "U9" },
    { value: "u10", label: "U10" },
    { value: "u11", label: "U11" },
    { value: "u12", label: "U12" },
    { value: "u13", label: "U13" },
    { value: "u14", label: "U14" },
    { value: "u15", label: "U15" },
    { value: "u17", label: "U17" },
    { value: "u18", label: "U18" },
    { value: "u19-u39", label: "U19-U39" },
    { value: "over u40", label: "Over U40" }
  ];
 
  useEffect(() => {
    // If editData is present, set the form fields with its values
    if (isEditMode && editData) {
      const formattedStartingDate = new Date(editData?.startingDate).toISOString().slice(0, 10);
      const formattedEndingDate = new Date(editData?.endingDate).toISOString().slice(0, 10);
      const formattedLastRegistrationDate = new Date(editData?.lastRegistrationDate).toISOString().slice(0, 10);
      console.log('edit data ', editData);
      setTitle(editData?.title || '');
      setType(editData?.type || '');
      setDescription(editData?.description || '');
      setPrice(editData?.price || '');
      setLocation(editData.location || '');
      setId(editData?.id || '');
      setAgeGroup(editData?.agegroup || '');
      setStartingDate(formattedStartingDate || '');
      setEndingDate(formattedEndingDate || '');
      setLastRegistrationDate(formattedLastRegistrationDate || '');
    }
  }, [editData, isEditMode]);
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };


  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleStartingDateChange = (event) => {
    setStartingDate(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleEndingDateChange = (event) => {
    setEndingDate(event.target.value);
  };
  const handleLastRegistrationDateChange = (event) => {
    setLastRegistrationDate(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const resetForm = () => {
    setTitle('');
    setType('');
    setDescription('');
    setAgeGroup('');
    setErrorMessage('');
    setStartingDate('');
    setEndingDate('');
    setLastRegistrationDate('');
    setLocation('');
    setPrice('');
  };

  const handleCancel = () => {
    resetForm(); // Call the resetForm function to clear all fields
    onCloseSession(); // Close the modal
  };


  const handleAgeGroupChange = (event) => {
    setAgeGroup(event.target.value);
  };

  const handleUploadData = async () => {
    if (!title) {
      toast.error('Title field is required.');
      return;
    }
    if (!type) {
      toast.error('Type field is required.');
      return;
    }
    if (!description) {
      toast.error('Description field is required.');
      return;
    }
    if (!agegroup) {
      toast.error('Age Group field is required.');
      return;
    }
    if (!price) {
      toast.error('Price field is required.');
      return;
    }
    if (!location) {
      toast.error('Location field is required.');
      return;
    }
    if (!startingDate) {
      toast.error('Starting Date field is required.');
      return;
    }
    if (!endingDate) {
      toast.error('Ending Date field is required.');
      return;
    }
    if (!lastRegistrationDate) {
      toast.error('Last Registration Date field is required.');
      return;
    }

    const formattedStartingDate = new Date(startingDate);
    const formattedEndingDate = new Date(endingDate);
    const formattedLastRegistrationDate = new Date(lastRegistrationDate);
    const data = {
      title,
      description,
      agegroup,
      price,
      type,
      location,
      startingDate: formattedStartingDate,
      endingDate: formattedEndingDate,
      lastRegistrationDate: formattedLastRegistrationDate,
    };
console.log('date data ',data)
    if (isEditMode) {
      const id = editData?.id;
      onCloseSession();

      const updated = await updateSession(id, data);

      if (updated) {
        dispatch(getAllSessions());
      }
    } else {
   
      onCloseSession();
      const created = await addSession(data);
      if (created) {
        dispatch(getAllSessions());
      }
    }
    resetForm(); // Reset form fields
   
  };


  return (
    <>
      <Button
        disableRipple
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="gala:add" />}
        onClick={onOpenSession}
        style={buttonStyles}
        hidden={hidden}
      >
        Add Session
      </Button>
      <Dialog open={openSession} onClose={handleCancel}>
        <DialogTitle>{isEditMode ? 'Edit Session' : 'Add New Session'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the following details for the new Session.</DialogContentText>

          <FormControl fullWidth margin="dense">
            <TextField
              autoFocus
              margin="dense"
              label="Title"
              fullWidth
              className='uppercase'
              value={title}
              onChange={handleTitleChange}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              label="Type"
              name="type"
              fullWidth
              required

              variant="outlined"
              value={type}
              onChange={handleTypeChange}
            >

              <MenuItem value="PLAYER">PLAYER</MenuItem>
              <MenuItem value="TRAINING_PROGRAM">TRAINING PROGRAM</MenuItem>

            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <TextField
              autoFocus
              margin="dense"
              label="Description"
              fullWidth
              value={description}
              onChange={handleDescriptionChange}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="agegroup">Age Group</InputLabel>
            <Select
              labelId="agegroup"
              label="Age Group"
              name="agegroup"
              value={agegroup}
              fullWidth
              className="uppercase"
              variant="outlined"
              onChange={handleAgeGroupChange}
            >
              {ages?.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <TextField
              autoFocus
              margin="dense"
              label="Price"
              fullWidth
              value={price}
              onChange={handlePriceChange}
            />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel id="location">Location</InputLabel>
            <Select
              labelId="location"
              label="Location"
              name="location"
              fullWidth
              required

              variant="outlined"
              value={location}
              onChange={handleLocationChange}
            >
              {cities.map((cities) => (
                <MenuItem key={cities.id} value={cities.name}>
                  {cities.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <TextField
              type="date"
              label="Starting Date"
              value={startingDate}
              name="startingDate"
              onChange={handleStartingDateChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <TextField
              type="date"
              label="Ending Date"
              value={endingDate}
              name="endingDate"
              onChange={handleEndingDateChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <TextField
              type="date"
              label="Registration Date"
              value={lastRegistrationDate}
              name="lastRegistrationDate"
              onChange={handleLastRegistrationDateChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={handleCancel} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
