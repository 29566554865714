import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../components/iconify';
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import useDeleteProduct from '../../../hooks/deleteItem';
import Loader from '../../../components/Loader';
import AddNewProductSideBar from './AddNewProductSideBar';
import { getAllProducts } from '../../../redux/actions/ProductAction';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  padding: '10px',
  objectFit: 'contain',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ setProductList, deleteProduct, updateProductDetails, product }) {
  const {
    title,
    selling_price: price,
    original_price: originalPrice,
    id,
    tags,
    stock_qty: stock,
    img_url: imgUrl,
    rating,
  } = product;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState({
    title,
    selling_price: price,
    original_price: originalPrice,
    tags,
    stock_qty: stock,
    img_url: imgUrl,
    rating,
    id: product.id,
  }); // Track the edit data

  const handleEditClick = () => {
    setEditData(product); // Set the edit data to the current product
    setIsEditDialogOpen(true);
  };
  // const { deleteProduct, success, error, loading } = useDeleteProduct();
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  console.log(isDeleteDialogOpen);
  const handleDeleteConfirm = async () => {
    try {
    setProductList((prevProduct) => prevProduct?.filter((item) => item?.id !== id));
    setIsDeleteDialogOpen(false); // Close delete dialog
    // toast.success("Deleted")
    // Show loader
      const res = await deleteProduct(id);
    } catch (error) {
    throw new Error(error)
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  // if (success) {
  //   toast.success("Product deleted successfully", {
  //     position: "top-right",
  //     autoClose: 3000, // Close the toast after 3 seconds
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //   });
  // }

  // if (error) {
  //   toast.error("Failed to delete  Product", {
  //     position: "top-right",
  //     autoClose: 3000, // Close the toast after 3 seconds
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //   });
  // }
  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {isEditDialogOpen && ( // Conditionally render AddNewEventSideBar
          <AddNewProductSideBar
            hidden
            openProduct={isEditDialogOpen} // Pass the state that controls the Dialog visibility
            onCloseProduct={() => setIsEditDialogOpen(false)} // Pass the function to close the Dialog
            editData={editData}
            updateProductDetails={updateProductDetails}
          />
        )}
        {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )} */}
        <StyledProductImg alt={title} src={product?.render_img_url} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        </Link>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {originalPrice && fCurrency(originalPrice)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <EditButton onEditClick={handleEditClick} />
          <DeleteButton onDeleteClick={handleDeleteClick} />
        </Stack>

        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Product</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this product?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Card>
  );
}

const DeleteButton = ({ onDeleteClick }) => {
  return (
    <Button color="primary" onClick={onDeleteClick}>
      <Iconify color="red" icon="fluent:delete-12-regular" />
    </Button>
  );
};
const EditButton = ({ onEditClick }) => {
  return (
    <Button onClick={onEditClick}>
      <EditIcon color="primary" style={{ fontSize: '18px' }} />
    </Button>
  );
};
