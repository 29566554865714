import {
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL,
  CLEAR_COUPON_ERRORS,
  ALL_COUPON_REQUEST,
  ALL_COUPON_SUCCESS,
  ALL_COUPON_FAIL,
} from '../constants/CouponsConstants';

const initialState = {
  coupons: [],
  couponCount: 0,
  resultPerPage: 0,
  coupon: {},
  loading: false,
  error: null,
  filteredCouponsCount: 0,
};

export const couponsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_COUPON_REQUEST:
    case GET_COUPON_REQUEST:
    case CREATE_COUPON_REQUEST:
    case DELETE_COUPON_REQUEST:
    case UPDATE_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
        coupons: [],
        coupon: {},
      };
    case ALL_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        coupons: action.payload.coupons,
        couponCount: action.payload.couponsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredCouponsCount: action.payload.filteredCouponsCount,
      };
    case CREATE_COUPON_SUCCESS:
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action.payload,
      };
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case GET_COUPON_FAIL:
    case ALL_COUPON_FAIL:
    case CREATE_COUPON_FAIL:
    case UPDATE_COUPON_FAIL:
    case DELETE_COUPON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_COUPON_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



