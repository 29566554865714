import React, { useEffect } from 'react';
import './loader.css';

const Loader = () => {
  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div className="loader-container z-[100] min-h-screen h-full absolute top-0 left-0 flex items-center justify-center w-full  bg-white opacity-75">
      <div className="loader h-16 w-16 border-4 border-t- rounded-full animate-spin" />
    </div>
  );
};

export default Loader;
