/* eslint-disable consistent-return */
/* eslint-disable consistent-return, prefer-const, no-plusplus, no-await-in-loop, no-undef */

import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries'; // Import your GraphQL queries for listing teams
import * as mutations from '../graphql/mutations'; // Import your GraphQL queries for listing teams
import { createRounds, createWinner } from './startTournamentScoreHooks';
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getLeagueQuery = `
query GetLeague($id: ID!) {
  getLeague(id: $id) {
    id
    title
    location
    eventStatus
    img_url
    teams {
      items {
        id
        name
        agegroup
        imageURL
        players {
          items {
            id
            participantName
            profile
            teamID
          }
        }
      }
    }
  }
}
`;

const updateLeagueMutation = `
  mutation UpdateLeague($input: UpdateLeagueInput!) {
    updateLeague(input: $input) {
      id
      
    }
  }
`;

function useStartScoreNew() {
  const [teams, setTeams] = useState([]);
  const [event, setEvent] = useState();
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getTeamsByLeague = async (eventId) => {
    try {
      const responseOfListTeams = await API.graphql({
        query: queries.listTeams,
        variables: {
          filter: {
            leagueId: {
              eq: eventId,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const data = responseOfListTeams?.data.listTeams.items;
      return data;
    } catch (error) {
      throw new Error('Fething Error teams', error);
    }
  };

  async function startEvents(eventId) {
    try {
      setLoading(true);
      // Fetch the events list
      const response = await API.graphql(graphqlOperation(queries.getLeague, { id: eventId }));

      const event = response.data.getLeague;
      delete event.createdAt;
      delete event.updatedAt;
      delete event.__typename;
      console.log('ENTER TOURNAMENT', event);
      if (!event) {
        toast.error('Event Not Found');
        setLoading(false);
        return;
      }
      if (event.eventStatus === 'EVENT_STARTED') {
        toast.error('Already Started');
        setLoading(false);
        return;
      }

      const teams = await getTeamsByLeague(eventId);
      const players = [];
      const playersResponse = teams?.map((team) => {
        const playerDetailsOfThisTeam = returnPlayersFromTeams(team);
        playerDetailsOfThisTeam?.map((player) => {
          players.push(player);
        });
      });

      console.log('TEAMS', teams);
      if (teams.length < 2) {
        toast.error('Teams is not enough');
        setLoading(false);
        return;
      }
      const score = [];
      console.log('Player', players);

      for (let i = 0; i < teams.length; i++) {
        const playerL = findPlayersFromEventsByTeam(teams[i], players);
        console.log('Player of Team+', playerL);
        if (playerL.length < 4) {
          toast.error(`${teams[i].name} Doesn't have enough players`);
          setLoading(false);
          return;
        }

        const playerScoreOfSingleTeams = [];
        for (let j = 0; j < playerL.length; j++) {
          const player = playerL[j];

          let playerDetails = {
            ...stats,
            teamId: teams[i].id,
            teamName: teams[i].name,
            teamLogo: teams[i].imageURL,
            playerId: player.id,
            playerName: player.participantName,
            playerImage: player.profile,
          };
          console.log('playerDetails', playerDetails);
          playerScoreOfSingleTeams.push(playerDetails);
        }

        score.push(playerScoreOfSingleTeams);
      }

      const totalRounds = calculateRounds(teams.length);
      const createdScore = [];

      for (let i = 0; i < score.length; i += 2) {
        if (i < score.length - 1) {
          console.log('createdScorePlayer', score[i][0].teamId);

          const firstTeam = {
            teamId: score[i][0].teamId,
            teamName: score[i][0].teamName,
            teamLogo: score[i][0].teamLogo,
          };

          const secondTeam = {
            teamId: score[i + 1][0].teamId,
            teamName: score[i + 1][0].teamName,
            teamLogo: score[i + 1][0].teamLogo,
          };

          const firstTeamsPlayerDetails = [...score[i]];
          const secondTeamsPlayerDetails = [...score[i + 1]];
          const bothPlayerDetails = [...firstTeamsPlayerDetails, ...secondTeamsPlayerDetails];

          const currentTwoTeams = [firstTeam, secondTeam];

          const singleScore = {
            event: event.title,
            eventId: event.id,
            eventImage: event.img_url,
            matchNumber: i,
            round: 1,
            teams: [...currentTwoTeams],
            firstTeam: currentTwoTeams[0],
            secondTeam: currentTwoTeams[1],
            ...rank,
            tournament: false,
            city: '',
            playerDetails: bothPlayerDetails,
            firstTeamDetails: firstTeamsPlayerDetails,
            secondTeamDetails: secondTeamsPlayerDetails,
            eventStatus: 'EVENT_STARTED',
            // roundId: ID @index(name: "byRounds")
            // eventsId: ID! @index(name: "byScore")
            // winner: ID! @index(name: "byWinner")
          };

          createdScore.push(singleScore);
        }
      }
      console.log('CreatedScore', createdScore);
      const teamsId = teams.map((team, id) => {
        return team.id;
      });
      let lastTeam;
      let totalTeams = [...teamsId];
      if (teamsId.length % 2 !== 0) {
        totalTeams = teamsId.slice(0, teamsId.length - 1);
        lastTeam = teamsId.pop();
      }
      const inputWinner = {
        eventName: event.title,
        eventId: event.id,
        tournamentId: event.id,
        totalRounds,
        eventStatus: 'EVENT_NOT_STARTED',
      };
      // const responseOfWinner = await API.graphql({
      //   query: mutations.createWinner,
      //   variables: {
      //     input: {
      //       eventName: event.title,
      //       eventId: event.id,
      //       tournamentId: event.id,
      //       totalRounds,
      //       eventStatus: 'EVENT_NOT_STARTED',
      //     },
      //   },
      //   authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      //   const winner = responseOfWinner.data.createWinner;
      const winner = await createWinner(inputWinner);

      console.log('Winner', winner);
      // const responseOfRounds = await API.graphql({
      //   query: mutations.createRounds,
      //   variables: {
      //     input: {
      //       eventName: event.title,
      //       eventId: event.id,
      //       round: 1,
      //       runnerUpList: [...totalTeams],
      //       eventStatus: 'EVENT_NOT_STARTED',
      //       winnerId: winner.id,
      //     },
      //   },
      //   authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      const roundsDataToCreate = {
        eventName: event.title,
        eventId: event.id,
        round: 1,
        runnerUpList: [...totalTeams],
        eventStatus: 'EVENT_NOT_STARTED',
        winnerId: winner.id,
      };
      const responseOfRounds = await createRounds(roundsDataToCreate);

      const rounds = responseOfRounds;
      if (teams.length % 2 !== 0) {
        const roundsDataToCreate = {
          eventName: event.title,
          eventId: event.id,
          round: 2,
          runnerUpList: [lastTeam],
          eventStatus: 'EVENT_NOT_STARTED',
          winnerId: winner.id,
        };
        const createdRounds = await createRounds(roundsDataToCreate);
      }
      console.log('TEAM ID', teamsId);
      createdScore.forEach(async (scoreOfPlayers, index, arr) => {
        console.log('scoreOfPlayers', scoreOfPlayers);

        try {
          console.log('Round', rounds);
          const scoreData = {
            ...scoreOfPlayers,
            roundId: rounds.id,
            eventsId: event.id,
            winnerId: winner.id,
          };
          const responseOfScore = await API.graphql({
            query: mutations.createScore,
            variables: {
              input: {
                ...scoreData,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          const scoreCreated = response.data.createScore;
          console.log('CreatedScore', scoreCreated);
        } catch (error) {
          console.error('Error While Creating Score:', error.message);
          throw new Error('Error while creating Score', error.message);
        }
      });

      console.log('score', score);
      console.log('TOTAL ROUNDS', totalRounds);

      const responseOfScore = await API.graphql({
        query: mutations.updateLeague,
        variables: {
          input: {
            // ...event,
            id: event.id,
            eventStatus: 'EVENT_STARTED',
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      toast.success(`Event Started`);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.log('ERROR Starting League', error);
      if (!error === 'Error: Error: Body must be a string. Received: undefined.') {
        toast.error(`Something Went Wrong! `, error);
        setError(error);
        console.error('Error', error);
        throw new Error(error);
      }
      setLoading(false);
    }
  }

  const fetchLeagueById = async (leagueId) => {
    try {
      const response = await API.graphql({
        query: getLeagueQuery,
        variables: { id: leagueId },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      return response.data.getLeague;
    } catch (error) {
      console.error('Error fetching league:', error);
      toast.error('Failed to fetch league');
      throw error; // Rethrow or handle the error as needed
    }
  };

  function groupTeamsByAgeGroup(teams) {
    return teams.reduce((acc, team) => {
      const agegroup = team.agegroup;
      if (!acc[agegroup]) {
        acc[agegroup] = [];
      }
      acc[agegroup].push(team);
      return acc;
    }, {});
  }

  function groupScoresByAgeGroup(scores) {
    return scores.reduce((acc, score) => {
      const agegroup = score.agegroup;
      if (!acc[agegroup]) {
        acc[agegroup] = [];
      }
      acc[agegroup].push(score);
      return acc;
    }, {});
  }

  function calculateRounds(n) {
    if (n <= 0) {
      return 0;
    }

    if (n === 1) {
      return 1;
    }

    let rounds = 0;
    let teams = n;

    while (teams > 1) {
      rounds++;
      teams = Math.ceil(teams / 2);
    }

    return rounds;
  }

  const returnPlayersFromTeams = (team) => {
    const player = team.players.items;
    return player;
  };

  const findPlayersFromEventsByTeam = (team, players) => {
    console.log('Team', team, players);
    return players.filter((player) => player.teamID === team.id);
  };

  return {
    startEvents,
    teams,
    event,
    players,
    loading,
    error,
  };
}

const stats = {
  GP: '0',
  MIN: '0',
  PTS: '0',
  FGM: '0',
  FGA: '0',
  FGPERCENT: '0',
  THREEPM: '0',
  THREEPA: '0',
  THREEPPERCENT: '0',
  FTM: '0',
  FTA: '0',
  FTPERCENT: '0',
  OREB: '0',
  DREB: '0',
  REB: '0',
  AST: '0',
  TOV: '0',
  STL: '0',
  BLK: '0',
  PF: '0',
  FP: '0',
  DDTWO: '0',
  TDTHREE: '0',
  PlusMinus: '0',
};

const rank = {
  record: '0',
  ppg: '0',
  rpe: '0',
  apg: '0',
  ranking: '0',
};

const findPlayers = async (players, team) => {
  const teamPlayers = [];
  console.log('PlayersDetails', team);
  for (let j = 0; j < players.length; j++) {
    const playerId = players[j];
    const response = await API.graphql(graphqlOperation(queries.getPlayer, { id: playerId.id }));
    if (!response.data.getPlayer) {
      toast.error('Player Not Found Id:', playerId);
      return false;
    }
    const player = response.data.getPlayer;
    console.log('Player', player);

    const playerDetails = {
      teamId: player.teamID,
      teamName: player.teamName,
      teamLogo: team.data.getTeam.imageURL,
      playerId: player.id,
      playerName: player.participantName,
      playerImage: player.profile,

      ...stats,
    };
    teamPlayers.push(playerDetails);
  }

  return teamPlayers;
};
const findPlayersFromEventsByTeam = (team, players) => {
  const playersOfThisTeams = players.filter((player) => player.teamID === team.id);
  return playersOfThisTeams;
};
export default useStartScoreNew;

/* eslint-enable consistent-return, prefer-const, no-plusplus, no-await-in-loop, no-undef */
