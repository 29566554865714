export const deleteTypeName = (object) => {
  console.log("object", object);

  if (Array.isArray(object)) {
    return object.map(item => deleteTypeName(item));
  }

  if (typeof object === 'object' && object !== null) {
    return Object.keys(object).reduce((newObj, key) => {
      if (key !== '__typename') {
        newObj[key] = deleteTypeName(object[key]);
      }
      return newObj;
    }, {});
  }

  return object;
};
