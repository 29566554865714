import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const useFilteredLeague5x5Data = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [leagueData, setLeagueData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFilteredData = async (eventId, location) => {
    try {
      setLoading(true);
      let teams = [];
      let nextToken = null;

      do {
        const responseTeams = await API.graphql({
          query: queries.listTeam5x5s,
          variables: {
            filter: {
              city: {
                eq: location,
              },
              leagueId: {
                eq: eventId,
              },
            },
            nextToken: nextToken,
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          },
        });

        const fetchedTeams = responseTeams.data.listTeam5x5s.items;
        teams = teams.concat(fetchedTeams);
        nextToken = responseTeams.data.listTeam5x5s.nextToken;
      } while (nextToken);

      const players = [];

      teams.map((team) => {
        team.players.items.map((playe) => {
          players.push(playe);
        });
      });

      // const responseLeagues = await API.graphql({
      //   query: queries.listPlayers,
      //   authMode: GRAPHQL_AUTH_MODE.API_KEY
      //   variables: {
      //     filter: {
      //       cityOfRegistration: {
      //         eq: location
      //       }
      //     }
      //   }
      // });

      // const leagues = responseLeagues?.data?.listPlayers?.items || [];
      const leagues = players || [];

      // Group league data by age group and league name
      const groupedData = leagues.reduce((acc, league) => {
        const { agegroup, teamName } = league;
        const key = `${agegroup}-${teamName}`;

        if (!acc[key]) {
          acc[key] = { agegroup, teamName, participants: [] };
        }

        acc[key].participants.push(league.participantName);

        return acc;
      }, {});

      // Convert the grouped data to an array
      const newFilteredData = Object.values(groupedData);

      setFilteredData(newFilteredData);
      setLeagueData(leagues);
      setLoading(false);
      return newFilteredData;
    } catch (error) {
      setLoading(false);
      console.error('Error fetching filtered data:', error);
      throw error;
    }
  };

  const generateJerseyNumbers = async (event, location, jerseySize) => {
    try {
      setLoading(true);
      console.log('EVent', event, location);
      await fetchFilteredData(event, location);

      const leagueDataForEvent = leagueData.filter((league) => league.cityOfRegistration === location);

      console.log('leagueDataForEvent', leagueDataForEvent);

      // Use Promise.all to wait for all tasks to complete
      await Promise.all(
        Object.entries(jerseySize).map(async ([size, range]) => {
          if (range) {
            const [min, max] = range.split('-').map((val) => parseInt(val.trim(), 10));
            const rangeNumbers = Array.from({ length: max - min + 1 }, (_, index) => (min + index).toString());
            const filteredDataForSize = leagueDataForEvent.filter((league) => league.jerseySize.toUpperCase() === size);

            // Get all existing jersey numbers for the current size
            const existingJerseyNumbers = leagueDataForEvent
              .filter((league) => league.jerseySize.toUpperCase() === size && league.jerseyNo)
              .map((league) => league.jerseyNo.toString().toUpperCase());

            console.log('existingJerseyNumbers', existingJerseyNumbers);

            // Filter out rangeNumbers that are already assigned
            const availableJerseyNumbers = rangeNumbers.filter(
              (jerseyNumber) => !existingJerseyNumbers.includes(jerseyNumber)
            );

            // Get records without jersey numbers for the current size
            const recordsWithoutJerseyNumber = leagueDataForEvent.filter(
              (league) => league.jerseySize.toUpperCase() === size
            );

            if (filteredDataForSize.length === 0) {
              toast.error(`No records available for size ${size}!`);
            } else if (availableJerseyNumbers.length === 0) {
              toast.error(`All numbers for size ${size} are already taken.`);
            } else {
              // Ensure all updates are completed before proceeding
              await Promise.all(
                recordsWithoutJerseyNumber.slice(0, availableJerseyNumbers.length).map((record, indx) => {
                  console.log('availableJerseyNumbers[indx]', availableJerseyNumbers[indx], indx);
                  return updateJerseyNumber(record.id, availableJerseyNumbers[indx]);
                  // return [];
                })
              );
              toast.success(`Jersey numbers for size ${size} updated successfully!`);
            }
          }
        })
      );

      // toast.success('Generated Jersey Numbers Successfully!');
      await fetchFilteredData(event, location);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error generating jersey numbers:', error);
      throw error;
    }
  };

  const updateJerseyNumber = async (id, jerseyNumber) => {
    try {
      await API.graphql(
        graphqlOperation(mutations.updatePlayer, {
          input: {
            id,
            jerseyNo: jerseyNumber,
          },
        })
      );
      console.log('success updating jersey number:');
    } catch (error) {
      console.error('Error updating jersey number:', error);
      throw error;
    }
  };

  return { fetchFilteredData, generateJerseyNumbers, filteredData, leagueData, loading };
};

export default useFilteredLeague5x5Data;
