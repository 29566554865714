/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import getLink from '../utils/getLinks';
import deleteFile from '../utils/deleteFile';
import * as queries from '../graphql/queries';
import uploadFile from '../utils/uploadFile';
import * as mutations from '../graphql/mutations';

const useHandlePagintationPlayers = () => {
  const [initialNextNextTokenVerification, setInitialNextNextTokenVerification] = useState(undefined);

  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [nextTokenVerification, setNextTokenVerification] = useState(undefined);
  const [nextNextTokenVerication, setNextNextTokenVerification] = useState(undefined);
  const [playersList, setPlayerList] = useState([]);
  const [playerList, setPlayersList] = useState([]);
  const [playersDetails, setPlayerDetails] = useState();

  const [verifiedPlayers, setVerifiedPlayers] = useState([]);
  const [notVerifiedPlayers, setNotVerifiedPlayers] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);
  const [previousTokensVerification, setPreviousTokensVerifications] = useState([]);

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };

  // const fetchPlayer = async ({ VerifiedName = '' }) => {
  //   setLoading(true);

  //   let variables;
  //   try {
  //     let filterExpression = {};
  //     console.log('VerifiedName: ' + VerifiedName);
  //     if (VerifiedName && VerifiedName.trim() !== '') {
  //       setInitialNextNextToken(undefined);
  //       setNextToken(undefined);
  //       setNextNextToken(undefined);
  //       setPreviousTokens([]);
  //       filterExpression = {
  //         title: {
  //           contains: VerifiedName.trim(),
  //         },
  //       };

  //       variables = {
  //         limit: 100,
  //         filter: filterExpression,
  //       };

  //       if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
  //         variables = {
  //           ...variables,
  //           nextToken,
  //         };
  //       }
  //     } else {
  //       variables = {
  //         limit: 10,
  //         filter: filterExpression,
  //       };

  //       if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
  //         variables = {
  //           ...variables,
  //           nextToken,
  //         };
  //       }

  //       // }

  //       const result = await API.graphql({
  //         query: queries.listLeagues,
  //         variables,
  //         authMode: GRAPHQL_AUTH_MODE.API_KEY // Adjust this as needed
  //       });

  //       const fetchedPlayers = result?.data?.listLeagues?.items || [];
  //       const updatedPlayers = await Promise.all(
  //         fetchedPlayers.map(async (Verified) => {
  //           try {
  //             const cachedImgUrl = await fetchImageFromCacheWithExpiration(Verified?.img_url);

  //             let render_img_url = cachedImgUrl;

  //             if (!cachedImgUrl) {
  //               render_img_url = await loadImageAndCache(Verified?.img_url);
  //             }

  //             const dataWithImageUrl = {
  //               ...Verified,
  //               render_img_url,
  //             };

  //             return { ...dataWithImageUrl };
  //           } catch (error) {
  //             console.error('Error fetching image URL', error);
  //             return Verified;
  //           }
  //         })
  //       );
  //       if (!initialNextNextToken) {
  //         setInitialNextNextToken(result?.data?.listLeague?.nextToken);
  //       }

  //       setPlayerList([...updatedPlayers]);
  //       setNextNextToken(result?.data?.listLeague?.nextToken);

  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error('Error fetching Players:', error);
  //   }
  // };
  const fetchPlayer = async () => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: queries.listPlayers,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const data = response?.data.listPlayers.items;
      const modifiedPlayers = data?.map((user) => {
        return {
          ...user,
          id: user?.id,
          name: user?.participantName,
          email: user?.email,
          status: 'Confirmed',
          //
          zone: 'Player',
          // Add other attributes you want to include
        };
      });
      const players = response?.data.listPlayers.items;
      const verifiedPlayers = players?.filter((player) => player.isVerified === true);
      const nonVerifiedPlayers = players?.filter((player) => player.isVerified !== true);

      setPlayerList(players);
      setPlayersList(modifiedPlayers);
      setVerifiedPlayers(verifiedPlayers);
      setNotVerifiedPlayers(nonVerifiedPlayers);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw new Error(error);
    }
  };

  const fetchPlayerWithPaginataion = async () => {
    try {
      setLoading(true);

      let variables = {
        limit: 100,
      };

      if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
        variables = {
          ...variables,
          nextToken,
        };
      }

      // }

      const result = await API.graphql({
        query: queries.listPlayers,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });

      if (!initialNextNextToken) {
        setInitialNextNextToken(result?.data?.listLeague?.nextToken);
      }
      const list = result?.data?.listPlayers?.items || [];
      setPlayerList([...list]);
      setNextNextToken(result?.data?.listPlayers?.nextToken);

      const playerList = result?.data.listPlayers.items;
      setPlayerList(playerList);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw new Error(error);
    }
  };

  const fetchVerifiedPlayer = async () => {
    try {
      setLoading(true);

      let variables = {
        limit: 100,
      };

      if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
        variables = {
          ...variables,
          nextToken,
        };
      }

      const result = await API.graphql({
        query: queries.listPlayers,
        variables: {
          limit: 10,
          filter: {
            isVerified: {
              eq: true,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });
      if (!initialNextNextTokenVerification) {
        setInitialNextNextTokenVerification(result?.data?.listLeague?.nextToken);
      }
      const list = result?.data?.listPlayers?.items || [];
      setPlayerList([...list]);
      setNextNextTokenVerification(result?.data?.listPlayers?.nextToken);

      const playerList = result?.data.listPlayers.items;

      setVerifiedPlayers(playerList);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw new Error(error);
    }
  };

  const fetchNotVerifiedPlayers = async () => {
    try {
      setLoading(true);
      let allPlayers = []; // Array to store all players

      let nextToken = null;
      do {
        let variables = {
          limit: 100,
        };

        if (nextToken) {
          variables.nextToken = nextToken;
        }

        const result = await API.graphql({
          query: queries.listPlayers,
          variables: {
            limit: 100,
            filter: {
              isVerified: {
                eq: false,
              },
            },
            nextToken: variables.nextToken, // Include nextToken in query variables
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const playerList = result.data.listPlayers.items;
        allPlayers = [...allPlayers, ...playerList]; // Concatenate current page of players

        nextToken = result.data.listPlayers.nextToken; // Update nextToken for next iteration
      } while (nextToken); // Continue fetching while there's a nextToken

      setNotVerifiedPlayers(allPlayers); // Set all fetched players
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw new Error(error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const fetchNextPageVerification = () => {
    if (nextNextTokenVerication) {
      setPreviousTokens([...previousTokensVerification, nextTokenVerification]);
      setNextToken(nextNextTokenVerication);
    }
  };

  const fetchPreviousPageVerification = () => {
    if (previousTokensVerification.length > 0) {
      const prevTokens = [...previousTokensVerification];
      const lastToken = prevTokens.pop();

      setPreviousTokensVerifications(prevTokens);
      setNextNextTokenVerification(nextTokenVerification || initialNextNextToken);
      setNextTokenVerification(lastToken);
    }
  };

  const deletePlayers = async (id) => {
    try {
      setPlayerList((prevPlayersList) => prevPlayersList.filter((item) => item.id !== id));
      setNotVerifiedPlayers((prevPlayersList) => prevPlayersList.filter((item) => item.id !== id));
      setVerifiedPlayers((prevPlayersList) => prevPlayersList.filter((item) => item.id !== id));
      setLoading(true);
      const res = await API.graphql(graphqlOperation(queries.getPlayer, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const isDeleted = await deleteFile(res?.data?.getPlayer?.img_url);
      const isDel = await deleteFile(res?.data?.getPlayer?.document);

      // if (isDeleted && isDel) {
      const response = await API.graphql({
        query: mutations.deletePlayer,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      console.log(response);
      toast.success('Players Deleted Successfully!');

      // Remove deleted Verified from the playersList

      // Remove deleted images from local storage
      localStorage.removeItem(res?.data?.deletePlayer?.profile);
      // }else{
      // toast.error('Error Deleting Players');

      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Players:', error);
    }
  };

  const updatePlayers = async (data) => {
    try {
      setLoading(true);
      const id = id || data?.id;

      const response = await API.graphql({
        query: mutations.updatePlayer,
        variables: { input: { id, ...data } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedData = response?.data?.updatePlayer;

      const profileLink = await getLink(updatedData?.profile);
      const document = await getLink(updatedData?.document);
      const d = {
        ...data,
        documentLink: document,
        profileLink,
      };
      const updatedDetails = {
        ...response?.data?.updateLeague,
      };

      setPlayerList((prevPlayersList) => {
        const updatedNewsList = prevPlayersList?.map((item) => {
          if (item.id === id) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedNewsList;
      });
      setPlayerDetails(...updatedDetails);

      toast.success('Players Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating Verified details:', error);
      toast.error('Error updating Verified details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const verifyPlayer = async (playerId) => {
    try {
      setLoading(true);
      const league = await API.graphql(graphqlOperation(queries.getLeague, { id }));

      const data = {
        ...league?.response?.getPlayer,
        isVerified: true,
      };

      const response = await API.graphql({
        query: mutations.updatePlayer,
        variables: { input: { ...data, id: playerId } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setLoading(false);
      const updatedDetails = {
        ...response?.data?.updateLeague,
      };

      setPlayerList((prevPlayersList) => {
        const updatedNewsList = prevPlayersList?.map((item) => {
          if (item.id === id) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedNewsList;
      });

      setNotVerifiedPlayers((prevPlayersList) => prevPlayersList.filter((item) => item.id !== id));

      setPlayerDetails(...updatedDetails);

      toast.success('Players Verified Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating Verified details:', error);
      toast.error('Error updating Verified details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const getPlayerDetails = async (playerId) => {
    try {
      setLoading(true);
      const playerResponse = await API.graphql({
        query: queries.getPlayer,
        variables: {
          id: playerId,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const playerData = playerResponse?.data?.getPlayer;

      const signedUrl = await getLink(playerData?.profile);
      const document = await getLink(playerData?.document);

      const updatedData = {
        ...playerData,
        img_url: signedUrl,
        profileLink: signedUrl,
        documentLink: document,
      };
      setPlayerDetails(updatedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw new Error(error);
    }
  };
  const fetchedPlayers = async (id) => {
    await getPlayerDetails(id);
  };
  // const addEvent = async (items) => {
  //   setLoading(true);

  //   try {
  //     setLoading(true);

  //     // Upload the image to S3 using Storage.put
  //     const updatedData = { ...items };

  //     const key = await uploadFile(updatedData?.img_url);
  //     const response = await create(key, updatedData);
  //     const render_img_url = await fetchImageFromCacheWithExpiration(key);

  //     const VerifiedData = {
  //       ...response?.data?.createLeague,
  //       render_img_url,
  //     };
  //     // Check if the team was successfully created
  //     setLoading(false);
  //     setPlayerList((prevPlayersList) => [VerifiedData, ...prevPlayersList]); // Add the new Verified data to the Verified list

  //     // Fetch Verified URL from AWS and update playersList
  //     // Implement fetchPlayerUrlFromAWS to retrieve Verified data

  //     setLoading(false);
  //     toast.success(`Created Successfully!`);
  //     return response;
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error('Something Went Wrong: Check Console');
  //     console.error('Error creating Verified:', error);
  //     throw error;
  //   }
  // };
  // const create = async (key, updatedData) => {
  //   setLoading(true);

  //   const response = await API.graphql({
  //     query: mutations.createLeague,
  //     variables: {
  //       input: {
  //         ...updatedData,
  //         img_url: key,
  //       },
  //     },
  //     authMode:GRAPHQL_AUTH_MODE.API_KEY
  //   });
  //   return response;
  // };

  useEffect(() => {
    fetchNotVerifiedPlayers();

    if (id) {
      getPlayerDetails(id);
    }
  }, []);
  return {
    playerList,
    playersList,
    notVerifiedPlayers,
    verifiedPlayers,
    nextNextToken,
    loading,
    deletePlayers,
    fetchNextPage,
    fetchPlayer,
    fetchPreviousPage,
    previousTokens,
    playersDetails,
    // addEvent,
    updatePlayers,
    verifyPlayer,
    getPlayerDetails,
  };
};

export default useHandlePagintationPlayers;
