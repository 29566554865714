import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';

import { clearTournamentErrors, getTournamentDetails, verifyTournament } from '../../redux/actions/TournamentAction';
import useHandlePagintationTournaments from '../../hooks/handlePaginationTournament';
import useHandlePagintationTournamentEvents from '../../hooks/handlePaginationTournamentEvents';

const Tournament = () => {
  const { loading, deleteTournaments } = useHandlePagintationTournaments();
  const { loading:eventLoading,getEventById,eventsList} = useHandlePagintationTournamentEvents();
  const {
    tournamentDetails,
    loading: loadingTournaments,
    error: tournamentError,
    deleted,
    updated,
    success,
  } = useSelector((state) => state.tournament);

  const dispatch = useDispatch();
  const { id } = useParams();
  console.log('console',tournamentDetails);
  const [isVerified, setIsVerified] = useState(tournamentDetails?.isVerified);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const navigate = useNavigate();

  const handleAccept = async () => {
    dispatch(verifyTournament(id));
    dispatch(getTournamentDetails(id));
    setIsVerified(true);
  };

  const handleReject = async () => {
    if (tournamentDetails?.id) {
      console.log('id', tournamentDetails?.id); // Check if a row is selected
   
      setIsDeleteDialogOpen(false);
      await deleteTournaments(tournamentDetails?.id);

      dispatch(getTournamentDetails(id));
      navigate('/dashboard/tournament-verifications');
      setIsVerified(true);

      // Use the selected row's id
    }
  };

  useEffect(() => {
    if (tournamentError) {
      dispatch(clearTournamentErrors());
    }
    if (id) {
      dispatch(getTournamentDetails(id));
      getEventById(tournamentDetails?.tournamentId);
    } 
    console.log('Player', tournamentDetails);
  }, [id, isVerified, tournamentError]);

  const isDisabled = true;
  if (loading||loadingTournaments || eventLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Box>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete User Details</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this User Details?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleReject} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container style={{ marginLeft: 1, marginTop: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box className="w-[100px] h-[100px] rounded-full overflow-hidden">
              <img
                src={tournamentDetails?.profileLink}
                alt={tournamentDetails?.participantName}
                className="w-full h-full object-cover"
              />
            </Box>

            <TextField
              label="Participant Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.participantName}
              disabled
            />
            <TextField
              label="Tournament"
              fullWidth
              margin="normal"
              variant="outlined"
              value={eventsList?.title}
              disabled
               InputLabelProps={{
    shrink: true,
  }}
            />
            <TextField
              label="Registered Team Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.teamName}
              disabled
            />
            <TextField
              label="Height"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.HEIGHT}
              disabled
            />
            <TextField
              label="Weight"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.WEIGHT}
              disabled
            />
            <TextField
              label="Years Played in HS"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.H_S}
              disabled
            />
            <TextField
              label="Years played as pro"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.NBA}
              disabled
            />
            <TextField
              label="Years Played in College"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.COLLEGE}
              disabled
            />
            <TextField
              label="Date of birth"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.dateOfBirth}
              disabled
            />
            <TextField
              label="Adult ?"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.isAdult}
              disabled
            />
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.email}
              disabled
            />
            <TextField
              label="Jersey Size"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.jerseySize}
              disabled
            />
            <TextField
              label="Member of Other Team"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.isMemberOfOtherTeam}
              disabled
            />
            <TextField
              label="School Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.schoolName}
              disabled
            />
            <TextField
              label="Grade"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.grade}
              disabled
            />
            <TextField
              label="Club Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.clubName}
              disabled
            />
            <TextField
              label="Team Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.teamName}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Secondary Emergency Contact Person Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.secondaryPersonName}
              disabled
            />
            <TextField
              label="Secondary Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.secondaryCallPhoneNumber}
              disabled
            />
            <TextField
              label="Secondary Email Address"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.secondaryEmergencyContactEmail}
              disabled
            />
            <TextField
              label="Primary Emergency Contact Person Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.primaryPersonName}
              disabled
            />
            <TextField
              label="Primary Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.primaryCallPhoneNumber}
              disabled
            />
            <TextField
              label="Primary Email Address"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.primaryEmergencyContactEmail}
              disabled
            />
            <TextField
              label="From Where You Heard About 3x3"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.heardAboutLeague}
              disabled
            />
            <TextField
              label="Health Conditions"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.healthConditions}
              disabled
            />
            <TextField
              label="Message"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.message}
              disabled
            />
            <TextField
              label="Parent First Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.parentFirstName}
              disabled
            />
            <TextField
              label="Parent Last Name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.parentLastName}
              disabled
            />
            <TextField
              label="Parent Email Address"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.parentEmailAddress}
              disabled
            />
            <TextField
              label="Parents Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.parentPhoneNumber}
              disabled
            />
            <TextField
              label="Parents Business Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.parentBusinessPhoneNumber}
              disabled
            />
            <TextField
              label="Parents Home Phone Number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.parentHomePhoneNumber}
              disabled
            />
            <TextField
              label="Parents Country"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.parentCountry}
              disabled
            />
            <TextField
              label="Signature"
              fullWidth
              margin="normal"
              variant="outlined"
              value={tournamentDetails?.signature}
              disabled
            />
          </Grid>
        </Grid>
      </Box>

      {!isVerified ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2} // Adjust the gap as needed for spacing between buttons
          marginTop={2} // Adjust the marginTop for top spacing
          marginBottom={2}
        >
          <Button
            variant="contained"
            onClick={handleAccept}
            style={{
              backgroundColor: 'green',
              color: 'white',
            }}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteClick}
            style={{
              backgroundColor: 'red',
              color: 'white',
            }}
          >
            Reject
          </Button>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2} // Adjust the gap as needed for spacing between buttons
          marginTop={2} // Adjust the marginTop for top spacing
          marginBottom={2}
        >
          <Typography
            variant="contained"
            disabled
            style={{
              color: 'green',
            }}
          >
            Verified
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Tournament;
