import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import Iconify from '../../../components/iconify';
import AddNewTutor from './AddNewTutor';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

export default function TutorCard({ data, deleteData, updateData, fetchData }) {
  const { name, profile, bio, id } = data;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [openTutor, setOpenTutor] = useState(false);
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  console.log('DATA : ', data);

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
    setOpenTutor(true);
  };

  const handleOpenModal = () => {
    setOpenTutor(true);
  };
  const handleCloseModal = () => {
    setOpenTutor(false);
  };
  const handleDeleteConfirm = async () => {
    setIsDeleteDialogOpen(false);
    await deleteData(data, id);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          <StyledProductImg alt={name} src={profile} />
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <EditButton onEditClick={handleEditClick} />
          {isEditDialogOpen && ( // Conditionally render AddNewEventSideBar
            <AddNewTutor
              hidden
              isOpen={openTutor} // Pass the state that controls the Dialog visibility
              onClose={handleCloseModal} // Pass the function to close the Dialog
              editData={data}
              onOpen={handleOpenModal}
              updateData={updateData}
            />
          )}
          <DeleteButton onDeleteClick={handleDeleteClick} />
        </Stack>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Tutor Card</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Card>
  );
}

const DeleteButton = ({ onDeleteClick }) => {
  return (
    <Button color="primary" onClick={onDeleteClick}>
      <Iconify color="red" icon="fluent:delete-12-regular" />
    </Button>
  );
};
const EditButton = ({ onEditClick }) => {
  return (
    <Button onClick={onEditClick}>
      <EditIcon color="primary" style={{ fontSize: '18px' }} />
    </Button>
  );
};
