import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Box, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createCategory, getAllCategory, clearCategoryErrors } from '../redux/actions/CategoryAction';
import Details from '../components/DetailsPage/Details';
import Loader from '../components/Loader';
import ModalTwo from '../components/ModalTwo';

export default function Category() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');

  const categories = useSelector((state) => state.category.categories);
  const loading = useSelector((state) => state.category.loading);
  const error = useSelector((state) => state.category.error);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    console.log('closeed')
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleAddCategory = () => {
    if (!categoryName.trim()) {
      toast.error('Category name is required');
      return;
    }
      dispatch(createCategory({ name: categoryName }))
      .then(() => {
        dispatch(getAllCategory());
        handleCloseModal();
        setCategoryName('');
      })
      .catch((error) => {
        console.error('Error adding category:', error);
      });
   
   
  };

  useEffect(() => {
    if (error) {
      dispatch(clearCategoryErrors());
    }
    dispatch(getAllCategory());
  }, [error, dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <ToastContainer />
      <Details label="Category" addDetails={handleShowModal} data={categories} />
      {showModal && (
        <ModalTwo
          title="Add Category"
          handleClose={handleCloseModal}
          handleInputChange={handleInputChange}
          handleAdd={handleAddCategory}
          buttonText="Add Category"
          inputLabel="Category Name"
        />
      )}
    </Container>
  );
}
