/* eslint-disable no-unused-vars */
import { useRecoilState } from "recoil"
import { Auth } from "aws-amplify";
import { userAtom } from "./atoms"

export const useGetUser = () => {
    const [user, setUser] = useRecoilState(userAtom);
    return async () => {
        const curUser = await Auth.currentUserInfo();
        setUser(curUser);
    }
}

export const useSetUser = () => {
    const [user, setUser] = useRecoilState(userAtom);
    return (receivedUser) => {
        setUser(receivedUser);
    }
}