import React, { useEffect, useState } from 'react';
import {
  Select,
  DialogActions,
  MenuItem,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LeagueGame from '../components/leaguegame/LeagueGameLatest';
import Loader from '../components/Loader';
import Iconify from '../components/iconify';
import useEventsList from '../hooks/getEventsList';
import useEventsById from '../hooks/getEventsById';
import useGetTeamById from '../hooks/getTeamById';
import useStartScoreNew from '../hooks/addScoreHooksNew';

import useFilterByTeam from '../hooks/filterPlayerBYTeam';

import { getAllTeams } from '../redux/actions/TeamAction';
import { getAllEvents } from '../redux/actions/EventsAction';
import { getAllCity } from '../redux/actions/CityAction';
import {fetchNotStartedLeagues} from '../hooks/useCustomQueriesLeague';
const ScorePage = () => {
  const [openScore, setOpenScore] = useState(false);
  const dispatch = useDispatch();
  const [events,setNotStartedEvents]=useState([]);

  
  
  const { city, loading: cityLoading, error: cityErrors } = useSelector((state) => state.city);


  const { startEvents, loading } = useStartScoreNew();
  const [event, setEvent] = useState('');
  const [eventId, setEventId] = useState('');

  const [eventError, setEventError] = useState(''); // Initialize Event error state

  console.log('events', events);

  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  const handleOpenScore = () => {
    setOpenScore(true);
  };
  const handleCloseScore = () => {
    setOpenScore(false);
  };

  const handleEventChange = async (e) => {
    const selectedEventTitle = e.target.value;
    console.log('sel;ce', selectedEventTitle); // Extract the selected event ID
    const selectedEvent = events?.find((event) => event.title === selectedEventTitle); // Find the selected event object

    // Ensure both ID and event object exist before setting state
    if (selectedEventTitle && selectedEvent) {
      setEventId(selectedEvent?.id); // Set the selected event ID
      setEvent(selectedEventTitle); // Set the selected event title
    }
  };

  const handleUploadData = async () => {
    try {
      // Create the item using the mutation
      if (!event) {
        setEventError('Event is required.'); // Set the Event error message
        return;
      }
      const newItem = {
        event,
      };
      console.log('Event:', event);
      await startEvents(eventId);
      

      // Reset the form fields after successful upload
      setEvent('');
      setEventError('');
      setOpenScore(false);
    } catch (error) {
      console.error('Error creating item:', error);
    }
  };
  const fetchNotStartedLagues=async()=>{
    try {
      const data=await fetchNotStartedLeagues()
      setNotStartedEvents(data);
    } catch (error) {
      console.log("Error fetching not startedLeagues",error)
    }finally{

    }
  }
  useEffect(() => {
    console.log('EVENTS', events);

    const data= fetchNotStartedLagues()
   
    dispatch(getAllCity());
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Stack
        direction="row"
        flexWrap="wrap-reverse"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ mb: 5, mr: 5 }}
      >
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button
            disableRipple
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="gala:add" />}
            onClick={handleOpenScore}
            style={buttonStyles}
          >
            Add Score
          </Button>
        </Stack>
      </Stack>

      <Grid container spacing={4} mt={10}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <LeagueGame />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={openScore}
        onClose={handleCloseScore}
        PaperProps={{
          style: {
            width: '40%', // adjust as needed
            maxWidth: 'none', // remove maxWidth if you want to limit the width to a certain value
          },
        }}
      >
        <DialogTitle>Add New Score</DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="event">Event</InputLabel>
              <Select
                labelId="event"
                label="Event"
                name="event"
                fullWidth
                variant="outlined"
                value={event}
                onChange={handleEventChange}
              >
                {events?.map((e) => (
                  <MenuItem key={e.id} value={e?.title}>
                    {e.title}
                  </MenuItem>
                ))}
              </Select>

              {eventError && <div style={{ color: 'red' }}>{eventError}</div>}
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2}>
              <Box mr={2}>
                <Button onClick={handleCloseScore} style={{ backgroundColor: 'red', color: 'white' }}>
                  Cancel
                </Button>
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Box ml={2}>
                <Button onClick={handleUploadData} style={{ backgroundColor: 'green', color: 'white' }}>
                  Add
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScorePage;
// const listScores = [
//   {
//     teamLogo: 'team1-logo.jpg',
//     teamName: 'Team 1',
//     stats: {
//       GP: 18,
//       MIN: 25,
//       PTS: 12,
//       FGM: 5,
//       FGA: 10,
//       'FG%': '45%',
//       '3PM': 100,
//       '3PA': 220,
//       '3P%': '45%',
//       FTM: 75,
//       FTA: 90,
//       'FT%': '83%',
//       FGPercent: 50.0,
//       OREB: 5,
//       DREB: 12,
//       REB: 17,
//       AST: 8,
//       TOV: 4,
//       STL: 3,
//       BLK: 2,
//       PF: 6,
//       FP: 28.5,
//       DD2: 2,
//       TD3: 0,
//       PlusMinus: 10,
//     },
//     player: {
//       playerName: 'Player 1',
//       playerImage: 'player1.jpg',
//     },
//   },
// ];
