/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import getLink from '../utils/getLinks';
import deleteFile from '../utils/deleteFile';
import * as queries from '../graphql/queries';
import uploadFile from '../utils/uploadFile';
import * as mutations from '../graphql/mutations';
import { deleteItem } from '../graphql/mutations';

const useHandlePagintationProduct = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [productList, setProductList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };

  const fetchProduct = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        // }

        const result = await API.graphql({
          query: queries.listItems,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });

        const fetchedProduct = result?.data?.listItems?.items || [];
        const updatedProduct = await Promise.all(
          fetchedProduct.map(async (product) => {
            try {
              const cachedImgUrl = await fetchImageFromCacheWithExpiration(product?.img_url);
              const cachedContentImgUrl = await fetchImageFromCacheWithExpiration(product?.content_img_url);
              const cachedVideoUrl = await fetchImageFromCacheWithExpiration(product?.video_url);

              let render_img_url = cachedImgUrl;

              if (!cachedImgUrl) {
                render_img_url = await loadImageAndCache(product?.img_url);
              }

              const dataWithImageUrl = {
                ...product,
                render_img_url,
              };

              return { ...dataWithImageUrl };
            } catch (error) {
              console.error('Error fetching image URL', error);
              return product;
            }
          })
        );
        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listItems?.nextToken);
        }

        setProductList([...updatedProduct]);
        setNextNextToken(result?.data?.listItems?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Product:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteProduct = async (id) => {
    try {
      setLoading(true);

      const res = await API.graphql(graphqlOperation(queries.getItem, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const isDeleted = await deleteFile(res?.data?.getItem?.img_url);

      if (isDeleted) {
        const response = await API.graphql({
          query: deleteItem,
          variables: { input: { id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        console.log(response);
        toast.success('Product Deleted Successfully!');
        setLoading(false);

        // Remove deleted product from the productList
        setProductList((prevProductList) => prevProductList.filter((item) => item.id !== id));

        // Remove deleted images from local storage
        localStorage.removeItem(res?.data?.getProduct?.img_url);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Product:', error);
    }
  };

  const updateProductDetails = async (productId, formData, oldImage) => {
    try {
      setLoading(true);
      if (oldImage !== formData.img_url) {
        const deleted = await deleteFile(oldImage);

        if (!deleted) {
          toast.error('something went wrong');
          return;
        }
        const uploadedKey = await uploadFile(formData.img_url);

        console.log('uploadedKe', uploadedKey);
        localStorage.removeItem(oldImage);
        const response = await API.graphql({
          query: mutations.updateItem,
          variables: { input: { ...formData, img_url: uploadedKey, id: productId } },

          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const render_img_url = await fetchImageFromCacheWithExpiration(response?.data?.updateItem?.img_url);

        const data = {
          ...response?.data?.updateItem,
          render_img_url,
        };

        setProductList((prevProductList) => {
          const updatedNewsList = prevProductList?.map((item) => {
            if (item.id === productId) {
              return { ...item, ...data };
            }
            return item;
          });
          return updatedNewsList;
        });

        toast.success('Product Updated Successfully!');
        setLoading(false);
        return true;
      }
      const response = await API.graphql({
        query: mutations.updateItem,
        variables: { input: { ...formData, id: productId } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const render_img_url = await fetchImageFromCacheWithExpiration(response?.data?.updateItem?.img_url);

      const data = {
        ...response?.data?.updateItem,
        render_img_url,
      };

      setProductList((prevProductList) => {
        const updatedNewsList = prevProductList?.map((item) => {
          if (item.id === productId) {
            return { ...item, ...data };
          }
          return item;
        });
        return updatedNewsList;
      });

      toast.success('Product Updated Successfully!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error updating product details:', error);
      toast.error('Error updating product details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const createItems = async (items) => {
    setLoading(true);

    try {
      setLoading(true);

      // Upload the image to S3 using Storage.put
      const updatedData = { ...items };

      const key = await uploadFile(updatedData?.img_url);
      const response = await create(key, updatedData);
      const render_img_url = await fetchImageFromCacheWithExpiration(key);

      const productData = {
        ...response?.data?.createItem,
        render_img_url,
      };
      // Check if the team was successfully created
      setLoading(false);
      setProductList((prevProductList) => [productData, ...prevProductList]); // Add the new product data to the product list

      // Fetch product URL from AWS and update productList
      // Implement fetchProductUrlFromAWS to retrieve product data

      setLoading(false);
      toast.success(`Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating product:', error);
      throw error;
    }
  };
  const create = async (key, updatedData) => {
    setLoading(true);

    const response = await API.graphql({
      query: mutations.createItem,
      variables: {
        input: {
          ...updatedData,
          img_url: key,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    return response;
  };
  useEffect(() => {
    fetchProduct('');
  }, [nextToken]);

  return {
    productList,
    nextNextToken,
    loading,
    deleteProduct,
    fetchNextPage,
    fetchProduct,
    fetchPreviousPage,
    previousTokens,
    createItems,
    updateProductDetails,
  };
};

export default useHandlePagintationProduct;
