/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries'; // Make sure to import your GraphQL queries
import getLink from '../utils/getLinks';

const useListScores = () => {
  const [listScores, setListScores] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchScores = async (event, city) => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query: queries.listScores,
        variables: {
          filter: {
            event: {
              eq: event, // Fetch only  event
            },
            city: {
              eq: city, // Fetch only city
            },
          },
        }, // Pass the event as a variable to the GraphQL query
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      // Assuming the response structure has changed, adjust the logic accordingly
      const list = response?.data?.listScores?.items;
      // if (list && list.length > 0) {
      //   const promises = list.map(async (item) => {
      //     const eventUrl = await getLink(item?.eventImage);
      //     item.eventUrl = eventUrl;

      //     if (item?.playerDetails && Array.isArray(item.playerDetails)) {
      //       await Promise.all(item.playerDetails.map(async (player) => {
      //         const playerUrl = await getLink(player?.playerImage);
      //         const teamLogo = await getLink(player?.teamLogo);
      //         player.playerImgUrl = playerUrl;
      //         player.teamLogoUrl = teamLogo;
      //       }));
      //     }

      //     return item;
      //   });
      // }

      // const updatedListScores = await Promise.all(promises);
      const filteredList = list.filter((item) => item.event === event);
      setListScores(filteredList);
      console.log('filteredList', filteredList);
      // Filter the list based on the event
      // const filteredList = list.filter((item) => item.event === event);
      // setListScores(filteredList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error('Error fetching scores:', error);
    }
  };

  return { fetchScores, listScores, error, loading };
};

export default useListScores;
