import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopClinicCard from './ClinicCard';

// ----------------------------------------------------------------------

ClinicCardList.propTypes = {
  clinicCards: PropTypes.array.isRequired,
};

export default function ClinicCardList({ clinicCards, loading, updateClinicCards, deleteClinicCards, ...other }) {
  return (
     <Grid container spacing={3} {...other}>
       {clinicCards?.map((clinicCard) => (
         <Grid key={clinicCard.id} item xs={12} sm={6} md={3}>
           <ShopClinicCard
             updateClinicCards={updateClinicCards}
             deleteClinicCards={deleteClinicCards}
             clinicCard={clinicCard}
           />
         </Grid>
       ))}
     </Grid>
  );
 }
 
