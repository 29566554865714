/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import getLink from '../utils/getLinks';
import deleteFile from '../utils/deleteFile';
import * as queries from '../graphql/queries';
import uploadFile from '../utils/uploadFile';
import * as mutations from '../graphql/mutations';

const useHandlePagintationPlayers5x5 = () => {
  const [initialNextNextTokenVerification, setInitialNextNextTokenVerification] = useState(undefined);

  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [nextTokenVerification, setNextTokenVerification] = useState(undefined);
  const [nextNextTokenVerication, setNextNextTokenVerification] = useState(undefined);
  const [playersList, setPlayerList] = useState([]);
  const [playerList, setPlayersList] = useState([]);
  const [playersDetails, setPlayerDetails] = useState();

  const [verifiedPlayers, setVerifiedPlayers] = useState([]);
  const [notVerifiedPlayers, setNotVerifiedPlayers] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);
  const [previousTokensVerification, setPreviousTokensVerifications] = useState([]);

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };

  const fetchPlayer = async () => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: queries.listPlayer5x5s,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const data = response?.data.listPlayer5x5s.items;
      const modifiedPlayers = data?.map((user) => {
        return {
          ...user,
          id: user?.id,
          name: user?.participantName,
          email: user?.email,
          status: 'Confirmed',
          //
          zone: 'Player',
          // Add other attributes you want to include
        };
      });
      const players = response?.data.listPlayer5x5s.items;
      const verifiedPlayers = players?.filter((player) => player.isVerified === true);
      const nonVerifiedPlayers = players?.filter((player) => player.isVerified !== true);

      setPlayerList(players);
      setPlayersList(modifiedPlayers);
      setVerifiedPlayers(verifiedPlayers);
      setNotVerifiedPlayers(nonVerifiedPlayers);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw new Error(error);
    }
  };

  const fetchPlayerWithPaginataion = async () => {
    try {
      setLoading(true);

      let variables = {
        limit: 100,
      };

      if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
        variables = {
          ...variables,
          nextToken,
        };
      }

      // }

      const result = await API.graphql({
        query: queries.listPlayer5x5s,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });

      if (!initialNextNextToken) {
        setInitialNextNextToken(result?.data?.listLeague5x5?.nextToken);
      }
      const list = result?.data?.listPlayer5x5s?.items || [];
      setPlayerList([...list]);
      setNextNextToken(result?.data?.listPlayer5x5s?.nextToken);

      const playerList = result?.data.listPlayer5x5s.items;
      setPlayerList(playerList);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw new Error(error);
    }
  };

  const fetchVerifiedPlayer = async () => {
    try {
      setLoading(true);

      let variables = {
        limit: 100,
      };

      if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
        variables = {
          ...variables,
          nextToken,
        };
      }

      const result = await API.graphql({
        query: queries.listPlayer5x5s,
        variables: {
          limit: 10,
          filter: {
            isVerified: {
              eq: true,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });
      if (!initialNextNextTokenVerification) {
        setInitialNextNextTokenVerification(result?.data?.listLeague5x5?.nextToken);
      }
      const list = result?.data?.listPlayer5x5s?.items || [];
      setPlayerList([...list]);
      setNextNextTokenVerification(result?.data?.listPlayer5x5s?.nextToken);

      const playerList = result?.data.listPlayer5x5s.items;

      setVerifiedPlayers(playerList);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw new Error(error);
    }
  };

  const fetchNotVerifiedPlayers = async () => {
    try {
      setLoading(true);
      let allPlayers = []; // Array to store all players

      let nextToken = null;
      do {
        let variables = {
          limit: 100,
        };

        if (nextToken) {
          variables.nextToken = nextToken;
        }

        const result = await API.graphql({
          query: queries.listPlayer5x5s,
          variables: {
            limit: 100,
            filter: {
              isVerified: {
                eq: false,
              },
            },
            nextToken: variables.nextToken, // Include nextToken in query variables
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const playerList = result.data.listPlayer5x5s.items;
        allPlayers = [...allPlayers, ...playerList]; // Concatenate current page of players

        nextToken = result.data.listPlayer5x5s.nextToken; // Update nextToken for next iteration
      } while (nextToken); // Continue fetching while there's a nextToken

      setNotVerifiedPlayers(allPlayers); // Set all fetched players
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw new Error(error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const fetchNextPageVerification = () => {
    if (nextNextTokenVerication) {
      setPreviousTokens([...previousTokensVerification, nextTokenVerification]);
      setNextToken(nextNextTokenVerication);
    }
  };

  const fetchPreviousPageVerification = () => {
    if (previousTokensVerification.length > 0) {
      const prevTokens = [...previousTokensVerification];
      const lastToken = prevTokens.pop();

      setPreviousTokensVerifications(prevTokens);
      setNextNextTokenVerification(nextTokenVerification || initialNextNextToken);
      setNextTokenVerification(lastToken);
    }
  };

  const deletePlayers = async (id) => {
    try {
      setPlayerList((prevPlayersList) => prevPlayersList.filter((item) => item.id !== id));
      setNotVerifiedPlayers((prevPlayersList) => prevPlayersList.filter((item) => item.id !== id));
      setVerifiedPlayers((prevPlayersList) => prevPlayersList.filter((item) => item.id !== id));
      setLoading(true);
      const res = await API.graphql(graphqlOperation(queries.getPlayer5x5, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const isDeleted = await deleteFile(res?.data?.getPlayer5x5?.img_url);
      const isDel = await deleteFile(res?.data?.getPlayer5x5?.document);

      // if (isDeleted && isDel) {
      const response = await API.graphql({
        query: mutations.deletePlayer5x5,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      console.log(response);
      toast.success('Players Deleted Successfully!');

      // Remove deleted Verified from the playersList

      // Remove deleted images from local storage
      localStorage.removeItem(res?.data?.deletePlayer5x5?.profile);
      // }else{
      // toast.error('Error Deleting Players');

      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Players:', error);
    }
  };

  const updatePlayers = async (data) => {
    try {
      setLoading(true);
      const id = id || data?.id;

      const response = await API.graphql({
        query: mutations.updatePlayer5x5,
        variables: { input: { id, ...data } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedData = response?.data?.updatePlayer5x5;

      const profileLink = await getLink(updatedData?.profile);
      const document = await getLink(updatedData?.document);
      const d = {
        ...data,
        documentLink: document,
        profileLink,
      };
      const updatedDetails = {
        ...response?.data?.updateLeague,
      };

      setPlayerList((prevPlayersList) => {
        const updatedNewsList = prevPlayersList?.map((item) => {
          if (item.id === id) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedNewsList;
      });
      setPlayerDetails(...updatedDetails);

      toast.success('Players Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating Verified details:', error);
      toast.error('Error updating Verified details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const verifyPlayer = async (playerId) => {
    try {
      setLoading(true);
      const league = await API.graphql(graphqlOperation(queries.getLeague5x5, { id }));

      const data = {
        ...league?.response?.getPlayer,
        isVerified: true,
      };

      const response = await API.graphql({
        query: mutations.updatePlayer5x5,
        variables: { input: { ...data, id: playerId } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setLoading(false);
      const updatedDetails = {
        ...response?.data?.updateLeague,
      };

      setPlayerList((prevPlayersList) => {
        const updatedNewsList = prevPlayersList?.map((item) => {
          if (item.id === id) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedNewsList;
      });

      setNotVerifiedPlayers((prevPlayersList) => prevPlayersList.filter((item) => item.id !== id));

      setPlayerDetails(...updatedDetails);

      toast.success('Players Verified Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating Verified details:', error);
      toast.error('Error updating Verified details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const getPlayerDetails = async (playerId) => {
    try {
      setLoading(true);
      const playerResponse = await API.graphql({
        query: queries.getPlayer5x5,
        variables: {
          id: playerId,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const playerData = playerResponse?.data?.getPlayer5x5;

      const signedUrl = await getLink(playerData?.profile);
      const document = await getLink(playerData?.document);

      const updatedData = {
        ...playerData,
        img_url: signedUrl,
        profileLink: signedUrl,
        documentLink: document,
      };
      setPlayerDetails(updatedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw new Error(error);
    }
  };
  const fetchedPlayers = async (id) => {
    await getPlayerDetails(id);
  };
  

  useEffect(() => {
    fetchNotVerifiedPlayers();

    if (id) {
      getPlayerDetails(id);
    }
  }, []);
  return {
    playerList,
    playersList,
    notVerifiedPlayers,
    verifiedPlayers,
    nextNextToken,
    loading,
    deletePlayers,
    fetchNextPage,
    fetchPlayer,
    fetchPreviousPage,
    previousTokens,
    playersDetails,
    // addEvent,
    updatePlayers,
    verifyPlayer,
    getPlayerDetails,
  };
};

export default useHandlePagintationPlayers5x5;
