import React from 'react';

// Sample data for tutors
const tutorsData = [
  {
    id: 1,
    name: 'John Doe',
    profilePic: 'https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg',
    bio: 'Experienced tutor in mathematics and physics.',
    coursesTaught: ['Mathematics', 'Physics', 'Chemistry'],
  },
  {
    id: 2,
    name: 'Jane Smith',
    profilePic: 'https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg',
    bio: 'Passionate about teaching languages.',
    coursesTaught: ['English', 'Spanish', 'French'],
  },
  // Add more tutor data as needed
];

const TutorList = () => { 
  return (
    <div>
      <h2>Tutors</h2>
          <div className="tutor-card flex  gap-10 items-center" >
        {tutorsData.map((tutor) => (
          <div className="tutor-card max-w-[250px] hover:shadow-md cursor-pointer p-5 text-center  flex flex-col gap-4 items-center" key={tutor.id}>
            <img className='shadow-sm max-w-[80px] rounded-full overflow-hidden h-[80px]'  src={tutor.profilePic} alt={tutor.name} />
            <div className="tutor-details">
              <h3>{tutor.name}</h3>
                        </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TutorList;
