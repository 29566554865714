import {
  CREATE_TRAININGPROGRAMCARD_REQUEST,
  CREATE_TRAININGPROGRAMCARD_SUCCESS,
  CREATE_TRAININGPROGRAMCARD_FAIL,
  UPDATE_TRAININGPROGRAMCARD_REQUEST,
  UPDATE_TRAININGPROGRAMCARD_SUCCESS,
  UPDATE_TRAININGPROGRAMCARD_FAIL,
  DELETE_TRAININGPROGRAMCARD_REQUEST,
  DELETE_TRAININGPROGRAMCARD_SUCCESS,
  DELETE_TRAININGPROGRAMCARD_FAIL,
  GET_TRAININGPROGRAMCARD_REQUEST,
  GET_TRAININGPROGRAMCARD_SUCCESS,
  GET_TRAININGPROGRAMCARD_FAIL,
  CLEAR_TRAININGPROGRAMCARD_ERRORS,
  ALL_TRAININGPROGRAMCARD_REQUEST,
  ALL_TRAININGPROGRAMCARD_SUCCESS,
  ALL_TRAININGPROGRAMCARD_FAIL,
} from '../constants/TrainingProgramCardsConstants';

const initialState = {
  trainingProgramCards: [],
  trainingProgramCardCount: 0,
  resultPerPage: 0,
  trainingProgramCard: {},
  isUpdated:false,
  loading: false,
  error: null,
  filteredTrainingProgramCardsCount: 0,
};

export const trainingProgramCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_TRAININGPROGRAMCARD_REQUEST:
    case GET_TRAININGPROGRAMCARD_REQUEST:
    case CREATE_TRAININGPROGRAMCARD_REQUEST:
    case DELETE_TRAININGPROGRAMCARD_REQUEST:
    case UPDATE_TRAININGPROGRAMCARD_REQUEST:
      return {
        ...state,
        loading: true,
        trainingProgramCards: [],
        trainingProgramCard: {},
      };
    case ALL_TRAININGPROGRAMCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        trainingProgramCards: action.payload.trainingProgramCards,
        trainingProgramCardCount: action.payload.trainingProgramCardsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredTrainingProgramCardsCount: action.payload.filteredTrainingProgramCardsCount,
      };
    case CREATE_TRAININGPROGRAMCARD_SUCCESS:
    case GET_TRAININGPROGRAMCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        trainingProgramCard: action.payload,
      };
    case DELETE_TRAININGPROGRAMCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_TRAININGPROGRAMCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case GET_TRAININGPROGRAMCARD_FAIL:
    case ALL_TRAININGPROGRAMCARD_FAIL:
    case CREATE_TRAININGPROGRAMCARD_FAIL:
    case UPDATE_TRAININGPROGRAMCARD_FAIL:
    case DELETE_TRAININGPROGRAMCARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_TRAININGPROGRAMCARD_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



