import React, { useState } from 'react';
import {
  TextField,
  Button,
  CardContent,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Grid,
  TablePagination,
  Card,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify'; // Move this import up
import { useHandleComptetive5x5Teams } from 'src/hooks/handleComptetive5x5Teams';
import { useHandleComptetive5x5Players } from 'src/hooks/handleComptetive5x5Players';
import useCityList from 'src/hooks/getCityList';

const ages = [
  { value: 'u7', label: 'U7' },
  { value: 'u8', label: 'U8' },
  { value: 'u9', label: 'U9' },
  { value: 'u10', label: 'U10' },
  { value: 'u11', label: 'U11' },
  { value: 'u12', label: 'U12' },
  { value: 'u13', label: 'U13' },
  { value: 'u14', label: 'U14' },
  { value: 'u15', label: 'U15' },
  { value: 'u17', label: 'U17' },
  { value: 'u18', label: 'U18' },
  { value: 'u19-u39', label: 'U19-U39' },
  { value: 'over u40', label: 'Over U40' },
];

const Comptetive5x5NotInvited = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [teams, setTeams] = useState([]);
  const [tryoutUsers, setTryoutUsers] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [filteredData, setFilteredData] = useState([]); // New state for filtered data

  const { loading: cityLoading, error, cities } = useCityList();
  const { loading,invitePlayer, getNotInvitedTryouts } = useHandleComptetive5x5Players();
  const { loading: teamsLoading, getAllTeams } = useHandleComptetive5x5Teams();

  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setIsAllSelected(checked);
    const updatedRows = { ...selectedRows };

    // Select or deselect all users in the entire dataset
    tryoutUsers.forEach((user) => {
      if (checked) {
        updatedRows[user.userId] = {
          selected: true,
          team: selectedRows[user.userId]?.team || '',
          city: selectedRows[user.userId]?.city || '',
          agegroup: selectedRows[user.userId]?.agegroup || '',
        };
      } else {
        delete updatedRows[user.userId];
      }
    });

    setSelectedRows(updatedRows);
  };

  React.useEffect(() => {
    // Update the Select All checkbox based on the overall selection state
    const allSelected = allUsers.every((user) => selectedRows[user.userId]?.selected);
    setIsAllSelected(allUsers.length > 0 && allSelected);
  }, [selectedRows, allUsers]);

  React.useEffect(() => {
    const loadData = async () => {
      const teamData = await getAllTeams();
      const tryoutData = await getNotInvitedTryouts();
      console.log('tryoutData', tryoutData?.items);
      setTeams(teamData);
      setAllUsers(tryoutData?.items);
      setTryoutUsers(tryoutData?.items);
    };
    loadData();
  }, []);

  React.useEffect(() => {
    const loadData = async () => {
      const tryoutData = await getNotInvitedTryouts();
      setFilteredData(tryoutData?.items || []); // Initialize filtered data with all items
    };
    loadData();
  }, []);

  const handleSubmit = () => {
    // Filter tryoutUsers based on selectedCity and selectedStatus
    const filtered = tryoutUsers.filter((user) => {
      const matchesCity = selectedCity ? user.cityOfRegistration === selectedCity : true; // If city is selected, filter; otherwise, include all
      return matchesCity ;
    });

    setFilteredData(filtered);
  };

  const handleRowSelection = (id) => {
    setSelectedRows((prev) => {
      const newRows = { ...prev };
      if (newRows[id]?.selected) {
        delete newRows[id];
      } else {
        newRows[id] = {
          selected: true,
          team: prev[id]?.team || '',
          city: prev[id]?.city || '',
          agegroup: prev[id]?.agegroup || '',
        };
      }
      return newRows;
    });
  };

  const handleFieldChange = (id, field, value) => {
    setSelectedRows((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Updated handleSubmitTryouts to pass data to invitePlayer
  const handleSubmitTryouts = async () => {
    const incompleteRows = Object.entries(selectedRows).filter(
      ([, value]) => !value.team || !value.city || !value.agegroup
    );
    console.log(incompleteRows.length);
    if (incompleteRows.length > 0) {
      toast.error('Please provide the Team, City, and Agegroup details for all selected rows.');
      return;
    }

    // Transform selectedRows into array of invitation data
    const invitationData = Object.entries(selectedRows).map(([userId, details]) => {
      const user = tryoutUsers.find((u) => u.userId === userId);
      const teamName = teams.find((t) => t.id === details.team)?.name;

      return {
        userId: userId,
        email: user.email,
        teamID: details.team,
        teamName: teamName,
        cityOfRegistration: details.city,
        agegroup: details.agegroup,
      };
    });

    try {
      await invitePlayer(invitationData);
      setSelectedRows({});
    } catch (error) {
      console.error('Tryout invitation error:', error);
    }
  };

  return (
    <Card className="w-full mx-auto mt-8">
      <ToastContainer />
      <CardContent>
          <div className="mt-4">
            <Typography variant="h5" className="mb-4">
              Reinvite Players via Tryouts
            </Typography>

            <Grid container spacing={2} alignItems="center" justifyContent="space-between" className="mb-4">
              {/* City Selector */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Select City</InputLabel>
                  <Select value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
                    {cities.map((city, index) => (
                      <MenuItem key={index} value={city.name}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Submit Button */}
              <Grid item xs={12} sm={4} container justifyContent="center">
                <Button
                  onClick={handleSubmit}
                  style={{ backgroundColor: '#1E90FF', color: 'white' }}
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

            {/* Conditional Rendering: Table or No Record Found */}
            {filteredData.length > 0 ? (
              <>
                <TableContainer className="mb-4" style={{ overflowX: 'auto', maxWidth: '100%' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            indeterminate={
                              Object.keys(selectedRows).length > 0 &&
                              Object.keys(selectedRows).length < filteredData.length
                            }
                          />
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Date of Birth</TableCell>
                        <TableCell>Team</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Age Group</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                        <TableRow key={user.userId}>
                          <TableCell>
                            <Checkbox
                              checked={!!selectedRows[user.userId]?.selected}
                              onChange={() => handleRowSelection(user.userId)}
                            />
                          </TableCell>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{new Date(user.dateOfBirth).toLocaleDateString()}</TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <InputLabel>Team</InputLabel>
                              <Select
                                value={selectedRows[user.userId]?.team || ''}
                                onChange={(e) => handleFieldChange(user.userId, 'team', e.target.value)}
                                disabled={!selectedRows[user.userId]?.selected}
                              >
                                {teams.map((team) => (
                                  <MenuItem key={team.id} value={team.id}>
                                    {team.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <InputLabel>City</InputLabel>
                              <Select
                                value={selectedRows[user.userId]?.city || ''}
                                onChange={(e) => handleFieldChange(user.userId, 'city', e.target.value)}
                                disabled={!selectedRows[user.userId]?.selected}
                              >
                                {cities.map((city, index) => (
                                  <MenuItem key={index} value={city.name}>
                                    {city.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <InputLabel>Age Group</InputLabel>
                              <Select
                                value={selectedRows[user.userId]?.agegroup || ''}
                                onChange={(e) => handleFieldChange(user.userId, 'agegroup', e.target.value)}
                                disabled={!selectedRows[user.userId]?.selected}
                              >
                                {ages.map((age) => (
                                  <MenuItem key={age.value} value={age.value}>
                                    {age.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />

                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Button
                      onClick={handleSubmitTryouts}
                      className="w-64"
                      disabled={Object.keys(selectedRows).length === 0}
                      style={buttonStyles}
                      color="primary"
                      variant="contained"
                    >
                      {loading || cityLoading ? <CircularProgress size={24} /> : 'Invite Players'}
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography variant="h6" align="center" color="textSecondary">
                No record found
              </Typography>
            )}
          </div>
       
      </CardContent>
    </Card>
  );
};

export default Comptetive5x5NotInvited;
