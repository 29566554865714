

export const GET_CLINICCARD_REQUEST = 'GET_CLINICCARD_REQUEST';
export const GET_CLINICCARD_SUCCESS = 'GET_CLINICCARD_SUCCESS';
export const GET_CLINICCARD_FAIL = 'GET_CLINICCARD_FAIL';


export const ALL_CLINICCARD_REQUEST = 'ALL_CLINICCARD_REQUEST';
export const ALL_CLINICCARD_SUCCESS = 'ALL_CLINICCARD_SUCCESS';
export const ALL_CLINICCARD_FAIL = 'ALL_CLINICCARD_FAIL';

export const CREATE_CLINICCARD_REQUEST = 'CREATE_CLINICCARD_REQUEST';
export const CREATE_CLINICCARD_SUCCESS = 'CREATE_CLINICCARD_SUCCESS';
export const CREATE_CLINICCARD_FAIL = 'CREATE_CLINICCARD_FAIL';

export const UPDATE_CLINICCARD_REQUEST = 'UPDATE_CLINICCARD_REQUEST';
export const UPDATE_CLINICCARD_SUCCESS = 'UPDATE_CLINICCARD_SUCCESS';
export const UPDATE_CLINICCARD_FAIL = 'UPDATE_CLINICCARD_FAIL';


export const DELETE_CLINICCARD_REQUEST = 'DELETE_CLINICCARD_REQUEST';
export const DELETE_CLINICCARD_SUCCESS = 'DELETE_CLINICCARD_SUCCESS';
export const DELETE_CLINICCARD_FAIL = 'DELETE_CLINICCARD_FAIL';
export const DELETE_CLINICCARD_RESET = 'DELETE_CLINICCARD_RESET';

export const CLEAR_CLINICCARD_ERRORS = 'CLEAR_CLINICCARD_ERRORS';
