import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import useCreateMeet from '../../hooks/createMeet';
import Loader from '../Loader';
import useHandlePaginationCourse from '../../hooks/handlePaginationCourse';
import TutorList from './TutorList';

const MeetCreate = () => {
  const [course, setCourse] = useState();
  const [duration, setDuration] = useState('');
  const [title, setTitle] = useState('');

  const [maxInvitees, setMaxInvitees] = useState('');
  const [displayRemainingSpots, setDisplayRemainingSpots] = useState(false);
  const [customDuration, setCustomDuration] = useState('30min');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [errors, setErrors] = useState({
    duration: false,
    maxInvitees: false,
  });
  const [everyDay, setEveryDay] = useState(false);
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const { createMeet, loading, meets } = useCreateMeet();
  const {
    courseList,
    nextNextToken,
    loading: courseLoading,
    deleteCourses,
    fetchNextPage,
    fetchCourse,
    fetchPreviousPage,
    previousTokens,
    addCourse,
    updateCourses,
  } = useHandlePaginationCourse();

  useEffect(() => {
    console.log('course', course);
    if (course) {
      const selectedCourse = courseList.find((c) => c.id === course);
      if (selectedCourse) {
        setTitle(selectedCourse.title);
        setMaxInvitees(selectedCourse.quantity);
        setPrice(selectedCourse.price);
        setDescription(selectedCourse.description);
      }
    }
  }, [course]);

  const handleDurationChange = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === 'custom') {
      setCustomDuration('');
    }
    setDuration(selectedOption);
  };

  const handleCustomDurationChange = (e) => {
    setCustomDuration(e.target.value);
  };

  const createProgram = async () => {
    if (!course || !duration || !maxInvitees || !price || !description || !selectedTimeSlot) {
      setErrors({
        duration: !duration,
        maxInvitees: !maxInvitees,
      });
      return;
    }

    const timeSlotAWSDateTime = selectedTimeSlot;
    console.log('course,', course);
    const newMeet = {
      title,
      selectedDate,
      price,
      description,
      maxUser: maxInvitees,
      duration,
      everyDay,
      from: timeSlotAWSDateTime,
      courseId: course,
    };

    await createMeet(newMeet);

    console.log({
      course,
      duration,
      maxInvitees,
      customDuration,
      selectedDate,
      selectedTimeSlot,
      everyDay,
      price,
      description,
    });
  };

  const generateTimeSlots = () => {
    const startTime = 5;
    const endTime = 23;
    const step = duration / 60;

    const timeSlots = Array.from({ length: endTime - startTime }, (_, index) => {
      const hour = startTime + index * step;
      const startHour = Math.floor(hour);
      const startMinute = (hour - startHour) * 60;
      const endHour = Math.floor(hour + step);
      const endMinute = (hour + step - endHour) * 60;

      const startTimeFormatted = `${String(startHour).padStart(2, '0')}:${String(Math.round(startMinute)).padStart(
        2,
        '0'
      )}`;
      const endTimeFormatted = `${String(endHour).padStart(2, '0')}:${String(Math.round(endMinute)).padStart(2, '0')}`;

      return `${startTimeFormatted} - ${endTimeFormatted}`;
    });

    return timeSlots;
  };

  if (loading || courseLoading) {
    return <Loader />;
  }
  return (
    <div>
      <TutorList />
    </div>
  );
};

export default MeetCreate;
