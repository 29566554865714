import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'; // Import Material icons
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, subMenu } = item;
  const [isOpen, setIsOpen] = useState(false);

  const handleSubMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <StyledNavItem
        to={path}
        component={subMenu ? 'div' : RouterLink}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: 'text.primary',
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
        onClick={subMenu ? handleSubMenuToggle : undefined}
      >
        {icon && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}
        <ListItemText disableTypography primary={title} />
        {subMenu && (
          <span>{isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</span>
        )}
      </StyledNavItem>
      {isOpen && subMenu && (
        <List  sx={{ pl: 4 }}>
          {subMenu.map((subItem) => (
            <NavItem key={subItem.title} item={subItem} />
          ))}
        </List>
      )}
    </>
  );
}
