import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries
import {
  CREATE_ADDPRICE_REQUEST,
  CREATE_ADDPRICE_SUCCESS,
  CREATE_ADDPRICE_FAIL,
  UPDATE_ADDPRICE_REQUEST,
  UPDATE_ADDPRICE_SUCCESS,
  UPDATE_ADDPRICE_FAIL,
  DELETE_ADDPRICE_REQUEST,
  DELETE_ADDPRICE_SUCCESS,
  DELETE_ADDPRICE_FAIL,
  GET_ADDPRICE_REQUEST,
  GET_ADDPRICE_SUCCESS,
  GET_ADDPRICE_FAIL,
  CLEAR_ADDPRICE_ERRORS,
  ALL_ADDPRICE_REQUEST,
  ALL_ADDPRICE_SUCCESS,
  ALL_ADDPRICE_FAIL,
} from '../constants/AddPricesConstants';

export const getAllAddPrices = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_ADDPRICE_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listPricePages));

    const updatedAddPrices = response.data.listPricePages.items || [];

    const updatedData = {
      addPrices: updatedAddPrices,
      addPricesCount: updatedAddPrices?.length,
      resultPerPage: 10,
      filteredAddPricesCount: null,
    };

    dispatch({
      type: ALL_ADDPRICE_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: ALL_ADDPRICE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAddPriceDetails = (addPriceId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ADDPRICE_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getPricePages, { id: addPriceId }));

    const newData = response?.data.getPricePages;
    dispatch({
      type: GET_ADDPRICE_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_ADDPRICE_FAIL,
      payload: errorMessage,
    });
  }
};

export const createAddPrice = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_ADDPRICE_REQUEST });

    const response = await API.graphql({
      query: mutations.createPricePages,

      variables: {
        input: {
          ...formData,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createPricePages;
    toast.success('Price Page Added Successfully!');

    dispatch({
      type: CREATE_ADDPRICE_SUCCESS,
      payload: data,
    });
    toast.success('Created!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('ADDPRICE error', error);

    dispatch({
      type: CREATE_ADDPRICE_FAIL,
      payload: error,
    });
  }
};
export const deleteAddPrice = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ADDPRICE_REQUEST });

    API.graphql({
      query: mutations.deletePricePages,
      variables: { input: { id } },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then(async (response) => {
      toast.success('Price Page Deleted Successfully!');

      console.log(response);
      dispatch({
        type: DELETE_ADDPRICE_SUCCESS,
        payload: true,
      });
    });

    toast.success('Deleted!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_ADDPRICE_FAIL,
      payload: error || 'Failed to fetch CLINIC CARD data',
    });
  }
};
export const updateAddPrice = (addPriceId, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ADDPRICE_REQUEST });

    const data = {
      type: formData?.type,
      price: formData?.price,
    };

    const response = await API.graphql({
      query: mutations.updatePricePages,
      variables: { input: { ...data, id: addPriceId } },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const updatedDetails = response?.data?.updatePricePages;
    toast.success('Price Page Updated Successfully!');
    dispatch({
      type: UPDATE_ADDPRICE_SUCCESS,
      payload: updatedDetails,
    });
    toast.success('Updated!');
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.error('Error updating ADDPRICE:', error);

    dispatch({
      type: UPDATE_ADDPRICE_FAIL,
      payload: error || 'Failed to update ADDPRICE',
    });
  }
};
export const clearAddPricesErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ADDPRICE_ERRORS });
};
