import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  Typography,
  Container,
  Button,
  Grid,
  InputLabel,
  FormHelperText,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../components/Loader';
import useHandlePagintationCampEvents from '../hooks/handlePaginationCampEvents';
import useCityList from '../hooks/getCityList';


const CampDetails = () => {

  const {
    getEventsByCity ,
    loading
  } = useHandlePagintationCampEvents()
  const [selectedCity, setSelectedEvent] = useState('');
  const [eventList, setEventList] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [submitted, setSubmitted] = useState(false);
  const [eventError, setEventError] = useState(null);
  const { cities } = useCityList();


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCityChange = (event) => {
    setSelectedEvent(event.target.value);
    setEventError(null);
  };

  const handleSubmit = async () => {
    if (!selectedCity) {
      setEventError('required');
    } else {
      setEventError('');
      const response = await getEventsByCity(selectedCity);
      console.log('response',response.length)
      setEventList(response);
      setSubmitted(true);
    }
  };
  const buttonStyles = {
    backgroundColor: '#1E90FF', // Set your desired background color
    color: 'white', // Set your desired text color
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Camp Details
      </Typography>
      <Grid container spacing={2} mb={5} alignItems="center">
        <Grid item xs={8}>
          <FormControl fullWidth margin="dense" error={eventError === 'required'}>
            <InputLabel id="event">Select City</InputLabel>
            <Select
              labelId="event"
              label="Select Event"
              name="event"
              fullWidth
              value={selectedCity}
              onChange={handleCityChange}
              sx={{ height: 56 }}
            >
              <MenuItem value="">Select City</MenuItem>
              {cities?.map((event) => (
                <MenuItem key={event.id} value={event.name}>
                  {event.name}
                </MenuItem>
              ))}
            </Select>
            {eventError === 'required' && <FormHelperText>This field is required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={4} display="flex" alignItems="center">
          <Button
            style={buttonStyles}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            sx={{ height: 56 }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      {submitted  && eventList.length == 0 && (
        <Box  display="flex" justifyContent="center"  alignItems="center">
        <Typography variant="body1" color="error">
          No Camp Details Available
        </Typography>
        </Box>
      )}

      {submitted && eventList && eventList.length > 0 && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>City Of Registration</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((camp) => (
                    <TableRow
                      key={camp.id}
                    >
                      <TableCell>{camp.name}</TableCell>
                      <TableCell>
                          {camp.cityOfRegistration}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={eventList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
     
        </>
      )}

    </Container>
  );
};

export default CampDetails;
