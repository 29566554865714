/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const useHandlePagintationAddPrices = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [addPricesList, setAddPricesList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchAddPrices = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        const result = await API.graphql({
          query: queries.listPricePages,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });

        const fetchedAddPrices = result?.data?.listPricePages?.items || [];

        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listPricePages?.nextToken);
        }

        setAddPricesList(fetchedAddPrices);
        setNextNextToken(result?.data?.listPricePages?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching AddPrices:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteAddPrices = async (id) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.deletePricePages,
        variables: {
          input: { id },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });
      console.log(response);
      toast.success('Price Page Deleted Successfully!');
      setLoading(false);

      // Remove deleted AddPrices from the AddPricesList
      setAddPricesList((prevAddPricesList) => prevAddPricesList.filter((item) => item.id !== id));
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting AddPrices:', error);
    }
  };

  const updateAddPrices = async (addPriceId, formData) => {
    try {
      setLoading(true);

      const result = await API.graphql({
        query: queries.listPricePages,
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });

      const fetchedAddPrices = result?.data?.listPricePages?.items || [];

      // Check if the updated type is already present in other records except the current one
      const isTypePresentInOtherRecords = fetchedAddPrices.some(
        (record) => record.type === formData.type && record.id !== addPriceId
      );

      if (isTypePresentInOtherRecords) {
        setLoading(false);
        toast.error('Type already exists.');
        return;
      }

      const data = {
        type: formData?.type,
        price: formData?.price,
      };

      const response = await API.graphql({
        query: mutations.updatePricePages,
        variables: { input: { ...data, id: addPriceId } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedDetails = {
        ...response?.data?.updatePricePages,
      };
      setLoading(false);

      setAddPricesList((prevAddPricesList) => {
        const updatedAddPriceList = prevAddPricesList?.map((item) => {
          if (item.id === addPriceId) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedAddPriceList;
      });

      toast.success('Price Page Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating AddPrices details:', error);
      toast.error('Error updating AddPrices details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const addAddPrice = async (items) => {
    setLoading(true);

    try {
      const result = await API.graphql({
        query: queries.listPricePages,
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });

      const fetchedAddPrices = result?.data?.listPricePages?.items || [];

      // Check if the type is already present
      const isTypePresent = fetchedAddPrices.some((record) => record.type === items.type);

      if (isTypePresent) {
        setLoading(false);
        toast.error('Type already exists.');
        return;
      }

      // Upload the image to S3 using Storage.put
      const updatedData = { ...items };
      const response = await create(updatedData);
      const addPricesData = {
        ...response?.data?.createPricePages,
      };
      setLoading(false);
      setAddPricesList((prevAddPricesList) => [addPricesData, ...prevAddPricesList]); // Add the new ClinicCards data to the events list

      setLoading(false);
      toast.success(`Price Page Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating events:', error);
      throw error;
    }
  };
  const create = async (updatedData) => {
    setLoading(true);

    const response = await API.graphql({
      query: mutations.createPricePages,
      variables: {
        input: {
          ...updatedData,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    return response;
  };
  useEffect(() => {
    fetchAddPrices('');
  }, [nextToken]);

  return {
    addPricesList,
    nextNextToken,
    loading,
    deleteAddPrices,
    fetchNextPage,
    fetchAddPrices,
    fetchPreviousPage,
    previousTokens,
    addAddPrice,
    updateAddPrices,
  };
};

export default useHandlePagintationAddPrices;
