import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const useAuthInfo = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState('');
  const [customAttributes, setCustomAttributes] = useState({});
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  useEffect(() => {
    const fetchAuthInfo = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });

        setIsAuthenticated(true);
        setUserId(user.attributes.sub);
        setCustomAttributes(user.attributes);
        setEmail(user.attributes.email || '');
      } catch (err) {
        setIsAuthenticated(false);
        setUserId('');
        setCustomAttributes({});
        setEmail('');
        console.log(err);
      }
    };

    fetchAuthInfo();
  }, []);

  // Use Object.keys() to iterate over customAttributes
  useEffect(() => {
    Object.keys(customAttributes).forEach((attribute) => {
      if (attribute === 'name') {
        setUserName(customAttributes[attribute]);
      }
    });
  }, [customAttributes]);

  return { isAuthenticated, userId, userName, email };
};

export default useAuthInfo;
