import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from '../../graphql/queries'; // Import your GraphQL queries
import * as mutations from '../../graphql/mutations'; // Import your GraphQL queries

import {
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  LIST_CATEGORIES_REQUEST,
  LIST_CATEGORIES_SUCCESS,
  LIST_CATEGORIES_FAIL,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY_RESET,
  CLEAR_CATEGORY_ERRORS,
} from '../constants/CategoryConstants';

export const getAllCategory = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_CATEGORIES_REQUEST });
    const response = await API.graphql(graphqlOperation(queries.listCategories));

    const data = response.data.listCategories.items || [];
    const updatedCategories = await Promise.all(
      data?.map(async (product) => {
        try {
          return { ...product };
        } catch (error) {
          toast.error('Something Went Wrong!');

          console.error('Error fetching category', error);
          return product;
        }
      })
    );

    const updatedData = {
      categories: updatedCategories,
      categoriesCount: updatedCategories?.length,
      resultPerPage: 10,
      filteredCategoryCount: null,
    };

    dispatch({
      type: LIST_CATEGORIES_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    dispatch({
      type: LIST_CATEGORIES_SUCCESS,
      payload: error,
    });
  }
};

export const getCategoryDetails = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CATEGORY_REQUEST });

    const response = await API.graphql(graphqlOperation(queries.getCategories, { id: categoryId }));

    const data = response?.data.getItem;
    dispatch({
      type: GET_CATEGORY_REQUEST,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;

    dispatch({
      type: GET_CATEGORY_FAIL,
      payload: errorMessage,
    });
  }
};
export const createCategory = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CATEGORY_REQUEST });
    const response = await API.graphql({
      query: mutations.createCategories,

      variables: {
        input: {
          title: formData.name,
        },
      },

      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    const data = response.data.createCategories;

    toast.success('Category Added Successfully!');

    dispatch({
      type: CREATE_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('CATEGORY error', error);

    dispatch({
      type: CREATE_CATEGORY_FAIL,
      payload: error,
    });
  }
};
export const deleteCategory = (id) => async (dispatch) => {
  console.log('ID', id);
  try {
    dispatch({ type: DELETE_CATEGORY_REQUEST });

    API.graphql({
      query: mutations.deleteCategories,
      variables: { input: { id } },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then(async (response) => {
      toast.success('Category Deleted Successfully!');

      console.log(response);
      dispatch({
        type: DELETE_CATEGORY_SUCCESS,
        payload: true,
      });
    });
  } catch (error) {
    toast.error('Something Went Wrong!');

    console.log('Error', error);
    dispatch({
      type: DELETE_CATEGORY_FAIL,
      payload: error || 'Failed to fetch category data',
    });
  }
};

export const clearCategoryErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CATEGORY_ERRORS });
};
