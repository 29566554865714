import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries';

const useLeagueList = () => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        setLoading(true);

        const response = await API.graphql({
          query: queries.listLeagues,
          variables: {
            filter: {
              eventStatus: {
                eq: 'EVENT_NOT_STARTED',
              },
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const leaguesData = response?.data?.listLeagues?.items || [];

        // Fetch players and count registered players for specific teams
        const playersResponse = await API.graphql({
          query: queries.listPlayers,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const players = playersResponse?.data?.listPlayers?.items || [];

        // Filter leagues based on registered players for specific teams and event
        const filteredLeagues = leaguesData.filter((league) => {
          const team1 = league?.teamsName[0]; // Replace with your team names
          const team2 = league?.teamsName[1];
          const team1Players = players?.filter(
            (player) => player?.teamName === team1 && player?.league === league?.title
          );
          const team2Players = players.filter(
            (player) => player?.teamName === team2 && player?.league === league?.title
          );

          return team1Players?.length < 4 || team2Players.length < 4;
        });

        setLeagues(filteredLeagues);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching leagues:', error);
      }
    };

    fetchLeagues();
  }, []);

  return { leagues };
};

export default useLeagueList;
