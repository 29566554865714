import {
  CREATE_CLINICCOURSE_REQUEST,
  CREATE_CLINICCOURSE_SUCCESS,
  CREATE_CLINICCOURSE_FAIL,
  UPDATE_CLINICCOURSE_REQUEST,
  UPDATE_CLINICCOURSE_SUCCESS,
  UPDATE_CLINICCOURSE_FAIL,
  DELETE_CLINICCOURSE_REQUEST,
  DELETE_CLINICCOURSE_SUCCESS,
  DELETE_CLINICCOURSE_FAIL,
  GET_CLINICCOURSE_REQUEST,
  GET_CLINICCOURSE_SUCCESS,
  GET_CLINICCOURSE_FAIL,
  CLEAR_CLINICCOURSE_ERRORS,
  ALL_CLINICCOURSE_REQUEST,
  ALL_CLINICCOURSE_SUCCESS,
  ALL_CLINICCOURSE_FAIL,
} from '../constants/ClinicCourseConstants';

const initialState = {
  clinicCourses: [],
  clinicCourseCount: 0,
  resultPerPage: 0,
  course: {},
  isUpdated:false,
  loading: false,
  error: null,
  filteredClinicCoursesCount: 0,
};

export const clinicCoursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CLINICCOURSE_REQUEST:
    case GET_CLINICCOURSE_REQUEST:
    case CREATE_CLINICCOURSE_REQUEST:
    case DELETE_CLINICCOURSE_REQUEST:
    case UPDATE_CLINICCOURSE_REQUEST:
      return {
        ...state,
        loading: true,
        clinicCourses: [],
        course: {},
      };
    case ALL_CLINICCOURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        clinicCourses: action.payload.clinicCourses,
        clinicCourseCount: action.payload.clinicCoursesCount,
        resultPerPage: action.payload.resultPerPage,
        filteredClinicCoursesCount: action.payload.filteredClinicCoursesCount,
      };
    case CREATE_CLINICCOURSE_SUCCESS:
    case GET_CLINICCOURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        course: action.payload,
      };
    case DELETE_CLINICCOURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_CLINICCOURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case GET_CLINICCOURSE_FAIL:
    case ALL_CLINICCOURSE_FAIL:
    case CREATE_CLINICCOURSE_FAIL:
    case UPDATE_CLINICCOURSE_FAIL:
    case DELETE_CLINICCOURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_CLINICCOURSE_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



