import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
// Import the toast styles
import { toast } from 'react-toastify';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import useDeleteDataFromS3 from './deleteDataFromS3';
import deleteFile from '../utils/deleteFile';
// import useGetLeaguesPlayerDetails from "./getLeaguesPlayerDetails";

const useDeleteEvent = () => {
  const [loading, setLoading] = useState(false);
  const { VideoDelete } = useDeleteDataFromS3();
  // const {}=useGetLeaguesPlayerDetails()
  // Add loading state

  const deleteEvent = async (id) => {
    try {
      setLoading(true); // Set loading to true when fetching data
      const res = await API.graphql(graphqlOperation(queries.getLeague, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const imgUrl = res?.data?.getLeague?.img_url;
      const isDeleted = await deleteFile(imgUrl);

      if (isDeleted) {
        const response = await API.graphql({
          query: mutations.deleteLeague,
          variables: {
            input: { id },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        console.log(response);
        toast.success('Event Deleted Successfully!');
      } else {
        toast.error('Something went wrong');
      }

      setLoading(false); // Set loading to false when data is fetched or in case of an error
      // Show success toast
    } catch (error) {
      console.error('Error Deleting Event :', error);
      toast.error('Something Went Wrong: Check Console');

      setLoading(false); // Set loading to false in case of an error
      // Show error toast
    }
  };

  // Call the deleteEvent function when the component mounts

  return { deleteEvent, loading }; // Include loading state in the return value
};

export default useDeleteEvent;
