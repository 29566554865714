import { Auth, API } from 'aws-amplify';

let nextToken;

export async function listUsers(users, limit) {
  const apiName = 'adminApiForSfbayarea';
  const path = '/admin/listUsers';
  try {
    const myInit = {
      queryStringParameters: {
        users,
        limit,
        token: nextToken,
      },
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    nextToken = NextToken;
    return rest;
  } catch (error) {
    console.log('Error Fething User', error);
    throw new Error(error);
  }
}
