import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries'; // Import your GraphQL queries for listing teams

function useGetSubscribedUsers() {
  const [subscribedUsers, setSubscribedUsers] = useState([]);
  const [notExportedUsers, setNotExportedUsers] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchSubscribedUsers() {
      try {
        // Make the GraphQL API call to fetch the list of teams
        const response = await API.graphql({
          query: queries.listSubscribedUsers, // Replace with the appropriate GraphQL query for listing teams
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const teamData = response?.data?.listSubscribedUsers?.items;

        const notExportedUsers = response?.data?.listSubscribedUsers?.items?.filter((user) => user.exported === null);

        setSubscribedUsers(teamData);
        setNotExportedUsers(notExportedUsers);

        setLoading(false);
      } catch (error) {
        toast.error('Something Went Wrong!');

        setError(error);
        setLoading(false);
      }
    }

    fetchSubscribedUsers();
  }, []);

  return { notExportedUsers, subscribedUsers, loading, error };
}

export default useGetSubscribedUsers;
