import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// utils
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from "react-redux";
import Iconify from '../../../components/iconify';
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import Loader from '../../../components/Loader';
import AddSessionSideBar from './AddSessionSideBar';
import useDeleteSession from '../../../hooks/deleteSession';
import { getAllSessions } from "../../../redux/actions/SessionAction";

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

SessionCard.propTypes = {
  sessions: PropTypes.object,
};
function formatDate(date) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  // Get day, month, and year components from the date
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  // Format the date as "dd-mm-yyyy"
  return `${day}-${month}-${year}`;
}

export default function SessionCard({sessions,updateSession,deleteSession }) {
  const dispatch = useDispatch();

  const { 
    title,
    type,
    description,
    agegroup,
    price,
    location,
    startingDate,
    endingDate,
    lastRegistrationDate,
    id,
  } = sessions;


  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleEditClick = () => {
    setEditData(sessions);
    setIsEditDialogOpen(true);
  };
  const handleDeleteConfirm = async () => {

    await deleteSession(id);
    dispatch(getAllSessions());
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  // Assuming createdAt is a Date object or a string date in a valid format
  const formattedDate = formatDate(startingDate);
  const endingDateFormat = formatDate(endingDate);
  const lastRegistrationDateFormat = formatDate(lastRegistrationDate);

  return (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        </Link>
        <Typography variant="subtitle2" noWrap>
            {type}
          </Typography>
        <Typography variant="subtitle2" noWrap>
          ${price}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          {agegroup}
        </Typography>
        <Typography variant="subtitle2" color="inherit" noWrap>
          <LocationOnIcon /> {location}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          Description:  {description}
        </Typography>

        <Typography variant="subtitle2" noWrap>
          <EventIcon /> Starting Date {formattedDate}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          <EventIcon /> Ending Date {endingDateFormat}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          <EventIcon /> Registration Date {lastRegistrationDateFormat}
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">

          <EditButton onEditClick={handleEditClick} />
          {isEditDialogOpen && (
            <AddSessionSideBar
             hidden
              openSession={isEditDialogOpen}
              onCloseSession={() => setIsEditDialogOpen(false)}
              editData={editData}
              updateSession={updateSession}
            />
          )}
          <DeleteButton onDeleteClick={handleDeleteClick} />
        </Stack>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Session</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Session?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Card>
  );
}

const DeleteButton = ({ onDeleteClick }) => {
  return (
    <Button color="primary" onClick={onDeleteClick}>
      <Iconify color="red" icon="fluent:delete-12-regular" />
    </Button>
  );
};
const EditButton = ({ onEditClick }) => {
  return (
    <Button onClick={onEditClick}>
      <EditIcon color="primary" style={{ fontSize: '18px' }} />
    </Button>
  );
};