import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { getKey } from '../utils/getKey';
import { allImage, getNonMatchingValues, ReplaceUrl, updateImageSrcInHtml } from '../utils/replaceUrl';
import uploadFile from '../utils/uploadFile';
import deleteFile from '../utils/deleteFile';
import getLink from '../utils/getLinks';

const useHandlePagintationActivities = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [activitiesList, setActivitiesList] = useState([]);
  const [activity, setActivity] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchActivities = async () => {
    setLoading(true);

    let variables;
    try {
      variables = {
        limit: 10,
      };

      if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
        variables = {
          ...variables,
          nextToken,
        };
      }

      const result = await API.graphql({
        query: queries.listActivities,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
      });

      const fetchedActivities = result?.data?.listActivities?.items || [];

      const updateActivityCards = await Promise.all(
        fetchedActivities?.map(async (item) => {
          const description = await updateImageSrcInHtml(item?.description, getLink, getKey);
          item.description = description;

          return item;
        })
      );

      if (!initialNextNextToken) {
        setInitialNextNextToken(result?.data?.listActivities?.nextToken);
      }

      setActivitiesList(updateActivityCards);
      setNextNextToken(result?.data?.listActivities?.nextToken);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Activities:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteActivities = async (id) => {
    try {
      setLoading(true);
      const oldResponse = await API.graphql(graphqlOperation(queries.getActivities, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const oldData = oldResponse.data.getActivities;

      const allOldImageKey = await allImage(oldData.description, getKey);

      allOldImageKey.map(async (key, index) => {
        await deleteFile(key);
      });

      const response = await API.graphql({
        query: mutations.deleteActivities,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      console.log(response);
      toast.success('Activity Deleted Successfully!');
      setLoading(false);

      // Remove deleted  from the List
      setActivitiesList((prevActivitiesList) => prevActivitiesList.filter((item) => item.id !== id));
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Activities:', error);
    }
  };
  const getActivityById = async (id) => {
    setLoading(true);
    try {
      const response = await API.graphql(graphqlOperation(queries.getActivities, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const activityCard = response?.data?.getActivities;

      const description = await updateImageSrcInHtml(activityCard?.description, getLink, getKey);
      activityCard.description = description;

      console.log('actiovitititi', activityCard);
      setActivity(activityCard);

      setLoading(false);
      return activityCard;
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Activity by ID:', error);
      throw error;
    }
  };
  const updateActivities = async (formData) => {
    try {
      setLoading(true);
      const data = {
        title: formData?.title,
        type: formData?.type,
        description: formData?.description,
      };
      const oldResponse = await API.graphql(graphqlOperation(queries.getActivities, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const oldData = oldResponse.data.getActivities;

      console.log('oldData', oldData);

      const allOldImageKey = await allImage(oldData.description, getKey);
      const newImageKey = await allImage(data.description, getKey);

      const { notInNew: deletedImageKey } = getNonMatchingValues(allOldImageKey, newImageKey);

      deletedImageKey?.map(async (key, index) => {
        await deleteFile(key);
      });

      const response = await API.graphql({
        query: mutations.updateActivities,
        variables: { input: { ...data, id: formData.id } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedDetails = {
        ...response?.data?.updateActivities,
      };
      setLoading(false);

      setActivitiesList((prevActivitiesList) => {
        const updatedActivityList = prevActivitiesList?.map((item) => {
          if (item.id === formData.id) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedActivityList;
      });

      toast.success('Activity Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating Activities details:', error);
      toast.error('Error updating Activities details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const addActivity = async (items) => {
    setLoading(true);

    try {
      setLoading(true);

      // Upload the image to S3 using Storage.put
      const updatedData = { ...items };
      const response = await create(updatedData);
      const activitiesData = {
        ...response?.data?.createActivities,
      };
      setLoading(false);
      setActivitiesList((prevActivitiesList) => [activitiesData, ...prevActivitiesList]); // Add the new Activities data to the events list

      setLoading(false);
      toast.success(`Activity Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating events:', error);
      throw error;
    }
  };
  const create = async (updatedData) => {
    setLoading(true);

    const response = await API.graphql({
      query: mutations.createActivities,
      variables: {
        input: {
          ...updatedData,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    return response;
  };
  useEffect(() => {
    fetchActivities('');
    if (id) {
      getActivityById(id);
    }
  }, [nextToken, id]);

  return {
    activitiesList,
    nextNextToken,
    loading,
    deleteActivities,
    fetchNextPage,
    fetchActivities,
    fetchPreviousPage,
    previousTokens,
    addActivity,
    updateActivities,
    activity,
    getActivityById,
  };
};

export default useHandlePagintationActivities;
