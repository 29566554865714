import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const buttonStyles = {
  backgroundColor: '#1E90FF', // Set your desired background color
  color: 'white', // Set your desired text color
};

const ModalTwo = ({ title, handleClose, handleInputChange, handleAdd, buttonText, inputLabel }) => {
  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={inputLabel}
          fullWidth
          required
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: '16px' }}>
        <Button onClick={handleClose} variant="outlined" color="secondary" style={{ marginRight: 'auto' }}>
          Cancel
        </Button>
        <Button onClick={handleAdd} style={buttonStyles} color="primary" variant="contained">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalTwo;
