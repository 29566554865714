import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import useGetLink from './getImageLink';
import uploadFile from '../utils/uploadFile';
import deleteFile from '../utils/deleteFile';
import getLink from '../utils/getLinks';

const useHandleTutor = () => {
  const [tutorList, setTutorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [previousTokens, setPreviousTokens] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const createTutor = async (tutorInput) => {
    try {
      setIsLoading(true);
      setError(null);
      if (!tutorInput.profile || !tutorInput.bio || !tutorInput.name) {
        toast.error('Not Enough Details');
        return;
      }

      const profile = await uploadFile(tutorInput.profile);

      const data = {
        name: tutorInput.name,
        bio: tutorInput.bio,
        profile,
      };

      const response = await API.graphql({
        query: mutations.createTutor,
        variables: { input: data },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      console.log('response', response.data.createTutor);
      const createdTour = response.data.createTutor;
      console.log('createdTour', createdTour);
      createdTour.oldProfile = createdTour.profile;
      createdTour.profile = await getLink(createdTour.profile);
      setTutorList([...tutorList, createdTour]);
      toast.success('Tutor created successfully!');
      return;
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTutor = async (tutorInput, id) => {
    try {
      setIsLoading(true);
      console.log('UPDATE TOUR', tutorInput);
      setError(null);
      if (tutorInput.newImage) {
        const deleted = await deleteFile(tutorInput.profile);
        if (!deleted) {
          toast.error('Image not Deleted');
          return;
        }
        const profile = await uploadFile(tutorInput.newImage);
        tutorInput.profile = profile;
        delete tutorInput.newImage;
      }
      const data = {
        profile: tutorInput?.profile,
        bio: tutorInput?.bio,
        name: tutorInput?.name,
      };
      const response = await API.graphql({
        query: mutations.updateTutor,
        variables: { input: { id, ...data } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const createdTour = response.data.updateTutor;
      console.log('CREATED TOUR', createdTour, response.data.updateTutor);
      createdTour.oldProfile = createdTour.profile;

      createdTour.profile = await getLink(createdTour.profile);

      setTutorList((prevTutors) => [...prevTutors.filter((tutor) => tutor.id !== createdTour.id), createdTour]); // Update tutor in list
      toast.success('Tutor updated successfully!');
      return;
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTutor = async (data, id) => {
    try {
      setIsLoading(true);
      setError(null);
      const deleted = await deleteFile(data.oldProfile);
      if (!deleted) {
        toast.error('Image not Deleted');

        return;
      }
      await API.graphql({
        query: mutations.deleteTutor,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      toast.success('Tutor deleted successfully!');

      setTutorList(tutorList?.filter((tut) => tut.id !== id));
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllTutors = async ({ productName = '' }) => {
    setIsLoading(true);
    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          name: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        // }
        const response = await API.graphql({
          query: queries.listTutors,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const tutors = response.data.listTutors.items;

        const updatedTutors = await Promise.all(
          tutors.map(async (tutor) => {
            tutor.oldProfile = tutor.profile;
            tutor.profile = await getLink(tutor.profile);
            return tutor;
          })
        );

        if (!initialNextNextToken) {
          setInitialNextNextToken(response?.data?.listTutors?.nextToken);
        }
        console.log('initialNextNextToken', initialNextNextToken);
        setTutorList([...updatedTutors]);
        setNextNextToken(response?.data?.listTutors?.nextToken);
        console.log('nextNextToken', nextNextToken);

        setIsLoading(false);

        return updatedTutors;
      }
    } catch (err) {
      setError(err.message);
      console.error(err);
      return [];
    } finally {
      setIsLoading(false);
    }
  };
  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };
  useEffect(() => {
    getAllTutors('');
  }, [nextToken]);

  return {
    tutorList,
    isLoading,
    error,
    createTutor,
    updateTutor,
    deleteTutor,
    nextNextToken,
    fetchNextPage,
    fetchPreviousPage,
    previousTokens,
  };
};

export default useHandleTutor;
