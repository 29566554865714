import { useState, useEffect } from 'react'; // Remove the unused import
import { API } from 'aws-amplify'; // Remove the unused import
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries';

const useVolunteersList = () => {
  const [volunteersList, setGetVolunteerUserList] = useState([]); // Use an array to store multiple volunteersList
  const [modifiedvVolunteersList, setModifiedGetVolunteerUserList] = useState([]); // Use an array to store multiple volunteersList
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVolunteersList = async () => {
      try {
        setLoading(true);
        const response = await API.graphql({
          query: queries.listVolunteers,

          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const voluenteers = response?.data?.listVolunteers?.items?.map((user) => ({
          ...user,

          // Add other attributes you want to include
        }));
        const modifiedVolunteers = response?.data?.listVolunteers?.items?.map((user) => ({
          ...user,
          name: user?.participantName,
          email: user?.email,
          isVerified: true,
          status: 'Confirmed',
          zone: `Volunteer`,

          // Add other attributes you want to include
        }));
        setModifiedGetVolunteerUserList(modifiedVolunteers);
        setGetVolunteerUserList(voluenteers);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error('Error fetching Volunteers List:', error);
      }
    };

    fetchVolunteersList();
  }, []); // Empty dependency array, meaning this effect will run only once after the component mounts

  return { volunteersList, modifiedvVolunteersList, loading };
};

export default useVolunteersList;
