import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const useHandlePaginationCourse = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [courseList, setCourseList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchCourse = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          title: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        // }

        const result = await API.graphql({
          query: queries.listTrainingProgramCourses,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });

        const fetchedCourses = result?.data?.listTrainingProgramCourses?.items || [];

        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listTrainingProgramCourses?.nextToken);
        }

        setCourseList(fetchedCourses);
        setNextNextToken(result?.data?.listTrainingProgramCourses?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Course:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteCourses = async (id) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.deleteTrainingProgram,
        variables: { input: { id } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      console.log(response);
      toast.success('Course Deleted Successfully!');
      setLoading(false);

      // Remove deleted Course from the CourseList
      setCourseList((prevCourseList) => prevCourseList.filter((item) => item.id !== id));
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Course:', error);
    }
  };

  const updateCourses = async (courseId, formData) => {
    try {
      setLoading(true);
      const data = {
        title: formData?.title,
        price: formData?.price,
        description: formData?.description,
        quantity: formData?.quantity,
        calendlyLink: formData?.calendlyLink,
      };

      const response = await API.graphql({
        query: mutations.updateTrainingProgramCourse,
        variables: { input: { ...data, id: courseId } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const updatedDetails = {
        ...response?.data?.updateTrainingProgramCourse,
      };
      setLoading(false);

      setCourseList((prevCourseList) => {
        const updatedCourseList = prevCourseList?.map((item) => {
          if (item.id === courseId) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedCourseList;
      });

      toast.success('Course Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating Course details:', error);
      toast.error('Error updating Course details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const addCourse = async (items) => {
    setLoading(true);

    try {
      setLoading(true);

      // Upload the image to S3 using Storage.put
      const updatedData = { ...items };
      const response = await create(updatedData);
      const coursesData = {
        ...response?.data?.createTrainingProgramCourse,
      };
      setLoading(false);
      setCourseList((prevCourseList) => [coursesData, ...prevCourseList]); // Add the new  data to the events list

      setLoading(false);
      toast.success(`Course Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating events:', error);
      throw error;
    }
  };
  const create = async (updatedData) => {
    setLoading(true);

    const response = await API.graphql({
      query: mutations.createTrainingProgramCourse,
      variables: {
        input: {
          ...updatedData,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
    return response;
  };
  useEffect(() => {
    fetchCourse('');
  }, [nextToken]);

  return {
    courseList,
    nextNextToken,
    loading,
    deleteCourses,
    fetchNextPage,
    fetchCourse,
    fetchPreviousPage,
    previousTokens,
    addCourse,
    updateCourses,
  };
};

export default useHandlePaginationCourse;
