/* eslint-disable no-else-return */
import { useState } from 'react';
import { API, Storage } from 'aws-amplify';
import { toast } from 'react-toastify';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import uploadFile from '../utils/uploadFile';
import * as mutation from '../graphql/mutations';
import * as queries from '../graphql/queries';

const useAddAdvertisement = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [imageLink, setImageLink] = useState('');

  const createAdvertisement = async (data) => {
    // Updated the parameter
    try {
      setLoading(true);

      const response = await API.graphql({
        query: queries.listAdvertisements,
        variables: {
          filter: {
            page: {
              contains: data?.page,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const index = response.data.listAdvertisements.items.length;
      if (data?.type === 'ADVERTISEMENT') {
        const res = await updatePages(data?.page, index);
        console.log('adsresponse', res, index, data?.page);
        if (res === false) {
          return false;
        }
      }

      const page = data.page + index;

      console.log('response', index, page);

      const lgKey = await uploadFile(data?.lg_img_url);
      const mdKey = await uploadFile(data?.md_img_url);
      const xsKey = await uploadFile(data?.xs_img_url);
      const smKey = await uploadFile(data?.sm_img_url);
      const updatedData = {
        type: data.type,
        lg_img_url: lgKey,
        md_img_url: mdKey,
        sm_img_url: xsKey,
        xs_img_url: smKey,
        page: data.page,
      };
      setData(updatedData);
      await create(updatedData);
      setLoading(false);
      return updatedData; // Return the created news data
    } catch (error) {
      console.error('Error Adding Advertisement:', error);
      setLoading(false);
      throw error; // Rethrow the error for handling in the component
    }
  };
  const create = async (updatedData) => {
    setLoading(true);

    console.log('uploading data', updatedData);
    const response = await API.graphql({
      query: mutation.createAdvertisement,
      variables: {
        input: {
          ...updatedData,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    // Check if the signup was successful
    toast.success('Advertisement Added Successfully!');
    console.log('Response', response);
    setLoading(false);
  };

  const updatePages = async (page, index) => {
    let result = true;
    console.log('pageindex', page, index);

    if (page === 'EVENTS') {
      const response = await API.graphql({
        query: queries.listLeagues,

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const listLeagues = response.data.listLeagues.items.length || 0;
      console.log('listLeagues', listLeagues, index, index >= listLeagues + 1);

      if (index >= listLeagues + 1) {
        toast.error('Page Does Not Have Space');
        result = false;
        return false;
      } else {
        toast.success(`${listLeagues + 1 - index - 1} Ads Left In This Page`);
        return true;
      }
    } else if (page === 'BLOG' || page === 'VLOG' || page === 'NEWS') {
      const response = await API.graphql({
        query: queries.listNews,

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const newslist = response.data?.listNews?.items.filter((item) => item.type === 'NEWS');
      const blogslist = response.data?.listNews?.items.filter((item) => item.type === 'BLOG');
      const vlogslist = response.data?.listNews?.items.filter((item) => item.type === 'VLOG');
      console.log('vlogslist', vlogslist);
      let listNews = 1;
      if (page === 'NEWS') {
        listNews += newslist.length;
      } else if (page === 'BLOG') {
        listNews += blogslist.length;
      } else {
        listNews += vlogslist.length;
        console.log('vlogslist', vlogslist, listNews, index);
      }
      console.log('vlogslistTrue', index / 3 >= listNews, index / 3, listNews);

      if (index >= listNews / 3) {
        toast.error('Page Does Not Have Space');
        result = false;

        return false;
      } else {
        toast.success(`${listNews / 3 <= 0 ? '0' : (listNews / 3).toFixed(1)} Ads Left In This Page`);
        return true;
      }
    } else if (page === 'HOME' || page === 'ABOUT' || page === 'RULES' || page === 'CONTACT' || page === 'STORE') {
      const listHome = 1;
      // if(page === 'HOME'){

      // }
      if (index >= listHome) {
        toast.error('Page Does Not Have Space');
        result = false;

        return false;
      } else {
        toast.success(`${listHome - 1} Ads Left In This Page`);
        return true;
      }
    } else if (
      page === 'CLINICS' ||
      page === 'TOURNAMENT' ||
      page === 'TRAININGPROGRAM' ||
      page === 'PARTNER' ||
      page === 'PLAYER' ||
      page === 'ICON' ||
      page === 'CAMP' ||
      page === 'LEAGUETEAM' ||
      page === 'LOGIN' ||
      page === 'LEAGUEPLAYER' ||
      page === 'LEAGUECITY' ||
      page === 'LEAGUEINFO' ||
      page === 'REGISTER'
    ) {
      const listPage = 2;
      if (index >= listPage) {
        toast.error('Page Does Not Have Space');
        result = false;

        return false;
      } else {
        toast.success(`${listPage - 1} Ads Left In This Page`);
        return true;
      }
    }

    return result;
  };

  // const upload = async (file, data) => {
  //   const image = await upload(file);
  //   await create(image, data);
  //   return image;
  // };

  // const uploadProfilePicture = async (file, updatedData) => {
  //   try {
  //     setLoading(true);

  //     // Check if the file type is an image (e.g., jpg, png, gif)
  //     if (file.type.includes('image/')) {
  //       const link = await Storage.put(file.name, file, {
  //         level: 'public',
  //         contentType: file.type,
  //       });
  //       const signedUrl = await Storage.get(link, {
  //         level: 'public',
  //         download: false,
  //       });
  //       console.log('Profile URL - ', link.key);
  //       setImageLink(link?.key);
  //       setLoading(false);
  //       await create(link?.key, updatedData);
  //       // return link?.key;
  //     }
  //   } catch (error) {
  //     console.error('Error uploading file: ', error);
  //     setLoading(false);
  //   }
  // };
  return { createAdvertisement, loading };
};

export default useAddAdvertisement;
