import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries"; // Make sure to import your GraphQL queries


const useItemById = () => {

    const fetchItems = async (itemId) => {
      try {
      
        // Iterate through the item IDs in the user's cart and fetch their details
     
          const response = await API.graphql(
            graphqlOperation(queries.getItem, { id: itemId })
          );


        // Wait for all promises to resolve
       
        return response;

      } catch (error) {
        console.error("Error fetching items:", error);
        throw error;  // Optionally rethrow the error to handle it in the calling code
      }
    };

 

  return {fetchItems};
};

export default useItemById;
