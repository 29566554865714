/* eslint-disable */
import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries'; // Import your GraphQL queries for listing teams

function useGetTeams() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function fetchTeams() {
    try {
      // Make the GraphQL API call to fetch the list of teams
      // const response = await API.graphql({
      //   query: queries.listTeams, // Replace with the appropriate GraphQL query for listing teams
      //   authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      const response = await API.graphql(graphqlOperation(queries.listTournamentTeams));
      const teamData = response?.data?.listTournamentTeams?.items;

      setTeams(teamData);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function fetchTeamsByEvents(eventId) {
    try {
      // Make the GraphQL API call to fetch the list of teams
      // const response = await API.graphql({
      //   query: queries.listTeams, // Replace with the appropriate GraphQL query for listing teams
      //   authMode:GRAPHQL_AUTH_MODE.API_KEY
      // });
      const response = await API.graphql({
        query: queries.listTournamentTeams,
        variables: {
          filter: {
            eventId: {
              eq: eventId,
            },
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      const teamData = response?.data?.listTournamentTeams?.items;

      setTeams(teamData);
      setLoading(false);
      return teamData;
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTeams();
  }, []);

  return { teams, fetchTeamsByEvents, loading, error, fetchTeams };
}

export default useGetTeams;
