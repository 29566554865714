import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Box } from  '@mui/material';
import Partner from '../components/signup/WorkWithUs';
import League from '../components/signup/League';
import Event from '../components/signup/Event';
import Volunteer from '../components/signup/Volunteer';
import Clinic from '../components/signup/Clinic';
import Tournament from '../components/signup/Tournament';
import TrainingProgram from '../components/signup/TrainingProgram';
import Camp from '../components/signup/Camp';
import User from '../components/signup/User';
import useGetLeaguesPlayerDetails from '../hooks/getLeaguesPlayerDetails';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';

const EditPlayer = () => {
  return (
    <Box display="flex" mb={5}>
      <DashboardLayout />
      <Box mt={10} width="100%">
        {/* Replace with the component you want to render */}
        {/* <Partner /> */}
        {/* <Event /> */}
        <League />
        {/* <User /> */}
          {/* <Clinic/> */}
        {/* <Tournament/> */}
        {/* <TrainingProgram/> */}
        {/* <Camp/> */}
        {/* <Volunteer /> */}
      </Box>
    </Box>
  );
};

export default EditPlayer;
