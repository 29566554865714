/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import getLink from '../utils/getLinks';
import deleteFile from '../utils/deleteFile';
import * as queries from '../graphql/queries';
import uploadFile from '../utils/uploadFile';
import * as mutations from '../graphql/mutations';

const useFetchAdvertisement = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [adList, setAdList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({ timestamp: Date.now(), imageUrl: signedUrl });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error('Error fetching or caching image:', error);
      return null;
    }
  };

  const fetchAd = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          type: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        // }

        const result = await API.graphql({
          query: queries.listAdvertisements,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });

        const fetchedProduct = result?.data?.listAdvertisements?.items || [];
        const updatedAds = await Promise.all(
          fetchedProduct.map(async (product) => {
            try {
              const cachedImgUrl = await fetchImageFromCacheWithExpiration(product?.sm_img_url);

              let img_link = cachedImgUrl;

              if (!cachedImgUrl) {
                img_link = await loadImageAndCache(product?.sm_img_url);
              }

              const dataWithImageUrl = {
                ...product,
                img_link,
              };

              return { ...dataWithImageUrl };
            } catch (error) {
              console.error('Error fetching image URL', error);
              return product;
            }
          })
        );
        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listAdvertisements?.nextToken);
        }

        setAdList([...updatedAds]);
        setNextNextToken(result?.data?.listAdvertisements?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Ad:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteAd = async (id) => {
    try {
      setLoading(true);
      const res = await API.graphql(graphqlOperation(queries.getAdvertisement, { id }), {
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const isDeletedLg = await deleteFile(res?.data?.getAdvertisement?.lg_img_url);
      const isDeletedMd = await deleteFile(res?.data?.getAdvertisement?.md_img_url);
      const isDeletedSm = await deleteFile(res?.data?.getAdvertisement?.sm_img_url);
      const isDeletedXs = await deleteFile(res?.data?.getAdvertisement?.xs_img_url);

      if (isDeletedLg && isDeletedMd && isDeletedSm && isDeletedXs) {
        const response = await API.graphql({
          query: mutations.deleteAdvertisement,
          variables: { input: { id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        console.log(response);
        toast.success('Advertisement Deleted Successfully!');
        setLoading(false);

        // Remove deleted product from the adList
        setAdList((prevAdList) => prevAdList.filter((item) => item.id !== id));

        // Remove deleted images from local storage
        localStorage.removeItem(res?.data?.getAdvertisement?.img_url);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Product:', error);
    }
  };

  const updateAdDetails = async (adId, formData) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: mutations.updateAdvertisement,
        variables: { input: { ...formData, id: adId } },

        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const cachedImgUrl = await fetchImageFromCacheWithExpiration(formData?.sm_img_url);

      let img_link = cachedImgUrl;

      if (!cachedImgUrl) {
        img_link = await loadImageAndCache(formData?.sm_img_url);
      }

      const data = {
        ...response?.data?.updateAdvertisement,
        img_link,
      };

      setAdList((prevAdList) => {
        const updatedAdsList = prevAdList?.map((item) => {
          if (item.id === adId) {
            return { ...item, ...data };
          }
          return item;
        });
        return updatedAdsList;
      });

      toast.success('Advertisement Updated Successfully!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error updating Advertisement details:', error);
      toast.error('Error updating Advertisement details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const createAdvertisements = async (formData) => {
    setLoading(true);

    try {
      const lgImageUrl = await uploadFile(formData.lg_img_url);
      const mdImageUrl = await uploadFile(formData.md_img_url);
      const smImageUrl = await uploadFile(formData.sm_img_url);
      const xsImageUrl = await uploadFile(formData.xs_img_url);
      const response = await API.graphql({
        query: mutations.createAdvertisement,

        variables: {
          input: {
            ...formData,
            lg_img_url: lgImageUrl,
            md_img_url: mdImageUrl,
            sm_img_url: smImageUrl,
            xs_img_url: xsImageUrl,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const img_link = await fetchImageFromCacheWithExpiration(smImageUrl);

      const productData = {
        ...response?.data?.createAdvertisement,
        img_link,
      };
      // Check if the team was successfully created
      setLoading(false);
      setAdList((prevAdList) => [productData, ...prevAdList]); // Add the new product data to the product list

      // Fetch product URL from AWS and update adList
      // Implement fetchAdUrlFromAWS to retrieve product data

      setLoading(false);
      toast.success(`Advertisement Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating product:', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchAd('');
  }, [nextToken]);

  return {
    adList,
    nextNextToken,
    loading,
    deleteAd,
    fetchNextPage,
    fetchAd,
    fetchPreviousPage,
    previousTokens,
    createAdvertisements,
    updateAdDetails,
  };
};

export default useFetchAdvertisement;
