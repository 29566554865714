import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import useGetImage from '../../../hooks/getImageLink';
import ShopEventCard from './EventCard';
import Loader from '../../../components/Loader';

// ----------------------------------------------------------------------

EventList.propTypes = {
  events: PropTypes.array.isRequired,
};

export default function EventList({ events, loading, updateEvents, deleteEvents, ...other }) {
  // const [imageLoading, setImageLoading] = useState(false);
  // const [updateEvent, setEvent] = useState([]);
  // const { getLink, loading:loadingImage } = useGetImage();

  // console.log('events', events);

  // useEffect(() => {
  //   setImageLoading(true);
  //   const fetchData = async () => {
  //     try {
  //       const updatedEvents = await Promise.all(
  //         events?.map(async (event) => {
  //           try {
  //             const signedUrl = await getLink(event?.img_url);
  //             return { ...event, img_url: signedUrl };
  //           } catch (error) {
  //             console.error('Error fetching image URL', error);
  //             return event;
  //           }
  //         })
  //       );

  //       setEvent(updatedEvents);
  //     } catch (error) {
  //       console.error('Error fetching data', error);
  //     } finally {
  //       setImageLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [events]);

  // if (loadingImage || loading) {
  //   return <Loader />;
  // }

  return (
    <Grid container spacing={3} {...other}>
      {events?.map((event) => (
        <Grid key={event.id} item xs={12} sm={6} md={3}>
          <ShopEventCard
            updateEvents={updateEvents}
            deleteEvents={deleteEvents}
            event={event}
         
          />
        </Grid>
      ))}
    </Grid>
  );
}
