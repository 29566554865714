import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Container, TextField, Button, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import useGetAllCity from '../hooks/getCityList';
import useHandleMokeData from '../hooks/handleMokeData';
import Loader from '../components/Loader';

const MockData = () => {
  const { createTournamentData, loading } = useHandleMokeData();
  const [tournamentType, setTournamentType] = useState('');
  const [title, setTitle] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  const [city, setCity] = useState('');
  const [numberOfTeams, setNumberOfTeams] = useState('');
  const { cities } = useGetAllCity();
  
  const ages = [
    { value: "u7", label: "U7" },
    { value: "u8", label: "U8" },
    { value: "u9", label: "U9" },
    { value: "u10", label: "U10" },
    { value: "u11", label: "U11" },
    { value: "u12", label: "U12" },
    { value: "u13", label: "U13" },
    { value: "u14", label: "U14" },
    { value: "u15", label: "U15" },
    { value: "u17", label: "U17" },
    { value: "u18", label: "U18" },
    { value: "u19-u39", label: "U19-U39" },
    { value: "over u40", label: "Over U40" }
  ];

  const buttonStyles = {
    backgroundColor: '#1E90FF',
    color: 'white',
  };
  const handleSubmit = () => {
    createTournamentData(tournamentType, title, ageGroup, city, numberOfTeams);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Container>
      <ToastContainer />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="tournament-type-label">Tournament Type</InputLabel>
            <Select
              name="tournament-type-label"
              labelId="tournament-type-label"
              label="Tournament Type"
              variant="outlined"
              value={tournamentType}
              onChange={(e) => setTournamentType(e.target.value)}
              fullWidth
            >
              <MenuItem value="league">League</MenuItem>
              <MenuItem value="tournament">Tournament</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="tournament-type-label">City</InputLabel>
            <Select
              name="tournament-type-label"
              labelId="tournament-type-label"
              label="city"
              variant="outlined"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
            >
              {
                cities?.map((city, index) => (
                  <MenuItem key={index} value={`${city.name}`}>{city.name}</MenuItem>

                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Title" fullWidth value={title} onChange={(e) => setTitle(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="agegroup">Age Group</InputLabel>
            <Select
              name="agegroup"
              variant="outlined"
              label="Age Group"
              fullWidth
              value={ageGroup}
              onChange={(e) => setAgeGroup(e.target.value)}
            >
              <MenuItem value="">Select Age Group</MenuItem>
              {ages?.map((data, index) => (
                <MenuItem value={data.value}>{data.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {tournamentType === 'league' && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="city">City</InputLabel>
              <Select
                name="city"
                variant="outlined"
                label="City"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
              >
                {cities?.map((value, index) => (
                  <MenuItem value={value.name} key={index}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Number of Teams"
            fullWidth
            value={numberOfTeams}
            onChange={(e) => setNumberOfTeams(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" style={buttonStyles} color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MockData;
