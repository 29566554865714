import { useState, useEffect } from "react";
import { API,graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { toast } from "react-toastify";
import * as queries from "../graphql/queries";

const useEventsById = () => {
  const [event, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

 
    const fetchEventsById = async (id) => {
      try {
        setLoading(true);
        setError(null);
        setSuccess(false);

        const response = await API.graphql(
          graphqlOperation(queries.getLeague, { id })
        );

        const eventsData = response?.data.getLeague;
        setEvents(response?.data?.getLeague);
        setSuccess(true);

      console.log("response",event)

      } catch (error) {
        console.error("Error fetching leagues:", error);
        setError(error);
      toast.error("something went wrong");

      } finally {
        setLoading(false);
      }
    };

  

  return { fetchEventsById, event,loading };
};

export default useEventsById;
