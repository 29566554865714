import React from 'react';
import { Box } from  '@mui/material';
import League5x5 from '../components/signup/League5x5';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';

const EditPlayer5x5 = () => {
  return (
    <Box display="flex" mb={5}>
      <DashboardLayout />
      <Box mt={10} width="100%">
        <League5x5 />
      
      </Box>
    </Box>
  );
};

export default EditPlayer5x5;
