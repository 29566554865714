import { useState, useEffect } from 'react'; // Remove the unused import
import { API } from 'aws-amplify'; // Remove the unused import
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries';

const useNewsList = () => {
  const [newsList, setGetNews] = useState([]); // Use an array to store multiple newsList
  const [loading, setLoading] = useState(false); // Use an array to store multiple newsList
  const [error, setError] = useState(null); // Use an array to store multiple newsList

  const fetchLeagues = async () => {
    try {
      setLoading(true);

      API.graphql({
        query: queries.listNews,
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }).then((response) => {
        const newsList = response?.data?.listNews?.items;
        setLoading(false);
        setGetNews(newsList);
      });

      // const response = await API.graphql(graphqlOperation(queries.listNews));
      // setGetNews(newsList);
    } catch (error) {
      setLoading(false);
      setError(error);

      console.error('Error fetching leagues:', error);
    }
  };
  useEffect(() => {
    fetchLeagues();
  }, []); // Empty dependency array, meaning this effect will run only once after the component mounts

  return { newsList, loading, error, fetchLeagues };
};

export default useNewsList;
