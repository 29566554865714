import React, { useState } from 'react';
import { Storage } from 'aws-amplify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useDeleteDataFromS3 = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  // eslint-disable-next-line consistent-return
  const VideoDelete = async (key) => {
    setIsDeleting(true);
    if (!key) {
      console.error('Provide Key');
      toast.error('Please provide a key');
      return false;
    }
    console.log('Deleting ...', key);

    try {
      await Storage.remove(key); // Delete the video from S3
      // toast.success('deleted successfully');
      // console.log('deleted successfully');
      return true;
    } catch (error) {
      console.error('Error deleting :', error);
      toast.error('Something went Wrong');
      return false;
    } finally {
      setIsDeleting(false);
    }
  };

  return { VideoDelete };
};

export default useDeleteDataFromS3;
