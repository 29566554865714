import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const useHandlePagintationCampEvents = () => {
  const [initialNextNextToken, setInitialNextNextToken] = useState(undefined);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState(undefined);
  const [eventsList, setEventsList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchEvents = async ({ productName = '' }) => {
    setLoading(true);

    let variables;
    try {
      let filterExpression = {};
      console.log('productName: ' + productName);
      if (productName && productName.trim() !== '') {
        setInitialNextNextToken(undefined);
        setNextToken(undefined);
        setNextNextToken(undefined);
        setPreviousTokens([]);
        filterExpression = {
          name: {
            contains: productName.trim(),
          },
        };

        variables = {
          limit: 100,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }
      } else {
        variables = {
          limit: 10,
          filter: filterExpression,
        };

        if (nextToken !== null && nextToken !== undefined && nextToken !== '') {
          variables = {
            ...variables,
            nextToken,
          };
        }

        const result = await API.graphql({
          query: queries.listCampEvents,
          variables,
          authMode: GRAPHQL_AUTH_MODE.API_KEY, // Adjust this as needed
        });
        const fetchedEvents = result?.data?.listCampEvents?.items || [];

        if (!initialNextNextToken) {
          setInitialNextNextToken(result?.data?.listCampEvents?.nextToken);
        }

        setEventsList([...fetchedEvents]);
        setNextNextToken(result?.data?.listCampEvents?.nextToken);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Events:', error);
    }
  };

  const fetchNextPage = () => {
    if (nextNextToken) {
      setPreviousTokens([...previousTokens, nextToken]);
      setNextToken(nextNextToken);
    }
  };

  const fetchPreviousPage = () => {
    if (previousTokens.length > 0) {
      const prevTokens = [...previousTokens];
      const lastToken = prevTokens.pop();

      setPreviousTokens(prevTokens);
      setNextNextToken(nextToken || initialNextNextToken);
      setNextToken(lastToken);
    }
  };

  const deleteEvents = async (id) => {
    try {
      setLoading(true);
    
        const response = await API.graphql({
          query: mutations.deleteCampEvent,
          variables: { input: { id } },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        console.log(response);
        toast.success('Events Deleted Successfully!');
        setLoading(false);

        // Remove deleted events from the eventsList
        setEventsList((prevEventsList) => prevEventsList.filter((item) => item.id !== id));

    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error Deleting Events:', error);
    }
  };

  const updateEvents = async (eventId, formData) => {
    try {
      setLoading(true);

      const data = {
        name: formData?.name,
        cityOfRegistration: formData?.cityOfRegistration,
      };

      const response = await API.graphql({
        query: mutations.updateCampEvent,
        variables: { input: { ...data, id: eventId } },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setLoading(false);
      const updatedDetails = {
        ...response?.data?.updateCampEvent
      };

      setEventsList((prevEventsList) => {
        const updatedNewsList = prevEventsList?.map((item) => {
          if (item.id === eventId) {
            return { ...item, ...updatedDetails };
          }
          return item;
        });
        return updatedNewsList;
      });

      toast.success('Events Updated Successfully!');
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error('Error updating events details:', error);
      toast.error('Error updating events details');
      throw error; // Propagate the error for handling in the component
    }
  };

  const addEvent = async (items) => {
    setLoading(true);

    try {
      setLoading(true);

      // Upload the image to S3 using Storage.put
      const updatedData = { ...items };
  const response = await API.graphql({
      query: mutations.createCampEvent,
      variables: {
        input: {
          ...updatedData
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });
      const eventsData = {
        ...response?.data?.createCampEvent
      };
      // Check if the team was successfully created
      setLoading(false);
      setEventsList((prevEventsList) => [eventsData, ...prevEventsList]); // Add the new events data to the events list

      setLoading(false);
      toast.success(`Created Successfully!`);
      return response;
    } catch (error) {
      setLoading(false);
      toast.error('Something Went Wrong: Check Console');
      console.error('Error creating events:', error);
      throw error;
    }
  };

  const getEventsByCity = async (city) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: queries.listCampEvents,
        variables: {
          filter: {
           cityOfRegistration: {eq: city} ,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      setLoading(false);
      console.log('listCampEvents', response?.data?.listCampEvents?.items)

      return response?.data?.listCampEvents?.items;
    } catch (error) {
      setLoading(false);
      console.error('Error fetching events details:', error);
      toast.error('Error fetching events details');
      throw error; // Propagate the error for handling in the component
    }
  };
  useEffect(() => {
    fetchEvents('');
  }, [nextToken]);

  return {
    eventsList,
    nextNextToken,
    loading,
    deleteEvents,
    fetchNextPage,
    fetchEvents,
    fetchPreviousPage,
    previousTokens,
    addEvent,
    updateEvents,
    getEventsByCity
  };
};

export default useHandlePagintationCampEvents;
