import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// utils
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from "react-redux";
import Iconify from '../../../components/iconify';
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';

import Loader from '../../../components/Loader';
import AddNewEventSideBar from './AddNewEventSideBar';
import {getAllEvents} from "../../../redux/actions/CampEventsAction";

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopEventCard.propTypes = {
  event: PropTypes.object,
};

export default function ShopEventCard({event,deleteEvents,updateEvents}) {
  const dispatch = useDispatch();

  const { name, agegroup, cityOfRegistration, id,} = event;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState(null); // Track the edit data
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleEditClick = () => {
    // console.log('Idds', id);
    // console.log('events', event);

    setEditData(event); // Set the edit data to the current event
    setIsEditDialogOpen(true);
  };
  const handleDeleteConfirm = async () => {
    setIsDeleteDialogOpen(false);
    await deleteEvents(id);
    // await fetchEventsLists();
    //  dispatch(deleteEvent(id)); 
     dispatch(getAllEvents()); 
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };



  return (
    <Card >
      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Typography variant="subtitle2" noWrap>
          {cityOfRegistration}
        </Typography>

        {/* Display location and date */}
        {/* <Typography variant="subtitle2" color="inherit" noWrap>
         {agegroup}
        </Typography> */}
      
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <EditButton onEditClick={handleEditClick} />
          {isEditDialogOpen && ( // Conditionally render AddNewEventSideBar
            <AddNewEventSideBar
            hidden
              openEvent={isEditDialogOpen} // Pass the state that controls the Dialog visibility
              onCloseEvent={() => setIsEditDialogOpen(false)} // Pass the function to close the Dialog
              editData={editData} // Pass your edit data
              updateEvents={updateEvents}
            />
          )}
          <DeleteButton onDeleteClick={handleDeleteClick} />
        </Stack>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Event</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Event?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Card>
  );
}

const DeleteButton = ({ onDeleteClick }) => {
  return (
    <Button color="primary" onClick={onDeleteClick}>
      <Iconify color="red" icon="fluent:delete-12-regular" />
    </Button>
  );
};
const EditButton = ({ onEditClick }) => {
  return (
    <Button onClick={onEditClick}>
      <EditIcon color="primary" style={{ fontSize: '18px' }} />
    </Button>
  );
};
