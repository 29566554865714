import React, { useEffect, useState } from 'react';
import { useLocation  } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography, CircularProgress, Button } from '@mui/material';
import { useCreateAppointment } from '../hooks/createMeetSecond';
import Iconify from '../components/iconify';
import Loader from '../components/Loader';

const UserMeet = () => {
    const location = useLocation();
    const lastPartOfPath = location.pathname.split('/').pop();
  const { listUserMeets, userMeets, deleteUserMeetById, loading } = useCreateAppointment();
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const meets = await listUserMeets(lastPartOfPath); // Fetch appointments based on id
        setAppointments(meets);
        console.log('apponirenhrhtg',appointments);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      }
    };
    fetchData();
  }, [lastPartOfPath]);

  const handleDeleteMeet = async (meetId) => {
    try {
      await deleteUserMeetById(meetId);
      setAppointments(appointments.filter((meet) => meet.id !== meetId));
    } catch (error) {
      console.error('Error deleting appointment:', error);
    }
  };
if(loading){
    return <Loader />;
}
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {lastPartOfPath === 'clinic' ? 'Clinic Appointments' : 'Training Program Appointments'}
      </Typography>
     
        <Grid container spacing={3}>
          {appointments.map((appointment) => (
            <Grid key={appointment.id} item xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                    {appointment?.userData[0]?.name}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {appointment?.userData[0]?.phoneNumber}
                  </Typography>
                <Typography variant="h6" gutterBottom>
                    {appointment.email}
                  </Typography>
                
                <Typography variant="body2"  component="p">
                    {appointment?.appointmentData?.name}
                  </Typography>
                  <Typography variant="body2"  component="p">
                   Tutor - {appointment?.tutorData?.name}
                  </Typography>
                  <Typography variant="body2"  component="p">
                    {appointment?.appointmentScheduleData?.startTime} - {appointment?.appointmentScheduleData?.endTime}
                  </Typography>
                  
                  <Typography variant="body2"  component="p">
                    {appointment?.date}
                  </Typography>
                  <Button onClick={() => handleDeleteMeet(appointment.id)}  sx={{ display: 'flex', justifyContent: 'center' }} color="secondary">
                  <Iconify color="red" icon="fluent:delete-12-regular" />
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
    
    </Container>
  );
};

export default UserMeet;
