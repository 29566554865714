import React, { useState, useMemo } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Loader from '../Loader';
import ScoreShow from './ScoreShow';

const TabPanel = ({ children, value, index }) => {
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const NewScore = React.memo(({ rounds,setListScores, listScores, setRounds, setCustomLoading, eventId }) => {
  const [tabValue, setTabValue] = useState(1);

  const totalRounds = useMemo(() => Math.max(...rounds.map((round) => round.round)), [rounds]);
console.log("sc")
  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box display={'flex'} gap={3} flexDirection={'column'}>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Score or Start Tournament" variant="fullWidth">
        {[...Array(totalRounds)].map((_, index) => (
          <Tab
            key={index + 1}
            label={`Round ${index + 1}`}
            value={index + 1}
            onClick={() => setTabValue(index + 1)}
            sx={{
              backgroundColor: tabValue === index + 1 ? 'primary.main' : 'transparent',
              color: tabValue === index + 1 ? 'white' : 'black',
            }}
          />
        ))}
      </Tabs>
      {[...Array(totalRounds)].map((_, index) => {
        // Filter scores for the current round based on roundNo
        const roundScores = listScores.filter((score) => score.round === index + 1);

        return (
          <TabPanel key={index} value={tabValue} index={index + 1}>
            {roundScores.length > 0 ? (
              <ScoreShow
                scores={roundScores}
                eventId={eventId}
                round={index + 1}
                setCustomLoading={setCustomLoading}
                setRounds={setRounds}
                setListScores={setListScores}
              />
            ) : (
              <ScoreShow scores={[]} eventId={eventId} round={index + 1} />
            )}
          </TabPanel>
        );
      })}
    </Box>
  );
});

export default NewScore;
