import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography, Button, Grid } from '@mui/material';
// components
// mock
import { useDispatch, useSelector } from 'react-redux';
import MainSkeleton from '../components/SkeletonLoader';

import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar,
  AddNewProductSideBar,
} from '../sections/@dashboard/products';
import useHandlePagintationProduct from '../hooks/hanldePaginationProducts';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);

  // const {fetchProducts,productsDetails}=useHandleProduct();
  const [update, setIsUpdating] = useState(false);
  const {
    productList,
    nextNextToken,
    loading,
    deleteProduct,
    fetchNextPage,
    fetchProduct,
    fetchPreviousPage,
    previousTokens,
    createItems,
    updateProductDetails,
  } = useHandlePagintationProduct();
  const [products, setProductList] = useState(productList);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleOpenProduct = () => {
    setOpenProduct(true);
  };
  const handleCloseProduct = () => {
    setOpenProduct(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    setProductList(productList || []);
  }, [productList]);

  const handleNextPage = () => {
    fetchNextPage();
  };

  const handlePreviousPage = () => {
    fetchPreviousPage();
  };



  return (
    <>
      <Helmet>
        <title> Dashboard: Products | Minimal UI </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Products
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            {/* <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort /> */}
            <br />
            <AddNewProductSideBar
              openProduct={openProduct}
              onCloseProduct={handleCloseProduct}
              onOpenProduct={handleOpenProduct}
              setLoading={setIsLoading}
              createItems={createItems}
              // checkAuthAndFetchData={checkAuthAndFetchData}
            />
          </Stack>
        </Stack>

        {loading || isLoading ? (
          <>
            <MainSkeleton />
            
          </>
        ) : (
          <ProductList
            products={products}
            setProductList={setProductList}
            updateProductDetails={updateProductDetails}
            deleteProduct={deleteProduct}

            // checkAuthAndFetchData={checkAuthAndFetchData}
          />
        )}
        {!loading && (
          <Stack
            direction="row"
            maxWidth={500}
            marginTop={6}
            marginRight="auto"
            marginLeft="auto"
            justifyContent="center"
            gap={5}
            alignItems="center"
            mb={2}
          >
            {fetchPreviousPage && previousTokens?.length > 0 && (
              <Button onClick={handlePreviousPage} disabled={previousTokens.length === 0}>
                Previous Page
              </Button>
            )}
            {nextNextToken !== null && (
              <Button onClick={handleNextPage} disabled={!nextNextToken}>
                Next Page
              </Button>
            )}
          </Stack>
        )}

        {/* <ProductCartWidget /> */}
      </Container>
    </>
  );
}
